const saveWhatAppUserDetails = ` 
mutation($whatsAppBusinessAccountId: String!, $whatsAppPhoneNumberId: String!, $tokenCode: String!, $quickReviewBusinessId : Int){
	saveWhatAppUserDetails(whatsAppBusinessAccountId: $whatsAppBusinessAccountId, whatsAppPhoneNumberId: $whatsAppPhoneNumberId, tokenCode: $tokenCode, quickReviewBusinessId: $quickReviewBusinessId) {
    message
    status
    data {
      accessToken
      isStoredToken
    }
	}
}  
`;

const getAllMessage = ` 
query($waChatRoomId: Int!, $sortBy: String!, $sortOrder: String!, $pageNumber: Int, $pageSize: Int){
	getAllMessage(waChatRoomId: $waChatRoomId, sortBy: $sortBy, sortOrder: $sortOrder, pageNumber: $pageNumber, pageSize: $pageSize) {
    message
    status
    data {
      conversationId
      cts
      from
      id
      message
      messageId
      templateData
      dynamicData
      location
      imageMsgId
      video
      document
      reactOnMsg{
        emoji
        message_id
      }
      replyId
      status
      template
      type
      uts
      whatsAppMedia
    }
	}
}  
`;

const getUserDetail = ` 
query($quickReviewBusinessId: Int){
	getUserDetail(quickReviewBusinessId: $quickReviewBusinessId) {
    message
    status
    data
	} 
}  
`;

const sendMessages = ` 
mutation($mediaName: String, $caption: String, $link: String, $message: String, $name: String, $phoneNumber: String!, $templateComponents: String, $templateId: Int, $type: String, $waBroadcastId: Int, $waBusinessPhoneNumberId: String!, $tempMessageId: String, $isNewDynamicTemplate: Boolean){
	sendMessages(mediaName: $mediaName, caption: $caption,link: $link, message: $message, name: $name, phoneNumber: $phoneNumber, templateComponents: $templateComponents, templateId: $templateId, type: $type, waBroadcastId: $waBroadcastId, waBusinessPhoneNumberId: $waBusinessPhoneNumberId, tempMessageId: $tempMessageId, isNewDynamicTemplate: $isNewDynamicTemplate) {
    message
    status
    data {
      messageId
      tempMessageId
    }
	}
}  
`;

const getChatRoomWithLastMessage = ` 
query($tagIds: [Int!], $createdAt: TimeFrame, $lastSeen: TimeFrame,$isBroadcast: Boolean!, $isUnRead: Boolean!, $search: String,$status: [String!], $userBusinessId: Int!, $isWaiting: Boolean){
	getChatRoomWithLastMessage(tagIds: $tagIds, createdAt: $createdAt, lastSeen: $lastSeen, isBroadcast: $isBroadcast, isUnRead: $isUnRead, search: $search, status:$status, userBusinessId: $userBusinessId, isWaiting: $isWaiting) {
    status
    message
    liveCount
    awaitingCount
    data {
      assignToOwner
      lastOnlineTime
      optedIn
      reactOnMsg{
        emoji
        message_id
      }
      readStatus
      sourceType
      template
      templateCount
      textCount
      name
      phoneNumber
      id
      waChatRoomId
      replyId
      chatResolveStatus
      reactOnMsg {
        message_id
        emoji
      }
      status
      type
      replyOnMessage
      cts
      message
    }
	}
}  
`;

const getWhatsappTemplate = ` 
query($searchText: String, $templateId: Int, $pageNumber: Int, $pageSize: Int, $businessId: Int, $status: String, $defaultTemplateCategoryId: Int){
	getWhatsappTemplate(searchText: $searchText, templateId: $templateId, pageNumber: $pageNumber, pageSize: $pageSize, defaultTemplateCategoryId: $defaultTemplateCategoryId, businessId: $businessId, status: $status) {
    message
    status
    totalCount
    data{
      displayName
      dynamicData
      categoryTypeId
      id
      templateData
      categoryName
      cts
      status
      type
      templateId
      templateName
      templateHealth
    }
	}
}  
`;

const getChatRoomNote = ` 
query($chatRoomId: Int!, $pageNumber: Int, $pageSize: Int){
	getChatRoomNote(chatRoomId: $chatRoomId, pageNumber: $pageNumber, pageSize: $pageSize) {
    message
    status
    data{
      note
      uts
      id
      cts
    }
	}
}  
`;

const createChatRoomNote = ` 
mutation($chatRoomId: Int!, $note: String!){
	createChatRoomNote(chatRoomId: $chatRoomId, note: $note) {
    message
    status
	}
}  
`;

const deleteChatRoomNote = ` 
mutation($noteId: Int!, $note: String){
	deleteChatRoomNote(noteId: $noteId, note: $note) {
    message
    status
	}
}  
`;

const editChatRoomNote = ` 
mutation($noteId: Int!, $note: String){
	editChatRoomNote(noteId: $noteId, note: $note) {
    message
    status
	}
}  
`;

const getWABroadCastList = ` 
query($isScheduled: Boolean, $pageSize: Int, $pageNumber: Int, $searchName: String, $userBusinessId: Int!){
	getWABroadCastList(isScheduled: $isScheduled, pageSize: $pageSize, pageNumber: $pageNumber, searchName: $searchName, userBusinessId: $userBusinessId) {
    data {
      cts
      id
      isScheduled
      name
      recipientsCount
      scheduledDate
      status
      campaignDurationInSec
      dynamicData
      templateData
      totalCreditUsage
    }
    totalCount
    message
    status
	}
}  
`;

const getWABroadCastContactList = ` 
query($contactIds: [Int!], $waBroadcastId: Int, $pageSize: Int, $pageNumber: Int){
	getWABroadCastContactList(contactIds:$contactIds, waBroadcastId: $waBroadcastId, pageSize: $pageSize, pageNumber: $pageNumber) {
    data {
      customerName
      phoneNumber
      messageSendCts
      status
    }
    totalCount
    message
    status
	}
}  
`;

const getWABroadCastPcData = ` 
query($waBroadcastId: Int!){
	getWABroadCastPcData(waBroadcastId: $waBroadcastId) {
    data {
      readCount
      sentCount
      failedCount
      repliedCount
      sendingCount
      deliveredCount
      deliveredContactIds
      failedContactIds
      readContactIds
      repliedContactIds
      sendContactIds
      sendingContactIds
    }
    message
    status
	}
}  
`;

const createBroadcast = ` 
mutation($allContactSelected: Boolean, $contact: [Contact!]!, $isScheduled: Boolean!, $name: String!, $scheduledDate: String, $templateId: Int!, $userBusinessId: Int!, $dynamicVariables: String){
	createBroadcast(allContactSelected: $allContactSelected, contact: $contact, isScheduled: $isScheduled, name: $name, scheduledDate: $scheduledDate, templateId: $templateId, userBusinessId: $userBusinessId, dynamicVariables: $dynamicVariables) {
    message
    status
	}
}  
`;

const updateBroadcast = ` 
mutation($allContactSelected: Boolean, $contact: [Contact!]!, $broadcastId: Int!, $isScheduled: Boolean!, $name: String!, $scheduledDate: String, $templateId: Int!, $dynamicVariables: String){
	updateBroadcast(allContactSelected: $allContactSelected, contact: $contact, broadcastId: $broadcastId, isScheduled: $isScheduled, name: $name, scheduledDate: $scheduledDate, templateId: $templateId, dynamicVariables: $dynamicVariables) {
    message
    status
	}
}  
`;

const saveCampaignContact = ` 
mutation($tags: [Tags!], $callFrom: String, $countryCode: String, $email: String, $firstName: String!, $lastName: String, $phoneNumber: Float, $userBusinessId: Int){
	saveCampaignContact(tags: $tags, callFrom: $callFrom, countryCode: $countryCode, email: $email, firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber, userBusinessId: $userBusinessId) {
    message
    status
	}
}  
`;

const searchCampaignContact = ` 
query($callFrom: String, $searchText: String!, $userBusinessId: Int){
	searchCampaignContact(callFrom: $callFrom, searchText: $searchText, userBusinessId: $userBusinessId) {
    message
    status
    data{
      countryCode
      email
      name
      phoneNumber
    }
	}
}  
`;

const deleteUserCsvContactOrCampaignContact = ` 
mutation($dataFrom: String, $email: String, $id: Int, $phoneNumber: String){
	deleteUserCsvContactOrCampaignContact(dataFrom: $dataFrom, email: $email, id: $id, phoneNumber: $phoneNumber) {
    message
    status
	}
}  
`;

const updateCsvContactOrCampaignContact = ` 
mutation($dataFrom: String, $email: String, $id: Int, $phoneNumber: String, $countryCode: String, $oldEmail: String, $oldPhoneNumber: String, $name: String, $firstName: String, $lastName: String){
	updateCsvContactOrCampaignContact(dataFrom: $dataFrom, email: $email, id: $id, phoneNumber: $phoneNumber, countryCode: $countryCode, oldEmail: $oldEmail, oldPhoneNumber: $oldPhoneNumber, name: $name, firstName: $firstName, lastName: $lastName) {
    message
    status
	}
}  
`;

const updateCampaignContact = ` 
mutation($tags: [Tags!], $dataFrom: String, $email: String, $id: Int, $phoneNumber: String, $countryCode: String, $oldEmail: String, $oldPhoneNumber: String, $name: String, $firstName: String, $lastName: String){
	updateCampaignContact(tags: $tags, dataFrom: $dataFrom, email: $email, id: $id, phoneNumber: $phoneNumber, countryCode: $countryCode, oldEmail: $oldEmail, oldPhoneNumber: $oldPhoneNumber, name: $name, firstName: $firstName, lastName: $lastName) {
    message
    status
	}
}  
`;

const getTemplateCategories = ` 
query{
	getTemplateCategories {
    data{
      id
      name
    }
   message
   status
	}
   
}  
`;

const getTemplateVariable = ` 
query{
	getTemplateVariable {
    data{
      id
      name
      isAutoReplace
      type
    }
   message
   status
	}
   
}  
`;

const createWhatsappTemplate = ` 
mutation($bodyVariables: [String!], $templateBody: String!, $templateButton: TemplateButtonData, $templateCategoryId: Int!, $templateFooter: String, $templateImage: Upload, $templateName: String!, $templateTitle: String){
	createWhatsappTemplate(bodyVariables: $bodyVariables, templateBody: $templateBody, templateButton: $templateButton, templateCategoryId: $templateCategoryId, templateFooter: $templateFooter, templateImage: $templateImage, templateName: $templateName, templateTitle: $templateTitle) {
   message
   status
	}
}  
`;

const deleteWhatsappTemplate = ` 
mutation($templateId: Int!){
	deleteWhatsappTemplate(templateId: $templateId) {
   message
   status
	}
}  
`;

const getBroadcastTemplate = ` 
query($waBroadcastId: Int!){
	getBroadcastTemplate(waBroadcastId: $waBroadcastId) {
   message
   status
   data{
    categoryTypeId
    dynamicData
    id
    isScheduled
    name
    templateData
    templateId
    templateName
    waUserId
   }
	}
}  
`;

const getChatRoomResolveStatusList = `query{
  getChatRoomResolveStatusList{
    status
    message
    data{
    key
      value
    }
  }
}`;

const getWaUserDetailsOnChatRoomId = ` query ($waChatRoomId: Int!){
  getWaUserDetailsOnChatRoomId(waChatRoomId:$waChatRoomId){
    status
    message
    data{
      name
      uts
      chatResolveStatus
    }
  }
}`;

const deleteWaUserMedia = `mutation deleteWaUserMedia($waUserMediaIds: [Int!]!) {
  deleteWaUserMedia(waUserMediaIds: $waUserMediaIds) {
    message
    status
  }
}

`;

const updateChatRoomResolveStatus = `mutation($status: String!, $waChatRoomId:Int!){
  updateChatRoomResolveStatus(status:$status,waChatRoomId:$waChatRoomId){
    status
    message
  }
}`;

const getCustomerJourney = `query($waChatRoomId: Int!, $pageNumber:Int, $pageSize:Int){
  getCustomerJourney(waChatRoomId:$waChatRoomId,pageNumber:$pageNumber, pageSize:$pageSize){
    status
    message
    totalCount
    data{
      convoName
      cts
      id
      journeyType
      uts
      wa_user_tags{
        colorCode
        tagName
      }
    }
  }
}`;

const getAttachedWaChatTags = `query($waChatRoomId: Int!){
  getAttachedWaChatTags(waChatRoomId:$waChatRoomId){
    status
    message
    data {
      id
      cts
      tagName
      colorCode
      customerJourney
      firstMessage
      waChatRoomId
    }
  }
}`;

const getWaChatTags = `query($tagId: Int, $searchText: String, $pageNumber: Int, $pageSize: Int){
  getWaChatTags(tagId:$tagId, searchText: $searchText, pageNumber: $pageNumber, pageSize: $pageSize){
    status
    message
    totalCount
    data {
      id
      cts
      tagName
      colorCode
      customerJourney
      firstMessage
    }
  }
}`;

const attachOrRemoveWaChatTag = `mutation($waChatRoomId: Int!, $task: String!, $tagIds: [Int!]){
  attachOrRemoveWaChatTag(waChatRoomId:$waChatRoomId, task: $task, tagIds: $tagIds){
    status
    message
  }
}`;

const createAndUpdateWaChatTag = `mutation($waChatRoomId: Int, $colorCode: String!, $customerJourney: Boolean!, $firstMessage: Boolean!, $tagId: Int, $tagName: String!){
  createAndUpdateWaChatTag(waChatRoomId:$waChatRoomId, colorCode: $colorCode, customerJourney: $customerJourney, firstMessage: $firstMessage, tagId: $tagId, tagName: $tagName){
    status
    message
  }
}`;
const addWaUserMedia = `mutation addWaUserMedia($file: Upload!, $fileType: String!) {
  addWaUserMedia(file: $file, fileType: $fileType) {
    status
    message
  }
}
`;

const whatsAppUsage = `mutation ($startTime: String!, $endTime: String!) { 
  whatsAppUsage(startTime: $startTime, endTime: $endTime) {
    status
    message
    data {
      SERVICE {
        cost
        conversation
      }
      MARKETING {
        cost
        conversation
      }
      UTILITY {
        cost
        conversation
      }
      AUTHENTICATION {
        cost
        conversation
      }
    }
  }
}`;

const addOrRemoveOptInOptOutKeywords = `mutation($optInKeyWords: [String!]
$optKeywordId: Int
$optOutKeywords: [String!]
$task: String!) {
  addOrRemoveOptInOptOutKeywords(
    optInKeyWords:$optInKeyWords
    optKeywordId:$optKeywordId
    task:$task
    optOutKeywords:$optOutKeywords
  ) {
    status
    message
  }
}`;

const updateOptInOptOutMessages = `mutation (
    $optInMessagesStatus: Boolean
    $optInMessages: String!
    $optOutMessages: String!
    $optOutMessagesStatus: Boolean
  ) {
  updateOptInOptOutMessages(
    optInMessagesStatus: $optInMessagesStatus
    optInMessages: $optInMessages
    optOutMessages: $optOutMessages
    optOutMessagesStatus:$optOutMessagesStatus
  ) {
    status
    message
  }
}`;

const getOptInOptOutKeywords = `query {
  getOptInOptOutKeywords {
    status
    message
    data {
      optIn {
        id
        keyword
      }
      optOut {
        id
        keyword
      }
    }
  }
}`;

const getOptInOptOutMessages = `query {
  getOptInOptOutMessages {
    status
    message
    data {
      id
      optInMessages
      optOutMessages
      optInMessagesStatus
      optOutMessagesStatus
    }
  }
}`;

const getWelcomeAndOffHourMessageSettings = `query {
  getWelcomeAndOffHourMessageSettings {
    status
    message
    data {
      id
      welcomeMessageStatus
      offHourMessageStatus
      welcomeMessageData {
        text
        messageType
        messageTypeFormat
        caption
        mediaUrl
      }
      offHourMessageData {
        text
        messageType
        messageTypeFormat
        caption
        mediaUrl
      }
      timeZone
      workingHours {
        day
        toggle
        offTime {
          hours
          minutes
        }
        setTime {
          hours
          minutes
        }
      }
    }
  }
}`;

const updateWelcomeAndOffHourMessageSettings = `mutation updateWelcomeAndOffHourMessageSettings($offHourMessageData: WelcomeAndOffHourMessageArgs!
$offHourMessageStatus: Boolean!
$timeZone: String!
$welcomeMessageData: WelcomeAndOffHourMessageArgs!
$welcomeMessageStatus: Boolean!
$workingHours: [WorkingHoursArgs!]!) {
  updateWelcomeAndOffHourMessageSettings(
    offHourMessageStatus: $offHourMessageStatus
    welcomeMessageStatus: $welcomeMessageStatus
    welcomeMessageData: $welcomeMessageData
    offHourMessageData: $offHourMessageData
    timeZone: $timeZone
    workingHours: $workingHours
  ) {
    status
    message
  }
}`;

const deleteWaChatTags = `mutation($tagIds:[Int!]) {
  deleteWaChatTags(tagIds: $tagIds) {
    status
    message
  }
}`;

const getWaUserProfileDetail = `query($quickReviewBusinessId: Int
){
  getWaUserProfileDetail(quickReviewBusinessId:$quickReviewBusinessId){
    data{
      address
      businessSector
      description
      email
      profileUrl
      websites
      displayPhoneNumber
      verifiedName
    }
    message
    status
  }
}`;

const updateWaUserProfileDetail = `mutation updateWaUserProfileDetail(
  $quickReviewBusinessId: Int!,
  $description: String!,
  $address: String!,
  $website: String!,
  $businessSector: String,
  $email: String,
  $image: Upload,
  $removeProfile: Boolean
) {
  updateWaUserProfileDetail(
    quickReviewBusinessId: $quickReviewBusinessId
    description: $description
    address: $address
    website: $website
    businessSector: $businessSector
    email: $email
    image: $image
    removeProfile: $removeProfile
  ) {
    message
    status
  }
}`;

const getWaBusinessSectors = `query {
  getWaBusinessSectors {
    data {
      description
      id
      name
    }
    message
    status
  }
}
`;

const getWaGreenTikStatus = `query ($quickReviewBusinessId: Int){
  getWaGreenTikStatus(quickReviewBusinessId: $quickReviewBusinessId){
    data{
      remark
      status
    }
    message
    status
  }
}`;

const updateWaGreenTikStatus = `mutation ($quickReviewBusinessId: Int) {
  updateWaGreenTikStatus(quickReviewBusinessId:$quickReviewBusinessId){
    message
    status
  }
}`;

const generateWhatsappLink = `mutation ($countryCode: String!
$message: String
$phoneNumber: Float!
$businessId: Int){
  generateWhatsappLink(
    countryCode: $countryCode
    phoneNumber: $phoneNumber
    message: $message
    businessId: $businessId
  ) {
    message
    status
    data {
      uniqueId
    }
  }
}`;

const getWaUserMedia = `query($fileType: String!, $pageNumber: Int, $pageSize: Int, $searchText: String){
  getWaUserMedia(fileType: $fileType, pageNumber: $pageNumber, pageSize: $pageSize, searchText: $searchText){
    message
    status
    allMediaCount {
      image
      audio
      video
      file
    }
    mediaCount {
      total
      recentCount
      totalCount
    }
    data {
      id
      isRecentlyUsed
      cts
      awsUrl
      fileData {
        fileName
        mimeType
      }
    }
  }
}`;

const getDefaultTemplateCategories = `query ($quickReviewBusinessId: Int){
  getDefaultTemplateCategories(quickReviewBusinessId: $quickReviewBusinessId){
    data{
      id
      name
      logo
      isTemplateAvailable
    }
    status
    message
  }
}
`;

const getTemplateLanguage = `query {
  getTemplateLanguage {
    status
    message
    data{
      id
      name
      symbol
    }
  }
}`;

const createOrEditWhatsappTemplate = ` 
mutation($bodyVariables: [WhatsappDynamicVariables!], $businessId: Int, $callToAction: [CallToActionButton!], $code: String, $draftId: Int, $expireMin: Int, $headerType: String, $quickReply: [String!], $templateBody: String, $templateCategoryId: Int, $templateFooter: String, $templateId: String, $templateImage: Upload, $templateLanguage: String, $templateName: String, $templateTitle: String, $existingMediaUrl:String){
	createOrEditWhatsappTemplate(bodyVariables: $bodyVariables, businessId: $businessId, callToAction: $callToAction, code: $code, draftId: $draftId, expireMin: $expireMin, headerType: $headerType, quickReply: $quickReply, templateBody: $templateBody, templateCategoryId: $templateCategoryId, templateFooter: $templateFooter, templateId: $templateId, templateImage: $templateImage, templateLanguage: $templateLanguage, templateName: $templateName, templateTitle: $templateTitle, existingMediaUrl: $existingMediaUrl) {
   message
   status
	}
}  
`;

const saveWhatsappTemplateDraft = ` 
mutation($bodyVariables: [WhatsappDynamicVariables!], $businessId: Int, $callToAction: [CallToActionButton!], $code: String, $draftId: Int, $expireMin: Int, $headerType: String, $quickReply: [String!], $templateBody: String, $templateCategoryId: Int, $templateFooter: String, $templateId: String, $templateImage: Upload, $templateLanguage: String, $templateName: String, $templateTitle: String, $existingMediaUrl: String){
	saveWhatsappTemplateDraft(bodyVariables: $bodyVariables, businessId: $businessId, callToAction: $callToAction, code: $code, draftId: $draftId, expireMin: $expireMin, headerType: $headerType, quickReply: $quickReply, templateBody: $templateBody, templateCategoryId: $templateCategoryId, templateFooter: $templateFooter, templateId: $templateId, templateImage: $templateImage, templateLanguage: $templateLanguage, templateName: $templateName, templateTitle: $templateTitle, existingMediaUrl: $existingMediaUrl) {
   message
   status
	}
}  
`;

const testBroadcast = ` 
mutation($contact: [Contact!]!, $isScheduled: Boolean!, $name: String, $scheduledDate: String, $templateId: Int!, $userBusinessId: Int!, $dynamicVariables: String){
	testBroadcast(contact: $contact, isScheduled: $isScheduled, name: $name, scheduledDate: $scheduledDate, templateId: $templateId, userBusinessId: $userBusinessId, dynamicVariables: $dynamicVariables) {
    message
    status
	}
}  
`;

const deleteBroadcast = `
mutation ($waBroadcastId: Int!) {
  deleteBroadcast(waBroadcastId: $waBroadcastId){
    status
    message
  }
}
`;

const wabaQuery = {
  saveWhatAppUserDetails,
  getAllMessage,
  getUserDetail,
  sendMessages,
  getChatRoomWithLastMessage,
  getWhatsappTemplate,
  getChatRoomNote,
  createChatRoomNote,
  deleteChatRoomNote,
  editChatRoomNote,
  getWABroadCastList,
  getWABroadCastContactList,
  getWABroadCastPcData,
  createBroadcast,
  updateBroadcast,
  saveCampaignContact,
  searchCampaignContact,
  deleteUserCsvContactOrCampaignContact,
  updateCsvContactOrCampaignContact,
  updateCampaignContact,
  getTemplateCategories,
  getTemplateVariable,
  createWhatsappTemplate,
  deleteWhatsappTemplate,
  getBroadcastTemplate,
  getChatRoomResolveStatusList,
  getWaUserDetailsOnChatRoomId,
  updateChatRoomResolveStatus,
  getCustomerJourney,
  getAttachedWaChatTags,
  attachOrRemoveWaChatTag,
  createAndUpdateWaChatTag,
  getWaChatTags,
  getWaUserMedia,
  addWaUserMedia,
  deleteWaUserMedia,
  addOrRemoveOptInOptOutKeywords,
  whatsAppUsage,
  updateOptInOptOutMessages,
  getOptInOptOutKeywords,
  getOptInOptOutMessages,
  getWelcomeAndOffHourMessageSettings,
  updateWelcomeAndOffHourMessageSettings,
  deleteWaChatTags,
  getWaUserProfileDetail,
  getWaBusinessSectors,
  updateWaUserProfileDetail,
  getWaGreenTikStatus,
  updateWaGreenTikStatus,
  generateWhatsappLink,
  getDefaultTemplateCategories,
  getTemplateLanguage,
  createOrEditWhatsappTemplate,
  saveWhatsappTemplateDraft,
  testBroadcast,
  deleteBroadcast,
};

export default wabaQuery;
