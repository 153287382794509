import {
  Button,
  CircularProgress,
  Divider,
  Drawer,
  FormControlLabel,
  Icon,
  InputAdornment,
  Pagination,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import { useSelector, useDispatch } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSnackbar } from 'notistack';
import { ChromePicker } from 'react-color';
import quickHub from '../../../query/quickHub';
import { fetchBusinesses, handleApiRequest } from '../../../common/common';
import ConformationDialogBox from '../../../CommonComponent/ConformationDialogBox';
import {
  getAllUserBusinessSubscription,
  selectDashboardData,
  setUserBusinesses,
  setUserCurrentBusinessDetailsValue,
} from '../../../../store/userSlice';
import CheckPlanForAddNewBusiness from '../../manage-business/checkPlanForAddNewBusiness/CheckPlanForAddNewBusiness';

const CreateLabels = (props) => {
  const {
    editLabels,
    openDrawer,
    setOpenDrawer,
    fetchManageLabels,
    setEditLabels,
    editBusinessScreen,
  } = props;
  const [searchText, setSearchText] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [businesses, setBusinesses] = useState([]);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState([]);
  const [removeBusiness, setRemoveBusiness] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [labelNameError, setLabelNameError] = useState(false);
  const dispatch = useDispatch();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [openSelectProductDialog, setOpenSelectProductDialog] = useState(false);
  const [planAvailableDialog, setPlanAvailableDialog] = useState(false);
  const [userAvailablePlan, setUserAvailablePlan] = useState([]);
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const [labelDetails, setLabelDetails] = useState({
    labelName: editLabels?.label || '',
    colorCode:
      editLabels?.color ||
      `#${Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, '0')}`,
  });
  const [selectAll, setSelectAll] = useState(false);
  const [allBusinessesOnCurrentPage, setAllBusinessesOnCurrentPage] = useState([]);

  useEffect(() => {
    if (editLabels?.id) {
      setLabelDetails({
        labelName: editLabels?.label,
        colorCode: editLabels?.color,
      });

      if (editLabels?.label?.length < 20) {
        setLabelNameError(false);
      }
    }
  }, [editLabels]);

  useEffect(() => {
    const fetchBusiness = async () => {
      try {
        const payload = {
          query: quickHub.getAllBusinesses,
          variables: {
            searchText,
            pageNumber: Number(page),
            pageSize: 5,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getAllBusinesses?.status === 200) {
          setBusinesses(result?.getAllBusinesses?.data);
          setTotalCount(result?.getAllBusinesses?.totalCount);
          if (editLabels?.id) {
            setSelectedBusiness((preBusiness) => {
              if (editLabels?.id) {
                const filteredBusinesses = result?.getAllBusinesses?.data?.filter((business) =>
                  business?.label?.some((label) => label?.id === editLabels?.id)
                );
                const uniqueBusinesses = filteredBusinesses?.filter(
                  (newBusiness) =>
                    !preBusiness.some(
                      (existingBusiness) => existingBusiness?.id === newBusiness?.id
                    )
                );

                return [...preBusiness, ...uniqueBusinesses];
              }
              return preBusiness;
            });
          }
        } else {
          enqueueSnackbar(result?.getAllBusinesses?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        console.error('Error fetching businesses:', error);
      }
    };
    fetchBusiness();
  }, [enqueueSnackbar, editLabels, searchText, page]);

  const handleRandomColor = () => {
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    setLabelDetails((prevState) => ({
      ...prevState,
      colorCode: randomColor,
    }));
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);

    if (isChecked) {
      // Add all businesses from current page if they're not already selected
      const newSelectedBusinesses = [...selectedBusiness];
      allBusinessesOnCurrentPage.forEach((business) => {
        if (!selectedBusiness.some((selected) => selected.id === business.id)) {
          newSelectedBusinesses.push(business);
        }
      });
      setSelectedBusiness(newSelectedBusinesses);
    } else {
      // Remove only the businesses from current page
      const filteredBusinesses = selectedBusiness.filter(
        (selected) => !allBusinessesOnCurrentPage.some((current) => current.id === selected.id)
      );
      setSelectedBusiness(filteredBusinesses);
    }
  };

  useEffect(() => {
    const areAllCurrentPageBusinessesSelected = allBusinessesOnCurrentPage.every((business) =>
      selectedBusiness.some((selected) => selected.id === business.id)
    );
    setSelectAll(areAllCurrentPageBusinessesSelected && allBusinessesOnCurrentPage.length > 0);
  }, [selectedBusiness, allBusinessesOnCurrentPage]);

  useEffect(() => {
    if (businesses?.length > 0) {
      setAllBusinessesOnCurrentPage(businesses);
    }
  }, [businesses]);

  const onSubmit = async () => {
    setRefreshIcon(true);
    try {
      const payload = {
        query: quickHub.createAndEditUserBusinessesLabel,
        variables: {
          label: labelDetails?.labelName,
          color: labelDetails?.colorCode,
          businessIdsToAdd:
            selectedBusiness?.length > 0 ? selectedBusiness?.map((business) => business?.id) : [],
          ...(editLabels && {
            businessIdsToRemove: removeBusiness?.map((business) => business?.id),
            labelId: editLabels?.id,
          }),
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.createAndEditUserBusinessesLabel?.status === 200) {
        fetchManageLabels();
        handleClear();
        enqueueSnackbar(result?.createAndEditUserBusinessesLabel?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        const userBusinesses = await fetchBusinesses();
        await localStorage.setItem('businesses', JSON.stringify(userBusinesses));
        await dispatch(setUserBusinesses(userBusinesses));
        await dispatch(
          setUserCurrentBusinessDetailsValue(
            userBusinesses?.find((item) => item?.id === userCurrentBusinessDetails?.id)
          )
        );
      } else {
        enqueueSnackbar(result?.createAndEditUserBusinessesLabel?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error in onSubmit:', error);
    }
  };

  const deleteLabel = async () => {
    const payload = {
      query: quickHub.deleteBusinessesLabel,
      variables: {
        labelId: Number(editLabels?.id),
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.deleteBusinessesLabel?.status === 200) {
      fetchManageLabels();
      setOpenDialogue(false);
      handleClear();
      enqueueSnackbar(result?.deleteBusinessesLabel?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar(result?.deleteBusinessesLabel?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'labelName' && value.length > 20) {
      setLabelNameError(true);
    } else {
      setLabelNameError(false);
      setLabelDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleClear = () => {
    if (editLabels) {
      setEditLabels();
    }
    setOpenDrawer(false);
    setSelectedBusiness([]);
    setRemoveBusiness([]);
    setPage(1);
    setSearchText('');
    setLabelDetails({
      labelName: '',
      colorCode: `#${Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, '0')}`,
    });
  };

  const handleSelectBusiness = (data) => {
    setSelectedBusiness((prevBusiness) => {
      const businessExists = prevBusiness?.some((business) => business?.id === data?.id);
      if (businessExists) {
        if (editLabels) {
          setRemoveBusiness((prevRemoved) => [...prevRemoved, data]);
        }
        return prevBusiness.filter((business) => business?.id !== data.id);
      }
      if (editLabels) {
        setRemoveBusiness((prevRemoved) =>
          prevRemoved.filter((business) => business.id !== data.id)
        );
      }
      return [...prevBusiness, data];
    });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleDeleteClosePopUp = () => {
    setOpenDialogue(false);
  };

  const handleAddBusiness = () => {
    const findAvailablePlan = userSubscriptionData?.filter((item) => !item?.userBusinessId);
    if (findAvailablePlan?.length > 0) {
      setUserAvailablePlan(findAvailablePlan);
      setPlanAvailableDialog(true);
    } else {
      setOpenSelectProductDialog(true);
    }
  };

  const isLabelNameEmpty = !labelDetails?.labelName || !labelDetails?.labelName.trim();

  return (
    <>
      <Drawer className="" anchor="right" open={openDrawer} transitionDuration={700}>
        <div className="md:max-w-[823px] md:min-w-[823px] p-16 relative">
          <div className="flex items-center justify-between mb-12">
            <div className="flex items-center gap-12">
              {editLabels && (
                <Button className="min-w-16 w-16 hover:bg-white" onClick={() => handleClear(false)}>
                  <ArrowBackIcon />
                </Button>
              )}
              <Typography className="font-bold text-18">
                {editLabels
                  ? t('manageLabels.editBusinessLabel')
                  : t('manageLabels.createNewLabel')}
              </Typography>
            </div>
            {editLabels?.id ? (
              <Button
                className="bg-red-100 hover:bg-red-100 rounded-md text-red border border-red border-solid font-medium min-h-32 h-32"
                onClick={() => setOpenDialogue(true)}
              >
                {t('manageLabels.delete')}{' '}
                <img className="w-20 h-20" src="assets/images/business/delete.svg" alt="" />
              </Button>
            ) : (
              <Button className="hover:bg-white" onClick={() => handleClear(false)}>
                <CloseIcon />
              </Button>
            )}
          </div>
          <hr />
          <div className="mt-12">
            <Typography className="font-semibold text-16">{t('manageLabels.labelName')}</Typography>
            <div className="h-44 bg-gray-A500 mt-12">
              <TextField
                className="w-full bg-gray-A500"
                size="small"
                placeholder={t('manageLabels.enterLabelName')}
                name="labelName"
                onChange={handleInputChange}
                value={labelDetails?.labelName}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    border: 'none',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
              />
            </div>
            {labelNameError && (
              <Typography className="text-red p-6">{t('manageLabels.labelError')}</Typography>
            )}
            <Typography className="font-semibold text-16 mt-12">
              {t('quickChat.createBot.selectColor')}
            </Typography>
            <div className="flex flex-wrap items-center space-x-2 mt-12 justify-between bg-gray-A500 relative px-12 py-6">
              <Typography>{labelDetails?.colorCode}</Typography>
              <div className="flex items-center gap-6 sm:gap-12">
                <Button
                  onClick={handleRandomColor}
                  className="!shadow-non min-w-160 w-160 sm:min-w-200 sm:w-200 h-32 hover:bg-white min-h-32 border border-solid sm:font-semibold text-12 sm:text-14 border-gray bg-white rounded-md p-0"
                >
                  {t('manageLabels.selectRandomColour')}{' '}
                  <img className="pl-6" src="assets/images/quick-hub/random.png" alt="" />
                </Button>
                <Divider className="sm:h-36 sm:w-2 h-1 w-28 bg-gray-300 sm:mx-auto mx-6 hidden sm:block" />
                <Button
                  className="min-w-32 min-h-32 w-32 h-32 rounded-full border"
                  style={{ backgroundColor: labelDetails?.colorCode }}
                  onClick={() => setShowColorPicker(true)}
                />
              </div>
              {showColorPicker && (
                <div className="shadow p-16 inline-block absolute bg-white right-0 top-0 z-20 -ml-120 mt-60 sm:mt-0 sm:ml-16">
                  <Icon
                    className="text-14 absolute top-2 right-2 cursor-pointer"
                    onClick={() => setShowColorPicker(false)}
                  >
                    close
                  </Icon>
                  <ChromePicker
                    color={labelDetails?.colorCode}
                    className="!shadow-none border border-1 border-solid border-grey-300"
                    onChangeComplete={(colors) =>
                      setLabelDetails((prevState) => ({
                        ...prevState,
                        colorCode: colors.hex,
                      }))
                    }
                  />
                </div>
              )}
            </div>
          </div>
          <div className="mt-16">
            <Typography className="font-semibold text-16">
              {t('manageLabels.assignBusiness')}
            </Typography>
            <div className="flex items-center justify-between">
              <TextField
                placeholder={t('business.visitedBusiness.labels.searchBusinessName')}
                variant="outlined"
                fullWidth
                className="bg-white w-full sm:w-512"
                margin="normal"
                value={searchText}
                size="small"
                onChange={(e) => {
                  setSearchText(e.target.value);
                  setPage(1);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: searchText && (
                    <InputAdornment position="end">
                      <Icon
                        color="quickChat"
                        className="cursor-pointer text-20"
                        onClick={() => setSearchText('')}
                      >
                        close
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
              <FormControlLabel
                value="end"
                control={<Checkbox />}
                label="Select All Business"
                labelPlacement="end"
                onChange={handleSelectAll}
                checked={selectAll}
              />
              <div>
                <Typography />
              </div>
            </div>
            {businesses?.length > 0 ? (
              <div>
                <div className="bg-gray-A500 rounded-md">
                  {businesses?.map((business, index) => {
                    return (
                      <div key={index}>
                        <div className="flex justify-between items-center px-12 py-10">
                          <div className="flex gap-16 items-center">
                            <img
                              className="max-w-128 min-w-128 h-76 rounded-md"
                              src={
                                business?.profileURL ||
                                '/assets/images/business/businessprofile.png'
                              }
                              alt=""
                            />
                            <div>
                              <Typography className="font-bold text-16">
                                {business?.name}
                              </Typography>
                              <Typography className="font-semibold text-14 max-w-400">
                                {business?.address}
                              </Typography>
                            </div>
                          </div>
                          <Checkbox
                            className=""
                            checked={selectedBusiness?.some((value) => value?.id === business?.id)}
                            onChange={() => handleSelectBusiness(business)}
                          />
                        </div>
                        <hr className="border !border-gray" />
                      </div>
                    );
                  })}
                  <div className="bg-white px-28 sm:px-52 py-20 sm:py-32 bottom-0 right-0 w-full flex justify-end">
                    <Pagination
                      count={Math.ceil(totalCount / 5)}
                      page={page}
                      onChange={handlePageChange}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                {searchText ? (
                  <div className="py-160">
                    <img className="m-auto" src="assets/images/quick-hub/noBusiness.png" alt="" />
                    <Typography className="font-bold text-20 text-center py-10">
                      {t('primaryDetails.nuBusinessFound')}
                    </Typography>
                    <Typography className="font-semibold text-16 text-center">
                      {t('primaryDetails.noBusinessFoundMessage')}
                    </Typography>
                  </div>
                ) : (
                  <div className="text-center pt-160">
                    <img className="m-auto" src="assets/images/business/businesses.png" alt="" />
                    <Typography className="font-bold text-24 pt-12">
                      {t('businesses.noBusinessAvailable')}
                    </Typography>
                    <Typography className="font-semibold text-16 pt-12">
                      {t('businesses.noBusinessAvailableMessage')}
                    </Typography>
                    <Button
                      className="bg-darkgreen text-white font-semibold mt-16 w-192 rounded-md hover:bg-darkgreen px-4 text-12 xs:text-14 xs:px-20"
                      onClick={() => handleAddBusiness()}
                    >
                      <AddIcon className="text-16 xs:text-24" />
                      {t('manageBusiness.button.addBusiness')}
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="flex flex-wrap justify-end mt-16 gap-16">
            <Button
              className="rounded-md w-160 text-quick-hub font-bold text-16"
              variant="outlined"
              onClick={() => handleClear()}
            >
              {t('common.cancel')}
            </Button>
            <Button
              className="rounded-md w-160 bg-quick-hub hover:bg-quick-hub text-white font-bold text-16"
              variant="contained"
              onClick={() => onSubmit()}
              disabled={refreshIcon || isLabelNameEmpty || !labelDetails?.colorCode}
            >
              {editLabels ? t('manageLabels.saveAndApply') : t('common.save')}
              {refreshIcon && (
                <CircularProgress className="text-quick-hub absolute mx-auto" size={24} />
              )}
            </Button>
          </div>
        </div>
      </Drawer>
      <ConformationDialogBox
        open={openDialogue}
        onClose={handleDeleteClosePopUp}
        additionalInfo={t('manageLabels.deleteMessage')}
        onDelete={deleteLabel}
        confirmationText={t('quickCampaignsContacts.confirmMessage')}
        deleteImage="/assets/images/icon/cancelPlan.png"
        backgroundColor="quick-hub"
        textColor="quick-hub"
        buttonText={t('common.delete')}
      />
      {(planAvailableDialog || openSelectProductDialog) && (
        <CheckPlanForAddNewBusiness
          openSelectProductDialog={openSelectProductDialog}
          planAvailableDialog={planAvailableDialog}
          userAvailablePlan={userAvailablePlan}
          setOpenSelectProductDialog={setOpenSelectProductDialog}
          setPlanAvailableDialog={setPlanAvailableDialog}
          setUserAvailablePlan={setUserAvailablePlan}
        />
      )}
    </>
  );
};

export default CreateLabels;
