import { Button } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { selectDashboardData } from 'app/store/userSlice';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import quickPostQuery from '../../query/quickPost';
import { handleApiRequest } from '../../common/common';
import quickChatQuery from '../../query/quickChat';

const QuickChatFeatures = ({
  setActiveStep,
  surpriseMeCardData,
  allFeaturesTrialDataId,
  chatTrialDone,
}) => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [quickChatDetails, setQuickChatDetails] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    surpriseMeCardData();
  }, [surpriseMeCardData]);

  const fetchQuickChatDetails = async () => {
    if (userCurrentBusinessDetails?.id) {
      const payloadData = {
        query: quickChatQuery.getQuickChatDetails,
        variables: {
          businessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const resultData = await handleApiRequest(payloadData);
      if (resultData?.getQuickChatDetails?.status === 200) {
        setQuickChatDetails(resultData?.getQuickChatDetails?.data);
        return resultData?.getQuickChatDetails?.data;
      }
    }
    return null;
  };

  const openChatBot = async () => {
    if (userCurrentBusinessDetails?.isOnlineBusiness) {
      enqueueSnackbar(t('createPost.onlineBusinessDesc'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }

    const details = await fetchQuickChatDetails();
    if (details?.domain) {
      const payload = {
        query: quickPostQuery.updateSurpriseMeCardData,
        variables: {
          quickHubSurpriseCardId: Number(allFeaturesTrialDataId),
          planType: 'chat',
        },
      };

      try {
        await handleApiRequest(payload);
        window.open(
          `${process.env.REACT_APP_PORTAL_URL}/quickchat/chat-assistant/${details.domain}`,
          '_blank'
        );
        surpriseMeCardData();
      } catch (error) {
        console.error('Error in openChatBot:', error);
      }
    } else {
      enqueueSnackbar(t('createPost.quickChatDetailsError'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <div className="flex flex-col items-center bg-white p-20">
      <img
        className="w-384 h-auto m-auto border-1 border-grey-300 rounded-8"
        src="/assets/video/surpriseMeChat.gif"
        alt=""
      />
      <div className="flex flex-col sm:flex-row justify-center w-full gap-14 mt-10">
        <Button
          className="md:text-18  mt-10 px-36 sm:px-60 font-semibold disabled:text-black rounded-md"
          variant="outlined"
          color="secondary"
          type="button"
          onClick={() => setActiveStep(3)}
        >
          {t('manageBusiness.button.next')}
        </Button>
        <Button
          className="text-14 mt-10 px-14 font-medium disabled:text-black disabled:bg-gray-300 rounded-md"
          variant="contained"
          color="secondary"
          type="button"
          onClick={openChatBot}
          disabled={chatTrialDone}
        >
          {t('createPost.testYourChatbot')}
        </Button>
      </div>
    </div>
  );
};

export default QuickChatFeatures;
