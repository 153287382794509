import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
} from '@mui/material';
import { t } from 'i18next';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import React from 'react';

const DeleteBox = ({
  open,
  onClose,
  onDelete,
  confirmationText,
  additionalInfo,
  deleteImage,
  refresh,
  backgroundColor,
  textColor,
  buttonText,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-confirmation-dialog"
      classes={{
        paper: `m-24 p-28 business-type-dropdown border-t-8 border-solid text-center border-t-${textColor} border-${textColor} rounded-md`,
      }}
      PaperProps={{ style: { maxWidth: '533px' } }}
    >
      <IconButton className="absolute top-4 right-4" onClick={onClose} color="inherit">
        <CloseIcon className="w-16 h-16 text-grey-700" />
      </IconButton>
      <div className="overflow-scroll">
        <div className="relative mt-10">
          <img src={deleteImage} className="mx-auto mb-12" width="100" height="64" alt="..." />
        </div>
        <DialogContent className="p-0">
          <DialogTitle id="delete-confirmation-dialog" className="text-24">
            {confirmationText}
          </DialogTitle>
          <DialogContent className="p-0">
            <div className="block w-full flex justify-center items-center mb-20">
              <Typography className="text-16 font-500 text-black w-11/12">
                {additionalInfo}
              </Typography>
            </div>
          </DialogContent>
          <DialogActions className="p-0 flex justify-between gap-14">
            <Button
              className={`md:text-16 font-medium rounded-md !border-${backgroundColor} text-${textColor} w-full max-w-256`}
              variant="outlined"
              color="secondary"
              onClick={onClose}
              style={{
                borderColor: backgroundColor,
              }}
            >
              {t('common.cancel')}
            </Button>

            <Button
              className={`md:text-16 font-medium rounded-md bg-${backgroundColor} w-full max-w-256`}
              variant="contained"
              disabled={refresh}
              color="secondary"
              onClick={onDelete}
            >
              {buttonText}
              {refresh && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          </DialogActions>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default DeleteBox;
