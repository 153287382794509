import { Button, Menu, MenuItem } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import history from '@history';
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { selectDashboardData } from 'app/store/userSlice';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import ConformationDialogBox from 'src/app/main/CommonComponent/ConformationDialogBox';
import { useSelector } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import quickSocialQuery from '../../query/quickSocial';
import { handleApiRequest } from '../../common/common';
import businessData from '../../query/business';
import TemplatePopUp from './TemplatePopUp';
import SchedulePopUp from './SchedulePopUp';

const Scheduled = () => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [openDialogueForDelete, setOpenDialogueForDelete] = useState(false);
  const [scheduledData, setScheduledData] = useState([]);
  const [expandedPosts, setExpandedPosts] = useState({});
  const [anchorEls, setAnchorEls] = useState(null);
  const [openTemplatePopUp, setOpenTemplatePopUp] = useState(false);
  const [selected, selectedCard] = useState();
  const [scheduledDate, setScheduledDate] = useState();
  const [scheduledTime, setScheduledTime] = useState();
  const [popUp, setPopUp] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const getScheduledData = useCallback(async () => {
    const payloadData = {
      query: quickSocialQuery.getQuickSocialSchedulePost,
      variables: {
        userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
      },
    };
    const resultData = await handleApiRequest(payloadData);
    if (resultData?.getQuickSocialSchedulePost?.status === 200) {
      const data = resultData?.getQuickSocialSchedulePost?.data;
      if (Array.isArray(data)) {
        setScheduledData(data);
      } else {
        setScheduledData([]);
      }
    }
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      getScheduledData();
    }
  }, [getScheduledData, userCurrentBusinessDetails?.id]);

  const deleteScheduledPost = useCallback(
    async (id) => {
      setLoading(true);
      const payloadData = {
        query: businessData.deleteQuickSocialSchedulePost,
        variables: {
          schedulePostId: id,
        },
      };
      const resultData = await handleApiRequest(payloadData);
      setLoading(false);
      if (resultData?.deleteQuickSocialSchedulePost?.status === 200) {
        enqueueSnackbar(resultData?.deleteQuickSocialSchedulePost?.message, {
          variant: 'success',
          autoHideDuration: 2000,
        });
        getScheduledData();
        setOpenDialogueForDelete(false);
      } else {
        enqueueSnackbar(resultData?.deleteQuickSocialSchedulePost?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    },
    [enqueueSnackbar, getScheduledData, setLoading]
  );

  const handleCloseDeleteDialog = () => {
    setPopUp(false);
    setOpenDialogueForDelete(false);
  };

  const formatTimeFromDate = (dateString) => {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${formattedMinutes} ${ampm}`;
  };

  const formatDateTime = (date, time) => {
    const originalDate = new Date(date);
    const formattedDat = originalDate?.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const [month, day, year] = formattedDat.split('/');
    const desiredFormat = `${year}-${month}-${day}`;
    const setTime = time.toLocaleTimeString();
    const dateTimeString = `${desiredFormat} ${setTime}`;
    const dateObj = new Date(dateTimeString);
    dateObj?.setHours(dateObj.getHours() + 5);
    dateObj?.setMinutes(dateObj.getMinutes() + 30);
    const offset = dateObj.getTimezoneOffset();
    const hoursOffset = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0');
    const minutesOffset = String(Math.abs(offset) % 60).padStart(2, '0');
    const sign = offset > 0 ? '-' : '+';
    const formattedDate = dateObj
      ?.toISOString()
      ?.replace(/:\d{2}\.\d{3}Z$/, '')
      ?.concat(`${sign}${hoursOffset}:${minutesOffset}`);
    return formattedDate;
  };

  const setSchedule = async () => {
    try {
      setRefresh(true);
      const payload = {
        query: quickSocialQuery.updateQuickSocialSchedulePost,
        variables: {
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          scheduleDate: formatDateTime(scheduledDate, scheduledTime) || selected?.scheduleDate,
          description: selected?.postData?.caption,
          editableText: selected?.editableText,
          html: selected?.html,
          schedulePostId: selected?.id,
          sharedChannels: selected?.sharedChannels?.map((ele) => ele?.id),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.updateQuickSocialSchedulePost?.status === 200) {
        getScheduledData();
        enqueueSnackbar(result?.updateQuickSocialSchedulePost?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setPopUp(false);
      }
    } catch (error) {
      console.error('Error regenerating suggestion:', error);
    } finally {
      setRefresh(false);
    }
  };

  const handleEdit = (item) => {
    history.push('/quick-social/post', {
      data: item,
      from: 'schedule',
    });
  };
  const toggleExpandPost = (postId) => {
    setExpandedPosts((prevExpanded) => ({
      ...prevExpanded,
      [postId]: !prevExpanded[postId],
    }));
  };

  const handleClickEventToCreatePost = (event) => {
    setAnchorEls(event.currentTarget);
  };

  const handleCloseBtn = () => {
    setAnchorEls(null);
  };

  const handleCloseTemplatePopUp = () => {
    setOpenTemplatePopUp(false);
  };

  const handleTemplatePost = () => {
    setOpenTemplatePopUp(true);
  };

  if (loading) {
    <FuseLoading />;
  }

  return (
    <div className="lg:p-24 p-16">
      <div className={`${scheduledData?.length > 0 ? 'max-w-3xl' : 'w-full'}`}>
        <p className="font-600 md:text-28 text-20 lg:mb-20 mb-16">
          {t('quickSocialScheduled.scheduled')}
        </p>
        {scheduledData?.length > 0 ? (
          scheduledData &&
          scheduledData?.map((item) => {
            const isExpanded = expandedPosts[item?.id] || false;
            return (
              <div
                key={item?.id}
                className="flex flex-col gap-20 bg-white p-10 sm:p-16 rounded mb-10"
              >
                <div className="flex justify-between items-center">
                  <div className="flex gap-10 items-center">
                    <img
                      src={
                        userCurrentBusinessDetails?.profileURL ||
                        '/assets/images/default-profile.png'
                      }
                      alt="Business Profile"
                      className="md:w-52 md:h-52 w-40 h-40 object-cover rounded-full"
                    />
                    <p className="text-14 sm:text-16 font-600 text-grey-600">
                      {userCurrentBusinessDetails?.name || 'Business Name'}
                    </p>
                  </div>
                  <div className="flex gap-10">
                    <Button className="!min-w-0 bg-gray-A500" onClick={() => handleEdit(item)}>
                      <ModeEditIcon className="text-20 sm:text-24 text-quick-social" />
                    </Button>
                    <Button
                      className="!min-w-0 bg-gray-A500"
                      onClick={() => {
                        setOpenDialogueForDelete(true);
                        setSelectedContactId(item?.id);
                      }}
                    >
                      <DeleteOutlineIcon className="text-20 sm:text-24 " />
                    </Button>
                  </div>
                </div>
                <div className="flex gap-20 sm:gap-0 justify-between items-start">
                  <div className="w-320 sm:w-480">
                    <div className="text-16 w-full font-500">
                      <p
                        className={`${
                          isExpanded ? 'whitespace-normal text-justify' : 'line-clamp-3'
                        } text-justify`}
                      >
                        {item?.postData?.caption || ''}
                      </p>
                      {item?.postData?.caption && item?.postData?.caption.length > 100 && (
                        <Button
                          className="text-blue-500 mt-2 hover:bg-transparent"
                          onClick={() => toggleExpandPost(item?.id)}
                        >
                          {isExpanded ? 'Show Less' : 'Read More'}
                        </Button>
                      )}
                    </div>
                  </div>
                  {item?.postData?.imageUrl && (
                    <img
                      src={item?.postData?.imageUrl}
                      alt="Post Preview"
                      className="sm:w-92 w-76 h-76 sm:h-92 object-cover"
                    />
                  )}
                </div>
                <div
                  onClick={() => {
                    selectedCard(item);
                    setPopUp(true);
                    setScheduledDate(new Date(item?.scheduledDate));
                    setScheduledTime(new Date(item?.scheduledDate));
                  }}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      setPopUp(true);
                    }
                  }}
                  className="border sm:flex items-center max-w-400 p-10 rounded-md border-grey-200 gap-32"
                >
                  <div className=" flex items-center gap-6">
                    <img
                      src="/assets/images/icon/DateIcon.svg"
                      alt="Date Icon"
                      className="sm:w-32 sm:h-32 w-24 h-24 object-cover"
                    />
                    <p className="text-12 sm:text-14 font-500">
                      {item?.scheduledDate
                        ? new Date(item?.scheduledDate).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric',
                          })
                        : t('quickSocialScheduled.noDateScheduled')}
                    </p>
                  </div>
                  <div className="">
                    <p className="text-12 sm:text-14 font-500">
                      {item?.scheduledDate
                        ? formatTimeFromDate(item?.scheduledDate)
                        : t('quickSocialScheduled.NoTimeAvailable')}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row gap-10">
                  {item?.sharedChannels?.length > 0 ? (
                    item?.sharedChannels.map((sharedData, sharedIndex) => (
                      <div key={sharedIndex}>
                        <img
                          src={sharedData?.logo || '/assets/images/default-channel.png'}
                          alt={sharedData?.name || 'Channel Logo'}
                          className="sm:w-32 sm:h-32 w-24 h-24 object-cover rounded-full"
                        />
                      </div>
                    ))
                  ) : (
                    <p className="text-12 text-grey-500">
                      {t('quickSocialScheduled.noChannelShared')}
                    </p>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <div className="flex w-full justify-center items-center">
            <div className="flex flex-col items-center justify-center text-center mt-112 w-400">
              <img src="assets/images/icon/socialNoSchedule.png" alt="connect" className="mb-16" />
              <h1 className="text-24 font-bold mb-16">
                {t('quickSocialScheduled.noScheduledPostsYet')}
              </h1>
              <p className="text-gray-600 font-medium mb-16">
                {t('quickSocialScheduled.noScheduledPostsYetContent')}
              </p>
              <Button
                variant="contained"
                color="quickSocial"
                className="rounded-md text-medium text-16 px-80"
                onClick={handleClickEventToCreatePost}
              >
                {t('quickSocialScheduled.createPost')}
              </Button>
              <Menu
                anchorEl={anchorEls}
                open={Boolean(anchorEls)}
                onClose={handleCloseBtn}
                classes={{ paper: 'min-w-256' }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{
                  '& .MuiPaper-root': {
                    boxShadow: '1px 1px 3px gray',
                  },
                }}
              >
                <MenuItem onClick={() => handleTemplatePost()}>
                  <BrokenImageOutlinedIcon className="text-quick-social text-20 mr-8 " />
                  {t('quickSocial.selectTemplate')}
                </MenuItem>
                <MenuItem onClick={() => history.push(`/quick-social/post`)}>
                  <CreateOutlinedIcon className="border-1 border-quick-social p-1 rounded-6 text-quick-social text-20 mr-8" />
                  {t('quickSocial.writeYourOwn')}
                </MenuItem>
              </Menu>
            </div>
            <TemplatePopUp open={openTemplatePopUp} onClose={handleCloseTemplatePopUp} />
          </div>
        )}

        {selected && popUp && (
          <SchedulePopUp
            open={popUp}
            onClose={handleCloseDeleteDialog}
            onCreatePost={setSchedule}
            backgroundColor="quick-social"
            textColor="white"
            buttonText="Schedule"
            scheduledDate={scheduledDate}
            setScheduledDate={setScheduledDate}
            scheduledTime={scheduledTime}
            setScheduledTime={setScheduledTime}
            refresh={refresh}
          />
        )}

        <ConformationDialogBox
          open={openDialogueForDelete}
          onClose={() => handleCloseDeleteDialog()}
          onDelete={() => deleteScheduledPost(selectedContactId)}
          confirmationText="Are You Sure?"
          additionalInfo={t('quickSocial.conformationMsg')}
          deleteImage="/assets/images/icon/socialDeleteIcon.png"
          backgroundColor="quick-social"
          textColor="quick-social"
          buttonText={t('common.delete')}
        />
      </div>
    </div>
  );
};

export default Scheduled;
