import {
  Button,
  Icon,
  InputAdornment,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import AddIcon from '@mui/icons-material/Add';
import Checkbox from '@mui/material/Checkbox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import businessData from 'src/app/main/query/business';
import {
  capitalizeFirstLetter,
  generateRandomColor,
  handleApiRequest,
} from 'src/app/main/common/common';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';
import AddContact from '../../AddContact';
import { ReactComponent as ContactIcon } from '../../../../../image/add-contact.svg';

const SelectContacts = (props) => {
  const {
    selectedContact,
    setSelectContact,
    setStepsCount,
    isAllPageContactSelect,
    setIsAllPageContactSelect,
    state,
  } = props;
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [openCreateDialogue, setOpenCreateDialogue] = useState(false);
  const [contacts, setContacts] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);

  const fetchContactData = useCallback(async () => {
    try {
      const payload = {
        query: businessData.getUserCampaignContact,
        variables: {
          callFrom: 'campaign',
          defaultSave: false,
          searchText: searchTerm,
          pageNumber: page,
          userBusinessId: Number(userCurrentBusinessDetails?.id),
          ...(state?.platForm === 1 && { sortBy: 'email' }),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getUserCampaignContact?.status === 200) {
        const allContacts = result.getUserCampaignContact?.data?.map((item) => ({
          ...item,
          userProfile: generateRandomColor(),
        }));
        setTotalCount(result?.getUserCampaignContact?.totalCount);
        setContacts(allContacts);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [page, searchTerm, state?.platForm, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    fetchContactData();
  }, [fetchContactData]);

  const cancelDialogue = async () => {
    setOpenCreateDialogue(false);
  };

  const handleSelectContact = (data) => {
    setSelectContact((prevContacts) => {
      const contactExists = prevContacts?.some((contact) => contact.id === data.id);
      if (contactExists) {
        return prevContacts?.filter((contact) => contact.id !== data.id);
      }
      return [...prevContacts, data];
    });
  };

  const handleSelectAllContact = () => {
    setSelectContact((prevSelected) => {
      const areAllAvailable = contacts?.every((contact) =>
        prevSelected?.some((selected) => selected?.phoneNumber === contact?.phoneNumber)
      );
      if (areAllAvailable) {
        return prevSelected?.filter((contact) => !contacts.some((c) => c?.id === contact?.id));
      }
      return [...new Set([...prevSelected, ...contacts])];
    });
  };

  const handleSearchTerm = (value) => {
    setSearchTerm(value);
    setPage(1);
  };

  const handleClearSearch = async () => {
    setSearchTerm('');
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  return (
    <div className="p-14 sm:p-24 md:w-[950px] mt-16">
      <div className="flex gap-8 items-center mt-8">
        <ArrowBackIcon onClick={() => setStepsCount(1)} />
        <Typography className="font-semibold text-20">
          {t('waba.broadcast.myBroadcast.selectContact')}
        </Typography>
      </div>
      <div className="p-14 sm:p-24 w-full overflow-scroll bg-white mt-16">
        {(contacts?.length > 0 || searchTerm) && (
          <div className="w-full flex flex-col sm:flex-row gap-8 sm:gap-16 mb-14 sm:mb-8 quick-chat">
            <TextField
              placeholder="Search name or number"
              variant="outlined"
              fullWidth
              margin="normal"
              size="small"
              className="w-full bg-gray-A500 mt-0"
              value={searchTerm}
              onChange={(e) => handleSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <Icon
                      color="quickChat"
                      className="cursor-pointer text-20"
                      onClick={handleClearSearch}
                    >
                      close
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="outlined"
              color="quickChat"
              autoFocus
              size="medium"
              className="rounded-md px-16 min-w-192 quick-chat bg-gray-A500 hover:bg-gray-A500 border border-gray-A500 hover:border-gray-A500"
            >
              <Checkbox
                checked={isAllPageContactSelect}
                onChange={() => {
                  setIsAllPageContactSelect(!isAllPageContactSelect);
                  setSelectContact(contacts);
                }}
              />
              <p className="text-16 font-medium">
                {t('quickCampaignsContacts.selectAll')}
                {isAllPageContactSelect ? `(${totalCount})` : ''}
              </p>
            </Button>
            <Button
              variant="outlined"
              color="quickChat"
              autoFocus
              onClick={() => setOpenCreateDialogue(true)}
              size="medium"
              className="rounded-md px-16 min-w-160 bg-gray-A500 hover:bg-gray-A500 border border-gray-A500 hover:border-gray-A500"
            >
              <AddIcon />
              <p className="text-16 font-medium">{t('quickCampaignsContacts.addContact')}</p>
            </Button>
          </div>
        )}
        {contacts?.length > 0 ? (
          <>
            <TableContainer
              component={Paper}
              className="rounded-md border border-solid border-grey-400 shadow-0"
            >
              <Table className="border border-solid border-t-0 min-w-480 md:min-w-586">
                <TableHead>
                  <TableRow className="bg-gray-A500">
                    <TableCell className="p-8 quick-chat sm:p-12 px-14 sm:px-24 border-b-2 border-grey-300 w-12">
                      <Checkbox
                        checked={
                          contacts.length > 0 &&
                          contacts.every((contact) =>
                            selectedContact.some((selected) => selected.id === contact.id)
                          )
                        }
                        onChange={() => handleSelectAllContact()}
                        disabled={isAllPageContactSelect}
                        className={`${
                          isAllPageContactSelect && 'opacity-50 cursor-not-allowed text-gray-400'
                        }`}
                      />
                    </TableCell>
                    <TableCell className="font-semibold p-8 sm:p-12 px-14 sm:px-24 text-14 sm:text-18 border-b-2 border-grey-300">
                      {t('common.name')}
                    </TableCell>
                    <TableCell className="font-semibold p-8 sm:p-12 px-14 sm:px-24 text-14 sm:text-18 border-b-2 border-grey-300">
                      {t('quickCampaignsContacts.contactNumber')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contacts?.map((item, index) => {
                    return (
                      <TableRow
                        key={index}
                        className="quick-chat hover:bg-quick-chat-100 hover:bg-opacity-30 h-64 border-b-2 border-grey-300"
                      >
                        <TableCell className="font-medium p-8 sm:p-12 px-14 sm:px-24">
                          <Checkbox
                            checked={
                              isAllPageContactSelect ||
                              selectedContact?.some((value) => value?.id === item?.id)
                            }
                            onChange={() => handleSelectContact(item)}
                            disabled={isAllPageContactSelect}
                            className={`${
                              isAllPageContactSelect &&
                              'opacity-50 cursor-not-allowed text-gray-400'
                            }`}
                          />
                        </TableCell>

                        <TableCell className="font-medium p-8 sm:p-12 px-14 sm:px-24 text-14 sm:text-18">
                          <div className="flex items-center gap-12">
                            <div
                              style={{
                                backgroundColor: `#${item.userProfile}33`,
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: `#${item.userProfile}`,
                                fontSize: '16px',
                                fontWeight: 'bold',
                              }}
                              className="sm:min-w-42 sm:min-h-42 min-w-28 min-h-28"
                            >
                              {item?.name?.charAt(0).toUpperCase()}
                            </div>
                            <Typography className="font-medium text-16">
                              {capitalizeFirstLetter(item?.name)}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell className="font-medium p-8 sm:p-12 px-14 sm:px-24 text-14 sm:text-18">
                          {`${item?.countryCode} ${item?.phoneNumber}`}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="bg-white px-24 sm:px-52 py-20 sm:py-32 bottom-0 right-0 w-full flex justify-end">
              <Pagination
                count={Math.ceil(totalCount / 10)}
                page={page}
                onChange={handlePageChange}
                showFirstButton
                showLastButton
              />
            </div>
          </>
        ) : (
          <div className="flex flex-col h-full justify-center items-center py-160">
            <div className="text-center">
              <ContactIcon className="fill-quick-chat m-auto" />
              <Typography className="py-8 font-semibold text-20">
                {t('quickCampaign.campaign.noContact')}
              </Typography>
              <Typography className="font-normal max-w-400">
                {t('quickCampaign.campaign.noContactAddMessage')}
              </Typography>
              <Button
                variant="contained"
                color="quickChat"
                autoFocus
                onClick={() => setOpenCreateDialogue(true)}
                size="medium"
                className="rounded-md mt-16 px-60"
              >
                <AddIcon />
                <p className="text-16 font-medium">{t('quickCampaignsContacts.addContact')}</p>
              </Button>
            </div>
          </div>
        )}
      </div>
      {contacts?.length > 0 && (
        <div className="mt-24 flex flex-wrap gap-12 justify-end items-center">
          <div className="text-end flex flex-col sm:flex-row gap-10 justify-normal sm:justify-end">
            <Button
              variant="outlined"
              color="quickChat"
              autoFocus
              className="rounded-md font-semibold text-16 px-32 md:px-76 md:py-12"
              // onClick={() => handleCreateTemplate()}
              size="medium"
            >
              {t('dashboard.dashboardSales.selected')}{' '}
              {isAllPageContactSelect
                ? `(${totalCount})`
                : selectedContact?.length > 0
                ? `(${selectedContact.length})`
                : ''}
            </Button>
            <Button
              variant="contained"
              color="quickChat"
              autoFocus
              onClick={() => setStepsCount(3)}
              size="medium"
              disabled={selectedContact?.length === 0 && !isAllPageContactSelect}
              className="rounded-md font-semibold text-16 px-40 md:px-76 disabled:opacity-40 disabled:bg-quick-chat disabled:text-white"
            >
              {t('quickCampaignsContacts.next')}
            </Button>
          </div>
        </div>
      )}
      <AddContact
        openValue={openCreateDialogue}
        cancelCall={cancelDialogue}
        type="add"
        popUpMessage={t('waba.broadcast.contactList.addContact')}
        popUpButtonName={t('waba.broadcast.contactList.addContact')}
        borderColor="quick-chat"
        textColor="quick-chat"
        callFrom="whatsapp"
        getData={fetchContactData}
        // isEmailRequired={createCampaignDetails?.platForm === 1}
      />
    </div>
  );
};

export default SelectContacts;
