import React, { useCallback, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { t } from 'i18next';
import moment from 'moment-timezone';
import ConformationDialogBox from 'src/app/main/CommonComponent/ConformationDialogBox';
import { useSnackbar } from 'notistack';
import Checkbox from '@mui/material/Checkbox';
import FilterListIcon from '@mui/icons-material/FilterList';
import FuseLoading from '@fuse/core/FuseLoading';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import SmsIcon from '@mui/icons-material/Sms';
import MessageIcon from '@mui/icons-material/Message';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import { selectDashboardData } from 'src/app/store/userSlice';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { useLocation, useParams } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/PersonOutlineOutlined';
import history from '@history';
import { useSelector } from 'react-redux';
import {
  Button,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Drawer,
  Pagination,
  Dialog,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import manageBusinessData from '../../../query/manageBusiness';
import { formatDateWithTimeZone, handleApiRequest } from '../../../common/common';
import Template from '../template/Template';
import UserProfile from '../../../common/userProfile';

const Campaign = () => {
  const params = useParams();
  const location = useLocation();
  const [timeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [selectedTab, setSelectedTab] = useState(location?.state?.selectTab || 0);
  const [campaignData, setCampaignData] = useState('');
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [messageType, setMessageType] = useState(location?.state?.messageType);
  const [selectedData, setSelectedData] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [details, setDetails] = useState('');
  const [filterIds, setFilterIds] = useState([]);
  const [engagementData, setEngagementData] = useState('');
  const [contactData, setContactData] = useState('');
  const [hoveredRow, setHoveredRow] = useState(null);
  const [isDelPopupOpen, setIsDelPopupOpen] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [page, setPage] = useState(1);
  const [openCreateCampaignDialog, setOpenCreateCampaignDialog] = useState(false);
  const [searchTextValue, setSearchTextValue] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [contactDataTotalCount, setContactDataTotalCount] = useState();
  const _ = require('lodash');

  const platFormType = [
    { id: 1, type: 'SMS' },
    { id: 2, type: 'Email' },
    { id: 3, type: 'Whatsapp' },
  ];
  const statusType = [
    { id: 4, type: 'Scheduled', status: 'scheduled' },
    { id: 5, type: 'Completed', status: 'sent' },
  ];

  const isInviteReview =
    selectedData?.campaign_services_standard_messages?.title?.split(' ').slice(0, 2).join(' ') ===
    'Invite Review';

  const getCampaignData = useCallback(async () => {
    try {
      if (!searchTextValue) {
        setIsLoading(true);
      }
      const [statusTypes, otherTypes] = _.partition(filterIds, (item) => [4, 5].includes(item?.id));

      const payload = {
        query: manageBusinessData.getSendCampaignMessages,
        variables: {
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          messageType,
          isCallFromHomePage: false,
          pageNumber: page,
          rowsPerPage: 10,
          filterPlatforms: _.map(otherTypes, 'type'),
          status: _.map(statusTypes, 'status'),
          searchText: searchTextValue,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getSendCampaignMessages?.status === 200) {
        const data = result.getSendCampaignMessages?.data;
        if (location.state) {
          const findSelectedData = data?.find((item) => item.id === location.state?.data?.id);
          if (findSelectedData) {
            setSelectedData(findSelectedData);
            setIsPopupOpen(true);
          }
          history.replace({
            pathname: location.pathname,
            state: null,
          });
        }
        setCampaignData(data);
        setTotal(result?.getSendCampaignMessages?.totalCount);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setIsLoading(false);
    }
  }, [
    searchTextValue,
    _,
    filterIds,
    userCurrentBusinessDetails?.id,
    messageType,
    page,
    location.state,
    location.pathname,
  ]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      getCampaignData();
    }
  }, [getCampaignData, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    const getDetailData = async () => {
      if (!selectedData?.id) return;
      try {
        const payload = {
          query: manageBusinessData.getAllQueueMessages,
          variables: {
            campaignServicesId: parseInt(selectedData?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getAllQueueMessages?.status === 200) {
          setDetails(result?.getAllQueueMessages?.data);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    };
    getDetailData();
  }, [selectedData?.id]);

  useEffect(() => {
    const getDetailData = async () => {
      if (!selectedData?.id) return;
      try {
        const payload = {
          query: manageBusinessData.getCampaignEngagement,
          variables: {
            campaignServicesId: parseInt(selectedData?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getCampaignEngagement?.status === 200) {
          setEngagementData(result?.getCampaignEngagement?.data);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    };
    getDetailData();
  }, [selectedData?.id]);

  useEffect(() => {
    const getDetailData = async () => {
      if (!selectedData?.id) return;
      try {
        const payload = {
          query: manageBusinessData.getQueueMessagesContactData,
          variables: {
            campaignServicesId: parseInt(selectedData?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getQueueMessagesContactData?.status === 200) {
          setContactData(result?.getQueueMessagesContactData?.data);
          setContactDataTotalCount(result?.getQueueMessagesContactData?.totalCount);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    };
    getDetailData();
  }, [selectedData?.id]);

  const deleteScheduledCampaign = async () => {
    try {
      setIsLoading(true);
      const payload = {
        query: manageBusinessData.deleteDraftAndScheduleCamping,
        variables: {
          campaignServicesId: parseInt(popupData?.id, 10),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.deleteDraftAndScheduleCamping?.status === 200) {
        setIsPopupOpen(false);
        enqueueSnackbar(result?.deleteDraftAndScheduleCamping?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setIsDelPopupOpen(false);
        setIsPopupOpen(false);
        getCampaignData();
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setPage(1);
    setSelectedTab(newValue);

    switch (newValue) {
      case 0:
        setMessageType(null);
        break;
      case 1:
        setMessageType('scheduled');
        break;
      case 2:
        setMessageType('draft');
        break;
      default:
        setMessageType(null);
        break;
    }
  };

  const handleRowClick = (data) => {
    if (selectedTab === 0 || selectedTab === 1) {
      setSelectedData(data);
      setIsPopupOpen(true);
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedData(null);
  };
  const handleDelClosePopUp = () => {
    setIsDelPopupOpen(false);
  };

  const handleDeleteClick = (data) => {
    setPopupData(data);
    setIsDelPopupOpen(true);
  };
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSelectFilter = (data) => {
    setFilterIds((prevFilterIds) =>
      prevFilterIds.some((item) => item?.id === data?.id)
        ? prevFilterIds.filter((item) => item?.id !== data?.id)
        : [...prevFilterIds, data]
    );
  };

  const handleClearFilter = () => {
    setFilterIds([]);
  };

  const handleFilterPlatFrom = (data) => {
    setFilterIds((prevFilterIds) => prevFilterIds.filter((item) => item?.id !== data?.id));
  };

  if (isLoading) {
    return <FuseLoading />;
  }

  const handleEditDraft = (data) => {
    history.push('quick-campaign/createCampaign', data);
  };

  return (
    <div>
      <div className="lg:p-24 p-16 ">
        <div className="flex justify-between lg:mb-20 mb-16">
          <h1 className=" md:text-28 text-20 font-semibold mb-16 sm:mb-0 flex items-center">
            {t('quickCampaign.campaign.title')}
          </h1>
          <Button
            variant="contained"
            color="quickCampaign"
            type="button"
            onClick={() => setOpenCreateCampaignDialog(true)}
            size="medium"
            className="rounded-md gap-10 px-8 sm:px-20 md:py-12"
          >
            <AddIcon />
            <p className="text-14 sm:text-16 font-medium">
              {' '}
              {t('quickCampaign.campaign.createCampaign')}
            </p>
          </Button>
        </div>
        <div className="border-solid border-grey sm:flex justify-between items-center border-b-2 mb-32 overflow-x-auto quick-campaign">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="quickCampaign"
            aria-label="secondary tabs example"
          >
            <Tab
              className={`sm:text-18 font-bold mr-20 p-0 ${
                selectedTab === 0 ? 'text-quick-campaign' : 'text-black'
              }`}
              value={0}
              label={
                <div className="flex items-center gap-10">
                  <span>{t('quickCampaign.campaign.tab1')}</span>
                  {selectedTab === 0 && total ? (
                    <span className="ml-2 text-quick-campaign  border-1 border-grey rounded-full px-10">
                      {total}
                    </span>
                  ) : null}
                </div>
              }
            />
            <Tab
              className={`sm:text-18 font-bold mr-20 p-0 ${
                selectedTab === 1 ? 'text-quick-campaign' : 'text-black'
              }`}
              value={1}
              label={
                <div className="flex items-center gap-10">
                  <span>{t('quickCampaign.campaign.tab2')}</span>
                  {selectedTab === 1 && total ? (
                    <span className="ml-2 text-quick-campaign border-1 border-grey rounded-full px-10">
                      {total}
                    </span>
                  ) : null}
                </div>
              }
            />
            <Tab
              className={`sm:text-18 font-bold p-0 ${
                selectedTab === 2 ? 'text-quick-campaign' : 'text-black'
              }`}
              value={2}
              label={
                <div className="flex items-center gap-10">
                  <span>{t('quickCampaign.campaign.tab3')}</span>
                  {selectedTab === 2 && total ? (
                    <span className="ml-2 text-quick-campaign border-1 border-grey rounded-full px-10">
                      {total}
                    </span>
                  ) : null}
                </div>
              }
            />
          </Tabs>
        </div>
        {(campaignData?.length > 0 || searchTextValue || filterIds?.length > 0) && (
          <div className="flex flex-wrap justify-between items-center my-20 relative w-full">
            <Typography className="font-semibold text-20">
              {selectedTab === 0
                ? t('quickCampaign.campaign.tab1')
                : selectedTab === 1
                ? t('quickCampaign.campaign.tab2')
                : t('quickCampaign.campaign.tab3')}
            </Typography>
            <div className="lg:flex w-full sm:w-auto justify-between items-center gap-16">
              {filterIds?.length > 0 && (
                <div className="flex flex-wrap sm:flex-nowrap justify-between items-center gap-16 mb-16 sm:mb-0">
                  <div>
                    <Button
                      type="button"
                      className="text-red font-semibold text-16 hover:bg-transparent w-112"
                      onClick={() => handleClearFilter()}
                    >
                      {t('businesses.clearFilters')}
                    </Button>
                  </div>
                  {filterIds?.map((item, i) => {
                    return (
                      <div key={i}>
                        <Button
                          type="button"
                          className="font-normal text-16 border border-solid border-black hover:bg-transparent rounded-md p-10"
                          onClick={() => handleFilterPlatFrom(item)}
                        >
                          {item?.type} <CloseIcon className="text-gray text-24 ml-6" />
                        </Button>
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="w-full flex justify-between items-center gap-16 lg:mt-0 mt-12">
                <TextField
                  placeholder={t('quickCampaign.campaign.searchCampaign')}
                  variant="outlined"
                  fullWidth
                  className="bg-white my-0 quick-campaign w-full sm:w-384"
                  margin="normal"
                  size="small"
                  value={searchTextValue}
                  onChange={(e) => {
                    setSearchTextValue(e.target.value);
                    setPage(1);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: searchTextValue && (
                      <InputAdornment position="end">
                        <IconButton
                          className="hover:bg-transparent"
                          onClick={() => setSearchTextValue('')}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    sx: {
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    },
                  }}
                />
                <Button
                  type="button"
                  className="border border-solid border-black rounded-md w-40 min-w-40 p-0 h-30 min-h-30 hover:bg-white"
                  onClick={() => {
                    setShowFilterOption(true);
                  }}
                >
                  <FilterListIcon className="text-black opacity-100 text-28" />
                </Button>
              </div>
              {showFilterOption && (
                <div className="absolute right-0 sm:right-24 z-10 top-80 sm:top-16 bg-white p-16 shadow rounded-xl">
                  <div className="flex justify-between items-center w-320 mb-10">
                    <Typography className="font-bold text-18">{t('quickSocial.filter')}</Typography>
                    <Button
                      type="button"
                      className="p-0 h-8 min-h-24 w-24 min-w-24 hover:bg-white"
                      onClick={() => setShowFilterOption(false)}
                    >
                      <CloseIcon />
                    </Button>
                  </div>
                  {/* Channel */}
                  <div className="flex justify-between items-center w-320 mt-16">
                    <Typography className="font-bold text-14">
                      {t('quickSocial.channel')}
                    </Typography>
                  </div>
                  {platFormType?.map((item, i) => {
                    return (
                      <div className="flex items-center justify-between mt-16" key={i}>
                        <Typography className="font-medium text-14">{item?.type}</Typography>
                        <Checkbox
                          className="p-0"
                          checked={filterIds.some((value) => value?.id === item?.id)}
                          onChange={() => handleSelectFilter(item)}
                        />
                      </div>
                    );
                  })}
                  {/* status type  */}
                  <div className="flex justify-between items-center w-320 mt-16">
                    <Typography className="font-bold text-14">{t('quickSocial.status')}</Typography>
                  </div>
                  {statusType?.map((item, i) => {
                    return (
                      <div className="flex items-center justify-between mt-16" key={i}>
                        <Typography className="font-medium text-14">{item?.type}</Typography>
                        <Checkbox
                          className="p-0"
                          checked={filterIds?.some((value) => value?.id === item?.id)}
                          onChange={() => handleSelectFilter(item)}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
        {(selectedTab === 0 || selectedTab === 1 || selectedTab === 2) &&
          (total > 0 ? (
            <div>
              <TableContainer component={Paper} className="rounded-md border-none">
                <Table>
                  <TableHead className="border-b-2 border-black">
                    <TableRow>
                      <TableCell className="font-semi-bold text-14 sm:text-18">
                        {t('common.srNo')}
                      </TableCell>
                      <TableCell className="font-semi-bold text-14 sm:text-18">
                        {t('quickCampaign.campaign.campaignName')}
                      </TableCell>
                      <TableCell className="font-semi-bold text-14 sm:text-18">
                        {t('quickCampaign.campaign.date')}
                      </TableCell>
                      {selectedTab === 0 || selectedTab === 1 ? (
                        <TableCell className="font-semi-bold text-14 sm:text-18 w-640">
                          {t('quickCampaign.campaign.templateName')}
                        </TableCell>
                      ) : (
                        <TableCell className="font-semi-bold text-14 sm:text-18"> </TableCell>
                      )}
                      {(selectedTab === 0 || selectedTab === 1) && (
                        <TableCell className="font-semi-bold  text-14 sm:text-18">
                          {t('quickCampaign.campaign.channel')}
                        </TableCell>
                      )}
                      {selectedTab === 0 ? (
                        <TableCell className="font-semi-bold text-14 sm:text-18">
                          {t('common.status')}
                        </TableCell>
                      ) : (
                        selectedTab === 1 && (
                          <TableCell className="font-semi-bold text-14 sm:text-18">
                            {t('quickCampaign.campaign.scheduleDate')}
                          </TableCell>
                        )
                      )}
                      {selectedTab === 0 && (
                        <TableCell className="font-semi-bold text-14 sm:text-18">
                          {t('quickCampaign.campaign.recipients')}
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {campaignData?.length > 0 &&
                      campaignData?.map((data, index) => (
                        <TableRow
                          key={index}
                          onClick={() => handleRowClick(data)}
                          className="cursor-pointer hover:bg-quick-campaign-100 hover:bg-opacity-30 border-b-2"
                          onMouseEnter={() => setHoveredRow(index)}
                          onMouseLeave={() => setHoveredRow(null)}
                        >
                          <TableCell
                            className={`font-medium text-12 sm:text-16 ${
                              selectedTab === 2 && 'h-76 min-w-32 max-w-32'
                            }`}
                          >
                            {(page - 1) * 10 + index + 1}
                          </TableCell>
                          <TableCell
                            className={`font-medium text-12 sm:text-16 ${
                              selectedTab === 2 && 'h-76 min-w-32 max-w-32'
                            }`}
                          >
                            {data?.campaignTitle}
                          </TableCell>
                          <TableCell
                            className={`font-medium text-12 sm:text-16 ${
                              selectedTab === 2 && 'h-76 min-w-32 max-w-32'
                            }`}
                          >
                            {formatDateWithTimeZone(Number(data?.cts) / 1000, 'MMM DD, YYYY')}
                          </TableCell>
                          {selectedTab === 0 || selectedTab === 1 ? (
                            <TableCell className="font-medium text-12 sm:text-16">
                              {data?.campaign_services_standard_messages?.title}
                            </TableCell>
                          ) : (
                            <TableCell
                              className={`${selectedTab === 2 && 'h-76 min-w-32 max-w-32'}`}
                            >
                              {hoveredRow === index ? (
                                <div className="flex gap-16 justify-end">
                                  <div className="flex gap-12">
                                    <Button
                                      className="p-0 min-w-36 w-36 min-h-36 h-36 bg-quick-campaign-100 hover:bg-quick-campaign-100"
                                      onClick={() => handleEditDraft(data)}
                                    >
                                      <img src="assets/images/quick-hub/edit.png" alt="" />
                                    </Button>
                                    <Button
                                      className="p-0 min-w-36 w-36 min-h-36 h-36 bg-quick-campaign-100 hover:bg-quick-campaign-100"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        handleDeleteClick(data);
                                      }}
                                    >
                                      <img src="assets/images/quick-hub/delete.png" alt="" />
                                    </Button>
                                  </div>
                                </div>
                              ) : (
                                <div className="flex gap-16 ">
                                  <div className="p-3">
                                    <div className="bg-transparent rounded-full h-16 w-16" />
                                  </div>
                                  <div className=" p-3">
                                    <div className="bg-transparent rounded-full h-16 w-16" />
                                  </div>
                                </div>
                              )}
                            </TableCell>
                          )}
                          {(selectedTab === 0 || selectedTab === 1) && (
                            <TableCell className="font-medium text-12 sm:text-16">
                              {data?.campaign_platform?.name}
                            </TableCell>
                          )}
                          {selectedTab === 0 ? (
                            <TableCell className="items-center justify-center">
                              <p
                                className={`${
                                  data?.status === 'pending'
                                    ? 'bg-quick-social-100 text-quick-social border-1 border-quick-social'
                                    : data?.status === 'sent'
                                    ? 'bg-green-100 text-quick-review border-1 border-quick-review'
                                    : 'bg-yellow-100 text-quick-chat border-1 border-quick-chat'
                                } rounded-full px-4 font-medium text-12 sm:text-16 text-center flex items-center justify-center`}
                              >
                                {data?.status === 'pending'
                                  ? 'In Progress'
                                  : data?.status === 'sent'
                                  ? 'Completed'
                                  : data?.status}
                              </p>
                            </TableCell>
                          ) : (
                            selectedTab === 1 && (
                              <TableCell className="font-medium text-12 sm:text-16">
                                {formatDateWithTimeZone(Number(data?.cts) / 1000, 'MMM DD, YYYY')}
                              </TableCell>
                            )
                          )}
                          {selectedTab === 0 && (
                            <TableCell className="font-medium text-12 sm:text-16">
                              {data?.totalCustomers}
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <ConformationDialogBox
                open={isDelPopupOpen}
                onClose={handleDelClosePopUp}
                additionalInfo={t('quickCampaign.campaign.deleteCampaignContent')}
                onDelete={() => deleteScheduledCampaign()}
                confirmationText="Are You Sure?"
                deleteImage="/assets/images/icon/campaignDeleteIcon.png"
                backgroundColor="quick-campaign"
                textColor="quick-campaign"
                buttonText={t('common.delete')}
              />
            </div>
          ) : searchTextValue?.length > 0 ? (
            <div className="flex flex-col justify-center items-center mt-96 text-center">
              <div className="flex flex-col items-center max-w-640">
                <img
                  src="assets/images/icon/noCampaign.png"
                  alt="No Campaign"
                  className="h-224 w-224 mb-16"
                />
                <h1 className="text-28 font-bold mb-16">
                  {t('quickCampaign.campaign.noCampaignFound')}
                </h1>
                <p className="text-18 font-500 mb-16 text-gray-700">
                  {t('quickCampaign.campaign.noCampaignFoundContent')}
                </p>
                <Button
                  variant="contained"
                  color="quickCampaign"
                  type="button"
                  onClick={() => setOpenCreateCampaignDialog(true)}
                  size="medium"
                  className="rounded-md gap-10 px-8 sm:px-20 md:py-12"
                >
                  <AddIcon />
                  <p className="text-14 sm:text-16 font-medium">
                    {' '}
                    {t('quickCampaign.campaign.createCampaign')}
                  </p>
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center mt-96 text-center">
              <div className="flex flex-col items-center max-w-640">
                <img
                  src="assets/images/icon/noCampaign.png"
                  alt="No Campaign"
                  className="h-224 w-224 mb-16"
                />
                <h1 className="text-28 font-bold mb-16">
                  {t('quickCampaign.campaign.noCampaignYet')}
                </h1>
                <p className="text-18 font-500 mb-16 text-gray-700">
                  {selectedTab === 0
                    ? t('quickCampaign.campaign.noCampaignContent')
                    : selectedTab === 1
                    ? t('quickCampaign.campaign.noScheduleContent')
                    : t('quickCampaign.campaign.noDraftContent')}
                </p>
                <Button
                  variant="contained"
                  color="quickCampaign"
                  size="medium"
                  type="button"
                  className="mb-16 rounded-md text-16 px-44 font-500"
                  onClick={() => history.push('quick-campaign/template')}
                >
                  {t('quickCampaign.campaign.addCampaign')}
                </Button>
              </div>
            </div>
          ))}
        <Drawer anchor="right" open={isPopupOpen} onClose={handleClosePopup} className="w-1/3">
          <div className=" bg-gray-A500  max-w-[500px] h-full rounded-md">
            <div className="flex justify-between p-20 bg-white">
              <h2 className="text-20 font-bold mb-4 text-center justify-center items-center flex">
                {selectedTab === 0
                  ? t('quickCampaign.campaign.campaignDetails')
                  : selectedTab === 1
                  ? t('quickCampaign.campaign.scheduleCampaignDetails')
                  : ''}
              </h2>
              <CloseIcon onClick={handleClosePopup} className="cursor-pointer" />
            </div>

            {selectedData && (
              <div className="bg-gray-A500 p-16">
                <div className="bg-white p-16 mb-16">
                  <div className="flex justify-between mb-20 bg-white ">
                    <h2 className="text-20 font-medium max-w-288">{selectedData?.campaignTitle}</h2>
                    <p
                      className={`${
                        selectedData?.status === 'pending'
                          ? 'bg-yellow-100 text-quick-chat border-1 border-quick-chat '
                          : selectedData?.status === 'sent'
                          ? 'bg-green-100 text-quick-review border-1 border-quick-review'
                          : ''
                      } rounded-full px-10 py-5 text-16 h-28 text-center flex items-center justify-center`}
                    >
                      {selectedData?.status !== 'scheduled' ? (
                        selectedData?.status === 'pending' ? (
                          'In Progress'
                        ) : selectedData?.status === 'sent' ? (
                          'Completed'
                        ) : (
                          selectedData?.status
                        )
                      ) : messageType === 'scheduled' ? (
                        <div className="flex gap-16">
                          <button
                            type="button"
                            className="cursor-pointer bg-quick-campaign-100 rounded-full p-3 opacity-100 flex justify-center items-center"
                            aria-label="Edit"
                            onClick={() => handleEditDraft(selectedData)}
                          >
                            <EditIcon className=" h-24 w-24 text-grey-600" />
                          </button>
                          <button
                            type="button"
                            className="cursor-pointer bg-quick-campaign-100 rounded-full p-3 opacity-100 flex justify-center items-center"
                            onClick={() => handleDeleteClick(selectedData)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                handleDeleteClick();
                              }
                            }}
                            aria-label="Delete"
                          >
                            <DeleteIcon className="h-24 w-24 text-grey-600" />
                          </button>
                        </div>
                      ) : null}
                    </p>
                  </div>
                  <div className="bg-gray-A500 p-16 rounded-md mb-20 ">
                    <p className="text-16 font-500 mb-14">
                      {selectedData?.campaign_services_standard_messages?.title}
                    </p>
                    {selectedData?.campaign_platform?.id !== 2 ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: selectedData?.campaign_services_standard_messages?.htmlTemplate,
                        }}
                      />
                    ) : (
                      <p className="bg-white rounded-md p-12">
                        {selectedData?.campaign_services_standard_messages?.messages}
                      </p>
                    )}
                    <p className="text-16 font-500 my-14">{t('quickCampaign.campaign.channel')}</p>
                    <p className="bg-white p-8 rounded-md">
                      {selectedData?.campaign_platform?.id === 1 ? (
                        <EmailIcon className="text-black text-20 mx-6" />
                      ) : selectedData?.campaign_platform?.id === 2 ? (
                        <MessageIcon className="text-black text-20 mx-6" />
                      ) : (
                        <WhatsAppIcon className="text-black text-20 mx-6" />
                      )}
                      {selectedData?.campaign_platform?.name}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <p className="flex gap-11">
                      <ScheduleIcon className="text-quick-campaign" fontSize="medium" />
                      <p className="text-15 font-medium">
                        {formatDateWithTimeZone(
                          Number(selectedData?.cts) / 1000,
                          'MMMM DD, YYYY h:mmA'
                        )}
                      </p>
                    </p>
                    <p>
                      <PersonIcon className="text-quick-campaign" fontSize="medium" />
                      {selectedData?.totalCustomers}
                    </p>
                  </div>
                </div>
                {selectedTab !== 2 && (
                  <div className="bg-white rounded-md p-20 mb-20 ">
                    <div className="flex flex-row">
                      <h2 className="mb-10 font-medium text-18">
                        {t('quickCampaign.campaign.details')}
                      </h2>
                      <Tooltip
                        classes={{
                          tooltip: 'bg-quick-campaign',
                          arrow: 'text-quick-chat',
                        }}
                        title={t('quickCampaign.campaign.camStatusCount')}
                      >
                        <img
                          className="w-14 h-14 md:w-16 md:h-16 ml-10 self-center mb-5"
                          src="/assets/images/icon/tooltip_info_blue.svg"
                          alt="good"
                        />
                      </Tooltip>
                    </div>
                    <div className="sm:flex sm:gap-14 ">
                      <div className="bg-gray-A500 rounded-md p-12 sm:mb-0 mb-10 text-14 font-medium">
                        <p className="font-bold text-16">{details?.statusCountData?.recipient}</p>
                        <p className="font-semibold">{t('quickCampaign.campaign.recipient')}</p>
                      </div>
                      <div className="bg-gray-A500 rounded-md p-12 sm:mb-0 mb-10 text-14 font-medium ">
                        <p className="font-bold text-16">{details?.statusCountData?.delivered}</p>
                        <p className="font-semibold">{t('quickCampaign.campaign.delivered')}</p>
                      </div>
                      <div className="bg-gray-A500 rounded-md p-12 sm:mb-0 mb-10 text-14 font-medium">
                        <p className="font-bold text-16">{details?.statusCountData?.failed}</p>
                        <p className="font-semibold">{t('quickCampaign.campaign.failed')}</p>
                      </div>
                      <div className="bg-gray-A500 rounded-md p-12 sm:mb-0 mb-10 text-14 font-medium">
                        <p className="font-bold text-16">{details?.statusCountData?.pending}</p>
                        <p className="font-semibold">{t('quickCampaign.campaign.pending')}</p>
                      </div>
                      <div className="bg-gray-A500 rounded-md p-12 sm:mb-0 mb-10 text-14 font-medium">
                        <p className="font-bold text-16">{details?.statusCountData?.sent}</p>
                        <p className="font-semibold">sent</p>
                      </div>
                    </div>
                  </div>
                )}
                {isInviteReview && selectedTab === 0 && (
                  <div className="bg-white rounded-md p-20 mb-20 font-medium text-18">
                    <div className="flex flex-row">
                      <h2 className="mb-10">{t('quickCampaign.campaign.engagement')}</h2>
                      <Tooltip
                        classes={{
                          tooltip: 'bg-quick-campaign',
                          arrow: 'text-quick-chat',
                        }}
                        title={t('quickCampaign.campaign.camEngageCount')}
                      >
                        <img
                          className="w-14 h-14 md:w-16 md:h-16 ml-10 self-center mb-5"
                          src="/assets/images/icon/tooltip_info_blue.svg"
                          alt="good"
                        />
                      </Tooltip>
                    </div>
                    <div className="sm:flex gap-14">
                      {Array.isArray(engagementData) &&
                        engagementData?.map((item, index) => (
                          <div
                            key={index}
                            className="bg-gray-A500 rounded-md p-12 sm:mb-0 mb-10 text-14 font-medium"
                          >
                            <p className="font-bold text-16">{item?.value}</p>
                            <p className="font-semibold">{item?.key}</p>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
                <div className="bg-white rounded-md p-20">
                  <h2 className="mb-10 pb-12 border-b-3 border-dashed border-grey-300 font-medium text-18">
                    {t('quickCampaign.campaign.sendTo')}
                  </h2>
                  <h2 className="font-medium text-18">
                    {contactDataTotalCount === 1
                      ? t('quickCampaign.campaign.contact')
                      : t('quickCampaign.campaign.contacts')}{' '}
                    ( {contactDataTotalCount} )
                  </h2>
                  <div>
                    {Array.isArray(contactData) &&
                      contactData.map((item, index) => (
                        <div key={index} className="rounded-md p-12 flex justify-between">
                          <div className="sm:flex sm:gap-14">
                            <div>
                              <UserProfile
                                name={item?.name}
                                fontSize="16px"
                                fontWeight="bold"
                                height="40px"
                                width="40px"
                              />
                            </div>
                            <div>
                              <p>
                                {item?.name
                                  ?.split(' ')
                                  ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                  ?.join(' ')}
                              </p>
                              <p>
                                {selectedData?.campaign_platform?.id === 1
                                  ? item?.email
                                  : `${item?.countryCode} ${item?.phoneNumber}`}
                              </p>
                            </div>
                          </div>
                          {item?.status !== 'scheduled' && (
                            <div>
                              <p
                                className={`${
                                  item?.status === 'failed'
                                    ? 'bg-red-100 text-red'
                                    : item?.status === 'delivered'
                                    ? 'bg-green-100 text-quick-review'
                                    : ''
                                } p-5 rounded-md text-14 font-semibold text-center flex items-center justify-center`}
                              >
                                {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                              </p>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </Drawer>
      </div>
      {total > 10 && (
        <div className="bg-white px-52 py-32 bottom-0 right-0 w-full flex justify-end">
          <Pagination
            count={Math.ceil(total / 10)}
            page={page}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
          />
        </div>
      )}
      <Dialog
        open={openCreateCampaignDialog}
        classes={{
          paper: `m-24 px-24 py-16 bg-gray-A500 border-t-8 border-solid border-t-quick-campaign border-quick-campaign rounded-md`,
        }}
        maxWidth="xl"
      >
        <div>
          <IconButton
            className="absolute right-0"
            onClick={() => setOpenCreateCampaignDialog(false)}
            color="inherit"
          >
            <CloseIcon className="text-24 text-grey-600" />
          </IconButton>
          <Template showInPopUp="true" />
        </div>
      </Dialog>
    </div>
  );
};

export default Campaign;
