import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Button,
  Checkbox,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import history from '@history';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import {
  capitalizeFirstLetter,
  convertTemplateToHtml,
  handleWabaApiRequest,
} from '../../../../common/common';
import { ReactComponent as SeenIcon } from '../../../../image/seen.svg';
import wabaQuery from '../../../../query/waba';
import { DeleteTemplate } from '../common/Popup';

const ViewBroadcastDetails = () => {
  const location = useLocation();
  const template = location?.state?.template;
  const [broadCastListStatusCount, setBroadCastListStatusCount] = useState();
  const templateDynamicData = location?.state?.template?.dynamicData;
  const [broadcastContactList, setBroadcastContactList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [viewTemplate, setViewTemplate] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { enqueueSnackbar } = useSnackbar();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [contactTypeIds, setContactTypeIds] = useState([]);
  const [contactType, setContactType] = useState('');
  const [openDeleteTemplateDialogue, setDeleteTemplateDialogue] = useState(false);

  useEffect(() => {
    const bodyText = template?.templateData?.components?.find(
      (item) => item?.type === 'BODY'
    )?.text;
    const updatedBodyText = bodyText?.replace(/\{\{(\d+)\}\}/g, (match, key) => {
      const replacement = template?.dynamicData?.body?.find(
        (item) => `{{${item.key}}}` === match
      )?.description;
      return `[${replacement}]` || match;
    });
    let currentText = updatedBodyText;
    template?.dynamicData?.body?.forEach((variable) => {
      if (variable.value) {
        let formattedValue = variable.value;
        if (variable.value instanceof Date) {
          if (variable.description.includes('Date')) {
            formattedValue = variable.value.toLocaleDateString('en-GB');
          } else if (variable.description.includes('Time')) {
            formattedValue = variable.value.toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
            });
          }
        } else if (variable.type === 'phoneNumber') {
          if (variable.value?.phoneNumber) {
            formattedValue = `+${variable.value.countryCode} ${variable.value.phoneNumber.slice(
              variable.value.countryCode.length
            )}`;
          } else {
            return;
          }
        }
        const escapedName = `\\[${variable.description}\\]`;
        currentText = currentText.replace(new RegExp(escapedName, 'g'), formattedValue);
      }
    });
    setViewTemplate(currentText);
  }, [template]);

  useEffect(() => {
    try {
      const BroadCast = async () => {
        const payload = {
          query: wabaQuery.getWABroadCastPcData,
          variables: {
            waBroadcastId: Number(template?.id),
          },
        };
        const res = await handleWabaApiRequest(payload);
        if (res?.getWABroadCastPcData?.status === 200) {
          setBroadCastListStatusCount(res?.getWABroadCastPcData?.data);
          setContactTypeIds(res?.getWABroadCastPcData?.data?.sendContactIds);
          setContactType(t('waba.broadcast.myBroadcast.broadcastStatusDetails.send'));
        }
      };

      BroadCast();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [template?.id]);

  useEffect(() => {
    const getWABroadCastContactList = async () => {
      try {
        const payload = {
          query: wabaQuery.getWABroadCastContactList,
          variables: {
            contactIds: contactTypeIds,
            pageNumber: page,
            pageSize: rowsPerPage,
            waBroadcastId: Number(template?.id),
          },
        };
        const res = await handleWabaApiRequest(payload);
        if (res?.getWABroadCastContactList?.status === 200) {
          setBroadcastContactList(res?.getWABroadCastContactList?.data);
          setTotalCount(res?.getWABroadCastContactList?.totalCount);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };
    if (template?.id) {
      getWABroadCastContactList();
    }
  }, [template, page, rowsPerPage, contactTypeIds]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  function formatDuration(seconds) {
    const units = [
      { label: 'h', value: Math.floor(seconds / 3600) },
      { label: 'min', value: Math.floor((seconds % 3600) / 60) },
      { label: 'sec', value: seconds % 60 },
    ];

    return (
      units
        .filter((unit) => unit.value > 0)
        .map((unit) => `${unit.value} ${unit.label}`)
        .join(' ') || '0sec'
    );
  }

  const broadcastDetails = [
    ...(template?.isScheduled
      ? [
          {
            icon: 'schedule.svg',
            text: t('waba.broadcast.myBroadcast.broadcastStatusDetails.status'),
            bg: 'bg-white',
            textColor: 'text-black',
            value: 'Schedule',
          },
          {
            icon: 'audience.svg',
            text: t('waba.broadcast.myBroadcast.broadcastStatusDetails.audience'),
            bg: 'bg-white',
            textColor: 'text-black',
            value: totalCount,
          },
        ]
      : [
          {
            icon: 'send.svg',
            text: t('waba.broadcast.myBroadcast.broadcastStatusDetails.send'),
            bg: 'bg-quick-chat',
            textColor: 'text-white',
            value: broadCastListStatusCount?.sentCount,
            ids: broadCastListStatusCount?.sendContactIds,
          },
          {
            icon: <SeenIcon className="w-24 stroke-[#0E92FF]" />,
            text: t('waba.broadcast.myBroadcast.broadcastStatusDetails.delivered'),
            bg: 'bg-white',
            textColor: 'text-black',
            isComponent: true,
            value: broadCastListStatusCount?.deliveredCount,
            ids: broadCastListStatusCount?.deliveredContactIds,
          },
          {
            icon: <SeenIcon className="w-24 stroke-black" />,
            text: t('waba.broadcast.myBroadcast.broadcastStatusDetails.seen'),
            bg: 'bg-white',
            textColor: 'text-black',
            isComponent: true,
            value: broadCastListStatusCount?.readCount,
            ids: broadCastListStatusCount?.readContactIds,
          },
          {
            icon: 'fail.svg',
            text: t('waba.broadcast.myBroadcast.broadcastStatusDetails.failed'),
            bg: 'bg-white',
            textColor: 'text-black',
            value: broadCastListStatusCount?.failedCount,
            ids: broadCastListStatusCount?.failedContactIds,
          },
        ]),
  ];

  const broadcastDetail = [
    {
      label: t('waba.broadcast.myBroadcast.broadcastDetail.templateType'),
      value:
        capitalizeFirstLetter(
          template?.templateData?.components?.find((item) => item?.type === 'HEADER')?.format
        ) || '-',
    },
    {
      label: t('waba.broadcast.myBroadcast.broadcastDetail.templateName'),
      value: template?.name,
    },
    // { label: t('waba.broadcast.myBroadcast.broadcastDetail.totalCreditUsage'), value: '₹ 500' },
    {
      label: t('waba.broadcast.myBroadcast.broadcastDetail.campaignCreatedAt'),
      value: moment(template.cts).format('MMM DD, YYYY h.mmA'),
    },
    template?.isScheduled
      ? {
          label: t('waba.broadcast.myBroadcast.broadcastDetail.campaignScheduledAt'),
          value: moment(template.scheduledDate).format('MMM DD, YYYY h.mmA'),
        }
      : undefined,
    template?.campaignDurationInSec
      ? {
          label: t('waba.broadcast.myBroadcast.broadcastDetail.campaignDuration'),
          value: formatDuration(template?.campaignDurationInSec) || '-',
        }
      : undefined,
  ].filter((item) => item !== undefined);

  const deleteBroadCast = async () => {
    try {
      setRefreshIcon(true);
      const payload = {
        query: wabaQuery.deleteBroadcast,
        variables: {
          waBroadcastId: Number(template?.id),
        },
      };
      const res = await handleWabaApiRequest(payload);
      setRefreshIcon(false);
      if (res?.deleteBroadcast?.status === 200) {
        setDeleteTemplateDialogue(false);
        history.push('quick-chat/waba-broadcastv2');
        enqueueSnackbar(res?.deleteBroadcast?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        setDeleteTemplateDialogue(false);
        enqueueSnackbar(res?.deleteBroadcast?.message || 'Failed to delete broadcast.', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      setDeleteTemplateDialogue(false);
      console.error('Error deleting broadcast:', error);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <div className="lg:p-24 p-16">
      <div className="flex gap-8 items-center justify-between">
        <div className="flex items-center gap-8">
          <ArrowBackIcon onClick={() => history.push('quick-chat/waba-broadcast')} />
          <Typography className="font-semibold text-20">{template?.name}</Typography>
        </div>
        {template?.isScheduled && (
          <Button
            className="rounded-md w-128 bg-white hover:bg-white"
            variant="outlined"
            color="quickChat"
            onClick={() => setDeleteTemplateDialogue(true)}
          >
            {t('common.cancel')}
          </Button>
        )}
      </div>
      <div className="bg-white px-28 py-20 rounded-md mt-16 flex justify-between">
        {broadcastDetail.map((item, index) => (
          <div key={index}>
            <Typography className="font-normal text-14">{item?.label}</Typography>
            <Typography className="font-semibold text-14 pt-8">{item.value}</Typography>
          </div>
        ))}
      </div>
      <div className="mt-20 flex flex-wrap gap-20">
        {broadcastDetails.map((item, index) => {
          return (
            <div
              key={index}
              className={`p-40 ${
                item.text === contactType ? 'bg-quick-chat' : 'bg-white'
              } rounded-lg flex items-center gap-12 w-256`}
              onClick={() => {
                setContactTypeIds(item?.ids);
                setContactType(item?.text);
                setPage(1);
              }}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setContactTypeIds(item?.ids);
                  setContactType(item?.text);
                }
              }}
            >
              <div className="bg-gray-A500 w-64 h-64 flex items-center justify-center rounded-full">
                {item.isComponent ? (
                  item.icon
                ) : (
                  <img className="w-24" src={`assets/icons/${item.icon}`} alt="" />
                )}
              </div>
              <div>
                <Typography
                  className={`font-bold text-24 ${
                    item.text === contactType ? 'text-white' : 'text-black'
                  }`}
                >
                  {item?.value}
                </Typography>
                <Typography
                  className={`font-semibold text-14 ${
                    item.text === contactType ? 'text-white' : 'text-black'
                  }`}
                >
                  {item.text}
                </Typography>
              </div>
            </div>
          );
        })}
      </div>
      <div className="p-8 mt-16 flex gap-24">
        <div className="w-full lg:w-[450px] bg-white px-40 pt-16">
          <Typography className="pb-20 font-bold text-16">
            {t('waba.broadcast.myBroadcast.preview')}
          </Typography>
          <div className="relative mb-16">
            <img className="w-380" src="assets/images/quick-chat/phone.png" alt="" />
            <div className="absolute top-84 left-0 px-16 right-0 rounded-md max-w-224 xs:max-w-256 mx-auto max-h-400 overflow-scroll">
              <div className="bg-white px-12 rounded-md pt-10">
                {template?.templateData?.components?.length > 0 &&
                  template?.templateData?.components?.map((templateValue, i) => (
                    <div key={i}>
                      {templateValue?.type === 'HEADER' && (
                        <>
                          {templateValue?.format === 'TEXT' && (
                            <Typography
                              className="font-bold"
                              dangerouslySetInnerHTML={{ __html: templateValue?.text }}
                            />
                          )}
                        </>
                      )}
                      {templateValue?.format === 'IMAGE' && (
                        <>
                          {templateDynamicData?.header?.map((dynamicValue) => (
                            <>
                              {dynamicValue?.type === 'image' && (
                                <img
                                  src={dynamicValue?.value}
                                  className="mb-10 rounded-md w-full h-144 object-cover shadow-md"
                                  alt="..."
                                />
                              )}
                            </>
                          ))}
                        </>
                      )}
                      {templateValue?.type === 'BODY' && (
                        <Typography
                          dangerouslySetInnerHTML={{
                            __html: convertTemplateToHtml(viewTemplate?.replace(/\n/g, '<br>')),
                          }}
                        />
                      )}
                      {templateValue?.type === 'FOOTER' && (
                        <Typography dangerouslySetInnerHTML={{ __html: templateValue?.text }} />
                      )}
                    </div>
                  ))}
              </div>
              {templateDynamicData?.components?.length > 0 &&
                templateDynamicData?.components?.map((templateValue, i) => (
                  <div key={i}>
                    {templateValue?.type === 'BUTTONS' && (
                      <>
                        {templateValue?.buttons?.map((buttonValue, b) => (
                          <div className="bg-white shadow p-4 rounded-md mt-4 h-32 text-center">
                            <a
                              className="font-semibold text-[#0096DE] text-16"
                              href={`${
                                buttonValue?.phone_number
                                  ? `tel:${buttonValue?.phone_number}`
                                  : buttonValue?.url
                              }`}
                              rel="noreferrer"
                              target="_blank"
                              role="button"
                              key={b}
                            >
                              <img
                                src={
                                  buttonValue?.phone_number
                                    ? '/assets/images/business/telephone.png'
                                    : '/assets/images/business/view.png'
                                }
                                className="w-14 h-14 align-middle mr-6 inline-block relative -top-1"
                                alt="..."
                              />
                              {buttonValue?.text}
                            </a>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
        {broadcastContactList?.length > 0 && (
          <div className="bg-white p-16 w-full">
            <TableContainer component={Paper} className="rounded-md border-none shadow-0">
              <Table className="border border-solid border-t-0 min-w-480 md:min-w-586">
                <TableHead>
                  <TableRow className="bg-gray-A500">
                    <TableCell className="p-8 text-semibold quick-campaign sm:p-12 px-14 sm:px-24 border-b-2 border-grey-300 w-12" />
                    <TableCell className="font-medium p-8 sm:p-12 px-14 sm:px-24 text-14 sm:text-18 border-b-2 border-grey-300">
                      {t('common.name')}
                    </TableCell>
                    <TableCell className="font-semibold p-8 sm:p-12 px-14 sm:px-24 text-14 sm:text-18 border-b-2 border-grey-300">
                      {t('quickCampaignsContacts.contactNumber')}
                    </TableCell>
                    <TableCell className="font-semibold p-8 sm:p-12 px-14 sm:px-24 text-14 sm:text-18 border-b-2 border-grey-300">
                      {t('quickCampaignsContacts.sendAt')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {broadcastContactList?.map((item, index) => {
                    return (
                      <TableRow
                        key={index}
                        className="quick-campaign hover:bg-quick-campaign-100 hover:bg-opacity-30 h-64 border-b-2 border-grey-300"
                      >
                        <TableCell className="font-medium p-8 sm:p-12 px-14 sm:px-24">
                          {(page - 1) * 10 + index + 1}
                        </TableCell>
                        <TableCell className="font-medium p-8 sm:p-12 px-14 sm:px-24 text-14 sm:text-18">
                          <div className="flex items-center gap-12">
                            <Typography className="font-medium text-16">
                              {capitalizeFirstLetter(item?.customerName)}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell className="font-medium p-8 sm:p-12 px-14 sm:px-24 text-14 sm:text-18">
                          {item?.phoneNumber}
                        </TableCell>
                        <TableCell className="font-medium p-8 sm:p-12 px-14 sm:px-24 text-14 sm:text-18">
                          {moment(template.messageSendCts).format('MMM DD, YYYY h.mmA')}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
      {broadcastContactList?.length > 0 && (
        <div className="px-52 py-32 bottom-0 right-0 w-full flex justify-end">
          <Pagination
            count={Math.ceil(totalCount / 10)}
            page={page}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
          />
        </div>
      )}
      <DeleteTemplate
        setDeleteTemplateDialogue={setDeleteTemplateDialogue}
        openDeleteTemplateDialogue={openDeleteTemplateDialogue}
        handleDelete={deleteBroadCast}
        refreshIcon={refreshIcon}
        deleteMessage={t('waba.broadcast.myBroadcast.deleteBroadcast')}
      />
    </div>
  );
};

export default ViewBroadcastDetails;
