import { Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import BroadcastDetails from './BroadcastDetails';
import SelectContacts from './SelectContact';
import { ReactComponent as DetailsIcon } from '../../../../../image/edit.svg';
import { ReactComponent as ContactIcon } from '../../../../../image/contact.svg';
import { ReactComponent as CampaignIcon } from '../../../../../image/campaign.svg';
import { ReactComponent as PostIcon } from '../../../../../image/post.svg';
import TestCampaign from './TestCampaign';
import PostOrSchedule from './PostOrSchedule';

const CreateBroadcastDetails = () => {
  const steps = [
    {
      name: t('waba.broadcast.myBroadcast.broadcastDetails'),
      imageUrl: DetailsIcon,
      class: 'fill-black',
      quickChatClass: 'fill-quick-chat',
    },
    {
      name: t('waba.broadcast.myBroadcast.selectContact'),
      imageUrl: ContactIcon,
      class: 'stroke-black',
      quickChatClass: 'stroke-quick-chat',
    },
    {
      name: t('waba.broadcast.myBroadcast.testCampaign'),
      imageUrl: CampaignIcon,
      class: 'fill-black',
      quickChatClass: 'fill-quick-chat',
    },
    {
      name: t('waba.broadcast.myBroadcast.postSchedule'),
      imageUrl: PostIcon,
      class: 'stroke-black',
      quickChatClass: 'stroke-quick-chat',
    },
  ];
  const location = useLocation();
  const [template, setTemplate] = useState(location?.state?.template);
  const [stepsCount, setStepsCount] = useState(1);
  const [selectedContact, setSelectContact] = useState([]);
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [campaignName, setCampaignName] = useState(template?.name);
  const [isAllPageContactSelect, setIsAllPageContactSelect] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [viewTemplate, setViewTemplate] = useState(location?.state?.template?.updatedBodyText);

  useEffect(() => {
    const variables = template?.dynamicData?.body?.map((item) => ({
      ...item,
      description: item.description?.replace(/\[|\]/g, ''),
      value: item?.value || '',
    }));
    const header = template?.dynamicData?.header?.find((item) => item?.type === 'image');
    setImageUrl(header?.value);
    setSelectedVariables(variables);
  }, [template, setSelectedVariables]);

  useEffect(() => {
    const replaceTemplateVariables = () => {
      return template?.updatedBodyText.replace(/\[\s*([^\]]+)\s*\]/g, (match, p1) => {
        const variable = selectedVariables?.find((v) => v.description === p1);
        if (!variable || !variable.value) return match;
        let formattedValue = variable.value;
        if (variable.type === 'date') {
          formattedValue = new Date(variable.value).toLocaleDateString('en-GB');
        } else if (variable.type === 'time') {
          formattedValue = new Date(variable.value).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
          });
        } else if (variable.type === 'phoneNumber') {
          formattedValue = `+${variable.value.slice(0, 2)} ${variable.value.slice(2)}`;
        }
        return formattedValue;
      });
    };
    const updatedTemplate = replaceTemplateVariables();
    setViewTemplate(updatedTemplate);
  }, [selectedVariables, template?.updatedBodyText]);

  return (
    <div className="lg:p-24 p-16">
      <Typography
        initial={{ x: -20 }}
        animate={{ x: 0, transition: { delay: 0.2 } }}
        delay={300}
        className="sm:flex md:text-28 text-20 font-bold mb-16 md:mb-0"
      >
        {t('waba.broadcast.myBroadcast.createBroadcast')}
      </Typography>
      <div className="flex justify-between items-center mt-20 p-10 sm:p-20 bg-white text-center rounded-md">
        {steps?.map((item, index) => {
          return (
            <>
              <div
                className={`flex items-center flex-col md:flex-row gap-8 ${
                  index < stepsCount ? 'text-quick-chat' : ''
                }`}
                key={index}
              >
                <item.imageUrl
                  className={`w-28 h-28 ${index < stepsCount ? item?.quickChatClass : item.class}`}
                />{' '}
                <Typography className="text-10 font-semibold sm:text-16 text-12 md:mr-20 md:whitespace-nowrap">
                  {item?.name}
                </Typography>
              </div>
              {index < steps.length - 1 && (
                <hr
                  className={`border h-2 w-full border-solid ${
                    index + 1 < stepsCount ? '!border-quick-chat' : ''
                  } mx-16`}
                />
              )}
            </>
          );
        })}
      </div>
      {stepsCount === 1 && (
        <BroadcastDetails
          template={template}
          setStepsCount={setStepsCount}
          selectedVariables={selectedVariables}
          setSelectedVariables={setSelectedVariables}
          setCampaignName={setCampaignName}
          campaignName={campaignName}
          setImageUrl={setImageUrl}
          imageUrl={imageUrl}
          setTemplate={setTemplate}
          setViewTemplate={setViewTemplate}
          viewTemplate={viewTemplate}
        />
      )}
      {stepsCount === 2 && (
        <SelectContacts
          stepsCount={stepsCount}
          selectedContact={selectedContact}
          setSelectContact={setSelectContact}
          setStepsCount={setStepsCount}
          isAllPageContactSelect={isAllPageContactSelect}
          setIsAllPageContactSelect={setIsAllPageContactSelect}
        />
      )}
      {stepsCount === 3 && (
        <TestCampaign
          setStepsCount={setStepsCount}
          template={template}
          imageUrl={imageUrl}
          campaignName={campaignName}
          selectedVariables={selectedVariables}
        />
      )}
      {stepsCount === 4 && (
        <PostOrSchedule
          template={template}
          selectedContact={selectedContact}
          setStepsCount={setStepsCount}
          selectedVariables={selectedVariables}
          setCampaignName={setCampaignName}
          campaignName={campaignName}
          isBroadcastSchedule={location?.state?.isSchedule}
          isAllPageContactSelect={isAllPageContactSelect}
          imageUrl={imageUrl}
          setSelectedVariables={setSelectedVariables}
          setViewTemplate={setViewTemplate}
          viewTemplate={viewTemplate}
          setTemplate={setTemplate}
          setImageUrl={setImageUrl}
        />
      )}
    </div>
  );
};

export default CreateBroadcastDetails;
