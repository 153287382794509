import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Tooltip, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import history from '@history';
import ReactApexChart from 'react-apexcharts';
import { selectDashboardData } from 'src/app/store/userSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import './style.css';
import { useSnackbar } from 'notistack';
import SocialPlatformTab from './socialPlatformTab';
import {
  fetchSocialConnections,
  formatDateWithTimeZone,
  handleApiRequest,
  handleFBLogin,
  handleSocialResponse,
  RenderIcon,
} from '../../common/common';
import metricsData from '../../query/metrics';
import quickSocialQuery from '../../query/quickSocial';
import NoData from '../matrices/noDataScreen';
import SelectPlatformBox from './SelectPlatformBox';
import Linkedin from './LinkedinConnect';

function Analytics() {
  const { t } = useTranslation();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [brandHealth, setBrandHealth] = useState([]);
  const [engagement, setEngagement] = useState([]);
  const [followersGrowth, setFollowersGrowth] = useState({});
  const [metricsIconInfo, setMetricsIconInfo] = useState(null);
  const [activeStep, setActiveStep] = useState();
  const [socialData, setSocialData] = useState([]);
  const [facebookUserId, setFacebookUserId] = useState();
  const [connectionData, setConnectionData] = useState([]);
  const [accessToken, setAccessToken] = useState();
  const [selectedPage, setSelectedPage] = useState([]);
  const [socialPlatformId, setSocialPlatformId] = useState(null);
  const [openDialog, setOpenDialog] = useState(true);
  const [isLinkedin, setIsLinkedin] = useState(false);
  const [openLinkedinPopUp, setOpenLinkedinPopUp] = useState(false);
  const [linkedinRefreshIcon, setLinkedinRefreshIcon] = useState(false);
  const appKey = process.env.REACT_APP_WABA_APP_ID;
  const fbConfigKey = process.env.REACT_APP_FB_CONFIG_ID;
  const inConfigKey = process.env.REACT_APP_IN_CONFIG_ID;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setBrandHealth();
  }, [activeStep]);

  const fetchAgentSupportStatus = useCallback(async () => {
    if (!activeStep || !userCurrentBusinessDetails?.id) return;
    setIsLoading(true);
    try {
      const payload = {
        query: quickSocialQuery.getQuickSocialBrandHealthMatrices,
        variables: {
          userBusinessId: Number(userCurrentBusinessDetails?.id),
          mediaChannelId: activeStep,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getQuickSocialBrandHealthMatrices?.status === 200) {
        const resultData = result?.getQuickSocialBrandHealthMatrices?.data?.brandHealthData;
        if (resultData) {
          setBrandHealth(JSON.parse(resultData));
        }
      }
      setEngagement([]);
      const payloadEngagement = {
        query: quickSocialQuery.getQuickSocialEngagementMetrics,
        variables: {
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          mediaChannelId: activeStep,
        },
      };
      const resultEngagement = await handleApiRequest(payloadEngagement);
      if (resultEngagement?.getQuickSocialEngagementMetrics?.status === 200) {
        const engagementData =
          resultEngagement?.getQuickSocialEngagementMetrics?.data?.engagementData;
        if (engagementData) {
          setEngagement(JSON.parse(engagementData));
        }
      }
      setFollowersGrowth({});
      const payloadMatrices = {
        query: quickSocialQuery.getQuickSocialMatrices,
        variables: {
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          mediaChannelId: activeStep,
        },
      };
      const resultMatrices = await handleApiRequest(payloadMatrices);
      if (resultMatrices?.getQuickSocialMatrices?.status === 200) {
        const followersGrowthData = resultMatrices?.getQuickSocialMatrices?.data;
        if (followersGrowthData) {
          setFollowersGrowth(followersGrowthData);
        }
      }
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
    }
    setIsLoading(false);
  }, [activeStep, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    fetchAgentSupportStatus();
  }, [fetchAgentSupportStatus]);

  useEffect(() => {
    try {
      const getMetricsInfoIconData = async () => {
        const payload = {
          query: metricsData.getMetricsInfoIconData,
        };
        const res = await handleApiRequest(payload);
        if (res?.getMetricsInfoIconData?.status === 200) {
          setMetricsIconInfo(res?.getMetricsInfoIconData?.data?.quick_social_info ?? null);
        }
      };
      getMetricsInfoIconData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, []);

  const handleSocialConnection = async (social) => {
    setLoading(true);
    try {
      let authResponse;
      if (social === 9 || social === 10) {
        authResponse = await handleFBLogin(appKey, fbConfigKey, inConfigKey, social);
        if (authResponse) {
          const fbAccessToken = authResponse?.accessToken;
          setFacebookUserId(authResponse?.userID);

          if (fbAccessToken && userCurrentBusinessDetails?.id) {
            await fetchSocialConnections(
              social,
              userCurrentBusinessDetails,
              setConnectionData,
              setAccessToken,
              setOpenDialog,
              setSelectedPage,
              setLoading,
              fbAccessToken,
              enqueueSnackbar
            );
          } else {
            console.log('Missing Facebook access token or business details.');
          }
        } else {
          console.log('Facebook login failed or was cancelled.');
        }
      } else if (social === 11) {
        history.push('/quick-social/social-connect/');
      } else if (social === 12) {
        setIsLinkedin(true);
        setOpenLinkedinPopUp(true);
      }
    } catch (error) {
      console.error('Error during social connection:', error);
    } finally {
      setLoading(false);
    }

    setSocialPlatformId(social);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  if (loading) {
    return <FuseLoading />;
  }

  const getChartSimple = (data, categories, index, color, types) => {
    const seriesData = {
      likes: [],
      comments: [],
      shares: [],
      engagement: [],
    };
    data.forEach((period) => {
      const likesValue = period.find((p) => p?.key === 'likes')?.value || 0;
      const commentsValue = period.find((p) => p?.key === 'comments')?.value || 0;
      const sharesValue = period.find((p) => p?.key === 'shares')?.value || 0;
      const engagementValue = period.find((p) => p?.key === 'engagement')?.value || 0;
      seriesData?.likes.push(likesValue);
      seriesData?.comments.push(commentsValue);
      seriesData?.shares.push(sharesValue);
      seriesData?.engagement.push(engagementValue);
    });
    const formattedCategories = categories?.map((category) =>
      formatDateWithTimeZone(Number(category), 'MMM DD, YYYY')
    );
    const chart = {
      series: [
        {
          name: 'Likes',
          data: seriesData?.likes,
        },
        {
          name: 'Comments',
          data: seriesData?.comments,
        },
        {
          name: 'Shares',
          data: seriesData?.shares,
        },
        {
          name: 'Engagement',
          data: seriesData?.engagement,
        },
      ],
      options: {
        grid: {
          strokeDashArray: 3,
          show: true,
        },
        chart: {
          events: {
            mounted: (charts) => {
              charts.windowResizeHandler();
            },
          },
          type: types,
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            columnWidth: '30px',
          },
        },
        legend: {
          onItemClick: {
            toggleDataSeries: false,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            radius: 12,
            shape: 'circle',
          },
        },
        colors: color,
        fill: {
          colors: color,
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          axisTicks: {
            show: false,
          },
          categories: formattedCategories,
          labels: {
            offsetY: 0,
          },
        },
        yaxis: {
          labels: {
            align: 'left',
            maxWidth: 350,
            position: 'left',
            offsetX: -15,
            formatter(val) {
              return val;
            },
            style: {
              fontSize: '14px',
            },
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: (val) => val,
          },
        },
      },
    };
    return (
      <div id={`chart${index}`} className="flex flex-col flex-auto w-full">
        <ReactApexChart
          options={chart.options}
          series={chart.series}
          type={chart.options.chart.type}
          height="300px"
          width="100%"
        />
      </div>
    );
  };

  const getChartArea = (data, categories, i, color, types) => {
    const seriesData = data?.map((item) => {
      const timeStamp = parseInt(item?.find((p) => p?.key === 'timeStamp')?.value, 10);
      const count = parseInt(item?.find((p) => p?.key === 'count')?.value, 10) || 0;
      return {
        x: formatDateWithTimeZone(Number(timeStamp), 'MMM DD, YYYY'),
        y: count,
        originalTimestamp: timeStamp,
      };
    });
    const formattedCategories = categories?.map((category) =>
      formatDateWithTimeZone(Number(category), 'MMM DD, YYYY')
    );
    const chart = {
      series: [
        {
          name: '',
          data: seriesData,
        },
      ],
      options: {
        grid: {
          strokeDashArray: 3,
          show: true,
        },
        chart: {
          events: {
            mounted: (charts) => {
              charts.windowResizeHandler();
            },
          },
          type: types,
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            columnWidth: '30px',
          },
        },
        legend: {
          onItemClick: {
            toggleDataSeries: false,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            radius: 12,
            shape: 'circle',
          },
        },
        colors: color,
        fill: {
          colors: color,
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          axisTicks: {
            show: false,
          },
          categories: formattedCategories,
          labels: {
            offsetY: 0,
            formatter: (val, index) => {
              if (index === 0 || index === categories.length - 1) {
                return val;
              }
              return '';
            },
            style: {
              fontSize: '14px',
            },
          },
        },
        yaxis: {
          labels: {
            show: false,
            align: 'left',
            maxWidth: 350,
            position: 'left',
            offsetX: -15,
            formatter(val) {
              return val;
            },
            style: {
              fontSize: '14px',
            },
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: (val, { seriesIndex, dataPointIndex }) => {
              const date = seriesData[dataPointIndex]?.originalTimestamp;
              return `Date: ${formatDateWithTimeZone(date, 'MMM DD, YYYY')}<br>Count: ${val}`;
            },
          },
        },
      },
    };
    return (
      <div id={`chart${i}`} className="flex flex-col flex-auto w-full">
        <ReactApexChart
          options={chart.options}
          series={chart.series}
          type={chart.options.chart.type}
          height="300px"
          width="100%"
        />
      </div>
    );
  };

  return (
    <div className="lg:p-24 p-16 h-full l-padding">
      <div className="flex w-full items-center justify-between lg:mb-20 mb-16">
        <div className="items-center justify-between">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex md:text-28 text-20 font-bold"
          >
            {t('quickSocial.analytics')}
          </Typography>
        </div>
      </div>
      <SocialPlatformTab
        onSelectTab={setActiveStep}
        socialData={socialData}
        setSocialData={setSocialData}
      />
      {!isLoading ? (
        <div>
          {socialData?.some(
            (item) => item?.isConnected && item?.reviewPlatformId === activeStep
          ) ? (
            <>
              {brandHealth && brandHealth?.length > 0 && (
                <>
                  <div className="w-full flex items-center justify-between">
                    <h1 className="text-20 md:text-24 font-bold mb-16">
                      {t('quickSocial.brandHealth')}
                    </h1>
                  </div>
                  <ul className="grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-x-24 mb-24 md-gap-y-0 gap-y-16">
                    {brandHealth?.map((item, index) => (
                      <li className="bg-white rounded-md md:p-32 p-16" key={index}>
                        <div className="flex items-center">
                          <img
                            className="mr-16 md:w-auto md:h-auto w-32 h-32"
                            src={
                              item?.key === 'Total Followers'
                                ? 'assets/images/quick-hub/quick-social/followers.png'
                                : item?.key === 'Total Posts'
                                ? 'assets/images/quick-hub/quick-social/posts.png'
                                : item?.key === 'Post Reach'
                                ? 'assets/images/quick-hub/quick-social/reach.png'
                                : 'assets/images/quick-hub/quick-social/engagement.png'
                            }
                            alt={item?.key}
                          />
                          <div>
                            <span className="md:text-32 text-20 font-bold block md:mb-8">
                              {item?.value}
                            </span>
                            <span className="md:text-20 text-16 font-bold block">{item?.key}</span>
                            <span className="md:text-20 text-16 font-bold block">
                              {item?.subText}
                            </span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {activeStep !== 11 && (
                <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-x-24 gap-y-16 sm:gap-y-0">
                  {followersGrowth?.followerGrowthData &&
                  followersGrowth?.followerGrowthData?.length > 0 ? (
                    <div className="bg-white rounded-md p-16">
                      <div className="w-full flex items-center justify-between">
                        <h1 className="text-20 md:text-24 font-bold mb-16">
                          {t('quickSocial.followersGrowth')}{' '}
                          <Tooltip
                            title={metricsIconInfo?.followersGrowth}
                            arrow
                            classes={{
                              tooltip: 'bg-quick-social',
                              arrow: 'text-quick-social',
                            }}
                          >
                            <span>
                              <RenderIcon textColor="quick-social" iconName="info" />
                            </span>
                          </Tooltip>
                        </h1>
                      </div>
                      <div>
                        {getChartArea(
                          followersGrowth?.followerGrowthData,
                          followersGrowth?.followerGrowthData?.map(
                            (period) => period?.find((p) => p?.key === 'timeStamp')?.value
                          ),
                          6,
                          ['#0BA861'],
                          'area'
                        )}
                      </div>
                      <div>
                        {followersGrowth?.summary && followersGrowth?.summary?.length > 0 && (
                          <div className="bg-gray-A500 p-12 rounded-md flex items-center">
                            <img
                              className="mr-16 w-52 h-52"
                              src="assets/images/quick-hub/quick-social/followers.png"
                              alt="..."
                            />
                            <div>
                              <span className="block text-20 font-semibold">
                                {followersGrowth?.summary?.find((sum) => sum?.key === 'difference')
                                  ?.value > 0
                                  ? `+${
                                      followersGrowth?.summary?.find(
                                        (sum) => sum?.key === 'difference'
                                      )?.value
                                    }`
                                  : followersGrowth?.summary?.find(
                                      (sum) => sum?.key === 'difference'
                                    )?.value}
                                (
                                {followersGrowth?.summary?.find((sum) => sum?.key === 'growthRate')
                                  ?.value > 0
                                  ? `+${
                                      followersGrowth?.summary?.find(
                                        (sum) => sum?.key === 'growthRate'
                                      )?.value
                                    }`
                                  : followersGrowth?.summary?.find(
                                      (sum) => sum?.key === 'growthRate'
                                    )?.value}
                                )
                              </span>
                              <span className="block text-16 font-medium">
                                {
                                  followersGrowth?.summary?.find((sum) => sum?.key === 'text')
                                    ?.value
                                }
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="bg-white rounded-md p-16">
                      <div className="w-full flex items-center justify-between">
                        <h1 className="text-20 md:text-24 font-bold mb-16">
                          {t('quickSocial.followersGrowth')}{' '}
                          <Tooltip
                            title={metricsIconInfo?.followersGrowth}
                            arrow
                            classes={{
                              tooltip: 'bg-quick-social',
                              arrow: 'text-quick-social',
                            }}
                          >
                            <span>
                              <RenderIcon textColor="quick-social" iconName="info" />
                            </span>
                          </Tooltip>
                        </h1>
                      </div>
                      <NoData />
                    </div>
                  )}
                  {engagement && engagement?.length > 0 ? (
                    <div className="bg-white rounded-md p-16">
                      <div className="w-full flex items-center justify-between">
                        <h1 className="text-20 md:text-24 font-bold mb-16">
                          {t('quickSocial.engagement')}
                          <Tooltip
                            title={metricsIconInfo?.engagement}
                            arrow
                            classes={{
                              tooltip: 'bg-quick-social',
                              arrow: 'text-quick-social',
                            }}
                          >
                            <span>
                              <RenderIcon textColor="quick-social" iconName="info" />
                            </span>
                          </Tooltip>
                        </h1>
                      </div>
                      <div>
                        {getChartSimple(
                          engagement,
                          engagement?.map(
                            (period) => period?.find((p) => p?.key === 'timeStamp')?.value
                          ),
                          6,
                          ['#46FF6B', '#F7E438', '#2E56F8', '#34D1F9'],
                          'bar'
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="bg-white rounded-md p-16">
                      <div className="w-full flex items-center justify-between">
                        <h1 className="text-20 md:text-24 font-bold mb-16">
                          {t('quickSocial.engagement')}
                          <Tooltip
                            title={metricsIconInfo?.engagement}
                            arrow
                            classes={{
                              tooltip: 'bg-quick-social',
                              arrow: 'text-quick-social',
                            }}
                          >
                            <span>
                              <RenderIcon textColor="quick-social" iconName="info" />
                            </span>
                          </Tooltip>
                        </h1>
                      </div>
                      <NoData />
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <div className="flex w-full justify-center items-center ">
              <div className="flex flex-col items-center justify-center text-center mt-112 w-400">
                <img
                  src="assets/images/icon/socialNotConnectIcon.png"
                  alt="connect"
                  className="mb-16"
                />
                <h1 className="text-24 font-bold mb-16">
                  {t('quickSocial.connect')}{' '}
                  {(() => {
                    const name = socialData?.find(
                      (item) => !item?.isConnected && item?.reviewPlatformId === activeStep
                    )?.name;
                    return name ? name.charAt(0).toUpperCase() + name.slice(1) : '';
                  })()}{' '}
                  {t('quickSocial.account')}
                </h1>
                <p className="text-gray-600 font-medium mb-16">
                  Your{' '}
                  {socialData
                    ?.find((item) => !item?.isConnected && item?.reviewPlatformId === activeStep)
                    ?.name?.replace(/^./, (char) => char.toUpperCase()) || ''}{' '}
                  {t('quickSocial.notConnectContent')}
                </p>
                <Button
                  variant="contained"
                  color="quickSocial"
                  className="rounded-md text-medium text-16 px-80"
                  onClick={() => handleSocialConnection(activeStep)}
                >
                  {t('quickSocial.connectNow')}
                </Button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="w-full h-512 justify-center items-center flex">
          <FuseLoading />
        </div>
      )}

      {connectionData?.length > 0 && openDialog && (
        <SelectPlatformBox
          onClose={handleClose}
          openDialog={openDialog}
          socialPlatformId={socialPlatformId}
          connectionData={connectionData}
          setSelectedPage={setSelectedPage}
          selectedPage={selectedPage}
          setOpenDialog={setOpenDialog}
          handleSocialResponse={() =>
            handleSocialResponse(
              socialPlatformId,
              accessToken,
              facebookUserId,
              selectedPage,
              userCurrentBusinessDetails,
              setLoading,
              enqueueSnackbar,
              setConnectionData,
              setOpenDialog,
              fetchAgentSupportStatus
            )
          }
          handleClose={handleClose}
        />
      )}
      {isLinkedin && (
        <Linkedin
          setIsLinkedin={setIsLinkedin}
          setLinkedinRefreshIcon={setLinkedinRefreshIcon}
          fetchAgentSupportStatus={fetchAgentSupportStatus}
          setOpenLinkedinPopUp={setOpenLinkedinPopUp}
          openLinkedinPopUp={openLinkedinPopUp}
        />
      )}
    </div>
  );
}
export default Analytics;
