import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Icon,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import Checkbox from '@mui/material/Checkbox';
import SortIcon from '@mui/icons-material/Sort';
import CloseIcon from '@mui/icons-material/Close';
import { t } from 'i18next';
import EditIcon from '@mui/icons-material/Edit';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import history from '@history';
import { useLocation } from 'react-router-dom';
import Pagination from '../../../../component/table/Pagination';
import reviewPlatformData from '../../../query/reviewPlatform';
import {
  capitalizeFirstLetter,
  generateRandomColor,
  formatDateWithTimeZone,
  getParamsId,
  handleApiRequest,
  reviewFormate,
  fetchPhysicalBusinessType,
  fetchReviewPlateForm,
  fetchOnlineBusinessType,
} from '../../../common/common';
import {
  getOnlineBusinessTypeData,
  getPhysicalBusinessTypeData,
  selectDashboardData,
  setOnlineBusinessType,
  setPhysicalBusinessType,
  setReviewPlateForm,
  setUserCurrentBusinessDetailsValue,
} from '../../../../store/userSlice';
import UserProfile from '../../../common/userProfile';
import Loading from '../../manage-business/Loading';
import manageBusinessData from '../../../query/manageBusiness';
import userData from '../../../query/user';
import userService from '../../../../services';
import NotVerifyBusinessPopUp from '../../businessCompetitor/NotVerifyBusinessPopUp';

const Reviews = () => {
  const sortData = [
    {
      id: 'reviewCts',
      idForOnline: 'cts',
      key: 'desc',
      name: 'Newest First',
    },
    {
      id: 'reviewCts',
      idForOnline: 'cts',
      key: 'asc',
      name: 'Oldest First',
    },
    {
      id: 'reviewRating',
      idForOnline: 'rating',
      key: 'asc',
      name: 'Rating -- 1 to 5',
    },
    {
      id: 'reviewRating',
      idForOnline: 'rating',
      key: 'desc',
      name: 'Rating -- 5 to 1',
    },
  ];
  const location = useLocation();
  const [timeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const { placeIdForRedirect, ratingTypeForRedirect, platformsId } = location.state || {};
  const [reviewData, setReviewData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [selectedReviewData, setSelectedReviewData] = useState();
  const [filterData, setFilterData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [placeId, setPlaceId] = useState(placeIdForRedirect);
  const [platformName, setPlatformName] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [plateFormId, setPlateFormId] = useState(platformsId);
  const [pageLoading, setPageLoading] = useState(false);
  const [platFormStarData, setPlatFormStarData] = useState('');
  const [loading, setLoading] = useState(true);
  const [zomatoRatingType, setZomatoRatingType] = useState(ratingTypeForRedirect || 'delivery');
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [totalReview, setTotalReview] = useState();
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [showSortOption, setShowSortOption] = useState(false);
  const [filterIds, setFilterIds] = useState([]);
  const [selectSortOption, setSelectSortOption] = useState(sortData[0]);
  const [generateDefaultReplyData, setGenerateDefaultReplyData] = useState([]);
  const [selectedReplyText, setSelectedReplyText] = useState();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [showOrHideReply, setShowOrHideReply] = useState();
  const [isReply, setIsReply] = useState(false);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const physicalBusinessTypeList = useSelector(getPhysicalBusinessTypeData);
  const onlineBusinessTypeList = useSelector(getOnlineBusinessTypeData);
  const [submitRefreshIcon, setSubmitRefreshIcon] = useState(false);
  const [businessTypeList, setBusinessTypeList] = useState([]);
  const [googleBusinessData, setGoogleBusinessData] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentBusinessDetails, setCurrentBusinessDetails] = useState(false);
  const loginUserData = userService.getUserData();
  const isDataAvailable = useRef(null);
  const dispatch = useDispatch();
  const filterRef = useRef(null);
  const matchedPlatform = currentBusinessDetails?.reviewplatform?.find(
    (platform) => platform?.reviewPlatformId === plateFormId
  );
  const sortRef = useRef(null);
  const userTimezone = moment.tz.guess();

  useEffect(() => {
    function handleClickOutside(event) {
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        setShowSortOption(false);
      }
    }

    if (showSortOption) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSortOption]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.isOnlineBusiness) {
      setBusinessTypeList(onlineBusinessTypeList);
    } else {
      setBusinessTypeList(physicalBusinessTypeList);
    }
  }, [
    userCurrentBusinessDetails?.isOnlineBusiness,
    onlineBusinessTypeList,
    physicalBusinessTypeList,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      setPageLoading(true);
      if (!localStorage.getItem('reviewPlateForm')) {
        const reviewPlateForm = await fetchReviewPlateForm();
        await localStorage.setItem('reviewPlateForm', JSON.stringify(reviewPlateForm));
        dispatch(setReviewPlateForm(reviewPlateForm));
      }
      if (!localStorage.getItem('physicalBusinessType')) {
        const physicalBusinessType = await fetchPhysicalBusinessType();
        await localStorage.setItem('physicalBusinessType', JSON.stringify(physicalBusinessType));
        dispatch(setPhysicalBusinessType(physicalBusinessType));
      }
      if (!localStorage.getItem('onlineBusinessType')) {
        const onlineBusinessType = await fetchOnlineBusinessType();
        await localStorage.setItem('onlineBusinessType', JSON.stringify(onlineBusinessType));
        dispatch(setOnlineBusinessType(onlineBusinessType));
      }
      setPageLoading(false);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchBusinessDetails = async () => {
      if (userCurrentBusinessDetails?.id) {
        setLoading(true);
        try {
          const payload = {
            query: manageBusinessData.getUserBusinessesGoogleDetails,
            variables: {
              businessId: Number(userCurrentBusinessDetails?.id),
            },
          };
          const result = await handleApiRequest(payload);
          setLoading(false);
          if (result?.getUserBusinessesGoogleDetails?.status === 200) {
            const data = result?.getUserBusinessesGoogleDetails?.data;
            const parsedData = JSON.parse(data);
            setGoogleBusinessData(parsedData);
          } else {
            enqueueSnackbar(result?.getUserBusinessesGoogleDetails?.message, {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
        } catch (error) {
          setLoading(false);
          console.error('Error fetching business details:', error);
        }
      }
    };
    fetchBusinessDetails();
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar]);

  useEffect(() => {
    const fetchDashBoard = async () => {
      try {
        const obj = {
          query: userData.getDashboard,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const res = await handleApiRequest(obj);
        setCurrentBusinessDetails(res?.getDashboard?.data?.[0]);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    if (userCurrentBusinessDetails?.id) {
      fetchDashBoard();
    }
  }, [userCurrentBusinessDetails?.id, dispatch]);

  const fetchPhysicalBusinessReview = useCallback(async () => {
    try {
      const payload = {
        query:
          Number(plateFormId) === 7
            ? reviewPlatformData.getOnlineBusinessReview
            : reviewPlatformData.getAllBusinessReviews,
        variables: {
          pageNumber: page,
          pageSize: rowsPerPage,
          filterReviewRating: filterIds?.map((item) => item?.ratingType),
          ...(Number(plateFormId) === 3 && {
            ratingType: zomatoRatingType,
          }),
          ...(Number(plateFormId) === 7
            ? {
                businessId: Number(userCurrentBusinessDetails?.id),
                reviewPlatformId: Number(plateFormId),
                orderBy: selectSortOption?.key,
                sortOrder: selectSortOption?.idForOnline,
              }
            : {
                platformId: Number(plateFormId),
                placeId,
                sortBy: selectSortOption?.id,
                sortOrder: selectSortOption?.key,
              }),
        },
      };
      const result = await handleApiRequest(payload);
      const res =
        Number(plateFormId) === 7 ? result?.getOnlineBusinessReview : result?.getAllBusinessReviews;

      if (res?.status === 200) {
        const data = res?.data?.map((item) => ({
          ...item,
          userProfile: generateRandomColor(),
        }));

        if (isDataAvailable.current === null) {
          isDataAvailable.current = data.length > 0;
        }

        setReviewData(data);
        setSelectedReviewData(res?.data[0]);
        Number(plateFormId) === 7
          ? setTotalCount(res?.totalReview)
          : setTotalCount(res?.totalReviews);
      } else {
        enqueueSnackbar(res?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      enqueueSnackbar('Failed to fetch reviews. Please try again.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } finally {
      setLoading(false);
    }
  }, [
    enqueueSnackbar,
    filterIds,
    page,
    placeId,
    plateFormId,
    rowsPerPage,
    selectSortOption?.id,
    selectSortOption?.idForOnline,
    selectSortOption?.key,
    userCurrentBusinessDetails?.id,
    zomatoRatingType,
  ]);

  useEffect(() => {
    if (plateFormId && (Number(plateFormId) === 7 || placeId)) {
      fetchPhysicalBusinessReview();
    } else {
      setLoading(false);
    }
  }, [fetchPhysicalBusinessReview, placeId, plateFormId]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setShowFilterOption(false);
      }
    }

    if (showFilterOption) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [fetchPhysicalBusinessReview, filterIds, showFilterOption]);

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      try {
        const { id: businessId, isOnlineBusiness } = currentBusinessDetails || {};
        if (!businessId) {
          console.error('Business ID is missing');
          return;
        }

        const variables = {
          businessId: Number(currentBusinessDetails?.id),
          ...(plateFormId !== 7 && { placeId }),
          ...(isOnlineBusiness && {
            platform: matchedPlatform?.name,
          }),
        };

        const payload = {
          query: reviewPlatformData?.getBusinessRatingFilterWise,
          variables,
        };
        const result = await handleApiRequest(payload);
        if (result?.getBusinessRatingFilterWise?.status === 200) {
          setPlatFormStarData(result?.getBusinessRatingFilterWise?.data);
        } else {
          console.error('Error in response:', result);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };
    const isConditionMet = !!(placeId && plateFormId && currentBusinessDetails?.id);
    isConditionMet ? fetchAllData() : setPlatFormStarData([]);
  }, [placeId, currentBusinessDetails, plateFormId, matchedPlatform]);

  useEffect(() => {
    if (!userCurrentBusinessDetails) return;
    const selectPlatform = plateFormId
      ? userCurrentBusinessDetails.reviewplatform?.find(
          (item) => item.reviewPlatformId === plateFormId
        )
      : userCurrentBusinessDetails.reviewplatform?.[0];
    if (!selectPlatform) return;
    const { reviewPlatformId, totalDinningReview } = selectPlatform;
    setPlateFormId(reviewPlatformId);
    setPlaceId(selectPlatform?.placeId);
    setPlatformName(selectPlatform?.name);
    if (!userCurrentBusinessDetails.isOnlineBusiness) {
      const isZomatoDining = plateFormId === 3 && zomatoRatingType === 'dining';
      setTotalReview(isZomatoDining ? totalDinningReview : selectPlatform?.totalReview);
    } else {
      setTotalReview(selectPlatform?.totalReview);
    }
  }, [userCurrentBusinessDetails, plateFormId, zomatoRatingType]);

  useEffect(() => {
    const fetchProducts = async () => {
      const payload = {
        query:
          parseInt(plateFormId, 10) === 5
            ? manageBusinessData.getAmazonProduct
            : parseInt(plateFormId, 10) === 6 && manageBusinessData.getFlipkartProduct,
        variables: {
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
        },
      };
      const result = await handleApiRequest(payload);
      setPageLoading(false);
      const res =
        parseInt(plateFormId, 10) === 5
          ? result?.getAmazonProduct
          : parseInt(plateFormId, 10) === 6 && result?.getFlipkartProduct;
      if (res?.status === 200) {
        setProducts(res?.data);
        const product = res?.data[0];
        setSelectedProduct(product);
        setPlaceId(product?.placeId);
      } else {
        enqueueSnackbar(res?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    };
    if (userCurrentBusinessDetails?.isOnlineBusiness && plateFormId && plateFormId !== 7) {
      fetchProducts();
    }
  }, [
    enqueueSnackbar,
    plateFormId,
    userCurrentBusinessDetails?.id,
    userCurrentBusinessDetails?.isOnlineBusiness,
  ]);

  useEffect(() => {
    const filterReviewRating = [
      { ratingType: 'positiveReview', ratingName: 'Positive Reviews' },
      { ratingType: 'negativeReview', ratingName: 'Negative Reviews' },
      { ratingType: 'fiveStarReview', ratingName: '5-star Reviews' },
      { ratingType: 'fourStarReview', ratingName: '4-star Reviews' },
      { ratingType: 'threeStarReview', ratingName: '3-star Reviews' },
      { ratingType: 'twoStarReview', ratingName: '2-star Reviews' },
      { ratingType: 'oneStarReview', ratingName: '1-star Reviews' },
    ];

    const fetchFilterData = async () => {
      const payload = {
        query: reviewPlatformData.getReviewFilter,
      };
      const result = await handleApiRequest(payload);
      if (result?.getReviewFilter?.status === 200) {
        const res = result?.getReviewFilter?.data;
        const filterRes = res?.filter((item) => !['Happy Reviews', 'Newest'].includes(item?.type));
        const data = filterRes.sort(
          (curr, prev) => prev.filterReviewRating - curr.filterReviewRating
        );
        const typeMap = Object.fromEntries(
          filterReviewRating.map(({ ratingName, ratingType }) => [ratingName, ratingType])
        );

        const sortedArray = filterReviewRating.reduce((acc, { ratingName }) => {
          const item = filterRes.find((obj) => obj.type === ratingName);
          return item ? [...acc, { ...item, ratingType: typeMap[ratingName] }] : acc;
        }, []);

        setFilterData(sortedArray);
      }
    };
    fetchFilterData();
  }, [enqueueSnackbar]);

  const selectReviewData = (data) => {
    setSelectedReviewData(data);
    setGenerateDefaultReplyData([]);
    setIsReply(false);
    setShowOrHideReply(false);
    setSelectedReplyText('');
    setGenerateDefaultReplyData([]);
  };

  const changeReviewPlatForm = (reviewPlatformId) => {
    const findReviewData = userCurrentBusinessDetails?.reviewplatform?.find(
      (item) => item.reviewPlatformId === reviewPlatformId
    );
    setPlaceId(findReviewData?.placeId);
    setPlateFormId(findReviewData?.reviewPlatformId);
    setReviewData([]);
    setPage(1);
  };

  const handleChangePage = (event, value) => {
    setPage(value + 1);
  };

  const handleChangeRowsPerPage = (event, value) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };
  const handleSelectProduct = (id) => {
    const findProduct = products?.find((product) => product.id === id);
    setSelectedProduct(findProduct);
    setPlaceId(findProduct?.placeId);
  };

  const handleSelectFilter = (id) => {
    if (filterIds?.includes(id)) {
      setFilterIds(
        filterIds?.filter((filterId) => filterId?.filterReviewRating !== id?.filterReviewRating)
      );
    } else {
      setFilterIds([...filterIds, id]);
    }
    setPage(1);
  };

  const handleSelectSortOption = (value) => {
    const findSortData = sortData?.find((item) => item?.name === value);
    setSelectSortOption(findSortData);
  };

  if (pageLoading) {
    return <FuseLoading />;
  }

  if (loading) {
    return <Loading />;
  }

  const redirectAddProduct = () => {
    const id = getParamsId();
    if (id) {
      history.push(`quick-reviews/product/${id}`, { platformsId: plateFormId });
    } else {
      history.push('quick-reviews/product', { platformsId: plateFormId });
    }
  };

  const generateReply = async () => {
    const findBusinessType = businessTypeList?.find(
      (businessType) =>
        Number(businessType?.id) === Number(userCurrentBusinessDetails?.businessTypeId)
    );
    if (findBusinessType?.bType && selectedReviewData?.review) {
      try {
        setRefreshIcon(true);
        const payload = {
          query: userData.ownerResponseOnReview,
          variables: {
            businessType: findBusinessType?.bType || '',
            review: selectedReviewData?.review,
            reviewerName: selectedReviewData?.reviewerName,
          },
        };
        const res = await handleApiRequest(payload);
        setRefreshIcon(false);
        if (res?.ownerResponseOnReview?.status === 200) {
          setSelectedReplyText(res?.ownerResponseOnReview?.data);
        } else {
          enqueueSnackbar(res?.ownerResponseOnReview?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        setRefreshIcon(false);
        console.error('Error in generateReply:', error);
      }
    }
  };

  const getDefaultGenerateReply = async () => {
    try {
      const payload = {
        query: userData.getDefaultReviewReply,
        variables: {
          star: selectedReviewData?.reviewRating,
        },
      };
      const res = await handleApiRequest(payload);
      if (res?.getDefaultReviewReply?.status === 200) {
        const editResponse = [
          ...(res.getDefaultReviewReply?.data?.map((item) => ({
            ...item,
            replyText: item.replyText?.replace(
              /\[Customers Name\]/g,
              selectedReviewData?.reviewerName
            ),
          })) || []),
        ];
        setGenerateDefaultReplyData((prevData) => [...prevData, ...editResponse]);
      } else {
        enqueueSnackbar(res?.getDefaultReviewReply?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error in getDefaultGenerateReply:', error);
    }
  };

  const handleSelectGenerateReply = (data) => {
    // setGenerateDefaultReplyData((prevData) => {
    //   const filteredData = prevData.filter((item) => item.replyText !== data.replyText);
    //   return [data, ...filteredData];
    // });
    setSelectedReplyText(data?.replyText);
  };

  const handleSubmitReply = async () => {
    setSubmitRefreshIcon(true);
    try {
      const payload = {
        query: userData.sendOrEditReplayToGoogleReview,
        variables: {
          userBusinessId: Number(userCurrentBusinessDetails?.id),
          reviewId: selectedReviewData?.googleBusinessReviewId,
          reply: selectedReplyText,
        },
      };
      const res = await handleApiRequest(payload);
      setSubmitRefreshIcon(false);
      if (res?.sendOrEditReplayToGoogleReview?.status === 200) {
        setIsReply(false);
        setGenerateDefaultReplyData(false);
        setShowOrHideReply(false);
        enqueueSnackbar(res?.sendOrEditReplayToGoogleReview?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar(res?.sendOrEditReplayToGoogleReview?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setSubmitRefreshIcon(false);
      console.error('Error in handleSubmitReply:', error);
    }
  };

  const handleReply = () => {
    const hasReplies = selectedReviewData?.businessReplies?.length > 0;
    if (!selectedReviewData?.googleBusinessReviewId) {
      window.open(selectedReviewData?.reviewRedirectUrl, '_blank');
    } else if (!hasReplies) {
      if (
        googleBusinessData?.isGoogleVerified &&
        googleBusinessData?.isVerifiedOnGoogle &&
        plateFormId === 2
      ) {
        setIsReply(true);
        generateReply();
        getDefaultGenerateReply();
      } else if (!googleBusinessData?.isVerifiedOnGoogle && plateFormId === 2) {
        setIsDialogOpen(true);
      } else {
        window.open(selectedReviewData?.reviewRedirectUrl, '_blank');
      }
    }
  };

  const handleEditReply = () => {
    if (googleBusinessData?.isGoogleVerified && googleBusinessData?.isVerifiedOnGoogle) {
      setIsReply(true);
      generateReply();
      getDefaultGenerateReply();
    } else if (!googleBusinessData?.isVerifiedOnGoogle) {
      setIsDialogOpen(true);
    } else {
      window.open(selectedReviewData?.reviewRedirectUrl, '_blank');
    }
  };

  const handleCancel = () => {
    setIsReply(false);
  };

  return (
    <div className="lg:p-24 p-16 ">
      <div className="sm:flex w-full items-center justify-between lg:mb-20 mb-16">
        <Typography
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
        >
          {t('reviews.title')}
        </Typography>
        <div className="sm:flex gap-24 text-center">
          <div className="items-center justify-end md:flex mb-20 md:mb-0 text-left">
            <Select
              className="rounded-md h-40 bg-white text-16 sm:w-full sm:min-w-256 leading-5 shadow-none capitalize"
              variant="filled"
              sx={{ '& .MuiFilledInput-input': { pt: '8px', zIndex: '10' } }}
              value={parseInt(plateFormId, 10)}
              fullWidth
              disabled={userCurrentBusinessDetails?.reviewplatform?.length === 1}
              onChange={(e) => changeReviewPlatForm(e?.target?.value)}
              IconComponent={() => (
                <ArrowDropDownOutlinedIcon className="text-28 align-middle absolute right-14 text-black ml-2" />
              )}
            >
              {!userCurrentBusinessDetails?.isOnlineBusiness &&
                userCurrentBusinessDetails?.reviewplatform
                  ?.slice()
                  .sort((a, b) => b.defaultReview - a.defaultReview)
                  .map((review, i) => (
                    <MenuItem className="capitalize" value={review?.reviewPlatformId} key={i}>
                      <div className="flex gap-8">
                        <img className="w-20" src={review?.logo} alt="" />{' '}
                        <Typography>
                          {' '}
                          {review?.name && capitalizeFirstLetter(review.name)}
                        </Typography>
                      </div>
                    </MenuItem>
                  ))}
              {userCurrentBusinessDetails?.isOnlineBusiness &&
                userCurrentBusinessDetails?.reviewplatform
                  ?.slice()
                  ?.sort((a, b) => a.id - b.id)
                  .map((item, index) => {
                    return (
                      <MenuItem className="capitalize" value={item?.reviewPlatformId} key={index}>
                        <div className="flex gap-8">
                          <img className="w-20" src={item?.logo} alt="" />{' '}
                          <Typography> {item?.name && capitalizeFirstLetter(item.name)}</Typography>
                        </div>
                      </MenuItem>
                    );
                  })}
            </Select>
          </div>
        </div>
      </div>
      {Number(plateFormId) === 3 && (
        <div className="border-solid border-grey sm:flex justify-between items-center border-b-2 mb-24 overflow-x-auto">
          <Tabs
            value={zomatoRatingType}
            onChange={(e, newValue) => {
              setZomatoRatingType(newValue);
            }}
            textColor="quickCampaign"
            indicatorColor="quickCampaign"
            aria-label="secondary tabs example"
          >
            <Tab
              className={`sm:text-18 font-bold p-0 ${
                zomatoRatingType === 'delivery' ? 'text-quick-review' : 'text-black'
              }`}
              value="delivery"
              label={t('reviews.deliveryRating')}
            />
            <Tab
              className={`sm:text-18 font-bold mr-20 p-0 ${
                zomatoRatingType === 'dining' ? 'text-quick-review' : 'text-black'
              }`}
              value="dining"
              label={t('reviews.diningRating')}
            />
          </Tabs>
        </div>
      )}
      <div className="flex flex-col md:flex-row items-center bg-quick-review-100 gap-24 rounded-12 mb-32 p-12 md:p-20">
        <div className="flex flex-col md:flex-row w-full md:w-1/3 mb-4 md:mb-0">
          <div className="flex justify-center items-center md:justify-start mb-4 md:mb-0">
            <img
              src={matchedPlatform?.logo}
              alt={matchedPlatform?.name}
              className="bg-white max-h-auto max-w-96 rounded-8 p-16"
            />
          </div>
          <div className="flex justify-center md:justify-normal ml-10">
            <div className="flex flex-col justify-center ">
              <div className="text-32 font-bold ">
                {matchedPlatform?.name ? capitalizeFirstLetter(matchedPlatform.name) : ''}
                {t('reviews.title')}
              </div>
              <div>
                {matchedPlatform && (
                  <span className="block">
                    {reviewFormate(
                      plateFormId === 5 || plateFormId === 6
                        ? selectedProduct?.rating
                        : zomatoRatingType === 'dining'
                        ? matchedPlatform?.totalDinningRatingStars
                        : matchedPlatform?.totalRatingStars,
                      56
                    )}
                  </span>
                )}
              </div>
              {matchedPlatform && matchedPlatform?.totalReview ? (
                <p className="text-20 font-medium">
                  {t('reviews.reviewCount')}
                  {plateFormId === 5 || plateFormId === 6
                    ? selectedProduct?.totalReviews
                    : zomatoRatingType === 'dining'
                    ? matchedPlatform?.totalDinningReview
                    : matchedPlatform?.totalReview}
                  {t('reviews.title')}
                </p>
              ) : (
                <p className="text-20 font-medium">{t('reviews.noReviews')}</p>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full justify-center md:w-2/3 gap-10 h-auto md:h-120">
          {platFormStarData &&
            platFormStarData?.reviewsDistributionJsonV1
              ?.sort((a, b) => {
                const order = ['fiveStar', 'fourStar', 'threeStar', 'twoStar', 'oneStar'];
                return order.indexOf(a.key) - order.indexOf(b.key);
              })
              .map((item) => {
                const starLabels = {
                  fiveStar: `5 ${t('reviews.stars')}`,
                  fourStar: `4 ${t('reviews.stars')}`,
                  threeStar: `3 ${t('reviews.stars')}`,
                  twoStar: `2 ${t('reviews.stars')}`,
                  oneStar: `1 ${t('reviews.stars')}`,
                };

                return (
                  <div
                    key={item?.key}
                    className="flex flex-row gap-10 text-sm sm:text-base font-semibold items-center"
                  >
                    <p className="w-60">{starLabels[item?.key]}</p>
                    <div className="w-full md:w-480 rounded-4 h-16 relative bg-white">
                      <div
                        className="z-9999 rounded-4 bg-quick-chat h-16 absolute top-0 left-0"
                        style={{
                          width:
                            Number(plateFormId) === 3
                              ? zomatoRatingType === 'delivery'
                                ? `${item?.delivery}%`
                                : zomatoRatingType === 'dining'
                                ? `${item?.dining}%`
                                : `${item?.delivery}%`
                              : `${item?.value}%`,
                        }}
                      />
                    </div>
                    <p className="min-w-32">
                      {Number(plateFormId) === 3
                        ? zomatoRatingType === 'delivery'
                          ? `${item?.delivery} %`
                          : zomatoRatingType === 'dining'
                          ? `${item?.dining} %`
                          : `${item?.delivery}%`
                        : `${item?.value}%`}
                    </p>
                  </div>
                );
              })}
        </div>
      </div>
      {isDataAvailable.current && (
        <div className="bg-white mt-20 w-full rounded-t-md p-16">
          <div className="flex md:w-1/3 items-center justify-between relative">
            <Typography className="font-semibold text-16">{t('quickSocial.title')}</Typography>
            <div className="flex gap-12">
              <Button
                className={`border border-solid ${
                  filterIds?.length > 0 ? 'text-quick-review' : 'border-gray-500'
                } rounded-md w-28 min-w-28 p-0 h-24 min-h-24 hover:bg-white`}
                onClick={() => {
                  setShowFilterOption(true);
                  setShowSortOption(false);
                }}
              >
                <FilterListIcon
                  className={`${
                    filterIds?.length > 0 ? 'text-quick-review' : 'text-gray-800'
                  } opacity-100`}
                />
              </Button>
              {filterIds?.length > 0 && (
                <Typography className="absolute right-32 -top-6 text-12 font-semibold bg-quick-review text-white rounded-full h-16 w-16 flex items-center justify-center">
                  {filterIds?.length}
                </Typography>
              )}
              <Button
                className="border border-solid
                rounded-md w-28 min-w-28 p-0 h-24 min-h-24 hover:bg-white text-quick-review"
                onClick={() => {
                  setShowSortOption(true);
                  setShowFilterOption(false);
                }}
              >
                <SortIcon className="opacity-100 text-quick-review" />
              </Button>
            </div>
            {showFilterOption && (
              <div
                ref={filterRef}
                className="absolute z-10 top-32 bg-white p-16 shadow rounded-xl right-0"
              >
                <div className="flex justify-between items-center w-[240px]">
                  <Typography className="font-bold text-18">{t('quickSocial.filter')}</Typography>
                  <Button
                    className="p-0 h-8 min-h-24 w-24 min-w-24 hover:bg-white"
                    onClick={() => {
                      setShowFilterOption(false);
                    }}
                  >
                    <CloseIcon />
                  </Button>
                </div>
                {filterData?.length > 0 &&
                  filterData?.map((item, i) => {
                    return (
                      <div className="flex items-center justify-between mt-16" key={i}>
                        <Typography>{item?.type}</Typography>
                        <Checkbox
                          className="p-0"
                          checked={filterIds?.some(
                            (value) => value?.filterReviewRating === item?.filterReviewRating
                          )}
                          onChange={() => handleSelectFilter(item)}
                        />
                      </div>
                    );
                  })}
                <div className="border-t-1 border-gray-A500 pt-12 flex justify-end items-center">
                  <div>
                    <Button
                      variant="outlined"
                      color="quickReview"
                      className="rounded-md mr-12 px-16 py-4"
                      size="small"
                      onClick={() => {
                        setShowFilterOption(false);
                        setFilterIds([]);
                      }}
                    >
                      {t('businesses.clearAll')}
                    </Button>
                    {/* <Button
                      variant="contained"
                      color="quickReview"
                      className="rounded-md px-16 py-4"
                      size="small"
                      onClick={() => {
                        fetchPhysicalBusinessReview();
                        setShowFilterOption(false);
                      }}
                    >
                      {t('reports.apply')}
                    </Button> */}
                  </div>
                </div>
              </div>
            )}
            {showSortOption && (
              <div
                ref={sortRef}
                className="absolute z-10 top-32 bg-white p-16 shadow rounded-xl right-0"
              >
                <div className="flex justify-between items-center mb-8 w-[240px]">
                  <Typography className="font-bold text-18">{t('quickSocial.sortBy')}</Typography>
                  <Button
                    className="p-0 h-8 w-24 flex justify-end  hover:bg-white"
                    onClick={() => {
                      setShowSortOption(false);
                    }}
                  >
                    <CloseIcon />
                  </Button>
                </div>
                <FormControl className="w-full">
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={(e) => handleSelectSortOption(e.target.value)}
                  >
                    {sortData?.length > 0 &&
                      sortData?.map((item, i) => {
                        return (
                          <div className="flex items-center justify-between w-full mb-10">
                            <Typography className="text-14" key={i}>
                              {item?.name}
                            </Typography>
                            <FormControlLabel
                              className="p-0 mr-0"
                              value={item?.name}
                              checked={selectSortOption?.name === item?.name}
                              control={<Radio className="p-0" />}
                              onChange={() => {
                                setPage(1);
                                setShowSortOption(false);
                              }}
                            />
                          </div>
                        );
                      })}
                  </RadioGroup>
                </FormControl>
              </div>
            )}
          </div>
        </div>
      )}
      <>
        {(Number(totalReview) > 0 && Number(reviewData?.length) > 0) ||
        (userCurrentBusinessDetails?.isOnlineBusiness && products?.length > 0) ? (
          <>
            <div className="bg-white w-full rounded--b-md p-16">
              <div className="flex flex-col-reverse md:flex-row w-full gap-20">
                <div className="overflow-scroll max-h-640 md:w-1/3">
                  {userCurrentBusinessDetails.isOnlineBusiness &&
                    products?.length > 0 &&
                    parseInt(plateFormId, 10) !== 7 && (
                      <Select
                        className="inline-block bg-white text-black capitalize border-none pt-0 font-semibold w-full mb-12 relative"
                        id="country"
                        size="small"
                        displayEmpty
                        sx={{
                          '& .MuiSelect-select': {
                            display: 'flex',
                            alignItems: 'center',
                            border: 'none',
                            maxWidth: '360px',
                          },
                        }}
                        value={selectedProduct?.id}
                        onChange={(e) => handleSelectProduct(e.target.value)}
                      >
                        {products?.map((product, i) => {
                          return (
                            <MenuItem
                              key={i}
                              value={product?.id}
                              className="flex capitalize whitespace-normal max-w-480"
                            >
                              {product?.genericName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  {reviewData?.length > 0 &&
                    reviewData?.map((review, index) => {
                      return (
                        <div
                          className={`flex rounded-md mb-16 bg-gray-A500 p-12 border border-solid border-gray-A300 ${
                            parseInt(selectedReviewData?.id, 10) === parseInt(review?.id, 10)
                              ? '!border-quick-review'
                              : ''
                          }`}
                          key={index}
                          onClick={() => selectReviewData(review)}
                          onKeyDown={(e) => {}}
                          role="button"
                          tabIndex={0}
                        >
                          <div className="w-full">
                            <div className="flex w-full justify-between">
                              <div className="flex itemx-center gap-12">
                                {review?.reviewerProfileUrl ? (
                                  <img
                                    className="w-48 h-48 rounded-3xl"
                                    src={
                                      review?.reviewerProfileUrl || 'assets/images/icon/Profile.png'
                                    }
                                    alt=""
                                  />
                                ) : (
                                  <div
                                    style={{
                                      backgroundColor: `#${review.userProfile}33`,
                                      width: '38px',
                                      height: '38px',
                                      borderRadius: '50%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      color: `#${review.userProfile}`,
                                      fontSize: '16px',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {review?.reviewerName?.charAt(0).toUpperCase()}
                                  </div>
                                )}
                                <div className="">
                                  <Typography className="font-semibold">
                                    {review?.reviewerName}
                                  </Typography>
                                  {[...Array(5)].map((_, i) => (
                                    <Icon
                                      className={`text-16 align-middle mr-2 -mt-2 ${
                                        i <
                                        (parseInt(plateFormId, 10) !== 7
                                          ? review?.reviewRating
                                          : review?.rating)
                                          ? 'text-yellow-800'
                                          : 'text-gray-400'
                                      }`}
                                      key={i}
                                    >
                                      star
                                    </Icon>
                                  ))}{' '}
                                  {review?.reviewRating || review?.rating}.0
                                </div>
                              </div>
                              <Typography className="">
                                {review?.reviewCts &&
                                  formatDateWithTimeZone(review?.reviewCts, 'MMM DD, YYYY')}
                              </Typography>
                            </div>
                            <Typography className="pt-12 text-14">
                              {review?.review && review.review.length > 190
                                ? `${review.review?.slice(0, 190)}...`
                                : review.review}
                            </Typography>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {reviewData?.length === 0 && (
                  <div className="text-center my-192">
                    <img className="m-auto" src="/assets/images/business/Reviews.png" alt="" />
                    <Typography className="text-16">
                      {t('reviews.NoReviewsFoundForTheFilter')}
                    </Typography>
                  </div>
                )}
                {selectedReviewData && (
                  <div className="h-max md:h-full md:w-2/3">
                    <div className="h-max md:h-full md:overflow-scroll rounded-md bg-gray-A300 p-12">
                      <div className="flex justify-between">
                        <div className="flex items-center gap-12">
                          {selectedReviewData?.reviewerProfileUrl ? (
                            <img
                              className="w-48 h-48 rounded-3xl"
                              src={
                                selectedReviewData?.reviewerProfileUrl ||
                                'assets/images/icon/Profile.png'
                              }
                              alt=""
                            />
                          ) : (
                            <div
                              style={{
                                backgroundColor: `#${selectedReviewData?.userProfile}33`,
                                width: '38px',
                                height: '38px',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: `#${selectedReviewData?.userProfile}`,
                                fontSize: '16px',
                                fontWeight: 'bold',
                              }}
                            >
                              {selectedReviewData?.reviewerName?.charAt(0).toUpperCase()}
                            </div>
                          )}
                          <div className="">
                            <Typography className="font-semibold">
                              {selectedReviewData?.reviewerName}
                            </Typography>
                            {[...Array(5)].map((_, i) => (
                              <Icon
                                className={`text-16 align-middle mr-2 -mt-2 ${
                                  i <
                                  (parseInt(plateFormId, 10) !== 7
                                    ? selectedReviewData?.reviewRating
                                    : selectedReviewData?.rating)
                                    ? 'text-yellow-800'
                                    : 'text-gray-400'
                                }`}
                                key={i}
                              >
                                star
                              </Icon>
                            ))}{' '}
                            {selectedReviewData?.reviewRating || selectedReviewData?.rating}
                            .0
                          </div>
                        </div>
                        <Typography className="">
                          {moment(selectedReviewData?.reviewCts).format('MMM DD, YYYY')}
                        </Typography>
                      </div>
                      <Typography className="font-medium pt-8 text-14 md:text-16">
                        {selectedReviewData?.review}
                      </Typography>
                      <div className="grid grid-cols-2 gap-8  sm:grid-cols-3 lg:grid-cols-4">
                        {selectedReviewData?.imageLinks?.length > 0 &&
                          selectedReviewData?.imageLinks?.map((item, i) => {
                            return (
                              <div className="" key={i}>
                                <img
                                  className="sm:w-192 sm:h-192 w-160 h-160 m-auto rounded-md mt-24"
                                  src={item}
                                  alt=""
                                />
                              </div>
                            );
                          })}
                      </div>
                      <div className="grid grid-cols-2 gap-8 sm:grid-cols-3 lg:grid-cols-4">
                        {Array.isArray(selectedReviewData?.videoLinks) &&
                          selectedReviewData?.videoLinks?.map((item, i) => (
                            <div className="w-full aspect-video mt-10" key={i}>
                              <iframe
                                className="block w-full h-full rounded-md"
                                src={item?.link}
                                allowFullScreen
                                title={`video-${i}`}
                              />
                            </div>
                          ))}
                      </div>

                      <div className="text-end">
                        {selectedReviewData?.responseFromOnwer ||
                        selectedReviewData?.businessReplies?.length > 0 ? (
                          <Button
                            className="text-quick-review font-semibold text-16 hover:bg-transparent"
                            onClick={() => setShowOrHideReply(!showOrHideReply)}
                          >
                            {showOrHideReply ? t('reviews.hide') : t('reviews.view')}{' '}
                            {t('reviews.youResponse')}
                          </Button>
                        ) : (
                          <Button
                            className="text-quick-review font-semibold text-16 hover:bg-transparent"
                            onClick={() => handleReply(selectedReviewData?.reviewRedirectUrl)}
                          >
                            {t('reviews.reply')}
                          </Button>
                        )}
                      </div>
                      {showOrHideReply && (
                        <div className="border-l-2 !border-[#707070] pl-12">
                          <div className="flex justify-between">
                            <div className="flex items-center gap-12">
                              {selectedReviewData?.businessReplies?.[0]
                                ?.business_owner_image_url ? (
                                <img
                                  className="w-48 h-48 rounded-3xl"
                                  src={
                                    selectedReviewData?.businessReplies?.[0]
                                      ?.business_owner_image_url || 'assets/images/icon/Profile.png'
                                  }
                                  alt=""
                                />
                              ) : (
                                <div
                                  style={{
                                    backgroundColor: `#${selectedReviewData.userProfile}33`,
                                    width: '38px',
                                    height: '38px',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: `#${selectedReviewData.userProfile}`,
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {selectedReviewData?.businessReplies?.[0]?.business_owner_name
                                    ? selectedReviewData?.businessReplies?.[0]?.business_owner_name
                                        ?.charAt(0)
                                        .toUpperCase()
                                    : loginUserData?.fullName?.charAt(0).toUpperCase()}
                                </div>
                              )}
                              <div className="">
                                <Typography className="font-semibold">
                                  {selectedReviewData?.businessReplies?.[0]?.business_owner_name
                                    ? selectedReviewData?.businessReplies?.[0]?.business_owner_name
                                    : loginUserData?.fullName}
                                </Typography>
                                <Typography className="font-semibold text-14">
                                  ({t('reviews.owner')})
                                </Typography>
                              </div>
                            </div>
                          </div>
                          <Typography className="font-medium pt-8 text-14 md:text-16">
                            {selectedReviewData?.responseFromOnwer ||
                              selectedReviewData?.businessReplies?.[0]?.reply_text}
                          </Typography>
                          <div className="text-end">
                            <Button
                              className="text-quick-review font-semibold text-16 hover:bg-transparent"
                              onClick={() => handleEditReply()}
                            >
                              <EditIcon className="text-quick-review text-20 mr-6" />{' '}
                              {t('common.edit')}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                    {googleBusinessData?.isVerifiedOnGoogle && isReply && (
                      <div className="mt-16">
                        <div className="flex items-center justify-between">
                          <Typography className="font-bold text-18 text-gray">
                            {t('reviews.replyOnThisReview')}
                          </Typography>
                          <Button
                            onClick={() => generateReply()}
                            disabled={refreshIcon}
                            className={`${
                              refreshIcon ? 'opacity-50 cursor-not-allowed' : 'hover:shadow-lg'
                            }`}
                          >
                            {/* Background Gradient */}
                            <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-purple-500 to-cyan-500 rounded-lg">
                              -
                            </span>
                            <span className="absolute inset-[2px] bg-white rounded-md">-</span>

                            {/* Content inside Button */}
                            <span className="relative flex items-center gap-2">
                              <img
                                src="/assets/images/icon/magic.svg"
                                alt="magic"
                                width="25px"
                                height="25px"
                              />
                              {t('reviews.generateReply')}
                            </span>
                          </Button>
                        </div>
                        <div className="mt-16">
                          <TextField
                            className="w-full bg-gray-A500"
                            value={selectedReplyText}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                              },
                            }}
                            onChange={(e) => setSelectedReplyText(e.target.value)}
                          />
                          {refreshIcon ? (
                            <div className="flex h-360 mt-10">
                              <span className="text-gray-900">Thinking</span>
                              <img
                                className="w-32 h-28 ml-2 items-center"
                                src="/assets/images/icon/regenerateText.gif"
                                alt="Loading..."
                              />
                            </div>
                          ) : (
                            generateDefaultReplyData.map((item, index) => (
                              <Typography
                                className="p-16 rounded-md mt-12 cursor-pointer bg-quick-review-50 border-2 border-quick-review"
                                onClick={() => handleSelectGenerateReply(item)}
                              >
                                {item.replyText}
                              </Typography>
                            ))
                          )}

                          {/* Buttons added here */}
                          <div className="flex justify-end mt-20">
                            <div className="xs:flex gap-8">
                              <div>
                                <Button
                                  className="rounded-md border-2 px-60 hover:border-2 font-semibold"
                                  color="quickReview"
                                  variant="outlined"
                                  onClick={() => handleCancel()}
                                >
                                  {t('common.cancel')}
                                </Button>
                              </div>
                              <div className="mt-12 xs:mt-0">
                                <Button
                                  className="rounded-md border-2 px-32 hover:border-2 font-semibold"
                                  color="quickReview"
                                  variant="contained"
                                  onClick={() => handleSubmitReply()}
                                  disabled={submitRefreshIcon}
                                >
                                  {t('reviews.submitReply')}
                                  {submitRefreshIcon && (
                                    <CircularProgress
                                      className="text-darkgreen absolute mx-auto"
                                      size={24}
                                    />
                                  )}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {totalCount > 10 && (
                <div className="w-full flex justify-end md:w-1/3">
                  <div className="flex justify-center">
                    <Pagination
                      totalCount={totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="bg-white text-center sm:py-192 w-full">
            {parseInt(totalReview, 10) > 0 &&
            Number(reviewData?.length) > 0 &&
            !userCurrentBusinessDetails?.isOnlineBusiness ? (
              <div className="h-full text-20 font-bold justify-center md:w-1/3">
                <img className="m-auto" src="/assets/images/business/Processing.png" alt="" />
                <Typography className="font-semibold text-20 my-20">
                  {t('reviews.processing')}
                </Typography>
                <Typography className="text-16">{t('reviews.loadingReviewMessage')}</Typography>
              </div>
            ) : (
              <div className="h-full text-20 font-bold justify-center md:w-1/3">
                {(parseInt(plateFormId, 10) === 5 || parseInt(plateFormId, 10) === 6) &&
                products?.length === 0 ? (
                  <div>
                    <img
                      className="m-auto w-128 mb-16"
                      src="/assets/images/business/products.png"
                      alt=""
                    />
                    {parseInt(plateFormId, 10) === 5 && (
                      <div>
                        <Typography className="font-semibold text-20 my-16">
                          {t('products.noAmazonProduct')}
                        </Typography>
                        <Typography className="text-16 max-w-640 m-auto">
                          {t('products.addAmazonProductMessage')}
                        </Typography>
                        <Button
                          className="bg-quick-review rounded-md text-white px-36 hover:bg-quick-review mt-16"
                          onClick={() => redirectAddProduct()}
                        >
                          {t('products.addAmazonProduct')}
                        </Button>
                      </div>
                    )}
                    {parseInt(plateFormId, 10) === 6 && (
                      <div>
                        <Typography className="font-semibold text-20 my-16">
                          {t('products.noFlipKartProduct')}
                        </Typography>
                        <Typography className="text-16 max-w-640 m-auto">
                          {t('products.addFlipkartProductMessage')}
                        </Typography>
                        <Button
                          className="bg-quick-review rounded-md text-white px-36 hover:bg-quick-review mt-16"
                          onClick={() => redirectAddProduct()}
                        >
                          {t('products.addFlipkartProduct')}
                        </Button>
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <img className="m-auto" src="/assets/images/business/Reviews.png" alt="" />
                    <Typography className="text-16">
                      {t('reviews.NoReviewsFoundForTheFilter')}
                    </Typography>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </>
      <NotVerifyBusinessPopUp isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} />
    </div>
  );
};

export default Reviews;
