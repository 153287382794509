import { Button, Drawer, Typography } from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { t } from 'i18next';

const CommonConfigureDrawer = (props) => {
  const { tabValue, openDrawer, setOpenDrawer } = props;
  const [selectedProvider, setSelectedProvider] = useState('gmail');
  const serviceProviderSteps = [
    {
      stepNumber: 1,
      title: 'Select your service provider',
      content: [
        {
          type: 'text',
          value: 'Select your service provider',
        },
      ],
    },
    {
      stepNumber: 2,
      title: 'Enter Your Account SID & Auth Token',
      content: [
        {
          type: 'sub-title',
          items: [
            {
              type: 'subtitle',
              value: 'How to get Account Sid and Auth token code',
            },
            {
              type: 'orderedList',
              items: [
                {
                  text: "Go to Twilio's website - ",
                  link: 'https://www.twilio.com/',
                  hasLink: true,
                },
                {
                  text: 'Log in to your Twilio account',
                  hasLink: false,
                },
                {
                  text: 'Navigate to the Twilio Console by clicking on your account name in the top right and selecting Console',
                  hasLink: false,
                },
                {
                  text: 'Find your "Account SID and Auth code" in account info',
                  hasLink: false,
                },
                {
                  text: 'Copy it and Paste here',
                  hasLink: false,
                },
              ],
            },
            {
              type: 'note',
              value:
                "If you're working with multiple Twilio projects, ensure you select the correct project from the dropdown at the top before copying the Account SID",
            },
          ],
        },
      ],
    },
    {
      stepNumber: 3,
      title: 'Save or Send test SMS',
      content: [
        {
          type: 'text',
          value: 'After entering all required details, you have two options:',
        },
        {
          type: 'radioOptions',
          options: [
            {
              id: 'save',
              title: 'Save:',
              label:
                'Click "Save" to store your Twilio credentials and phone number for future use.',
            },
            {
              id: 'send',
              title: 'Send Test SMS:',
              label: ' Click "Send Test SMS" to verify that Twilio is working correctly.',
            },
          ],
        },
        {
          type: 'orderedList',
          items: [
            {
              text: "Enter a recipient's phone number",
              hasLink: false,
            },
            {
              text: 'Click Send to test message delivery',
              hasLink: false,
            },
            {
              text: 'If successful, your Twilio integration is set up properly',
              hasLink: false,
            },
          ],
        },
      ],
    },
  ];

  const emailServiceProviders = [
    {
      id: 'gmail',
      name: 'Gmail',
      steps: [
        {
          stepNumber: 1,
          title: 'Select Email Service Provider',
          content: [
            {
              type: 'orderedList',
              items: [
                {
                  text: 'Click on the Email Service Provider dropdown menu.',
                  hasLink: false,
                },
                {
                  text: 'Select the appropriate provider from the menu.',
                  hasLink: false,
                },
              ],
            },
          ],
        },
        {
          stepNumber: 2,
          title: 'Provide Provider Name',
          content: [
            {
              type: 'text',
              items: [
                {
                  text: 'Enter the Provider Name in the corresponding field. Example: Gmail or Marketing/Gmail',
                  hasLink: false,
                },
                {
                  text: 'Example: Gmail or Marketing/Gmail',
                  title: 'Example:',
                  hasLink: false,
                },
              ],
            },
          ],
        },
        {
          stepNumber: 3,
          title: 'Enter Sender Email/Alias',
          content: [
            {
              type: 'text',
              items: [
                {
                  text: 'Enter the Sender Email or Alias you want to appear on outgoing emails.',
                  hasLink: false,
                },
                {
                  text: 'info@mycompany.com or no-reply@mycompany.com',
                  title: 'Example: ',
                  hasLink: false,
                },
              ],
            },
          ],
        },
        {
          stepNumber: 4,
          title: 'Input Username and Password',
          content: [
            {
              type: 'orderedList',
              items: [
                {
                  text: 'Enter the username for your email account.',
                  hasLink: false,
                },
                {
                  text: 'Enter the Password for authentication.',
                  hasLink: false,
                },
              ],
            },
            {
              type: 'note',
              value:
                'Ensure you use the correct email account credentials or an app-specific password.',
              hasLink: false,
            },
          ],
        },
        {
          stepNumber: 5,
          title: 'Provide Server Name/IP and Port',
          content: [
            {
              type: 'orderedList',
              items: [
                {
                  text: 'Enter the Server Name/IP of your SMTP server. Example: smtp.gmail.com',
                  hasLink: false,
                },
                {
                  text: 'Enter the Port number.',
                  hasLink: false,
                },
                {
                  text: 'Common ports: 587 for TLS or 465 for SSL.',
                  hasLink: false,
                },
              ],
            },
          ],
        },
        {
          stepNumber: 6,
          title: 'Test the Configuration',
          content: [
            {
              type: 'orderedList',
              items: [
                {
                  text: 'Click on the Test button to verify if the configuration is working correctly.',
                  hasLink: false,
                },
                {
                  text: "If successful, you'll receive a test email.",
                  hasLink: false,
                },
                {
                  text: 'If unsuccessful, check your credentials or server details.',
                  hasLink: false,
                },
              ],
            },
          ],
        },
        {
          stepNumber: 7,
          title: 'Save the Configuration',
          content: [
            {
              type: 'orderedList',
              items: [
                {
                  text: 'Once the test email is successful, click on the Save button to save your configuration.',
                  hasLink: false,
                },
                {
                  text: 'Your email setup is now complete, and you can start sending campaigns using this configuration.',
                  hasLink: false,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'brevo',
      name: 'Brevo',
      steps: [
        {
          stepNumber: 1,
          title: 'Select Brevo Email Service Provider',
          content: [
            {
              type: 'orderedList',
              items: [
                {
                  text: 'Click on the Brevo Service Provider dropdown menu.',
                  hasLink: false,
                },
                {
                  text: 'Select Brevo from the menu.',
                  hasLink: false,
                },
              ],
            },
          ],
        },
        {
          stepNumber: 2,
          title: 'Enter Provider Details',
          content: [
            {
              type: 'text',
              items: [
                {
                  text: 'In the Provider Name field, enter Brevo or a relevant name like Marketing/Brevo.',
                  hasLink: false,
                },
              ],
            },
          ],
        },
        {
          stepNumber: 3,
          title: 'Enter Sender Email/Alias',
          content: [
            {
              type: 'text',
              items: [
                {
                  text: 'Enter the Sender Email or Alias you want to appear on outgoing emails.',
                  hasLink: false,
                },
                {
                  text: 'info@mycompany.com or no-reply@mycompany.com',
                  title: 'Example: ',
                  hasLink: false,
                },
              ],
            },
          ],
        },
        {
          stepNumber: 4,
          title: 'Enter Username and Password',
          content: [
            {
              type: 'text',
              items: [
                {
                  text: 'Your Brevo account email',
                  title: 'Username: ',
                  hasLink: false,
                },
                {
                  text: 'SMTP API key from your Brevo account instead of your login password',
                  title: 'Password: ',
                  hasLink: false,
                },
              ],
            },
            {
              type: 'subtitle',
              items: [
                {
                  text: 'How to Get an API Key for Brevo:',
                  hasLink: false,
                },
              ],
            },
            {
              type: 'orderedList',
              items: [
                {
                  text: 'Log in to ',
                  hasLink: true,
                  linkName: 'Brevo.',
                  link: 'https://www.brevo.com/',
                },
                {
                  text: 'Go to SMTP & API from the dashboard.',
                  hasLink: false,
                },
                {
                  text: 'Click on the SMTP tab.',
                  hasLink: false,
                },
                {
                  text: "Click Generate a new SMTP key (if you don't already have one).",
                  hasLink: false,
                },
                {
                  text: 'Enter key and confirm.',
                  hasLink: false,
                },
                {
                  text: 'Copy the SMTP key and store it securely, as it will be shown only once.',
                  hasLink: false,
                },
                {
                  text: 'Use this key as your Password here.',
                  hasLink: false,
                },
              ],
            },
            {
              type: 'note',
              value:
                'Ensure you use the correct email account credentials or an app-specific password.',
            },
          ],
        },
        {
          stepNumber: 5,
          title: 'Provide Server Name/IP and Port',
          content: [
            {
              type: 'orderedList',
              items: [
                {
                  text: 'Enter the Server Name/IP of your SMTP server. Example: smtp-relay.brevo.com',
                  hasLink: false,
                },
                {
                  text: 'Enter the Port number.',
                  hasLink: false,
                },
                {
                  text: 'Common ports: 587 for TLS or 465 for SSL.',
                  hasLink: false,
                },
              ],
            },
          ],
        },
        {
          stepNumber: 6,
          title: 'Test the Configuration',
          content: [
            {
              type: 'orderedList',
              items: [
                {
                  text: 'Click on the Send test Email button to verify if the configuration is working correctly.',
                  hasLink: false,
                },
                {
                  text: "If successful, you'll receive a test email.",
                  hasLink: false,
                },
                {
                  text: 'If unsuccessful, check your credentials or server details.',
                  hasLink: false,
                },
              ],
            },
          ],
        },
        {
          stepNumber: 7,
          title: 'Save the Configuration',
          content: [
            {
              type: 'orderedList',
              items: [
                {
                  text: 'Once the test email is successful, click on the Save button to save your configuration.',
                  hasLink: false,
                },
                {
                  text: 'Your email setup is now complete, and you can start sending campaigns using this configuration.',
                  hasLink: false,
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const handleProviderChange = (providerId) => {
    setSelectedProvider(providerId);
  };

  const providerData = emailServiceProviders?.find((provider) => provider?.id === selectedProvider);

  return (
    <div>
      <Drawer anchor="right" open={openDrawer} transitionDuration={700}>
        <div className="xs:min-w-400 xs:max-w-400 flex flex-col h-full bg-gray-A500">
          <div className="flex items-center justify-between gap-12 p-16">
            <Typography variant="subtitle1" className="font-semibold mb-1 text-gray-900">
              {tabValue === 2
                ? t('reports.howToConnectServiceProvider')
                : t('reports.howToConnectEmailProvider')}
            </Typography>
            <Button
              className="min-w-16 w-16 hover:bg-transparent"
              onClick={() => setOpenDrawer(false)}
            >
              <CloseIcon />
            </Button>
          </div>
          <hr />
          <div className="px-16 pb-16 pt-9 flex-grow overflow-y-auto">
            {tabValue === 1 && (
              <div className="email-setup-modal">
                <div className="p-4">
                  <div className="flex mb-4">
                    {emailServiceProviders?.map((provider) => (
                      <button
                        key={provider?.id}
                        type="button"
                        className={`flex-1 py-2 px-4 border-blue-gray-800 ${
                          selectedProvider === provider?.id
                            ? 'bg-blue-gray-800 text-white'
                            : 'bg-white text-blue-gray-800 border'
                        }`}
                        onClick={() => handleProviderChange(provider?.id)}
                      >
                        <Typography
                          variant="body1"
                          className={`flex-1 py-2 px-4  ${
                            selectedProvider === provider?.id
                              ? 'bg-blue-gray-800 text-white'
                              : 'bg-white text-blue-gray-800 '
                          }`}
                        >
                          {provider?.name}
                        </Typography>
                      </button>
                    ))}
                  </div>
                </div>
                <div className="mt-4 overflow-y-auto">
                  {providerData?.steps?.map((step) => (
                    <div key={step?.stepNumber} className="bg-white rounded-lg py-9 px-14 mb-9">
                      <Typography variant="subtitle1" className="font-semibold mb-1 text-gray-900">
                        {t('reports.step')} {step?.stepNumber} : {step?.title}
                      </Typography>

                      {step?.content?.map((item, idx) => {
                        if (item?.type === 'text' || item?.type === 'subtitle') {
                          return (
                            <Typography
                              key={idx}
                              variant={item?.type === 'subtitle' ? 'subtitle1' : 'body1'}
                              className={`${
                                item?.type === 'subtitle'
                                  ? 'font-semibold mb-1 text-gray-900'
                                  : 'text-gray-900 mt-2'
                              }`}
                            >
                              <ol
                                key={idx}
                                className="list-inside space-y-3 text-gray-900 mt-12 ml-5"
                              >
                                {item?.items?.map((textItem, textIdx) => (
                                  <li key={textIdx} className="mb-1">
                                    <span className="font-semibold"> {textItem?.title} </span>
                                    {textItem?.hasLink ? (
                                      <span>
                                        {textItem?.text}{' '}
                                        <a
                                          style={{ color: '#212121' }}
                                          href={textItem?.link}
                                          className="text-blue-600 text-13 hover:text-blue-800 inline-flex items-center"
                                        >
                                          {textItem?.title}
                                        </a>
                                      </span>
                                    ) : (
                                      textItem?.text
                                    )}
                                  </li>
                                ))}
                              </ol>
                            </Typography>
                          );
                        }

                        if (item?.type === 'orderedList') {
                          return (
                            <ol
                              key={idx}
                              className="list-decimal list-inside space-y-3 text-gray-900 mt-12 ml-5"
                            >
                              {item?.items?.map((listItem, listIdx) => (
                                <li key={listIdx} className="mb-1">
                                  {listItem?.hasLink ? (
                                    <>
                                      {listItem?.text
                                        ?.split(/(Example:)/g)
                                        ?.map((part, index) =>
                                          part === 'Example:' ? (
                                            <strong key={index}>{part}</strong>
                                          ) : (
                                            part
                                          )
                                        )}{' '}
                                      <a
                                        style={{ color: '#212121' }}
                                        href={listItem?.link}
                                        className="text-blue-600 text-13 hover:text-blue-800 inline-flex items-center"
                                      >
                                        {listItem?.linkName}
                                      </a>
                                    </>
                                  ) : (
                                    listItem?.text
                                      ?.split(/(Example:)/g)
                                      ?.map((part, index) =>
                                        part === 'Example:' ? (
                                          <strong key={index}>{part}</strong>
                                        ) : (
                                          part
                                        )
                                      )
                                  )}
                                </li>
                              ))}
                            </ol>
                          );
                        }
                        if (item?.type === 'note') {
                          return (
                            <div key={idx} className=" bg-gray-A500 p-2 rounded mb-2 py-6 px-8">
                              <Typography variant="body1">
                                {' '}
                                <span className="font-semibold">{t('reports.note')}</span>{' '}
                                {item?.value}
                              </Typography>
                            </div>
                          );
                        }

                        return null;
                      })}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {tabValue === 2 && (
              <div className="sms-setup-modal">
                <div className="px-4 py-2">
                  {serviceProviderSteps?.map((step) => (
                    <div key={step?.stepNumber} className="bg-white rounded-lg  py-9 px-14 mb-9">
                      <Typography variant="subtitle1" className="font-semibold mb-1 text-gray-900">
                        {t('reports.step')} {step?.stepNumber} : {step?.title}
                      </Typography>
                      {step?.content?.map((item, idx) => {
                        if (item?.type === 'text') {
                          return (
                            <Typography
                              variant="body1"
                              className="list-decimal list-inside space-y-3 text-gray-900 mt-12"
                            >
                              {item?.value}
                            </Typography>
                          );
                        }
                        if (item?.type === 'orderedList') {
                          return (
                            <ol
                              key={idx}
                              className="list-decimal list-inside space-y-3 text-gray-900 mt-12 ml-5"
                            >
                              {item?.items?.map((listItem, listIdx) => (
                                <li key={listIdx} className="mb-1">
                                  {listItem?.hasLink ? (
                                    <span>
                                      {listItem?.text}{' '}
                                      <a
                                        href={listItem?.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: '#212121' }}
                                      >
                                        {listItem?.link}
                                      </a>
                                    </span>
                                  ) : (
                                    listItem?.text
                                  )}
                                </li>
                              ))}
                            </ol>
                          );
                        }
                        if (item?.type === 'note') {
                          return (
                            <div key={idx} className=" bg-gray-A500 p-2 rounded mb-2 py-6 px-8">
                              <Typography variant="body1">
                                {' '}
                                <span className="font-semibold">{t('reports.note')}</span>{' '}
                                {item?.value}
                              </Typography>
                            </div>
                          );
                        }
                        if (item?.type === 'radioOptions') {
                          return (
                            <div key={idx} className="mb-2">
                              {item?.options?.map((option) => (
                                <div key={option?.id} className="mb-2">
                                  <div className="flex items-baseline">
                                    <div className="bg-quick-campaign h-9 w-14 rounded-full border-gray-300 m-5" />
                                    <label htmlFor={option?.id} className="text-gray-900">
                                      <span className="font-semibold"> {option?.title} </span>
                                      {option?.label}
                                    </label>
                                  </div>
                                  <div className="flex justify-center">
                                    {option?.id === 'save' && (
                                      <div className="font-semibold"> {t('reports.or')} </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          );
                        }
                        if (item?.type === 'sub-title') {
                          return (
                            <div key={idx} className="bg-gray-50 p-10 rounded-lg mb-6">
                              {item?.items?.map((subItem, subIndex) => {
                                switch (subItem?.type) {
                                  case 'subtitle':
                                    return (
                                      <Typography
                                        variant="subtitle1"
                                        className="font-semibold mb-1 text-gray-900"
                                      >
                                        {subItem?.value}
                                      </Typography>
                                    );

                                  case 'orderedList':
                                    return (
                                      <ol
                                        key={subIndex}
                                        className="list-decimal list-inside space-y-3 text-gray-900 mt-12"
                                      >
                                        {subItem?.items?.map((listItem, listIndex) => (
                                          <li key={listIndex}>
                                            {listItem?.text}
                                            {listItem?.hasLink && (
                                              <a
                                                style={{ color: '#212121' }}
                                                href={listItem?.link}
                                                className="inline-flex items-center"
                                              >
                                                {listItem?.link}
                                              </a>
                                            )}
                                          </li>
                                        ))}
                                      </ol>
                                    );

                                  case 'note':
                                    return (
                                      <div
                                        key={idx}
                                        className=" bg-gray-A500 p-2 rounded mb-2 py-6 px-8"
                                      >
                                        <Typography variant="body1">
                                          {' '}
                                          <span className="font-semibold">
                                            {t('reports.note')}
                                          </span>{' '}
                                          {subItem?.value}
                                        </Typography>
                                      </div>
                                    );
                                  default:
                                    return null;
                                }
                              })}
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default CommonConfigureDrawer;
