import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import _ from '@lodash';
import Icon from '@mui/material/Icon';
import FuseLoading from '@fuse/core/FuseLoading';
import moment from 'moment';
import history from '@history';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import salesData from '../../query/sales';
import { getDataToServer, getEncryptedData, handleApiRequest } from '../../common/common';

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  if (numSelected > 0) {
    return (
      <Toolbar className="absolute left-0 right-0 -top-80 bg-black text-white z-10 rounded-md">
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
            className="text-white"
          >
            {numSelected} Selected
          </Typography>
        ) : (
          ''
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton className="text-white">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function SalesPerson() {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [actionValues, setActionValues] = useState({});
  const [orderNow, setOrderNow] = useState('desc');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [refreshViewIcon, setRefreshViewIcon] = useState(false);
  const [salesPersonData, setSalesPersonData] = useState([]);
  const [salesUserId, setSalesUserId] = useState();
  const [totalSalesPerson, setTotalSalesPerson] = useState(0);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = salesPersonData?.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const payload = {
        query: salesData.getSalesUsers,
        variables: {
          pageNumber: 1,
          pageSize: 10,
          searchText: '',
          sortBy: 'id',
          sortOrder: 'desc',
          isActive: true,
        },
      };
      const res = await getDataToServer(payload);
      setLoading(false);
      if (res?.getSalesUsers?.status === 200) {
        setSalesPersonData(res?.getSalesUsers?.data);
        setTotalSalesPerson(res.getSalesUsers?.totalCount);
      }
    }
    fetchData();
  }, []);

  const commonGetData = async (searchValues = '') => {
    setSearchValue(searchValues);
    const payload = {
      query: salesData.getSalesUsers,
      variables: {
        searchText: searchValues,
        pageNumber: page,
        pageSize: rowsPerPage,
        isActive: true,
      },
    };
    const res = await getDataToServer(payload);
    if (res?.getSalesUsers?.status === 200) {
      setSalesPersonData(res?.getSalesUsers?.data);
      setTotalSalesPerson(res.getSalesUsers?.totalCount);
    }
  };

  function handleSearchEvent(event) {
    setPage(1);
    const searchText = event.target.value;
    commonGetData(searchText);
  }

  const handleActionDropdownChange = (event, id) => {
    const { value } = event.target;
    setActionValues((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSorting = async (event) => {
    const sortColumnName = event;
    setOrderBy(sortColumnName);
    if (order === 'asc') {
      const sorted = [...salesPersonData].sort((sortTypeA, sortTypeB) =>
        sortTypeA.col > sortTypeB.col ? 1 : 1
      );
      setSalesPersonData(sorted);
      setOrderNow('asc');
      setOrder('desc');
    }
    if (order === 'desc') {
      const sorted = [...salesPersonData].sort((sortTypeA, sortTypeB) =>
        sortTypeA.col < sortTypeB.col ? 1 : 1
      );
      setSalesPersonData(sorted);
      setOrderNow('desc');
      setOrder('asc');
    }
    const payload = {
      query: salesData.getSalesUsers,
      variables: {
        pageSize: 10,
        pageNumber: 1,
        searchText: '',
        sortBy: sortColumnName,
        sortOrder: order,
        isActive: true,
      },
    };
    const res = await getDataToServer(payload);
    if (res?.getSalesUsers?.status === 200) {
      setSalesPersonData(res?.getSalesUsers?.data);
      setTotalSalesPerson(res.getSalesUsers?.totalCount);
    }
  };

  const handleChangePage = async (event, value) => {
    setPage(value + 1);
    setLoadingPage(true);
    const payload = {
      query: salesData.getSalesUsers,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: value + 1,
        searchText: searchValue,
        sortBy: orderBy,
        sortOrder: orderNow,
        isActive: true,
      },
    };
    const res = await getDataToServer(payload);
    setLoadingPage(false);
    if (res?.getSalesUsers?.status === 200) {
      setSalesPersonData(res?.getSalesUsers?.data);
      setTotalSalesPerson(res.getSalesUsers?.totalCount);
    }
  };

  const handleChangeRowsPerPage = async (event) => {
    setLoadingPage(true);
    setRowsPerPage(event.target.value);
    setPage(1);
    const payload = {
      query: salesData.getSalesUsers,
      variables: {
        pageSize: event.target.value,
        pageNumber: 1,
        searchText: searchValue,
        sortBy: orderBy,
        sortOrder: orderNow,
        isActive: true,
      },
    };
    const res = await getDataToServer(payload);
    setLoadingPage(false);
    if (res?.getSalesUsers?.status === 200) {
      setSalesPersonData(res?.getSalesUsers?.data);
      setTotalSalesPerson(res.getSalesUsers?.totalCount);
    }
  };

  function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead className="bg-grey-50 rounded-md">
        <TableRow>
          {/* <TableCell padding="checkbox" className="rounded-tl-md">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all User',
              }}
            />
          </TableCell> */}
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon> {t('salesPerson.column.srNo')}</TableSortLabel>
          </TableCell>
          {/* <TableCell
              className="text-16 font-medium"
              align="left"
            >
              <TableSortLabel
                hideSortIcon
                direction={order}
                onClick={() => {
                  handleSorting('id');
                }}
              >
                Index
              </TableSortLabel>
            </TableCell> */}
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel
              direction={order}
              onClick={() => {
                handleSorting('name');
              }}
            >
              {t('salesPerson.column.salespersonName')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('salesPerson.column.emailId')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel
              direction={order}
              onClick={() => {
                handleSorting('id');
              }}
            >
              {t('dashboard.dashboardAdmin.dateOfJoining')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('salesPerson.column.phoneNo')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium rounded-tr-md">
            <TableSortLabel hideSortIcon>{t('common.action')}</TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  if (loading) {
    return <FuseLoading />;
  }

  function redirectUserRoutes(id) {
    return history.push({
      pathname: `/sales-details/${id}`,
    });
  }

  const handleCreateSalesAccount = () => {
    history.push('/create-sales-account');
  };

  const handleClearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
    await commonGetData();
  };

  const handleDelete = async () => {
    setRefreshViewIcon(true);
    const encryptData = getEncryptedData(JSON.stringify({ salesUserId }));
    const payload = {
      query: salesData.deleteSalesUser,
      variables: { data: encryptData },
    };
    const result = await handleApiRequest(payload);
    if (result?.deleteSalesUser?.status === 200) {
      enqueueSnackbar(result?.deleteSalesUser?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setOpenDialog(false);
      setRefreshViewIcon(false);
    } else {
      enqueueSnackbar(result?.deleteSalesUser?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setRefreshViewIcon(false);
    }
    if (result?.deleteSalesUser?.status === 200) {
      commonGetData();
    }
  };

  return (
    <div className="p-20 lg:p-24">
      {loadingPage && (
        <div className="loader-container fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="loader h-40 w-40 rounded-full border-4 border-solid border-white border-t-darkgreen" />
        </div>
      )}
      <div className="flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-16 md:text-28 font-bold"
          >
            {t('salesPerson.title')}
          </Typography>
        </div>
        <div className="flex flex-1 items-center justify-center px-12">
          <Paper
            component={motion.div}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex items-center w-full max-w-512 px-8 rounded-8 shadow-none"
          >
            <Input
              placeholder={t('users.UserList.placeholder.search')}
              className="flex flex-1 mx-8 text-black text-16"
              disableUnderline
              name="searchText"
              fullWidth
              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={handleSearchEvent}
              value={searchValue}
            />
            {searchValue ? (
              <Icon color="action" className="cursor-pointer" onClick={() => handleClearSearch()}>
                close
              </Icon>
            ) : (
              <Icon color="action">search</Icon>
            )}
          </Paper>
        </div>
        <div>
          <Button
            className="bg-darkgreen text-white px-20 hover:bg-darkgreen text-base"
            type="button"
            onClick={() => handleCreateSalesAccount()}
          >
            {t('salesPerson.button.createSalesAccount')}
          </Button>
        </div>
        <div className="items-center justify-end" />
      </div>
      <div className="bg-white rounded-md relative shadow">
        {salesPersonData?.length > 0 ? (
          <>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={salesPersonData?.length}
                />
                <TableBody>
                  {salesPersonData &&
                    salesPersonData.length > 0 &&
                    salesPersonData.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          className="border border-t-2 border-t-grey-100"
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          {/* <TableCell
                            padding="checkbox"
                            onClick={(event) => handleClick(event, row.id)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell> */}
                          {/* <TableCell
                            component="th"
                            className="text-16 color-black font-semibold"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {index + 1}+{rowsPerPage * page}
                          </TableCell> */}
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {(page - 1) * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.firstName} {row?.lastName}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.email}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.cts ? moment(row?.cts).format('MMM DD, YYYY') : '-'}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.phoneNumber?.countryCode}{' '}
                            {row?.phoneNumber?.phoneNumber ? row?.phoneNumber?.phoneNumber : '-'}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            <Select
                              className="rounded-md border border-grey-100 font-semibold text-grey-600 h-28 bg-grey-100 text-16 w-96 min-h-0 p-0 leading-4 shadow-none pt-0"
                              sx={{ '& .MuiFilledInput-input': { pt: '14px' } }}
                              variant="filled"
                              value={actionValues[row.id] || 'action'}
                              fullWidth
                              onChange={(event) => handleActionDropdownChange(event, row.id)}
                            >
                              <MenuItem
                                value="action"
                                className="capitalize mx-5 rounded-md"
                                disabled
                              >
                                {t('common.action')}
                              </MenuItem>
                              <MenuItem
                                value="view"
                                className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                                onClick={() => redirectUserRoutes(row.id)}
                              >
                                {t('common.viewDetails')}
                              </MenuItem>
                              <MenuItem
                                value="delete"
                                // onClick={() => handleDelete(row.id)}
                                onClick={() => {
                                  setOpenDialog(true);
                                  setSalesUserId(row.id);
                                }}
                                className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                              >
                                {t('common.delete')}
                              </MenuItem>
                            </Select>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }}
            className="flex flex-1 items-center justify-center h-full"
          >
            <Typography color="textSecondary" variant="h5" className="text-16 py-12">
              {t('salesPerson.validationMessage.noSalesPerson')}
            </Typography>
          </motion.div>
        )}
      </div>
      {salesPersonData?.length > 0 && (
        <TablePagination
          sx={{
            '.MuiInputBase-root': {
              lineHeight: 'inherit',
              padding: 0,
            },
          }}
          component="div"
          count={totalSalesPerson}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      {openDialog && (
        <Dialog
          classes={{
            paper: 'm-24 p-28 max-w-400',
          }}
          open={openDialog}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              setOpenDialog(false);
            }
          }}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle className="p-0 text-center">
            <img
              src="/assets/images/icon/delete.png"
              className="img-fluid mx-auto w-136 h-136"
              alt="..."
            />
            <Typography className="text-20 font-semibold text-black text-center w-full pb-10 mt-28 mb-0">
              {t('salesPerson.deleteSalesPerson.title')}
            </Typography>
            <Icon
              onClick={() => {
                setOpenDialog(false);
              }}
              className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
            >
              close
            </Icon>
          </DialogTitle>
          <DialogContent className="p-0 mb-28">
            <DialogContentText
              id="alert-dialog-description"
              className="text-14 font-medium text-center text-black max-w-sm"
            >
              {t('salesPerson.deleteSalesPerson.desc')}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="p-0 justify-center">
            <Button
              variant="outlined"
              color="secondary"
              className="md:text-16 font-medium disabled:text-black rounded-md px-48 pb-6"
              aria-label={t('common.cancel')}
              onClick={() => {
                setOpenDialog(false);
              }}
              type="button"
              size="small"
            >
              {t('common.cancel')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className="md:text-16 font-medium disabled:text-black rounded-md px-48 pb-6"
              aria-label={t('common.confirm')}
              onClick={() => handleDelete()}
              disabled={refreshViewIcon}
              type="button"
              size="small"
            >
              {t('common.confirm')}
              {refreshViewIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default SalesPerson;
