import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Button, CircularProgress, MenuItem, Select, Typography } from '@mui/material';
import { GoogleMap, LoadScript, Circle, OverlayView } from '@react-google-maps/api';
import RefreshIcon from '@mui/icons-material/Refresh';
import PushPinIcon from '@mui/icons-material/PushPin';
import PinDropIcon from '@mui/icons-material/PinDrop';
import AppsIcon from '@mui/icons-material/Apps';
import CreatableSelect from 'react-select/creatable';
import Icon from '@mui/material/Icon';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import manageBusinessData from '../../../query/manageBusiness';
import { capitalizeFirstLetter, formatRating, handleApiRequest } from '../../../common/common';
import { selectDashboardData } from '../../../../store/userSlice';
import Loading from '../../manage-business/Loading';

const GeoGridPoints = (props) => {
  const {
    currentLocation,
    placeId,
    reviewPlatformId,
    businessId,
    refreshIcon,
    setRefreshIcon,
    fetchCompetitorData,
  } = props;
  const [circles, setCircles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [keywords, setKeywords] = useState([]);
  const [keywordValue, setKeywordValue] = useState(0);
  const [gridValue, setGridValue] = useState(3);
  const [radiusValue, setRadiusValue] = useState(1);
  const [zoomLevel, setZoomLevel] = useState(12);
  const [rankData, setRankData] = useState([]);
  const [selectCircleValue, setSelectCircleValue] = useState();
  const [topKeywords, setTopKeywords] = useState([]);
  const [gridAndRadiusPoints, setGridAndRadiusPoints] = useState([]);
  const location = useLocation();
  const [locationState, setLocationState] = useState(location?.state);
  const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const lib = ['places'];
  const mapRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [reviewData, setReviewData] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [physicalBusinessTypeList, setPhysicalBusinessTypeList] = useState([]);
  const initialRadius = 600;
  // const mapStyle = [
  //   { elementType: 'geometry', stylers: [{ color: '#f5f5f5' }] },
  //   { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
  //   { elementType: 'labels.text.fill', stylers: [{ color: '#616161' }] },
  //   { elementType: 'labels.text.stroke', stylers: [{ color: '#f5f5f5' }] },
  //   {
  //     featureType: 'administrative.land_parcel',
  //     elementType: 'labels.text.fill',
  //     stylers: [{ color: '#bdbdbd' }],
  //   },
  //   { featureType: 'poi', elementType: 'geometry', stylers: [{ color: '#eeeeee' }] },
  //   { featureType: 'poi', elementType: 'labels.text.fill', stylers: [{ color: '#757575' }] },
  //   { featureType: 'poi.park', elementType: 'geometry', stylers: [{ color: '#e5e5e5' }] },
  //   { featureType: 'poi.park', elementType: 'labels.text.fill', stylers: [{ color: '#9e9e9e' }] },
  //   { featureType: 'road', elementType: 'geometry', stylers: [{ color: '#ffffff' }] },
  //   {
  //     featureType: 'road.arterial',
  //     elementType: 'labels.text.fill',
  //     stylers: [{ color: '#757575' }],
  //   },
  //   { featureType: 'road.highway', elementType: 'geometry', stylers: [{ color: '#dadada' }] },
  //   {
  //     featureType: 'road.highway',
  //     elementType: 'labels.text.fill',
  //     stylers: [{ color: '#616161' }],
  //   },
  //   { featureType: 'road.local', elementType: 'labels.text.fill', stylers: [{ color: '#9e9e9e' }] },
  //   { featureType: 'transit.line', elementType: 'geometry', stylers: [{ color: '#e5e5e5' }] },
  //   { featureType: 'transit.station', elementType: 'geometry', stylers: [{ color: '#eeeeee' }] },
  //   { featureType: 'water', elementType: 'geometry', stylers: [{ color: '#c9c9c9' }] },
  //   { featureType: 'water', elementType: 'labels.text.fill', stylers: [{ color: '#9e9e9e' }] },
  // ];

  const options = keywords?.map((item) => ({
    value: item.id,
    label: capitalizeFirstLetter(item.value),
  }));

  useEffect(() => {
    if (localStorage.getItem('physicalBusinessType')) {
      setPhysicalBusinessTypeList(JSON.parse(localStorage?.getItem('physicalBusinessType')));
    }
  }, []);

  useEffect(() => {
    const reviewPlatform = userCurrentBusinessDetails?.reviewplatform;
    const findDefaultPlatform = reviewPlatform?.find(
      (item) => parseInt(item.reviewPlatformId, 10) === 2
    );
    setReviewData(findDefaultPlatform);
    const convertParse = findDefaultPlatform?.geometry && JSON.parse(findDefaultPlatform?.geometry);
  }, [userCurrentBusinessDetails?.reviewplatform]);

  useEffect(() => {
    const fetchGeoGridPointAndRadius = async () => {
      try {
        const payload = {
          query: manageBusinessData.getGeoGridAndRadius,
        };
        const result = await handleApiRequest(payload);
        if (result?.getGeoGridAndRadius?.status === 200) {
          setGridAndRadiusPoints(result.getGeoGridAndRadius?.data);
        }
      } catch (error) {
        console.error('Error fetching Geo Grid Points:', error);
      }
    };
    fetchGeoGridPointAndRadius();
  }, []);

  useEffect(() => {
    const fetchKeywords = async () => {
      try {
        const payload = {
          query: manageBusinessData.getRankApiKeyword,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getRankApiKeyword?.status === 200) {
          const data = result.getRankApiKeyword?.data;
          setTopKeywords(data);
          const addId = data?.map((item, i) => ({ value: item, id: i + 1 }));
          const storedKeywords = JSON.parse(localStorage.getItem('keywords')) || [];
          let combinedKeywords = [...addId, ...storedKeywords];
          combinedKeywords = combinedKeywords.filter(
            (item, index, self) => index === self.findIndex((word) => word.value === item.value)
          );
          setKeywords(combinedKeywords);
          localStorage.setItem('keywords', JSON.stringify(combinedKeywords));
        }
      } catch (error) {
        console.error('Error fetching keywords:', error);
      }
    };
    if (userCurrentBusinessDetails?.id && !locationState) {
      fetchKeywords();
    }
  }, [userCurrentBusinessDetails?.id, locationState]);

  const fetchGridData = useCallback(
    async (radius, grid) => {
      setLoading(true);
      try {
        const payload = {
          query: manageBusinessData.getGridPoints,
          variables: {
            latitude: locationState?.queryParams?.latitude || currentLocation?.lat,
            longitude: locationState?.queryParams?.longitude || currentLocation?.lng,
            radius: parseInt(radius, 10) || radiusValue,
            gridSize: parseInt(grid, 10) || gridValue,
          },
        };
        const result = await handleApiRequest(payload);
        setLoading(false);
        if (result?.getGridPoints?.status === 200) {
          const data = result?.getGridPoints?.data?.map(([lat, lng]) => ({ lat, lng }));
          setCircles(data);
          if (locationState?.rankHistory?.points) {
            const pointData = locationState?.rankHistory?.points;
            const updatedCircleArray = data.map((item, index) => ({
              ...item,
              self_rank: pointData[index]?.self_rank,
            }));
            setCircles(updatedCircleArray);
          }
        } else {
          enqueueSnackbar(result?.getGridPoints?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching grid data:', error);
      }
    },
    [currentLocation, enqueueSnackbar, locationState, gridValue, radiusValue]
  );

  useEffect(() => {
    const findGridValue = gridAndRadiusPoints?.geoGrid?.find(
      (item) => parseInt(item.value, 10) === parseInt(gridValue, 10)
    );
    const findRadiusValue = gridAndRadiusPoints?.radius?.find(
      (item) => parseInt(item.value, 10) === parseInt(radiusValue, 10)
    );
    if (currentLocation?.lat && currentLocation?.lng && findRadiusValue && findRadiusValue) {
      fetchGridData(findRadiusValue?.value, findGridValue?.value);
    }
  }, [fetchGridData, currentLocation, gridValue, radiusValue, gridAndRadiusPoints]);

  const handleChangeKeyword = (selectedOption) => {
    setKeywordValue(selectedOption);
  };

  useEffect(() => {
    if (locationState?.queryParams?.latitude && locationState?.queryParams?.longitude) {
      const data = locationState?.rankHistory?.points;
      const findGridValue = gridAndRadiusPoints?.geoGrid?.find(
        (item) => parseInt(item.value, 10) === parseInt(locationState.queryParams?.grid_size, 10)
      );
      const findRadiusValue = gridAndRadiusPoints?.radius?.find(
        (item) => parseInt(item.value, 10) === parseInt(locationState?.queryParams?.radius, 10)
      );
      const findKeywordValue = options?.find(
        (option) => option.label === capitalizeFirstLetter(locationState?.queryParams?.keyword)
      );
      setRankData(data);
      setGridValue(findGridValue?.value);
      setRadiusValue(findRadiusValue?.value);
      setKeywordValue(findKeywordValue?.value);
    }
  }, [locationState, gridAndRadiusPoints, options]);

  const handleSelectGrid = (value) => {
    setGridValue(value);
    setSelectCircleValue('');
    setRankData([]);
    setLocationState(null);
    const findGridValue = gridAndRadiusPoints?.geoGrid?.find(
      (item) => parseInt(item.value, 10) === parseInt(value, 10)
    );
    const findRadiusValue = gridAndRadiusPoints?.radius?.find(
      (item) => parseInt(item.value, 10) === parseInt(radiusValue, 10)
    );
    fetchGridData(findRadiusValue?.value, findGridValue?.value);
  };

  const handleSelectRadius = (value) => {
    setRadiusValue(value);
    setSelectCircleValue('');
    setRankData([]);
    setLocationState(null);
    const findGridValue = gridAndRadiusPoints?.geoGrid?.find(
      (item) => parseInt(item.value, 10) === parseInt(gridValue, 10)
    );
    const findRadiusValue = gridAndRadiusPoints?.radius?.find(
      (item) => parseInt(item.value, 10) === parseInt(value, 10)
    );
    fetchGridData(findRadiusValue?.value, findGridValue?.value);
  };

  const handleLoad = (map) => {
    mapRef.current = map;
    if (window.google && window.google.maps) {
      const bounds = new window.google.maps.LatLngBounds();
      circles.forEach((circle) => {
        bounds.extend(new window.google.maps.LatLng(circle.lat, circle.lng));
      });
      map.fitBounds(bounds);
      window.google.maps.event.addListenerOnce(map, 'idle', () => {
        const center = map.getCenter();
        const scale = 2 ** map.getZoom();

        const worldCoordinateCenter = map.getProjection().fromLatLngToPoint(center);
        const pixelOffset = new window.google.maps.Point(100 / scale || 0, 0);
        const newCenter = map
          .getProjection()
          .fromPointToLatLng(
            new window.google.maps.Point(
              worldCoordinateCenter.x - pixelOffset.x,
              worldCoordinateCenter.y - pixelOffset.y
            )
          );

        map.setCenter(newCenter);
      });
      map.addListener('zoom_changed', () => {
        setZoomLevel(map.getZoom());
      });
    }
  };
  const handleScan = async () => {
    try {
      setRefreshIcon(true);

      const findKeyword = keywords?.find(
        (item) => parseInt(item.id, 10) === parseInt(keywordValue, 10)
      );
      const findRadiusValue = gridAndRadiusPoints?.radius?.find(
        (item) => parseInt(item.value, 10) === parseInt(radiusValue, 10)
      );
      const findGridValue = gridAndRadiusPoints?.geoGrid?.find(
        (item) => parseInt(item.value, 10) === parseInt(gridValue, 10)
      );

      const payload = {
        query: manageBusinessData.getRankApi,
        variables: {
          latitude: currentLocation?.lat,
          longitude: currentLocation?.lng,
          userBusinessId: parseInt(businessId, 10),
          placeId,
          reviewPlatformId: parseInt(reviewPlatformId, 10),
          keyword: findKeyword?.value,
          radius: findRadiusValue?.value,
          gridSize: findGridValue?.value,
        },
      };

      const result = await handleApiRequest(payload);
      setRefreshIcon(false);

      if (result?.getRankApi?.status === 200) {
        const data = result.getRankApi?.data;
        const updatedCircleArray = circles?.map((item, index) => ({
          ...item,
          self_rank: data[index]?.self_rank,
        }));
        setCircles(updatedCircleArray);
        setRankData(data);
        fetchCompetitorData();
      } else {
        enqueueSnackbar(result?.getRankApi?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } catch (error) {
      console.error('Error during scan:', error);
      setRefreshIcon(false);
    }
  };

  const calculateRadius = (index) => {
    const factor = 2 ** (zoomLevel - 12);
    return selectCircleValue === index ? initialRadius / (factor * 0.75) : initialRadius / factor;
  };

  const createOverlayContent = (rank, i) => {
    const findCenterElement = (circles.length + 1) / 2;
    return (
      <div className="text-white text-16 text-center flex items-center justify-center h-full font-semibold">
        {rank ? (
          rank > 20 ? (
            '20+'
          ) : (
            rank
          )
        ) : findCenterElement === i ? (
          <PushPinIcon />
        ) : (
          <RefreshIcon className={`${refreshIcon && 'animate-spin'}`} />
        )}
      </div>
    );
  };

  const clickOnPoint = (rank, i) => {
    if (rank) {
      setSelectCircleValue(i);
    }
  };

  const displayData = () => {
    const findData = rankData?.find((item, i) => i + 1 === selectCircleValue);
    return (
      <div className="absolute max-w-640 md:max-w-512 top-586 xs:top-512 sm:top-480 md:top-384 left-1/2 md:left-80 h-512 overflow-scroll z-10 transform -translate-x-1/2 md:translate-x-0">
        {findData?.result?.length > 0 ? (
          findData?.result.map((item, i) => {
            return (
              <div className="bg-white rounded-md p-16 mb-16" key={i}>
                <div className="flex flex-wrap sm:flex-nowrap justify-between">
                  <div className="w-full">
                    <div className="flex gap-16 items-center">
                      <div
                        className={`min-w-40 min-h-40 text-20 font-bold rounded-full ${
                          item?.rank < 6
                            ? `bg-[#22c55e]`
                            : item.rank < 11
                            ? 'bg-[#84cc16]'
                            : item.rank < 16
                            ? 'bg-[#fb923c]'
                            : 'bg-[#b91c1c]'
                        } flex items-center justify-center text-white`}
                      >
                        {item?.rank}
                      </div>
                      <div>
                        <Typography className="font-bold text-16 max-w-320">
                          {item?.name}
                        </Typography>
                        <Typography className="font-semibold text-center flex text-gray-600 text-12 py-2">
                          <Icon className="text-yellow-800 text-16">star</Icon> {item?.rating} .{' '}
                          {item?.total_reviews}
                        </Typography>
                        <Typography className="py-4 font-semibold text-gray-600 text-14">
                          {item?.primary_category}
                        </Typography>
                        <Typography className="py-4 font-semibold text-gray-600 text-14 max-w-320">
                          {item?.address}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <Button
                    className="w-160 p-0 font-bold text-14 border-2 border-darkgreen text-darkgreen rounded-md hover:bg-white"
                    variant="outlined"
                    color="quickReview"
                    onClick={() => window.open(item?.map_url, '_blank')}
                  >
                    {t('businessCompetitor.viewDetails')}
                  </Button>
                </div>
                <hr className="border-b-1 text-black" />
                <div className="max-w-320 py-8 m-auto text-center">
                  <div className="flex gap-16 item-center">
                    <Typography className="font-bold text-14 text-gray-800">
                      {t('businessCompetitor.avgRank')} : {item?.avg_rank}
                    </Typography>
                    <Typography className="font-bold text-14 text-gray-800">
                      {t('businessCompetitor.distFromPoint')} :{' '}
                      {item?.distance_of_point_from_center.toFixed(2)}
                    </Typography>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <>
            {selectCircleValue && (
              <div className="bg-red-100 rounded-md p-16 mb-16">
                <div className="flex justify-between">
                  <div>
                    <div className="flex gap-16 items-center">
                      <div className="w-40 h-40 text-20 font-bold rounded-full bg-[#b91c1c] flex items-center justify-center text-white">
                        {findData?.self_rank > 20 ? '20+' : findData?.self_rank}
                      </div>
                      <div>
                        <Typography className="font-bold text-16 max-w-320">
                          {userCurrentBusinessDetails?.name}
                          <span className="bg-orange text-white px-12 ml-6 py-2 text-12 rounded-full">
                            You
                          </span>
                        </Typography>
                        <Typography className="py-4 font-semibold text-gray-600 text-14">
                          {
                            physicalBusinessTypeList?.find(
                              (item) => item?.id === userCurrentBusinessDetails?.businessTypeId
                            )?.bType
                          }
                        </Typography>
                        <Typography className="py-4 font-semibold text-gray-600 text-14 max-w-320">
                          {userCurrentBusinessDetails?.address}
                        </Typography>
                        <Typography className="font-bold text-16 text-gray-800">
                          {t('businessCompetitor.avgRank')} :{' '}
                          {findData?.self_rank > 20 ? '20+' : findData?.self_rank}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <Typography className="font-semibold text-center flex text-gray-600 text-16 py-2">
                    <Icon className="text-yellow-800 text-16 mt-4 mr-4">star</Icon>{' '}
                    {reviewData?.totalRatingStars} . {formatRating(reviewData?.totalReview)}
                  </Typography>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const handleCreate = (inputValue) => {
    const newKeyword = { id: keywords.length + 1, value: inputValue };
    const updatedKeywords = [...keywords, newKeyword];
    setKeywords(updatedKeywords);
    localStorage.setItem('keywords', JSON.stringify(updatedKeywords));
    handleChangeKeyword(newKeyword.id);
  };

  return (
    <div>
      {displayData()}
      {topKeywords?.length > 0 && (
        <div className="flex items-center flex-wrap gap-10 mt-4 mb-20">
          <Typography className="font-semibold text-xl">
            {' '}
            {t('businessCompetitor.topKeywords')}:{' '}
          </Typography>
          {topKeywords?.map((keyword, i) => {
            return (
              <Button
                className={`border border-solid px-24 font-bold text-16 ${
                  keywordValue === i + 1
                    ? 'bg-quick-review text-white border-quick-review hover:bg-quick-review'
                    : 'text-gray border-gray hover:transparent hover:bg-white'
                }`}
                onClick={() => handleChangeKeyword(i + 1)}
              >
                {capitalizeFirstLetter(keyword)}
              </Button>
            );
          })}
        </div>
      )}
      <div className="flex gap-8 mb-16 flex-col sm:flex-row">
        <div className="flex flex-row gap-10 justify-between">
          <CreatableSelect
            className="sm:max-w-144 max-w-full text-16 font-semibold"
            value={options?.find((option) => option.value === keywordValue)}
            onChange={(option) => handleChangeKeyword(option.value)}
            onCreateOption={handleCreate}
            isDisabled={refreshIcon}
            options={options}
            placeholder={t('businessCompetitor.selectKeyword')}
            isSearchable
            classNames={{
              control: (state) =>
                'border border-black rounded-lg shadow-sm hover:border-gray-400 min-h-40 focus:border-blue-500',
              menu: () => 'z-50 rounded-md shadow-lg mt-4 max-h-216 overflow-y-auto',
              option: (state) =>
                state.isFocused
                  ? 'cursor-pointer px-12 py-3 text-16'
                  : 'cursor-pointer px-12 py-3 text-16',
            }}
          />
          <Select
            className="sm:max-w-144 max-w-full text-16 font-semibold"
            defaultValue={0}
            value={
              gridAndRadiusPoints?.geoGrid?.some((item) => item.value === gridValue) ? gridValue : 0
            }
            onChange={(e) => handleSelectGrid(e.target.value)}
            size="small"
            disabled={refreshIcon}
            renderValue={(selected) =>
              selected === 0 ? (
                <div className="flex items-center">
                  <AppsIcon className="ml-8 text-20" />
                  {t('businessCompetitor.selectGrid')}
                </div>
              ) : (
                <div className="flex items-center">
                  <AppsIcon className="text-20" />
                  <span className="ml-8">{`${
                    gridAndRadiusPoints?.geoGrid?.find((item) => item.value === selected)?.value
                  } X ${
                    gridAndRadiusPoints?.geoGrid?.find((item) => item.value === selected)?.value
                  } Grid`}</span>
                </div>
              )
            }
          >
            <MenuItem className="text-16 font-semibold" value={0} disabled>
              {t('businessCompetitor.selectGrid')}
            </MenuItem>
            {gridAndRadiusPoints?.geoGrid?.length > 0 &&
              gridAndRadiusPoints?.geoGrid?.map((item, i) => {
                return (
                  <MenuItem className="font-semibold text-16" key={i} value={item.value}>
                    {item.key}
                  </MenuItem>
                );
              })}
          </Select>
        </div>
        <div className="flex justify-between flex-row gap-10">
          <Select
            className="sm:w-112 w-full text-16 font-semibold"
            defaultValue={0}
            value={
              gridAndRadiusPoints?.radius?.some((item) => item.value === radiusValue)
                ? radiusValue
                : 0
            }
            onChange={(e) => handleSelectRadius(e.target.value)}
            size="small"
            disabled={refreshIcon}
            renderValue={(selected) =>
              selected === 0 ? (
                <div className="flex items-center">
                  <PinDropIcon className="ml-8 text-20" />
                  {t('businessCompetitor.selectRadius')}
                </div>
              ) : (
                <div className="flex items-center">
                  <PinDropIcon className="text-20" />
                  <span className="ml-8">{`${
                    gridAndRadiusPoints?.radius?.find((item) => item.value === selected)?.value
                  } Kms`}</span>
                </div>
              )
            }
          >
            <MenuItem className="text-16 font-semibold" value={0} disabled>
              {t('businessCompetitor.selectRadius')}
            </MenuItem>
            {gridAndRadiusPoints?.radius?.length > 0 &&
              gridAndRadiusPoints?.radius?.map((item, i) => {
                return (
                  <MenuItem className="font-semibold text-16" key={i} value={item.value}>
                    {item.key}
                  </MenuItem>
                );
              })}
          </Select>
          <Button
            className="bg-quick-review hover:bg-quick-review text-white text-20 font-semibold rounded-md px-28 sm:px-60 disabled:bg-gray disabled:text-white"
            onClick={() => handleScan()}
            disabled={refreshIcon || !keywordValue}
          >
            {' '}
            Scan
            {refreshIcon && <CircularProgress className="text-quick-review absolute mx-auto" />}
          </Button>
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <LoadScript
          id="script-loader"
          googleMapsApiKey={key}
          libraries={lib}
          loadingElement={<div className="text-center">Loading...</div>}
        >
          <GoogleMap
            mapContainerStyle={{
              height: '600px',
              width: '100%',
            }}
            zoom={12}
            center={currentLocation}
            options={{
              disableDefaultUI: false,
              draggable: true,
              scrollwheel: false,
              mapTypeControl: false,
              streetViewControl: false,
              // styles: mapStyle,
            }}
            onLoad={handleLoad}
          >
            {circles?.map((circle, index) => {
              return (
                <div>
                  <Circle
                    className="relative"
                    key={index}
                    center={{ lat: circle.lat, lng: circle.lng }}
                    radius={calculateRadius(index + 1)}
                    onClick={() => clickOnPoint(circle?.self_rank, index + 1)}
                    options={{
                      fillColor: circle?.self_rank
                        ? circle?.self_rank < 6
                          ? '#22c55e'
                          : circle.self_rank < 11
                          ? '#84cc16'
                          : circle.self_rank < 16
                          ? '#fb923c'
                          : '#b91c1c'
                        : '#6b7280',
                      fillOpacity: 1,
                      strokeColor:
                        selectCircleValue === index + 1
                          ? '#fff'
                          : circle?.self_rank
                          ? circle?.self_rank < 6
                            ? '#22c55e'
                            : circle.self_rank < 11
                            ? '#84cc16'
                            : circle.self_rank < 16
                            ? '#fb923c'
                            : '#b91c1c'
                          : '#6b7280',
                      strokeOpacity: 1,
                      strokeWeight: selectCircleValue === index + 1 ? 4 : 2,
                      clickable: true,
                    }}
                  />
                  <OverlayView
                    position={{ lat: circle.lat, lng: circle.lng }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  >
                    {createOverlayContent(circle.self_rank, index + 1)}
                  </OverlayView>
                </div>
              );
            })}
          </GoogleMap>
        </LoadScript>
      )}
    </div>
  );
};

export default GeoGridPoints;
