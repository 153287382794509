import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import history from '@history';
import axios from 'axios';
import { appendNavigationItem } from 'app/store/fuse/navigationSlice';
import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import { showMessage } from 'app/store/fuse/messageSlice';
import { logoutUser, setUser } from 'app/store/userSlice';
import userService from '../services/userService';

const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    userService.on('onAutoLogin', () => {
      // dispatch(showMessage({ message: 'Signing in with JWT' }));

      /**
       * Sign in and retrieve user data with stored token
       */
      userService
        .signInWithToken()
        .then((user) => {
          success(user);
          if (
            user &&
            user.roles &&
            user.roles.type === 'sales_user' &&
            ((history && history.location.pathname === '/') ||
              history.location.pathname === '/sign-in')
          ) {
            history.push('/dashboard');
          } else if (
            user &&
            user.roles &&
            user.roles.type === 'business_user' &&
            ((history && history.location.pathname === '/') ||
              history.location.pathname === '/sign-in')
          ) {
            history.push('/business/dashboard');
          } else if (
            user &&
            user.roles &&
            user.roles.type === 'admin' &&
            ((history && history.location.pathname === '/') ||
              history.location.pathname === '/sign-in')
          ) {
            history.push('/admin/dashboard');
          } else if (
            user &&
            user.roles &&
            user.roles.type === 'agent' &&
            ((history && history.location.pathname === '/') ||
              history.location.pathname === '/sign-in')
          ) {
            history.push('/agency/dashboard');
          }
        })
        .catch((error) => {
          pass(error.message);
        });
    });

    userService.on('onLogin', (user) => {
      success(user, 'Signed in');
    });

    userService.on('onLogout', () => {
      pass('Signed out');

      dispatch(logoutUser());
    });

    userService.on('onAutoLogout', (message) => {
      pass(message);

      dispatch(logoutUser());
    });

    userService.on('onNoAccessToken', () => {
      // pass();
      setWaitAuthCheck(false);
      setIsAuthenticated(false);
      if (
        history.location.pathname.match('/verifyEmail') ||
        history.location.pathname.match('/reset-password') ||
        history.location.pathname.match('/sign-up') ||
        history.location.pathname.match('/verifyOtp') ||
        history.location.pathname.match('/userInformation') ||
        history.location.pathname.match('/scanner') ||
        history.location.pathname.match('/landing') ||
        history.location.pathname.match('/app-review') ||
        history.location.pathname.match('/pay') ||
        history.location.pathname.match('/pay-update') ||
        history.location.pathname.match('/addon-update') ||
        history.location.pathname.match('/subscription-update') ||
        history.location.pathname.match('/subscribe') ||
        history.location.pathname.match('/delete-user') ||
        history.location.pathname.match('/sign-in-token') ||
        history.location.pathname.match('/qrcode-order') ||
        history.location.pathname.match('/privacy-policy') ||
        history.location.pathname.match('/terms-of-service') ||
        history.location.pathname.match('/disclaimer') ||
        history.location.pathname.match('/end-use-license-agreement') ||
        // history.location.pathname.match(/^\/quickchat\/[^/]+\/[^/]+$/) ||
        history.location.pathname.match('/quickchat/chat-assistant') ||
        history.location.pathname.match('/chatbot') ||
        history.location.pathname.match('/sign-in/apple') ||
        history.location.pathname.includes('/whatsapp')
      ) {
        /* empty */
      } else if (history.location.pathname.match('/apps')) {
        /* empty */
      } else {
        history.push('/sign-in');
      }
    });

    userService.init();

    function success(user, message) {
      if (message) {
        dispatch(showMessage({ message }));
      }

      Promise.all([
        dispatch(setUser(user)),
        // You can receive data in here before app initialization
      ]).then((values) => {
        setWaitAuthCheck(false);
        setIsAuthenticated(true);
      });
    }

    function pass(message) {
      if (message) {
        dispatch(showMessage({ message }));
      }

      setWaitAuthCheck(false);
      setIsAuthenticated(false);
    }
  }, [dispatch]);

  return waitAuthCheck ? (
    <FuseSplashScreen />
  ) : (
    <AuthContext.Provider value={{ isAuthenticated }}>{children}</AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
