import { Button, CircularProgress, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { t } from 'i18next';
import history from '@history';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import manageBusinessData from 'src/app/main/query/manageBusiness';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';
import quickHub from '../../../../query/quickHub';
import { handleApiRequest } from '../../../../common/common';
import NotVerifyBusinessPopUp from '../../../businessCompetitor/NotVerifyBusinessPopUp';

const Posts = () => {
  const [post, setPost] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [googleBusinessData, setGoogleBusinessData] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const fetchBusinessDetails = async () => {
      if (userCurrentBusinessDetails?.id) {
        setLoading(true);
        try {
          const payload = {
            query: manageBusinessData.getUserBusinessesGoogleDetails,
            variables: {
              businessId: Number(userCurrentBusinessDetails?.id),
            },
          };
          const result = await handleApiRequest(payload);
          setLoading(false);
          if (result?.getUserBusinessesGoogleDetails?.status === 200) {
            const data = result?.getUserBusinessesGoogleDetails?.data;
            const parsedData = JSON.parse(data);
            setGoogleBusinessData(parsedData);
          } else {
            enqueueSnackbar(result?.getUserBusinessesGoogleDetails?.message, {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
        } catch (error) {
          setLoading(false);
          console.error('Error fetching business details:', error);
        }
      }
    };
    fetchBusinessDetails();
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar]);

  const fetchPost = useCallback(async () => {
    try {
      setLoading(true);
      const payload = {
        query: quickHub.getGooglePostsPostedByMultipleBusinesses,
      };
      const result = await handleApiRequest(payload);
      setLoading(false);
      if (result?.getGooglePostsPostedByMultipleBusinesses?.status === 200) {
        const data = result.getGooglePostsPostedByMultipleBusinesses?.data;
        if (data?.length > 0) {
          setPost(
            data.map((item) => ({
              ...item,
              schedule: JSON.parse(item.schedule),
            }))
          );
        }
      } else {
        enqueueSnackbar(result?.getGooglePostsPostedByMultipleBusinesses?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching posts:', error);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    fetchPost();
  }, [fetchPost]);

  function formatDate(date) {
    const { day, month, year } = date;
    const newDate = new Date(year, month - 1, day);
    return newDate.toLocaleDateString('en-US', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  }

  const formatTime = (endTime) => {
    const hours = String(endTime.hours).padStart(2, '0');
    const minutes = endTime.minutes ? String(endTime.minutes).padStart(2, '0') : '00';
    return `${hours}:${minutes}`;
  };

  const handleDelete = async (postId) => {
    if (!postId) return;
    try {
      setRefreshIcon(true);
      const payload = {
        query: quickHub.deleteGooglePostsInBulk,
        variables: {
          globalGooglePostId: Number(postId),
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);

      if (result?.deleteGooglePostsInBulk?.status === 200) {
        fetchPost();
        enqueueSnackbar(result?.deleteGooglePostsInBulk?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar(result?.deleteGooglePostsInBulk?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error deleting post:', error);
      enqueueSnackbar('Failed to delete post. Please try again later.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="bg-gray-A500 h-full p-16 sm:p-24">
      <div className="flex items-center justify-between">
        <Typography className="text-20 sm:text-28 font-bold">{t('posts.title')}</Typography>
        <Button
          className="bg-darkgreen text-white font-semibold rounded-md hover:bg-darkgreen px-4 text-12 xs:text-14 xs:px-20"
          onClick={() => {
            if (!googleBusinessData?.isVerifiedOnGoogle) {
              setIsDialogOpen(true);
            } else {
              history.push('quick-hub/createNewPost');
            }
          }}
        >
          <AddIcon className="text-16 xs:text-24" />
          {t('posts.createNewPost')}
        </Button>
      </div>
      <div className="pt-12">
        {post?.length > 0 ? (
          <div className="grid grid-cols-4 col-span-1 gap-16 mt-12">
            {post?.map((item, index) => {
              return (
                <div
                  className="w-360 bg-white p-12 border border-solid rounded-md transition-transform duration-300 hover:scale-105 cursor-pointer"
                  key={index}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <img
                    className="w-full h-320 border border-solid rounded-md"
                    src={item?.image}
                    alt=""
                  />
                  <div className="flex items-center justify-between pt-12">
                    <Typography className="font-bold text-20">{item?.type} Post</Typography>
                    {hoveredIndex === index && (
                      <Button
                        className="p-0 min-w-32 w-32 min-h-32 h-32 bg-gray-A500 hover:bg-gray-A500"
                        onClick={() => handleDelete(item?.id)}
                        disabled={refreshIcon && hoveredIndex === index}
                      >
                        <img className="w-20" src="assets/images/quick-hub/delete.png" alt="" />
                        {refreshIcon && hoveredIndex === index && (
                          <CircularProgress
                            className="text-quick-review absolute mx-auto"
                            size={24}
                          />
                        )}
                      </Button>
                    )}
                  </div>
                  <Typography className="font-medium text-18 py-12">{item?.description}</Typography>
                  <div className="flex items-center justify-between bg-gray-A500 rounded-lg p-4 w-full">
                    <div className="">
                      <p className="font-bold text-16">{t('posts.start')} -</p>
                      <p className="font-medium text-14">{formatDate(item?.schedule?.startDate)}</p>
                      <p className="font-medium text-14">
                        {formatTime(item?.schedule?.startTime)} Hrs
                      </p>
                    </div>
                    <div className="flex items-center justify-center w-1/3 relative">
                      <div className="w-full h-1 bg-black" />
                      <div className="absolute left-0 w-8 h-8 bg-black rounded-full" />
                      <div className="absolute right-0 w-8 h-8 bg-black rounded-full" />
                    </div>
                    <div className="">
                      <p className="font-bold text-16">{t('posts.end')} -</p>
                      <p className="font-medium text-14">{formatDate(item?.schedule?.endDate)}</p>
                      <p className="font-medium text-14">
                        {formatTime(item?.schedule?.endTime)} Hrs
                      </p>
                    </div>
                  </div>
                  {item?.userBusinessIds?.length > 0 && (
                    <Typography className="font-bold text-20 pt-12">
                      {item?.userBusinessIds?.length} {t('navigation.businesses')}
                    </Typography>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <div className="text-center pt-128">
            <img className="m-auto" src="assets/images/quick-hub/noPost.png" alt="" />
            <Typography className="text-20 font-bold pt-12 pb-8">
              {t('posts.noPostCreated')}
            </Typography>
            <Typography className="text-16 font-medium">{t('posts.noPostMessage')}</Typography>
            <Button
              className="bg-darkgreen text-white font-semibold rounded-md mt-16 hover:bg-darkgreen px-4 text-12 xs:text-14 xs:px-20"
              onClick={() => {
                if (!googleBusinessData?.isVerifiedOnGoogle) {
                  setIsDialogOpen(true);
                } else {
                  history.push('quick-hub/createNewPost');
                }
              }}
            >
              <AddIcon className="text-16 xs:text-24" />
              {t('posts.createNewPost')}
            </Button>
          </div>
        )}
      </div>
      <NotVerifyBusinessPopUp
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        from="Post"
      />
    </div>
  );
};

export default Posts;
