import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  Icon,
  Checkbox,
  Drawer,
  IconButton,
  Select,
  MenuItem,
  FormControlLabel,
  StepConnector,
  InputAdornment,
  Pagination,
} from '@mui/material';
import history from '@history';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { t } from 'i18next';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useCallback, useEffect, useState } from 'react';
import { formatDateWithTimeZone, handleApiRequest } from 'src/app/main/common/common';
import quickAdsQuery from 'src/app/main/query/quickAds';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  DatePicker,
  LocalizationProvider,
  renderTimeViewClock,
  TimePicker,
} from '@mui/x-date-pickers';
import SearchIcon from '@mui/icons-material/Search';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { styled } from '@mui/system';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  GoogleMap,
  Marker,
  LoadScript,
  StandaloneSearchBox,
  Autocomplete,
} from '@react-google-maps/api';
import { useSnackbar } from 'notistack';
import SelectObjective from '../add-ads-campaign/select-objective';

const QuickSocialConnector = styled(StepConnector)(() => ({
  '& .MuiStepConnector-line': {
    borderColor: '#219EBC',
  },
}));

const CustomStepIcon = () => <FiberManualRecordIcon style={{ color: '#219EBC' }} />;

const Leads = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selected, setSelected] = useState({});
  const [currentLocation, setCurrentLocation] = useState([
    { name: 'Pune', lat: 18.5204303, lng: 73.8567437 },
  ]);
  const [searchTerm, setSearchTerm] = useState('');
  const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const lib = ['places'];
  const [autocompleteMap, setAutocompleteMap] = useState({});
  const [selectedRowData, setSelectedRowData] = useState(null);
  const filterList1 = ['COMPLETED', 'PAUSED', 'ACTIVE'];
  const [campaignStatus, setCampaignStatus] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [status, setStatus] = useState([]);
  const [scheduledTime, setScheduledTime] = useState(new Date());
  const [scheduledDate, setScheduledDate] = useState(new Date());
  const [selectOpen, setSelectOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [note, setNote] = useState('');
  const [drawerStatus, setDrawerStatus] = useState(selected?.status?.key || null);
  const [lead, setLead] = useState();
  const [loading, setLoading] = useState(false);
  const [sendNotification, setSendNotification] = useState(null);
  const [leadNoteId, setLeadNoteId] = useState(null);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [dataLoading, setDataLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [selectedMapFullAddress, setSelectedMapFullAddress] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [showFilterOption, setShowFilterOption] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedFilterList1, setSelectedFilterList1] = useState([]);
  const [openSelectObjective, setOpenSelectObjective] = useState(false);
  const [cardsData, setCardsData] = useState([]);

  const connected = cardsData?.length > 0 && cardsData?.some((item) => item?.isConnect);

  const fetchCardDetails = useCallback(async () => {
    try {
      const payload = {
        query: quickAdsQuery.getAdsConnectApis,
        variables: {
          businessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getAdsConnectApis?.status === 200) {
        const cards = result?.getAdsConnectApis?.data;
        setCardsData(cards);
      } else if (result?.getAdsConnectApis?.status === 190) {
        enqueueSnackbar(
          result?.getAdsConnectApis?.errorMessage || result?.getAdsConnectApis?.message,
          {
            variant: 'error',
            autoHideDuration: 2000,
          }
        );
        setTimeout(() => {
          history.push(`/quick-ads/ads-social-connect/`);
        }, 1000);
      }
    } catch (error) {
      console.error('error', error);
    }
  }, [enqueueSnackbar, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      fetchCardDetails();
    }
  }, [fetchCardDetails, userCurrentBusinessDetails?.id]);

  const fetchAdsCampaign = useCallback(async () => {
    try {
      setLoading(true);
      const payload = {
        query: quickAdsQuery.getQuickAdsLeads,
        variables: {
          businessId: Number(userCurrentBusinessDetails?.id),
          quickHubAdsAccountId: Number(userCurrentBusinessDetails?.quickHubAdsAccountId),
          searchText: searchTerm,
          pageNumber,
          pageSize,
          campaignStatus: selectedFilterList1,
          status: selectedFilters,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getQuickAdsLeads?.status === 200) {
        setLead(result?.getQuickAdsLeads?.data);
        setTotalCount(result?.getQuickAdsLeads?.totalCount);
      } else if (result?.getQuickAdsLeads?.status === 190) {
        enqueueSnackbar(
          result?.getQuickAdsLeads?.errorMessage || result?.getQuickAdsLeads?.message,
          {
            variant: 'error',
            autoHideDuration: 2000,
          }
        );
        setTimeout(() => {
          history.push(`/quick-ads/ads-social-connect/`);
        }, 1000);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
  }, [
    enqueueSnackbar,
    pageNumber,
    pageSize,
    searchTerm,
    selectedFilterList1,
    selectedFilters,
    userCurrentBusinessDetails?.id,
    userCurrentBusinessDetails?.quickHubAdsAccountId,
  ]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id && userCurrentBusinessDetails?.quickHubAdsAccountId)
      fetchAdsCampaign();
  }, [
    fetchAdsCampaign,
    userCurrentBusinessDetails?.id,
    userCurrentBusinessDetails?.quickHubAdsAccountId,
  ]);

  const getQuickAdsStatus = useCallback(async () => {
    try {
      setLoading(true);
      const payload = {
        query: quickAdsQuery.getQuickAdsStatus,
      };
      const result = await handleApiRequest(payload);
      if (result?.getQuickAdsStatus?.status === 200) {
        setFilterList(result?.getQuickAdsStatus?.data);
      } else if (result?.getQuickAdsStatus?.status === 190) {
        enqueueSnackbar(
          result?.getQuickAdsStatus?.errorMessage || result?.getQuickAdsStatus?.message,
          {
            variant: 'error',
            autoHideDuration: 2000,
          }
        );
        setTimeout(() => {
          history.push(`/quick-ads/ads-social-connect/`);
        }, 1000);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  const getQuickAdsLeadsByLeadId = useCallback(async () => {
    if (!selectedRowData?.id) return;
    try {
      setLoading(true);
      const payload = {
        query: quickAdsQuery.getQuickAdsLeadsByLeadId,
        variables: { leadId: selectedRowData?.id },
      };
      const result = await handleApiRequest(payload);
      if (result?.getQuickAdsLeadsByLeadId?.status === 200) {
        setDrawerStatus(result?.getQuickAdsLeadsByLeadId?.data?.status?.key);
        setSelected(result?.getQuickAdsLeadsByLeadId?.data);
        setSendNotification(result?.getQuickAdsLeadsByLeadId?.data?.sendNotification);
        setScheduledDate(new Date(result?.getQuickAdsLeadsByLeadId?.data?.followUp));
        setScheduledTime(new Date(result?.getQuickAdsLeadsByLeadId?.data?.followUp));
      } else if (result?.getQuickAdsLeadsByLeadId?.status === 190) {
        enqueueSnackbar(
          result?.getQuickAdsLeadsByLeadId?.errorMessage ||
            result?.getQuickAdsLeadsByLeadId?.message,
          {
            variant: 'error',
            autoHideDuration: 2000,
          }
        );
        setTimeout(() => {
          history.push(`/quick-ads/ads-social-connect/`);
        }, 1000);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, selectedRowData?.id]);

  useEffect(() => {
    getQuickAdsLeadsByLeadId();
  }, [getQuickAdsLeadsByLeadId]);

  useEffect(() => {
    const updateQuickAdsLeadsStatus = async () => {
      try {
        setLoading(true);
        const payload = {
          query: quickAdsQuery.updateQuickAdsLeadsStatus,
          variables: {
            followUp:
              scheduledTime && scheduledDate ? formatDateTime(scheduledDate, scheduledTime) : null,
            leadId: selectedRowData?.id,
            sendNotification,
            status: drawerStatus,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.updateQuickAdsLeadsStatus?.status === 200) {
          fetchAdsCampaign();
        }
        if (result?.updateQuickAdsLeadsStatus?.status === 190) {
          enqueueSnackbar(
            result?.updateQuickAdsLeadsStatus?.errorMessage ||
              result?.updateQuickAdsLeadsStatus?.message,
            {
              variant: 'error',
              autoHideDuration: 2000,
            }
          );
          setTimeout(() => {
            history.push(`/quick-ads/ads-social-connect/`);
          }, 1000);
        }
        if (result?.updateQuickAdsLeadsStatus?.status === 400) {
          enqueueSnackbar(
            result?.updateQuickAdsLeadsStatus?.errorMessage ||
              result?.updateQuickAdsLeadsStatus?.message,
            {
              variant: 'error',
              autoHideDuration: 2000,
            }
          );
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };
    if (
      selectedRowData?.id &&
      drawerStatus &&
      scheduledTime &&
      scheduledDate &&
      selectedRowData?.id
    ) {
      updateQuickAdsLeadsStatus();
    }
  }, [
    drawerStatus,
    enqueueSnackbar,
    fetchAdsCampaign,
    scheduledDate,
    scheduledTime,
    selectedRowData?.id,
    sendNotification,
  ]);

  const createOrUpdateLeadNote = async () => {
    if (!selectedRowData?.id) return;
    try {
      setLoading(true);
      const payload = {
        query: quickAdsQuery.createOrUpdateLeadNote,
        variables: { leadId: selectedRowData?.id, leadNoteId, note },
      };
      const result = await handleApiRequest(payload);
      if (result?.createOrUpdateLeadNote?.status === 200) {
        setNote('');
        setLeadNoteId(null);
        getQuickAdsLeadsByLeadId();
        enqueueSnackbar(result?.createOrUpdateLeadNote?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else if (result?.createOrUpdateLeadNote?.status === 190) {
        enqueueSnackbar(
          result?.createOrUpdateLeadNote?.errorMessage || result?.createOrUpdateLeadNote?.message,
          {
            variant: 'error',
            autoHideDuration: 2000,
          }
        );
        setTimeout(() => {
          history.push(`/quick-ads/ads-social-connect/`);
        }, 1000);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
  };

  const deleteQuickAdsLeadNote = async (id) => {
    try {
      setLoading(true);
      const payload = {
        query: quickAdsQuery.deleteQuickAdsLeadNote,
        variables: { leadNoteId: id },
      };
      const result = await handleApiRequest(payload);
      if (result?.deleteQuickAdsLeadNote?.status === 200) {
        getQuickAdsLeadsByLeadId();
        enqueueSnackbar(result?.deleteQuickAdsLeadNote?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else if (result?.deleteQuickAdsLeadNote?.status === 190) {
        enqueueSnackbar(
          result?.deleteQuickAdsLeadNote?.errorMessage || result?.deleteQuickAdsLeadNote?.message,
          {
            variant: 'error',
            autoHideDuration: 2000,
          }
        );
        setTimeout(() => {
          history.push(`/quick-ads/ads-social-connect/`);
        }, 1000);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectFilter = (filter) => {
    const filterValue = typeof filter === 'object' && filter !== null ? filter?.key : filter;
    if (filterList1.includes(filterValue)) {
      setSelectedFilterList1((prevFilters) =>
        prevFilters.includes(filterValue)
          ? prevFilters.filter((item) => item !== filterValue)
          : [...prevFilters, filterValue]
      );
    } else {
      setSelectedFilters((prevFilters) =>
        prevFilters.includes(filterValue)
          ? prevFilters.filter((item) => item !== filterValue)
          : [...prevFilters, filterValue]
      );
    }
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const handleDrawer = (rowData) => {
    setSelectedRowData(rowData);
    setOpenDrawer(true);
  };

  const handleClosePopup = () => {
    setOpenDrawer(false);
    setDrawerStatus(null);
    setSendNotification(null);
    setScheduledDate(null);
    setScheduledTime(null);
    setSelected({});
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleTimeChange = (event) => {
    setScheduledTime(event);
  };

  const handleDateChange = (data) => {
    setScheduledDate(data);
  };

  const isDateError = () => {
    if (!scheduledDate) return false;

    const today = new Date();
    const selectedDate = new Date(scheduledDate);

    return selectedDate < today.setHours(0, 0, 0, 0);
  };

  const isTimeError = () => {
    if (!scheduledDate || !scheduledTime) return false;

    const now = new Date();
    const selectedDate = new Date(scheduledDate);

    if (
      selectedDate?.getFullYear() === now.getFullYear() &&
      selectedDate?.getMonth() === now.getMonth() &&
      selectedDate?.getDate() === now.getDate()
    ) {
      const selectedTime = new Date();
      selectedTime?.setHours(scheduledTime?.getHours(), scheduledTime?.getMinutes(), 0, 0);
      return selectedTime < now;
    }

    return false;
  };

  const formatDateTime = (date, time) => {
    const originalDate = new Date(date);
    const formattedDat = originalDate?.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const [month, day, year] = formattedDat.split('/');
    const desiredFormat = `${year}-${month}-${day}`;

    const setTime = time.toLocaleTimeString();

    const dateTimeString = `${desiredFormat} ${setTime}`;
    const dateObj = new Date(dateTimeString);
    dateObj?.setHours(dateObj.getHours() + 5);
    dateObj?.setMinutes(dateObj.getMinutes() + 30);
    const offset = dateObj.getTimezoneOffset();
    const hoursOffset = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0');
    const minutesOffset = String(Math.abs(offset) % 60).padStart(2, '0');
    const sign = offset > 0 ? '-' : '+';
    const formattedDate = dateObj
      ?.toISOString()
      ?.replace(/:\d{2}\.\d{3}Z$/, '')
      ?.concat(`${sign}${hoursOffset}:${minutesOffset}`);
    return formattedDate;
  };

  const onLoad = (autocompleteData) => {
    setAutocompleteMap(autocompleteData);
  };

  const onPlacesChanged = async () => {
    setDataLoading(true);
    if (autocompleteMap !== null) {
      const place = autocompleteMap?.getPlace();
      const newLocation = {
        name: place?.name,
        lat: place?.geometry?.location?.lat(),
        lng: place?.geometry?.location?.lng(),
      };
      setCurrentLocation((prev) => {
        const isDuplicate = prev?.some(
          (loc) => loc?.lat === newLocation?.lat && loc?.lng === newLocation?.lng
        );
        if (!isDuplicate) {
          return [...prev, newLocation];
        }
        return prev;
      });
      if (!place?.place_id) {
        enqueueSnackbar(t('manageBusiness.addBusinessSteps.businessLocation.selectData'), {
          variant: 'error',
          autoHideDuration: 3000,
        });
        setDataLoading(false);
        return;
      }
      setSelectedMapFullAddress(place?.formatted_address);
      let formattedAddress = '';
      try {
        formattedAddress = place?.formatted_address;
      } catch (error) {
        console.error('An error occurred', error);
      }
      setDataLoading(false);
    }
  };

  const onPlaceSearch = (event) => {
    setSelectedMapFullAddress(event.target.value);
  };

  const handleChangePage = (event, value) => {
    setPage(value + 1);
  };

  const handleChangeRowsPerPage = (event, value) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  return (
    <div className="lg:p-24 p-16 h-full">
      <div className="flex justify-between items-center mb-20">
        <Typography className="text-20 sm:text-28 font-bold">{t('navigation.leads')}</Typography>
        {(connected || lead?.length > 0) && (
          <div className="flex items-start sm:items-center flex-col sm:flex-row gap-10 sm:gap-0 md:w-auto w-full md:justify-normal justify-between quick-social">
            <div className="flex sm:w-auto w-full">
              <TextField
                placeholder="Search"
                variant="outlined"
                margin="normal"
                value={searchTerm}
                disabled={!lead}
                className="bg-gray-A500 border-none rounded-sm border-grey-A500 m-0 max-h-40 sm:w-320 w-full"
                onChange={(e) => {
                  setPage(1);
                  setSearchTerm(e?.target?.value);
                }}
                InputProps={{
                  endAdornment: searchTerm ? (
                    <InputAdornment position="end">
                      <Icon
                        color="quickSocial"
                        className="cursor-pointer text-20"
                        onClick={handleClearSearch}
                      >
                        {t(`quickAds.close`)}
                      </Icon>
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="relative">
              <Button
                className="rounded-md w-28 min-w-28 p-20 h-24 min-h-24 mx-16"
                color="quickSocial"
                variant="contained"
                disabled={!lead || !connected}
                onClick={() => {
                  setShowFilterOption((prev) => !prev);
                  getQuickAdsStatus();
                }}
              >
                <FilterListIcon />
              </Button>
              <div className="quick-social">
                {showFilterOption && (
                  <div className="absolute z-10 top-60 right-16 bg-gray-A500 min-w-320 w-full rounded-md shadow p-14">
                    <div className="flex items-center justify-between mb-12">
                      <h3 className="text-20 font-semibold">
                        {' '}
                        {t(`users.UserList.Filter.mainBtn`)}
                      </h3>
                      <Icon
                        className="cursor-pointer text-18"
                        aria-hidden
                        onClick={() => setShowFilterOption(false)}
                      >
                        {t(`quickAds.close`)}
                      </Icon>
                    </div>
                    <Typography className="text-20 font-semibold">
                      {t(`quickAds.campaigns`)}
                    </Typography>
                    {filterList1 &&
                      filterList1?.map((list, index) => (
                        <div className="flex items-center justify-between mt-10" key={index}>
                          <Typography className="text-16 text-grey-800 font-medium capitalize">
                            {list.charAt(0).toUpperCase() + list.slice(1).toLowerCase()}
                          </Typography>
                          <Checkbox
                            className="p-0 text-grey-300 rounded-md"
                            checked={selectedFilterList1?.includes(list)}
                            onChange={() => handleSelectFilter(list)}
                          />
                        </div>
                      ))}
                    <hr className="mt-10 mb-10" />
                    <Typography className="text-20 font-semibold">{t(`quickAds.leads`)}</Typography>
                    {filterList &&
                      filterList?.map((list, index) => (
                        <div className="flex items-center justify-between mt-10 " key={index}>
                          <Typography className="text-16 text-grey-800 font-medium capitalize">
                            {list?.viewName}
                          </Typography>
                          <Checkbox
                            className="p-0 text-grey-300 rounded-md"
                            checked={selectedFilters?.includes(list?.key)}
                            onChange={() => handleSelectFilter(list)}
                          />
                        </div>
                      ))}
                    <div className="flex mt-16 gap-12 justify-end">
                      <Button
                        className="rounded-md py-0 max-w-128 w-full text-16 font-medium min-h-32 bg-quick-social-100"
                        size="small"
                        variant="outlined"
                        color="quickSocial"
                        type="button"
                        onClick={() => {
                          setSelectedFilters([]);
                          setSelectedFilterList1([]);
                          setShowFilterOption(false);
                        }}
                      >
                        {t(`quickCampaign.campaign.clearFilter`)}
                        <Icon className="text-16">{t(`quickAds.close`)}</Icon>
                      </Button>
                      <Button
                        className="rounded-md py-0 max-w-128 w-full text-16 font-medium min-h-32"
                        size="small"
                        variant="contained"
                        color="quickSocial"
                        onClick={() => {
                          fetchAdsCampaign();
                          setShowFilterOption(false);
                        }}
                        type="button"
                      >
                        {t('quickChat.apply')}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {connected && lead?.length > 0 ? (
        <TableContainer
          component={Paper}
          className="rounded-md border-1 border-solid border-grey-A500 shadow"
        >
          <Table>
            <TableHead className="border-b-2 border-grey-400">
              <TableRow>
                <TableCell className="font-bold text-18">
                  {t('quickCampaign.campaign.srNo')}
                </TableCell>
                <TableCell className="font-bold  text-18">
                  {t(`quickAds.lead`)} {t('common.name')}
                </TableCell>
                <TableCell className="font-bold  text-18">
                  {t('quickCampaign.campaign.campaignName')}
                </TableCell>
                <TableCell className="font-bold text-18">{t(`quickAds.adPlatform`)}</TableCell>
                <TableCell className="font-bold  text-18">
                  {t('quickCampaign.campaign.date')}
                </TableCell>
                <TableCell className="font-bold  text-18">{t('common.emailId')}</TableCell>
                <TableCell className="font-bold  text-18">{t('common.phone')}</TableCell>
                <TableCell className="font-bold  text-18">{t('common.status')}</TableCell>
                <TableCell className="font-bold  text-18">{t(`quickAds.followUpAction`)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lead?.length > 0 &&
                lead?.map((leads, index) => (
                  <TableRow
                    key={index}
                    onClick={() => handleDrawer(leads)}
                    className="cursor-pointer hover:bg-quick-social-100 hover:bg-opacity-30 border-b-2"
                  >
                    <TableCell className="font-medium text-16 p-12">{index + 1}</TableCell>
                    <TableCell className="font-medium text-16 p-12">
                      {leads?.formData?.find((item) => item?.name === 'full_name')?.value}
                    </TableCell>
                    <TableCell className="font-medium text-16 p-12">
                      {leads?.campaignName}
                    </TableCell>
                    <TableCell className="font-medium text-16 p-12">
                      <p>{leads?.shareOnFacebook && 'Facebook'}</p>
                      <p> {leads?.shareOnInstagram && 'Instagram'}</p>
                    </TableCell>
                    <TableCell className="font-medium text-16 p-12">
                      {formatDateWithTimeZone(leads?.createdTime)}
                    </TableCell>
                    <TableCell className="font-medium text-16 p-12">
                      {leads?.formData?.find((item) => item?.name === 'email')?.value}
                    </TableCell>
                    <TableCell className="font-medium text-16 p-12">
                      {leads?.formData?.find((item) => item?.name === 'phone_number')?.value}
                    </TableCell>
                    <TableCell className="p-12 border-b-0">
                      <span
                        className={`rounded-full text-14 font-medium text-center px-10 py-5 ${
                          leads?.status?.key === 'in_progress'
                            ? 'bg-quick-social-100 text-quick-social border-1 border-quick-social'
                            : leads?.status?.key === 'converted'
                            ? 'bg-quick-campaign-100 text-quick-campaign border-1 border-quick-campaign'
                            : leads?.status?.key === 'pending_response'
                            ? 'bg-red-100 text-[#DE3636] border-1 border-[#DE3636]'
                            : leads?.status?.key === 'new_leads'
                            ? 'bg-green-100 text-quick-review border-1 border-quick-review'
                            : leads?.status?.key === 'closed'
                            ? 'bg-gray-A500 text-gray border-1 border-gray'
                            : leads?.status?.key === 'interested'
                            ? 'bg-[#f1cdea] text-[#C837AB] border-1 border-[#C837AB]'
                            : 'bg-yellow-100 text-quick-chat border-1 border-quick-chat'
                        }`}
                      >
                        {leads?.status?.viewName}
                      </span>
                    </TableCell>
                    <TableCell className="font-medium text-16 p-12">{leads?.lastAction}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : searchTerm && lead?.length > 0 ? (
        <div className="flex flex-col items-center justify-center max-w-384 mx-auto custom-height">
          <img
            src="assets/images/quick-hub/quick-ads/no-leads.png"
            alt="No ads"
            className="h-320 w-320 mb-16"
          />
          <div className="text-28 font-bold mb-16">{t('quickCampaign.campaign.noLeadsYet')}</div>
          <p className="text-17 font-500 mb-16 text-gray-700 text-center">
            {t('quickAds.noLeadsContent')}
          </p>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center max-w-384 mx-auto custom-height">
          <img
            src="assets/images/quick-hub/quick-ads/no-leads.png"
            alt="No ads"
            className="h-320 w-320 mb-16"
          />
          <div className="text-28 font-bold mb-16">{t('quickCampaign.campaign.noLeadsYet')}</div>
          <p className="text-17 font-500 mb-16 text-gray-700 text-center">
            {t('quickAds.noLeadsContent')}
          </p>
          <Button
            variant="outlined"
            color="quickSocial"
            size="medium"
            disabled={!connected}
            onClick={() => setOpenSelectObjective(true)}
            className="mb-16 rounded-md text-16 font-500 bg-quick-social-100 text-black"
          >
            {t('quickCampaign.campaign.createYourFirstCampaign')}
          </Button>
        </div>
      )}
      {openDrawer && drawerStatus && (
        <Drawer
          anchor="right"
          open={openDrawer}
          onClose={handleClosePopup}
          sx={{ '& .MuiDrawer-paper': { width: '750px' } }}
        >
          <div className="p-16 flex justify-between items-center">
            <div className="flex gap-10 items-center">
              <h2 className="text-20 font-600 mb-4 items-center flex">
                {t('quickAds.leadDetails')}
              </h2>
            </div>
            <div>
              <IconButton className="" onClick={handleClosePopup} color="inherit">
                <CloseIcon className="w-24 h-24 text-black" />
              </IconButton>
            </div>
          </div>
          <div className="p-16 bg-gray-A500 w-full">
            <div className=" mb-20 flex w-full gap-16">
              <div className="">
                <img src={selected?.mediaUrl} alt="trial_accounts" className="w-224 h-224" />
              </div>
              <div className="w-full gap-4">
                <div className="w-full flex justify-between items-center mb-12">
                  <Typography className="text-20 font-semibold text-quick-social capitalize">
                    {selected?.campaignName}
                  </Typography>
                  <div className="gap-10 flex">
                    {selected?.shareOnFacebook && (
                      <img
                        src="assets/images/icon/facebook.png"
                        className="w-28 h-28"
                        alt="facebook"
                      />
                    )}
                    {selected?.shareOnInstagram && (
                      <img
                        src="assets/images/icon/instagram.png"
                        className="w-28 h-28"
                        alt="instagram"
                      />
                    )}
                  </div>
                </div>
                <div className="w-full flex flex-col bg-white p-10 border-1 rounded-md border-grey-400">
                  <div className="mb-10">
                    <Typography className="text-20 font-medium text-gray">
                      {t(`quickAds.lead`)} {t('common.name')}:
                    </Typography>
                    <Typography className="text-24 font-semibold capitalize">
                      {selected?.formData?.find((item) => item?.name === 'full_name')?.value}
                    </Typography>
                  </div>
                  <div className="flex justify-between w-full mb-16">
                    <p
                      className={`font-semibold ${
                        selected?.status?.key === 'in_progress'
                          ? 'bg-quick-social-100 text-quick-social border-1 border-quick-social'
                          : selected?.status?.key === 'converted'
                          ? 'bg-quick-campaign-100 text-quick-campaign border-1 border-quick-campaign'
                          : selected?.status?.key === 'pending_response'
                          ? 'bg-red-100 text-[#DE3636] border-1 border-[#DE3636]'
                          : selected?.status?.key === 'new_leads'
                          ? 'bg-green-100 text-quick-review border-1 border-quick-review'
                          : selected?.status?.key === 'closed'
                          ? 'bg-gray-A500 text-gray border-1 border-gray'
                          : selected?.status?.key === 'interested'
                          ? 'bg-[#f1cdea] text-[#C837AB] border-1 border-[#C837AB]'
                          : 'bg-yellow-100 text-quick-chat border-1 border-quick-chat'
                      } rounded-full text-14 text-center px-10 py-5`}
                    >
                      {selected?.status?.viewName}
                    </p>
                    <Typography className="text-gray text-18 font-500">
                      {formatDateWithTimeZone(
                        lead?.find((item) => item?.id === selected?.id)?.createdTime
                      )}
                    </Typography>
                  </div>
                  <div className="flex justify-between">
                    <a
                      href={`tel:${
                        Number(
                          selected?.formData?.find((item) => item?.name === 'phone_number')?.value
                        ) || ''
                      }`}
                    >
                      <Button
                        variant="outlined"
                        color="quickSocial"
                        className="rounded-md text-15 font-semibold bg-quick-social-100 py-24 border-2 border-solid border-quick-social hover:border-2"
                      >
                        <div className="bg-quick-social text-white rounded-full p-5 mr-5 flex justify-center items-center">
                          <CallOutlinedIcon className="w-20 h-20" />
                        </div>
                        {t(`quickAds.callNow`)}
                      </Button>
                    </a>
                    <a
                      href={`mailto:${
                        selected?.formData?.find((item) => item?.name === 'email')?.value || ''
                      }`}
                    >
                      <Button
                        variant="outlined"
                        color="quickSocial"
                        className="rounded-md text-15 font-semibold bg-quick-social-100 py-24 border-2 border-solid border-quick-social hover:border-2"
                      >
                        <div className="bg-quick-social text-white rounded-full p-4 mr-5 flex justify-center items-center">
                          <EmailOutlinedIcon className="w-20 h-20" />
                        </div>
                        {t(`quickAds.sendMail`)}
                      </Button>
                    </a>
                    <Button
                      variant="outlined"
                      color="quickSocial"
                      className="rounded-md text-15 font-semibold bg-quick-social-100 py-24 border-2 border-solid border-quick-social hover:border-2"
                      onClick={() => {
                        const phoneNumber =
                          Number(
                            selected?.formData?.find((item) => item?.name === 'phone_number')?.value
                          ) || '';
                        const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
                        window.open(whatsappLink, '_blank', 'noopener noreferrer');
                      }}
                    >
                      <div className="bg-quick-social text-white rounded-full p-5 border-2 mr-5 flex justify-center items-center">
                        <WhatsAppIcon className="w-20 h-20" />
                      </div>
                      {t(`quickAds.whatsapp`)}
                    </Button>
                  </div>
                </div>
              </div>
              <div>
                <></>
              </div>
            </div>
            <div className=" flex mb-20 bg-white border-1 border-solid border-gray-A500 shadow rounded-md p-10 justify-between">
              <div>
                <h3 className="font-semibold mb-14 text-18">
                  {' '}
                  {t(`quickCampaign.campaign.contact`)}{' '}
                  {t(`comparePlanAndFeature.comparePlanAndFeatureList.column.information`)}{' '}
                </h3>
                <div className="flex mb-5">
                  {' '}
                  <EmailOutlinedIcon />{' '}
                  <p className="text-16 font-500 ml-9"> {t(`common.emailId`)} - </p>{' '}
                  <p className="text-16 font-semibold ml-5">
                    {' '}
                    {selected?.formData?.find((item) => item?.name === 'email')?.value}
                  </p>
                </div>
                <div className="flex mb-5">
                  {' '}
                  <CallOutlinedIcon />{' '}
                  <p className="text-16 font-500 ml-9"> {t(`common.phone`)} - </p>{' '}
                  <p className="text-16 font-semibold ml-5">
                    {' '}
                    {selected?.formData?.find((item) => item?.name === 'phone_number')?.value}
                  </p>
                </div>
              </div>
              <div className="w-360 h-112">
                <LoadScript
                  id="script-loader"
                  googleMapsApiKey={key}
                  libraries={lib}
                  loadingElement={<div>{t(`business.searchBusiness.labels.loading`)}</div>}
                >
                  <GoogleMap
                    mapContainerStyle={{
                      height: '112px',
                      width: '100%',
                      position: 'relative',
                    }}
                    className="custom-height rounded-lg"
                    zoom={8}
                    center={
                      currentLocation[
                        currentLocation?.length > 1 ? Number(currentLocation?.length) - 1 : 0
                      ]
                    }
                    options={{
                      fullscreenControl: false,
                      mapTypeControl: false,
                      streetViewControl: false,
                      zoomControl: false,
                    }}
                  >
                    {currentLocation?.map((marker, index) => (
                      <Marker key={index} position={marker} />
                    ))}
                    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlacesChanged} zIndex="999">
                      <StandaloneSearchBox zIndex="999">
                        <input
                          type="text"
                          value={selectedMapFullAddress}
                          onChange={(e) => onPlaceSearch(e)}
                          hidden
                          placeholder={t(
                            'manageBusiness.addBusinessSteps.businessLocation.searchYourName'
                          )}
                          style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            width: `300px`,
                            height: `32px`,
                            padding: `0 12px`,
                            borderRadius: `5px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `14px`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                            position: 'absolute',
                            margin: '0 auto',
                            top: '14px',
                            left: '0',
                            right: '0',
                            zIndex: '1',
                          }}
                        />
                      </StandaloneSearchBox>
                    </Autocomplete>
                  </GoogleMap>
                </LoadScript>
              </div>
            </div>
            <div className="flex w-full gap-20">
              {drawerStatus && (
                <div className="w-1/2 py-10">
                  <p className="text-18 font-500 mb-6"> {t(`common.status`)} </p>
                  <Select
                    className="w-full border-1 border-solid border-gray-A500 shadow bg-white"
                    value={drawerStatus}
                    onChange={(e) => setDrawerStatus(e.target.value)}
                    displayEmpty
                    size="medium"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#f5f6fa',
                        },
                        '&:hover fieldset': {
                          borderColor: '#219EBC',
                        },
                      },
                      '& .MuiSelect-select': {
                        fontWeight: '600',
                        lineHeight: '22px',
                        fontSize: 16,
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      {t('quickAds.select')}
                    </MenuItem>
                    <MenuItem value="pending_response" className="text-16">
                      {' '}
                      <span className="bg-red h-10 w-10 rounded-full mr-5"> </span>{' '}
                      {t('quickAds.pendingResponse')}
                    </MenuItem>
                    <MenuItem value="converted" className="text-16">
                      {' '}
                      <span className="bg-[#876AFE] h-10 w-10 rounded-full mr-5"> </span>{' '}
                      {t('dashboard.dashboardSales.converted')}{' '}
                    </MenuItem>
                    <MenuItem value="in_progress" className="text-16">
                      <span className="bg-quick-social h-10 w-10 rounded-full mr-5"> </span>{' '}
                      {t('metrics.inProgress')}
                    </MenuItem>
                    <MenuItem value="new_leads" className="text-16">
                      {' '}
                      <span className="bg-green h-10 w-10 rounded-full mr-5"> </span>{' '}
                      {t('quickAds.newLead')}
                    </MenuItem>
                    <MenuItem value="follow_up_needed" className="text-16">
                      <span className="bg-[#FF9A3D] h-10 w-10 rounded-full mr-5"> </span>{' '}
                      {t('quickAds.followUp')}
                      Needed{' '}
                    </MenuItem>
                    <MenuItem value="interested" className="text-16">
                      <span className="bg-[#FF3DF2] h-10 w-10 rounded-full mr-5"> </span>
                      {t('quickAds.interested')}
                    </MenuItem>
                    <MenuItem value="closed" className="text-16">
                      <span className="bg-[#B4B4B4] h-10 w-10 rounded-full mr-5"> </span>{' '}
                      {t('quickAds.closed')}
                    </MenuItem>
                  </Select>
                </div>
              )}
              <div className="w-1/2">
                <div className="flex justify-between items-center">
                  <p className="text-18 font-500"> {t('quickAds.followUpReminder')}</p>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sendNotification}
                        onChange={() => {
                          setSendNotification(!sendNotification);
                        }}
                      />
                    }
                    label={<p className="text-14 font-500 "> {t('quickAds.sendNotification')}</p>}
                    labelPlacement="start"
                  />
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  onClick={handleToggle}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleToggle();
                    }
                  }}
                  className="bg-white  border-1 border-solid border-gray-A500 shadow flex justify-between p-12 "
                >
                  {scheduledTime && scheduledDate ? (
                    <p className="text-18 font-500">
                      {' '}
                      {formatDateWithTimeZone(
                        formatDateTime(scheduledDate, scheduledTime),
                        ' MMM DD YYYY, HH:MM a'
                      )}
                    </p>
                  ) : (
                    <p className="text-16 font-medium">{t('quickAds.setDate&Time')} </p>
                  )}
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={handleToggle}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleToggle();
                      }
                    }}
                    className="cursor-pointer focus:outline-none"
                    aria-label="Toggle menu"
                  >
                    <ArrowForwardIosIcon
                      className={`transition-transform duration-300 ${isOpen ? 'rotate-90' : ''}`}
                    />
                  </div>
                </div>
                {isOpen && (
                  <div className="bg-white p-10 absolute z-10 w-360 shadow-2 shadow-grey">
                    <div className="mb-20">
                      <Typography className="font-semibold mb-10 mt-0 text-14 lg:text-18">
                        {t('sendMessage.selectDate')}
                      </Typography>
                      <div className="mt-4">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            className="w-full select-date bg-gray-A500 rounded-md"
                            id="note"
                            variant="contained"
                            fullWidth
                            disablePast
                            value={scheduledDate}
                            format="MMM dd, yyyy"
                            onChange={handleDateChange}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: '#f5f6fa',
                                },
                                '&:hover fieldset': {
                                  borderColor: '#219EBC',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#219EBC',
                                },
                              },
                              '& .MuiSvgIcon-root': {
                                color: '#219EBC',
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                      {isDateError() && (
                        <Typography className="text-red-500 text-sm mt-2">
                          {t('quickAds.pleaseSelectAValidDate')}
                        </Typography>
                      )}
                    </div>
                    <div className="">
                      <Typography className="font-semibold mb-10 mt-0 text-14 lg:text-18">
                        {t('sendMessage.selectTime')}
                      </Typography>
                      <div className="mt-4">
                        <TimePicker
                          className="w-full select-date bg-gray-A500 rounded-md"
                          id="time"
                          onChange={handleTimeChange}
                          size="small"
                          value={scheduledTime}
                          required
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#f5f6fa',
                              },
                              '&:hover fieldset': {
                                borderColor: '#219EBC',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#219EBC',
                              },
                            },
                            '& .MuiSvgIcon-root': {
                              color: '#219EBC',
                            },
                          }}
                        />
                      </div>
                      {isTimeError() && (
                        <Typography className="text-red-500 text-sm mt-2">
                          {t('quickAds.validTime')}
                        </Typography>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              <p className="text-18 font-500">{t('common.note')}</p>
              <div className="relative w-full mb-20">
                <TextField
                  className="w-full border-1 border-solid border-gray-A500 shadow bg-white"
                  value={note}
                  size="medium"
                  rows={3}
                  multiline
                  onChange={(e) => setNote(e.target.value)}
                  placeholder="Enter notes here.."
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: '#219EBC',
                      },
                    },
                    '& .MuiInputBase-input': {
                      fontWeight: '600',
                      lineHeight: '22px',
                      fontSize: 16,
                    },
                  }}
                />
                {note && (
                  <Button
                    color="quickSocial"
                    variant="contained"
                    className="absolute bottom-10 right-10 bg-quick-social text-white px-4 py-1 rounded-md shadow-md"
                    onClick={() => createOrUpdateLeadNote(note)}
                  >
                    {t('common.save')}
                  </Button>
                )}
              </div>
              <div className="mb-14">
                {selected?.notes &&
                  selected?.notes?.length > 0 &&
                  selected?.notes?.map((notes, index) => (
                    <div
                      key={index}
                      className="bg-white mb-10 p-16  border-1 border-solid border-gray-A500 shadow rounded-sm"
                    >
                      {' '}
                      <p className="text-16 font-500 mb-12 "> {notes?.note}</p>
                      <div className="flex justify-between">
                        {' '}
                        <p className="text-18 text-gray">
                          {formatDateWithTimeZone(notes?.cts)}{' '}
                        </p>{' '}
                        <div className="flex justify-center items-center gap-16">
                          <div
                            role="button"
                            tabIndex="0"
                            onClick={() => {
                              setNote(notes?.note);
                              setLeadNoteId(notes?.id);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === 'Space') {
                                setNote(notes?.note);
                                setLeadNoteId(notes?.id);
                              }
                            }}
                            aria-label="Delete"
                          >
                            <img
                              src="/assets/images/business/details1.png"
                              alt="del"
                              className="h-28 w-28"
                              style={{
                                filter: 'brightness(0) saturate(100%)',
                              }}
                            />
                          </div>
                          <div
                            role="button"
                            tabIndex="0"
                            onClick={() => deleteQuickAdsLeadNote(notes?.id)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === 'Space') {
                                deleteQuickAdsLeadNote(notes?.id);
                              }
                            }}
                            aria-label="Delete"
                          >
                            <img
                              src="/assets/images/business/delete.svg"
                              alt="del"
                              style={{
                                filter: 'brightness(0) saturate(100%)',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div>
              <h3 className="text-18 font-semibold text-gray-800 mb-20">
                {t('quickAds.activity')}
              </h3>
              {selected?.activity &&
                selected?.activity?.length > 0 &&
                selected?.activity
                  ?.slice()
                  ?.reverse()
                  ?.map((activity, index) => (
                    <div
                      key={index}
                      className="!border-l-2 !border-quick-social !border-solid relative pl-10 pb-24 items-start last:pb-0"
                    >
                      <span className="bg-quick-social rounded-full -left-6  absolute h-10 w-10">
                        {' '}
                      </span>
                      <p className="text-grey font-semibold text-18 relative -top-5">
                        {formatDateWithTimeZone(activity?.cts)}
                      </p>
                      <p className="text-black text-16 ">{activity?.activity}</p>
                    </div>
                  ))}
            </div>
          </div>
        </Drawer>
      )}

      {connected && totalCount > 10 && (
        <div className="bg-white px-52 py-32 bottom-0 right-0 w-full flex justify-end">
          <Pagination
            totalCount={totalCount}
            rowsPerPage={pageSize}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      )}

      {openSelectObjective && (
        <SelectObjective
          openValue={openSelectObjective}
          cancelCall={() => setOpenSelectObjective(false)}
        />
      )}
    </div>
  );
};

export default Leads;
