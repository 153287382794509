import { Button, Dialog, DialogContent, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import history from '@history';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

export const AddBusinessDialog = (props) => {
  const { isAddBusinessDialog, setAddBusinessDialog } = props;
  return (
    <Dialog
      open={isAddBusinessDialog}
      aria-labelledby="delete-confirmation-dialog"
      classes={{
        paper: `m-24 business-type-dropdown bg-gray-A500 text-center rounded-md max-w-480`,
      }}
    >
      <DialogContent className="p-16 text-start">
        <div className="text-right">
          <CloseIcon onClick={() => setAddBusinessDialog(false)} />
        </div>
        <div className="text-center w-full px-16 pb-20">
          <img className="m-auto w-200" src="/assets/images/business/business.png" alt="" />
          <Typography className="font-bold text-20 py-16">
            {t('quickHubDashboard.addYourBusiness')}
          </Typography>
          <Typography className="font-medium text-16 text-center">
            {t('quickHubDashboard.addBusinessMessage')}
          </Typography>
          <div className="flex gap-12 justify-center mt-20">
            <Button
              className="rounded-md w-160 bg-quick-hub text-white hover:bg-quick-hub font-semibold text-16"
              variant="contained"
              onClick={() => {
                history.push('manage-business');
              }}
            >
              <AddIcon /> {t('manageBusiness.addBusiness')}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
