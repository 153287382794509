import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { t } from 'i18next';
import React from 'react';

const TagsAddEditPopUp = ({
  open,
  onClose,
  onClick,
  from,
  refresh,
  backgroundColor,
  inputValue,
  setInputValue,
  journey,
  setJourney,
  firstMessage,
  setFirstMessage,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paper: `m-24 p-28 business-type-dropdown rounded-md`,
      }}
      PaperProps={{ style: { maxWidth: '700px' } }}
    >
      <div className="flex justify-between items-center mb-32">
        <Typography className="text-24 font-semibold">
          {from === 'edit' ? 'Edit Tag' : 'Add Tag'}
        </Typography>
        <IconButton className="" onClick={onClose} color="inherit">
          <CloseIcon className="w-24 h-24 text-grey-700" />
        </IconButton>
      </div>
      <div className="overflow-scroll">
        <DialogContent className="p-0">
          <div className="mb-24">
            <Typography className="mb-16 text-16 font medium">
              {t('waba.setting.tagName')}
            </Typography>
            <TextField
              placeholder="Enter Tag Name..."
              className="md:w-586 w-256 p-0 quick-chat"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
          <DialogContent className="p-0 mb-40">
            <div className=" w-full flex justify-between items-center mb-24">
              <div>
                <Typography className="text-16 font-medium text-black ">
                  {t('waba.setting.customerJourney')}
                </Typography>
                <Typography className="text-12 font-medium text-gray-400 ">
                  {t('waba.setting.customerJourneyContent')}
                </Typography>
              </div>
              <Switch checked={journey} onChange={() => setJourney(!journey)} />
            </div>
            <div className=" w-full flex justify-between items-center">
              <div>
                <Typography className="text-16 font-medium text-black ">
                  {t('waba.setting.firstMessage')}
                </Typography>
                <Typography className="text-12 font-medium text-gray-400 ">
                  {t('waba.setting.firstMessageContent')}
                </Typography>
              </div>
              <Switch checked={firstMessage} onChange={() => setFirstMessage(!firstMessage)} />
            </div>
          </DialogContent>
          <DialogActions className="p-0 flex justify-end gap-14">
            <Button
              className={`md:text-16 font-medium rounded-md !border-${backgroundColor} text-${backgroundColor} hover:text-${backgroundColor} w-full max-w-160`}
              variant="outlined"
              color="secondary"
              onClick={onClose}
              style={{
                borderColor: backgroundColor,
              }}
              size="small"
            >
              {t('common.cancel')}
            </Button>

            <Button
              className={`md:text-16 font-medium rounded-md bg-${backgroundColor} text-white w-full max-w-160`}
              variant="contained"
              disabled={refresh || !inputValue}
              color="secondary"
              onClick={onClick}
              size="small"
            >
              {from === 'edit' ? t('common.save') : t('listManagement.add')}
              {refresh && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          </DialogActions>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default TagsAddEditPopUp;
