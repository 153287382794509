import { Button, Dialog, DialogContent, Pagination, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';
import wabaQuery from '../../../../../query/waba';
import { capitalizeFirstLetter, handleWabaApiRequest } from '../../../../../common/common';
import { ViewTemplate } from '../../common/Popup';

const SelectTemplate = (props) => {
  const {
    openSelectTemplateDialogue,
    setOpenSelectTemplateDialogue,
    type,
    handleTemplateSelect,
    setTemplate,
    setImageUrl,
    selectedVariables,
    setViewTemplate,
    setSelectedVariables,
  } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [templates, setTemplates] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [viewDialogue, setViewDialogue] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState();

  useEffect(() => {
    const getWhatsappTemplate = async () => {
      try {
        if (userCurrentBusinessDetails?.id) {
          const payload = {
            query: wabaQuery.getWhatsappTemplate,
            variables: {
              businessId: Number(userCurrentBusinessDetails?.id),
              pageNumber: page,
              pageSize: 12,
            },
          };
          const res = await handleWabaApiRequest(payload);
          if (
            res?.getWhatsappTemplate?.status === 200 &&
            res?.getWhatsappTemplate?.data?.length > 0
          ) {
            const parsedData = res.getWhatsappTemplate.data.map((item) => ({
              ...item,
              dynamicData: item.dynamicData ? JSON.parse(item.dynamicData) : {},
              templateData: item.templateData ? JSON.parse(item.templateData) : {},
            }));
            const processedTemplates = parsedData.map((template) => {
              const bodyText = template?.templateData?.components?.find(
                (item) => item?.type === 'BODY'
              )?.text;
              const updatedBodyText = bodyText?.replace(/\{\{(\d+)\}\}/g, (match, key) => {
                const replacement = template?.dynamicData?.body?.find(
                  (item) => `{{${item.key}}}` === match
                )?.description;
                return `[${replacement}]` || match;
              });
              return {
                ...template,
                updatedBodyText,
              };
            });
            setTemplates(processedTemplates);
            setTotalCount(res.getWhatsappTemplate?.totalCount);
          }
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };
    getWhatsappTemplate();
  }, [userCurrentBusinessDetails?.id, page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div>
      <Dialog
        open={openSelectTemplateDialogue}
        aria-labelledby="delete-confirmation-dialog"
        classes={{
          paper: `m-24 business-type-dropdown bg-gray-A500 rounded-md`,
        }}
        PaperProps={{
          style: {
            width: '100%',
            overflowY: 'auto',
            maxWidth: '1650px',
            height: '100%',
            maxHeight: '900px',
          },
        }}
      >
        <div className="flex items-center justify-between pl-16 pt-10">
          <Typography className="text-start font-bold text-24">
            {t('waba.broadcast.myBroadcast.selectTemplate')}
          </Typography>
          <Button
            className="hover:bg-transparent"
            onClick={() => {
              setOpenSelectTemplateDialogue(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
        <hr className="mt-16" />
        <DialogContent className="p-16">
          {templates?.length > 0 && (
            <>
              <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 col-span-1 gap-20">
                {templates?.map((item, index) => {
                  return (
                    <div
                      className="bg-white"
                      key={index}
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setViewDialogue(true);
                        setSelectedTemplate(item);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          setViewDialogue(true);
                          setSelectedTemplate(item);
                        }
                      }}
                    >
                      <div className="px-16 py-12">
                        <Typography className="text-start font-bold text-18">
                          {item?.templateName}
                        </Typography>
                        <div className="flex items-center justify-between pt-12">
                          <Typography className="px-12 py-6 text-white bg-quick-hub rounded-full">
                            {capitalizeFirstLetter(item?.status)}
                          </Typography>
                          <Typography className="text-gray">{item?.categoryName}</Typography>
                        </div>
                      </div>
                      <hr />
                      <div
                        className="mx-16 px-16 my-12 py-12 h-384"
                        style={{
                          backgroundImage: `url('/assets/images/quick-chat/DefaultWallpaper.svg')`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                        }}
                      >
                        <div className="overflow-scroll h-320">
                          {item?.templateData?.components?.length > 0 && (
                            <div className="bg-white px-12 rounded-md pt-10 ">
                              {item?.templateData?.components?.map((templateValue, i) => (
                                <div key={i}>
                                  {templateValue?.type === 'HEADER' && (
                                    <>
                                      {templateValue?.format === 'TEXT' && (
                                        <Typography
                                          className="font-bold"
                                          dangerouslySetInnerHTML={{ __html: templateValue?.text }}
                                        />
                                      )}
                                    </>
                                  )}
                                  {templateValue?.format === 'IMAGE' && (
                                    <>
                                      {item?.dynamicData?.header?.map((dynamicValue) => (
                                        <>
                                          {dynamicValue?.type === 'image' && (
                                            <img
                                              src={dynamicValue?.value}
                                              className="mb-10 rounded-md w-full h-144 object-cover shadow-md"
                                              alt="..."
                                            />
                                          )}
                                        </>
                                      ))}
                                    </>
                                  )}
                                  {templateValue?.type === 'BODY' && (
                                    <Typography
                                      dangerouslySetInnerHTML={{
                                        __html: item?.updatedBodyText?.replace(/\n/g, '<br>'),
                                      }}
                                    />
                                  )}
                                  {templateValue?.type === 'FOOTER' && (
                                    <>
                                      <Typography
                                        dangerouslySetInnerHTML={{ __html: templateValue?.text }}
                                      />
                                    </>
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                          <div>
                            {item?.templateData?.components?.length > 0 &&
                              item?.templateData?.components?.map((templateValue, i) => (
                                <div key={i}>
                                  {templateValue?.type === 'BUTTONS' && (
                                    <>
                                      {templateValue?.buttons?.map((buttonValue, b) => (
                                        <div className="bg-white shadow p-4 rounded-md mt-4 h-32 text-center">
                                          <a
                                            className="font-semibold text-[#0096DE] text-16"
                                            href={`${
                                              buttonValue?.phone_number
                                                ? `tel:${buttonValue?.phone_number}`
                                                : buttonValue?.url
                                            }`}
                                            rel="noreferrer"
                                            target="_blank"
                                            role="button"
                                            key={b}
                                          >
                                            <img
                                              src={
                                                buttonValue?.phone_number
                                                  ? '/assets/images/business/telephone.png'
                                                  : '/assets/images/business/view.png'
                                              }
                                              className="w-14 h-14 align-middle mr-6 inline-block relative -top-1"
                                              alt="..."
                                            />
                                            {buttonValue?.text}
                                          </a>
                                        </div>
                                      ))}
                                    </>
                                  )}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="px-52 py-32 bottom-0 right-0 w-full flex justify-end">
                <Pagination
                  count={Math.ceil(totalCount / 12)}
                  page={page}
                  onChange={handlePageChange}
                  showFirstButton
                  showLastButton
                />
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
      <ViewTemplate
        viewDialogue={viewDialogue}
        setViewDialogue={setViewDialogue}
        selectedTemplate={selectedTemplate}
        setOpenSelectTemplateDialogue={setOpenSelectTemplateDialogue}
        handleTemplateSelect={handleTemplateSelect}
        selectedVariables={selectedVariables}
        setSelectedVariables={setSelectedVariables}
        setTemplate={setTemplate}
        setImageUrl={setImageUrl}
        setViewTemplate={setViewTemplate}
        type={type}
      />
    </div>
  );
};

export default SelectTemplate;
