import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Drawer,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import history from '@history';
import { t } from 'i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  DatePicker,
  LocalizationProvider,
  renderTimeViewClock,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PhoneInput from 'react-phone-input-2';
import userService from 'src/app/services';
import { convertTemplateToHtml } from 'src/app/main/common/common';
import MediaList from '../MediaList';

export const ViewTemplate = (props) => {
  const {
    viewDialogue,
    setViewDialogue,
    selectedTemplate,
    setOpenSelectTemplateDialogue,
    handleTemplateSelect,
    setTemplate,
    setImageUrl,
    setViewTemplate,
    selectedVariables,
    setSelectedVariables,
    type,
  } = props;
  const [templateDetailsDialog, setTemplateDetailDialog] = useState();
  return (
    <>
      <Dialog
        open={viewDialogue}
        classes={{
          paper: `m-24 business-type-dropdown bg-white text-center rounded-md`,
        }}
        PaperProps={{
          style: {
            maxWidth: '400px',
            minWidth: '400px',
            minHeight: '580px',
            maxHeight: '580px',
          },
        }}
      >
        <div className="flex items-center justify-end pl-16 pt-10">
          <Button
            className="hover:bg-transparent"
            onClick={() => {
              setViewDialogue(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
        <DialogContent className="px-16 py-0 text-start">
          <div className="bg-gray-A500 w-full px-16 py-16">
            <Typography className="font-semibold text-16">
              {selectedTemplate?.displayName}
            </Typography>
            <div className="h-400 mt-12 overflow-scroll">
              <div className="bg-white px-12 rounded-md pt-10">
                {selectedTemplate?.templateData?.components?.length > 0 &&
                  selectedTemplate?.templateData?.components?.map((templateValue, i) => (
                    <div key={i}>
                      {templateValue?.type === 'HEADER' && (
                        <>
                          {templateValue?.format === 'TEXT' && (
                            <Typography
                              className="font-bold"
                              dangerouslySetInnerHTML={{ __html: templateValue?.text }}
                            />
                          )}
                        </>
                      )}
                      {templateValue?.format === 'IMAGE' && (
                        <>
                          {selectedTemplate?.dynamicData?.header?.map((dynamicValue) => (
                            <>
                              {dynamicValue?.type === 'image' && (
                                <img
                                  src={dynamicValue?.value}
                                  className="mb-10 rounded-md"
                                  alt="..."
                                />
                              )}
                            </>
                          ))}
                        </>
                      )}
                      {templateValue?.type === 'BODY' && (
                        <Typography
                          dangerouslySetInnerHTML={{
                            __html: convertTemplateToHtml(
                              selectedTemplate?.updatedBodyText?.replace(/\n/g, '<br>')
                            ),
                          }}
                        />
                      )}
                      {templateValue?.type === 'FOOTER' && (
                        <Typography dangerouslySetInnerHTML={{ __html: templateValue?.text }} />
                      )}
                    </div>
                  ))}
              </div>
              <div>
                {selectedTemplate?.templateData?.components?.length > 0 &&
                  selectedTemplate?.templateData?.components?.map((templateValue, i) => (
                    <div key={i}>
                      {templateValue?.type === 'BUTTONS' && (
                        <>
                          {templateValue?.buttons?.map((buttonValue, b) => (
                            <div className="bg-white shadow p-4 rounded-md mt-4 h-32 text-center">
                              <a
                                className="font-semibold text-[#0096DE] text-16"
                                href={`${
                                  buttonValue?.phone_number
                                    ? `tel:${buttonValue?.phone_number}`
                                    : buttonValue?.url
                                }`}
                                rel="noreferrer"
                                target="_blank"
                                role="button"
                                key={b}
                              >
                                <img
                                  src={
                                    buttonValue?.phone_number
                                      ? '/assets/images/business/telephone.png'
                                      : '/assets/images/business/view.png'
                                  }
                                  className="w-14 h-14 align-middle mr-6 inline-block relative -top-1"
                                  alt="..."
                                />
                                {buttonValue?.text}
                              </a>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <Button
              className="w-full rounded-md"
              variant="contained"
              color="quickChat"
              onClick={() => {
                setViewDialogue(false);
                if (type === 'broadcast') {
                  setTemplate(selectedTemplate);
                  setOpenSelectTemplateDialogue(false);
                } else if (type === 'waBaChat') {
                  setOpenSelectTemplateDialogue(false);
                  setTemplateDetailDialog(true);
                } else if (type === 'postOrSchedule') {
                  setTemplateDetailDialog(true);
                  setOpenSelectTemplateDialogue(false);
                } else {
                  history.push('quick-chat/createBroadcast', { template: selectedTemplate });
                }
              }}
            >
              {t('common.next')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      {templateDetailsDialog && (
        <TemplateDetailsDialog
          templateDetailsDialog={templateDetailsDialog}
          setTemplateDetailDialog={setTemplateDetailDialog}
          handleTemplateSelect={handleTemplateSelect}
          template={selectedTemplate}
          selectedVariables={selectedVariables}
          setSelectedVariables={setSelectedVariables}
          setViewTemplate={setViewTemplate}
          setTemplate={setTemplate}
          setImageUrl={setImageUrl}
          type={type}
        />
      )}
    </>
  );
};

export const CancelBroadCast = (props) => {
  const { openCancelBroadcastDialogue, setCancelBroadcastDialogue } = props;
  return (
    <Dialog
      open={openCancelBroadcastDialogue}
      aria-labelledby="delete-confirmation-dialog"
      classes={{
        paper: `m-24 business-type-dropdown bg-white text-center rounded-md`,
      }}
      PaperProps={{
        style: {
          maxWidth: '470px',
          overflowY: 'auto',
          minWidth: '470px',
        },
      }}
    >
      <div className="flex items-center justify-end pl-16 pt-10">
        <Button
          className="hover:bg-transparent"
          onClick={() => {
            setCancelBroadcastDialogue(false);
          }}
        >
          <CloseIcon />
        </Button>
      </div>
      <DialogContent className="p-16 text-start">
        <div className="text-center w-full px-16 pb-20">
          <img className="m-auto" src="assets/images/quick-chat/delete.png" alt="" />
          <Typography className="font-semibold text-24 py-12">
            {t('waba.broadcast.myBroadcast.cancelBroadcast')}
          </Typography>
          <Typography className="font-medium text-16">
            {t('waba.broadcast.myBroadcast.cancelBroadcastMessage')}
          </Typography>
          <div className="flex gap-12 justify-center mt-20">
            <Button
              className="rounded-md w-112"
              variant="outlined"
              color="quickChat"
              onClick={() => {
                setCancelBroadcastDialogue(false);
              }}
            >
              {t('common.cancel')}
            </Button>
            <Button
              className="rounded-md w-112"
              variant="contained"
              color="quickChat"
              onClick={() => {
                history.push('quick-chat/waba-broadcast');
                setCancelBroadcastDialogue(false);
              }}
            >
              {t('common.confirm')}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const DuplicateTemplate = (props) => {
  const { openDuplicateDialogue, setDuplicateDialogue, selectedTemplate, activeTab } = props;
  return (
    <Dialog
      open={openDuplicateDialogue}
      aria-labelledby="delete-confirmation-dialog"
      classes={{
        paper: `m-24 business-type-dropdown bg-white text-center rounded-md`,
      }}
      PaperProps={{
        style: {
          maxWidth: '470px',
          overflowY: 'auto',
          minWidth: '470px',
        },
      }}
    >
      <div className="flex items-center justify-end pl-16 pt-10">
        <Button
          className="hover:bg-transparent"
          onClick={() => {
            setDuplicateDialogue(false);
          }}
        >
          <CloseIcon />
        </Button>
      </div>
      <DialogContent className="p-16 text-start">
        <div className="text-center w-full px-16 pb-20">
          <img className="m-auto" src="assets/images/quick-chat/delete.png" alt="" />
          <Typography className="font-semibold text-24 py-12">
            {t('waba.broadcast.myBroadcast.duplicateTemplate')}
          </Typography>
          <Typography className="font-medium text-16">
            {t('waba.broadcast.myBroadcast.duplicateTemplateMessage')}
          </Typography>
          <div className="flex gap-12 justify-center mt-20">
            <Button
              className="rounded-md w-112"
              variant="outlined"
              color="quickChat"
              onClick={() => {
                setDuplicateDialogue(false);
              }}
            >
              {t('common.cancel')}
            </Button>
            <Button
              className="rounded-md w-112"
              variant="contained"
              color="quickChat"
              onClick={() => {
                setDuplicateDialogue(false);
                history.push('quick-chat/createOrEditTemplate', {
                  template: selectedTemplate,
                  status: activeTab,
                });
              }}
            >
              {t('common.confirm')}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const DeleteTemplate = (props) => {
  const {
    openDeleteTemplateDialogue,
    setDeleteTemplateDialogue,
    handleDelete,
    refreshIcon,
    deleteMessage,
  } = props;
  return (
    <Dialog
      open={openDeleteTemplateDialogue}
      aria-labelledby="delete-confirmation-dialog"
      classes={{
        paper: `m-24 business-type-dropdown bg-white text-center rounded-md`,
      }}
      PaperProps={{
        style: {
          maxWidth: '470px',
          overflowY: 'auto',
          minWidth: '470px',
        },
      }}
    >
      <div className="flex items-center justify-end pl-16 pt-10">
        <Button
          className="hover:bg-transparent"
          onClick={() => {
            setDeleteTemplateDialogue(false);
          }}
        >
          <CloseIcon />
        </Button>
      </div>
      <DialogContent className="p-16 text-start">
        <div className="text-center w-full px-16 pb-20">
          <img className="m-auto" src="assets/images/quick-chat/delete.png" alt="" />
          <Typography className="font-semibold text-24 py-12">
            {t('waba.broadcast.myBroadcast.areYouSure')}
          </Typography>
          <Typography className="font-medium text-16">{deleteMessage}</Typography>
          <div className="flex gap-12 justify-center mt-20">
            <Button
              className="rounded-md w-112"
              variant="outlined"
              color="quickChat"
              onClick={() => {
                setDeleteTemplateDialogue(false);
              }}
            >
              {t('common.cancel')}
            </Button>
            <Button
              className="rounded-md w-112"
              variant="contained"
              color="quickChat"
              disabled={refreshIcon}
              onClick={() => handleDelete()}
            >
              {t('common.confirm')}
              {refreshIcon && (
                <CircularProgress size={24} className="absolute text-quick-chat mx-auto" />
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const DiscardTemplate = (props) => {
  const {
    openDiscardTemplateDialogue,
    setDiscardTemplateDialogue,
    saveAsDraft,
    refreshIcon,
    handleDiscardTemplate,
  } = props;
  return (
    <Dialog
      open={openDiscardTemplateDialogue}
      aria-labelledby="delete-confirmation-dialog"
      classes={{
        paper: `m-24 business-type-dropdown bg-white text-center rounded-md`,
      }}
      PaperProps={{
        style: {
          maxWidth: '470px',
          overflowY: 'auto',
          minWidth: '470px',
        },
      }}
    >
      <div className="flex items-center justify-end pl-16 pt-10">
        <Button
          className="hover:bg-transparent"
          onClick={() => {
            setDiscardTemplateDialogue(false);
          }}
        >
          <CloseIcon />
        </Button>
      </div>
      <DialogContent className="p-16 text-start">
        <div className="text-center w-full px-16 pb-20">
          <img className="m-auto" src="assets/images/quick-chat/discard.svg" alt="" />
          <Typography className="font-semibold text-24 py-12">
            {t('waba.broadcast.myBroadcast.discardTemplate')}
          </Typography>
          <Typography className="font-medium text-16">
            {t('waba.broadcast.myBroadcast.discardMessage')}
          </Typography>
          <div className="flex gap-12 justify-center mt-20">
            <Button
              className="rounded-md w-160"
              variant="outlined"
              color="quickChat"
              onClick={() => handleDiscardTemplate()}
            >
              {t('waba.broadcast.myBroadcast.discardTemplate')}
            </Button>
            <Button
              className="rounded-md w-160"
              variant="contained"
              color="quickChat"
              disabled={refreshIcon}
              onClick={() => saveAsDraft()}
            >
              {t('waba.broadcast.myBroadcast.saveAsDraft')}
              {refreshIcon && (
                <CircularProgress size={24} className="absolute text-quick-chat mx-auto" />
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const ConfirmTemplateSubmission = (props) => {
  const {
    templateSubmissionDialogue,
    setTemplateSubmissionDialogue,
    handleCreateTemplate,
    refreshIcon,
  } = props;
  return (
    <Dialog
      open={templateSubmissionDialogue}
      aria-labelledby="delete-confirmation-dialog"
      classes={{
        paper: `m-24 business-type-dropdown bg-white text-center rounded-md`,
      }}
      PaperProps={{
        style: {
          maxWidth: '520px',
          overflowY: 'auto',
          minWidth: '520px',
        },
      }}
    >
      <div className="flex items-center justify-end pl-16 pt-10">
        <Button
          className="hover:bg-transparent"
          onClick={() => {
            setTemplateSubmissionDialogue(false);
          }}
        >
          <CloseIcon />
        </Button>
      </div>
      <DialogContent className="p-16 text-start">
        <div className="text-center w-full px-16 pb-20">
          <img className="m-auto" src="assets/images/quick-chat/template.svg" alt="" />
          <Typography className="font-semibold text-24 py-12">
            {t('waba.broadcast.myBroadcast.confirmTemplateSubmission')}
          </Typography>
          <Typography className="font-medium text-16">
            {t('waba.broadcast.myBroadcast.confirmTemplateSubmissionMessage')}
          </Typography>
          <div className="flex gap-12 justify-center mt-20">
            <Button
              className="rounded-md w-160"
              variant="outlined"
              color="quickChat"
              onClick={() => setTemplateSubmissionDialogue(false)}
            >
              {t('common.cancel')}
            </Button>
            <Button
              className="rounded-md w-160"
              variant="contained"
              color="quickChat"
              disabled={refreshIcon}
              onClick={() => {
                handleCreateTemplate();
              }}
            >
              {t('common.confirm')}
              {refreshIcon && (
                <CircularProgress size={24} className="absolute text-quick-chat mx-auto" />
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const TemplateSubmitted = (props) => {
  const { templateSubmittedDialogue, setTemplateSubmittedDialogue, refreshIcon } = props;
  return (
    <Dialog
      open={templateSubmittedDialogue}
      aria-labelledby="delete-confirmation-dialog"
      classes={{
        paper: `m-24 business-type-dropdown bg-white text-center rounded-md`,
      }}
      PaperProps={{
        style: {
          maxWidth: '470px',
          overflowY: 'auto',
          minWidth: '470px',
        },
      }}
    >
      <DialogContent className="p-16 text-start">
        <div className="text-center w-full px-16 pb-20">
          <img className="m-auto w-128" src="assets/images/quick-chat/success.png" alt="" />
          <Typography className="font-semibold text-24 py-12">
            {t('waba.broadcast.myBroadcast.templateSubmitted')}
          </Typography>
          <Typography className="font-medium text-16">
            {t('waba.broadcast.myBroadcast.templateSubmittedMessage')}
          </Typography>
          <div className="flex gap-12 justify-center mt-20">
            <Button
              className="rounded-md w-160"
              variant="contained"
              color="quickChat"
              disabled={refreshIcon}
              onClick={() => {
                setTemplateSubmittedDialogue(false);
                history.push('quick-chat/template', { activeTab: 4 });
              }}
            >
              {t('waba.broadcast.myBroadcast.backToHome')}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const SuccessDialogue = (props) => {
  const { successDialogue, setSuccessDialogue, saveAsDraft, isSchedule } = props;
  return (
    <Dialog
      open={successDialogue}
      aria-labelledby="delete-confirmation-dialog"
      classes={{
        paper: `m-24 business-type-dropdown bg-white text-center rounded-md`,
      }}
      PaperProps={{
        style: {
          maxWidth: '500px',
          overflowY: 'auto',
          minWidth: '500px',
        },
      }}
    >
      <DialogContent className="p-16 text-start">
        <div className="text-center w-full px-16 pb-20">
          <img className="m-auto w-160" src="assets/images/quick-chat/success.png" alt="" />
          <Typography className="font-semibold text-24 py-12">
            {isSchedule
              ? t('waba.broadcast.myBroadcast.campaignScheduledSuccessFully')
              : t('waba.broadcast.myBroadcast.campaignSuccessFully')}
          </Typography>
          <Typography className="font-medium text-16">
            {isSchedule
              ? t('waba.broadcast.myBroadcast.campaignScheduledSuccessFullyMessage')
              : t('waba.broadcast.myBroadcast.campaignSuccessFullyMessage')}
          </Typography>
          <div className="flex gap-12 justify-center mt-20">
            <Button
              className="rounded-md w-200"
              variant="contained"
              color="quickChat"
              onClick={() =>
                history.push('quick-chat/waba-broadcast', { tabValue: isSchedule ? 2 : 1 })
              }
            >
              {t('waba.broadcast.myBroadcast.backToHome')}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const ScheduleDialog = (props) => {
  const {
    setIsSchedule,
    isSchedule,
    scheduledDate,
    scheduledTime,
    setScheduledTime,
    setScheduledDate,
    handleCreateBroadCast,
    refreshIcon,
  } = props;

  const handleDateChange = (data) => {
    setScheduledDate(data);
  };

  const handleTimeChange = (event) => {
    setScheduledTime(event);
  };

  return (
    <Dialog
      open={isSchedule}
      classes={{
        paper: `m-24 px-24 py-16 rounded-md min-w-400`,
      }}
    >
      <div className="flex items-center justify-between mb-24">
        <h3 className="text-24 font-bold">{t('quickCampaign.campaign.schedulePost')}</h3>
        <div>
          <IconButton
            className=""
            onClick={() => {
              setIsSchedule(false);
              setScheduledDate(null);
              setScheduledTime(null);
            }}
            color="inherit"
          >
            <CloseIcon className="text-24 text-grey-600" />
          </IconButton>
        </div>
      </div>
      <DialogContent className="p-0 mb-24">
        <div className=" items-center justify-between gap-16">
          <div className="mb-20">
            <Typography className="text-18 text-[#444444] mt-4 font-semibold text-left">
              {t('sendMessage.selectDate')}
            </Typography>
            <div className="mt-4 quick-chat">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  className="w-full select-date"
                  id="note"
                  variant="outlined"
                  fullWidth
                  disablePast
                  value={scheduledDate}
                  format="MM dd, yyyy"
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select a date"
                      sx={{
                        '& .MuiInputAdornment-root svg': {
                          color: 'red',
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="">
            <Typography className="text-18 text-[#444444] mt-4 font-semibold text-left">
              {t('sendMessage.selectTime')}
            </Typography>
            <div className="mt-4 quick-chat">
              <TimePicker
                className="w-full"
                id="time"
                onChange={handleTimeChange}
                value={scheduledTime}
                size="small"
                required
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="p-0 flex justify-end gap-14">
        <Button
          className="md:text-16 font-medium rounded-md bg-quick-chat px-60 disabled:opacity-40 disabled:bg-quick-chat disabled:text-white"
          variant="contained"
          color="secondary"
          onClick={() => handleCreateBroadCast()}
          disabled={!scheduledDate || !scheduledTime}
        >
          {t('quickCampaign.campaign.schedule')}
          {refreshIcon && (
            <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const TemplateDetailsDialog = (props) => {
  const {
    setSelectedVariables,
    templateDetailsDialog,
    setTemplateDetailDialog,
    template,
    setTemplate,
    setImageUrl,
    handleTemplateSelect,
    setViewTemplate,
    type,
  } = props;
  const loginUserData = userService.getUserData();
  const [displayTemplate, setDisplayTemplate] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [openMediaDialog, setOpenMediaDialog] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [dynamicVariables, setDynamicVariables] = useState([]);

  useEffect(() => {
    setDisplayTemplate(template?.updatedBodyText);
    const variables = template?.dynamicData?.body?.map((item) => ({
      ...item,
      description: item.description.replace(/\[|\]/g, ''),
      value: item?.value || '',
    }));
    const header = template?.dynamicData?.header?.find((item) => item?.type === 'image');
    setImgUrl(header?.value);
    setDynamicVariables(variables);
  }, [template]);

  useEffect(() => {
    const replaceTemplateVariables = () => {
      return template?.updatedBodyText?.replace(/\[\s*([^\]]+)\s*\]/g, (match, p1) => {
        const variable = dynamicVariables?.find((v) => v.description === p1);
        if (!variable || !variable.value) return match;
        let formattedValue = variable.value;
        if (variable.type === 'date') {
          formattedValue = new Date(variable.value).toLocaleDateString('en-GB');
        } else if (variable.type === 'time') {
          formattedValue = new Date(variable.value).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
          });
        } else if (variable.type === 'phoneNumber') {
          formattedValue = `+${variable.value.slice(0, 2)} ${variable.value.slice(2)}`;
        }
        return formattedValue;
      });
    };
    const updatedTemplate = replaceTemplateVariables();
    setDisplayTemplate(updatedTemplate);
  }, [dynamicVariables, template?.updatedBodyText]);

  const handleInputChange = (index, value) => {
    setDynamicVariables((prev) =>
      prev?.map((item, i) => (i === index ? { ...item, value } : item))
    );
  };

  const handleRemoveImage = () => {
    setImgUrl();
  };

  const handleMediaSelected = async (media) => {
    if (media) {
      setImgUrl(media?.awsUrl);
      setSelectedMedia(media);
      setOpenMediaDialog(false);
    }
  };

  const handleSave = () => {
    const updateTemplateData = {
      ...template,
      dynamicData: {
        ...template.dynamicData,
        ...(template.dynamicData.body && { body: dynamicVariables }),
        ...(template.dynamicData.header && {
          header: template.dynamicData.header.map((item) =>
            item.type === 'image' ? { ...item, value: imgUrl } : item
          ),
        }),
      },
    };
    setSelectedVariables(dynamicVariables);
    if (type === 'waBaChat') {
      handleTemplateSelect(updateTemplateData);
    } else if (type === 'postOrSchedule') {
      setViewTemplate(displayTemplate);
      setTemplate((prevTemplate) => ({
        ...prevTemplate,
        dynamicData: {
          ...prevTemplate.dynamicData,
          ...(prevTemplate.dynamicData.body && { body: dynamicVariables }),
          ...(prevTemplate.dynamicData.header && {
            header: prevTemplate.dynamicData.header.map((item) =>
              item.type === 'image' ? { ...item, value: imgUrl } : item
            ),
          }),
        },
      }));
      setTemplate((prevTemplate) => ({
        ...prevTemplate,
        dynamicData: {
          ...prevTemplate.dynamicData,
          body: dynamicVariables,
        },
      }));
      setImageUrl(imgUrl || '');
    }
    setTemplateDetailDialog(false);
  };

  return (
    <>
      <Dialog
        open={templateDetailsDialog}
        classes={{
          paper: `m-24 px-16 py-16 rounded-md`,
        }}
        PaperProps={{
          style: {
            width: '100%',
            overflowY: 'auto',
            maxWidth: '1260px',
            height: '100%',
            maxHeight: '880px',
          },
        }}
      >
        <DialogContent className="p-0 mb-24">
          <div className="flex justify-between w-full">
            {dynamicVariables?.length > 0 && (
              <Typography className="font-semibold text-24 pb-16 w-full">
                {t('waba.broadcast.myBroadcast.editTemplateVariables')}
              </Typography>
            )}
            <div className="w-full text-right">
              <IconButton
                className="hover:bg-white"
                onClick={() => setTemplateDetailDialog(false)}
                color="inherit"
              >
                <CloseIcon className="text-24 text-grey-600" />
              </IconButton>
            </div>
          </div>
          <div className="sm:flex justify-between gap-8">
            <div className="w-full px-12">
              {dynamicVariables?.length > 0 &&
                dynamicVariables?.map((item, index) => (
                  <div key={index} className="mt-12 quick-chat">
                    <Typography className="text-[#686868] text-16 font-medium mb-12">
                      {item.description}
                    </Typography>
                    {item.type === 'text' || item.type === 'int' ? (
                      <TextField
                        className="w-full"
                        value={item.value || ''}
                        onChange={(e) => handleInputChange(index, e.target.value)}
                        placeholder={`Enter ${item.description}`}
                        size="medium"
                        InputProps={{
                          style: { fontSize: '16px', fontWeight: 500 },
                        }}
                      />
                    ) : item.type === 'date' ? (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          className="w-full select-date mt-6"
                          value={item?.value ? new Date(item.value) : null}
                          onChange={(newDate) => handleInputChange(index, newDate)}
                          renderInput={(params) => <TextField {...params} />}
                          disablePast
                        />
                      </LocalizationProvider>
                    ) : item.type === 'time' ? (
                      <TimePicker
                        className="w-full mt-6"
                        id="time"
                        onChange={(newTime) => handleInputChange(index, newTime)}
                        value={item?.value ? new Date(item.value) : null}
                        size="medium"
                        required
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                      />
                    ) : (
                      item?.type === 'phoneNumber' && (
                        <PhoneInput
                          placeholder={t(
                            'manageBusiness.addBusinessSteps.addBusiness.form.phone.placeHolder'
                          )}
                          inputStyle={{
                            width: '100%',
                            height: '52px',
                            color: 'black',
                            fontWeight: '400',
                          }}
                          className="w-full "
                          name="phone_number"
                          required
                          size="small"
                          value={item.value?.phoneNumber?.toString()}
                          country={loginUserData?.isoCode.toLowerCase()}
                          enableSearch="true"
                          onChange={(phone, countryData) => {
                            handleInputChange(index, phone);
                          }}
                        />
                      )
                    )}
                  </div>
                ))}
              {template?.templateData?.components?.find(
                (item) => item?.type === 'HEADER' && item?.format === 'IMAGE'
              ) && (
                <div className="bg-white mt-16">
                  <Typography className="font-semibold text-16">
                    {t('waba.broadcast.myBroadcast.media')}
                  </Typography>
                  {imgUrl ? (
                    <div className="my-12 relative">
                      <img className="rounded-md w-256  h-160" src={imgUrl} alt="" />
                      <CloseRoundedIcon
                        onClick={() => handleRemoveImage()}
                        className="text-18 absolute -top-8 left-[245px] bg-white rounded-full text-black font-normal cursor-pointer hover:text-gray-700 focus:outline-none"
                      />
                    </div>
                  ) : (
                    <Paper
                      className="relative h-full rounded-8 shadow-none border-1 border-solid bg-gray-A500 py-20 mt-12 text-center"
                      role="button"
                      onDragOver={(e) => e.preventDefault()}
                    >
                      <span className="block text-center">
                        <img
                          src="/assets/images/business/uploadImage.png"
                          className="mx-auto mb-12 w-28"
                          alt="..."
                        />
                      </span>
                      <Button
                        className="inline-block hover:bg-transparent"
                        onClick={() => setOpenMediaDialog(true)}
                      >
                        <span className="cursor-pointer text-quick-chat font-bold text-14">
                          {t('waba.broadcast.myBroadcast.clickToUpload')}
                        </span>{' '}
                        <span className="text-14 font-semibold text-grey-A300">
                          {t('waba.broadcast.myBroadcast.dragOrDrop')}
                        </span>
                        <Typography className="text-14 font-semibold text-grey-A300">
                          {t('waba.broadcast.myBroadcast.imageType')}
                        </Typography>
                      </Button>
                    </Paper>
                  )}
                </div>
              )}
              <div className="flex justify-end gap-16 mt-12">
                <Button
                  className="rounded-md w-160 bg-white hover:bg-white font-semibold text-16"
                  variant="outlined"
                  color="quickChat"
                  onClick={() => setTemplateDetailDialog(false)}
                >
                  {t('common.cancel')}
                </Button>
                <Button
                  className="md:text-16 font-medium rounded-md bg-quick-chat px-60"
                  variant="contained"
                  color="secondary"
                  onClick={() => handleSave()}
                  disabled={dynamicVariables?.some(
                    (item) =>
                      item.value === null ||
                      item.value === undefined ||
                      (typeof item.value === 'string' && item.value.trim() === '') ||
                      (Array.isArray(item.value) && item.value.length === 0)
                  )}
                >
                  {t('common.save')}
                </Button>
              </div>
            </div>
            <Divider className="h-content bg-gray w-4" />
            <div className="w-full pt-16 flex justify-center items-center">
              <div className="h-640 w-480 p-16 bg-gray-A500">
                <Typography className="font-bold text-16">
                  {t('waba.broadcast.myBroadcast.preview')}
                </Typography>
                <div className="relative mb-16 w-full">
                  <img
                    className="w-288 h-586 m-auto"
                    src="assets/images/quick-chat/phone.png"
                    alt=""
                  />
                  <div className="absolute top-80 left-0 right-0 rounded-md max-w-224 xs:max-w-288 mx-auto h-480 overflow-scroll">
                    <div className="bg-white px-12 mx-28 rounded-md pt-10">
                      {template?.templateData?.components?.length > 0 &&
                        template?.templateData?.components?.map((templateValue, i) => (
                          <div key={i}>
                            {templateValue?.type === 'HEADER' && (
                              <>
                                {templateValue?.format === 'TEXT' && (
                                  <Typography
                                    className="font-bold"
                                    dangerouslySetInnerHTML={{ __html: templateValue?.text }}
                                  />
                                )}
                              </>
                            )}
                            {templateValue?.format === 'IMAGE' && (
                              <>
                                {template?.dynamicData?.header?.map((dynamicValue) => (
                                  <>
                                    {dynamicValue?.type === 'image' && (
                                      <img
                                        src={imgUrl || dynamicValue?.value}
                                        className="mb-10 rounded-md w-full h-144 object-cover shadow-md"
                                        alt="..."
                                      />
                                    )}
                                  </>
                                ))}
                              </>
                            )}
                            {templateValue?.type === 'BODY' && (
                              <Typography
                                dangerouslySetInnerHTML={{
                                  __html: convertTemplateToHtml(
                                    displayTemplate?.replace(/\n/g, '<br>')
                                  ),
                                }}
                              />
                            )}
                            {templateValue?.type === 'FOOTER' && (
                              <Typography
                                dangerouslySetInnerHTML={{ __html: templateValue?.text }}
                              />
                            )}
                          </div>
                        ))}
                    </div>
                    {template?.templateData?.components?.length > 0 &&
                      template?.templateData?.components?.map((templateValue, i) => (
                        <div key={i} className="mx-28">
                          {templateValue?.type === 'BUTTONS' && (
                            <>
                              {templateValue?.buttons?.map((buttonValue, b) => (
                                <div className="bg-white shadow p-4 rounded-md mt-4 h-32 text-center">
                                  <a
                                    className="font-semibold text-[#0096DE] text-16"
                                    href={`${
                                      buttonValue?.phone_number
                                        ? `tel:${buttonValue?.phone_number}`
                                        : buttonValue?.url
                                    }`}
                                    rel="noreferrer"
                                    target="_blank"
                                    role="button"
                                    key={b}
                                  >
                                    <img
                                      src={
                                        buttonValue?.phone_number
                                          ? '/assets/images/business/telephone.png'
                                          : '/assets/images/business/view.png'
                                      }
                                      className="w-14 h-14 align-middle mr-6 inline-block relative -top-1"
                                      alt="..."
                                    />
                                    {buttonValue?.text}
                                  </a>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <MediaList
        openValue={openMediaDialog}
        cancelCall={() => setOpenMediaDialog(false)}
        popUpMessage={t('waba.inbox.mediaLibrary')}
        onMediaSelected={handleMediaSelected}
        selectedMedia={selectedMedia}
        broadcast
      />
    </>
  );
};

export const ViewTemplateInDrawer = (props) => {
  const { viewTemplateInDrawer, setViewTemplateInDrawer, selectedTemplate } = props;

  return (
    <Drawer open={viewTemplateInDrawer} anchor="right" className="">
      <div className="sm:min-w-480 sm:max-w-480">
        <div className="flex items-center justify-between pl-16 py-10">
          <Typography className="font-semibold text-24">{selectedTemplate?.displayName}</Typography>
          <Button
            className="hover:bg-transparent"
            onClick={() => {
              setViewTemplateInDrawer(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
        <div className="px-12 xs:px-24 bg-gray-A500 h-screen text-start">
          <div className="w-full px-16 py-16">
            <div className="mt-12">
              <div className="bg-white px-12 rounded-md py-10">
                {selectedTemplate?.templateData?.components?.length > 0 &&
                  selectedTemplate?.templateData?.components?.map((templateValue, i) => {
                    return (
                      <div key={i}>
                        {templateValue?.type === 'HEADER' && (
                          <>
                            {templateValue?.format === 'TEXT' && (
                              <Typography
                                className="font-bold"
                                dangerouslySetInnerHTML={{ __html: templateValue?.text }}
                              />
                            )}
                          </>
                        )}
                        {templateValue?.format === 'IMAGE' && (
                          <>
                            {selectedTemplate?.dynamicData?.header?.map((dynamicValue) => (
                              <>
                                {dynamicValue?.type === 'image' && (
                                  <img
                                    src={dynamicValue?.value}
                                    className="mb-10 rounded-md object-cover w-[384px] h-[200px] shadow-md"
                                    alt="..."
                                  />
                                )}
                              </>
                            ))}
                          </>
                        )}
                        {templateValue?.type === 'BODY' && (
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: convertTemplateToHtml(
                                selectedTemplate?.updatedBodyText?.replace(/\n/g, '<br>')
                              ),
                            }}
                          />
                        )}
                        {templateValue?.type === 'FOOTER' && (
                          <>
                            <Typography dangerouslySetInnerHTML={{ __html: templateValue?.text }} />
                          </>
                        )}
                      </div>
                    );
                  })}
              </div>
              <div>
                {selectedTemplate?.templateData?.components?.length > 0 &&
                  selectedTemplate?.templateData?.components?.map((templateValue, i) => (
                    <div key={i}>
                      {templateValue?.type === 'BUTTONS' && (
                        <>
                          {templateValue?.buttons?.map((buttonValue, b) => (
                            <div className="bg-white shadow p-4 rounded-md mt-4 h-32 text-center">
                              <a
                                className="font-semibold text-[#0096DE] text-16"
                                href={`${
                                  buttonValue?.phone_number
                                    ? `tel:${buttonValue?.phone_number}`
                                    : buttonValue?.url
                                }`}
                                rel="noreferrer"
                                target="_blank"
                                role="button"
                                key={b}
                              >
                                <img
                                  src={
                                    buttonValue?.phone_number
                                      ? '/assets/images/business/telephone.png'
                                      : '/assets/images/business/view.png'
                                  }
                                  className="w-14 h-14 align-middle mr-6 inline-block relative -top-1"
                                  alt="..."
                                />
                                {buttonValue?.text}
                              </a>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <Button
              className="w-full rounded-md mt-16"
              variant="contained"
              color="quickChat"
              onClick={() =>
                history.push('quick-chat/createBroadcast', { template: selectedTemplate })
              }
            >
              {t('waba.broadcast.myBroadcast.startBroadCastWithTemplate')}
            </Button>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
