import { Button, MenuItem, Select, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import userService from 'src/app/services';
import { t } from 'i18next';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import history from '@history';
import { getAllUserBusinessSubscription, selectDashboardData } from 'app/store/userSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import reviewPlatformData from '../../query/reviewPlatform';
import { handleApiRequest } from '../../common/common';
import description from './description.css';
import quickHub from '../../query/quickHub';

const Description = () => {
  const options = [
    {
      id: 7,
      name: 'Quick Reviews',
      url: 'quick-reviews',
      title: 'WELCOME TO QUICK REVIEWS!',
      heading: `<h1>Your One-Stop Solution For <br />
              <span style="color:#0BA861">Customer Reviews.</span></h1>`,
      description:
        'Collect valuable feedback, gain insights, and enhance your business with quick reviews, your customer feedback solution.',
      image: '/assets/images/business/description.png',
      pricingImage: '/assets/images/business/quick-reviews.svg',
      backgroundImage: '/assets/images/business/review_description.png',
      benefit: `<h1><span style="color:#0BA861">Build</span>
                  A Community</h1>`,
      message:
        'Quick Reviews offers multiple tools to help you stay connected with your customers. Gain insights into what they value most about your business and identify opportunities for further improvement to enhance their experience and drive your growth.   ',
    },
    {
      id: 8,
      name: 'Quick Social',
      url: 'quick-social',
      title: 'WELCOME TO QUICK SOCIAL!',
      heading: `<h1><span style="color:#0BA861">Effortless Multi-Platform</span>
              Social Posting</h1>`,
      description:
        'Easily create and share customer reviews. Engage with your audience and enhance your business reputation.',
      image: '/assets/images/business/quick-social.png',
      pricingImage: '/assets/images/business/quick-social-descritpion.svg',
      backgroundImage: '/assets/images/business/social_description.png',
      benefit: `<h1>Grow Your<span style="color:#0BA861"> Presence</span></h1>`,
      message:
        'Quick Social helps you effortlessly manage and share content across multiple platforms. Engage your audience with consistent posts, track interactions, and build a strong online presence to drive brand awareness and customer loyalty.',
    },
    {
      id: 9,
      name: 'Quick Chat',
      url: 'quick-chat',
      title: 'WELCOME TO QUICK CHAT!',
      heading: `<h1>Experience Seamless <br />
              <span style="color:#0BA861">Instant Bot Conversations.</span></h1>`,
      description:
        'Welcome to Quick Chat, Where instant bot conversations streamline your experience. Get swift, automated assistance anytime, anywhere!',
      image: '/assets/images/business/quickchat.png',
      pricingImage: '/assets/images/business/quick-chat.svg',
      backgroundImage: '/assets/images/business/quick-chat-description.png',
      benefit: `<h1>Engage<span style="color:#0BA861">
                 Effortlessly</span></h1>`,
      message:
        'Quick Chat enables seamless communication with your customers, helping you respond to inquiries, manage leads, and provide real-time support. Build lasting relationships, improve customer satisfaction, and drive business growth with instant, meaningful interactions.',
    },
    {
      id: 10,
      name: 'Quick Campaign',
      url: 'quick-campaign',
      title: 'WELCOME TO QUICK CAMPAIGNS',
      heading: `<h1><span style="color:#0BA861">Connect</span>
               With Your<br /> Customers Seamlessly</h1>`,
      description:
        'Welcome to Quick Campaigns! Effortlessly send promotions, festival wishes, offers, and discounts with just one click. Enhance customer engagement and boost your business.',
      image: '/assets/images/business/QUICKCAMPAIGNS.png',
      pricingImage: '/assets/images/business/quick-campaign.svg',
      backgroundImage: '/assets/images/business/campaign_description.png',
      benefit: `<h1><span style="color:#0BA861">Effortless Marketing <br /> </span>
                 with One Click</h1>`,
      message:
        'Discover Quick Campaign benefits: create and send marketing campaigns effortlessly via WhatsApp, SMS, and email, target the right audience, schedule messages, and track engagement—all in one place.',
    },
    {
      id: 11,
      url: 'quick-ads',
      name: 'Quick ads',
      title: 'WELCOME TO QUICK ADS!',
      heading: `<h1><span style="color:#0BA861">Seamlessly</span>
                connect with your customers.</h1>`,
      description:
        'Launch promotions, showcase offers, and drive engagement with impactful ads. Boost your business with just a few clicks!',
      image: '/assets/images/business/quick-ads.png',
      pricingImage: '/assets/images/business/quick-ads.svg',
      backgroundImage: '/assets/images/business/ads_description.png',
      benefit: `<h1><span style="color:#0BA861">Effortless Advertising </span> <br />
                 in a Few Clicks.</h1>`,
      message:
        'Discover Quick Ads benefits: launch targeted ad campaigns seamlessly, set your budget, choose your audience, and track performance—all in one place.',
    },
    {
      id: 12,
      url: 'quick-bundle',
      name: 'Quick bundle',
      title: 'WELCOME TO QUICK ADS!',
      heading: `<h1>Unlock the Power of <br /> <span style="color:#0BA861">All-in-One Marketing & Customer Engagement</span></h1>`,
      description:
        'Why juggle multiple tools? Quick Hub bundles Quick Reviews, Chat, Campaign, Social, and Ads at a discounted price to help you scale effortlessly.',
      image: '/assets/images/business/quick-hub.png',
      pricingImage: '/assets/images/business/bundle.png',
      backgroundImage: '/assets/images/business/ads_description.png',
      benefit: `<h1><span style="color:#0BA861">Effortless Advertising </span> <br />
                 in a Few Clicks.</h1>`,
      message:
        'Discover Quick Ads benefits: launch targeted ad campaigns seamlessly, set your budget, choose your audience, and track performance—all in one place.',
    },
  ];
  const params = useParams();
  const [packageData, setPackageData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [isFirstBusiness, setIsFirstBusiness] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [packageInterval, setPackageInterval] = useState('monthActive');
  const loginUserData = userService.getUserData();
  const welcomeSection =
    params?.productLocation === 'quick-bundle'
      ? options
      : options.filter((item) => item?.url === params?.productLocation);
  const findStatus = options.find((item) => item?.url === params?.productLocation);
  const [isPlaying, setIsPlaying] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [productsDataForId, setProductsDataForId] = useState([]);
  const [loading, setLoading] = useState(false);
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const location = useLocation();
  const videoRef = React.createRef();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const selectedId = productsDataForId?.find(
    (item) => item?.packageTypeId === Number(findStatus?.id)
  );
  const selectedProduct = productsData?.filter((product) => product?.name === selectedId?.name);
  const isProductAvailable = !selectedProduct || new Date(selectedProduct?.expireDate) > new Date();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const payload = {
          query: quickHub.getQuickHubProducts,
          variables: {
            countryId: Number(loginUserData?.countryId),
            packageInterval: 'month',
          },
        };
        const result = await handleApiRequest(payload);
        setLoading(false);
        if (result?.getQuickHubProducts?.status === 200) {
          setProductsDataForId(result?.getQuickHubProducts?.data);
        }
      } catch (error) {
        console.error('Error fetching QuickHub products:', error);
      }
    };
    if (loginUserData?.countryId) {
      fetchData();
    }
  }, [loginUserData?.countryId]);

  useEffect(() => {
    const products = [
      {
        id: 7,
        productName: 'Quick Reviews',
        product: 'quick-reviews',
        descriptionPath: 'quick-reviews/description/',
        dashboardPath: 'quick-reviews/dashboard/',
      },
      {
        id: 9,
        productName: 'Quick Chat',
        product: 'quick-chat',
        descriptionPath: 'quick-chat/description/',
        dashboardPath: 'quick-chat/dashboard/',
      },
      {
        id: 8,
        productName: 'Quick Social',
        product: 'quick-social',
        descriptionPath: 'quick-social/description/',
        dashboardPath: 'quick-social/dashboard/',
      },
      {
        id: 10,
        productName: 'Quick Campaigns',
        product: 'quick-campaign',
        descriptionPath: 'quick-campaign/description/',
        dashboardPath: 'quick-campaign/dashboard/',
      },
      {
        id: 11,
        productName: 'Quick Ads',
        product: 'quick-ads',
        descriptionPath: 'quick-ads/description/',
        dashboardPath: 'quick-ads/dashboard/',
      },
    ].map((product) => ({ ...product, isExpired: false, isPurchasedOneTime: false }));
    const currentDate = new Date();
    const userBusinessSubscriptions =
      userSubscriptionData?.filter(
        ({ userBusinessId }) => userBusinessId === userCurrentBusinessDetails?.id
      ) || [];
    const activeSubscriptions = userBusinessSubscriptions.filter(
      ({ expireDate }) => new Date(expireDate) > currentDate
    );
    if (userCurrentBusinessDetails?.id) {
      const purchaseProductsOrNot = products.map((product) => ({
        ...product,
        isPurchasedOneTime: userBusinessSubscriptions.some(
          (pkg) =>
            pkg.name === product.productName ||
            pkg.comboDetails?.some(({ name }) => name === product.productName) ||
            pkg.packageBundleDetails?.some(({ packageTypeId }) => packageTypeId === product.id)
        ),
      }));
      const updatedProducts = purchaseProductsOrNot.map((product) => {
        const matchingPackage = activeSubscriptions.find(
          (pkg) =>
            pkg.name === product.productName ||
            pkg.comboDetails?.some(({ name }) => name === product.productName) ||
            pkg.packageBundleDetails?.some(({ packageTypeId }) => packageTypeId === product.id)
        );
        return {
          ...product,
          isExpired: !matchingPackage || new Date(matchingPackage.expireDate) <= currentDate,
        };
      });
      updatedProducts.forEach(
        ({ product, isExpired, isPurchasedOneTime, descriptionPath, dashboardPath }) => {
          if (location.pathname.includes(product)) {
            if (!location?.state?.isRedirectNotNeed && !isExpired && isPurchasedOneTime) {
              history.push(dashboardPath, { replace: true });
            } else if (isExpired && location?.state?.isRedirectNotNeed) {
              history.push(descriptionPath, { replace: true });
            }
          }
        }
      );
    }
  }, [
    userSubscriptionData,
    userCurrentBusinessDetails?.id,
    location.pathname,
    location?.state?.isRedirectNotNeed,
  ]);

  useEffect(() => {
    const transformUserSubscriptionData = () => {
      const relevantSubscriptions = userCurrentBusinessDetails?.id
        ? userSubscriptionData?.filter(
            (item) => item?.userBusinessId === userCurrentBusinessDetails?.id
          )
        : userSubscriptionData;
      return relevantSubscriptions?.reduce((acc, item) => {
        const addDetailsToAccumulator = (details, key, expireDate, isFreeTrial) => {
          details?.forEach((detail) => {
            if (detail?.[key]) {
              acc.push({ name: detail[key], expireDate, isFreeTrial });
            }
          });
        };

        if (item?.name && item?.name !== 'quick bundle' && item?.name !== 'quick combo') {
          acc.push({
            name: item?.name,
            expireDate: item?.expireDate,
            isFreeTrial: item?.isFreeTrial,
            isOneTimePayment: item?.isOneTimePayment,
            isSubscription: item?.isSubscription,
            stripePriceId: item?.stripePriceId,
          });
        }
        addDetailsToAccumulator(
          item?.comboDetails,
          'name',
          item?.expireDate,
          item?.isFreeTrial,
          item?.isOneTimePayment,
          item?.isSubscription,
          item?.stripePriceId
        );
        addDetailsToAccumulator(
          item?.packageBundleDetails,
          'name',
          item?.expireDate,
          item?.isFreeTrial,
          item?.isOneTimePayment,
          item?.isSubscription,
          item?.stripePriceId
        );
        return acc;
      }, []);
    };
    const products = transformUserSubscriptionData();
    const areAllProductsValid = products?.every(
      (product) => new Date() <= new Date(product?.expireDate)
    );
    setProductsData(products);
  }, [location?.pathname, userSubscriptionData, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    const fetchFilterData = async () => {
      const payload = {
        query: reviewPlatformData.getPackages,
        variables: {
          packageTypeId: Number(findStatus?.id),
          packageInterval: `${packageInterval === 'monthActive' ? 'month' : 'year'}`,
          countryId: loginUserData?.countryId,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getPackages?.status === 200) {
        setAllData(result?.getPackages?.data[0]);
        setPackageData(
          result?.getPackages?.data[0]?.featureInsideSection &&
            JSON.parse(result?.getPackages?.data[0]?.featureInsideSection)
        );
      }
    };
    fetchFilterData();
  }, [findStatus?.id, loginUserData?.countryId, packageInterval]);

  useEffect(() => {
    const fetchFilterData = async () => {
      try {
        const payload = {
          query: reviewPlatformData.getProduct,
          variables: {
            businessId: Number(userCurrentBusinessDetails?.id),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getProduct?.status === 200) {
          setIsFirstBusiness(result?.getProduct?.isFirstBusiness);
        }
      } catch (err) {
        console.error('Error', err);
      }
    };
    if (userCurrentBusinessDetails?.id) {
      fetchFilterData();
    }
  }, [userCurrentBusinessDetails?.id]);

  const checkFreeTrial = async () => {
    if (selectedId) {
      try {
        const payload = {
          query: reviewPlatformData.startFreeTrail,
          variables: {
            packageIds: [Number(selectedId?.id)],
            userBusinessId: Number(userCurrentBusinessDetails?.id),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.startFreeTrail?.status === 200) {
          history.push('/business/dashboard/');
        } else {
          enqueueSnackbar(result?.startFreeTrail?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (err) {
        console.error('Error', err);
      }
    }
  };

  const myFunction = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleVideoPlay = () => {
    setIsPlaying(true);
  };

  const handleVideoPause = () => {
    setIsPlaying(false);
  };

  const handleVideoEnded = () => {
    setIsPlaying(false);
    videoRef.current.pause();
    videoRef.current.currentTime = 0;
    videoRef.current.load();
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-24 px-10 lg:px-112 font-quicksand">
      <div
        className={`bg-white text-center mt-20 w-full rounded-20 flex flex-col items-center shadow-3 bg-gradient-to-b from-white to-[#DEECEB] ${
          findStatus?.id === 9 || findStatus?.id === 10 ? 'p-0' : 'p-16'
        }`}
      >
        <div
          className={`flex items-center flex-col ${
            findStatus?.id === 9 || findStatus?.id === 10 ? 'w-full' : 'max-w-5xl'
          }`}
        >
          <div className="max-w-5xl p-20 md:p-52">
            <h1 className="text-20 sm:text-28 font-500">{findStatus?.title}</h1>
            <p
              className="mt-10 break-word mainHeading font-700 leading-tight"
              dangerouslySetInnerHTML={{ __html: findStatus?.heading }}
            />
            <p className="mt-20 text-20 sm:text-28 font-500">{findStatus?.description}</p>
            {params?.productLocation !== 'quick-bundle' && (
              <div className="mt-20 flex flex-col sm:flex-row gap-20 justify-center">
                {selectedProduct?.length === 0 &&
                  !isProductAvailable &&
                  (userCurrentBusinessDetails?.id ? isFirstBusiness : true) && (
                    <Button
                      className="rounded px-10 bg-white md:px-28 mx-10 md:mx-0 text-20"
                      color="quickReview"
                      variant="outlined"
                      onClick={checkFreeTrial}
                      size="small"
                    >
                      {t('selectProduct.startFreeTrial')}
                    </Button>
                  )}
                <Button
                  className="rounded px-10 md:px-28 mx-10 md:mx-0 text-20"
                  color="quickReview"
                  variant="contained"
                  onClick={() => {
                    history.push('selectProduct/', {
                      packageBundle: [findStatus?.id],
                      intervalType: 'month',
                      paymentDetails: 'subscription',
                      isBusinessAdded: !!userCurrentBusinessDetails?.id,
                    });
                  }}
                  size="small"
                >
                  {t('common.subscribeNow')}
                </Button>
              </div>
            )}
          </div>
          {(findStatus?.id === 7 || findStatus?.id === 8) && (
            <div className="flex justify-center">
              <img className="md:max-w-512 max-w-200" src={findStatus?.image} alt="" />
            </div>
          )}
        </div>
        <div>
          {(findStatus?.id === 9 ||
            findStatus?.id === 10 ||
            findStatus?.id === 11 ||
            findStatus?.id === 12) && (
            <div className="flex">
              <img className="w-full" src={findStatus?.image} alt="" />
            </div>
          )}
        </div>
      </div>
      {packageData?.title && packageData?.heading && packageData?.description && (
        <div className="bg-white mt-40 px-16 md:px-64 w-full rounded-20 p-20 md:p-64 flex flex-col shadow-3">
          <div className="flex flex-col gap-10">
            <h3 className="font-500">{packageData?.title}</h3>
            <div
              className="font-600 cssInTitle"
              dangerouslySetInnerHTML={{ __html: packageData?.heading }}
            />
            <p className="text-20 max-w-4xl">{packageData?.description}</p>
          </div>
          <div className="grid sm:grid-cols-1 lg:grid-cols-2 mt-16 gap-24">
            {packageData?.featureDetails?.length &&
              packageData?.featureDetails.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col max-w-full md:max-w-2xl bg-gray-A500 p-16 md:p-24 rounded-6"
                >
                  <div className="flex flex-row gap-8">
                    <div>
                      <img src={item?.icon} alt="" className="mr-14 max-w-40" />
                    </div>
                    <div className="flex flex-col justify-center">
                      <h2 className="font-600">{item?.title}</h2>
                      <p className="text-20 leading-8">{item?.details}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {params?.productLocation === 'quick-bundle' && (
            <div className="mt-16 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 col-span-1 gap-16">
              {packageData?.quickHubBundleProductIncluded?.length > 0 &&
                packageData?.quickHubBundleProductIncluded?.map((item, i) => (
                  <div className="bg-gray-A500 rounded-md p-16" key={i}>
                    <div className="flex gap-16 items-center">
                      <img className="w-32 h-32" src={item?.icon} alt="" />
                      <Typography className="text-24 text-medium">{item?.name}</Typography>
                    </div>
                    <Typography className="font-semibold text-16 mt-16">{item?.title}</Typography>
                    {item?.included?.length > 0 && (
                      <ul className="list-disc list-inside mt-16">
                        {item?.included?.map((data, index) => (
                          <li key={index} className="pb-8 font-medium text-14">
                            {data}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
            </div>
          )}
        </div>
      )}
      {params?.productLocation !== 'quick-bundle' && (
        <div
          className="mt-40 text-center flex justify-center items-center w-full h-480 bg-cover bg-no-repeat"
          style={{ backgroundImage: `url(${findStatus?.backgroundImage})` }}
        >
          <div
            className={`${
              findStatus?.url === 'quick-ads' || findStatus?.url === 'quick-campaign'
                ? 'max-w-640'
                : findStatus?.url === 'quick-social'
                ? 'px-192'
                : 'px-80'
            }`}
          >
            <h1
              className="font-600 cssInTitle text-center"
              dangerouslySetInnerHTML={{ __html: findStatus?.benefit }}
            />
            <p className="text-16 md:text-24 text-center font-medium">{findStatus?.message}</p>
          </div>
        </div>
      )}
      <div className="bg-white mt-40 text-center w-full rounded-20 p-20 md:p-64 flex flex-col items-center shadow-3">
        <p className="text-20 font-500">{packageData?.pricingDetails?.title}</p>
        <div>
          <h1
            className="font-600 mt-10 cssInTitle"
            dangerouslySetInnerHTML={{ __html: packageData?.pricingDetails?.heading }}
          />
        </div>
        <p className="text-20 max-w-480 font-500 mt-10">
          {packageData?.pricingDetails?.description}
        </p>
        <div className="text-center mt-36 mb-28">
          <span className="rounded-12 border border-darkgreen inline-block">
            <span
              className={`${
                packageInterval === 'monthActive'
                  ? 'bg-darkgreen text-white'
                  : 'bg-white text-darkgreen'
              } cursor-pointer text-16 font-medium py-8 inline-block rounded-12 min-w-136 text-center`}
              aria-hidden="true"
              onClick={() => {
                setPackageInterval('monthActive');
              }}
            >
              {t('pricingCatalogue.addNew.monthly')}
            </span>
            <span
              className={`${
                packageInterval === 'yearActive'
                  ? 'bg-darkgreen text-white'
                  : 'bg-white text-darkgreen'
              } cursor-pointer text-16 font-medium py-8 inline-block rounded-12 min-w-136 text-center`}
              aria-hidden="true"
              onClick={() => {
                setPackageInterval('yearActive');
              }}
            >
              {t('pricingCatalogue.addNew.yearly')}
            </span>
          </span>
        </div>

        <div className="w-full flex gap-20 md:flex-row flex-col justify-evenly">
          <div className="border rounded-8 flex items-center w-full p-10 !border-darkgreen">
            <img className="m-auto" src={findStatus?.pricingImage} alt="" />
          </div>
          <div className="border rounded-8 !border-darkgreen md:w-400">
            <div className="p-20">
              <img className="max-w-64" src={allData?.image} alt="" />
              <p className="mt-14 text-20 text-start font-600">{allData?.name}</p>
              <Typography className="mt-14 text-start text-14 text-grey-700 font-500">
                {packageData?.pricingDetails?.description}
              </Typography>
              {allData?.offerAmount && (
                <Typography className="text-24 text-start font-bold mb-0">
                  <span>{loginUserData?.countryId === 2 ? '₹' : '$'}</span>
                  {allData?.offerAmount}/{allData?.packageInterval?.replace(/_/g, ' ')}
                </Typography>
              )}
              <Typography
                className={`block text-start ${
                  allData?.offerAmount
                    ? 'line-through text-grey-500 font-medium text-20'
                    : 'text-24 font-bold'
                }`}
              >
                <span>{loginUserData?.countryId === 2 ? '₹' : '$'}</span>
                {allData?.amount}/{allData?.packageInterval?.replace(/_/g, ' ')}
              </Typography>
              <Button
                className="bg-darkgreen mt-14 flex justify-center rounded px-10 md:px-28 leading-5 w-full text-white disabled:bg-gray disabled:border-gray border border-solid border-darkgreen hover:bg-darkgreen mx-0 text-16"
                type="button"
                onClick={() =>
                  history.push('selectProduct/', {
                    packageBundle: [allData?.packageTypeId],
                    intervalType: allData?.packageInterval,
                    paymentDetails: 'subscription',
                    isBusinessAdded: !!userCurrentBusinessDetails?.id,
                  })
                }
              >
                {t('common.subscribeNow')}
              </Button>
              <h3 className="my-14 font-600 text-16 text-start">{packageData?.title}</h3>
              {packageData?.pricingDetails?.included?.length &&
                packageData?.pricingDetails?.included.map((item, index) => (
                  <div className="flex items-center pb-10">
                    {' '}
                    <img
                      src="/assets/images/business/check_circle.svg"
                      className="img-fluid max-w-20 mr-20"
                      alt="..."
                    />{' '}
                    <p className="text-14 font-500 text-start">{item}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
