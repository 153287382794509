import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  FormControl,
  Icon,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import history from '@history';
import { useLocation, useParams } from 'react-router-dom';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import SentimentSatisfiedAltOutlinedIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentNeutralOutlinedIcon from '@mui/icons-material/SentimentNeutralOutlined';
import Pagination from 'src/app/component/table/Pagination';
import {
  capitalizeFirstLetter,
  formatDateWithTimeZone,
  handleApiRequest,
} from '../../../common/common';
import manageBusinessData from '../../../query/manageBusiness';
import { selectDashboardData } from '../../../../store/userSlice';
import UserProfile from '../../../common/userProfile';

const ServiceRecovery = () => {
  const params = useParams();
  const options = [
    { id: 1, label: 'request_for_Call', value: 'Request for call', buttonName: 'Request' },
    { id: 2, label: 'called', value: 'Called', buttonName: 'Called' },
    { id: 3, label: 'solved', value: 'Solved', buttonName: 'Solved' },
  ];
  const [tabValue, setTabValue] = useState(0);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [negativeReviewData, setNegativeReviewData] = useState([]);
  const [negativeReview, setNegativeReview] = useState([]);
  const [preventionData, setPreventionData] = useState([]);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [customerReviewData, setCustomerReviewData] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [negativeReviewId, setNegativeReviewId] = useState();
  const [star, setStar] = useState();
  const [face, setFace] = useState();
  const [pageLoading, setPageLoading] = useState(true);
  const [changeStatus, setChangeStatus] = useState('all');
  const location = useLocation();
  const { plateFormID, paramId } = location.state || {};
  const productId = Number(params?.productId);
  const [enableDisable, setEnableDisable] = useState({
    recoveryStatus: false,
    preventionStatus: true,
  });
  const [totalPreventData, setTotalPreventData] = useState();
  const [page, setPage] = useState(1);
  const [totalServiceRecoveryData, setTotalServiceRecoveryData] = useState();
  const [serviceRecoveryPage, setServiceRecoveryPage] = useState(1);
  const [serviceRecoveryPerPage, setserviceRecoveryPerPage] = useState(10);
  const { enqueueSnackbar } = useSnackbar();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const fetchBusinessData = useCallback(async () => {
    let payload;
    let apiResult;
    try {
      if (Number(plateFormID)) {
        const variables = { userBusinessId: Number(userCurrentBusinessDetails?.id) };

        if (productId) {
          if (Number(plateFormID) === 5) {
            variables.amazonProductId = productId;
          } else if (Number(plateFormID) === 6) {
            variables.flipkartProductId = productId;
          }
        }
        payload = {
          query:
            Number(plateFormID) === 5
              ? manageBusinessData.getAmazonProduct
              : Number(plateFormID) === 6 && manageBusinessData.getFlipkartProduct,
          variables,
        };

        apiResult = await handleApiRequest(payload);
        const res =
          Number(plateFormID) === 5 ? apiResult?.getAmazonProduct : apiResult?.getFlipkartProduct;

        setPageLoading(false);

        if (res?.status === 200) {
          const filteredProducts = res?.data?.filter((item) => item?.id);
          if (filteredProducts.length > 0) {
            setEnableDisable({
              recoveryStatus: filteredProducts[0]?.isNegativeReviewRedirect,
              preventionStatus: filteredProducts[0]?.isNegativeReviewsPrevention,
            });
          }
        }
      } else {
        payload = {
          query: manageBusinessData.getUserBusinessDetail,
        };
        const result = await handleApiRequest(payload);
        if (result?.getUserBusinessDetail?.status === 200) {
          const data = result.getUserBusinessDetail?.data;
          const findData = data?.find(
            (item) => Number(item?.id) === Number(userCurrentBusinessDetails?.id)
          );
          setEnableDisable({
            recoveryStatus: findData?.isNegativeReviewRedirect,
            preventionStatus: findData?.isNegativeReviewsPrevention,
          });
        } else {
          enqueueSnackbar(result?.getUserBusinessDetail?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    } catch (error) {
      console.error('error', error);
    }
  }, [plateFormID, userCurrentBusinessDetails?.id, productId, enqueueSnackbar]);

  useEffect(() => {
    fetchBusinessData();
  }, [fetchBusinessData]);

  useEffect(() => {
    const fetchData = async () => {
      setPageLoading(true);
      const variables = {};
      if (userCurrentBusinessDetails?.isOnlineBusiness && productId) {
        if (Number(plateFormID) === 5) {
          variables.amazonProductId = productId;
        } else if (Number(plateFormID) === 6) {
          variables.flipkartProductId = productId;
        }
      } else {
        variables.businessId = Number(userCurrentBusinessDetails?.id);
      }
      variables.pageNumber = Number(page);
      variables.pageSize = rowsPerPage || 10;
      const payload = {
        query: manageBusinessData.getNegativeReviewsBaseOnPrevention,
        variables,
      };
      const result = await handleApiRequest(payload);
      setPageLoading(false);

      if (result?.getNegativeReviewsBaseOnPrevention?.status === 200) {
        setPreventionData(result?.getNegativeReviewsBaseOnPrevention?.data);
        setTotalPreventData(result?.getNegativeReviewsBaseOnPrevention?.totalCount);
      } else {
        enqueueSnackbar(result?.getNegativeReviewsBaseOnPrevention?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    };
    if (userCurrentBusinessDetails?.id) {
      fetchData();
    }
  }, [
    userCurrentBusinessDetails?.id,
    userCurrentBusinessDetails?.isOnlineBusiness,
    enqueueSnackbar,
    productId,
    plateFormID,
    page,
    rowsPerPage,
  ]);

  useEffect(() => {
    const fetchPreventData = async () => {
      try {
        setPageLoading(true);
        const variables = {};

        if (userCurrentBusinessDetails?.isOnlineBusiness && productId) {
          if (Number(plateFormID) === 5) {
            variables.amazonProductId = productId;
          } else if (Number(plateFormID) === 6) {
            variables.flipkartProductId = productId;
          }
        } else {
          variables.userBusinessId = Number(userCurrentBusinessDetails?.id);
        }
        variables.pageNumber = Number(serviceRecoveryPage);
        variables.pageSize = serviceRecoveryPerPage || 10;
        const payload = {
          query: manageBusinessData.getNegativeReviewRedirectStatus,
          variables,
        };

        const result = await handleApiRequest(payload);
        setPageLoading(false);

        if (result?.getNegativeReviewRedirectStatus?.status === 200) {
          const data = result?.getNegativeReviewRedirectStatus?.data;
          setTotalServiceRecoveryData(result?.getNegativeReviewRedirectStatus?.totalCount);
          setNegativeReviewData(data);
          setNegativeReview(data);
        } else {
          enqueueSnackbar(result?.getNegativeReviewRedirectStatus?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        setPageLoading(false);
        console.error('An error occurred while fetching preventive data', error);
      }
    };
    if (userCurrentBusinessDetails?.id) {
      fetchPreventData();
    }
  }, [
    userCurrentBusinessDetails?.id,
    enqueueSnackbar,
    productId,
    plateFormID,
    userCurrentBusinessDetails?.isFlipkartProducts,
    userCurrentBusinessDetails?.isOnlineBusiness,
    userCurrentBusinessDetails?.isAmazonProducts,
    serviceRecoveryPage,
    serviceRecoveryPerPage,
  ]);

  const viewCustomerData = async (id) => {
    setNegativeReviewId(id);
    setOpenDialogue(true);

    const variables = {
      negativeReviewId: Number(id),
    };
    if (userCurrentBusinessDetails?.isOnlineBusiness && productId) {
      switch (Number(plateFormID)) {
        case 5:
          variables.amazonProductId = productId;
          break;
        case 6:
          variables.flipkartProductId = productId;
          break;
        default:
          break;
      }
    } else {
      variables.userBusinessId = Number(userCurrentBusinessDetails?.id);
    }
    const payload = {
      query: manageBusinessData.getNegativeReview,
      variables,
    };
    const result = await handleApiRequest(payload);
    if (result?.getNegativeReview?.status === 200) {
      const data = result?.getNegativeReview?.data[0];
      setCustomerReviewData(data);
      const findStatus = options.find((item) => item.label === data.status);
      setSelectedStatus(findStatus?.id);
    } else {
      enqueueSnackbar(result?.getNegativeReview?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const updateNegativeReview = async () => {
    const findStatus = options.find((item) => Number(item.id) === Number(selectedStatus));
    setRefreshIcon(true);
    const payload = {
      query: manageBusinessData.negativeReviewUpdate,
      variables: {
        negativeReviewId: Number(negativeReviewId),
        status: findStatus?.label,
      },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (result?.negativeReviewUpdate?.status === 200) {
      handleCancel();
      const variables = {};

      if (userCurrentBusinessDetails?.isOnlineBusiness && productId) {
        if (Number(plateFormID) === 5) {
          variables.amazonProductId = productId;
        } else if (Number(plateFormID) === 6) {
          variables.flipkartProductId = productId;
        }
      } else {
        variables.userBusinessId = Number(userCurrentBusinessDetails?.id);
      }
      variables.pageNumber = Number(serviceRecoveryPage);
      variables.pageSize = serviceRecoveryPerPage || 10;
      const obj = {
        query: manageBusinessData.getNegativeReviewRedirectStatus,
        variables,
      };
      const res = await handleApiRequest(obj);
      if (res?.getNegativeReviewRedirectStatus?.status === 200) {
        const resData = res?.getNegativeReviewRedirectStatus?.data;
        setTotalServiceRecoveryData(res?.getNegativeReviewRedirectStatus?.totalCount);
        setNegativeReviewData(resData);
        setNegativeReview(resData);
        if (changeStatus === 'all') {
          setNegativeReviewData(resData);
        } else {
          const findStatusLabel = options.find((item) => Number(item.id) === Number(changeStatus));
          const filterData = resData?.filter((item) => item.status === findStatusLabel?.label);
          setNegativeReviewData(filterData);
        }
      } else {
        enqueueSnackbar(res?.getNegativeReviewRedirectStatus?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } else {
      enqueueSnackbar(result?.negativeReviewUpdate?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleCancel = () => {
    setSelectedStatus();
    setNegativeReviewId();
    setOpenDialogue(false);
    setCustomerReviewData();
  };

  const handleChangeStatus = async (e) => {
    const statusValue = e.target.value;
    setChangeStatus(statusValue);
    if (statusValue === 'all') {
      setNegativeReviewData(negativeReview);
    } else {
      const findStatus = options.find((item) => Number(item.id) === Number(statusValue));
      const filterData = negativeReview?.filter((item) => item.status === findStatus?.label);
      setNegativeReviewData(filterData);
    }
  };

  const enableDisableService = async () => {
    setEnableDisable({ ...enableDisable, recoveryStatus: !enableDisable.recoveryStatus });
    try {
      if (productId) {
        const payload = {
          query: manageBusinessData.setAmazonAndFlipkartNegativeReviewRedirect,
          variables: {
            isActive: !enableDisable.recoveryStatus,
            ...(enableDisable.recoveryStatus === true && {
              negativeReviewsPrevention: false,
            }),
          },
        };
        if (Number(plateFormID) === 5) {
          payload.variables.amazonProductId = productId;
        } else if (Number(plateFormID) === 6) {
          payload.variables.flipkartProductId = productId;
        }
        const result = await handleApiRequest(payload);
        if (result?.setAmazonAndFlipkartNegativeReviewRedirect?.status === 200) {
          await fetchBusinessData();
        } else {
          enqueueSnackbar(result?.setAmazonAndFlipkartNegativeReviewRedirect?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } else {
        const payload = {
          query: manageBusinessData.setNegativeReviewRedirect,
          variables: {
            userBusinessId: Number(userCurrentBusinessDetails?.id),
            isActive: !enableDisable.recoveryStatus,
            ...(enableDisable.recoveryStatus === true && {
              negativeReviewsPrevention: false,
            }),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.setNegativeReviewRedirect?.status === 200) {
          await fetchBusinessData();
        } else {
          enqueueSnackbar(result?.setNegativeReviewRedirect?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    } catch (error) {
      console.error('error', error);
    }
  };
  const handlePageChange = (event, value) => {
    setPage(value + 1);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleServiceRecoveryPageChange = (event, value) => {
    setServiceRecoveryPage(value + 1);
  };
  const handleChangeServiceRecoveryPerPage = (event) => {
    setserviceRecoveryPerPage(parseInt(event.target.value, 10));
    setServiceRecoveryPage(1);
  };

  const enableDisablePrevention = async () => {
    if (enableDisable.recoveryStatus) {
      setEnableDisable({
        ...enableDisable,
        preventionStatus: !enableDisable.preventionStatus,
      });
      try {
        if (productId) {
          const payload = {
            query: manageBusinessData.setAmazonAndFlipkartNegativeReviewRedirect,
            variables: {
              isActive: enableDisable.recoveryStatus,
              isNegativeReviewsPrevention: !enableDisable.preventionStatus,
            },
          };
          if (Number(plateFormID) === 5) {
            payload.variables.amazonProductId = productId;
          } else if (Number(plateFormID) === 6) {
            payload.variables.flipkartProductId = productId;
          }
          const result = await handleApiRequest(payload);
          if (result?.setAmazonAndFlipkartNegativeReviewRedirect?.status === 200) {
            fetchBusinessData();
          } else {
            enqueueSnackbar(result?.setAmazonAndFlipkartNegativeReviewRedirect?.message, {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
        } else {
          const payload = {
            query: manageBusinessData.setNegativeReviewRedirect,
            variables: {
              userBusinessId: Number(userCurrentBusinessDetails?.id),
              isActive: enableDisable.recoveryStatus,
              negativeReviewsPrevention: !enableDisable.preventionStatus,
            },
          };
          const result = await handleApiRequest(payload);
          if (result?.setNegativeReviewRedirect?.status === 200) {
            fetchBusinessData();
          } else {
            enqueueSnackbar(result?.setNegativeReviewRedirect?.message, {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
        }
      } catch (error) {
        console.error('error', error);
      }
    }
  };

  const fetchAttributeLevelThreshold = useCallback(async () => {
    try {
      setPageLoading(true);
      const variables = {};
      if (userCurrentBusinessDetails?.isOnlineBusiness && productId) {
        if (Number(plateFormID) === 5) {
          variables.amazonProductId = Number(productId);
        } else if (Number(plateFormID) === 6) {
          variables.flipkartProductId = Number(productId);
        }
      } else {
        variables.userBusinessId = Number(userCurrentBusinessDetails?.id);
      }

      const payload = {
        query: manageBusinessData.getAttributeLevelThreshold,
        variables,
      };

      const result = await handleApiRequest(payload);
      setPageLoading(false);
      if (result?.getAttributeLevelThreshold?.status === 200) {
        const { data } = result.getAttributeLevelThreshold;
        setStar(data?.star);
        setFace(data?.face);
      } else {
        enqueueSnackbar(result?.getAttributeLevelThreshold?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setPageLoading(false);
      console.error('An error occurred while fetching attribute level threshold', error);
    }
  }, [
    userCurrentBusinessDetails?.isOnlineBusiness,
    userCurrentBusinessDetails?.id,
    productId,
    plateFormID,
    enqueueSnackbar,
  ]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      fetchAttributeLevelThreshold();
    }
  }, [fetchAttributeLevelThreshold, userCurrentBusinessDetails?.id]);

  const handleStarChange = (event) => {
    const newStarValue = event.target.value;
    setStar(newStarValue);
    updateAttributeLevelThreshold(newStarValue);
  };

  const handleFaceChange = (event) => {
    const newFaceValue = event.target.value;
    setFace(newFaceValue);
    updateAttributeLevelThreshold(newFaceValue);
  };

  const getFaceIcon = (value) => {
    switch (value) {
      case 'Sad':
        return <SentimentDissatisfiedOutlinedIcon className="text-16 align-middle" />;
      case 'Neutral':
        return <SentimentNeutralOutlinedIcon className="text-16 align-middle" />;
      case 'Happy':
        return <SentimentSatisfiedAltOutlinedIcon className="text-16 align-middle" />;
      default:
        return null;
    }
  };

  const updateAttributeLevelThreshold = async (value) => {
    try {
      setRefreshIcon(true);
      const variables = {};
      if (userCurrentBusinessDetails?.isOnlineBusiness && productId) {
        if (Number(plateFormID) === 5) {
          variables.amazonProductId = productId;
        } else if (Number(plateFormID) === 6) {
          variables.flipkartProductId = productId;
        }
      } else {
        variables.userBusinessId = Number(userCurrentBusinessDetails?.id);
      }
      if (typeof value === 'number') {
        variables.star = value;
      }
      if (typeof value === 'string') {
        variables.face = value;
      }

      const payload = {
        query: manageBusinessData.updateAttributeLevelThreshold,
        variables,
      };

      const result = await handleApiRequest(payload);
      setRefreshIcon(false);

      if (result?.updateAttributeLevelThreshold?.status === 200) {
        enqueueSnackbar(result?.updateAttributeLevelThreshold?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        fetchAttributeLevelThreshold();
      } else {
        enqueueSnackbar(result?.updateAttributeLevelThreshold?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('An error occurred while updating attribute level threshold', error);
    }
  };

  if (pageLoading) {
    return <FuseLoading />;
  }

  return (
    <div>
      {params?.productId && (
        <div className="">
          <Toolbar className="pt-32 font-semibold min-h-fit">
            <Icon className="text-20 cursor-pointer" onClick={() => history.go(-1)}>
              west
            </Icon>
            <Typography
              variant="subtitle1"
              color="inherit"
              className="flex-1 px-12 font-bold text-18"
            >
              {t('products.productSettings')}
            </Typography>
          </Toolbar>
        </div>
      )}
      <div className={`${!productId ? 'lg:p-24 p-16' : 'h-max lg:p-24 p-16'}`}>
        <div className="flex items-center lg:mb-20 mb-16">
          <Typography
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex md:text-28 text-20 font-bold "
          >
            {t('serviceRecovery.title')}
          </Typography>
        </div>
        <div className="bg-white p-14 sm:p-28 rounded-md">
          <div className="flex items-center justify-between">
            <div className="w-5/6">
              <Typography className="font-bold text-20">
                {t('serviceRecovery.enableServiceRecovery')}
              </Typography>
              <Typography className="font-semibold text-14 leading-8 mt-16">
                {t('serviceRecovery.enableServiceRecoveryMsg')}
              </Typography>
            </div>
            <Switch checked={enableDisable.recoveryStatus} onChange={enableDisableService} />
          </div>
        </div>
        {enableDisable.recoveryStatus && (
          <div className="bg-white p-14 sm:p-28 rounded-md mt-28">
            <div className="d-flex flex-col">
              <div className="w-5/6">
                <Typography className="font-bold text-20">
                  {t('serviceRecovery.setAttributeThresholdLevel')}
                </Typography>
              </div>
              <hr className="my-16 w-full border-dashed border border-gray-400" />
              {star && face && (
                <>
                  <div>
                    <Typography className="font-normal text-15 pb-5">
                      {t('serviceRecovery.setStarRating')}
                    </Typography>
                    <FormControl fullWidth>
                      <Select value={star} onChange={handleStarChange} displayEmpty>
                        {Array.from({ length: 5 }, (_, index) => index + 1).map((value, i) => (
                          <MenuItem key={i} value={value} className="hover:bg-quick-review-A300">
                            <StarBorderOutlinedIcon className="font-semibold text-15 align-middle" />
                            <span className="pl-5 text-15 align-middle font-semibold">{`${value} Star`}</span>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <Typography className="font-normal text-15 pb-5 mt-16">
                      {t('serviceRecovery.setFaceRating')}
                    </Typography>

                    <FormControl fullWidth>
                      <Select value={face} onChange={handleFaceChange} displayEmpty>
                        {['Sad', 'Neutral', 'Happy'].map((value, i) => (
                          <MenuItem key={i} value={value} className="hover:bg-quick-review-A300">
                            {getFaceIcon(value)}
                            <span className="pl-5 font-semibold text-15 align-middle">{`${value}`}</span>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {(!userCurrentBusinessDetails?.isOnlineBusiness ||
          (userCurrentBusinessDetails?.isOnlineBusiness && params?.productId)) && (
          <div className="bg-white p-14 sm:p-28 rounded-md mt-28">
            <div className="flex items-center justify-between">
              <div className="w-5/6">
                <Typography className="font-bold text-20">
                  {t('serviceRecovery.preventReviewUploads')}
                </Typography>
                <Typography className="font-semibold text-14 leading-8 mt-16">
                  {t('serviceRecovery.preventReviewUploadsMsg')}
                </Typography>
              </div>
              <Switch checked={enableDisable.preventionStatus} onChange={enableDisablePrevention} />
            </div>
          </div>
        )}
        <div className="bg-white p-14 sm:p-28 rounded-md min-h-384 mt-28">
          <div className="border-b-2 pb-12 border-solid border-grey mb-28 sm:flex justify-between items-center">
            <Tabs
              value={tabValue}
              onChange={(e, newValue) => setTabValue(newValue)}
              textColor={
                !userCurrentBusinessDetails?.isOnlineBusiness ||
                (userCurrentBusinessDetails?.isOnlineBusiness && params?.productId)
                  ? 'secondary'
                  : 'primary'
              }
              indicatorColor={
                !userCurrentBusinessDetails?.isOnlineBusiness ||
                (userCurrentBusinessDetails?.isOnlineBusiness && params?.productId)
                  ? 'secondary'
                  : 'null'
              }
              aria-label="secondary tabs example"
            >
              <Tab
                className="sm:text-18 font-semibold p-0 sm:mr-28 mr-14"
                value={0}
                label={t('serviceRecovery.serviceRecoveryInstance')}
              />

              {(!userCurrentBusinessDetails?.isOnlineBusiness ||
                (userCurrentBusinessDetails?.isOnlineBusiness && params?.productId)) && (
                <Tab
                  className="sm:text-18 font-semibold p-0"
                  value={1}
                  label={t('serviceRecovery.preventedReviews')}
                />
              )}
            </Tabs>
            {tabValue === 0 && (
              <Select
                className="w-160 mt-8 sm:0 bg-quick-review-100"
                value={changeStatus || 'all'}
                size="small"
                onChange={handleChangeStatus}
              >
                <MenuItem value="all">All</MenuItem>
                {options.map((item, i) => {
                  return (
                    <MenuItem value={item.id} key={i}>
                      {item.value}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          </div>
          {Number(tabValue) === 0 && (
            <>
              {negativeReviewData?.length > 0 ? (
                <>
                  {negativeReviewData?.map((item, i) => {
                    const buttonName = options?.find((button) => button?.label === item?.status);
                    return (
                      <div className="sm:flex justify-between bg-gray-100 p-16 mb-16" key={i}>
                        <div className="flex items-center gap-16">
                          <div>
                            <UserProfile name={item?.name} />
                          </div>
                          <div>
                            <Typography className="font-semibold text-16">
                              {capitalizeFirstLetter(item?.name)}
                            </Typography>
                            <Typography>
                              <AccessTimeIcon className="text-gray w-16" />
                              <span className="pl-8">
                                {formatDateWithTimeZone(item?.cts, 'MMM DD, YYYY')}
                              </span>
                              <span className="pl-8">
                                {formatDateWithTimeZone(item?.cts, 'hh:mm A')}
                              </span>
                            </Typography>
                          </div>
                        </div>
                        <div className="mt-12 sm:mt-0">
                          <Button
                            className={`${
                              buttonName?.buttonName === 'Request'
                                ? 'bg-yellow-A300 !text-yellow-A500'
                                : buttonName?.buttonName === 'Called'
                                ? '!bg-blue !text-white'
                                : '!bg-green !text-white'
                            } rounded px-36`}
                            onClick={() => viewCustomerData(item?.id)}
                          >
                            {buttonName?.buttonName}
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                  <Pagination
                    totalCount={totalServiceRecoveryData}
                    rowsPerPage={serviceRecoveryPerPage}
                    page={serviceRecoveryPage}
                    handleChangePage={handleServiceRecoveryPageChange}
                    handleChangeRowsPerPage={handleChangeServiceRecoveryPerPage}
                  />
                </>
              ) : (
                <div className="flex items-center justify-center">
                  <div>
                    <img className="w-96 m-auto" src="/assets/images/icon/Group38341.svg" alt="" />
                    <Typography className="text-center text-20 pt-12 font-semibold">
                      {t('serviceRecovery.noServiceRecovery')}
                    </Typography>
                    <Typography className="text-center pt-10 sm:max-w-288 m-auto">
                      {t('serviceRecovery.noServiceMessage')}
                    </Typography>
                  </div>
                </div>
              )}
            </>
          )}
          {Number(tabValue) === 1 && (
            <>
              {preventionData?.length > 0 ? (
                <>
                  {preventionData?.map((prevent, i) => {
                    return (
                      <div className="flex w-full justify-between bg-gray-100 p-16 mb-16" key={i}>
                        <div className="w-full">
                          {[...Array(5)].map((_, index) => (
                            <Icon
                              className={`text-16 align-middle mr-2 -mt-2 ${
                                index < prevent?.rating ? 'text-yellow-800' : 'text-gray-400'
                              }`}
                              key={index}
                            >
                              star
                            </Icon>
                          ))}
                          <div className="flex item-center justify-between">
                            <Typography className="font-semibold">
                              {formatDateWithTimeZone(prevent?.cts, 'DD-MM-YYYY')}
                            </Typography>
                            {prevent.phoneNumber?.phoneNumber !== 0 && (
                              <Typography>
                                {prevent.phoneNumber?.countryCode}{' '}
                                {prevent.phoneNumber?.phoneNumber}
                              </Typography>
                            )}
                          </div>
                          <Typography className="pt-6 text-14">{prevent.review}</Typography>
                        </div>
                      </div>
                    );
                  })}
                  <Pagination
                    totalCount={totalPreventData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handlePageChange}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <div className="flex items-center justify-center">
                  <div>
                    <img className="w-96 m-auto" src="/assets/images/icon/downthumb.svg" alt="" />
                    <Typography className="text-center text-20 pt-12 font-semibold">
                      {t('serviceRecovery.noPreventedYet')}
                    </Typography>
                    <Typography className="text-center pt-10 sm:max-w-288 m-auto">
                      {t('serviceRecovery.noPreventedMsg')}
                    </Typography>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <Dialog
          classes={{
            paper:
              'm-24 p-28 business-type-dropdown border-t-8 border-solid border-t-quick-review border-red-A300 rounded-md overflow-auto',
          }}
          PaperProps={{ style: { maxHeight: '600px', overflowY: 'scroll' } }}
          open={openDialogue}
          fullWidth
          maxWidth="xs"
        >
          <div className="overflow-y-auto">
            <Icon
              className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
              onClick={() => handleCancel()}
            >
              cancel
            </Icon>
            <Typography className="text-20 font-semibold text-black w-full text-left">
              {t('serviceRecovery.serviceRecoveryDetails')}
            </Typography>
            <hr className="border border-dashed border-solid my-8 border-gray-100" />
            <div>
              <Typography className="mt-12">{t('serviceRecovery.customerName')}</Typography>
              <Typography className="bg-gray-100 p-10 mt-8 font-semibold">
                {customerReviewData?.name || '-'}
              </Typography>
            </div>
            <div>
              <Typography className="mt-12">{t('common.phoneNo')}</Typography>
              <Typography className="bg-gray-100 p-10 mt-8 font-semibold">
                {`${customerReviewData?.countryCode} ${customerReviewData?.phoneNumber}` || '-'}
              </Typography>
            </div>
            <div>
              <Typography className="mt-12">{t('serviceRecovery.autoGeneratedText')}</Typography>
              <Typography className="bg-gray-100 p-10 mt-8 font-semibold">
                {customerReviewData?.autoGeneratedReview || '-'}
              </Typography>
            </div>
            <div>
              <Typography className="mt-12">{t('serviceRecovery.dateOfCallRequest')}</Typography>
              <Typography className="bg-gray-100 p-10 mt-8 font-semibold">
                {formatDateWithTimeZone(customerReviewData?.cts, 'DD-MM-YYYY')}
              </Typography>
            </div>
            <div>
              <Typography className="mt-12">{t('common.note')}</Typography>
              <Typography className="bg-gray-100 p-10 mt-8 font-semibold">
                {customerReviewData?.reason || '-'}
              </Typography>
            </div>
            <div>
              <Typography className="mt-12">{t('common.status')}</Typography>
              <Select
                className="w-full"
                size="small"
                value={Number(selectedStatus)}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                {options.map((item, i) => {
                  return (
                    <MenuItem value={item.id} key={i}>
                      {item.value}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div className="text-center">
              <Button
                className="bg-quick-review rounded mt-20 disabled:bg-gray disabled:text-black w-224 md:px-60 text-white hover:bg-quick-review mx-10 md:mx-0"
                disabled={refreshIcon}
                onClick={() => updateNegativeReview()}
              >
                {refreshIcon && (
                  <CircularProgress size={24} className="text-quick-review absolute mx-auto" />
                )}
                {t('common.save')}
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default ServiceRecovery;
