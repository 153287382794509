import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import history from '@history';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSnackbar } from 'notistack';
import { Button, Typography, CircularProgress, TextField, Switch, Box } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  DatePicker,
  LocalizationProvider,
  renderTimeViewClock,
  TimePicker,
} from '@mui/x-date-pickers';

function ChooseMedia({ setActiveStep, mediaFiles, setMediaFiles, setFrom, adsData, refreshIcon }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const apiKey = 'AAGbst3NNN8';

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array?.from(e.dataTransfer.files);
    setMediaFiles(files);
  };

  const handleFileUpload = (e) => {
    const files = Array?.from(e.target.files);
    setMediaFiles(files);
  };

  const triggerFileInput = () => {
    if (fileInputRef?.current) {
      fileInputRef?.current?.click();
    }
  };

  const handleDivClick = () => {
    document.getElementById('mediaUpload')?.click();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handleDivClick();
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="quick-social">
      <div className="bg-white rounded-lg border-1 border-solid border-grey-300 p-20 mb-20 ">
        <Typography className="font-semibold mb-10 mt-0 text-14 lg:text-18">
          {t('quickAds.uploadOrCreateMedia')}
        </Typography>
        {!mediaFiles && !adsData?.mediaUrl ? (
          <div className="flex md:gap-20 gap-10 mb-24">
            {/* <div>
            {' '}
            <Button
              // onClick={handleRequestExport}
              className="bg-blue-500 text-white py-2 px-4 rounded"
            >
              Open Canva Editor
            </Button>{' '}
          </div> */}
            <div
              className="border-dashed border-2 border-gray-300 rounded-md p-64 flex flex-col items-center justify-center text-center cursor-pointer hover:border-gray-500 transition-all"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onClick={handleDivClick}
              onKeyDown={handleKeyDown}
              role="button"
              tabIndex={0}
            >
              <img src="assets/images/icon/upLoadImg.svg" alt="Preview" />
              <p className="text-18 font-500">{t('quickAds.mediaUpLoad')}</p>
              <label htmlFor="mediaUpload" className="mt-2 cursor-pointer text-18 font-500">
                {t('quickAds.browseFiles')}
                <input
                  id="mediaUpload"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleFileUpload}
                />
              </label>
            </div>
          </div>
        ) : (
          <>
            <img
              src={mediaFiles ? URL?.createObjectURL(mediaFiles[0]) : adsData?.mediaUrl}
              alt="preview"
              className="max-h-640 max-w-640"
            />
          </>
        )}
      </div>

      <div className="flex items-center justify-end gap-10">
        {(mediaFiles || adsData?.mediaUrl) && (
          <>
            <Button
              className="md:text-16 font-medium rounded-md px-28 disabled:bg-gray"
              variant="outlined"
              color="quickSocial"
              disabled={refreshIcon}
              onClick={triggerFileInput}
            >
              {t('quickAds.replaceMedia')}
              {refreshIcon && (
                <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
              )}
            </Button>
            <input
              type="file"
              accept="image/*"
              className="hidden"
              ref={fileInputRef}
              onChange={handleFileUpload}
            />
          </>
        )}
        <Button
          className="md:text-16 font-medium rounded-md px-28 disabled:bg-gray"
          variant="contained"
          color="quickSocial"
          onClick={() => {
            setFrom('preview');
            setActiveStep(3);
          }}
          disabled={(!mediaFiles && !adsData?.mediaUrl) || refreshIcon}
        >
          {t('quickAds.proceed')}
          {refreshIcon && (
            <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
          )}
        </Button>
      </div>
    </div>
  );
}
export default ChooseMedia;
