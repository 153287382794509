/* global FB */
import {
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  Drawer,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { QRCodeCanvas } from 'qrcode.react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { t } from 'i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSnackbar } from 'notistack';
import PhoneInput from 'react-phone-input-2';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import userService from 'src/app/services';
import wabaQuery from 'src/app/main/query/waba';
import {
  getDecryptData,
  handlePhoneNumberValidationCheck,
  handleWabaApiRequest,
  wabaImageSendToServer,
} from 'src/app/main/common/common';

const WabaDashBoard = () => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const loginUserData = userService.getUserData();
  const { enqueueSnackbar } = useSnackbar();
  const fileInputRefProfile = useRef(null);
  const [sector, setSector] = useState([]);
  const [isOpenWhatsApp, setIsOpenWhatsApp] = useState(false);
  const [isShowProfile, setIsShowProfile] = useState(false);
  const [isOpenProfileUpdate, setIsOpenProfileUpdate] = useState(false);
  const [isOpenApplyGreenTick, setIsOpenApplyGreenTick] = useState(false);
  const [isProfileEdit, setIsProfileEdit] = useState(false);
  const [businessDescription, setBusinessDescription] = useState('');
  const [businessSector, setBusinessSector] = useState('');
  const [businessAddress, setBusinessAddress] = useState('');
  const [businessEmail, setBusinessEmail] = useState('');
  const [businessWebSite, setBusinessWebSite] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [accountDetail, setAccountDetail] = useState();
  const [profileImage, setProfileImage] = useState();
  const [refresh, setRefresh] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [greenTickStatus, setGreenTickStatus] = useState([]);
  const [linkDrawer, setLinkDrawer] = useState(false);
  const [customWabaLinkMessage, setCustomWabaLinkMessage] = useState();
  const [phone, setPhone] = useState();
  const [qrValue, setQRValue] = useState([]);
  const [isORPopUp, setIsORPopUp] = useState(true);
  const redirectURL = process.env.REACT_APP_WABA_SCANNER_REDIRECT_URL;
  const qrLink = `${redirectURL}uniqueId=${qrValue?.uniqueId}`;
  const appKey = process.env.REACT_APP_WABA_APP_ID;
  const configId = process.env.REACT_APP_WABA_CONFIG_ID;
  const greenTickForm = process.env.REACT_APP_GREEN_TICK_FORM;
  const [whatsAppBusinessAccountId, setWhatsAppBusinessAccountId] = useState('');
  const [whatsAppPhoneNumberId, setWhatsAppPhoneNumberId] = useState('');
  const [tokenCode, setTokenCode] = useState();
  const [whatAppUserDetails, setWhatAppUserDetails] = useState({});
  const [userDetails, setUserDetails] = useState();

  const [number, setNumber] = useState();
  const defaultValues = {
    firstName: '',
    phone_number: '',
    mail: '',
  };

  const cleanAndCountDots = (formate) => {
    if (!formate) return { cleaned: '', dotCount: 0 };
    const cleaned = formate.replace(/[\s\-+]/g, '');
    const dotCount = (cleaned.match(/\./g) || []).length;
    return { cleaned, dotCount };
  };

  useEffect(() => {
    window.fbAsyncInit = function fbAsyncInit() {
      FB.init({
        appId: appKey,
        xfbml: true,
        version: 'v20.0',
      });
    };
    (function loadSDK(d, s, id) {
      let js = d.getElementById(id);
      if (js) return;
      const fjs = d.getElementsByTagName(s)[0];
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, [appKey]);

  useEffect(() => {
    const sessionInfoListener = async (event) => {
      if (!event.origin.includes('facebook.com')) return;
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          if (data.event === 'FINISH') {
            const { phone_number_id: phoneNumberId, waba_id: wabaId } = data.data;
            await setWhatsAppBusinessAccountId(wabaId);
            await setWhatsAppPhoneNumberId(phoneNumberId);
            console.log('Finished Signup', phoneNumberId, wabaId);
          } else {
            const { current_step: currentStep } = data.data;
            console.log('Current Step', currentStep);
          }
        }
      } catch {
        console.log('Non JSON Response', event.data);
      }
    };

    window.addEventListener('message', sessionInfoListener);
    return () => {
      window.removeEventListener('message', sessionInfoListener);
    };
  }, []);

  useEffect(() => {
    const saveUserDetails = async () => {
      try {
        if (!userDetails && whatsAppPhoneNumberId) {
          const payload = {
            query: wabaQuery.saveWhatAppUserDetails,
            variables: {
              whatsAppBusinessAccountId,
              whatsAppPhoneNumberId,
              tokenCode,
              quickReviewBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
            },
          };
          const res = await handleWabaApiRequest(payload);

          if (res?.saveWhatAppUserDetails?.status === 200) {
            setWhatAppUserDetails(res?.saveWhatAppUserDetails);
          }
        }
      } catch (error) {
        setPageLoading(false);
        console.error('An error occurred:', error);
      }
    };
    saveUserDetails();
  }, [
    whatsAppBusinessAccountId,
    whatsAppPhoneNumberId,
    tokenCode,
    userDetails,
    userCurrentBusinessDetails?.id,
  ]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (userCurrentBusinessDetails?.id) {
        setPageLoading(true);
        try {
          const payload = {
            query: wabaQuery.getUserDetail,
            variables: {
              quickReviewBusinessId: userCurrentBusinessDetails?.id,
            },
          };
          const res = await handleWabaApiRequest(payload);
          setPageLoading(false);
          if (res?.getUserDetail?.status === 200) {
            if (res?.getUserDetail?.data) {
              const decryptedData = JSON.parse(getDecryptData(res.getUserDetail.data));
              setUserDetails(decryptedData);
              setWhatsAppPhoneNumberId(decryptedData?.whatsAppPhoneNumberId);
            }
          }
        } catch (error) {
          setPageLoading(false);
          console.error('An error occurred:', error);
        }
      }
    };
    fetchUserDetails();
  }, [whatAppUserDetails, userCurrentBusinessDetails?.id]);

  const launchWhatsAppSignup = () => {
    FB.login(
      function handleFBLogin(response) {
        if (response.authResponse) {
          const { code } = response.authResponse;
          console.log('CODE', code);
          setTokenCode(code);
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      {
        config_id: configId,
        response_type: 'code',
        override_default_response_type: true,
        extras: {
          feature: 'whatsapp_embedded_signup',
          sessionInfoVersion: 2,
        },
      }
    );
  };

  const getWaUserProfileDetail = useCallback(async () => {
    try {
      const payload = {
        query: wabaQuery.getWaUserProfileDetail,
        variables: {
          quickReviewBusinessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const res = await handleWabaApiRequest(payload);
      if (res?.getWaUserProfileDetail?.status === 200) {
        setAccountDetail(res?.getWaUserProfileDetail?.data);
        setBusinessDescription(res?.getWaUserProfileDetail?.data?.description);
        setBusinessSector(res?.getWaUserProfileDetail?.data?.businessSector);
        setBusinessAddress(res?.getWaUserProfileDetail?.data?.address);
        setBusinessEmail(res?.getWaUserProfileDetail?.data?.email);
        setBusinessWebSite(res?.getWaUserProfileDetail?.data?.websites);
        setSelectedImage(res?.getWaUserProfileDetail?.data?.profileUrl);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    getWaUserProfileDetail();
  }, [getWaUserProfileDetail]);

  useEffect(() => {
    const getWaBusinessSectors = async () => {
      setPageLoading(true);
      try {
        const payload = {
          query: wabaQuery.getWaBusinessSectors,
        };
        const res = await handleWabaApiRequest(payload);
        if (res?.getWaBusinessSectors?.status === 200) {
          setSector(res?.getWaBusinessSectors?.data);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
      setPageLoading(false);
    };
    getWaBusinessSectors();
  }, []);

  const updateWaUserProfileDetail = async () => {
    setRefresh(true);
    try {
      const payload = {
        query: wabaQuery.updateWaUserProfileDetail,
        variables: {
          quickReviewBusinessId: Number(userCurrentBusinessDetails?.id),
          description: businessDescription,
          address: businessAddress,
          website: businessWebSite,
          businessSector,
          email: businessEmail,
          image: profileImage,
          removeProfile: false,
        },
      };
      const res = await wabaImageSendToServer(payload);
      if (res?.updateWaUserProfileDetail?.status === 200) {
        getWaUserProfileDetail();
        setBusinessDescription('');
        setBusinessSector('');
        setBusinessAddress('');
        setBusinessEmail('');
        setBusinessWebSite('');
        setProfileImage();
        setIsProfileEdit(false);
      } else {
        enqueueSnackbar(res?.updateWaUserProfileDetail?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
    setRefresh(false);
  };

  const getWaGreenTikStatus = useCallback(async () => {
    setPageLoading(true);
    try {
      const payload = {
        query: wabaQuery.getWaGreenTikStatus,
        variables: {
          quickReviewBusinessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const res = await handleWabaApiRequest(payload);
      if (res?.getWaGreenTikStatus?.status === 200) {
        setGreenTickStatus(res?.getWaGreenTikStatus?.data);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
    setPageLoading(false);
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    getWaGreenTikStatus();
  }, [getWaGreenTikStatus]);

  const updateWaGreenTikStatus = async () => {
    try {
      const payload = {
        query: wabaQuery.updateWaGreenTikStatus,
        variables: {
          quickReviewBusinessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const res = await handleWabaApiRequest(payload);
    } catch (error) {
      console.error('An error occurred while updating:', error);
    }
  };

  const generateWhatsappLink = async () => {
    try {
      const payload = {
        query: wabaQuery.generateWhatsappLink,
        variables: {
          countryCode: `+${phone?.dialCode}`,
          phoneNumber: Number(phone?.phoneNumber),
          message: customWabaLinkMessage,
          businessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const res = await handleWabaApiRequest(payload);
      if (res?.generateWhatsappLink?.status === 200) {
        setLinkDrawer(false);
        setIsORPopUp(true);
        setCustomWabaLinkMessage('');
        setQRValue(res?.generateWhatsappLink?.data);
      }
    } catch (error) {
      console.error('An error occurred while updating:', error);
    }
  };

  const handleButtonClick = () => {
    fileInputRefProfile.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfileImage(file);
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  const handleSectorChange = (event) => {
    setBusinessSector(event.target.value);
  };

  const handleGreenTickClick = () => {
    if (greenTickForm) {
      window.open(greenTickForm, '_blank');
      updateWaGreenTikStatus();
    }
  };

  const schema = yup.object().shape({
    phone_number: yup.string().required(t('signInPage.enterPhoneNumber')),
    mail: yup.string().email(t('common.enterAValidEmail')).trim(),
  });

  const { formState, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const handleWAPhone = (event, dataTwo) => {
    setPhone({
      ...dataTwo,
      phoneNumber: Number(event.slice(dataTwo?.dialCode?.length)),
    });
    setValue('phone_number', event.slice(dataTwo?.dialCode?.length), {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const downloadQRCode = () => {
    const canvas = document.querySelector('canvas');
    const pngUrl = canvas.toDataURL('image/png');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'QRCode.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(qrLink).then(() => {
      enqueueSnackbar('Link copied to clipboard!', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    });
  };

  if (pageLoading) {
    return <FuseLoading />;
  }

  return (
    <>
      <div className="p-20 lg:p-28">
        <div className="sm:flex sm:justify-between mb-24">
          <Typography className="text-28 font-bold">{t('waba.dashboard.title')}</Typography>
          <div className="sm:flex sm:gap-20 sm:items-center sm:justify-center">
            <div className="text-18 font-semibold mb-10">
              {t('waba.dashboard.status')}
              <span
                className={`${
                  userDetails?.status === 'PENDING'
                    ? 'text-red'
                    : userDetails?.status === 'APPROVED'
                    ? 'text-green'
                    : 'text-red'
                }`}
              >
                {userDetails?.status === 'PENDING'
                  ? 'PENDING'
                  : userDetails?.status === 'APPROVED'
                  ? 'LIVE'
                  : 'PENDING'}
              </span>
            </div>
            {!userDetails?.status && (
              <Button
                color="quickReview"
                variant="contained"
                className="rounded-md"
                size="small"
                onClick={launchWhatsAppSignup}
              >
                {t('quickChat.apply')}
              </Button>
            )}
          </div>
        </div>
        <div className="sm:flex max-w-full gap-24">
          <div className="sm:w-2/3">
            <Typography className="text-20 font-medium mb-16">
              {t('waba.dashboard.whatsAppBusinessDashboard')}
            </Typography>
            <div className="bg-white rounded-md p-20 mb-16">
              <div className="flex justify-between items-center">
                <div className="flex justify-center items-center gap-24">
                  <div className="relative">
                    <WhatsAppIcon className="bg-[#2DDC4F] text-white rounded-full p-2 h-40 w-40" />
                    {userDetails?.status === 'APPROVED' && (
                      <DoneRoundedIcon className="bg-quick-review text-white rounded-full p-2 h-20 w-20 absolute -right-6 -bottom-12 transform -translate-y-1/2" />
                    )}
                  </div>
                  <Typography className="text-20 font-semibold">
                    {t('waba.dashboard.applyForWhatsAppBusinessAPI')}
                  </Typography>
                </div>
                <ExpandMoreRoundedIcon
                  className={`h-40 w-40 cursor-pointer transition-transform ${
                    isOpenWhatsApp ? 'rotate-180' : 'rotate-0'
                  }`}
                  onClick={() => setIsOpenWhatsApp((prev) => !prev)}
                />
              </div>
              <Collapse in={isOpenWhatsApp}>
                <div className="flex justify-between items-center py-20">
                  <div className="max-w-512">
                    <Typography className="text-16 font-medium mb-48">
                      {t('waba.dashboard.applyForWhatsAppBusinessAPIContent')}
                    </Typography>
                    <div className="flex items-center gap-24">
                      {/* <Button
                        variant="outlined"
                        className="rounded-md"
                        size="medium"
                        color="quickChat"
                      >
                        {t('waba.dashboard.migrateFromVendor')}
                      </Button> */}
                      <Button
                        variant={
                          userDetails?.status === 'PENDING' || !userDetails?.status
                            ? 'contained'
                            : 'outlined'
                        }
                        className={`rounded-md disabled:bg-quick-chat-200 disabled:text-white ${
                          userDetails?.status === 'APPROVED'
                            ? 'pointer-events-none bg-quick-review-50 border-none'
                            : ''
                        }`}
                        size="medium"
                        disabled={userDetails?.status === 'PENDING'}
                        color={
                          userDetails?.status === 'PENDING' || !userDetails?.status
                            ? 'quickChat'
                            : 'quickReview'
                        }
                        onClick={() => {
                          if (!userDetails?.status) {
                            launchWhatsAppSignup();
                          }
                        }}
                      >
                        {!userDetails?.status
                          ? t('waba.dashboard.applyForWhatsAppAPI')
                          : userDetails?.status === 'PENDING'
                          ? t('waba.dashboard.applied')
                          : t('waba.dashboard.live')}
                      </Button>
                    </div>
                  </div>
                  <div>
                    <img src="assets/images/icon/whatsAppAPI.png" alt="" className="w-288 h-160" />
                  </div>
                </div>
                {userDetails?.status && (
                  <>
                    <div className="border-t-2 border-gray-700 py-16">
                      <div className="flex items-center justify-between mb-16">
                        <div className="flex items-center gap-24">
                          <FacebookRoundedIcon className="h-40 w-40 text-blue-500" />
                          <Typography className="text-20 font-semibold">
                            {t('waba.dashboard.facebookManagerVerification')}
                          </Typography>
                        </div>
                        <Button
                          variant={userDetails?.status === 'PENDING' ? 'contained' : 'outlined'}
                          className={`rounded-md text-semibold ${
                            userDetails?.status === 'APPROVED'
                              ? 'pointer-events-none bg-quick-review-50 border-none'
                              : ''
                          }`}
                          color={userDetails?.status === 'PENDING' ? 'error' : 'quickReview'}
                          size="medium"
                        >
                          {userDetails?.status === 'PENDING'
                            ? t('waba.dashboard.pendingReview')
                            : t('waba.dashboard.verified')}
                        </Button>
                      </div>
                      <div>
                        <li className="text-16 font-medium mb-12">
                          {t('waba.dashboard.facebookManagerVerificationPointOne')}
                        </li>
                        <li className="text-16 font-medium mb-12">
                          {t('waba.dashboard.facebookManagerVerificationPointTwo')}
                        </li>
                        <li className="text-16 font-medium">
                          {t('waba.dashboard.facebookManagerVerificationPointThree')}
                        </li>
                      </div>
                    </div>

                    <div className="border-t-2 border-gray-700 py-16">
                      <div className="flex items-center justify-between mb-16">
                        <div className="flex items-center gap-24">
                          <WhatsAppIcon className="bg-quick-chat text-white rounded-full p-2 h-32 w-32" />
                          <Typography className="text-20 font-semibold">
                            {t('waba.dashboard.phoneAndDisplayNameVerification')}
                          </Typography>
                        </div>
                        <Button
                          variant={
                            !userDetails?.businessVerificationStatus ? 'contained' : 'outlined'
                          }
                          className={`rounded-md ${
                            userDetails?.businessVerificationStatus
                              ? 'pointer-events-none bg-quick-review-50 border-none'
                              : ''
                          }`}
                          color={
                            userDetails?.businessVerificationStatus ? 'quickReview' : 'quickChat'
                          }
                          size="medium"
                        >
                          {userDetails?.businessVerificationStatus
                            ? t('waba.dashboard.verified')
                            : t('waba.dashboard.notVerified')}
                        </Button>
                      </div>
                      <div>
                        <li className="text-16 font-medium mb-12">
                          {t('waba.dashboard.phoneAndDisplayNameVerificationPointOne')}
                        </li>
                        <li className="text-16 font-medium mb-12">
                          {t('waba.dashboard.phoneAndDisplayNameVerificationPointTwo')}
                        </li>
                        <li className="text-16 font-medium">
                          {t('waba.dashboard.facebookManagerVerificationPointThree')}
                        </li>
                      </div>
                    </div>
                  </>
                )}
              </Collapse>
            </div>
            <div className="bg-white rounded-md p-20 mb-16 ">
              <div className="flex justify-between items-center">
                <div className="flex justify-center items-center gap-24">
                  <div className="relative">
                    <PersonRoundedIcon className="bg-quick-chat text-white rounded-full p-2 h-40 w-40" />
                    {accountDetail && (
                      <DoneRoundedIcon className="bg-quick-review text-white rounded-full p-2 h-20 w-20 absolute -right-6 -bottom-12 transform -translate-y-1/2" />
                    )}
                  </div>
                  <Typography className="text-20 font-semibold">
                    {t('waba.dashboard.setYourProfile')}
                  </Typography>
                </div>
                <ExpandMoreRoundedIcon
                  className={`h-40 w-40 cursor-pointer transition-transform ${
                    isOpenProfileUpdate ? 'rotate-180' : 'rotate-0'
                  }`}
                  onClick={() => setIsOpenProfileUpdate((prev) => !prev)}
                />
              </div>
              <Collapse in={isOpenProfileUpdate}>
                <div className=" flex justify-between items-center pt-20">
                  <div className="max-w-512">
                    <Typography className="text-16 font-medium mb-48">
                      {t('waba.dashboard.setYourProfileContent')}
                    </Typography>
                    <div className="flex items-center gap-24">
                      {/* <Button
                        variant="outlined"
                        className="rounded-md"
                        size="medium"
                        color="quickChat"
                      >
                        {t('waba.dashboard.migrateFromVendor')}
                      </Button> */}
                      <Button
                        variant="contained"
                        className="rounded-md"
                        size="medium"
                        disabled={userDetails?.status === 'PENDING' || !userDetails?.status}
                        onClick={() => setIsProfileEdit(true)}
                        color="quickChat"
                      >
                        {!accountDetail ? t('waba.dashboard.setProfile') : t('common.editProfile')}
                      </Button>
                    </div>
                  </div>
                  <div>
                    <img
                      src="assets/images/icon/whatsAppProfile.png"
                      alt=""
                      className="w-288 h-160"
                    />
                  </div>
                </div>
              </Collapse>
            </div>
            <div className="bg-white rounded-md p-20 mb-16 ">
              <div className="flex justify-between items-center">
                <div className="flex justify-center items-center gap-24">
                  <div className="relative">
                    {/* <DoneRoundedIcon className="bg-[#2DDC4F] text-white rounded-full p-2 h-40 w-40" /> */}
                    <img src="assets/images/icon/blue_tick.png" alt="" className="h-40 w-40" />

                    {greenTickStatus?.status === 'APPROVED' && (
                      <DoneRoundedIcon className="bg-quick-review text-white rounded-full p-2 h-20 w-20 absolute -right-6 -bottom-12 transform -translate-y-1/2" />
                    )}
                  </div>
                  <Typography className="text-20 font-semibold">
                    {t('waba.dashboard.applyForGreenTick')}
                  </Typography>
                </div>
                <ExpandMoreRoundedIcon
                  className={`h-40 w-40 cursor-pointer transition-transform ${
                    isOpenApplyGreenTick ? 'rotate-180' : 'rotate-0'
                  }`}
                  onClick={() => setIsOpenApplyGreenTick((prev) => !prev)}
                />
              </div>
              <Collapse in={isOpenApplyGreenTick}>
                <div className="flex justify-between items-center pt-20">
                  <div className="max-w-512">
                    <Typography className="text-16 font-medium mb-48">
                      {t('waba.dashboard.applyForGreenTickContent')}
                    </Typography>
                    <div className="flex items-center gap-24">
                      {/* <Button
                        variant="outlined"
                        className="rounded-md"
                        size="medium"
                        color="quickChat"
                      >
                        {t('waba.dashboard.migrateFromVendor')}
                      </Button> */}
                      <Button
                        variant={!greenTickStatus?.status ? 'contained' : 'outlined'}
                        className={`rounded-md ${
                          greenTickStatus?.status
                            ? 'pointer-events-none bg-quick-review-50 border-none'
                            : ''
                        }`}
                        size="medium"
                        color={greenTickStatus?.status ? 'quickReview' : 'quickChat'}
                        disabled={userDetails?.status === 'PENDING' || !userDetails?.status}
                        onClick={() => {
                          if (!greenTickStatus) {
                            handleGreenTickClick();
                          }
                        }}
                      >
                        {!greenTickStatus
                          ? t('quickChat.apply')
                          : greenTickStatus?.status === 'PENDING'
                          ? t('waba.dashboard.applied')
                          : t('waba.dashboard.verified')}
                      </Button>
                    </div>
                  </div>
                  <div>
                    <img src="assets/images/icon/greenTick.png" alt="" className="w-288 h-160" />
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
          <div className="sm:w-1/3">
            {accountDetail && (
              <div>
                <div className="bg-white p-16 max-w-512 mb-20">
                  <div className="flex justify-between ">
                    <div className="flex mb-12 gap-14">
                      {accountDetail?.profileUrl ? (
                        <img src={accountDetail?.profileUrl} alt="" className="h-60 w-60" />
                      ) : (
                        <div className="h-60 w-60 flex justify-center items-center bg-gray">
                          <Typography>
                            {accountDetail?.verifiedName
                              ? accountDetail.verifiedName.charAt(0).toUpperCase() +
                                accountDetail.verifiedName.slice(1)
                              : ''}
                          </Typography>
                        </div>
                      )}
                      <div>
                        <Typography className="text-20 font-semibold">
                          {accountDetail?.verifiedName || 'N/A'}
                        </Typography>
                        <Typography className="text-16 font-medium text-gray-700">
                          {accountDetail?.businessSector || 'N/A'}
                        </Typography>
                      </div>
                    </div>
                    <div className="flex items-start">
                      <button
                        type="button"
                        onClick={() => setIsProfileEdit(true)}
                        className="p-0 bg-transparent border-none"
                        aria-label="Edit Profile"
                      >
                        <img
                          src="assets/images/icon/editIcon.png"
                          alt="Edit Profile"
                          className="h-32 w-32"
                        />
                      </button>
                    </div>
                  </div>
                  <div className="flex mb-12">
                    <Typography className="text-16 font-medium text-gray-800">
                      {t('quickCampaignsContacts.contactNumber')} :
                    </Typography>
                    <Typography className="text-16 font-medium ">
                      {accountDetail?.displayPhoneNumber || 'No Contact Number'}
                    </Typography>
                  </div>
                  {accountDetail?.displayPhoneNumber && (
                    <div className=" mb-24">
                      <Typography className="text-16 font-medium text-gray-800">
                        Contact Link :{' '}
                      </Typography>
                      <div className="flex gap-8 items-center">
                        <Typography className="text-16 font-medium ">
                          wa.quickchat.com/{accountDetail?.displayPhoneNumber}
                        </Typography>

                        <ContentCopyIcon
                          onClick={() => copyToClipboard(accountDetail?.displayPhoneNumber)}
                          className="text-grey flex justify-center items-baseline h-16 w-16"
                        />
                      </div>
                    </div>
                  )}

                  <Button
                    className=" text-14 font-semibold rounded-md bg-gray-A500 flex gap-24"
                    onClick={() => setIsShowProfile((prev) => !prev)}
                  >
                    View Profile
                    <ExpandMoreRoundedIcon
                      className={`h-32 w-32 cursor-pointer transition-transform rounded-md ${
                        isShowProfile ? 'rotate-180' : 'rotate-0'
                      }`}
                    />
                  </Button>
                  <Collapse in={isShowProfile}>
                    {' '}
                    <div className="mb-20 mt-16">
                      <Typography className="text-16 font-medium text-gray-700 mb-8">
                        Description
                      </Typography>
                      <Typography className="text-16 font-medium ">
                        {accountDetail?.description || 'No Description'}
                      </Typography>
                    </div>
                    <div className="mb-20">
                      <Typography className="text-16 font-medium text-gray-700 mb-8">
                        Address{' '}
                      </Typography>
                      <Typography className="text-16 font-medium ">
                        {accountDetail?.address || 'No Address'}
                      </Typography>
                    </div>
                    <div className="mb-20">
                      <Typography className="text-16 font-medium text-gray-700 mb-8">
                        Email{' '}
                      </Typography>
                      <Typography className="text-16 font-medium ">
                        {accountDetail?.email || 'No Email'}
                      </Typography>
                    </div>
                    <div className="mb-20">
                      <Typography className="text-16 font-medium text-gray-700 mb-8">
                        Websites{' '}
                      </Typography>
                      <Typography className="text-16 font-medium ">
                        {accountDetail?.websites || 'No Website'}
                      </Typography>
                    </div>{' '}
                  </Collapse>

                  {/* <div className="mb-20">
                    <Typography className="text-16 font-medium text-gray-700 mb-8">
                      Description
                    </Typography>
                    <Typography className="text-16 font-medium ">
                      {accountDetail?.description || 'No Description'}
                    </Typography>
                  </div>
                  <div className="mb-20">
                    <Typography className="text-16 font-medium text-gray-700 mb-8">
                      Address{' '}
                    </Typography>
                    <Typography className="text-16 font-medium ">
                      {accountDetail?.address || 'No Address'}
                    </Typography>
                  </div>
                  <div className="mb-20">
                    <Typography className="text-16 font-medium text-gray-700 mb-8">
                      Email{' '}
                    </Typography>
                    <Typography className="text-16 font-medium ">
                      {accountDetail?.email || 'No Email'}
                    </Typography>
                  </div>
                  <div className="mb-20">
                    <Typography className="text-16 font-medium text-gray-700 mb-8">
                      Websites{' '}
                    </Typography>
                    <Typography className="text-16 font-medium ">
                      {accountDetail?.websites || 'No Website'}
                    </Typography>
                  </div> */}
                </div>
              </div>
            )}
            <div className="bg-white p-16 rounded-md">
              <WhatsAppIcon className="bg-[#2DDC4F] text-white rounded-full p-2 h-32 w-32 mb-16" />
              <Typography className="text-20 font-semibold">
                {t('waba.dashboard.customizeWhatsAppLink')}
              </Typography>
              <Typography className="text-16 font-medium text-gray-800 mb-20">
                {t('waba.dashboard.customizeWhatsAppLinkContent')}
              </Typography>
              <Button
                className="rounded-md"
                variant="contained"
                color="quickChat"
                size="small"
                onClick={() => setLinkDrawer(true)}
              >
                {t('waba.dashboard.createNow')}
              </Button>
            </div>
          </div>
        </div>
        {/* <div className="bg-white shadow rounded-md p-14 md:p-28 mb-28">
          <Typography className="text-20 sm:text-24 font-semibold mb-12 mt-0">
            {t('waba.dashboard.welcome')}
          </Typography>
          <Typography className="text-16 sm:text-20 font-medium text-grey-600 mb-24">
            {t('waba.dashboard.welcomeDesc')}
          </Typography>
          <ul>
            <li className="text-14 sm:text-18 sm:gap-0 gap-10 font-semibold text-black border-2 border-solid rounded-md md:p-20 p-10 md:mb-20 mb-10 flex items-center justify-between">
              <span>
                <img
                  src="/assets/images/business/waba-dash-1.png"
                  className="img-fluid w-full max-w-20 sm:max-w-28 mr-12 inline-block align-middle"
                  alt="..."
                />{' '}
                Apply for WhatsApp Business API
              </span>{' '}
              <Button
                type="text"
                variant="contained"
                className="w-76 sm:w-96 text-12 sm:text-14 font-semibold rounded-md"
                size="small"
                color="quickChat"
              >
                Apply
              </Button>
            </li>
            <li className="text-14 sm:text-18 font-semibold text-black border-2 border-solid rounded-md md:p-20 p-10 md:mb-20 mb-10 flex items-center justify-between">
              <span>
                <img
                  src="/assets/images/business/waba-dash-2.png"
                  className="img-fluid w-full max-w-20 sm:max-w-28 mr-12 inline-block align-middle"
                  alt="..."
                />{' '}
                Phone & Display Name Verification
              </span>
              <Button
                type="text"
                variant={userDetails?.status === 'PENDING' ? 'outlined' : 'contained'}
                className="w-76 sm:w-96 text-12 sm:text-14 font-semibold rounded-md"
                size="small"
                color="quickChat"
              >
                {userDetails?.status === 'PENDING' ? 'Pending' : 'Approved'}
              </Button>
            </li>
            <li className="text-14 sm:text-18 font-semibold text-black border-2 border-solid rounded-md md:p-20 p-10 md:mb-20 mb-10 flex items-center justify-between">
              <span>
                <img
                  src="/assets/images/business/waba-dash-3.png"
                  className="img-fluid w-full max-w-20 sm:max-w-28 mr-12 inline-block align-middle"
                  alt="..."
                />{' '}
                Facebook Manager Verification
              </span>
              <Button
                type="text"
                variant={userDetails?.status === 'PENDING' ? 'outlined' : 'contained'}
                className="w-76 sm:w-96 text-12 sm:text-14 font-semibold rounded-md"
                size="small"
                color="quickChat"
              >
                {userDetails?.status === 'PENDING' ? 'Pending' : 'Verified'}
              </Button>
            </li>
            <li className="text-18 font-semibold text-black border-2 border-solid rounded-md md:p-20 p-10 md:mb-20 mb-10 flex items-center">
              <img
                src="/assets/images/business/waba-dash-4.png"
                className="img-fluid w-full max-w-28 mr-12"
                alt="..."
              />{' '}
              Setup Your Profile
            </li>
            <li className="text-18 font-semibold text-black border-2 border-solid rounded-md md:p-20 p-10 flex items-center">
              <img
                src="/assets/images/business/waba-dash-5.png"
                className="img-fluid w-full max-w-28 mr-12"
                alt="..."
              />{' '}
              Apply for Green Tick
            </li>
          </ul>
        </div> */}
        {/* <div className="bg-white shadow rounded-md p-20 md:p-28 mb-28">
          <div className="flex items-center justify-between">
            <Typography className="text-24 font-semibold mb-12 mt-0">
              {t('waba.dashboard.conversationCredit')}
            </Typography>
            <span className="semi-bold text-24 font-bold">45/1000 Used</span>
          </div>
          <div className="block w-full mb-24">
            <Slider
              aria-label="Temperature"
              className="block pb-0"
              defaultValue={30}
              value={50}
              // onChange={valueText}
              size="large"
              min={1}
              max={1000}
              color="secondary"
              sx={{
                height: 12,
                '& .MuiSlider-thumb': {
                  backgroundColor: 'secondary.main',
                  '&:last-of-type': {
                    borderRadius: 0,
                    width: 2,
                    height: 24,
                  },
                },
              }}
            />
          </div>
          <div className="bg-grey-100 rounded-lg w-full px-20 py-16 mb-28">
            <span className="font-bold text-24">$ 4591</span>
          </div>
          <div className="bg-grey-100 rounded-lg w-full px-20 py-16">
            <h4 className="font-semibold text-18 mb-12">{t('waba.dashboard.enterAmount')}</h4>
            <Typography className="text-18 font-medium text-grey-600 mb-24">
              {t('waba.dashboard.minimum')}
            </Typography>
            <Input
              placeholder={t('appPromotionSlider.search')}
              className="flex flex-1 mx-8 text-black text-16 bg-white"
              disableUnderline
              name="searchText"
              fullWidth
              // onChange={handleSearchEvent}
              // value={searchValue}
              inputProps={{
                'aria-label': 'Search',
              }}
            />
          </div>
        </div> */}
        {isProfileEdit && (
          <Drawer
            anchor="right"
            open={isProfileEdit}
            onClose={() => setIsProfileEdit(false)}
            className=" "
            sx={{
              '& .MuiDrawer-paper': {
                maxWidth: '565px',
              },
            }}
          >
            <div className="flex justify-between items-center ">
              <Typography className="text-20 font-semibold px-20 py-16">
                {t('common.editProfile')}
              </Typography>
              <Button
                onClick={() => {
                  setIsProfileEdit(false);
                  setBusinessDescription('');
                  setBusinessSector('');
                  setBusinessAddress('');
                  setBusinessEmail('');
                  setBusinessWebSite('');
                  setProfileImage();
                  getWaUserProfileDetail();
                }}
                className="bg-white hover:bg-white"
              >
                <CloseIcon />
              </Button>
            </div>
            <div className="p-20">
              <div className="border-2 border-black border-solid p-20 rounded-md mb-20">
                <Typography className="text-16 font-semibold mb-16">
                  {t('waba.setting.profilePicture')}
                </Typography>
                <div className=" mb-16 flex gap-16 justify-center items-center">
                  {selectedImage ? (
                    <img src={selectedImage} alt="" className="rounded-full h-144 w-144" />
                  ) : (
                    <div className="h-144 w-144 rounded-full bg-gray-400"> </div>
                  )}
                  <div>
                    <Typography className="text-12 font-normal">
                      {t('waba.setting.profileUploadContent1')}
                    </Typography>
                    <Typography className="text-12 font-normal">
                      {t('waba.setting.profileUploadContent2')}
                    </Typography>
                    <Typography className="text-12 font-normal">
                      {t('waba.setting.profileUploadContent3')}
                    </Typography>
                  </div>
                </div>
                <div className="gap-12 flex">
                  <Button
                    variant="contained"
                    className="rounded-md"
                    color="quickChat"
                    size="medium"
                    onClick={() => handleButtonClick()}
                  >
                    {t('common.change')}
                  </Button>
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRefProfile}
                    className="hidden"
                    onChange={handleFileChange}
                  />
                  {/* <Button variant="outlined" className="rounded-md" color="quickChat" size="medium">
                  Remove
                </Button> */}
                </div>
              </div>

              <div className="mb-20">
                <Typography className="text-16 font-medium mb-12">
                  {t(`waba.setting.descriptionOfBusiness`)}
                </Typography>
                <TextField
                  placeholder="Enter A Description..."
                  value={businessDescription}
                  onChange={(e) => setBusinessDescription(e.target.value)}
                  multiline
                  rows={7}
                  className="w-full quick-chat"
                />
              </div>
              <div className="mb-20 quick-chat">
                <Typography className="text-16 font-medium mb-12">
                  {t(`waba.setting.businessSector`)}
                </Typography>
                <FormControl fullWidth>
                  <Select value={businessSector} onChange={handleSectorChange}>
                    {sector?.map((sectors) => (
                      <MenuItem key={sectors.id} value={sectors.name}>
                        {sectors.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="mb-20">
                <Typography className="text-16 font-medium mb-12">
                  {t(`waba.setting.officeAddress`)}
                </Typography>
                <TextField
                  placeholder="Enter office address..."
                  value={businessAddress}
                  onChange={(e) => setBusinessAddress(e.target.value)}
                  multiline
                  maxRows={3}
                  className="w-full quick-chat"
                />
              </div>
              <div className="mb-20">
                <Typography className="text-16 font-medium mb-12">{t('common.email')}</Typography>
                <TextField
                  placeholder="enteryouremail@domain.com"
                  value={businessEmail}
                  onChange={(e) => setBusinessEmail(e.target.value)}
                  multiline
                  maxRows={3}
                  className="w-full quick-chat"
                />
              </div>
              <div className="mb-20">
                <Typography className="text-16 font-medium mb-12">
                  {t('listManagement.website')}
                </Typography>
                <TextField
                  placeholder="www.mywebsite.com"
                  value={businessWebSite}
                  onChange={(e) => setBusinessWebSite(e.target.value)}
                  multiline
                  maxRows={3}
                  className="w-full quick-chat"
                />
              </div>
              <div className="flex justify-end items-center">
                <div className="flex gap-12">
                  <Button
                    variant="outlined"
                    color="quickChat"
                    className="rounded-md"
                    size="medium"
                    disabled={refresh}
                    onClick={() => {
                      setBusinessDescription('');
                      setBusinessSector('');
                      setBusinessAddress('');
                      setBusinessEmail('');
                      setBusinessWebSite('');
                      setProfileImage();
                      setIsProfileEdit(false);
                      getWaUserProfileDetail();
                    }}
                  >
                    {t('common.cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    color="quickChat"
                    className="rounded-md"
                    size="medium"
                    disabled={
                      refresh ||
                      !businessDescription ||
                      !businessSector ||
                      !businessAddress ||
                      !businessEmail ||
                      !businessWebSite
                    }
                    onClick={() => updateWaUserProfileDetail()}
                  >
                    {t('common.save')}
                    {refresh && (
                      <CircularProgress size={24} className="text-quick-chat absolute mx-auto" />
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </Drawer>
        )}

        {linkDrawer && (
          <Drawer
            anchor="right"
            open={linkDrawer}
            onClose={() => {
              setQRValue([]);
              setLinkDrawer(false);
            }}
            className="max-w-786"
            sx={{
              '& .MuiDrawer-paper': {
                maxWidth: '1080px',
              },
            }}
          >
            <div className="flex justify-between items-center border-b-2 border-grey-400">
              <Typography className="text-20 font-semibold px-20 py-16">
                {t('waba.dashboard.customizeWhatsAppLink')}
              </Typography>
              <Button
                onClick={() => {
                  setQRValue([]);
                  setLinkDrawer(false);
                }}
                className="bg-white hover:bg-white"
              >
                <CloseIcon />
              </Button>
            </div>
            <div className="bg-gray-A500 p-20 h-full">
              <Typography className="text-16 font-medium mb-12">
                {t('waba.dashboard.whatsAppContactNumber')}
              </Typography>
              <PhoneInput
                placeholder={t('common.phoneNumber')}
                inputStyle={{
                  width: '100%',
                  height: '37px',
                }}
                className="w-full bg-white mb-24"
                name="phone_number"
                required
                error={!!errors.phone_number}
                helperText={errors?.phone_number?.message}
                size="small"
                country={loginUserData?.isoCode?.toLowerCase()}
                enableSearch="true"
                onChange={(value, dataOne) => {
                  handleWAPhone(value, dataOne);
                  const validPhoneNumber = handlePhoneNumberValidationCheck(value, dataOne);
                }}
                InputProps={{
                  endAdornment: phone && (
                    <InputAdornment position="end">
                      <DeleteOutlineIcon
                        className="text-20 text-black cursor-pointer "
                        onClick={() => setPhone([])}
                      />
                    </InputAdornment>
                  ),
                }}
              />

              <Typography className="text-16 font-medium text-gray mb-12">
                {t('waba.dashboard.customMessage')}
              </Typography>
              <TextField
                placeholder="Hello, I am interested to know more about your business..."
                multiline
                value={customWabaLinkMessage}
                onChange={(e) => setCustomWabaLinkMessage(e.target.value)}
                rows={5}
                fullWidth
                className="bg-white mb-12 quick-chat"
              />
              <Button
                className="rounded-md"
                color="quickChat"
                size="medium"
                variant="contained"
                disabled={
                  !customWabaLinkMessage ||
                  cleanAndCountDots(phone?.format)?.dotCount !==
                    (phone?.dialCode?.length || 0) + (String(phone?.phoneNumber)?.length || 0)
                }
                onClick={() => generateWhatsappLink()}
              >
                {t('waba.dashboard.createLink')}
              </Button>
            </div>
          </Drawer>
        )}

        {qrValue?.uniqueId && (
          <Dialog
            open={isORPopUp}
            onClose={() => {
              setCustomWabaLinkMessage('');
              setIsORPopUp(false);
            }}
            classes={{
              paper: `m-24 business-type-dropdown rounded-md`,
            }}
            PaperProps={{ style: { maxWidth: '574px', maxHeight: '646px' } }}
          >
            <DialogContent className="py-32 px-24">
              <div className="right-3 top-3 absolute">
                <IconButton className="" onClick={() => setIsORPopUp(false)} color="inherit">
                  <CloseIcon className="w-32 h-32 text-grey-700" />
                </IconButton>
              </div>
              <Typography className="text-28 font-bold mb-14">
                {t('waba.dashboard.qrTitle')}
              </Typography>
              <Typography className="text-16 font-medium mb-24">
                {t('waba.dashboard.qrPopUpContent')}
              </Typography>
              <div className="mb-44">
                <div className="bg-gray-A500 flex justify-center items-center py-4 px-9 gap-16">
                  <WhatsAppIcon className="bg-[#2DDC4F] text-white rounded-full h-28 w-28" />
                  {qrValue?.uniqueId && (
                    <Typography className="text-18 font-semibold">{qrLink}</Typography>
                  )}
                </div>
              </div>
              <div className="flex justify-center items-center mb-24">
                {qrValue?.uniqueId && <QRCodeCanvas value={qrLink} size={250} />}
              </div>
              <div className="gap-10 flex justify-center items-center">
                <Button
                  className="rounded-md"
                  variant="outlined"
                  color="quickReview"
                  size="medium"
                  onClick={() => downloadQRCode()}
                >
                  Download QR
                </Button>
                <Button
                  className="rounded-md"
                  variant="contained"
                  color="quickReview"
                  size="medium"
                  onClick={() => copyToClipboard()}
                >
                  Copy Link
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        )}
      </div>
    </>
  );
};

export default WabaDashBoard;
