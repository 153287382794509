import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import { json, useParams } from 'react-router-dom';
import InputBase from '@mui/material/InputBase';
import Avatar from '@mui/material/Avatar';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import './QuickChatEditor.css';
import { publicIpv4 } from 'public-ip';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import { Button, Icon, TextField, InputLabel } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import userService from 'src/app/services';
import { connectAssistanceSocket } from '../../../../socket';
import quickChatQuery from '../../query/quickChat';
import queryData from '../../query/common';
import {
  formatDateWithTimeZone,
  handleApiRequest,
  handlePhoneNumberValidationCheck,
  getEncryptedData,
  getDecryptData,
} from '../../common/common';

function ChatBot({ setOpenChatPopup }) {
  const loginUserData = userService.getUserData();
  const assistanceSocket = connectAssistanceSocket();
  const chatContainerRef = useRef(null);
  const chatScroll = useRef(null);
  const [timeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [messageText, setMessageText] = useState('');
  const [messages, setMessages] = useState([]);
  const [isBotTyping, setIsBotTyping] = useState(true);
  const [displayMenu, setDisplayMenu] = useState(false);
  const [isConnected, setIsConnected] = useState(assistanceSocket.connected);
  const currentTime = moment().tz(timeZone).toISOString();
  const [ipAddress, setIpAddress] = useState(null);
  const [cachedIp, setCachedIp] = useState(null);
  const [subscribeChannelByClientUserStatus, setSubscribeChannelByClientUserStatus] =
    useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [prevPage, setPrevPage] = useState(0);
  const [wasLastList, setWasLastList] = useState(false);
  const [showLeadForm, setShowLeadForm] = useState(false);
  const [phone, setPhone] = useState();
  const [phoneValue, setPhoneValue] = useState();
  const [customerName, setCustomerName] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [errorMessage, setErrorMessage] = useState(true);
  const [currentCountry, setCurrentCountry] = useState();
  const { source, userId } = useParams();
  const finalUserId = source === 'app' && userId ? Number(userId) : Number(loginUserData?.id);

  const tags = ['Connect with sales', 'Start free trial', 'Schedule Demo'];

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const ip = await publicIpv4();
        setIpAddress(ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchIp();
  }, []);

  useEffect(() => {
    if (!loginUserData) {
      const ipAddressDataValue = publicIpv4();
      const ipAddressData = async () => {
        const payload = {
          query: queryData.getLocation,
          variables: {
            data: getEncryptedData(`{"ipAddress": "${await ipAddressDataValue}"}`),
          },
        };
        const locationResponse = await handleApiRequest(payload);
        if (locationResponse?.getLocation?.data) {
          const locationData = locationResponse?.getLocation?.data;

          if (locationData) {
            setCurrentCountry(JSON.parse(getDecryptData(locationData)).isoCode.toLowerCase());
          }
        }
      };
      ipAddressData();
    }
  }, [currentCountry, loginUserData]);

  useEffect(() => {
    if (chatScroll.current) {
      chatScroll.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, enqueueSnackbar]);

  const initialSetData = useCallback(
    (data) => {
      if (data?.length === 0) {
        setTimeout(() => {
          setMessages([
            {
              message:
                'Hi My name is QuickHub Bot. I’m here to help you get started. What would you like to do today?',
              messageBy: 'BOT',
              time: moment().tz(timeZone).toISOString(),
            },
          ]);
          setIsBotTyping(false);
          setDisplayMenu(true);
        }, 2000);
      } else {
        setMessages(data);
        setIsBotTyping(false);
        setDisplayMenu(true);
      }
    },
    [timeZone]
  );

  useEffect(() => {
    const fetchIp = async () => {
      const ip = await ipAddress;
      setCachedIp(ip);
    };

    if (!cachedIp) {
      fetchIp();
    }
  }, [cachedIp, ipAddress]);

  const fetchAgentSupportStatus = useCallback(async () => {
    try {
      const payloadData = {
        query: quickChatQuery.getUserHelperBotConversation,
        variables: {
          sortBy: 'cts',
          sortOrder: 'desc',
          pageNumber: currPage,
          pageSize: 20,
        },
      };
      if (finalUserId) {
        payloadData.variables.userId = finalUserId;
      }
      const resultData = await handleApiRequest(payloadData);

      if (
        resultData?.getUserHelperBotConversation?.status === 200 ||
        resultData?.getUserHelperBotConversation?.status === 404
      ) {
        setHasMore(true);
        if (resultData?.getUserHelperBotConversation?.data?.length === 0) {
          setWasLastList(true);
          setHasMore(false);
          if (currPage === 1) {
            initialSetData([]);
          }
          return;
        }
        setPrevPage(currPage);
        setMessages([
          ...(resultData?.getUserHelperBotConversation?.data.reverse() || []),
          ...messages,
        ]);
        if (currPage === 1) {
          initialSetData(resultData?.getUserHelperBotConversation?.data);
        }

        const previousScrollHeight = chatContainerRef.current?.scrollHeight;
        setTimeout(() => {
          const newScrollHeight = chatContainerRef.current?.scrollHeight;
          chatContainerRef.current.scrollTop += newScrollHeight - previousScrollHeight;
        }, 0);
      }
    } catch (error) {
      console('An error occurred while fetching data', error);
    }
  }, [currPage, messages, initialSetData, finalUserId]);

  useEffect(() => {
    if (!wasLastList && prevPage !== currPage) {
      fetchAgentSupportStatus();
    }
  }, [currPage, wasLastList, prevPage, fetchAgentSupportStatus, hasMore]);

  useEffect(() => {
    if (!assistanceSocket || assistanceSocket.connected) {
      return;
    }
    const handleSubscribeChatData = async () => {
      if (!assistanceSocket || !assistanceSocket.connected) {
        console.log('Socket is not connected');
        return;
      }

      try {
        assistanceSocket.emit('eventFromHelperChatBot', {
          eventType: 'subscribeUserForQuickHubHelperBot',
          data: { ...(finalUserId && { userId: finalUserId }), ipAddress },
        });

        assistanceSocket.emit('eventFromHelperChatBot', {
          eventType: 'beginHelperBotCommunication',
          data: {
            ...(finalUserId && { userId: finalUserId }),
            ipAddress,
            message:
              'Hi My name is QuickHub Bot. I’m here to help you get started. What would you like to do today?',
          },
        });
      } catch (error) {
        console.error('Error fetching IP address or connecting to chat:', error);
        setIsConnected(false);
      }
    };

    handleSubscribeChatData();
  }, [assistanceSocket, ipAddress, isConnected, finalUserId]);

  useEffect(() => {
    const handleMessage = async (message) => {
      if (message?.eventType === 'nextReplyByBot') {
        if (message?.data?.message) {
          const newMessage = {
            message: message?.data?.message,
            messageBy: 'BOT',
            cts: currentTime,
          };
          setMessages((prevMessages) => [...prevMessages, newMessage]);
          setIsBotTyping(false);
          if (message?.data?.other?.instruction === 'render_lead_form') {
            setShowLeadForm(true);
          }
          const previousScrollHeight = chatContainerRef.current?.scrollHeight;
          setTimeout(() => {
            const newScrollHeight = chatContainerRef.current?.scrollHeight;
            chatContainerRef.current.scrollTop += newScrollHeight - previousScrollHeight;
          }, 0);
        }
      }
    };

    const handleConnect = async () => {
      try {
        const ipAddressValue = await ipAddress;
        assistanceSocket.emit('eventFromHelperChatBot', {
          eventType: 'subscribeUserForQuickHubHelperBot',
          data: { ...(finalUserId && { userId: finalUserId }), ipAddressValue },
        });
        console.log('Socket connected from event');
        setSubscribeChannelByClientUserStatus(false);
        setIsConnected(true);
      } catch (error) {
        console.error('Error connecting to chat:', error);
        setIsConnected(false);
      }
    };

    const handleDisconnect = () => {
      setIsConnected(false);
    };

    // Attach event listeners
    assistanceSocket.on('connect', handleConnect);
    assistanceSocket.on('disconnect', handleDisconnect);
    assistanceSocket.on('eventFromHelperChatBot', handleMessage);

    // Cleanup function to remove the event listeners when component unmounts or re-renders
    return () => {
      assistanceSocket.off('connect', handleConnect);
      assistanceSocket.off('disconnect', handleDisconnect);
      assistanceSocket.off('eventFromHelperChatBot', handleMessage);
    };
  }, [assistanceSocket, currentTime, subscribeChannelByClientUserStatus, ipAddress, finalUserId]);

  const sendMessage = async (e) => {
    const message = messageText || e;

    if (message?.trim()) {
      const ipAddressValue = await ipAddress;
      assistanceSocket.emit('eventFromHelperChatBot', {
        eventType: 'usersReply',
        data: {
          ...(finalUserId && { userId: finalUserId }),
          ipAddress: ipAddressValue,
          message,
        },
      }); // for send message
      const newMessages = [...messages, { message, cts: currentTime }];
      setMessages(newMessages);
      setIsBotTyping(true);
      setMessageText('');
      setTimeout(() => {
        if (chatContainerRef.current) {
          chatContainerRef.current.scrollTo({
            top: chatContainerRef.current.scrollHeight,
            behavior: 'smooth',
          });
        }
      }, 0);
    }
    setShowLeadForm(false);
  };

  const onMessageSubmit = async (e) => {
    e.preventDefault();
    sendMessage();
    setDisplayMenu(false);
  };

  const handleTagClick = async (tag) => {
    setMessageText(tag);
    sendMessage(tag);
    setDisplayMenu(false);
  };

  const handleSubmit = async () => {
    try {
      const ipAddressValue = await ipAddress;
      const leadMessage = `name: ${customerName}, phoneNumber: ${phone?.phoneNumber}`;

      const leadMessageDisplay = `
      <div>
        Name: ${customerName}<br/>
        Contact: + ${phone?.dialCode} ${phone?.phoneNumber}
      </div>
    `;

      assistanceSocket.emit('eventFromHelperChatBot', {
        eventType: 'usersReply',
        data: {
          ...(finalUserId && { userId: finalUserId }),
          ipAddress: ipAddressValue,
          message: leadMessage,
          other: {
            leadData: {
              name: customerName,
              countryCode: phone?.dialCode,
              phoneNumber: phone?.phoneNumber,
              ipAddress: ipAddressValue,
            },
          },
        },
      });

      setMessages([
        ...messages,
        { message: leadMessageDisplay, time: moment().tz(timeZone).toISOString() },
      ]);
      setShowLeadForm(false);
    } catch (error) {
      console.error('Error connecting to chat:', error);
    }
  };

  const groupMessagesByDate = (messagesData) => {
    return messagesData.reduce((groups, message) => {
      const date = formatDateWithTimeZone(message?.time || message?.cts, 'YYYY-MM-DD');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});
  };

  const onScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = chatContainerRef.current;
      if (scrollTop === 0) {
        setHasMore(false);
        setCurrPage((prevPageNumber) => prevPageNumber + 1);
      }
    }
  };

  const handlePhoneChange = (event, data, value, formattedValue) => {
    setPhoneValue(event);
    setPhone({
      ...data,
      phoneNumber: parseInt(event.slice(data?.dialCode?.length), 10),
    });
  };

  const closeWindow = () => {
    if (source === 'app') {
      window.open('about:blank', '_self');
      window.close();
    } else {
      setOpenChatPopup(false);
    }
  };

  return (
    <>
      <div className="relative h-full">
        <AppBar className="shadow sticky">
          <Toolbar className="px-20 py-14 bg-quick-hub rounded-tl-md rounded-tr-md">
            <div className="flex flex-1 items-center px-12">
              <img src="/assets/images/quick-hub/quick-hub.png" className="w-28 h-28" alt="" />
              <span className="bg-quick-hub w-8 h-8 mt-20 -ml-8 border border-solid border-white rounded-full" />
              <Typography className="mx-16 text-white text-16 sm:text-20 font-600" color="inherit">
                {t('assistance.title')}
              </Typography>
            </div>
            <div className="flex px-4">
              <IconButton
                className="px-0 py-0"
                onClick={() => closeWindow()}
                color="inherit"
                size="large"
              >
                <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>

        <div className="max-h-480 overflow-auto">
          <div
            className="flex-grow overflow-auto p-20 pb-10"
            onScroll={onScroll}
            ref={chatContainerRef}
          >
            <div className="block max-w-[1050px] mx-auto">
              {messages.map((msg, i) => (
                <div
                  key={i}
                  className={`relative mx-auto ${
                    msg?.messageBy === 'BOT' ? 'text-left' : `text-right`
                  }`}
                >
                  {msg.messageBy === 'BOT' && (
                    <div className="float-left mr-6 bg-darkgreen-100 w-40 h-40 flex justify-center items-center relative rounded-full overflow-hidden">
                      <img
                        className="w-full h-full object-cover"
                        name="logo"
                        src="/assets/images/quick-hub/quick-hub.png"
                        alt="bell notification icon"
                      />
                    </div>
                  )}
                  <div>
                    <div className="sm:max-w-288 max-w-288 inline-block mb-14">
                      {msg.messageBy === 'BOT' && (
                        <span className="text-12 font-medium mb-6 block tracking-wide">
                          {t('assistance.title')}
                        </span>
                      )}
                      <div
                        className={`transition ease-in-out delay-150 md:p-16 p-10 my-5 mx-auto rounded-md message text-14 font-medium ${
                          msg?.messageBy === 'BOT'
                            ? 'bg-gray-A500 text-left'
                            : `bg-quick-hub text-left text-white`
                        } `}
                        dangerouslySetInnerHTML={{
                          __html: msg?.message,
                        }}
                      />
                      <Typography
                        className="time w-full text-12 font-medium text-grey-700 mt-8 whitespace-nowrap"
                        color="text.secondary"
                      >
                        {formatDateWithTimeZone(msg?.cts, 'hh:mm A')}
                      </Typography>
                    </div>
                  </div>
                </div>
              ))}

              {showLeadForm && (
                <div className="relative mx-auto text-left mt-10">
                  <div className="float-left mr-6 bg-darkgreen-100 w-40 h-40 flex justify-center items-center relative rounded-full overflow-hidden">
                    <img
                      className="w-full h-full object-cover"
                      name="logo"
                      src="/assets/images/quick-hub/quick-hub.png"
                      alt="bell notification icon"
                    />
                  </div>
                  <div className="max-w-288 w-full inline-block bg-gray-A500 rounded-md p-10">
                    <div className="block mb-10">
                      <TextField
                        className="w-full bg-white"
                        value={customerName}
                        size="small"
                        onChange={(e) => setCustomerName(e.target.value)}
                        sx={{
                          '& .MuiInputBase-input': {
                            fontWeight: '600',
                            lineHeight: '22px',
                            fontSize: 14,
                          },
                        }}
                        placeholder={t('assistance.enterName')}
                      />
                    </div>
                    <div className="block mb-10">
                      <PhoneInput
                        placeholder={t('common.phoneNumber')}
                        inputStyle={{
                          width: '100%',
                          height: '37px',
                        }}
                        className="w-full"
                        name="phone_number"
                        required
                        value={phoneValue}
                        size="small"
                        country={loginUserData?.isoCode?.toLowerCase() || currentCountry}
                        enableSearch="true"
                        onChange={(value, data) => {
                          handlePhoneChange(value, data);
                          const validPhoneNumber = handlePhoneNumberValidationCheck(value, data);
                          setErrorMessage(validPhoneNumber);
                        }}
                      />
                      {errorMessage === false && phoneValue?.length > 0 && (
                        <p className="text-red mt-5 text-16">
                          {t('productSettings.InvalidPhoneNumber')}
                        </p>
                      )}
                    </div>
                    <div className="text-right">
                      <Button
                        className="rounded-md min-h-32 h-32 min-w-112"
                        onClick={() => handleSubmit()}
                        variant="contained"
                        color="secondary"
                        disabled={!customerName || !phoneValue || !errorMessage}
                      >
                        {t('agencyBusiness.clients.form.submit')}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {displayMenu && (
              <div className="tags block max-w-[1050px] relative mx-auto text-left mt-8 pl-[43px]">
                {tags?.map((tag, i) => (
                  <div
                    key={i}
                    className="tag bg-white font-medium text-14 text-black border-1 border-solid border-darkgreen rounded-md px-10 py-6 m-2 cursor-pointer inline-block"
                    aria-hidden="true"
                    onClick={() => handleTagClick(tag)}
                  >
                    {tag}
                  </div>
                ))}
              </div>
            )}

            {isBotTyping && (
              <div className="block max-w-[1050px] relative mx-auto text-left">
                <Avatar src="" className="float-left mr-6 w-40 h-40 bg-darkgreen-100 md:p-10 p-5" />
                <div className="max-w-480 inline-block">
                  <div className="py-16 my-5 mx-auto rounded-2xl message md:text-16 font-semibold bg-white text-left rounded-tl-none">
                    <div className="typing-indicator">
                      <div className="dot bg-quick-hub" />
                      <div className="dot bg-quick-hub" />
                      <div className="dot bg-quick-hub" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div ref={chatScroll} className="h-20" />
        </div>

        <form
          onSubmit={onMessageSubmit}
          className="bg-white bottom-0 absolute left-0 right-0 mx-auto max-w-[1050px] pt-24"
        >
          <Paper className="rounded-md flex items-center relative shadow-none border-t border-solid border-grey-400">
            <InputBase
              autoFocus={false}
              id="message-input"
              size="small"
              className="flex flex-1 grow shrink-0 px-16 h-60 text-black"
              placeholder={t('waba.inbox.typeMessage')}
              onChange={(e) => {
                setMessageText(e.target.value);
              }}
              value={messageText}
            />
            <IconButton
              className="absolute right-10 top-8 bg-quick-hub rounded-full w-44 h-44 hover:bg-black"
              type="submit"
              size="medium"
            >
              <FuseSvgIcon className="rotate-45 w-24 h-24" color="white">
                heroicons-outline:paper-airplane
              </FuseSvgIcon>
            </IconButton>
          </Paper>
        </form>
      </div>
    </>
  );
}

export default ChatBot;
