import { Button, CircularProgress, Dialog, DialogContent, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import history from '@history';
import { t } from 'i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import quickSocialQuery from '../../query/quickSocial';
import { getDecryptData, getEncryptedData, handleApiRequest } from '../../common/common';
import UserDetails from '../../admin/users/UserDetails';

const TwitterConnect = (props) => {
  const {
    fetchAgentSupportStatus,
    setIsTwitterConnect,
    setTwitterRefreshIcon,
    oauthToken,
    oauthVerifier,
    userBusinessId,
    callFrom,
  } = props;
  const [twitterUserDetails, srtTwitterUserDetails] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const redirectTwitter = async () => {
      const payload = {
        query: quickSocialQuery.getTwitterRedirectUrl,
      };
      const result = await handleApiRequest(payload);
      if (result?.getTwitterRedirectUrl?.status === 200) {
        history.push(result.getTwitterRedirectUrl?.data?.redirectUrl);
      }
    };
    if (!oauthToken && !oauthVerifier) {
      redirectTwitter();
    }
  }, [oauthToken, oauthVerifier]);

  useEffect(() => {
    const authenticateTwitterUserDetails = async () => {
      const payload = {
        query: quickSocialQuery.authenticateAndGetTwitterUserDetails,
        variables: {
          oauthToken,
          oauthVerifier,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.authenticateAndGetTwitterUserDetails?.status === 200) {
        srtTwitterUserDetails(
          JSON.parse(getDecryptData(result.authenticateAndGetTwitterUserDetails?.data))
        );
        setOpenDialog(true);
      }
    };
    if (oauthToken && oauthVerifier) {
      authenticateTwitterUserDetails();
    }
  }, [oauthToken, oauthVerifier]);

  const handleConnectTwitterAccount = async () => {
    try {
      const payload = {
        query: quickSocialQuery.connectTwitterAccount,
        variables: {
          data: getEncryptedData(JSON.stringify(twitterUserDetails)),
          userBusinessId: Number(userBusinessId),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.connectTwitterAccount?.status === 200) {
        fetchAgentSupportStatus();
        handleClosePopUp();
        enqueueSnackbar(result?.connectTwitterAccount.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        handleClosePopUp();
        enqueueSnackbar(result?.connectTwitterAccount.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      handleClosePopUp();
      enqueueSnackbar('Failed to connect Twitter account. Please try again.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      console.error('Error connecting Twitter account:', error);
    }
  };

  const handleClosePopUp = () => {
    setIsTwitterConnect(false);
    setTwitterRefreshIcon(false);
    if (callFrom === 'dashboard') {
      history.push('/quick-social/dashboard');
    } else if (callFrom === 'socialConnect') {
      history.push('/quick-social/social-connect/');
    }
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        aria-labelledby="delete-confirmation-dialog"
        classes={{
          paper: `m-24 business-type-dropdown text-center rounded-md`,
        }}
        PaperProps={{ style: { maxWidth: '470px', overflowY: 'auto', minWidth: '470px' } }}
      >
        <div className="flex items-center justify-between pl-16 pt-10">
          <Typography className="text-start font-bold text-16">
            {t('quickSocial.connectYourTwitterAccount')}
          </Typography>
          <Button className="hover:bg-white" onClick={() => handleClosePopUp()}>
            <CloseIcon />
          </Button>
        </div>
        <hr className="mt-16" />
        <DialogContent className="p-16">
          <div className="flex gap-24 mt-16 items-center border border-solid rounded-md p-10">
            <img
              className="w-84 h-84 rounded-full"
              src={twitterUserDetails?.userDetails?.profileImageUrl}
              alt=""
            />
            <div className="w-full text-start">
              <div className="">
                <Typography className="font-semibold">
                  {twitterUserDetails?.userDetails?.name}
                </Typography>
                <Typography>{twitterUserDetails?.userDetails?.screenName}</Typography>
              </div>
              <div className="flex items-center justify-between">
                <Typography>
                  {twitterUserDetails?.userDetails?.followersCount} {t('quickSocial.followers')}
                </Typography>
                <Button
                  className="p-0 border border-solid rounded-md px-16 py-10 h-32 min-h-32 bg-quick-social-100 font-semibold border-quick-social"
                  onClick={() => handleConnectTwitterAccount()}
                >
                  {t('quickSocial.connect')}
                  {refreshIcon && (
                    <CircularProgress className="text-quick-social absolute mx-auto" size={24} />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TwitterConnect;
