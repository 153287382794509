import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  RadioGroup,
  FormControl,
  Radio,
  FormControlLabel,
  Button,
  Icon,
} from '@mui/material';
import { useSelector } from 'react-redux';
import manageBusinessData from 'src/app/main/query/manageBusiness';
import wabaQuery from 'src/app/main/query/waba';
import history from '@history';
import {
  handleApiRequest,
  handleWabaApiRequest,
  getDecryptData,
  getParamsId,
  handlePhoneNumberValidationCheck,
} from 'src/app/main/common/common';
import * as yup from 'yup';
import FuseLoading from '@fuse/core/FuseLoading';
import { useForm } from 'react-hook-form';
import { t } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectDashboardData } from 'src/app/store/userSlice';
import Sms from './sms';
import Mail from './mail';
import UserService from '../../../../services/userService';
import CommonConfigureDrawer from './commonConfigureDrawer';

const defaultValues = {
  firstName: '',
  phone_number: '',
  mail: '',
};

const Configure = () => {
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(2);
  const [smsType, setSmsType] = useState(0);
  const [quickHubNumber, setQuickHubNumber] = useState({});
  const loginUserData = UserService.getUserData();
  const [providerList, setProviderList] = useState([]);
  const [userDetails, setUserDetails] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [phoneValue, setPhoneValue] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchQuickHubCredentialProvider = async () => {
      try {
        const payload = {
          query: manageBusinessData.getQuickHubDefaultCred,
          variables: {
            countryId: Number(loginUserData.countryId),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getQuickHubDefaultCred?.status === 200) {
          setQuickHubNumber(result?.getQuickHubDefaultCred?.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchQuickHubCredentialProvider();
  }, [loginUserData.countryId]);

  useEffect(() => {
    const fetchQuickHubCredentialProvider = async () => {
      try {
        const payload = {
          query: manageBusinessData.getQuickHubCredentialProvider,
          variables: {
            campaignPlatformId: Number(tabValue),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getQuickHubCredentialProvider?.status === 200) {
          setProviderList(result?.getQuickHubCredentialProvider?.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchQuickHubCredentialProvider();
  }, [tabValue]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (userCurrentBusinessDetails?.id) {
        setLoading(true);

        try {
          const payload = {
            query: wabaQuery.getUserDetail,
            variables: {
              quickReviewBusinessId: userCurrentBusinessDetails?.id,
            },
          };
          const res = await handleWabaApiRequest(payload);
          setLoading(false);
          if (res?.getUserDetail?.status === 200) {
            if (res?.getUserDetail?.data) {
              const decryptedData = JSON.parse(getDecryptData(res.getUserDetail.data));
              setUserDetails(decryptedData);
              setPhoneValue(decryptedData?.displayPhoneNumber);
            }
          }
        } catch (error) {
          setLoading(false);
          console.error('An error occurred:', error);
        }
      }
    };
    fetchUserDetails();
  }, [userCurrentBusinessDetails?.id]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="lg:p-24 p-16">
      <Typography className="font-bold md:text-28 text-20 lg:mb-20 mb-16">
        {t('navigation.configure')}
      </Typography>
      <div className="border-solid border-grey sm:flex justify-between items-center border-b-2 mb-32 overflow-x-auto">
        <Tabs
          value={tabValue}
          className="sm:overflow-hidden overflow-scroll quick-campaign"
          sx={{
            '& .MuiTabs-scroller': {
              overflow: { xs: 'scroll !important', sm: 'hidden !important' },
            },
          }}
          onChange={handleTabChange}
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab
            className={`sm:text-18 font-semibold mr-40 p-0 min-w-0 ${
              tabValue === 2 ? 'text-quick-campaign' : 'text-black'
            }`}
            value={2}
            label={t('dashboard.exploreFeatures.campaigns.sms')}
          />
          <Tab
            className={`sm:text-18 font-semibold mr-40 p-0 min-w-0 ${
              tabValue === 1 ? 'text-quick-campaign' : 'text-black'
            }`}
            value={1}
            label={t('common.email')}
          />
          <Tab
            className={`sm:text-18 font-semibold p-0 min-w-0 ${
              tabValue === 3 ? 'text-quick-campaign' : 'text-black'
            }`}
            value={3}
            label={t('dashboard.exploreFeatures.campaigns.whatsapp')}
          />
        </Tabs>
      </div>
      <div className="max-w-786">
        {tabValue === 2 && (
          <>
            <div className="bg-white rounded-md py-8 px-20">
              <FormControl>
                <RadioGroup
                  row
                  value={smsType}
                  onChange={(event) => setSmsType(Number(event.target.value))}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio sx={{ color: '#8B78DC', '&.Mui-checked': { color: '#8B78DC' } }} />
                      }
                      label={
                        <Typography className="text-20 font-medium">
                          {t('configure.quickHubNumber')}
                        </Typography>
                      }
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio sx={{ color: '#8B78DC', '&.Mui-checked': { color: '#8B78DC' } }} />
                      }
                      label={
                        <Typography className="text-20 font-medium">
                          {t('configure.yourNumber')}
                        </Typography>
                      }
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </div>
            <div className="bg-quick-campaign-100 rounded-md py-8 px-20 mt-10">
              <div className="flex items-center justify-between">
                <span className="text-16 font-semibold">
                  {t('configure.howToConnectYourServiceProvider')}
                </span>
                <Button
                  className="rounded md:px-40 mx-10 md:mx-0 text-16 font-medium"
                  variant="contained"
                  color="quickCampaign"
                  type="button"
                  onClick={() => setOpenDrawer(true)}
                >
                  {' '}
                  {t('common.learnMore')}
                </Button>
              </div>
            </div>
            <div className="bg-white rounded-md py-8 px-20 mt-10">
              <Typography className="text-16 text-grey-900 font-medium">
                {smsType === 0 && (
                  <>
                    <span>{t('configure.quickHubNumber')} : </span>
                    <span>
                      {' '}
                      {quickHubNumber?.sms?.find((item) => item.key === 'countryCode')?.value}{' '}
                      {quickHubNumber?.sms?.find((item) => item.key === 'phoneNumber')?.value}
                    </span>
                  </>
                )}
              </Typography>
              {smsType === 1 && <Sms providerList={providerList} />}
            </div>

            {smsType === 1 && (
              <Typography className="text-18 font-medium mt-12">
                <b> {t('common.note')} - </b>
                {t('configure.configureNote')}
              </Typography>
            )}
          </>
        )}

        {tabValue === 1 && (
          <>
            <div className="bg-white rounded-md py-8 px-20">
              <FormControl>
                <RadioGroup
                  row
                  value={smsType}
                  onChange={(event) => setSmsType(Number(event.target.value))}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio sx={{ color: '#8B78DC', '&.Mui-checked': { color: '#8B78DC' } }} />
                      }
                      label={
                        <Typography className="text-20 font-medium">
                          {t('configure.quickHubEmail')}
                        </Typography>
                      }
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio sx={{ color: '#8B78DC', '&.Mui-checked': { color: '#8B78DC' } }} />
                      }
                      label={
                        <Typography className="text-20 font-medium">
                          {t('configure.yourEmail')}
                        </Typography>
                      }
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </div>
            <div className="bg-quick-campaign-100 rounded-md py-8 px-20 mt-10">
              <div className="flex items-center justify-between">
                <span className="text-16 font-semibold">
                  {t('configure.howToConnectYourEmailServiceProvider')}
                </span>
                <Button
                  className="rounded md:px-40 mx-10 md:mx-0 text-16 font-medium"
                  variant="contained"
                  color="quickCampaign"
                  type="button"
                  onClick={() => setOpenDrawer(true)}
                >
                  {t('common.learnMore')}
                </Button>
              </div>
            </div>
            <div className="bg-white rounded-md py-8 px-20 mt-10">
              <Typography className="text-16 text-grey-900 font-medium">
                {smsType === 0 && (
                  <>
                    <span>{t('configure.quickHubEmail')} : </span>
                    <span>
                      {' '}
                      {quickHubNumber?.email?.find((item) => item.key === 'email')?.value}
                    </span>
                  </>
                )}
              </Typography>
              {smsType === 1 && <Mail providerList={providerList} />}
            </div>

            {smsType === 1 && (
              <Typography className="text-18 font-medium mt-12">
                <b> {t('common.note')} - </b>
                {t('configure.configureNoteEmail')}
              </Typography>
            )}
          </>
        )}
        {tabValue === 3 && (
          <>
            <div className="bg-white rounded-md py-8 px-20">
              <FormControl>
                <RadioGroup
                  row
                  value={smsType}
                  onChange={(event) => setSmsType(Number(event.target.value))}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio sx={{ color: '#8B78DC', '&.Mui-checked': { color: '#8B78DC' } }} />
                      }
                      label={
                        <Typography className="text-20 font-medium">
                          {t('configure.quickHubNumber')}
                        </Typography>
                      }
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio sx={{ color: '#8B78DC', '&.Mui-checked': { color: '#8B78DC' } }} />
                      }
                      label={
                        <Typography className="text-20 font-medium">
                          {t('configure.yourNumber')}
                        </Typography>
                      }
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </div>
            <div className="bg-white rounded-md py-8 px-20 mt-10">
              <Typography className="text-16 text-grey-900 font-medium">
                {smsType === 0 && (
                  <>
                    <span>{t('configure.quickHubWPNumber')} : </span>
                    <span>
                      {' '}
                      {
                        quickHubNumber?.whatsApp?.find((item) => item.key === 'countryCode')?.value
                      }{' '}
                      {quickHubNumber?.whatsApp?.find((item) => item.key === 'phoneNumber')?.value}
                    </span>
                  </>
                )}
              </Typography>
              {smsType === 1 && (
                <>
                  {userDetails?.waAccessToken ? (
                    <div>
                      <div className="flex items-center justify-between mb-16">
                        <div className="flex items-center">
                          <img
                            src={userCurrentBusinessDetails?.profileURL}
                            alt="img"
                            className="w-40 h-40 rounded-full mr-10"
                          />
                          <span className="text-16 font-semibold">
                            {userCurrentBusinessDetails?.name}
                          </span>
                        </div>

                        <Button
                          className="rounded-md w-full xs:w-auto text-12 min-w-120 h-28 min-h-28 hover:bg-quick-campaign cursor-auto"
                          variant="contained"
                          color="quickCampaign"
                        >
                          <Icon className="text-14 mr-5">check</Icon> {t('quickSocial.connected')}
                        </Button>
                      </div>
                      <div>
                        <p className="text-16 font-medium mb-12 ">
                          {t('configure.whatsappBusinessApiNumber')}
                        </p>
                        {userDetails?.displayPhoneNumber && (
                          <div className="cursor-default">
                            <PhoneInput
                              placeholder={t('common.phoneNumber')}
                              inputStyle={{
                                width: '100%',
                                height: '37px',
                                backgroundColor: '#f3f3f3',
                                cursor: 'default',
                              }}
                              className="w-full cursor-default"
                              name="phone_number"
                              value={phoneValue}
                              size="small"
                              country={loginUserData?.isoCode?.toLowerCase()}
                              enableSearch={false}
                              disabled
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="text-center">
                      <img
                        src="assets/images/icon/whatsapp.png"
                        alt="wp"
                        className="mb-20 mx-auto"
                      />
                      <h3 className="text-24 font-semibold">{t('configure.wpNotApply')}</h3>
                      <Typography className="text-20 font-medium text-grey-700 mt-16">
                        {t('configure.wpNotApplyDesc')}
                      </Typography>
                      <Button
                        onClick={() => {
                          const paramsId = getParamsId();
                          history.push({
                            pathname: `quick-chat/waba-home/${paramsId}`,
                          });
                        }}
                        className="rounded-md mt-24 w-full xs:w-auto text-14 xs:text-16 min-w-288 mb-16"
                        variant="contained"
                        color="quickCampaign"
                      >
                        {t('configure.applyNow')}
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
      <CommonConfigureDrawer
        tabValue={tabValue}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
      />
    </div>
  );
};

export default Configure;
