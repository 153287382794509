import withReducer from 'app/store/withReducer';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import CryptoJS from 'crypto-js';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import FuseLoading from '@fuse/core/FuseLoading';
import Collapse from '@mui/material/Collapse';
import { AnimatedCounter } from 'react-animated-counter';
import _ from '@lodash';
import Chip from '@mui/material/Chip';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import history from '@history';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PhoneInput from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { publicIpv4 } from 'public-ip';
import {
  getEncryptedData,
  getDecryptData,
  handleApiRequest,
  handlePhoneNumberValidationCheck,
} from '../common/common';
import UserProfile from '../common/userProfile';
import queryData from '../query/common';
import qrData from '../query/qrScanner';

import {
  getBusinessQRDetail,
  submitBusinessReview,
  addVisitorCountBasedOnIPAddress,
  getBusinessProductByBusinessId,
  getBusinessServicesByBusinessId,
  getAllRemainingCountV1,
  getBusinessAgentDetailsOnPortalSideUsingBusinessQRId,
  requestCallForNegativeReviews,
  getAmazonProductDetailsBaseOnId,
  getLocation,
  getFlipkartProductDetailsBaseOnId,
  resetqrScanner,
} from './store/qrScannerSlice';
import reducer from './store';

const useStyles = makeStyles((theme) => ({
  customCheckbox: {
    position: 'absolute',
    opacity: '0',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
  },
}));

/**
 * Form Validation Schema
 */
const schemaCall = yup.object().shape({
  name: yup.string().required('Please Enter Name'),
  phoneNumber: yup.string().required('Please Enter phoneNumber'),
});

const defaultValues = {
  review: '',
};

const defaultValuesCall = {
  name: '',
  phoneNumber: '',
  reason: '',
};

function QrScanner() {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const { t } = useTranslation();

  const getAgentList = useSelector(
    ({ qrScannerReducer }) =>
      qrScannerReducer?.qrScanner?.getBusinessAgentDetailsOnPortalSideUsingBusinessQRIdData?.data
        ?.getBusinessAgentDetailsOnPortalSideUsingBusinessQRId || {}
  );

  const businessProductValue = useSelector(
    ({ qrScannerReducer }) =>
      qrScannerReducer?.qrScanner?.getBusinessProductByBusinessIdValue?.data
        ?.getBusinessServicesAndProductByBusinessId
  );
  let getProductList = [];

  if (businessProductValue?.data) {
    getProductList = JSON.parse(getDecryptData(businessProductValue?.data));
  }

  const businessServicesValue = useSelector(
    ({ qrScannerReducer }) =>
      qrScannerReducer?.qrScanner?.getBusinessServicesByBusinessIdValue?.data
        ?.getBusinessServicesAndProductByBusinessId
  );

  let getServiceList = [];

  if (businessServicesValue?.data) {
    getServiceList = JSON.parse(getDecryptData(businessServicesValue?.data));
  }

  const requestCallForNegativeReviewsStatus = useSelector(
    ({ qrScannerReducer }) =>
      qrScannerReducer?.qrScanner?.requestCallForNegativeReviewsData?.data
        ?.requestCallForNegativeReviews
  );

  const submitStatus = useSelector(({ qrScannerReducer }) =>
    qrScannerReducer?.qrScanner?.submitBusinessReviewData?.data?.submitBusinessReview
      ? qrScannerReducer.qrScanner.submitBusinessReviewData.data.submitBusinessReview
      : {}
  );

  const { control, formState, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const {
    control: controlCall,
    watch: watchCall,
    reset: resetCall,
    handleSubmit: handleSubmitCall,
    formState: formStateCall,
    getValues: getValuesCall,
    setError: setErrorCall,
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValuesCall,
    resolver: yupResolver(schemaCall),
  });
  const { isValid: isValidCall, dirtyFields: dirtyFieldsCall, errors: errorsCall } = formStateCall;

  const faces = [
    {
      id: 1,
      value: 'bad',
    },
    { id: 2, value: 'okay' },
    { id: 3, value: 'good' },
  ];

  const thumbs = [
    {
      id: 1,
      value: 'no',
    },
    { id: 2, value: 'yes' },
  ];

  const [loading, setLoading] = useState(true);
  const [isActive, setIsActive] = useState('tab-0');
  const [isActiveClass, setIsActiveClass] = useState('');
  const [isActiveButton, setIsActiveButton] = useState('tabButton-0');
  const [ratingValue, setRatingValue] = useState([]);
  const [facesValue, setFacesValue] = useState([]);
  const [starValue, setStarValue] = useState([]);
  const [likeValue, setLikeValue] = useState([]);
  const [attributeNumber, setAttributeNumber] = useState(1);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const { isValid, dirtyFields, errors } = formState;
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const [reviewData, setReviewValue] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorMessages, setErrorMessages] = useState(true);
  const [textValue, setTextValue] = useState();
  const [rotateIcon, setRotateIcon] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentAttribute, setCurrentAttribute] = useState({});
  const [optionsWithReviewText, setOptionsWithReviewText] = useState([]);
  const [requestCallName, setRequestCallName] = useState();
  const [requestCallReason, setRequestCallReason] = useState();
  const [nextScreen, setNextScreen] = useState(false);
  const [nextButtonStatus, setNextButtonStatus] = useState(false);
  const [requestCallScreen, setRequestCallScreen] = useState(false);
  const [askCallScreen, setAskCallScreen] = useState(false);
  const [requestCallRefreshIcon, setRequestCallRefreshIcon] = useState(false);
  const [requestDone, setRequestDone] = useState(false);
  const [autoGenerateButton, setAutoGenerateButton] = useState(true);
  const [selectedAgentId, setSelectedAgentId] = useState(0);
  const [overAllRatingValue, setOverAllRatingValue] = useState(0);
  const [overAllRatingNext, setOverAllRatingNext] = useState(false);
  const [overAllRatingSuccess, setOverAllRatingNextSuccess] = useState(false);
  const [currentSelectedAttribute, setCurrentSelectedAttribute] = useState({});
  const [selectedProductServicesId, setSelectedProductServicesId] = useState([]);
  const [specialProduct, setSpecialProduct] = useState([]);
  const [specialServices, setSpecialServices] = useState([]);
  const [selectedTriedProduct, setSelectedTriedProduct] = useState([]);
  const [selectedTriedServices, setSelectedTriedServices] = useState([]);
  const [currentCountry, setCurrentCountry] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [autoGenerateRemainingCount, setAutoGenerateRemainingCount] = useState();
  const [negativeReviewRemainingCount, setNegativeReviewRemainingCount] = useState();
  const [reviewGenerateStatus, setReviewGenerateStatus] = useState(false);
  const [isAutogenerate, setIsAutogenerate] = useState(false);
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [reviewerName, setReviewerName] = useState();
  const [businessDetails, setBusinessDetails] = useState({});
  const [businessQRDetailsData, setBusinessQRDetailsData] = useState({});
  const [negativeReviewDetail, setNegativeReviewDetail] = useState(false);
  const [reviewIsTyped, setReviewIsTyped] = useState(false);
  const [liveStreaming, setLiveStreaming] = useState(false);
  const [attributesValueObj, setAttributesValueObj] = useState({});
  const [isSliding, setIsSliding] = useState(false);
  const [direction, setDirection] = useState('');
  const [agentData, setAgentData] = useState();
  const businessQRId = routeParams.id;
  const allAttributeData = [];
  const complexityMapping = {
    1: 'simple',
    2: 'moderate',
    3: 'advanced',
  };

  const langComplexity =
    complexityMapping[businessDetails?.autoGenerateReviewComplexityTypeId] || 'moderate';
  const SYMMETRIC_KEY_BASE64 = process.env.REACT_APP_SYMMETRIC_KEY_BASE;
  const IV_BASE64 = process.env.REACT_APP_IV_BASE;

  const symmetricKey = CryptoJS.enc.Hex.parse(
    CryptoJS.enc.Base64.parse(SYMMETRIC_KEY_BASE64).toString()
  );
  const iv = CryptoJS.enc.Hex.parse(CryptoJS.enc.Base64.parse(IV_BASE64).toString());

  allAttributeData.push(...facesValue, ...starValue, ...likeValue, ...ratingValue);

  useEffect(() => {
    const addUserProfile = getAgentList?.allAgent?.map((item) => {
      let randomColorValue;
      do {
        randomColorValue = Math.floor(Math.random() * 16777215)
          .toString(16)
          .padStart(6, '0');
      } while (randomColorValue.toUpperCase() === 'FFFFFF');

      return {
        ...item,
        userProfile: randomColorValue,
      };
    });

    setAgentData(addUserProfile);
  }, [getAgentList]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const trackingUniqueId = urlParams.get('method');
    if (trackingUniqueId) {
      const apiUrl = `${process.env.REACT_APP_TRACKING_ID}/click?trackingUniqueId=${trackingUniqueId}`;
      axios.get(apiUrl).catch((error) => {
        console.error('API call error:', error);
      });
    }
  }, []);

  useEffect(() => {
    if (!currentCountry) {
      const ipAddress = publicIpv4();
      const ipAddressData = async () => {
        const payload = {
          query: queryData.getLocation,
          variables: {
            data: getEncryptedData(`{"ipAddress": "${await ipAddress}"}`),
          },
        };
        const locationResponse = await dispatch(getLocation(payload));
        if (locationResponse?.payload?.data?.getLocation?.data) {
          const locationData = locationResponse?.payload?.data?.getLocation?.data;
          if (locationData) {
            setCurrentCountry(JSON.parse(getDecryptData(locationData)).isoCode.toLowerCase());
          }
        }
      };
      ipAddressData();
    }
  }, [dispatch, currentCountry]);

  useEffect(() => {
    const fetchData = async () => {
      if (businessDetails && Object.keys(businessDetails).length === 0) {
        setLoading(true);
        const qrPayload = {
          query: qrData.getBusinessQRDetail,
          variables: { businessQRId },
        };
        const qrResponseData = await dispatch(getBusinessQRDetail(qrPayload));
        setLoading(false);
        const responseStatus = qrResponseData?.payload?.data?.getBusinessQRDetail?.status;
        if (responseStatus === 404) {
          setBusinessDetails({ isScrap: false });
          setBusinessQRDetailsData(200);
        } else if (responseStatus === 200) {
          setBusinessDetails({
            ...qrResponseData?.payload?.data?.getBusinessQRDetail?.data,
            isScrap: false,
          });
          setBusinessQRDetailsData(responseStatus);
        }
        if (responseStatus === 404) {
          setErrorMessage(true);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, [businessDetails, dispatch, businessQRId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          businessDetails &&
          Object.keys(businessDetails).length > 0 &&
          parseInt(businessQRDetailsData, 10) === 200
        ) {
          if (businessDetails?.isAmazonProduct && !businessDetails.isScrap) {
            setOverAllRatingNext(true);
            const payload = {
              query: qrData.getAmazonProductDetailsBaseOnId,
              variables: {
                amazonProductId: businessDetails?.amazonProduct?.id,
              },
            };
            const amazonResponseData = await dispatch(getAmazonProductDetailsBaseOnId(payload));
            setBusinessDetails({
              ...amazonResponseData?.payload?.data?.getAmazonProductDetailsBaseOnId?.data,
              isScrap: true,
            });
          } else if (businessDetails?.isFlipkartProduct && !businessDetails.isScrap) {
            setOverAllRatingNext(true);
            const payload = {
              query: qrData.getFlipkartProductDetailsBaseOnId,
              variables: {
                flipkartProductId: businessDetails?.flipkartProduct?.id,
              },
            };
            const flipkartResponseData = await dispatch(getFlipkartProductDetailsBaseOnId(payload));
            setBusinessDetails({
              ...flipkartResponseData?.payload?.data?.getFlipkartProductDetailsBaseOnId?.data,
              isScrap: true,
            });
          } else if (businessDetails?.isOnlineBusiness) {
            setOverAllRatingNext(false);
          } else if (!businessDetails?.isOnlineBusiness) {
            setOverAllRatingNext(true);
          }

          if (businessDetails?.reviewModeTypeId === 2) {
            setOverAllRatingNext(true);
          }

          if (businessDetails?.id) {
            const payloadProduct = {
              query: qrData.getBusinessServicesAndProductByBusinessId,
              variables: {
                businessId: parseInt(businessDetails?.id, 10),
                type: 'product',
              },
            };
            dispatch(getBusinessProductByBusinessId(payloadProduct));

            const payloadDataService = {
              query: qrData.getBusinessServicesAndProductByBusinessId,
              variables: {
                businessId: parseInt(businessDetails?.id, 10),
                type: 'service',
              },
            };
            dispatch(getBusinessServicesByBusinessId(payloadDataService));

            const payloadDataAgent = {
              query: qrData.getBusinessAgentDetailsOnPortalSideUsingBusinessQRId,
              variables: {
                businessId: parseInt(businessDetails?.id, 10),
                businessQrId: businessQRId,
              },
            };
            dispatch(getBusinessAgentDetailsOnPortalSideUsingBusinessQRId(payloadDataAgent));
          }
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        // setLoading(false); // Set loading state to false when request completes
      }
    };

    fetchData();
  }, [businessQRId, dispatch, businessDetails, businessQRDetailsData]);

  useEffect(() => {
    if (businessDetails && Object.keys(businessDetails).length === 0) {
      if (businessDetails?.reviewModeTypeId === 2) {
        setOverAllRatingNext(true);
      }
    }
  }, [businessQRId, businessDetails]);

  useEffect(() => {
    if (
      (businessDetails?.reviewModeTypeId === 5 ||
        businessDetails?.isExpire ||
        autoGenerateRemainingCount <= 0) &&
      !businessDetails?.isOnlineBusiness
    ) {
      setLoading(true);
      history.push(businessDetails?.platFormURL);
    }
  }, [businessDetails, autoGenerateRemainingCount]);

  useEffect(() => {
    let unmounted = false;
    const fetchBusinessData = async () => {
      if (businessDetails?.id) {
        const payloadDataCount = {
          query: qrData.getAllRemainingCountV1,
          variables: {
            businessId: parseInt(businessDetails?.id, 10),
            userId: parseInt(businessDetails?.userId, 10),
          },
        };
        const result = await dispatch(getAllRemainingCountV1(payloadDataCount));

        if (result?.payload?.data?.getAllRemainingCountV1?.data?.autoGenerateReview) {
          const availableCount =
            result?.payload?.data?.getAllRemainingCountV1?.data?.autoGenerateReview
              ?.availableCount || 0;
          const useCount =
            result?.payload?.data?.getAllRemainingCountV1?.data?.autoGenerateReview?.useCount || 0;
          const remainingCount = parseInt(availableCount - useCount, 10);
          if (remainingCount > 0 || remainingCount > 0 || businessDetails?.isOnlineBusiness) {
            setLoading(false);
          }
          setAutoGenerateRemainingCount(remainingCount);
        }
        if (result?.payload?.data?.getAllRemainingCountV1?.data?.negativeReviewRemainingCount) {
          const availableCount =
            result?.payload?.data?.getAllRemainingCountV1?.data?.negativeReviewRemainingCount
              ?.availableCount || 0;
          const useCount =
            result?.payload?.data?.getAllRemainingCountV1?.data?.negativeReviewRemainingCount
              ?.useCount || 0;
          const remainingCount = parseInt(availableCount - useCount, 10);
          setNegativeReviewRemainingCount(remainingCount);
        }
      }
    };

    fetchBusinessData();
    return () => {
      unmounted = true;
    };
  }, [businessDetails?.id, dispatch, businessDetails?.userId, businessDetails?.isOnlineBusiness]);

  useEffect(() => {
    if (getAgentList?.defaultAgent?.length > 0) {
      setSelectedAgentId(getAgentList?.defaultAgent[0]?.id);
    }
  }, [getAgentList?.defaultAgent]);

  useEffect(() => {
    async function fetchIsReviewNegative() {
      if (submitStatus?.status === 200) {
        if (negativeReviewRemainingCount > 0 && businessDetails?.isNegativeReviewRedirect) {
          // if(businessDetails?.user_businesses_attribute_level_threshold)
          // if (negativeReviewDetail?.status === 200) {
          //   negativeReviewDetail?.isNegative === 'Positive'
          //     ? businessDetails?.isOnlineBusiness
          //       ? (setOverAllRatingNextSuccess(true), setAskCallScreen(true))
          //       : history.push(businessDetails?.platFormURL)
          //     : setAskCallScreen(true);
          // }

          !negativeReviewDetail
            ? businessDetails?.isOnlineBusiness
              ? (setOverAllRatingNextSuccess(true), setAskCallScreen(true))
              : history.push(businessDetails?.platFormURL)
            : setAskCallScreen(true);
        } else if (
          negativeReviewRemainingCount <= 0 ||
          !businessDetails?.isNegativeReviewRedirect
        ) {
          businessDetails?.isOnlineBusiness
            ? (setOverAllRatingNextSuccess(true), setAskCallScreen(true))
            : history.push(businessDetails?.platFormURL);
        }
      }
    }

    fetchIsReviewNegative();
  }, [
    submitStatus?.status,
    businessDetails?.platFormURL,
    businessDetails?.isAutoTextGenerate,
    businessDetails?.isOnlineBusiness,
    negativeReviewRemainingCount,
    negativeReviewDetail,
    businessDetails?.isNegativeReviewRedirect,
  ]);

  useEffect(() => {
    if (submitStatus?.status === 200) {
      setRefreshIcon(false);
      setPhoneValue('');
      reset(defaultValues);
      setRatingValue([]);
      setFacesValue([]);
      setStarValue([]);
      setLikeValue([]);
      if (
        (!businessDetails?.isNegativeReviewRedirect &&
          !businessDetails?.isAutoTextGenerate &&
          !businessDetails?.isOnlineBusiness) ||
        (!businessDetails?.isAutoTextGenerate && !businessDetails?.isOnlineBusiness)
      ) {
        history.push(businessDetails?.platFormURL);
        dispatch(resetqrScanner());
      } else if (!businessDetails?.isAutoTextGenerate && businessDetails?.isOnlineBusiness) {
        setOverAllRatingNextSuccess(true);
      }
    }
  }, [
    businessDetails?.platFormURL,
    reset,
    dispatch,
    submitStatus,
    businessDetails?.isNegativeReviewRedirect,
    businessDetails?.isAutoTextGenerate,
    businessDetails?.isOnlineBusiness,
  ]);

  useEffect(() => {
    if (requestCallForNegativeReviewsStatus?.status === 200 && negativeReviewRemainingCount > 0) {
      setRequestDone(true);
      setRequestCallRefreshIcon(false);
    } else if (requestCallForNegativeReviewsStatus?.status === 400) {
      enqueueSnackbar(requestCallForNegativeReviewsStatus?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
      setRequestCallRefreshIcon(false);
    }
  }, [
    requestCallForNegativeReviewsStatus,
    closeSnackbar,
    enqueueSnackbar,
    negativeReviewRemainingCount,
  ]);

  useEffect(() => {
    let filteredProducts;
    let specialProductsNames;
    if (businessProductValue?.data) {
      const productList = JSON.parse(getDecryptData(businessProductValue?.data));
      filteredProducts = productList?.filter((product) => product.specialty === true);
      specialProductsNames = filteredProducts.map((product) => product.name);
    }
    setSpecialProduct(specialProductsNames);
  }, [businessProductValue?.data]);

  useEffect(() => {
    let filteredServices;
    let specialServicesNames;
    if (businessServicesValue?.data) {
      const servicesList = JSON.parse(getDecryptData(businessServicesValue?.data));
      filteredServices = servicesList?.filter((product) => product.specialty === true);
      specialServicesNames = filteredServices.map((product) => product.name);
    }

    setSpecialServices(specialServicesNames);
  }, [businessServicesValue?.data]);

  useEffect(() => {
    let triedServices;
    let triedServicesNames;
    if (businessServicesValue?.data) {
      const servicesList = JSON.parse(getDecryptData(businessServicesValue?.data));
      triedServices = servicesList?.filter((product) =>
        selectedProductServicesId.includes(product.id)
      );
      triedServicesNames = triedServices.map((product) => product.name);
    }

    setSelectedTriedServices(triedServicesNames);
  }, [businessServicesValue?.data, selectedProductServicesId]);

  useEffect(() => {
    let triedProducts;
    let triedProductsNames;
    if (businessProductValue?.data) {
      const productList = JSON.parse(getDecryptData(businessProductValue?.data));
      triedProducts = productList?.filter((product) =>
        selectedProductServicesId.includes(product.id)
      );
      triedProductsNames = triedProducts.map((product) => product.name);
    }

    setSelectedTriedProduct(triedProductsNames);
  }, [businessProductValue?.data, selectedProductServicesId]);

  const openModal = () => {
    setIsOpenModel(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const closeModal = () => {
    setIsOpenModel(false);
    handleSkipAndContinue();
  };

  useEffect(() => {
    let timeoutId;
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    if (isOpenModel) {
      document.addEventListener('keydown', handleKeyDown);

      timeoutId = setTimeout(() => {
        closeModal();
      }, 12000);
    }
    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [closeModal, isOpenModel]);

  function onHandleReviews(e, item) {
    const {
      bAttribute: name,
      bAttributeId,
      isCustomAttribute,
      business_attribute_values: businessAttributeValues,
    } = item;
    const { type, id: typeId } = businessAttributeValues;
    setTextValue('');
    setCurrentSelectedAttribute({
      ...item,
      reviewType: type,
    });
    const reviewValue = parseInt(e.target.value, 10);
    let reviewsData = [];
    let setReviewData = [];
    switch (type) {
      case 'Stars':
        reviewsData = starValue;
        break;
      case 'Faces':
        reviewsData = facesValue;
        break;
      case 'Thumbs':
        reviewsData = likeValue;
        break;
      case 'NPS':
        reviewsData = ratingValue;
        break;
      default:
        break;
    }
    const appendData = {
      name,
      review: reviewValue,
      attributeId: parseInt(typeId, 10),
      bAttributeId: parseInt(bAttributeId, 10),
      reviewType: type,
      isCustomAttribute,
    };
    setCurrentAttribute(appendData);
    if (reviewsData.find((obj) => obj.name === name)) {
      const foundIndex = reviewsData.findIndex((x) => x.name === name);
      reviewsData[foundIndex] = appendData;
      setReviewData = [...reviewsData];
    } else {
      setReviewData = [...reviewsData, appendData];
    }
    switch (type) {
      case 'Stars':
        setStarValue(setReviewData);
        break;
      case 'Faces':
        setFacesValue(setReviewData);
        break;
      case 'Thumbs':
        setLikeValue(setReviewData);
        break;
      case 'NPS':
        setRatingValue(setReviewData);
        break;
      default:
        break;
    }
    if (businessDetails?.reviewModeTypeId === 3) {
      handleNextButtonIndex(setReviewData);
    }
  }
  if (loading) {
    return <FuseLoading />;
  }

  const handleChangeChip = (value) => {
    const updatedValues = [...selectedProductServicesId];
    if (updatedValues.includes(value)) {
      const index = updatedValues.indexOf(value);
      updatedValues.splice(index, 1);
    } else {
      updatedValues.push(value);
    }
    setSelectedProductServicesId(updatedValues);
  };

  const marks = [
    {
      value: 1,
      label: (
        <img src="/assets/images/icon/rating-1.svg" width="30px" height="30px" alt="rating-5" />
      ),
    },
    {
      value: 2,
      label: (
        <img src="/assets/images/icon/rating-2.svg" width="30px" height="30px" alt="rating-5" />
      ),
    },
    {
      value: 3,
      label: (
        <img src="/assets/images/icon/rating-3.svg" width="30px" height="30px" alt="rating-5" />
      ),
    },
    {
      value: 4,
      label: (
        <img src="/assets/images/icon/rating-4.svg" width="30px" height="30px" alt="rating-5" />
      ),
    },
    {
      value: 5,
      label: (
        <img src="/assets/images/icon/rating-5.svg" width="30px" height="30px" alt="rating-5" />
      ),
    },
  ];

  const IOSSlider = styled(Slider)(({ theme }) => ({
    height: 2,
    padding: '15px 0',
    margin: ' 30px 0 0 0',
    '& .MuiSlider-thumb': {
      backgroundColor: '#fff',
      background: "url('/assets/images/icon/point.svg') no-repeat center",
      boxShadow: 'none',
      '&:focus, &:hover, &.Mui-active': {
        boxShadow: 'none',
      },
      '&:before': {
        boxShadow: 'none',
      },
    },
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-rail': {
      opacity: 0.5,
      backgroundColor: '#bfbfbf',
    },
    "& .MuiSlider-markLabel[data-index='4'] ": {
      right: -30,
    },
    '& .MuiSlider-markLabel ': {
      top: -30,
    },
    '& .MuiSlider-mark': {
      backgroundColor: '#bfbfbf',
      height: 8,
      width: 1,
      '&.MuiSlider-markActive': {
        opacity: 1,
        backgroundColor: 'currentColor',
      },
    },
  }));

  const onSubmit = async () => {
    try {
      setRefreshIcon(true);
      const options = [];
      const optionsData = [];
      let negativeReviewResult;

      options.push(...facesValue, ...starValue, ...likeValue, ...ratingValue);
      options.forEach((record) => {
        optionsData.push({
          review: record.review,
          attributeId: record.attributeId,
          bAttributeId: parseInt(record.bAttributeId, 10),
          isCustomAttribute: record.isCustomAttribute,
        });
      });

      const formattedString = JSON.stringify(attributesValueObj);
      let negativeReviewType = false;
      if (negativeReviewRemainingCount > 0 && businessDetails?.isNegativeReviewRedirect) {
        // const payload = {
        //   query: qrData.isReviewNegative,
        //   variables: {
        //     review: textValue,
        //     attributes: formattedString,
        //   },
        // };
        // negativeReviewResult = await handleApiRequest(payload);

        // setNegativeReviewDetail(negativeReviewResult?.isReviewNegative);
        const faceMap = {
          Sad: 1,
          Neutral: 2,
          Happy: 3,
        };

        const thumbMap = {
          No: 1,
          Yes: 2,
        };

        allAttributeData.forEach((review) => {
          if (
            review.reviewType === 'Stars' &&
            review.review < businessDetails?.user_businesses_attribute_level_threshold?.star
          ) {
            setNegativeReviewDetail(true);
            negativeReviewType = true;
          }
          if (
            review.reviewType === 'Faces' &&
            review.review <
              faceMap[businessDetails?.user_businesses_attribute_level_threshold?.face]
          ) {
            setNegativeReviewDetail(true);
            negativeReviewType = true;
          }
          if (
            review.reviewType === 'Thumbs' &&
            review.review <
              thumbMap[businessDetails?.user_businesses_attribute_level_threshold?.thumbs]
          ) {
            setNegativeReviewDetail(true);
            negativeReviewType = true;
          }
        });
      }

      const finalTextValue = textValue;
      const payload = {
        query: qrData.submitBusinessReview,
        variables: {
          attributeDetail: optionsData,
          reviewDescription: finalTextValue,
          reviewPlatformId: businessDetails?.review_platforms?.id,
          isAutoThankYouSupport: businessDetails?.isAutoThankYouReply,
          businessProductsAndServicesId: selectedProductServicesId,
          isNegativeReview: negativeReviewType,
          isAutogenerate,
          phoneNumber: {
            phoneNumber,
            countryCode: countryCode ? `+${countryCode}` : '',
          },
        },
      };
      const businessId = parseInt(businessDetails?.id, 10);
      const currentUrl = window.location.href;
      const method = new URL(currentUrl).searchParams.get('method');
      if (!Number.isNaN(businessId)) {
        const visitorPayload = {
          query: qrData.addVisitorCountBasedOnIPAddress,
          variables: {
            businessId,
            ...(method && { trackingUniqueId: method }),
          },
        };
        dispatch(addVisitorCountBasedOnIPAddress(visitorPayload));
      }
      if (selectedAgentId !== 0) {
        payload.variables.businessAgentDetailsId = parseInt(selectedAgentId, 10);
      }
      if (businessDetails?.review_platforms.name === 'amazon') {
        payload.variables.amazonProductId = parseInt(businessDetails?.id, 10);
      } else if (businessDetails?.review_platforms.name === 'flipkart') {
        payload.variables.flipkartProductId = parseInt(businessDetails?.id, 10);
      } else {
        payload.variables.businessId = parseInt(businessDetails?.id, 10);
      }
      if (businessDetails?.isOnlineBusiness) {
        payload.variables.rating = overAllRatingValue;
        payload.variables.reviewerName = reviewerName;
      }

      navigator.clipboard.writeText(finalTextValue);
      setTextValue(textValue);

      dispatch(submitBusinessReview(payload));
    } catch (e) {
      setRotateIcon(false);
      setRefreshIcon(false);
    }
  };

  function onSubmitCall() {
    setRequestCallRefreshIcon(true);
    const payload = {
      query: qrData.requestCallForNegativeReviews,
      variables: {
        autoGeneratedReview: textValue,
        name: requestCallName,
        reason: requestCallReason,
        phoneNumber: parseInt(phoneNumber, 10),
        userId: parseInt(businessDetails?.userId, 10),
        countryCode: `+${countryCode}`,
      },
    };
    if (parseInt(businessDetails?.review_platforms?.id, 10) === 5) {
      payload.variables.amazonProductId = parseInt(businessDetails?.id, 10);
    } else if (parseInt(businessDetails?.review_platforms?.id, 10) === 6) {
      payload.variables.flipkartProductId = parseInt(businessDetails?.id, 10);
    } else {
      payload.variables.businessId = parseInt(businessDetails?.id, 10);
    }
    dispatch(requestCallForNegativeReviews(payload));
  }
  function handleNextButtonIndex(setReviewData) {
    setDirection('next');
    setNextButtonStatus(true);
    setTimeout(() => {
      setIsSliding(true);
    }, 400);
    setTimeout(() => {
      setDirection('done');
    }, 910);
    const indexNumber = parseInt(isActiveButton.split('-')[1], 10) + 1;
    if (indexNumber < businessDetails?.business_types?.business_attributes?.length) {
      setTimeout(() => {
        setIsActiveButton(`tabButton-${indexNumber}`);
        setIsActive(`tab-${indexNumber}`);
        setIsActiveClass('translate-x-0');
        setNextButtonStatus(false);

        setIsSliding(false);
      }, 1000);
    }
    if (
      businessDetails?.isAutoTextGenerate &&
      indexNumber === businessDetails?.business_types?.business_attributes?.length &&
      autoGenerateRemainingCount > 0
    ) {
      setNextScreen(true);
      if (getProductList?.length === 0 || getServiceList?.length === 0) {
        handleReview(setReviewData);
      }
    }
    setCurrentSelectedAttribute({
      ...businessDetails?.business_types?.business_attributes.at(indexNumber),
      reviewType:
        businessDetails?.business_types?.business_attributes.at(indexNumber)
          ?.business_attribute_values?.type,
    });
    setCurrentAttribute({
      ...currentAttribute,
      reviewType:
        businessDetails?.business_types?.business_attributes.at(indexNumber)
          ?.business_attribute_values?.type,
    });
    setAttributeNumber(indexNumber + 1);
  }

  function handlePreviousButtonIndex() {
    const indexNumber = parseInt(isActiveButton.split('-')[1], 10) - 1;
    setDirection('previous');
    setTimeout(() => {
      setIsSliding(true);
    }, 400);
    if (indexNumber < businessDetails?.business_types?.business_attributes?.length) {
      setTimeout(() => {
        setIsActiveButton(`tabButton-${indexNumber}`);
        setIsActive(`tab-${indexNumber}`);
        setIsSliding(false);
      }, 1000);
    }
  }

  const handleFormSubmit = (data) => {
    setReviewValue(data);
  };

  function handleSkipAndContinue() {
    onSubmit(reviewData);
  }
  function handlePhoneChange(event, data, value, formattedValue) {
    setCountryCode(data?.dialCode);
    const enteredPhoneNumber = event.slice(data.dialCode.length);
    setPhoneNumber(enteredPhoneNumber);
    setPhoneValue(event);
  }

  function onChangeNumber(event) {
    setOverAllRatingValue(event);
  }

  async function handleOnChangeText(value) {
    setTextValue(value);
    setReviewIsTyped(true);
    if (!value) {
      setReviewIsTyped(false);
      setReviewGenerateStatus(false);
    }
  }
  async function handleSetReviewText(value) {
    if (!value) {
      setReviewIsTyped(false);
      setReviewGenerateStatus(false);
    }

    setTextValue(value);
    const foundIndex = optionsWithReviewText.findIndex(
      (x) => parseInt(x?.bAttributeId, 10) === parseInt(currentSelectedAttribute?.bAttributeId, 10)
    );
    if (optionsWithReviewText.length > 0 && foundIndex >= 0) {
      if (optionsWithReviewText[foundIndex].reviewText) {
        optionsWithReviewText[foundIndex].reviewText = value;
      } else {
        optionsWithReviewText[foundIndex] = {
          ...optionsWithReviewText[foundIndex],
          reviewText: value,
        };
      }
      setOptionsWithReviewText(optionsWithReviewText);
    } else {
      optionsWithReviewText.push({
        ...currentSelectedAttribute,
        reviewText: value,
      });
      setOptionsWithReviewText(optionsWithReviewText);
    }
  }

  const handleCheckReview = () => {
    setOpen(!open);
  };

  const handleOnSkip = () => {
    setNextScreen(true);
    if (
      autoGenerateRemainingCount > 0 &&
      (getProductList?.length === 0 || getServiceList?.length === 0)
    ) {
      handleReview();
    }
  };

  async function sendReviewRequest(combinedData) {
    try {
      setRotateIcon(true);
      setLiveStreaming(true);
      const response = await fetch(process.env.REACT_APP_AI_QUICK_REVIEW, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(combinedData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const reader = response?.body?.getReader();
      setRotateIcon(false);
      let receivedText = '';
      while (true) {
        // eslint-disable-next-line no-await-in-loop
        const { done, value } = await reader.read();

        if (done) {
          break;
        }
        const chunk = new TextDecoder().decode(value);

        receivedText += chunk;
        setTextValue(receivedText);
        setIsAutogenerate(true);
      }
      setLiveStreaming(false);
      return {
        status: 200,
        message: 'success',
      };
    } catch (e) {
      setLiveStreaming(false);
      return {
        status: 500,
        message: e,
      };
    }
  }

  const encryptData = (data, symmetricKey1, iv1) => {
    const jsonString = JSON.stringify(data);
    const encryptedData = CryptoJS.AES.encrypt(jsonString, symmetricKey1, {
      iv: iv1,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
    return encryptedData;
  };

  async function handleReview(setReviewData) {
    const starValueData = setReviewData?.length > 0 ? setReviewData : starValue;
    try {
      if (businessDetails?.isAutoTextGenerate) {
        setTextValue('');
        const options = [];
        const optionsData = [];
        const obj = {};
        options.push(...facesValue, ...starValueData, ...likeValue, ...ratingValue);
        let agentName;
        if (!getAgentList?.defaultAgent?.length > 0) {
          const agentNameArray = getAgentList?.allAgent?.filter(
            (item) => item.id === selectedAgentId
          );
          agentName = agentNameArray?.[0]?.name;
        } else {
          agentName = getAgentList?.defaultAgent?.[0]?.name;
        }
        options.forEach((record) => {
          let val;
          if (record?.reviewType === 'Faces') {
            faces.find((value) => {
              if (value?.id === record?.review) {
                val = value.value;
              }
              return '';
            });
          } else if (record?.reviewType === 'Thumbs') {
            thumbs.find((value) => {
              if (value?.id === record?.review) {
                val = value.value;
              }
              return '';
            });
          } else if (record.reviewType === 'NPS' || record.reviewType === 'Stars') {
            val = `${record.review}/5`;
          }

          optionsData.push({
            review: val,
            attributeId: record.attributeId,
            attributes: record.name,
            reviewType: record.reviewType,
          });
        });
        optionsData.forEach((element, index) => {
          obj[`${element.attributes}`] = element.review;
        });

        const reviewObj = {
          business_type: businessDetails?.isAmazonProduct
            ? 'Amazon product'
            : businessDetails?.isFlipkartProduct
            ? 'Flipkart Product'
            : businessDetails?.business_types?.bType,
          business_id:
            businessDetails?.isAmazonProduct || businessDetails?.isFlipkartProduct
              ? Number(businessDetails?.userBusinessId)
              : Number(businessDetails?.id),
          attributes: obj,
          lang_complexity: langComplexity,
          review_mode: businessDetails?.reviewModeTypeName?.replace(' Mode', '').toLowerCase(),
          attribute_number: attributeNumber,
          special_products: specialProduct,
          special_services: specialServices,
          user_tried_products: selectedTriedProduct,
          user_tried_services: selectedTriedServices,
          agent_name: agentName,
          // userId: parseInt(businessDetails?.userId, 10),
          // userBusinessId: parseInt(businessDetails?.id || businessDetails?.userBusinessId, 10),
        };

        if (
          businessDetails?.review_platforms.name === 'amazon' ||
          businessDetails?.review_platforms.name === 'flipkart'
        ) {
          reviewObj.product_name = businessDetails?.genericName || businessDetails?.name;
          reviewObj.online_store_name = businessDetails?.review_platforms.name;
        }

        if (businessDetails?.review_platforms?.name === 'yelp') {
          reviewObj.platform = 'yelp';
        }

        const combinedData = encryptData(reviewObj, symmetricKey, iv);

        setRotateIcon(true);

        setAttributesValueObj(reviewObj?.attributes);

        sendReviewRequest(combinedData)
          .then((responseData) => {
            if (responseData?.status === 200) {
              setRotateIcon(false);
              setReviewGenerateStatus(true);
              setReviewIsTyped(false);
            }
          })

          .catch((error) => {
            console.log(error);
            setRotateIcon(false);
            setAutoGenerateButton(false);
          });
        setReviewIsTyped(false);
      }
    } catch (error) {
      setRotateIcon(false);
      console.error('Error:', error);
    }
  }
  return (
    <>
      <div>
        {businessDetails?.reviewModeTypeId !== 5 && businessDetails?.isExpire === false && (
          <>
            {businessDetails && Object.keys(businessDetails).length > 0 && (
              <div className="relative mb-80">
                <div className="overflow-hidden relative cover-img pt-32 pb-84 md:pt-36 md:pb-112 rounded-b-2xl bg-[#323952] w-full">
                  <div
                    className={`logo review-logo ${
                      businessDetails?.reviewModeTypeId === 2
                        ? 'formmode top-20 md:top-52'
                        : 'top-40 md:top-96 '
                    }`}
                  >
                    <img
                      className="mx-auto max-w-96"
                      src="assets/images/logo/logo_white.png"
                      alt="logo"
                    />
                    {businessDetails?.reviewModeTypeId === 2 && !overAllRatingSuccess && (
                      <h1 className="md:text-18 text-18 font-medium mb-12 text-center text-white mt-20">
                        {businessDetails?.isAmazonProduct
                          ? `Do you like "${businessDetails?.amazonProduct?.title}"?`
                          : businessDetails?.isFlipkartProduct
                          ? `Do you like "${businessDetails?.flipkartProduct?.title}"?`
                          : `${t('qrScanner.labels.enjoying')} ${businessDetails?.name}?`}
                      </h1>
                    )}
                  </div>
                </div>

                <div className={`p-0 ${businessDetails?.reviewModeTypeId === 3 && 'mt-20'}`}>
                  <div
                    className={`${
                      businessDetails?.reviewModeTypeId === 3 && !nextScreen && 'min-h-320'
                    } ${
                      businessDetails?.reviewModeTypeId !== 2 && !nextScreen
                        ? 'bg-white md:p-32 p-16 rounded-md'
                        : ''
                    } w-full max-w-384 mx-auto text-center relative md:-mt-96 -mt-60`}
                  >
                    {/* Default Mode */}
                    {!nextScreen ? (
                      <div>
                        <Box
                          key={businessDetails?.name}
                          role="group"
                          aria-labelledby="rank"
                          className="text-left"
                        >
                          {((businessDetails?.reviewModeTypeId === 3 &&
                            businessDetails?.isOnlineBusiness &&
                            !overAllRatingNext) ||
                            (businessDetails?.isOnlineBusiness &&
                              businessDetails?.reviewModeTypeId === 2)) && (
                            <div
                              className={`min-h-320 bg-white rounded-md w-full max-w-384 mx-auto text-center relative ${
                                businessDetails?.reviewModeTypeId === 2 && 'md:p-24 p-16 mb-16'
                              } `}
                            >
                              <h1 className="md:text-2xl text-18 font-bold mt-0 mb-12 mt-20">
                                Overall Rating
                              </h1>
                              <Typography>
                                Please select the rating that best reflects your experience with us.
                              </Typography>
                              <Box
                                className={`mx-auto text-center ${
                                  businessDetails?.reviewModeTypeId === 3 &&
                                  'h-288 flex items-center'
                                }`}
                              >
                                <div className="mx-auto text-center mt-48">
                                  <AnimatedCounter
                                    className="block text-60 font-bold mx-auto block justify-center"
                                    value={overAllRatingValue}
                                    incrementColor="black"
                                    decrementColor="black"
                                    decimalPrecision={1}
                                    color="black"
                                    fontSize="60px"
                                  />

                                  <div className="w-full mx-auto text-center mt-24">
                                    <Rating
                                      name="star rating"
                                      value={overAllRatingValue}
                                      onChange={(event, newValue) => onChangeNumber(newValue)}
                                      icon={
                                        <StarIcon
                                          style={{
                                            width: '36px',
                                            height: '36px',
                                          }}
                                          fontSize="inherit"
                                        />
                                      }
                                      emptyIcon={
                                        <StarIcon
                                          style={{
                                            opacity: 1,
                                            color: '#EEEEEE',
                                            width: '36px',
                                            height: '36px',
                                          }}
                                          fontSize="36px"
                                        />
                                      }
                                    />
                                  </div>
                                  {businessDetails?.reviewModeTypeId === 3 && (
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      className={`rounded-md mx-auto block px-40 text-16 font-medium mt-48 ${
                                        overAllRatingValue <= 0 ? 'bg-grey' : ''
                                      }`}
                                      onClick={() => {
                                        setOverAllRatingNext(true);
                                      }}
                                      disabled={overAllRatingValue <= 0}
                                    >
                                      {t('qrScanner.labels.next')}
                                    </Button>
                                  )}
                                </div>
                              </Box>
                            </div>
                          )}
                          {overAllRatingNext && (
                            <div>
                              {businessDetails?.business_types?.business_attributes &&
                                businessDetails?.business_types?.business_attributes?.length > 0 &&
                                businessDetails?.business_types?.business_attributes?.map(
                                  (item, index) => (
                                    <div
                                      key={index}
                                      className={`${
                                        businessDetails?.reviewModeTypeId === 3
                                          ? `tab-${index} ${
                                              isActive === `tab-${index}`
                                                ? `rating-content-active ${isActiveClass}`
                                                : `rating-content-inActive ${isActiveClass}`
                                            }`
                                          : 'bg-white md:p-24 p-16 rounded-md mb-16'
                                      }`}
                                    >
                                      {businessDetails?.reviewModeTypeId === 3 && (
                                        <div className="text-center">
                                          <span className="bg-darkgreen relative -mt-52 mx-auto text-white border-4 border-solid border-white w-64 h-64 inline-block text-center flex items-center rounded-full text-20 font-semibold">
                                            <span className="mx-auto">
                                              {index + 1} /{' '}
                                              {
                                                businessDetails?.business_types?.business_attributes
                                                  ?.length
                                              }
                                            </span>
                                          </span>
                                          <h1 className="md:text-2xl text-18 font-bold mt-0 mb-12 mt-20">
                                            {businessDetails?.isAmazonProduct
                                              ? `Do you like "${businessDetails?.genericName}"?`
                                              : businessDetails?.isFlipkartProduct
                                              ? `Do you like "${businessDetails?.genericName}"?`
                                              : `${t('qrScanner.labels.enjoying')} ${
                                                  businessDetails?.name
                                                }?`}
                                          </h1>
                                        </div>
                                      )}

                                      {(businessDetails?.reviewModeTypeId === 2 ||
                                        businessDetails?.reviewModeTypeId === 3) && (
                                        <div className="radio-group-container">
                                          <h2
                                            className={`md:text-18 text-18 font-bold mt-0 text-center capitalize ${
                                              businessDetails?.reviewModeTypeId === 3
                                                ? 'text-darkgreen mb-40'
                                                : ' mb-12'
                                            } ${
                                              isSliding
                                                ? direction === 'next'
                                                  ? 'sliding-next'
                                                  : 'sliding-previous'
                                                : ''
                                            } ${direction === 'done' ? 'slide-show' : ''}`}
                                          >
                                            {item?.bAttribute}
                                          </h2>
                                        </div>
                                      )}
                                      {item?.business_attribute_values?.type === 'Faces' && (
                                        <div className="radio-group-container">
                                          <Box
                                            className={`mx-auto text-center ${
                                              businessDetails?.reviewModeTypeId === 3 &&
                                              'h-288 flex items-center'
                                            } ${
                                              isSliding
                                                ? direction === 'next'
                                                  ? 'sliding-next'
                                                  : 'sliding-previous'
                                                : ''
                                            } ${direction === 'done' ? 'slide-show' : ''}`}
                                          >
                                            {/* test face*/}
                                            <div className="mx-auto">
                                              {businessDetails?.reviewModeTypeId !== 2 && (
                                                <div className="mx-auto mb-32 rounded-full inline-block">
                                                  {parseInt(
                                                    facesValue
                                                      .filter((attribute) => {
                                                        return attribute?.name === item?.bAttribute;
                                                      })
                                                      .map((attributeValue) => {
                                                        return attributeValue.review;
                                                      }),
                                                    10
                                                  ) === 1 ? (
                                                    <img
                                                      src="/assets/images/icon/bad-face.svg"
                                                      alt="bad"
                                                      width={100}
                                                      height={100}
                                                      className="mx-auto active-rating"
                                                    />
                                                  ) : parseInt(
                                                      facesValue
                                                        .filter((attribute) => {
                                                          return (
                                                            attribute?.name === item?.bAttribute
                                                          );
                                                        })
                                                        .map((attributeValue) => {
                                                          return attributeValue.review;
                                                        }),
                                                      10
                                                    ) === 2 ? (
                                                    <img
                                                      src="/assets/images/icon/okay-face.svg"
                                                      alt="okay"
                                                      width={100}
                                                      height={100}
                                                      className="mx-auto active-rating"
                                                    />
                                                  ) : parseInt(
                                                      facesValue
                                                        .filter((attribute) => {
                                                          return (
                                                            attribute?.name === item?.bAttribute
                                                          );
                                                        })
                                                        .map((attributeValue) => {
                                                          return attributeValue.review;
                                                        }),
                                                      10
                                                    ) === 3 ? (
                                                    <img
                                                      src="/assets/images/icon/good-face.svg"
                                                      alt="good"
                                                      width={100}
                                                      height={100}
                                                      className="mx-auto active-rating"
                                                    />
                                                  ) : (
                                                    <img
                                                      src="/assets/images/icon/review-default.svg"
                                                      width={100}
                                                      height={100}
                                                      alt="silent"
                                                      className="mx-auto"
                                                    />
                                                  )}
                                                </div>
                                              )}
                                              <RadioGroup
                                                aria-labelledby={item?.bAttribute}
                                                className="mx-auto w-full block text-center"
                                                row
                                                defaultValue="0"
                                                name={item?.bAttribute}
                                                value={(e) => facesValue(e)}
                                                onChange={(event) => onHandleReviews(event, item)}
                                              >
                                                <FormControlLabel
                                                  className="relative"
                                                  value={1}
                                                  control={
                                                    <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                                  }
                                                  label={
                                                    <code>
                                                      {parseInt(
                                                        facesValue
                                                          .filter((attribute) => {
                                                            return (
                                                              attribute?.name === item?.bAttribute
                                                            );
                                                          })
                                                          .map((attributeValue) => {
                                                            return attributeValue.review;
                                                          }),
                                                        10
                                                      ) === 1 ? (
                                                        <img
                                                          src={
                                                            businessDetails?.reviewModeTypeId === 2
                                                              ? '/assets/images/icon/bad-face.svg'
                                                              : '/assets/images/icon/bad.svg'
                                                          }
                                                          alt="bad"
                                                          className={`${
                                                            businessDetails?.reviewModeTypeId === 2
                                                              ? 'active-rating w-52'
                                                              : 'w-48'
                                                          }`}
                                                        />
                                                      ) : (
                                                        <img
                                                          src="/assets/images/icon/bad.svg"
                                                          alt="bad"
                                                          style={{
                                                            filter:
                                                              businessDetails?.reviewModeTypeId ===
                                                              2
                                                                ? 'grayscale(100%)'
                                                                : 'none',
                                                          }}
                                                          className={`${
                                                            businessDetails?.reviewModeTypeId === 2
                                                              ? 'opacity-60 inactive-rating w-52'
                                                              : 'w-48'
                                                          }`}
                                                        />
                                                      )}
                                                      {businessDetails?.reviewModeTypeId !== 2 && (
                                                        <span className="block mt-5 font-semibold">
                                                          {t('qrScanner.labels.bad')}
                                                        </span>
                                                      )}
                                                    </code>
                                                  }
                                                  labelPlacement="bottom"
                                                />
                                                <FormControlLabel
                                                  className="relative"
                                                  value={2}
                                                  control={
                                                    <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                                  }
                                                  label={
                                                    <code>
                                                      {parseInt(
                                                        facesValue
                                                          .filter((attribute) => {
                                                            return (
                                                              attribute?.name === item?.bAttribute
                                                            );
                                                          })
                                                          .map((attributeValue) => {
                                                            return attributeValue.review;
                                                          }),
                                                        10
                                                      ) === 2 ? (
                                                        <img
                                                          src={
                                                            businessDetails?.reviewModeTypeId === 2
                                                              ? '/assets/images/icon/okay-face.svg'
                                                              : '/assets/images/icon/okay.svg'
                                                          }
                                                          alt="okay"
                                                          className={`${
                                                            businessDetails?.reviewModeTypeId === 2
                                                              ? 'active-rating w-52'
                                                              : 'w-48'
                                                          }`}
                                                        />
                                                      ) : (
                                                        <img
                                                          src="/assets/images/icon/okay.svg"
                                                          alt="okay"
                                                          style={{
                                                            filter:
                                                              businessDetails?.reviewModeTypeId ===
                                                              2
                                                                ? 'grayscale(100%)'
                                                                : 'none',
                                                          }}
                                                          className={`${
                                                            businessDetails?.reviewModeTypeId === 2
                                                              ? 'w-52 inactive-rating'
                                                              : 'w-48'
                                                          }`}
                                                        />
                                                      )}
                                                      {businessDetails?.reviewModeTypeId !== 2 && (
                                                        <span className="block mt-5 font-semibold">
                                                          {t('qrScanner.labels.okay')}
                                                        </span>
                                                      )}
                                                    </code>
                                                  }
                                                  labelPlacement="bottom"
                                                />
                                                <FormControlLabel
                                                  className="relative"
                                                  value={3}
                                                  control={
                                                    <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                                  }
                                                  label={
                                                    <code>
                                                      {parseInt(
                                                        facesValue
                                                          .filter((attribute) => {
                                                            return (
                                                              attribute?.name === item?.bAttribute
                                                            );
                                                          })
                                                          .map((attributeValue) => {
                                                            return attributeValue.review;
                                                          }),
                                                        10
                                                      ) === 3 ? (
                                                        <img
                                                          src={
                                                            businessDetails?.reviewModeTypeId === 2
                                                              ? '/assets/images/icon/good-face.svg'
                                                              : '/assets/images/icon/good.svg'
                                                          }
                                                          alt="good"
                                                          className={`${
                                                            businessDetails?.reviewModeTypeId === 2
                                                              ? 'active-rating w-52'
                                                              : 'w-48'
                                                          }`}
                                                        />
                                                      ) : (
                                                        <img
                                                          src="/assets/images/icon/good.svg"
                                                          alt="good"
                                                          style={{
                                                            filter:
                                                              businessDetails?.reviewModeTypeId ===
                                                              2
                                                                ? 'grayscale(100%)'
                                                                : 'none',
                                                          }}
                                                          className={`${
                                                            businessDetails?.reviewModeTypeId === 2
                                                              ? 'opacity-70 w-52 inactive-rating'
                                                              : 'w-48'
                                                          }`}
                                                        />
                                                      )}
                                                      {businessDetails?.reviewModeTypeId !== 2 && (
                                                        <span className="block mt-5 font-semibold">
                                                          {t('qrScanner.labels.good')}
                                                        </span>
                                                      )}
                                                    </code>
                                                  }
                                                  labelPlacement="bottom"
                                                />
                                              </RadioGroup>
                                            </div>
                                          </Box>
                                        </div>
                                      )}
                                      {item?.business_attribute_values?.type === 'Thumbs' && (
                                        <div className="radio-group-container">
                                          <Box
                                            className={`mx-auto text-center ${
                                              businessDetails?.reviewModeTypeId === 3 &&
                                              'h-288 flex items-center'
                                            } ${
                                              isSliding
                                                ? direction === 'next'
                                                  ? 'sliding-next'
                                                  : 'sliding-previous'
                                                : ''
                                            } ${direction === 'done' ? 'slide-show' : ''}`}
                                          >
                                            {/* <p>test thumb</p>*/}
                                            <RadioGroup
                                              aria-labelledby="demo-radio-buttons-group-label"
                                              className="mx-auto w-full block text-center"
                                              row
                                              defaultValue="1"
                                              name={item?.bAttribute}
                                              onChange={(event) => onHandleReviews(event, item)}
                                              value={(e) => likeValue(e)}
                                            >
                                              <FormControlLabel
                                                className="relative"
                                                value={1}
                                                control={
                                                  <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                                }
                                                label={
                                                  <code>
                                                    {parseInt(
                                                      likeValue
                                                        .filter((attribute) => {
                                                          return (
                                                            attribute?.name === item?.bAttribute
                                                          );
                                                        })
                                                        .map((attributeValue) => {
                                                          return attributeValue.review;
                                                        }),
                                                      10
                                                    ) === 1 ? (
                                                      <img
                                                        src="/assets/images/icon/dislike-active.svg"
                                                        alt="no"
                                                      />
                                                    ) : (
                                                      <img
                                                        src="/assets/images/icon/dislike-deactive.svg"
                                                        className={`${
                                                          businessDetails?.reviewModeTypeId === 2
                                                            ? 'opacity-50'
                                                            : ''
                                                        }`}
                                                        alt="no"
                                                      />
                                                    )}
                                                    {businessDetails?.reviewModeTypeId !== 2 && (
                                                      <span className="block mt-5 font-semibold">
                                                        No
                                                      </span>
                                                    )}
                                                  </code>
                                                }
                                                labelPlacement="bottom"
                                              />
                                              <FormControlLabel
                                                className="relative"
                                                value={2}
                                                control={
                                                  <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                                }
                                                label={
                                                  <code>
                                                    {parseInt(
                                                      likeValue
                                                        .filter((attribute) => {
                                                          return (
                                                            attribute?.name === item?.bAttribute
                                                          );
                                                        })
                                                        .map((attributeValue) => {
                                                          return attributeValue.review;
                                                        }),
                                                      10
                                                    ) === 2 ? (
                                                      <img
                                                        src="/assets/images/icon/like-active.svg"
                                                        alt="yes"
                                                      />
                                                    ) : (
                                                      <img
                                                        src="/assets/images/icon/like-deactive.svg"
                                                        alt="yes"
                                                        className={`${
                                                          businessDetails?.reviewModeTypeId === 2
                                                            ? 'opacity-50'
                                                            : ''
                                                        }`}
                                                      />
                                                    )}
                                                    {businessDetails?.reviewModeTypeId !== 2 && (
                                                      <span className="block mt-5 font-semibold">
                                                        {t('qrScanner.labels.yes')}
                                                      </span>
                                                    )}
                                                  </code>
                                                }
                                                labelPlacement="bottom"
                                              />
                                            </RadioGroup>
                                          </Box>
                                        </div>
                                      )}
                                      {item?.business_attribute_values?.type === 'NPS' && (
                                        <Box
                                          sx={{
                                            width: '100%',
                                            maxWidth: '476px',
                                          }}
                                          className="mx-auto rating-ruler text-center"
                                        >
                                          <div className="mx-auto mb-32 rounded-full inline-block">
                                            {parseInt(
                                              ratingValue
                                                .filter((attribute) => {
                                                  return attribute?.name === item?.bAttribute;
                                                })
                                                .map((attributeValue) => {
                                                  return attributeValue.review;
                                                }),
                                              10
                                            ) === 1 ? (
                                              <img
                                                src="/assets/images/icon/rating-1.svg"
                                                alt="rating-1"
                                                className="mx-auto"
                                              />
                                            ) : parseInt(
                                                ratingValue
                                                  .filter((attribute) => {
                                                    return attribute?.name === item?.bAttribute;
                                                  })
                                                  .map((attributeValue) => {
                                                    return attributeValue.review;
                                                  }),
                                                10
                                              ) === 2 ? (
                                              <img
                                                src="/assets/images/icon/rating-2.svg"
                                                alt="rating-2"
                                                className="mx-auto"
                                              />
                                            ) : parseInt(
                                                ratingValue
                                                  .filter((attribute) => {
                                                    return attribute?.name === item?.bAttribute;
                                                  })
                                                  .map((attributeValue) => {
                                                    return attributeValue.review;
                                                  }),
                                                10
                                              ) === 3 ? (
                                              <img
                                                src="/assets/images/icon/rating-3.svg"
                                                alt="rating-3"
                                                className="mx-auto"
                                              />
                                            ) : parseInt(
                                                ratingValue
                                                  .filter((attribute) => {
                                                    return attribute?.name === item?.bAttribute;
                                                  })
                                                  .map((attributeValue) => {
                                                    return attributeValue.review;
                                                  }),
                                                10
                                              ) === 4 ? (
                                              <img
                                                src="/assets/images/icon/rating-4.svg"
                                                alt="rating-4"
                                                className="mx-auto"
                                              />
                                            ) : parseInt(
                                                ratingValue
                                                  .filter((attribute) => {
                                                    return attribute?.name === item?.bAttribute;
                                                  })
                                                  .map((attributeValue) => {
                                                    return attributeValue?.review;
                                                  }),
                                                10
                                              ) === 5 ? (
                                              <img
                                                src="/assets/images/icon/rating-5.svg"
                                                alt="rating-5"
                                                className="mx-auto"
                                              />
                                            ) : (
                                              <img
                                                src="/assets/images/icon/review-default.svg"
                                                alt="silent"
                                                className="mx-auto"
                                              />
                                            )}
                                          </div>
                                          <IOSSlider
                                            aria-label="Rating"
                                            className="mb-32"
                                            defaultValue={1}
                                            value={
                                              parseInt(
                                                ratingValue
                                                  .filter((attribute) => {
                                                    return attribute?.name === item?.bAttribute;
                                                  })
                                                  .map((attributeValue) => {
                                                    return attributeValue?.review;
                                                  }),
                                                10
                                              ) || 0
                                            }
                                            step={1}
                                            min={1}
                                            max={5}
                                            marks={marks}
                                            onChange={(event) => onHandleReviews(event, item)}
                                            valueLabelDisplay="off"
                                          />
                                        </Box>
                                      )}
                                      {item?.business_attribute_values?.type === 'Stars' && (
                                        <>
                                          {businessDetails?.reviewModeTypeId === 2 ? (
                                            <Box className="mx-auto text-center">
                                              <RadioGroup
                                                aria-labelledby={item?.bAttribute}
                                                className="mx-auto w-full block text-center"
                                                row
                                                defaultValue="0"
                                                name={item?.bAttribute}
                                                value={(e) => starValue(e)}
                                                onChange={(event) => onHandleReviews(event, item)}
                                              >
                                                <FormControlLabel
                                                  className="relative m-2"
                                                  value={1}
                                                  control={
                                                    <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                                  }
                                                  label={
                                                    <code>
                                                      {parseInt(
                                                        starValue
                                                          .filter((attribute) => {
                                                            return (
                                                              attribute?.name === item?.bAttribute
                                                            );
                                                          })
                                                          .map((attributeValue) => {
                                                            return attributeValue.review;
                                                          }),
                                                        10
                                                      ) === 1 ? (
                                                        <img
                                                          src="/assets/images/icon/reviews/colorful/starcolor1.svg"
                                                          alt="1"
                                                          className="w-44 mx-4"
                                                        />
                                                      ) : (
                                                        <img
                                                          src="/assets/images/icon/reviews/grey/star1.svg"
                                                          alt="1"
                                                          className="w-44 mx-4"
                                                        />
                                                      )}
                                                    </code>
                                                  }
                                                  labelPlacement="bottom"
                                                />
                                                <FormControlLabel
                                                  className="relative m-2"
                                                  value={2}
                                                  control={
                                                    <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                                  }
                                                  label={
                                                    <code>
                                                      {parseInt(
                                                        starValue
                                                          .filter((attribute) => {
                                                            return (
                                                              attribute?.name === item?.bAttribute
                                                            );
                                                          })
                                                          .map((attributeValue) => {
                                                            return attributeValue.review;
                                                          }),
                                                        10
                                                      ) === 2 ? (
                                                        <img
                                                          src="/assets/images/icon/reviews/colorful/starcolor2.svg"
                                                          alt="2"
                                                          className="w-44 mx-4"
                                                        />
                                                      ) : (
                                                        <img
                                                          src="/assets/images/icon/reviews/grey/star2.svg"
                                                          alt="2"
                                                          className="w-44 mx-4"
                                                        />
                                                      )}
                                                    </code>
                                                  }
                                                  labelPlacement="bottom"
                                                />
                                                <FormControlLabel
                                                  className="relative m-2"
                                                  value={3}
                                                  control={
                                                    <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                                  }
                                                  label={
                                                    <code>
                                                      {parseInt(
                                                        starValue
                                                          .filter((attribute) => {
                                                            return (
                                                              attribute?.name === item?.bAttribute
                                                            );
                                                          })
                                                          .map((attributeValue) => {
                                                            return attributeValue.review;
                                                          }),
                                                        10
                                                      ) === 3 ? (
                                                        <img
                                                          src="/assets/images/icon/reviews/colorful/starcolor3.svg"
                                                          alt="3"
                                                          className="w-44 mx-4"
                                                        />
                                                      ) : (
                                                        <img
                                                          src="/assets/images/icon/reviews/grey/star3.svg"
                                                          alt="3"
                                                          className="w-44 mx-4"
                                                        />
                                                      )}
                                                    </code>
                                                  }
                                                  labelPlacement="bottom"
                                                />

                                                <FormControlLabel
                                                  className="relative m-2"
                                                  value={4}
                                                  control={
                                                    <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                                  }
                                                  label={
                                                    <code>
                                                      {parseInt(
                                                        starValue
                                                          .filter((attribute) => {
                                                            return (
                                                              attribute?.name === item?.bAttribute
                                                            );
                                                          })
                                                          .map((attributeValue) => {
                                                            return attributeValue.review;
                                                          }),
                                                        10
                                                      ) === 4 ? (
                                                        <img
                                                          src="/assets/images/icon/reviews/colorful/starcolor4.svg"
                                                          alt="1"
                                                          className="w-44 mx-4"
                                                        />
                                                      ) : (
                                                        <img
                                                          src="/assets/images/icon/reviews/grey/star4.svg"
                                                          alt="1"
                                                          className="w-44 mx-4"
                                                        />
                                                      )}
                                                    </code>
                                                  }
                                                  labelPlacement="bottom"
                                                />

                                                <FormControlLabel
                                                  className="relative m-2"
                                                  value={5}
                                                  control={
                                                    <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                                  }
                                                  label={
                                                    <code>
                                                      {parseInt(
                                                        starValue
                                                          .filter((attribute) => {
                                                            return (
                                                              attribute?.name === item?.bAttribute
                                                            );
                                                          })
                                                          .map((attributeValue) => {
                                                            return attributeValue.review;
                                                          }),
                                                        10
                                                      ) === 5 ? (
                                                        <img
                                                          src="/assets/images/icon/reviews/colorful/starcolor5.svg"
                                                          alt="5"
                                                          className="w-44 mx-4"
                                                        />
                                                      ) : (
                                                        <img
                                                          src="/assets/images/icon/reviews/grey/star5.svg"
                                                          alt="5"
                                                          className="w-44 mx-4"
                                                        />
                                                      )}
                                                    </code>
                                                  }
                                                  labelPlacement="bottom"
                                                />
                                              </RadioGroup>
                                            </Box>
                                          ) : (
                                            <div className="radio-group-container">
                                              <Box
                                                className={`mx-auto text-center ${
                                                  businessDetails?.reviewModeTypeId === 3 &&
                                                  'h-288 flex items-center'
                                                } ${
                                                  isSliding
                                                    ? direction === 'next'
                                                      ? 'sliding-next'
                                                      : 'sliding-previous'
                                                    : ''
                                                } ${direction === 'done' ? 'slide-show' : ''}`}
                                              >
                                                {/* <p>test star</p>*/}
                                                <div className="mx-auto">
                                                  <div className="mx-auto mb-32 rounded-full inline-block">
                                                    {parseInt(
                                                      starValue
                                                        .filter((attribute) => {
                                                          return (
                                                            attribute?.name === item?.bAttribute
                                                          );
                                                        })
                                                        .map((attributeValue) => {
                                                          return attributeValue.review;
                                                        }),
                                                      10
                                                    ) === 1 ? (
                                                      <img
                                                        src="/assets/images/icon/star-1.svg"
                                                        alt="rating-1"
                                                        className="mx-auto"
                                                      />
                                                    ) : parseInt(
                                                        starValue
                                                          .filter((attribute) => {
                                                            return (
                                                              attribute?.name === item?.bAttribute
                                                            );
                                                          })
                                                          .map((attributeValue) => {
                                                            return attributeValue.review;
                                                          }),
                                                        10
                                                      ) === 2 ? (
                                                      <img
                                                        src="/assets/images/icon/star-2.svg"
                                                        alt="rating-2"
                                                        className="mx-auto"
                                                      />
                                                    ) : parseInt(
                                                        starValue
                                                          .filter((attribute) => {
                                                            return (
                                                              attribute?.name === item?.bAttribute
                                                            );
                                                          })
                                                          .map((attributeValue) => {
                                                            return attributeValue.review;
                                                          }),
                                                        10
                                                      ) === 3 ? (
                                                      <img
                                                        src="/assets/images/icon/star-3.svg"
                                                        alt="rating-3"
                                                        className="mx-auto"
                                                      />
                                                    ) : parseInt(
                                                        starValue
                                                          .filter((attribute) => {
                                                            return (
                                                              attribute?.name === item?.bAttribute
                                                            );
                                                          })
                                                          .map((attributeValue) => {
                                                            return attributeValue.review;
                                                          }),
                                                        10
                                                      ) === 4 ? (
                                                      <img
                                                        src="/assets/images/icon/star-4.svg"
                                                        alt="rating-4"
                                                        className="mx-auto"
                                                      />
                                                    ) : parseInt(
                                                        starValue
                                                          .filter((attribute) => {
                                                            return (
                                                              attribute?.name === item?.bAttribute
                                                            );
                                                          })
                                                          .map((attributeValue) => {
                                                            return attributeValue.review;
                                                          }),
                                                        10
                                                      ) === 5 ? (
                                                      <img
                                                        src="/assets/images/icon/star-5.svg"
                                                        alt="rating-5"
                                                        className="mx-auto"
                                                      />
                                                    ) : (
                                                      <img
                                                        src="/assets/images/icon/star-default.svg"
                                                        alt="silent"
                                                        className="mx-auto"
                                                      />
                                                    )}
                                                  </div>
                                                  <div className="w-full mx-auto text-center">
                                                    <Rating
                                                      name="star rating"
                                                      value={parseInt(
                                                        starValue
                                                          .filter((attribute) => {
                                                            return (
                                                              attribute?.name === item?.bAttribute
                                                            );
                                                          })
                                                          .map((attributeValue) => {
                                                            return attributeValue.review;
                                                          }),
                                                        10
                                                      )}
                                                      onChange={(event) =>
                                                        onHandleReviews(event, item)
                                                      }
                                                      icon={
                                                        <StarIcon
                                                          style={{
                                                            width: '28px',
                                                            height: '28px',
                                                          }}
                                                          fontSize="inherit"
                                                        />
                                                      }
                                                      emptyIcon={
                                                        <StarOutlineIcon
                                                          style={{
                                                            opacity: 1,
                                                            color: '#faaf00',
                                                            width: '28px',
                                                            height: '28px',
                                                          }}
                                                          fontSize="28px"
                                                        />
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </Box>
                                            </div>
                                          )}
                                        </>
                                      )}
                                      {businessDetails?.reviewModeTypeId === 3 && (
                                        <div className="flex">
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            className={`rounded-md mx-auto block px-40 text-16 font-medium bg-grey-300 text-black hover:text-white ${
                                              index === 0
                                                ? 'bg-grey text-white cursor-not-allowed'
                                                : ''
                                            }`}
                                            onClick={() => handlePreviousButtonIndex(true)}
                                            disabled={index === 0}
                                          >
                                            {t('qrScanner.labels.previous')}
                                          </Button>
                                          <Button
                                            variant="contained"
                                            color="secondary"
                                            className={`rounded-md mx-auto block px-5 min-w-136 text-16 font-medium ${
                                              currentAttribute?.bAttributeId !==
                                              parseInt(item?.bAttributeId, 10)
                                                ? 'bg-grey-300 text-black hover:text-white'
                                                : ''
                                            }`}
                                            onClick={() =>
                                              businessDetails?.business_types?.business_attributes
                                                ?.length ===
                                              index + 1
                                                ? `${
                                                    (autoGenerateRemainingCount <= 0 ||
                                                      !businessDetails?.isAutoTextGenerate) &&
                                                    !businessDetails?.isOnlineBusiness
                                                      ? handleSkipAndContinue()
                                                      : handleOnSkip()
                                                  }`
                                                : handleNextButtonIndex()
                                            }
                                            // disabled={allAttributeData.length <= 0}
                                          >
                                            {currentAttribute?.bAttributeId !==
                                            parseInt(item?.bAttributeId, 10)
                                              ? `${
                                                  (!businessDetails?.isAutoTextGenerate &&
                                                    businessDetails?.business_types
                                                      ?.business_attributes?.length ===
                                                      index + 1) ||
                                                  (businessDetails?.business_types
                                                    ?.business_attributes?.length ===
                                                    index + 1 &&
                                                    autoGenerateRemainingCount <= 0)
                                                    ? businessDetails?.isOnlineBusiness
                                                      ? `${t('qrScanner.labels.next')}`
                                                      : `  ${t('qrScanner.button.submit')}
                                                          ${
                                                            businessDetails?.review_platforms?.name
                                                          }`
                                                    : nextButtonStatus
                                                    ? `${t('qrScanner.labels.next')}`
                                                    : `${t('qrScanner.labels.skip')}`
                                                }`
                                              : `${
                                                  businessDetails?.isAutoTextGenerate
                                                    ? `${t('qrScanner.labels.next')}`
                                                    : ` ${t('qrScanner.button.submit')}{' '}
                                            ${businessDetails?.review_platforms?.name}`
                                                }`}
                                          </Button>
                                        </div>
                                      )}
                                    </div>
                                  )
                                )}
                            </div>
                          )}
                          {businessDetails?.reviewModeTypeId === 2 && (
                            <Button
                              variant="contained"
                              color="secondary"
                              className={`rounded-md mx-auto block px-40 text-16 font-medium ${
                                allAttributeData.length <= 0 ? 'bg-grey' : ''
                              }`}
                              // onClick={() => {
                              //   !businessDetails?.isAutoTextGenerate &&
                              //   !businessDetails?.isOnlineBusiness
                              //     ? handleSkipAndContinue()
                              //     : setNextScreen(true)
                              //   handleReview();
                              // }}
                              onClick={() => {
                                if (
                                  (!businessDetails?.isAutoTextGenerate &&
                                    !businessDetails?.isOnlineBusiness) ||
                                  autoGenerateRemainingCount <= 0
                                ) {
                                  handleSkipAndContinue();
                                } else {
                                  setNextScreen(true);
                                  if (
                                    getProductList?.length === 0 ||
                                    getServiceList?.length === 0
                                  ) {
                                    handleReview();
                                  }
                                }
                              }}
                              disabled={
                                businessDetails?.isOnlineBusiness
                                  ? overAllRatingValue <= 0
                                  : allAttributeData.length <= 0
                              }
                            >
                              {businessDetails?.isAutoTextGenerate && autoGenerateRemainingCount > 0
                                ? `${t('qrScanner.labels.next')}`
                                : businessDetails?.isOnlineBusiness
                                ? 'Submit'
                                : `${t('qrScanner.button.submit')}
                                            ${businessDetails?.review_platforms?.name}`}
                            </Button>
                          )}
                        </Box>
                      </div>
                    ) : (
                      <>
                        {!askCallScreen && !overAllRatingSuccess && (
                          <div className="bg-white md:p-24 p-16 rounded-md mb-16">
                            {businessDetails?.isOnlineBusiness && (
                              <div className="block mb-24">
                                <InputLabel
                                  htmlFor="rName"
                                  className="text-16 font-medium mb-12 text-grey-900 text-left"
                                >
                                  {t('qrScanner.labels.yourName')}
                                  <span className="text-red">*</span>
                                </InputLabel>
                                <TextField
                                  size="small"
                                  id="rName"
                                  placeholder={t('qrScanner.labels.yourNamePlaceholder')}
                                  value={reviewerName}
                                  onChange={(e) => setReviewerName(e?.target?.value)}
                                  variant="outlined"
                                  required
                                  fullWidth
                                />
                              </div>
                            )}

                            <div>
                              <div className="justify-center">
                                <InputLabel
                                  htmlFor="mobile"
                                  className="text-16 font-medium mb-12 text-grey-900 text-left"
                                >
                                  Mobile Number (Optional)
                                </InputLabel>
                                <PhoneInput
                                  country={currentCountry}
                                  name="mobile"
                                  value={phoneValue}
                                  enableSearch="true"
                                  variant="filled"
                                  className="w-full scanner-phone"
                                  onChange={(value, data) => {
                                    handlePhoneChange(value, data);
                                    const validPhoneNumber = handlePhoneNumberValidationCheck(
                                      value,
                                      data
                                    );
                                    setErrorMessages(validPhoneNumber);
                                  }}
                                  inputStyle={{
                                    width: '100%',
                                    height: '40px',
                                    backgroundColor: '#F4F3F4',
                                  }}
                                />
                                {errorMessages === false && phoneValue?.length > 0 && (
                                  <p className="text-red mt-5 text-16 text-start">
                                    {t('productSettings.InvalidPhoneNumber')}
                                  </p>
                                )}
                              </div>
                              <span className="block text-left text-11 text-black mt-8">
                                😊 Share your mobile number for special gifts and exclusive offers.
                                Your number is safe; no spam, just our way of saying thanks for
                                being part of our community! 🎁✨
                              </span>
                            </div>

                            {!getAgentList?.defaultAgent?.length > 0 &&
                              getAgentList?.allAgent?.length > 0 && (
                                <div className="block text-left">
                                  <InputLabel
                                    htmlFor="agent"
                                    className="text-16 font-medium mt-16 mb-8 text-grey-900"
                                  >
                                    {t('qrScanner.labels.agentTitle')}
                                  </InputLabel>
                                  <Select
                                    id="agent"
                                    name="agent"
                                    className="w-full"
                                    size="small"
                                    value={selectedAgentId}
                                    onChange={(e) => {
                                      setSelectedAgentId(e.target.value);
                                    }}
                                  >
                                    <MenuItem value={0} disabled>
                                      {t('qrScanner.labels.selectAgent')}
                                    </MenuItem>
                                    {agentData?.map((option) => {
                                      return (
                                        <MenuItem key={option.id} value={option.id}>
                                          <div className="flex items-center">
                                            <div
                                              style={{
                                                backgroundColor: `#${option.userProfile}33`,
                                                width: '30px',
                                                height: '30px',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: `#${option.userProfile}`,
                                                fontSize: '14px',
                                                fontWeight: 'semibold',
                                              }}
                                            >
                                              {option?.name.charAt(0).toUpperCase()}
                                            </div>
                                            <div className="ml-10">
                                              <span className="text-14 font-semibold block break-words whitespace-break-spaces max-w-256 capitalize">
                                                {option.name}
                                              </span>
                                              <span className="text-12 text-grey font-medium block mt-3 capitalize">
                                                {option.role}
                                              </span>
                                            </div>
                                          </div>
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </div>
                              )}

                            {getProductList?.length > 0 && (
                              <div
                                className="text-left mx-auto mt-20"
                                style={{ maxWidth: '550px' }}
                              >
                                <h2 className="font-semibold text-16 mb-16">
                                  Which products have you liked here?
                                </h2>
                                {getProductList &&
                                  getProductList?.length > 0 &&
                                  getProductList?.map((item) => (
                                    <Chip
                                      key={item?.id}
                                      label={
                                        <span className="flex items-center">
                                          {selectedProductServicesId.includes(item?.id) && (
                                            <img
                                              className="transition duration-150 ease-in-out"
                                              src="/assets/images/icon/check.svg"
                                              width={15}
                                              height={15}
                                              alt=""
                                            />
                                          )}
                                          <span
                                            className={`transition duration-150 ease-in-out ${
                                              selectedProductServicesId.includes(item?.id) && 'ml-5'
                                            }`}
                                          >
                                            {item?.name}
                                          </span>
                                        </span>
                                      }
                                      className={`${
                                        selectedProductServicesId.includes(item?.id)
                                          ? 'border-darkgreen'
                                          : ''
                                      } border-grey-200 capitalize bg-white border-1 border-solid rounded-full my-6 mr-12 font-semibold`}
                                      clickable
                                      style={{
                                        backgroundColor: selectedProductServicesId.includes(
                                          item?.id
                                        )
                                          ? '#e3efed'
                                          : '#fff',
                                        border: '1px solid',
                                        borderColor: selectedProductServicesId.includes(item?.id)
                                          ? '#0BA861'
                                          : '#DBDBDB',
                                      }}
                                      onClick={() => handleChangeChip(item?.id)}
                                      onDelete={() => handleChangeChip(item?.id)}
                                      deleteIcon={
                                        <Checkbox
                                          checked={selectedProductServicesId.includes(item?.id)}
                                          className="absolute opacity-0"
                                        />
                                      }
                                    />
                                  ))}
                              </div>
                            )}

                            {getServiceList?.length > 0 && (
                              <div
                                className="text-left mx-auto mt-20"
                                style={{ maxWidth: '550px' }}
                              >
                                <h2 className="font-semibold text-16 mb-16">
                                  Which services have you liked here?
                                </h2>
                                {getServiceList &&
                                  getServiceList?.length > 0 &&
                                  getServiceList?.map((item) => (
                                    <Chip
                                      key={item?.id}
                                      label={
                                        <span className="flex items-center">
                                          {selectedProductServicesId.includes(item?.id) && (
                                            <img
                                              className="transition duration-150 ease-in-out"
                                              src="/assets/images/icon/check.svg"
                                              alt=""
                                              width={15}
                                              height={15}
                                            />
                                          )}
                                          <span
                                            className={`transition duration-150 ease-in-out ${
                                              selectedProductServicesId.includes(item?.id) && 'ml-5'
                                            }`}
                                          >
                                            {item?.name}
                                          </span>
                                        </span>
                                      }
                                      className={`${
                                        selectedProductServicesId.includes(item?.id)
                                          ? 'border-darkgreen'
                                          : ''
                                      } border-grey-200 capitalize bg-white border-1 border-solid rounded-full my-6 mr-12 font-semibold`}
                                      clickable
                                      style={{
                                        backgroundColor: selectedProductServicesId.includes(
                                          item?.id
                                        )
                                          ? '#e3efed'
                                          : '#fff',
                                        border: '1px solid',
                                        borderColor: selectedProductServicesId.includes(item?.id)
                                          ? '#0BA861'
                                          : '#DBDBDB',
                                      }}
                                      onClick={() => handleChangeChip(item?.id)}
                                      onDelete={() => handleChangeChip(item?.id)}
                                      deleteIcon={
                                        <Checkbox
                                          checked={selectedProductServicesId.includes(item?.id)}
                                          className="absolute opacity-0"
                                        />
                                      }
                                    />
                                  ))}
                              </div>
                            )}

                            <Button
                              role="button"
                              className="text-14 font-medium mt-0 mb-8 mt-20 text-left w-full bg-white flex items-center justify-between p-0 hover:bg-white"
                              onClick={handleCheckReview}
                              onKeyDown={handleCheckReview}
                            >
                              {t('qrScanner.labels.writeReview')}
                              <span className="bg-darkgreen-100 w-24 h-24 rounded-full inline-block relative">
                                {open ? (
                                  <Icon className="text-18 text-darkgreen absolute bottom-2 left-3">
                                    expand_more
                                  </Icon>
                                ) : (
                                  <Icon className="text-18 text-darkgreen absolute left-3 bottom-2">
                                    expand_less
                                  </Icon>
                                )}
                              </span>
                            </Button>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                              <div
                                style={{ border: '1px solid #b7b7b7' }}
                                className="p-10 rounded-md mb-16"
                              >
                                <Box role="group" aria-labelledby="rank" className="text-left">
                                  {businessDetails?.business_types?.business_attributes &&
                                    businessDetails?.business_types?.business_attributes?.length >
                                      0 &&
                                    businessDetails?.business_types?.business_attributes?.map(
                                      (item, index) => (
                                        <div
                                          key={index}
                                          className="flex items-center justify-between mb-5"
                                        >
                                          <h2 className="text-12 font-semibold mt-0 text-grey-700 mb-0 max-w-120 text-left">
                                            {item?.bAttribute}
                                          </h2>

                                          {item?.business_attribute_values?.type === 'Faces' && (
                                            <Box className="text-center">
                                              <div className="mx-auto">
                                                <RadioGroup
                                                  aria-labelledby={item?.bAttribute}
                                                  className="mx-auto w-full block text-center"
                                                  row
                                                  defaultValue="0"
                                                  name={item?.bAttribute}
                                                  value={(e) => facesValue(e)}
                                                  onChange={(event) => onHandleReviews(event, item)}
                                                >
                                                  <FormControlLabel
                                                    className="relative m-4"
                                                    value={1}
                                                    control={
                                                      <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                                    }
                                                    label={
                                                      <code>
                                                        {parseInt(
                                                          facesValue
                                                            .filter((attribute) => {
                                                              return (
                                                                attribute?.name === item?.bAttribute
                                                              );
                                                            })
                                                            .map((attributeValue) => {
                                                              return attributeValue.review;
                                                            }),
                                                          10
                                                        ) === 1 ? (
                                                          <img
                                                            src="/assets/images/icon/reviews/colorful/faces1.svg"
                                                            alt="bad"
                                                            className="w-20"
                                                          />
                                                        ) : (
                                                          <img
                                                            src="/assets/images/icon/reviews/grey/faces1.svg"
                                                            alt="bad"
                                                            className="w-20"
                                                          />
                                                        )}
                                                      </code>
                                                    }
                                                    labelPlacement="bottom"
                                                  />
                                                  <FormControlLabel
                                                    className="relative m-4"
                                                    value={2}
                                                    control={
                                                      <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                                    }
                                                    label={
                                                      <code>
                                                        {parseInt(
                                                          facesValue
                                                            .filter((attribute) => {
                                                              return (
                                                                attribute?.name === item?.bAttribute
                                                              );
                                                            })
                                                            .map((attributeValue) => {
                                                              return attributeValue.review;
                                                            }),
                                                          10
                                                        ) === 2 ? (
                                                          <img
                                                            src="/assets/images/icon/reviews/colorful/faces2.svg"
                                                            alt="okay"
                                                            className="w-20"
                                                          />
                                                        ) : (
                                                          <img
                                                            src="/assets/images/icon/reviews/grey/faces2.svg"
                                                            alt="okay"
                                                            className="w-20"
                                                          />
                                                        )}
                                                      </code>
                                                    }
                                                    labelPlacement="bottom"
                                                  />
                                                  <FormControlLabel
                                                    className="relative m-4"
                                                    value={3}
                                                    control={
                                                      <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                                    }
                                                    label={
                                                      <code>
                                                        {parseInt(
                                                          facesValue
                                                            .filter((attribute) => {
                                                              return (
                                                                attribute?.name === item?.bAttribute
                                                              );
                                                            })
                                                            .map((attributeValue) => {
                                                              return attributeValue.review;
                                                            }),
                                                          10
                                                        ) === 3 ? (
                                                          <img
                                                            src="/assets/images/icon/reviews/colorful/faces3.svg"
                                                            alt="good"
                                                            className="w-20"
                                                          />
                                                        ) : (
                                                          <img
                                                            src="/assets/images/icon/reviews/grey/faces3.svg"
                                                            alt="good"
                                                            className="w-20"
                                                          />
                                                        )}
                                                      </code>
                                                    }
                                                    labelPlacement="bottom"
                                                  />
                                                </RadioGroup>
                                              </div>
                                            </Box>
                                          )}
                                          {item?.business_attribute_values?.type === 'Thumbs' && (
                                            <div className="radio-group-container">
                                              <Box className="text-right">
                                                <RadioGroup
                                                  aria-labelledby="demo-radio-buttons-group-label"
                                                  className="mx-auto w-full block"
                                                  row
                                                  defaultValue="1"
                                                  name={item?.bAttribute}
                                                  onChange={(event) => onHandleReviews(event, item)}
                                                  value={(e) => likeValue(e)}
                                                >
                                                  <FormControlLabel
                                                    className="relative m-4"
                                                    value={1}
                                                    control={
                                                      <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                                    }
                                                    label={
                                                      <code>
                                                        {parseInt(
                                                          likeValue
                                                            .filter((attribute) => {
                                                              return (
                                                                attribute?.name === item?.bAttribute
                                                              );
                                                            })
                                                            .map((attributeValue) => {
                                                              return attributeValue.review;
                                                            }),
                                                          10
                                                        ) === 1 ? (
                                                          <img
                                                            src="/assets/images/icon/dislike-active.svg"
                                                            alt="no"
                                                            className="w-20"
                                                          />
                                                        ) : (
                                                          <img
                                                            src="/assets/images/icon/dislike-deactive.svg"
                                                            className="opacity-50 w-20"
                                                            alt="no"
                                                          />
                                                        )}
                                                      </code>
                                                    }
                                                    labelPlacement="bottom"
                                                  />
                                                  <FormControlLabel
                                                    className="relative m-4"
                                                    value={2}
                                                    control={
                                                      <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                                    }
                                                    label={
                                                      <code>
                                                        {parseInt(
                                                          likeValue
                                                            .filter((attribute) => {
                                                              return (
                                                                attribute?.name === item?.bAttribute
                                                              );
                                                            })
                                                            .map((attributeValue) => {
                                                              return attributeValue.review;
                                                            }),
                                                          10
                                                        ) === 2 ? (
                                                          <img
                                                            src="/assets/images/icon/like-active.svg"
                                                            alt="yes"
                                                            className="w-20"
                                                          />
                                                        ) : (
                                                          <img
                                                            src="/assets/images/icon/like-deactive.svg"
                                                            alt="yes"
                                                            className="opacity-50 w-20"
                                                          />
                                                        )}
                                                      </code>
                                                    }
                                                    labelPlacement="bottom"
                                                  />
                                                </RadioGroup>
                                              </Box>
                                            </div>
                                          )}
                                          {item?.business_attribute_values?.type === 'Stars' && (
                                            <>
                                              {businessDetails?.reviewModeTypeId === 2 ? (
                                                <Box className="text-center">
                                                  <RadioGroup
                                                    aria-labelledby={item?.bAttribute}
                                                    className="mx-auto w-full block text-center"
                                                    row
                                                    defaultValue="0"
                                                    name={item?.bAttribute}
                                                    value={(e) => starValue(e)}
                                                    onChange={(event) =>
                                                      onHandleReviews(event, item)
                                                    }
                                                  >
                                                    <FormControlLabel
                                                      className="relative m-4"
                                                      value={1}
                                                      control={
                                                        <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                                      }
                                                      label={
                                                        <code>
                                                          {parseInt(
                                                            starValue
                                                              .filter((attribute) => {
                                                                return (
                                                                  attribute?.name ===
                                                                  item?.bAttribute
                                                                );
                                                              })
                                                              .map((attributeValue) => {
                                                                return attributeValue.review;
                                                              }),
                                                            10
                                                          ) === 1 ? (
                                                            <img
                                                              src="/assets/images/icon/reviews/colorful/starcolor1.svg"
                                                              alt="1"
                                                              className="w-20"
                                                            />
                                                          ) : (
                                                            <img
                                                              src="/assets/images/icon/reviews/grey/star1.svg"
                                                              alt="1"
                                                              className="w-20"
                                                            />
                                                          )}
                                                        </code>
                                                      }
                                                      labelPlacement="bottom"
                                                    />
                                                    <FormControlLabel
                                                      className="relative m-4"
                                                      value={2}
                                                      control={
                                                        <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                                      }
                                                      label={
                                                        <code>
                                                          {parseInt(
                                                            starValue
                                                              .filter((attribute) => {
                                                                return (
                                                                  attribute?.name ===
                                                                  item?.bAttribute
                                                                );
                                                              })
                                                              .map((attributeValue) => {
                                                                return attributeValue.review;
                                                              }),
                                                            10
                                                          ) === 2 ? (
                                                            <img
                                                              src="/assets/images/icon/reviews/colorful/starcolor2.svg"
                                                              alt="2"
                                                              className="w-20"
                                                            />
                                                          ) : (
                                                            <img
                                                              src="/assets/images/icon/reviews/grey/star2.svg"
                                                              alt="2"
                                                              className="w-20"
                                                            />
                                                          )}
                                                        </code>
                                                      }
                                                      labelPlacement="bottom"
                                                    />
                                                    <FormControlLabel
                                                      className="relative m-4"
                                                      value={3}
                                                      control={
                                                        <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                                      }
                                                      label={
                                                        <code>
                                                          {parseInt(
                                                            starValue
                                                              .filter((attribute) => {
                                                                return (
                                                                  attribute?.name ===
                                                                  item?.bAttribute
                                                                );
                                                              })
                                                              .map((attributeValue) => {
                                                                return attributeValue.review;
                                                              }),
                                                            10
                                                          ) === 3 ? (
                                                            <img
                                                              src="/assets/images/icon/reviews/colorful/starcolor3.svg"
                                                              alt="3"
                                                              className="w-20"
                                                            />
                                                          ) : (
                                                            <img
                                                              src="/assets/images/icon/reviews/grey/star3.svg"
                                                              alt="3"
                                                              className="w-20"
                                                            />
                                                          )}
                                                        </code>
                                                      }
                                                      labelPlacement="bottom"
                                                    />

                                                    <FormControlLabel
                                                      className="relative m-4"
                                                      value={4}
                                                      control={
                                                        <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                                      }
                                                      label={
                                                        <code>
                                                          {parseInt(
                                                            starValue
                                                              .filter((attribute) => {
                                                                return (
                                                                  attribute?.name ===
                                                                  item?.bAttribute
                                                                );
                                                              })
                                                              .map((attributeValue) => {
                                                                return attributeValue.review;
                                                              }),
                                                            10
                                                          ) === 4 ? (
                                                            <img
                                                              src="/assets/images/icon/reviews/colorful/starcolor4.svg"
                                                              alt="1"
                                                              className="w-20"
                                                            />
                                                          ) : (
                                                            <img
                                                              src="/assets/images/icon/reviews/grey/star4.svg"
                                                              alt="1"
                                                              className="w-20"
                                                            />
                                                          )}
                                                        </code>
                                                      }
                                                      labelPlacement="bottom"
                                                    />

                                                    <FormControlLabel
                                                      className="relative m-4"
                                                      value={5}
                                                      control={
                                                        <Radio className="absolute w-full h-full left-0 top-0 opacity-0" />
                                                      }
                                                      label={
                                                        <code>
                                                          {parseInt(
                                                            starValue
                                                              .filter((attribute) => {
                                                                return (
                                                                  attribute?.name ===
                                                                  item?.bAttribute
                                                                );
                                                              })
                                                              .map((attributeValue) => {
                                                                return attributeValue.review;
                                                              }),
                                                            10
                                                          ) === 5 ? (
                                                            <img
                                                              src="/assets/images/icon/reviews/colorful/starcolor5.svg"
                                                              alt="5"
                                                              className="w-20"
                                                            />
                                                          ) : (
                                                            <img
                                                              src="/assets/images/icon/reviews/grey/star5.svg"
                                                              alt="5"
                                                              className="w-20"
                                                            />
                                                          )}
                                                        </code>
                                                      }
                                                      labelPlacement="bottom"
                                                    />
                                                  </RadioGroup>
                                                </Box>
                                              ) : (
                                                <div className="text-right">
                                                  <Rating
                                                    name="star rating"
                                                    value={parseInt(
                                                      starValue
                                                        .filter((attribute) => {
                                                          return (
                                                            attribute?.name === item?.bAttribute
                                                          );
                                                        })
                                                        .map((attributeValue) => {
                                                          return attributeValue.review;
                                                        }),
                                                      10
                                                    )}
                                                    onChange={(event) =>
                                                      onHandleReviews(event, item)
                                                    }
                                                    icon={
                                                      <StarIcon
                                                        style={{
                                                          width: '20px',
                                                          height: '20px',
                                                        }}
                                                        fontSize="inherit"
                                                      />
                                                    }
                                                    emptyIcon={
                                                      <StarIcon
                                                        style={{
                                                          opacity: 1,
                                                          color: '#EEEEEE',
                                                          width: '20px',
                                                          height: '20px',
                                                        }}
                                                        fontSize="20px"
                                                      />
                                                    }
                                                  />
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </div>
                                      )
                                    )}
                                </Box>
                              </div>
                            </Collapse>

                            <form
                              name="reviewForm"
                              noValidate
                              className="flex flex-col justify-center w-full review-form rounded-md"
                              onSubmit={handleSubmit(handleFormSubmit)}
                            >
                              <Controller
                                name="review"
                                className="border-none text-center"
                                control={control}
                                render={({ field }) => (
                                  <div
                                    className="relative mx-auto"
                                    style={{
                                      width: '100%',
                                      maxWidth: '550px',
                                    }}
                                  >
                                    <div>
                                      <div>
                                        <TextField
                                          {...field}
                                          className="bg-grey-50 border-1 border-solid border-grey-300 rounded-md qr-textarea pb-72"
                                          style={{
                                            width: '100%',
                                            maxWidth: '550px',
                                            backgroundColor: '#F7F8FA',
                                          }}
                                          InputProps={{
                                            startAdornment: (
                                              <div className="absolute top-[12%] items-center gap-10 flex">
                                                {rotateIcon && (
                                                  <>
                                                    <div>Thinking</div>
                                                    <img
                                                      className="w-32 h-28"
                                                      src="/assets/images/icon/regenerateText.gif"
                                                      alt=""
                                                    />
                                                  </>
                                                )}
                                              </div>
                                            ),
                                          }}
                                          type="text"
                                          placeholder={
                                            rotateIcon ? '' : t('qrScanner.provideReview')
                                          }
                                          value={textValue}
                                          onChange={(e) => {
                                            handleSetReviewText(e.target.value);
                                          }}
                                          onKeyUp={(e) => {
                                            handleOnChangeText(e.target.value);
                                          }}
                                          multiline
                                          minRows={6}
                                          error={!!errors.review}
                                          helperText={errors?.review?.message}
                                          variant="outlined"
                                          required
                                          fullWidth
                                        />
                                        {autoGenerateButton && autoGenerateRemainingCount > 0 && (
                                          <>
                                            {businessDetails?.isAutoTextGenerate &&
                                              (ratingValue.length > 0 ||
                                                facesValue.length > 0 ||
                                                starValue.length > 0 ||
                                                likeValue.length > 0) &&
                                              !reviewIsTyped &&
                                              !liveStreaming &&
                                              !rotateIcon && (
                                                <Button
                                                  className="group px-6 py-3 font-medium text-gray-900  justify-evenly mx-auto left-0 max-w-[190px] absolute bottom-96 mb-0 right-5 text-14 rounded-md"
                                                  onClick={handleReview}
                                                >
                                                  <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-purple-500 to-cyan-500 rounded-lg">
                                                    -
                                                  </span>
                                                  <span className="absolute inset-[2px] bg-grey-50 rounded-md">
                                                    -
                                                  </span>
                                                  <span className="relative flex items-center gap-x-8 ">
                                                    <img
                                                      src="/assets/images/icon/magic.svg"
                                                      alt="magic"
                                                      width="25px"
                                                      height="25px"
                                                    />
                                                    {reviewGenerateStatus
                                                      ? t('qrScanner.reGenerateAiText')
                                                      : t('qrScanner.generateAiText')}
                                                  </span>
                                                </Button>
                                              )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        width: '100%',
                                        maxWidth: '550px',
                                      }}
                                      className=" text-left mx-auto pt-24"
                                    >
                                      <strong>{t('common.note')}</strong>{' '}
                                      <p className="text-red">{t('qrScanner.labels.textCopied')}</p>
                                    </div>
                                  </div>
                                )}
                              />

                              <div className="modal-container">
                                {isOpenModel && (
                                  <div
                                    className="modal-overlay"
                                    onKeyDown={closeModal}
                                    role="button"
                                    tabIndex={0}
                                    onClick={closeModal}
                                  >
                                    <div
                                      className="modal-content relative"
                                      onKeyDown={(e) => e.stopPropagation()}
                                      role="button"
                                      tabIndex={0}
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <Button
                                        className="close-button px-0 min-w-0"
                                        onClick={closeModal}
                                      >
                                        <Icon className="text-16 text-grey-600">close</Icon>
                                      </Button>
                                      <img
                                        src="/assets/images/icon/End_User_Giving_Reviews_UI.gif"
                                        alt="GIF"
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div className="flex items-center justify-center z-0">
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  className="whitespace-nowrap rounded-md px-32 py-8 text-center bg-darkgreen text-white font-semibold text-18 mt-24 mx-12 disabled:text-white"
                                  aria-label="Reset"
                                  onClick={
                                    businessDetails?.review_platforms?.name !== 'google'
                                      ? handleSkipAndContinue
                                      : handleSkipAndContinue
                                  }
                                  disabled={
                                    (businessDetails?.isOnlineBusiness && !reviewerName) ||
                                    refreshIcon ||
                                    liveStreaming ||
                                    !textValue ||
                                    !errorMessages
                                  }
                                  type="submit"
                                >
                                  {businessDetails?.isOnlineBusiness && 'Submit'}
                                  {!businessDetails?.isOnlineBusiness && (
                                    <>
                                      {t('qrScanner.button.submit')}{' '}
                                      {businessDetails?.review_platforms?.name}
                                    </>
                                  )}
                                  {refreshIcon && (
                                    <CircularProgress
                                      size={24}
                                      className="text-darkgreen absolute mx-auto"
                                    />
                                  )}
                                </Button>
                              </div>
                            </form>
                          </div>
                        )}

                        {overAllRatingSuccess && (
                          <div className="bg-white md:p-24 p-16 rounded-md mb-16">
                            <h3 className="text-24 font-semibold mb-20">
                              {t('qrScanner.onlineBusiness.thank')}
                            </h3>
                            <img
                              src="/assets/images/icon/thankyou.png"
                              alt="..."
                              className="mx-auto mb-40 mt-40"
                            />

                            <Typography className="font-medium mb-40">
                              {t('qrScanner.onlineBusiness.thankDesc')}
                            </Typography>
                          </div>
                        )}

                        {!overAllRatingSuccess && (
                          <>
                            {askCallScreen && !requestCallScreen && (
                              <div className="bg-white md:p-24 p-16 rounded-md mb-16">
                                <img
                                  src="/assets/images/icon/apologize_icon.png"
                                  alt="..."
                                  className="mx-auto mb-40 mt-40"
                                />
                                <h3 className="text-18 font-semibold mb-14">
                                  {t('qrScanner.labels.inconvenience')}
                                </h3>
                                <Typography className="font-medium mb-40">
                                  {t('qrScanner.labels.inconvenienceDesc')}
                                </Typography>

                                <Button
                                  variant="contained"
                                  color="secondary"
                                  className="rounded-md mx-auto block px-40 text-16 font-semibold block w-full"
                                  onClick={() => setRequestCallScreen(true)}
                                >
                                  {t('qrScanner.labels.request')}
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  className="rounded-md mx-auto block px-40 text-16 font-semibold block w-full mt-24"
                                  onClick={() => {
                                    businessDetails?.isOnlineBusiness ||
                                    businessDetails?.isNegativeReviewsPrevention
                                      ? setOverAllRatingNextSuccess(true)
                                      : history.push(businessDetails?.platFormURL);
                                  }}
                                >
                                  {(businessDetails?.isOnlineBusiness ||
                                    businessDetails?.isNegativeReviewsPrevention) && (
                                    <>{t('qrScanner.button.submitReview')}</>
                                  )}
                                  {!businessDetails?.isOnlineBusiness &&
                                    !businessDetails?.isNegativeReviewsPrevention && (
                                      <>
                                        {t('qrScanner.labels.share')}{' '}
                                        <span className="capitalize">
                                          {businessDetails?.review_platforms?.name}
                                        </span>
                                      </>
                                    )}
                                </Button>
                              </div>
                            )}
                          </>
                        )}
                        {requestCallScreen && !requestDone && (
                          <div className="bg-white md:p-24 p-16 rounded-md mb-16 text-left">
                            <h4 className="text-center text-18 font-semibold mb-10">
                              {t('qrScanner.requestCall.title')}
                            </h4>
                            <Typography className="text-center text-14 mb-24">
                              {t('qrScanner.requestCall.desc')}
                            </Typography>
                            <form
                              noValidate
                              // onSubmit={handleSubmitCall(onSubmitCall)}
                              className="flex flex-col md:overflow-hidden"
                              name="requestCallForm"
                            >
                              <div className="block mb-24">
                                <InputLabel
                                  htmlFor="name"
                                  className="text-16 font-medium mb-12 text-grey-900"
                                >
                                  {t('common.name')}
                                </InputLabel>
                                <Controller
                                  control={controlCall}
                                  name="name"
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      size="small"
                                      id="name"
                                      error={!!errorsCall.name}
                                      helperText={errorsCall?.name?.message}
                                      value={requestCallName}
                                      onChange={(e) => setRequestCallName(e?.target?.value)}
                                      variant="outlined"
                                      required
                                      fullWidth
                                    />
                                  )}
                                />
                              </div>
                              <div className="block mb-24">
                                <InputLabel
                                  htmlFor="phoneNumber"
                                  className="text-16 font-medium mb-12 text-grey-900"
                                >
                                  {t('qrScanner.requestCall.form.phoneNumber.label')}
                                </InputLabel>
                                {/* <Controller
                                control={controlCall}
                                name="phoneNumber"
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    size="small"
                                    id="phoneNumber"
                                    error={!!errorsCall.phoneNumber}
                                    helperText={errorsCall?.phoneNumber?.message}
                                    variant="outlined"
                                    required
                                    fullWidth
                                  />
                                )}
                              /> */}
                                <div className="flex flex-col justify-center">
                                  <PhoneInput
                                    country={currentCountry}
                                    value={phoneValue}
                                    enableSearch="true"
                                    variant="filled"
                                    onChange={(value, data) => {
                                      handlePhoneChange(value, data);
                                      const validPhoneNumber = handlePhoneNumberValidationCheck(
                                        value,
                                        data
                                      );
                                      setErrorMessages(validPhoneNumber);
                                    }}
                                    className="w-full scanner-phone"
                                    inputStyle={{
                                      width: '100%',
                                      height: '40px',
                                      backgroundColor: '#F4F3F4',
                                    }}
                                  />
                                  {errorMessages === false && phoneValue?.length > 0 && (
                                    <p className="text-red mt-5 text-16 text-start">
                                      {t('productSettings.InvalidPhoneNumber')}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="block mb-24">
                                <InputLabel
                                  htmlFor="reason"
                                  className="text-16 font-medium mb-12 text-grey-900"
                                >
                                  {t('qrScanner.requestCall.form.reason.label')} (optional)
                                </InputLabel>
                                <Controller
                                  control={controlCall}
                                  name="reason"
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      size="small"
                                      id="reason"
                                      variant="outlined"
                                      value={requestCallReason}
                                      onChange={(e) => setRequestCallReason(e?.target?.value)}
                                      fullWidth
                                    />
                                  )}
                                />
                              </div>

                              <Button
                                className="md:text-16 font-semibold disabled:text-black rounded-md"
                                variant="contained"
                                color="secondary"
                                type="button"
                                onClick={() => onSubmitCall()}
                                disabled={
                                  !requestCallName ||
                                  !phoneNumber ||
                                  !errorMessages ||
                                  phoneNumber?.length === 0
                                }
                              >
                                {t('qrScanner.requestCall.title')}
                                {requestCallRefreshIcon && (
                                  <CircularProgress
                                    size={24}
                                    className="text-darkgreen absolute mx-auto"
                                  />
                                )}
                              </Button>
                            </form>
                          </div>
                        )}

                        {requestDone && (
                          <div className="bg-white md:p-24 p-16 rounded-md mb-16">
                            <img
                              src="/assets/images/icon/done_icon.png"
                              alt="..."
                              className="mx-auto mb-40 mt-40"
                            />
                            <h3 className="text-18 font-semibold mb-20">
                              {t('qrScanner.requestCall.done')}
                            </h3>
                            <Typography className="font-medium mb-40">
                              {t('qrScanner.requestCall.doneDesc')}
                            </Typography>
                            <Button
                              variant="contained"
                              color="secondary"
                              className="rounded-md mx-auto block px-40 text-16 font-semibold block w-full mt-24"
                              onClick={() => {
                                setNextScreen(false);
                                window.location.reload();
                                dispatch(resetqrScanner());
                              }}
                            >
                              {t('qrScanner.requestCall.doneButton')}
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {errorMessage && (
        <div className="flex items-center h-screen mx-10 sm:mx-0">
          <div className="mx-auto">
            <img src="/assets/images/icon/error.png" alt="..." className="mx-auto mb-40" />
            <h1 className="text-16 md:text-20 font-semibold tracking-tight leading-tight md:leading-none text-center mb-16 text-darkgreen">
              {t('qrScanner.labels.oops404')}
            </h1>
            <h5 className="mt-8 text-14 md:text-16 font-medium text-center max-w-480">
              {t('qrScanner.labels.trialExpiredMsg')}
            </h5>
            <div className="text-center text-16 mt-10">
              Call us:
              <span className="font-semibold ml-6">
                {currentCountry === 'in' || currentCountry === 'IN'
                  ? process.env.REACT_APP_INDIA_MOBILE_NUMBER
                  : process.env.REACT_APP_USA_MOBILE_NUMBER}
              </span>
              <span className="block mt-6 mb-6">
                Email Us:
                <span className="font-semibold ml-6">
                  <a href={`mailto:${process.env.REACT_APP_EMIL}`}>{process.env.REACT_APP_EMIL}</a>
                </span>
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default withReducer('qrScannerReducer', reducer)(QrScanner);
