import { Box, Button, Card, CardContent, IconButton, Typography } from '@mui/material';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import FuseLoading from '@fuse/core/FuseLoading';
import quickChatQuery from '../../query/quickChat';
import { handleApiRequest } from '../../common/common';

const AddBotOnWebsite = (props) => {
  const { userBusinessesId } = props;
  const [copy, setCopy] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chatbotCode, setChatbotCode] = useState([]);

  const fetchDashboardData = async (id) => {
    setIsLoading(true);
    const fetchPayload = (query, variables) => ({
      query,
      variables: { ...variables },
    });

    const requests = [
      handleApiRequest(
        fetchPayload(quickChatQuery?.getChatBotIntegrationCode, { businessId: id })
      ).catch((err) => ({ error: err })),
    ];

    try {
      const results = await Promise.all(requests);

      const [getChatBotIntegrationCode] = results.map((result) => (result?.error ? null : result));

      if (getChatBotIntegrationCode) {
        setIsLoading(false);
        setChatbotCode(getChatBotIntegrationCode?.getChatBotIntegrationCode?.data?.code);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching dashboard data', error);
    }
  };

  const handleCopyClick = async (id) => {
    const textToCopy = document?.getElementById(id)?.innerText;

    navigator?.clipboard?.writeText('')?.then(() => {
      setCopy(true);
      return navigator?.clipboard?.writeText(textToCopy);
    });
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  useEffect(() => {
    fetchDashboardData(userBusinessesId);
  }, [userBusinessesId]);

  return (
    <>
      {isLoading ? (
        <div>
          <FuseLoading />
        </div>
      ) : (
        <div className="max-w-586">
          <Typography variant="h5" className="text-gray-900 font-semibold mb-24">
            {t('quickChat.addBotWebsite.howToAddYourChatbotOnWebsite')}
          </Typography>

          {['Copy the Chatbot Code', 'Insert the Code on Your Website', 'Save & Publish'].map(
            (title, index) => (
              <Box key={index} className="flex items-start mb-16">
                <Box className="bg-gray-800 text-white w-28 h-28 flex items-center justify-center rounded-full   mr-4">
                  <Typography variant="body2" className="text-14 font-bold">
                    {index + 1}
                  </Typography>
                </Box>

                <Box className="flex-1 bg-white rounded-lg p-16 ml-8  ">
                  <Typography variant="h6" className="text-16 sm:text-20 font-500">
                    {t(title)}
                  </Typography>
                  {index === 0 ? (
                    <>
                      <Typography variant="body1" className="text-gray-600 mt-1">
                        {t('quickChat.addBotWebsite.copyTheProvidedEmbedScript')}
                      </Typography>

                      <Box className="bg-gray-A500 p-7 rounded-md mt-3 justify-between items-center">
                        <Typography
                          variant="body2"
                          id="domain"
                          className="text-gray-700 max-h-112 overflow-y-scroll px-5 mb-5"
                        >
                          {chatbotCode}
                        </Typography>
                        <div className="flex justify-center pb-4 pt-9">
                          <Button
                            className="rounded-4 px-36 flex items-center gap-6"
                            variant="contained"
                            color="quickChat"
                            type="button"
                            onClick={() => handleCopyClick('domain')}
                          >
                            <ContentCopyRoundedIcon className="text-16" />
                            <Typography variant="subtitle1" className="text-16">
                              {copy
                                ? `${t('business.selectedBusinessDetail.labels.copied')} !`
                                : t('quickChat.chatbotDetail.copy')}
                            </Typography>
                          </Button>
                        </div>
                      </Box>
                    </>
                  ) : index === 1 ? (
                    <Typography variant="body2" className="text-gray-600 mt-1">
                      {t('quickChat.addBotWebsite.pasteTheHTMLCodeBeforeTheBodyTag')}
                    </Typography>
                  ) : (
                    <Typography variant="body2" className="text-gray-600 mt-1">
                      {t('quickChat.addBotWebsite.saveChangesAndPublish')}
                    </Typography>
                  )}
                </Box>
              </Box>
            )
          )}
        </div>
      )}
    </>
  );
};

export default AddBotOnWebsite;
