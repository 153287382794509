import { Button, Dialog, DialogTitle, Icon, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';
import { handleApiRequest } from '../../../common/common';
import quickChatQuery from '../../../query/quickChat';

const BusinessNotVerify = (props) => {
  const { businessNotVerify, setBusinessNotVerify } = props;
  const [botDetails, setBotDetails] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (userCurrentBusinessDetails?.id) {
          const payloadData = {
            query: quickChatQuery.getQuickChatDetails,
            variables: {
              businessId: Number(userCurrentBusinessDetails?.id),
            },
          };
          const resultData = await handleApiRequest(payloadData);
          if (resultData?.getQuickChatDetails?.status === 200) {
            setBotDetails(resultData?.getQuickChatDetails?.data);
          }
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };
    fetchDetails();
  }, [userCurrentBusinessDetails?.id]);

  return (
    <Dialog
      classes={{
        paper: 'm-24 rounded-md',
      }}
      open={businessNotVerify}
      maxWidth="sm"
    >
      <DialogTitle className="p-0 py-16 px-20">
        <Icon
          onClick={() => setBusinessNotVerify(false)}
          className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
        >
          cancel
        </Icon>
      </DialogTitle>
      <div className="w-full p-20">
        <img className="m-auto" src="assets/images/business/business.svg" alt="" />
        <Typography className="font-bold text-24 text-center py-16">
          {t('waba.businessNotVerify')}
        </Typography>
        <Typography className="font-medium text-14 text-center sm:px-40">
          {t('waba.businessVerifyMessage')}
        </Typography>
        <div className="flex justify-center gap-12 my-20">
          <Button
            className="rounded-md w-160"
            variant="outlined"
            color="quickChat"
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_PORTAL_URL}/quickchat/chat-assistant/${botDetails?.domain}`,
                '_blank'
              )
            }
          >
            {t('waba.getHelp')}
          </Button>
          <Button
            className="rounded-md w-160"
            variant="contained"
            color="quickChat"
            onClick={() => window.open('https://business.facebook.com/', '_blank')}
          >
            {t('waba.verifyNow')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default BusinessNotVerify;
