import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { t } from 'i18next';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { Typography, Icon, Button } from '@mui/material';
import quickHub from '../../../query/quickHub';
import { getEncryptedData, handleApiRequest } from '../../../common/common';

const StripePayment = ({
  setStripePaymentSuccess,
  setPaymentMethodId,
  totalAmount,
  setAddCardDetails,
  from,
  setOpenDrawer,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage('');

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        console.error('[Error]', error);
      } else {
        const paymentMethoData = JSON.stringify({
          paymentMethodId: paymentMethod.id,
        });
        if (from !== 'add new card') {
          setPaymentMethodId(paymentMethod.id);
        }
        const payload = {
          query: quickHub.addCardInStripe,
          variables: {
            data: getEncryptedData(paymentMethoData),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.addCardInStripe?.status === 200) {
          if (from !== 'add new card') {
            setStripePaymentSuccess(true);
            setAddCardDetails(false);
          } else {
            enqueueSnackbar(result?.addCardInStripe?.message, {
              variant: 'success',
              autoHideDuration: 2000,
            });

            setOpenDrawer(false);
          }
        } else {
          enqueueSnackbar(result?.addCardInStripe?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
          setLoading(false);
        }
        return;
      }
    } catch (error) {
      setMessage('Payment failed. Please try again.');
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="">
      <h2 className="text-18 font-semibold mb-6">
        {' '}
        {from !== 'add new card' && (
          <Icon
            className="text-20 cursor-pointer inline-block"
            onClick={() => setAddCardDetails(false)}
          >
            west
          </Icon>
        )}
        {from !== 'add new card' ? ` ${t('payment.addPayment')}` : `${t('plan.addCard')}`}
      </h2>
      {from !== 'add new card' && (
        <Typography className="text-14 font-medium mb-16 leading-none block">
          {t('payment.paymentDesc')}
        </Typography>
      )}
      <div className=" mx-auto p-14 bg-white border border-solid rounded-lg max-w-full mt-16">
        <CardElement className="p-3 border rounded h-36 bg-gray-A500 pt-10 pl-10 mt-0" />

        <Typography className="text-12 text-grey-500 font-medium mt-16">
          {t('payment.paySecure')}
        </Typography>
        <Typography className="text-12 text-grey-500 font-medium mt-16 flex">
          <Icon className="mr-4 text-16 inline-block">lock</Icon>
          <span className="inline-block">{t('payment.payInfo')}</span>
        </Typography>
        {message && <p className="mt-4 text-red-500">{message}</p>}
      </div>
      <div className="text-right">
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={!stripe || loading}
          className="rounded-md mt-16"
        >
          {from !== 'add new card'
            ? loading
              ? 'Processing...'
              : `Pay $${totalAmount}`
            : `${t('plan.addNewCardDetails')}`}
        </Button>
      </div>
    </form>
  );
};

export default StripePayment;
