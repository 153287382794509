import AutoReply from './AutoReply';
import AutoReplyOnReviews from './AutoReplyOnReviews';

const AutoReplyConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'quick-reviews/autoReply/:id?',
      element: <AutoReply />,
    },
    {
      path: 'quick-reviews/AutoReplyOnReviews/:id?',
      element: <AutoReplyOnReviews />,
    },
  ],
};

export default AutoReplyConfig;
