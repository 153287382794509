import { Dialog, DialogTitle, Icon, Typography, Button, Tabs, Tab, Checkbox } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import { t } from 'i18next';
import FuseLoading from '@fuse/core/FuseLoading';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactPlayer from 'react-player';
import { ReactComponent as DeleteIcon } from 'src/app/main/image/delete.svg';
import { ReactComponent as DownloadIcon } from 'src/app/main/image/download.svg';
import ConformationDialogBox from 'src/app/main/CommonComponent/ConformationDialogBox';
import { useSnackbar } from 'notistack';
import wabaQuery from 'src/app/main/query/waba';
import { handleWabaApiRequest, uploadImageToServer } from 'src/app/main/common/common';

const MediaList = (props) => {
  const { openValue, cancelCall, popUpMessage, onMediaSelected, selectedMedia, broadcast } = props;
  const [dialogOpen, setDialogOpen] = useState(openValue);
  const { enqueueSnackbar } = useSnackbar();
  const [mediaList, setMediaList] = useState([]);
  const [mediaCount, setMediaCount] = useState();
  const [loading, setLoading] = useState(false);
  const [searchValueMedia, setSearchValueMedia] = useState('');
  const [tabValue, setTabValue] = useState('image');
  const [isHovered, setIsHovered] = useState(null);
  const [selectedMedias, setSelectedMedias] = useState(selectedMedia || []);
  const [openDialogueForConformation, setOpenDialogueForConformation] = useState(false);
  const toggleMediaSelection = (media) => {
    setSelectedMedias((prevSelected) => {
      const isAlreadySelected = prevSelected.some((item) => item.id === media.id);

      return isAlreadySelected
        ? prevSelected.filter((item) => item.id !== media.id)
        : [...prevSelected, media];
    });
  };
  const [singleDelete, setSingleDelete] = useState();

  const handleMedia = useCallback(async () => {
    try {
      const payload = {
        query: wabaQuery.getWaUserMedia,
        variables: {
          fileType: tabValue,
          searchText: searchValueMedia,
        },
      };
      const res = await handleWabaApiRequest(payload);
      if (res?.getWaUserMedia?.status === 200) {
        setMediaList(res?.getWaUserMedia?.data);
        setMediaCount(res?.getWaUserMedia?.allMediaCount);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [searchValueMedia, tabValue]);

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    const fileTypeMap = {
      image: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/webp'],
      audio: ['audio/mpeg', 'audio/wav', 'audio/ogg', 'audio/mp3'],
      video: ['video/mp4', 'video/mpeg', 'video/webm', 'video/ogg'],
      file: [
        'application/pdf',
        'application/msword',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ],
    };
    if (fileTypeMap[tabValue]?.includes(selectedFile.type)) {
      const payload = {
        query: wabaQuery.addWaUserMedia,
        variables: {
          file: selectedFile,
          fileType: tabValue,
        },
      };
      const result = await uploadImageToServer(selectedFile, payload, 'file');
      if (result?.addWaUserMedia?.status === 200) {
        handleMedia();
        enqueueSnackbar(result?.addWaUserMedia?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
    } else {
      console.error(`Invalid file type for ${tabValue}`);
    }
  };

  const handleDelete = async (deleteId) => {
    try {
      const payloadData = {
        query: wabaQuery.deleteWaUserMedia,
        variables: {
          waUserMediaIds: (selectedMedias?.length > 0 &&
            selectedMedias?.map((item) => item?.id)) || [deleteId],
        },
      };
      const result = await handleWabaApiRequest(payloadData);
      if (result?.deleteWaUserMedia?.status === 200) {
        enqueueSnackbar(result?.deleteWaUserMedia?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setOpenDialogueForConformation(false);
        setSingleDelete();
        handleMedia();
      } else {
        enqueueSnackbar(result?.deleteWaUserMedia?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error', error);
    }
  };

  const handleSelectedData = async () => {
    try {
      onMediaSelected(selectedMedias);
      setDialogOpen(false);
    } catch (error) {
      console.error('Error', error);
    }
  };

  useEffect(() => {
    handleMedia();
  }, [handleMedia]);

  const handleClose = () => {
    setDialogOpen(false);
    cancelCall();
  };

  if (loading) {
    return <FuseLoading />;
  }

  const getAcceptedFileTypes = (tabData) => {
    const fileTypeMap = {
      image: 'image/*',
      video: 'video/*',
      audio: 'audio/*',
      file: '.pdf,.doc,.docx,.xls,.xlsx,.csv,.txt',
    };

    return fileTypeMap[tabData] || '*/*';
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDownload = (url, fileName) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = fileName || 'download'; // Default file name if not provided
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Dialog
      classes={{
        paper: 'm-24 max-w-[1089px] width-full rounded-md',
      }}
      open={openValue}
      onKeyDown={(event) => {
        if (event.keyCode === 27) {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="xs"
    >
      <div className="p-28 pb-0">
        <DialogTitle className="p-0 mb-16 flex items-center justify-between">
          <Typography className="text-24 font-semibold text-black w-full mb-0">
            {popUpMessage}
          </Typography>
          <Icon
            onClick={() => handleClose()}
            className="text-24 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            close
          </Icon>
        </DialogTitle>
        <div className="flex items-center justify-between mb-12">
          <div className="relative w-full max-w-512">
            <input
              type="text"
              className="px-10 py-2 rounded-md w-full h-40 bg-gray-A500 pl-40 text-16 text-grey-700"
              placeholder={t('waba.mediaLibrary.searchMedia')}
              onChange={(event) => setSearchValueMedia(event?.target?.value)}
              value={searchValueMedia}
            />
            <Icon
              aria-hidden
              className="absolute left-10 top-10 text-20 cursor-pointer text-gray-700"
            >
              search
            </Icon>
          </div>
          <div className="flex justify-center items-center">
            <label htmlFor="button-file">
              <input
                accept={getAcceptedFileTypes(tabValue)}
                name="button-file"
                className="hidden w-0 cursor-pointer"
                id="button-file"
                type="file"
                onChange={handleFileChange}
              />

              <Button
                className="rounded-md min-h-40 h-40 text-16 font-medium"
                variant="contained"
                color="quickChat"
                component="span"
              >
                <img
                  src="/assets/images/icon/upload.png"
                  alt="upload"
                  className="w-20 sm:w-20 h-20 sm:h-20 mr-6"
                />{' '}
                {`${t('waba.mediaLibrary.upload')} ${tabValue}`}
              </Button>
            </label>
            <Button
              className="rounded-md min-h-40 h-40 text-16 font-medium ml-12"
              variant="outlined"
              color="quickChat"
              onClick={() => setOpenDialogueForConformation(true)}
              disabled={!selectedMedias || selectedMedias.length === 0}
            >
              <DeleteIcon
                aria-hidden
                className={`${
                  !selectedMedias || (selectedMedias.length === 0 && '!stroke-gray-400')
                } w-20 h-20 hover:stroke-quick-chat group-hover:stroke-quick-chat stroke-quick-chat inline-block mr-6 cursor-pointer`}
              />{' '}
              {t('common.delete')}
            </Button>
          </div>
        </div>
        <div className="quick-chat">
          <Tabs
            value={tabValue}
            className="p-0 overflow-x-auto whitespace-nowrap"
            onChange={handleTabChange}
            textColor="quickChat"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              className={`md:text-18 text-14 font-medium px-0 min-w-0 ml-0 mx-48  ${
                tabValue === 'image' ? 'text-quick-chat' : 'text-black'
              }`}
              value="image"
              label={`${t('waba.mediaLibrary.tabs.tab1')} (${mediaCount?.image})`}
            />
            <Tab
              className={`md:text-18 text-14 font-medium px-0 min-w-0 mx-48 ${
                tabValue === 'video' ? 'text-quick-chat' : 'text-black'
              }`}
              value="video"
              label={`${t('waba.mediaLibrary.tabs.tab2')} (${mediaCount?.video})`}
            />
            {/* <Tab
          className={`md:text-18 text-14 font-medium px-0 min-w-0 mx-48 ${
            tabValue === 'audio' ? 'text-quick-chat' : 'text-black'
          }`}
          value="audio"
          label={`${t('waba.mediaLibrary.tabs.tab3')} (${mediaCount?.audio})`}
        /> */}
            <Tab
              className={`md:text-18 text-14 font-medium px-0 min-w-0 mr-0 mx-48 ${
                tabValue === 'file' ? 'text-quick-chat' : 'text-black'
              }`}
              value="file"
              label={`${t('waba.mediaLibrary.tabs.tab4')} (${mediaCount?.file})`}
            />
          </Tabs>
        </div>
      </div>

      <div className="w-full flex bg-gray-A500 py-20 px-28 pb-64">
        <div className="w-full">
          <div className="grid xl:grid-cols-6 lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-20">
            {mediaList &&
              mediaList?.length > 0 &&
              mediaList?.map((media, index) => (
                <div>
                  <div
                    key={index}
                    className=" text-14 font-medium cursor-pointer rounded-md border border-gray-200 relative"
                    onMouseEnter={() => setIsHovered(media?.id)}
                    onMouseLeave={() => setIsHovered(null)}
                    onClick={() => {
                      onMediaSelected(media);
                      cancelCall();
                    }}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        onMediaSelected(media);
                        cancelCall();
                      }
                    }}
                  >
                    {selectedMedias?.some((item) => item.id === media?.id) && (
                      <span className="w-20 h-20 rounded-md bg-quick-hub text-white absolute top-2 right-2 text-center pt-2">
                        <Icon className="text-16">check</Icon>
                      </span>
                    )}
                    {tabValue === 'image' && (
                      <img
                        src={media?.awsUrl}
                        className="img-fluid mx-auto rounded-md w-full max-h-144"
                        alt="..."
                      />
                    )}
                    {tabValue === 'video' && media?.awsUrl && (
                      <ReactPlayer url={media?.awsUrl} controls width="100%" height="100%" />
                    )}
                    {tabValue === 'file' && (
                      <span className="inline-block w-full h-144 bg-quick-chat rounded-md flex items-center">
                        <img src="/assets/images/icon/file.png" className="mx-auto" alt="..." />
                      </span>
                    )}
                    {isHovered === media?.id && (
                      <div className="absolute inset-0 bg-black/50 flex items-center justify-center gap-4 quick-chat rounded-md">
                        {!broadcast && (
                          <Checkbox
                            className="absolute top-2 right-2 w-5 h-5 cursor-pointer z-10 rounded-none border border-gray-300 bg-white"
                            checked={selectedMedias.some((item) => item.id === media?.id)}
                            onChange={() => toggleMediaSelection(media)}
                            onClick={(e) => e.stopPropagation()}
                          />
                        )}
                        <span className="bg-gray-A500 rounded-full w-24 h-24 text-center inline-block mx-8">
                          <DownloadIcon
                            aria-hidden
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDownload(media?.awsUrl, media?.fileData?.fileName);
                            }}
                            className="w-14 h-14 fill-black cursor-pointer mx-auto relative top-4"
                          />
                        </span>
                        <span className="bg-gray-A500 rounded-full w-24 h-24 text-center inline-block mx-8">
                          <DeleteIcon
                            aria-hidden
                            onClick={(e) => {
                              e.stopPropagation();
                              setOpenDialogueForConformation(true);
                              setSingleDelete(media?.id);
                            }}
                            className="w-14 h-14 stroke-black cursor-pointer mx-auto relative top-4"
                          />
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="block text-black mt-8 font-medium w-full overflow-hidden whitespace-nowrap text-ellipsis">
                    {media?.fileData?.fileName}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="text-right py-12 px-28">
        {!broadcast && (
          <Button
            className="rounded-md min-h-40 h-40 min-w-128 text-16 font-medium"
            variant="contained"
            color="quickChat"
            onClick={handleSelectedData}
          >
            {t('common.select')}
          </Button>
        )}
      </div>
      <ConformationDialogBox
        open={openDialogueForConformation}
        onClose={() => setOpenDialogueForConformation(false)}
        onDelete={() => handleDelete(singleDelete)}
        confirmationText="Are You Sure?"
        additionalInfo={`${t('waba.mediaLibrary.deleteConfirm')}`}
        deleteImage="/assets/images/icon/trashBin.png"
        backgroundColor="quick-chat"
        textColor="quick-chat"
        buttonText={t('common.confirm')}
      />
    </Dialog>
  );
};

export default MediaList;
