import React, { useCallback, useEffect, useState, useRef } from 'react';
import { t } from 'i18next';
import {
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  InputLabel,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import manageBusinessData from '../../query/manageBusiness';
import { handleApiRequest } from '../../common/common';

const AddDescription = ({
  suggestion,
  setSuggestion,
  description,
  setDescription,
  handleNext,
  passFrom,
  template,
}) => {
  const [isSuggestion, setIsSuggestion] = useState(false);
  const [isBtLoading, setIsBtLoading] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const debounceTimer = useRef(null);

  const handleRegenerate = useCallback(
    async (descRip) => {
      setIsBtLoading(true);
      try {
        const payload = {
          query: manageBusinessData.sharePostGenerateSummarizeReviewsV1,
          variables: {
            review: descRip,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.sharePostGenerateSummarizeReviewsV1?.status === 200) {
          setSelectedSuggestion(null);
          if (description) {
            setSuggestion(result?.sharePostGenerateSummarizeReviewsV1?.data);
            setIsSuggestion(true);
          }
        }
      } catch (error) {
        console.error('Error regenerating suggestion:', error);
      }
      setIsBtLoading(false);
    },
    [description, setSuggestion]
  );

  const handleRegenerate2 = () => {
    if (description.length > 0) {
      setIsSuggestion(true);
      handleRegenerate(description);
    }
  };

  const handleToggleCollapse = () => {
    setIsSuggestion((prev) => !prev);
    handleRegenerate(description);
  };

  const handleOnClickSug = (desc, index) => {
    setDescription(desc);
    setSelectedSuggestion(index);
  };

  const formatDescription = (text) => {
    if (!text) return '';
    const parts = text.split(' ');
    const normalText = parts.filter((word) => !word.startsWith('#')).join(' ');
    const hashtags = parts.filter((word) => word.startsWith('#')).join(' ');
    return normalText + (hashtags ? `\n${hashtags}` : '');
  };

  return (
    <div>
      <div className="bg-white p-20 rounded-md quick-social mt-16">
        <div className="mb-12">
          <InputLabel htmlFor="botAnswer" className="text-20 font-semibold mb-10 text-black ">
            {t('quickSocialPost.addDescription')}
          </InputLabel>
          <TextField
            placeholder={t('quickSocialPost.placeHolder')}
            variant="outlined"
            fullWidth
            value={formatDescription(description)}
            onChange={(e) => setDescription(e.target.value)}
            className="max-w-full text-14 bg-gray-A500 "
            color="quickChat"
            multiline
            rows={4}
            inputProps={{
              className: 'focus:ring-0 focus:outline-none',
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
              },
            }}
          />
        </div>
        <div>
          <div className="mb-24 ">
            <div className="flex mb-10 justify-between">
              {description?.length !== 0 && (
                <Button
                  className="relative md:text-16 p-5 max-w-[192px] w-192 rounded-md bg-white text-gray-900 gap-10 overflow-hidden"
                  size="small"
                  type="button"
                  onClick={handleRegenerate2}
                >
                  <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-purple-500 to-cyan-500 rounded-lg">
                    -
                  </span>
                  <span className="absolute inset-[2px] bg-grey-50 rounded-md"> </span>
                  <span className="relative z-10 flex items-center gap-10">
                    <span className="relative justify-center flex items-center gap-2">
                      <img
                        src="/assets/images/icon/magic.svg"
                        alt="magic"
                        width="25px"
                        height="25px"
                      />
                      {t('quickSocialPost.regenerate')}
                    </span>
                  </span>
                </Button>
              )}
            </div>
            <Collapse
              in={isSuggestion}
              timeout="auto"
              unmountOnExit
              className="bg-grey-200 p-12 rounded-12"
            >
              <div className="mb-12 flex items-center">
                <h3>{t('quickSocialPost.suggestedText')}</h3>
                <IconButton onClick={() => setIsSuggestion(false)} color="inherit">
                  <CloseIcon className="w-16 h-16 text-black" />
                </IconButton>
              </div>
              {isBtLoading ? (
                <div className="bg-white p-12 flex justify-start items-start  text-center mb-8 rounded-8 ">
                  <div className="text-gray-900 items-center text-center p-5">Thinking</div>
                  <img
                    className="w-32 h-28 ml-2 items-center"
                    src="/assets/images/icon/regenerateText.gif"
                    alt="Loading..."
                  />
                </div>
              ) : (
                <>
                  {suggestion?.length > 0 ? (
                    suggestion?.map((suggestions, index) => {
                      const words = suggestions.split(' ');
                      const text = words?.filter((word) => !word.startsWith('#')).join(' ');
                      const hashtags = words?.filter((word) => word.startsWith('#')).join(' ');

                      return (
                        <div
                          key={index}
                          className={`mb-8 rounded-8 p-12 cursor-pointer ${
                            selectedSuggestion === index
                              ? 'bg-quick-social text-white'
                              : 'bg-white hover:bg-quick-social-100'
                          }`}
                          onClick={() => handleOnClickSug(suggestions, index)}
                          role="button"
                          tabIndex={0}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              handleOnClickSug(suggestions, index);
                            }
                          }}
                        >
                          <p>{text}</p>
                          {hashtags && <p>{hashtags}</p>}
                        </div>
                      );
                    })
                  ) : (
                    <p className="bg-white mb-5 rounded-8 p-10">
                      {t('quickSocialPost.noSuggestions')}
                    </p>
                  )}
                </>
              )}
            </Collapse>
          </div>
        </div>
        {passFrom === 'Dashboard' && (
          <div className="flex justify-end">
            <Button
              className="md:text-18 font-semibold bg-quick-social border border-solid rounded-md hover:text-white items-center px-32 p-10"
              variant="contained"
              color="quickSocial"
              type="button"
              disabled={!template && description?.length === 0}
              onClick={() => {
                handleNext();
              }}
            >
              {t('quickSocialPost.next')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
export default AddDescription;
