import { Button, Paper, TextField, Typography } from '@mui/material';
import {
  DatePicker,
  LocalizationProvider,
  renderTimeViewClock,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PhoneInput from 'react-phone-input-2';
import userService from 'src/app/services';
import { convertTemplateToHtml } from 'src/app/main/common/common';
import { CancelBroadCast } from '../../common/Popup';
import SelectTemplate from '../selectTemplate/SelectTemplate';
import MediaList from '../../MediaList';

const BroadcastDetails = (props) => {
  const {
    template,
    setStepsCount,
    selectedVariables,
    setSelectedVariables,
    viewTemplate,
    setTemplate,
    setCampaignName,
    campaignName,
    setImageUrl,
    imageUrl,
  } = props;
  const [openCancelBroadcastDialogue, setCancelBroadcastDialogue] = useState(false);
  const [openSelectTemplateDialogue, setOpenSelectTemplateDialogue] = useState(false);
  const loginUserData = userService.getUserData();
  const [openMediaDialog, setOpenMediaDialog] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState([]);

  useEffect(() => {
    setImageUrl(selectedMedia?.awsUrl);
  }, [selectedMedia, setImageUrl]);

  const handleRemoveImage = () => {
    setImageUrl();
  };

  const handleInputChange = (index, value) => {
    setSelectedVariables((prev) =>
      prev?.map((item, i) => (i === index ? { ...item, value } : item))
    );
  };

  const handleMediaSelected = async (media) => {
    if (media) {
      setSelectedMedia(media);
      setOpenMediaDialog(false);
    }
  };

  return (
    <div className="mt-16">
      <Typography className="font-semibold text-20">
        {t('waba.broadcast.myBroadcast.campaignDetails')}
      </Typography>
      <div className="lg:max-w-[1200px] quick-chat mt-20 p-10 sm:p-20 bg-white rounded-md">
        <Typography className="font-semibold text-20">
          {t('waba.broadcast.myBroadcast.campaignName')}
        </Typography>
        <Typography className="font-semibold text-12 py-10 text-grey-A300">
          {t('waba.broadcast.myBroadcast.campaignNameMessage')}
        </Typography>
        <TextField
          className="w-full"
          onChange={(e) => setCampaignName(e.target.value)}
          value={campaignName}
          placeholder="Enter Campaign Name"
          InputProps={{
            style: { fontSize: '16px', fontWeight: 500 },
          }}
        />
      </div>
      <div className="flex flex-wrap justify-between w-full lg:max-w-[1200px] mt-16 gap-20">
        <div className="w-full lg:w-[720px]">
          <div className="bg-white p-10 sm:p-20 rounded-md w-full">
            <Typography className="font-semibold text-20">
              {t('waba.broadcast.myBroadcast.selectedTemplate')}
            </Typography>
            <div className="mt-12">
              <div className="flex items-center justify-between">
                <Typography className="font-medium text-16">
                  {t('waba.broadcast.myBroadcast.templateName')}
                </Typography>
                <Button
                  className="text-quick-chat font-semibold text-16 p-0 hover:bg-transparent"
                  onClick={() => setOpenSelectTemplateDialogue(true)}
                >
                  {t('common.edit')}
                </Button>
              </div>
              <TextField
                className="w-full pt-8"
                size="medium"
                value={template?.templateName || template?.templateData?.name}
                disabled
                InputProps={{
                  style: { fontSize: '16px', fontWeight: 500 },
                }}
              />
            </div>
            <div className="mt-16">
              {selectedVariables?.length > 0 && (
                <Typography className="font-semibold text-20 pb-16">
                  {t('waba.broadcast.myBroadcast.editTemplateVariables')}
                </Typography>
              )}
              {selectedVariables?.length > 0 &&
                selectedVariables?.map((item, index) => (
                  <div key={index} className="mt-12 quick-chat">
                    <Typography className="text-[#686868] text-16 font-medium mb-12">
                      {item.description}
                    </Typography>
                    {item.type === 'text' || item.type === 'int' ? (
                      <TextField
                        className="w-full"
                        value={item.value || ''}
                        onChange={(e) => handleInputChange(index, e.target.value)}
                        placeholder={`Enter ${item.description}`}
                        size="medium"
                        InputProps={{
                          style: { fontSize: '16px', fontWeight: 500 },
                        }}
                      />
                    ) : item.type === 'date' ? (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          className="w-full select-date mt-6"
                          value={item.value ? new Date(item.value) : null}
                          onChange={(newDate) => handleInputChange(index, newDate)}
                          renderInput={(params) => <TextField {...params} />}
                          disablePast
                        />
                      </LocalizationProvider>
                    ) : item.type === 'time' ? (
                      <TimePicker
                        className="w-full mt-6"
                        id="time"
                        onChange={(newTime) => handleInputChange(index, newTime)}
                        value={item.value ? new Date(item.value) : null}
                        size="medium"
                        required
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                      />
                    ) : (
                      item?.type === 'phoneNumber' && (
                        <PhoneInput
                          placeholder={t(
                            'manageBusiness.addBusinessSteps.addBusiness.form.phone.placeHolder'
                          )}
                          inputStyle={{
                            width: '100%',
                            height: '40px',
                            color: 'black',
                            fontWeight: '400',
                          }}
                          className="w-full "
                          name="phone_number"
                          required
                          size="small"
                          value={item.value?.toString()}
                          country={loginUserData?.isoCode.toLowerCase()}
                          enableSearch="true"
                          onChange={(phone, countryData) => {
                            handleInputChange(index, phone);
                          }}
                        />
                      )
                    )}
                  </div>
                ))}
            </div>
          </div>
          {template?.templateData?.components?.find(
            (item) => item?.type === 'HEADER' && item?.format === 'IMAGE'
          ) && (
            <div className="bg-white p-20 mt-16">
              <Typography className="font-semibold text-16">
                {t('waba.broadcast.myBroadcast.media')}
              </Typography>
              {imageUrl ? (
                <div className="my-12 relative">
                  <img className="rounded-md w-256  h-160" src={imageUrl} alt="" />
                  <CloseRoundedIcon
                    onClick={() => handleRemoveImage()}
                    className="text-18 absolute -top-8 left-[245px] bg-white rounded-full text-black font-normal cursor-pointer hover:text-gray-700 focus:outline-none"
                  />
                </div>
              ) : (
                <Paper
                  className="relative h-full rounded-8 shadow-none border-1 border-solid bg-gray-A500 py-20 mt-12 text-center"
                  role="button"
                  onDragOver={(e) => e.preventDefault()}
                >
                  <span className="block text-center">
                    <img
                      src="/assets/images/business/uploadImage.png"
                      className="mx-auto mb-12 w-28"
                      alt="..."
                    />
                  </span>
                  <Button
                    className="inline-block hover:bg-transparent"
                    onClick={() => setOpenMediaDialog(true)}
                  >
                    <span className="cursor-pointer text-quick-chat font-bold text-14">
                      {t('waba.broadcast.myBroadcast.clickToUpload')}
                    </span>{' '}
                    <span className="text-14 font-semibold text-grey-A300">
                      {t('waba.broadcast.myBroadcast.dragOrDrop')}
                    </span>
                    <Typography className="text-14 font-semibold text-grey-A300">
                      {t('waba.broadcast.myBroadcast.imageType')}
                    </Typography>
                  </Button>
                </Paper>
              )}
            </div>
          )}
        </div>
        <div className="w-full lg:w-[450px] bg-white px-40 pt-16">
          <Typography className="pb-20 font-bold text-16">
            {t('waba.broadcast.myBroadcast.preview')}
          </Typography>
          <div className="relative mb-16">
            <img
              className="w-380 m-auto h-[720px]"
              src="assets/images/quick-chat/phone.png"
              alt=""
            />
            <div className="absolute top-96 left-0 right-0 rounded-md max-w-224 xs:max-w-288 mx-auto h-[572px] overflow-scroll">
              <div className="bg-white px-12 rounded-md pt-10">
                {template?.templateData?.components?.length > 0 &&
                  template?.templateData?.components?.map((templateValue, i) => (
                    <div key={i}>
                      {templateValue?.type === 'HEADER' && (
                        <>
                          {templateValue?.format === 'TEXT' && (
                            <Typography
                              className="font-bold"
                              dangerouslySetInnerHTML={{ __html: templateValue?.text }}
                            />
                          )}
                        </>
                      )}
                      {templateValue?.format === 'IMAGE' && (
                        <>
                          {template?.dynamicData?.header?.map((dynamicValue) => (
                            <>
                              {dynamicValue?.type === 'image' && (
                                <img
                                  src={imageUrl || dynamicValue?.value}
                                  className="mb-10 rounded-md w-full h-144 object-cover shadow-md"
                                  alt="..."
                                />
                              )}
                            </>
                          ))}
                        </>
                      )}
                      {templateValue?.type === 'BODY' && (
                        <Typography
                          dangerouslySetInnerHTML={{
                            __html: convertTemplateToHtml(viewTemplate?.replace(/\n/g, '<br>')),
                          }}
                        />
                      )}
                      {templateValue?.type === 'FOOTER' && (
                        <Typography dangerouslySetInnerHTML={{ __html: templateValue?.text }} />
                      )}
                    </div>
                  ))}
              </div>
              {template?.templateData?.components?.length > 0 &&
                template?.templateData?.components?.map((templateValue, i) => (
                  <div key={i}>
                    {templateValue?.type === 'BUTTONS' && (
                      <>
                        {templateValue?.buttons?.map((buttonValue, b) => (
                          <div className="bg-white shadow p-4 rounded-md mt-4 h-32 text-center">
                            <a
                              className="font-semibold text-[#0096DE] text-16"
                              href={`${
                                buttonValue?.phone_number
                                  ? `tel:${buttonValue?.phone_number}`
                                  : buttonValue?.url
                              }`}
                              rel="noreferrer"
                              target="_blank"
                              role="button"
                              key={b}
                            >
                              <img
                                src={
                                  buttonValue?.phone_number
                                    ? '/assets/images/business/telephone.png'
                                    : '/assets/images/business/view.png'
                                }
                                className="w-14 h-14 align-middle mr-6 inline-block relative -top-1"
                                alt="..."
                              />
                              {buttonValue?.text}
                            </a>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap gap-12 justify-end w-full lg:w-[720px] p-20 mt-16">
        <Button
          className="rounded-md w-160 bg-white hover:bg-white font-semibold text-16"
          variant="outlined"
          color="quickChat"
          onClick={() => setCancelBroadcastDialogue(true)}
        >
          {t('common.cancel')}
        </Button>
        <Button
          className="rounded-md w-160 font-semibold text-16"
          variant="contained"
          color="quickChat"
          onClick={() => setStepsCount(2)}
          disabled={
            selectedVariables?.some(
              (item) =>
                item.value === null ||
                item.value === undefined ||
                (typeof item.value === 'string' && item.value.trim() === '') ||
                (Array.isArray(item.value) && item.value.length === 0)
            ) || !campaignName
          }
        >
          {t('common.next')}
        </Button>
      </div>
      <CancelBroadCast
        openCancelBroadcastDialogue={openCancelBroadcastDialogue}
        setCancelBroadcastDialogue={setCancelBroadcastDialogue}
      />
      <SelectTemplate
        openSelectTemplateDialogue={openSelectTemplateDialogue}
        setOpenSelectTemplateDialogue={setOpenSelectTemplateDialogue}
        setTemplate={setTemplate}
        type="broadcast"
      />
      <MediaList
        openValue={openMediaDialog}
        cancelCall={() => setOpenMediaDialog(false)}
        popUpMessage={t('waba.inbox.mediaLibrary')}
        onMediaSelected={handleMediaSelected}
        selectedMedia={selectedMedia}
        broadcast
      />
    </div>
  );
};

export default BroadcastDetails;
