import {
  Button,
  CircularProgress,
  Icon,
  Typography,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import {
  DatePicker,
  LocalizationProvider,
  renderTimeViewClock,
  TimePicker,
} from '@mui/x-date-pickers';
import AddIcon from '@mui/icons-material/Add';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { stateToHTML } from 'draft-js-export-html';
import { t } from 'i18next';
import InputLabel from '@mui/material/InputLabel';
import { useSnackbar } from 'notistack';
import history from '@history';
import ReactSelect from 'react-select';
import { FixedSizeList as List } from 'react-window';
import CloseIcon from '@mui/icons-material/Close';
import { Editor, EditorState, RichUtils, Modifier, convertToRaw, ContentState } from 'draft-js';
import userService from 'src/app/services';
import EmojiPicker from 'emoji-picker-react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import 'draft-js/dist/Draft.css';
import './template.css';
import PhoneInput from 'react-phone-input-2';
import {
  convertTemplateToHtml,
  handleWabaApiRequest,
  wabaImageSendToServer,
} from '../../../../common/common';
import wabaQuery from '../../../../query/waba';
import { selectDashboardData } from '../../../../../store/userSlice';
import { ConfirmTemplateSubmission, DiscardTemplate, TemplateSubmitted } from '../common/Popup';

const CreateOrEditTemplate = () => {
  const buttons = [
    {
      value: 'NONE',
      label: t('quickCampaign.template.button.none'),
    },
    {
      value: 'QUICK_REPLY',
      label: t('quickCampaign.template.button.quickReplies'),
    },
    {
      value: 'CALL_TO_ACTION',
      label: t('quickCampaign.template.button.callToAction'),
    },
    {
      value: 'COPY_CODE',
      label: t('quickCampaign.template.button.code'),
    },
    {
      value: 'ALL',
      label: t('quickCampaign.template.button.all'),
    },
  ];
  const callToAction = [
    {
      value: 'PHONE_NUMBER',
      label: t('waba.broadcast.AddTemplate.callPhone'),
    },
    {
      value: 'URL',
      label: t('waba.broadcast.AddTemplate.viewWebsite'),
    },
  ];

  const location = useLocation();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [categoryList, setCategoryList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [selectCategory, setSelectCategory] = useState();
  const [selectLanguage, setSelectLanguage] = useState();
  const [templateName, setTemplateName] = useState(
    location?.state?.template?.templateData?.name || ''
  );
  const [footerText, setFooterText] = useState(
    location?.state?.template?.templateData?.components?.find((item) => item?.type === 'FOOTER')
      ?.text || ''
  );
  const [headerText, setHeaderText] = useState(
    location?.state?.template?.templateData?.components?.find((item) => item?.type === 'HEADER')
      ?.text || ''
  );
  const loginUserData = userService.getUserData();
  const [isVariable, setIsVariable] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [phoneBody, setPhoneBody] = useState(EditorState.createEmpty());
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [imageUpload, setImageUpload] = useState();
  const [selectedButton, setSelectedButton] = useState('NONE');
  const [code, setCode] = useState();
  const [expireMin, setExpireMin] = useState();
  const [templateSubmittedDialogue, setTemplateSubmittedDialogue] = useState(false);
  const [templateSubmissionDialogue, setTemplateSubmissionDialogue] = useState(false);
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [openDiscardTemplateDialogue, setDiscardTemplateDialogue] = useState(false);
  const [headerType, setHeaderType] = useState(
    location?.state?.template?.templateData?.components
      ?.find((item) => item?.type === 'HEADER')
      ?.format?.toLowerCase() || 'none'
  );
  const [variableList, setVariableList] = useState([]);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [customizeButtons, setCustomizeButtons] = useState([]);

  const fileImgRef = useRef();

  useEffect(() => {
    const template = location?.state?.template?.templateData;
    const templateDynamicValue = location?.state?.template?.dynamicData;
    if (!template || !templateDynamicValue) {
      return;
    }
    const bodyText = template?.components?.find((item) => item?.type === 'BODY')?.text;
    const updatedBodyText = bodyText?.replace(/\{\{(\d+)\}\}/g, (match, key) => {
      const replacement = templateDynamicValue?.body?.find(
        (item) => `{{${item.key}}}` === match
      )?.description;
      return `[${replacement}]` || match;
    });

    setSelectedVariables(
      templateDynamicValue?.body?.map((item) => ({
        ...item,
        description: item.description.replace(/\[|\]/g, ''),
      })) || []
    );
    setEditorState(
      updatedBodyText
        ? EditorState.createWithContent(ContentState.createFromText(updatedBodyText, '\n'))
        : EditorState.createEmpty()
    );
    setPhoneBody(
      updatedBodyText
        ? EditorState.createWithContent(ContentState.createFromText(updatedBodyText, '\n'))
        : EditorState.createEmpty()
    );

    const language = languageList?.find((item) => item?.symbol === template?.language);
    setSelectLanguage(language);

    const category = categoryList?.find(
      (item) => item?.label?.toUpperCase() === template?.category
    );
    setSelectCategory(category);

    const image = templateDynamicValue?.header?.find((item) => item?.type === 'image')?.value;
    setImageUrl(image);
    const quickReplyButton = template?.components?.find((item) => item?.type === 'BUTTONS');

    if (quickReplyButton?.buttons?.length) {
      const buttonTypes = quickReplyButton.buttons.map((btn) => btn.type);
      const hasQuickReply = buttonTypes.includes('QUICK_REPLY');
      const hasCopyCode = buttonTypes.includes('COPY_CODE');
      const hasCallToAction = buttonTypes.includes('PHONE_NUMBER') || buttonTypes.includes('URL');
      if (hasQuickReply && hasCallToAction) {
        setSelectedButton('ALL');
      } else if (hasQuickReply) {
        setSelectedButton('QUICK_REPLY');
      } else if (hasCopyCode) {
        setSelectedButton('COPY_CODE');
      } else if (hasCallToAction) {
        setSelectedButton('CALL_TO_ACTION');
      }
    }
    setCustomizeButtons(quickReplyButton?.buttons?.length > 0 ? quickReplyButton?.buttons : []);
  }, [languageList, categoryList, location?.state]);

  useEffect(() => {
    const contentState = editorState?.getCurrentContent();
    const plainText = convertToRaw(contentState)
      .blocks.map((block) => block.text)
      .join(' ');

    const filteredVariables = selectedVariables?.filter((variable) =>
      plainText.includes(`[${variable.description}]`)
    );

    if (JSON.stringify(filteredVariables) !== JSON.stringify(selectedVariables)) {
      setSelectedVariables(filteredVariables);
    }
  }, [phoneBody, selectedVariables, editorState]);

  const handleInputChange = (index, value) => {
    setSelectedVariables((prev) =>
      prev?.map((item, i) => (i === index ? { ...item, value } : item))
    );
  };

  useEffect(() => {
    const currentContent = editorState.getCurrentContent();
    let currentText = currentContent.getPlainText();
    selectedVariables?.forEach((variable) => {
      if (variable.value) {
        let formattedValue = variable.value;
        if (variable.value instanceof Date) {
          if (variable.description.includes('Date')) {
            formattedValue = variable.value.toLocaleDateString('en-GB');
          } else if (variable.description.includes('Time')) {
            formattedValue = variable.value.toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
            });
          }
        } else if (variable.type === 'phoneNumber') {
          if (variable.value?.phoneNumber) {
            formattedValue = `+${variable.value.countryCode} ${variable.value.phoneNumber.slice(
              variable.value.countryCode.length
            )}`;
          } else {
            return;
          }
        }
        const escapedName = `\\[${variable.description}\\]`;
        currentText = currentText.replace(new RegExp(escapedName, 'g'), formattedValue);
      }
    });
    const newContentState = ContentState.createFromText(currentText, '\n');
    const updateBody = EditorState.push(editorState, newContentState, 'insert-characters');
    setPhoneBody(updateBody);
  }, [selectedVariables, editorState]);

  const convertContentToHTML = () => {
    const contentState = phoneBody.getCurrentContent();
    return stateToHTML(contentState);
  };

  const getBodyText = (editorStates) => {
    const contentState = editorStates.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    let formattedText = '';
    const bodyVariables = new Set();

    rawContent.blocks.forEach((block) => {
      const blockText = block.text;
      const styles = new Map();

      const placeholders = blockText.match(/\[.*?\]/g);
      if (placeholders) {
        placeholders.forEach((placeholder) => {
          bodyVariables.add(placeholder.replace(/[[]]/g, ''));
        });
      }

      block.inlineStyleRanges.forEach((styleRange) => {
        const { style, offset, length } = styleRange;
        const key = `${offset}-${offset + length}`;
        const existing = styles.get(key) || [];
        styles.set(key, [...existing, style]);
      });

      let lastIndex = 0;
      Array.from(styles.entries()).forEach(([range, stylesW]) => {
        const [start, end] = range.split('-').map(Number);
        const textToFormat = blockText.slice(start, end);
        let formattedSegment = textToFormat;

        stylesW.forEach((style) => {
          if (style === 'BOLD') {
            formattedSegment = `*${formattedSegment}*`;
          } else if (style === 'ITALIC') {
            formattedSegment = `_${formattedSegment}_`;
          } else if (style === 'STRIKETHROUGH') {
            formattedSegment = `~${formattedSegment}~`;
          } else if (style === 'CODE') {
            formattedSegment = `\`\`\`${formattedSegment}\`\`\``;
          }
        });

        formattedText += blockText.slice(lastIndex, start) + formattedSegment;
        lastIndex = end;
      });

      formattedText += `${blockText.slice(lastIndex)}\n`;
    });

    return { formattedText, bodyVariables: Array.from(bodyVariables) };
  };

  const CustomMenuList = (propsList) => {
    const itemHeight = 35;
    const { options, children, maxHeight, getValue } = propsList;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * itemHeight;
    if (options.length > 0) {
      return (
        <div>
          <List
            height={maxHeight}
            itemCount={children.length}
            itemSize={itemHeight}
            initialScrollOffset={initialOffset}
          >
            {({ index, style }) => (
              <div style={style} className="bg-white relative">
                {children[index]}
              </div>
            )}
          </List>
        </div>
      );
    }
    return <div className="p-16">Loading....</div>;
  };

  useEffect(() => {
    const whatAppTemplateCategories = async () => {
      try {
        const payload = {
          query: wabaQuery.getTemplateCategories,
        };
        const res = await handleWabaApiRequest(payload);
        if (res?.getTemplateCategories?.status === 200) {
          const templateCategoryList = res?.getTemplateCategories?.data?.map((idx) => ({
            value: idx.id,
            label: idx.name,
          }));
          setCategoryList(templateCategoryList);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };
    whatAppTemplateCategories();
  }, []);

  useEffect(() => {
    const getTemplateLanguage = async () => {
      try {
        const payload = {
          query: wabaQuery.getTemplateLanguage,
        };
        const res = await handleWabaApiRequest(payload);
        if (res?.getTemplateLanguage?.status === 200) {
          const templateCategoryList = res?.getTemplateLanguage?.data?.map((idx) => ({
            value: idx.id,
            label: idx.name,
            symbol: idx.symbol,
          }));
          setLanguageList(templateCategoryList);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };
    getTemplateLanguage();
  }, []);

  useEffect(() => {
    const getTemplateVariable = async () => {
      try {
        const payload = {
          query: wabaQuery.getTemplateVariable,
        };
        const res = await handleWabaApiRequest(payload);
        if (res?.getTemplateVariable?.status === 200) {
          setVariableList(res?.getTemplateVariable?.data);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };
    getTemplateVariable();
  }, []);

  const handleUploadImageChange = async (e) => {
    const file = e?.target?.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();

    reader.onload = async () => {
      setImageUrl(reader.result);
      setImageUpload(file);
    };

    try {
      reader.readAsDataURL(file);
    } catch (error) {
      enqueueSnackbar('Failed to read the file. Ensure the file is valid.', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      console.error('File reading error:', error);
    }
  };

  const handleCreateTemplate = async () => {
    setRefreshIcon(true);
    const template = location?.state?.template;
    try {
      const { formattedText } = getBodyText(editorState);
      const payload = {
        query: wabaQuery.createOrEditWhatsappTemplate,
        variables: {
          businessId: Number(userCurrentBusinessDetails?.id),
          bodyVariables: selectedVariables?.map((item) => ({
            isAutoReplace: false,
            type: item?.type,
            value: item?.type === 'phoneNumber' ? item?.value?.phoneNumber : item?.value,
            key: item?.description,
          })),
          callToAction: customizeButtons
            ?.filter((item) => item?.type === 'URL' || item?.type === 'PHONE_NUMBER')
            .map((item) => ({
              buttonText: item?.text,
              buttonType: item?.type,
              buttonData: item?.type === 'URL' ? item?.url : `+${item?.phone_number}`,
            })),
          quickReply: customizeButtons
            ?.filter((item) => item?.type === 'QUICK_REPLY')
            .map((item) => item?.text),
          code: '',
          expireMin: Number(expireMin),
          headerType,
          templateBody: formattedText ? encodeURIComponent(formattedText) : '',
          templateCategoryId: Number(selectCategory?.value),
          templateFooter: footerText,
          templateLanguage: selectLanguage?.symbol,
          ...(location?.state?.status === 'DRAFT' && {
            templateId: template?.templateId,
          }),
          templateName,
          ...(headerType === 'image'
            ? imageUpload
              ? { templateImage: imageUpload }
              : {
                  existingMediaUrl: location?.state?.template?.dynamicData?.header?.find(
                    (item) => item?.type === 'image'
                  )?.value,
                }
            : { templateTitle: headerText }),
        },
      };
      const res = await wabaImageSendToServer(payload);
      setRefreshIcon(false);
      if (res.createOrEditWhatsappTemplate?.status === 200) {
        setTemplateName('');
        setTemplateSubmissionDialogue(false);
        setTemplateSubmittedDialogue(true);
        enqueueSnackbar(`${t('waba.broadcast.AddTemplate.successMsg')}`, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        setTemplateSubmissionDialogue(false);
        enqueueSnackbar(res?.createOrEditWhatsappTemplate?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setTemplateSubmissionDialogue(false);
      setRefreshIcon(false);
      enqueueSnackbar(
        error?.message?.toLowerCase() === 'url malformed'
          ? 'Please remove emoji from body'
          : 'Something went wrong',
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      );
      console.error('An error occurred:', error);
    }
  };

  const saveAsDraft = async () => {
    setRefreshIcon(true);
    const template = location?.state?.template;
    try {
      const { formattedText } = getBodyText(editorState);
      const payload = {
        query: wabaQuery.saveWhatsappTemplateDraft,
        variables: {
          businessId: Number(userCurrentBusinessDetails?.id),
          bodyVariables: selectedVariables?.map((item) => ({
            isAutoReplace: false,
            type: item?.type,
            value: item?.type === 'phoneNumber' ? item?.value?.phoneNumber : item?.value,
            key: item?.description,
          })),
          callToAction: customizeButtons
            ?.filter((item) => item?.type === 'URL' || item?.type === 'PHONE_NUMBER')
            .map((item) => ({
              buttonText: item?.text,
              buttonType: item?.type,
              buttonData: item?.type === 'URL' ? item?.url : `+${item?.phone_number}`,
            })),
          quickReply: customizeButtons
            ?.filter((item) => item?.type === 'QUICK_REPLY')
            .map((item) => item?.text),
          code: '',
          ...(location?.state?.status === 'DRAFT' && {
            draftId: Number(template?.id),
          }),
          expireMin: Number(expireMin),
          headerType,
          templateBody: encodeURIComponent(formattedText),
          templateCategoryId: Number(selectCategory?.value),
          templateFooter: footerText,
          templateLanguage: selectLanguage?.symbol,
          templateName,
          ...(location?.state?.status === 'DRAFT' && {
            templateId: template?.templateId,
          }),
          ...(headerType === 'image'
            ? imageUpload
              ? { templateImage: imageUpload }
              : {
                  existingMediaUrl: location?.state?.template?.dynamicData?.header?.find(
                    (item) => item?.type === 'image'
                  )?.value,
                }
            : { templateTitle: headerText }),
        },
      };
      const res = await wabaImageSendToServer(payload);
      setRefreshIcon(false);
      if (res.saveWhatsappTemplateDraft?.status === 200) {
        setTemplateName('');
        history.push('quick-chat/template', { activeTab: 3 });
        setDiscardTemplateDialogue(false);
        enqueueSnackbar(`${t('waba.broadcast.AddTemplate.successMsg')}`, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar(res?.saveWhatsappTemplateDraft?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleKeyCommand = (command, editorStates) => {
    const newState = RichUtils.handleKeyCommand(editorStates, command);
    if (newState) {
      setEditorState(newState);
      setPhoneBody(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const onBoldClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
  };

  const onItalicClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
  };

  const onStrikethroughClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'STRIKETHROUGH'));
  };

  const addEmoji = (event, emojiObject) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEmoji = Modifier.insertText(
      contentState,
      editorState.getSelection(),
      emojiObject.emoji
    );
    const newEditorState = EditorState.push(
      editorState,
      contentStateWithEmoji,
      'insert-characters'
    );
    setEditorState(newEditorState);
    setShowEmojiPicker(!showEmojiPicker);
  };

  const handleVariableSelect = (variable) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    let contentStateWithVariable;
    if (selectionState.isCollapsed()) {
      contentStateWithVariable = Modifier.insertText(
        contentState,
        selectionState,
        `[${variable?.name}]`
      );
    } else {
      contentStateWithVariable = Modifier.replaceText(
        contentState,
        selectionState,
        `[${variable?.name}]`
      );
    }

    const newEditorState = EditorState.push(
      editorState,
      contentStateWithVariable,
      'insert-characters'
    );
    setEditorState(newEditorState);
    setPhoneBody(newEditorState);
    setIsVariable(false);
    setSelectedVariables((prev) => {
      if (prev?.some((item) => item.description.includes(variable?.name))) {
        return prev;
      }
      return [
        ...prev,
        {
          type: variable?.type,
          isAutoReplace: variable?.isAutoReplace,
          description: variable?.name,
        },
      ];
    });
  };

  const handleInputNameChange = (e, maxLength, type) => {
    const newValue = e.target.value;
    if (newValue.length <= maxLength) {
      if (type === 'templateName') {
        setTemplateName(newValue);
      } else if (type === 'footerText') {
        setFooterText(newValue);
      }
    }
  };

  const handleEditorChange = (state) => {
    const currentContent = state.getCurrentContent();
    const plainText = currentContent.getPlainText('');
    const length = plainText?.length;

    if (length <= 1024) {
      setEditorState(state);
      setPhoneBody(state);
    } else {
      const trimmedContent = Modifier.removeRange(currentContent, state.getSelection(), 'backward');
      setEditorState(EditorState.push(state, trimmedContent, 'remove-range'));
      setPhoneBody(EditorState.push(state, trimmedContent, 'remove-range'));
    }
  };

  const handleUpdateReply = (index, value) => {
    setCustomizeButtons((prevReplies) => {
      let updatedReplies = prevReplies?.map((item, i) =>
        i === index ? { ...item, text: value, type: 'QUICK_REPLY' } : item
      );
      if (selectedButton === 'QUICK_REPLY') {
        updatedReplies = updatedReplies.filter((item) => item.type === 'QUICK_REPLY');
      }
      return updatedReplies;
    });
  };

  const handleAddNewReply = () => {
    setCustomizeButtons((prevReplies) => {
      const quickReplyCount = prevReplies.filter((item) => item.type === 'QUICK_REPLY').length;
      return quickReplyCount < 10
        ? [...prevReplies, { text: '', type: 'QUICK_REPLY' }]
        : prevReplies;
    });
  };

  const handleRemoveReply = (index) => {
    setCustomizeButtons((prevReplies) => prevReplies.filter((_, i) => i !== index));
  };

  const handleAddAction = (action) => {
    setCustomizeButtons((prev) => {
      const lastAction = prev[prev.length - 1]?.callActionButton;
      const nextAction = action || (lastAction === 'PHONE_NUMBER' ? 'URL' : 'PHONE_NUMBER');

      return [
        ...prev,
        {
          type: nextAction,
          text: '',
          buttonData: '',
          isChange: !!action,
        },
      ];
    });
  };

  const handleRemoveAction = (index) => {
    setCustomizeButtons((prevActions) => prevActions.filter((_, i) => i !== index));
  };

  const handleSelectButtonType = (value) => {
    setSelectedButton(value);
    if (value === 'ALL') {
      setCustomizeButtons([]);
    }
  };

  const handleDiscardTemplate = () => {
    setHeaderText('none');
    setImageUpload();
    setImageUrl('');
    setCustomizeButtons([]);
    setSelectedVariables([]);
    setSelectCategory('');
    setSelectLanguage('');
    setSelectedButton('NONE');
    setTemplateName('');
    setHeaderText('');
    setFooterText('');
    setExpireMin('');
    setEditorState(EditorState.createEmpty());
    setPhoneBody(EditorState.createEmpty());
    setCode('');
    setDiscardTemplateDialogue(false);
  };

  return (
    <div className="lg:p-24 p-16">
      <div className="flex flex-col md:flex-row quick-chat gap-16">
        <div className="w-full">
          <Typography className="font-semibold text-24">
            {t('quickCampaign.template.createTemplates')}
          </Typography>
          <div className="w-full flex flex-col mt-24 quick-chat">
            <div className="bg-white p-20 mb-16">
              <Typography className="font-semibold text-18">
                {t('quickCampaign.template.templateDetails')}
              </Typography>
              <div className="mt-16">
                <div className="block md:basis-1/2">
                  <div className="">
                    <InputLabel id="templateName" className="text-16 font-medium text-grey-900">
                      {t('waba.broadcast.AddTemplate.name')}
                    </InputLabel>
                    <Typography className="font-normal text-grey-A300 text-14 max-w-640 my-8">
                      {t('quickCampaign.template.note')}
                    </Typography>
                  </div>
                  <TextField
                    name="templateName"
                    type="text"
                    onChange={(e) => handleInputNameChange(e, 120, 'templateName')}
                    placeholder={t('quickCampaign.template.addTemplateName')}
                    value={templateName}
                    className="bg-white"
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      style: { fontSize: '16px', fontWeight: 500 },
                    }}
                  />
                </div>
                <div className="flex flex-wrap sm:flex-nowrap justify-between gap-16 my-12">
                  <div className="md:basis-1/2">
                    <InputLabel
                      id="templateName"
                      className="text-16 font-medium mb-4 text-grey-900 mb-10"
                    >
                      {t('quickCampaign.template.templateCategory')}
                    </InputLabel>
                    <ReactSelect
                      labelId="templateCategory"
                      placeholder={t('waba.broadcast.AddTemplate.selectCategory')}
                      id="templateCategory"
                      name="templateCategory"
                      minMenuHeight="45px"
                      onChange={(e) => {
                        setSelectCategory(e);
                        if (e?.label === 'Authentication') {
                          setHeaderType('none');
                          setSelectedButton('COPY_CODE');
                        }
                      }}
                      value={selectCategory}
                      options={categoryList}
                      components={{
                        MenuList: CustomMenuList,
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          minHeight: '42px',
                          borderColor: state.isFocused ? '#EBB32F' : baseStyles.borderColor,
                          boxShadow: state.isFocused ? '0 0 0 1px #EBB32F' : baseStyles.boxShadow,
                          '&:hover': {
                            borderColor: state.isFocused ? '#EBB32F' : baseStyles.borderColor,
                          },
                        }),
                      }}
                    />
                  </div>
                  <div className="md:basis-1/2">
                    <InputLabel
                      id="templateName"
                      className="text-16 font-medium mb-4 text-grey-900 mb-10"
                    >
                      {t('quickCampaign.template.templateLanguage')}
                    </InputLabel>
                    <ReactSelect
                      labelId="templateCategory"
                      placeholder={t('waba.broadcast.AddTemplate.selectCategory')}
                      id="templateCategory"
                      name="templateCategory"
                      minMenuHeight="45px"
                      onChange={(e) => {
                        setSelectLanguage(e);
                      }}
                      value={selectLanguage}
                      options={languageList}
                      components={{
                        MenuList: CustomMenuList,
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          minHeight: '42px',
                          borderColor: state.isFocused ? '#EBB32F' : baseStyles.borderColor,
                          boxShadow: state.isFocused ? '0 0 0 1px #EBB32F' : baseStyles.boxShadow,
                          '&:hover': {
                            borderColor: state.isFocused ? '#EBB32F' : baseStyles.borderColor,
                          },
                        }),
                      }}
                    />
                  </div>
                </div>
                <div>
                  <Typography className="text-16 font-medium text-grey-900">
                    {t('quickCampaign.template.templateHeader')}
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(e) => setHeaderType(e.target.value)}
                      value={headerType}
                    >
                      <FormControlLabel value="none" control={<Radio />} label="None" />
                      {selectCategory?.label !== 'Authentication' && (
                        <>
                          <FormControlLabel value="text" control={<Radio />} label="Text" />
                          <FormControlLabel value="image" control={<Radio />} label="Image" />
                        </>
                      )}
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              {headerType === 'text' && (
                <div>
                  <Typography className="text-16 font-medium text-grey-900">Header</Typography>
                  <TextField
                    className="w-full mt-4"
                    value={headerText}
                    onChange={(e) => setHeaderText(e.target.value)}
                    placeholder="Enter header value"
                    size="medium"
                    InputProps={{
                      style: { fontSize: '16px', fontWeight: 500 },
                    }}
                  />
                </div>
              )}
              {headerType === 'image' && (
                <div className="block mt-12">
                  <InputLabel id="media" className="text-16 font-medium mb-4 text-grey-900 mb-10">
                    {t('waba.broadcast.AddTemplate.media')}
                  </InputLabel>
                  {imageUrl ? (
                    <div className="relative inline-block">
                      <img
                        alt="..."
                        className="w-auto sm:max-w-320 capitalize text-40"
                        src={imageUrl}
                      />{' '}
                      <Button
                        className="bg-white absolute right-10 top-10 w-20 h-20 min-w-20 min-h-20 hover:bg-white"
                        onClick={() => {
                          setImageUrl();
                          setImageUpload();
                        }}
                      >
                        <CloseIcon className="text-black text-lg" />
                      </Button>
                    </div>
                  ) : (
                    <div className="border-2 border-dotted border-quick-chat text-center py-20 rounded-md">
                      <Icon>upload</Icon>
                      <h4 className="text-16 font-semibold">
                        {t('waba.broadcast.AddTemplate.uploadFile')}
                      </h4>
                      <span className="text-14 font-medium block text-grey-600 my-12">
                        {t('waba.broadcast.AddTemplate.uploadInfo')}
                      </span>
                      <div className="relative">
                        <label htmlFor="template-image">
                          <input
                            className="hidden w-full"
                            id="template-image"
                            type="file"
                            ref={fileImgRef}
                            onChange={handleUploadImageChange}
                            accept="image/*"
                            aria-labelledby="upload-label"
                          />
                          <Button
                            className="border rounded-md bg-quick-chat-100 font-semibold text-quick-chat hover:text-white hover:bg-quick-chat"
                            variant="contained"
                            color="secondary"
                            component="span"
                            id="upload-label"
                          >
                            {t('waba.broadcast.AddTemplate.uploadImage')}
                          </Button>
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="bg-white p-20">
              <Typography className="font-semibold text-18">
                {t('quickCampaign.template.contentCustomization')}
              </Typography>
              <div className="block mb-20">
                <div className="flex justify-between relative items-center">
                  <InputLabel id="body" className="text-16 font-medium mt-16 text-grey-900 mb-10">
                    {t('waba.broadcast.AddTemplate.body')}
                  </InputLabel>
                  <Button
                    className="text-16 text-quick-chat font-semibold hover:bg-white"
                    onClick={() => setIsVariable(!isVariable)}
                  >
                    {t('quickCampaign.template.addVariable')}
                  </Button>
                  {isVariable && (
                    <div className="absolute top-40 right-8 p-12 bg-white shadow z-10">
                      <CloseIcon
                        className="absolute right-8 cursor-pointer"
                        onClick={() => setIsVariable(false)}
                      />
                      <div className="mt-16">
                        {variableList?.length > 0 &&
                          variableList?.map((item, index) => {
                            return (
                              <Button
                                className="font-semibold py-6 w-160 block text-start hover:bg-white"
                                key={index}
                                onClick={() => handleVariableSelect(item)}
                              >
                                {item?.name}
                              </Button>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>
                <Typography className="text-grey-A300">
                  {t('waba.broadcast.AddTemplate.bodyInfo')}
                </Typography>
                <Typography className="mb-10 text-grey-A300">
                  {t('waba.broadcast.AddTemplate.bodyExample')}
                </Typography>
                <div className="border-1 border-solid border-grey-400 p-10 rounded-md relative">
                  <div className="mb-10 flex flex-col xs:flex-row gap-10">
                    <div className="bg-grey-50 rounded-md w-full">
                      {/* <Button
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                    className="min-w-40"
                  >
                    <Icon className="text-16">insert_emoticon</Icon>
                  </Button> */}
                      <Button
                        onClick={onBoldClick}
                        className="text-20 font-bold text-grey-600 min-w-0"
                      >
                        B
                      </Button>
                      <Button
                        onClick={onItalicClick}
                        className="text-20 font-bold italic text-grey-600 min-w-0"
                      >
                        I
                      </Button>
                      <Button
                        onClick={onStrikethroughClick}
                        className="text-20 font-bold line-through text-grey-600 min-w-0"
                        title="Strike-through"
                      >
                        T
                      </Button>
                    </div>
                  </div>
                  {showEmojiPicker && <EmojiPicker onEmojiClick={addEmoji} className="absolute" />}
                  <div className="min-h-200">
                    <Editor
                      className="min-h-200"
                      editorState={editorState}
                      handleKeyCommand={handleKeyCommand}
                      onChange={handleEditorChange}
                      placeholder={t('waba.broadcast.AddTemplate.bodyPlaceHolder')}
                      ariaMultiline={10}
                    />
                  </div>
                </div>
                <div>
                  <Typography className="text-16 text-black mt-16 font-medium">
                    {t('quickCampaign.template.sampleValuesOfVariables')}
                  </Typography>
                  <Typography className="font-normal text-grey-A300 text-14 max-w-640 my-8">
                    {t('quickCampaign.template.sampleValueInfo')}
                  </Typography>
                  <div className="grid grid-cols-2 col-span-1 gap-16 justify-between items-center">
                    {selectedVariables?.length > 0 &&
                      selectedVariables?.map((item, index) => (
                        <div key={index}>
                          <Typography className="text-[#686868] text-16 font-medium">
                            {item.description}
                          </Typography>
                          {item.type === 'text' || item.type === 'int' ? (
                            <TextField
                              className="w-full mt-6"
                              value={item.value || ''}
                              onChange={(e) => handleInputChange(index, e.target.value)}
                              placeholder={`Enter ${item.description}`}
                              size="medium"
                              InputProps={{
                                style: { fontSize: '16px', fontWeight: 500 },
                              }}
                            />
                          ) : item.type === 'date' ? (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                className="w-full select-date mt-6"
                                value={item.value || null}
                                onChange={(newDate) => handleInputChange(index, newDate)}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                          ) : item.type === 'time' ? (
                            <TimePicker
                              className="w-full mt-6"
                              id="time"
                              onChange={(newTime) => handleInputChange(index, newTime)}
                              size="medium"
                              required
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                            />
                          ) : (
                            item?.type === 'phoneNumber' && (
                              <PhoneInput
                                placeholder={t(
                                  'manageBusiness.addBusinessSteps.addBusiness.form.phone.placeHolder'
                                )}
                                inputStyle={{
                                  width: '100%',
                                  height: '52px',
                                  color: 'black',
                                  fontWeight: '400',
                                }}
                                className="w-full "
                                name="phone_number"
                                required
                                size="small"
                                value={item.value?.phoneNumber?.toString()}
                                country={loginUserData?.isoCode.toLowerCase()}
                                enableSearch="true"
                                onChange={(phone, countryData) => {
                                  handleInputChange(index, {
                                    phoneNumber: phone,
                                    countryCode: countryData.dialCode,
                                  });
                                }}
                              />
                            )
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              {selectCategory?.label === 'Authentication' ? (
                <div>
                  <div className="mb-4">
                    <InputLabel
                      id="footer"
                      className="text-16 font-medium mb-4 text-grey-900 mb-10"
                    >
                      {t('waba.broadcast.AddTemplate.expirationWarning')}
                    </InputLabel>
                    <Typography className="font-normal text-grey-A300 text-14">
                      {t('waba.broadcast.AddTemplate.expirationMessage')}
                    </Typography>
                    <div className="grid grid-cols-2 col-span-1 gap-16 justify-between items-center pt-12">
                      <TextField
                        name="footer"
                        placeholder={t('waba.broadcast.AddTemplate.enterTime')}
                        type="number"
                        onChange={(e) => setExpireMin(e.target.value)}
                        value={expireMin}
                        className="bg-white"
                        variant="outlined"
                        size="medium"
                        fullWidth
                        InputProps={{
                          style: { fontSize: '16px', fontWeight: 500 },
                        }}
                      />
                      <Typography>{t('waba.broadcast.AddTemplate.minutes')}</Typography>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="block mb-20 md:basis-1/2">
                  <div className="flex justify-between items-center mb-4">
                    <InputLabel
                      id="footer"
                      className="text-16 font-medium mb-4 text-grey-900 mb-10"
                    >
                      {t('waba.broadcast.AddTemplate.footer')}
                    </InputLabel>
                    <span className="text-sm text-gray-500">{footerText?.length || 0}/1024</span>
                  </div>
                  <TextField
                    name="footer"
                    placeholder={t('waba.broadcast.AddTemplate.placeHolder')}
                    type="text"
                    onChange={(e) => handleInputNameChange(e, 60, 'footerText')}
                    value={footerText}
                    className="bg-white"
                    variant="outlined"
                    size="medium"
                    fullWidth
                    maxLength={60}
                    InputProps={{
                      style: { fontSize: '16px', fontWeight: 500 },
                    }}
                  />
                </div>
              )}
            </div>
            <div className="bg-white mt-16 p-20">
              <Typography className="text-black text-16 font-semibold">
                {t('quickCampaign.template.buttonCustomization')}
              </Typography>
              <Typography className="text-black text-14 mt-10 font-medium">
                {t('quickCampaign.template.buttons')}
              </Typography>
              <div>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(e) => handleSelectButtonType(e.target.value)}
                    value={selectedButton}
                  >
                    {buttons
                      ?.filter((item) =>
                        selectCategory?.label === 'Authentication'
                          ? item?.value === 'COPY_CODE'
                          : true
                      )
                      .map((items, i) => {
                        return (
                          <FormControlLabel
                            value={items.value}
                            control={<Radio />}
                            label={items.label}
                            key={i}
                          />
                        );
                      })}
                  </RadioGroup>
                </FormControl>
              </div>
              {(selectedButton === 'QUICK_REPLY' || selectedButton === 'ALL') && (
                <div>
                  {customizeButtons
                    ?.filter((item) => item?.type === 'QUICK_REPLY')
                    .map((item, i) => {
                      const originalIndex = customizeButtons.findIndex((btn) => btn === item);
                      return (
                        <div className="mb-4" key={i}>
                          <div className="flex justify-between w-200 items-center">
                            <Typography className="pb-2">
                              {t('quickCampaign.template.reply')} {i + 1}
                            </Typography>
                            <Typography className="text-sm text-gray-500">
                              {item?.text.length}/24
                            </Typography>
                          </div>
                          <div className="flex items-center gap-4">
                            <TextField
                              name="buttonText"
                              placeholder={t('waba.broadcast.AddTemplate.buttonText')}
                              type="text"
                              onChange={(e) => {
                                const value = e.target.value.slice(0, 24);
                                handleUpdateReply(originalIndex, value);
                              }}
                              value={item?.text}
                              className="bg-white"
                              variant="outlined"
                              size="small"
                              InputProps={{
                                style: { fontSize: '16px', fontWeight: 500 },
                              }}
                            />
                            {(selectedButton === 'ALL' ||
                              (selectedButton === 'QUICK_REPLY' && i !== 0)) && (
                              <CloseIcon
                                className="cursor-pointer"
                                onClick={() => handleRemoveReply(originalIndex)}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  {customizeButtons?.filter((item) => item?.type === 'QUICK_REPLY')?.length < 10 &&
                    selectedButton !== 'ALL' && (
                      <Button
                        className="p-0 font-semibold text-16"
                        variant="text"
                        color="quickChat"
                        onClick={() => handleAddNewReply()}
                      >
                        <AddIcon className="font-bold" />
                        {t('quickCampaign.template.addNewReply')} (
                        {10 -
                          (customizeButtons.filter((item) => item?.type === 'QUICK_REPLY')?.length >
                          0
                            ? customizeButtons.filter((item) => item?.type === 'QUICK_REPLY').length
                            : 0)}
                        )
                      </Button>
                    )}
                </div>
              )}
              {(selectedButton === 'CALL_TO_ACTION' || selectedButton === 'ALL') && (
                <div>
                  {customizeButtons
                    ?.filter((item) => item?.type === 'PHONE_NUMBER' || item?.type === 'URL')
                    ?.map((action, index) => {
                      const originalIndex = customizeButtons.findIndex((btn) => btn === action);
                      return (
                        <div key={index} className="flex items-center mt-16 gap-16">
                          <Select
                            className="rounded-md p-0 shadow-none pt-5"
                            sx={{ '& .MuiFilledInput-input': { pt: '14px' } }}
                            size="small"
                            variant="outlined"
                            value={action.type}
                            fullWidth
                            disabled={action.isChange}
                            onChange={(e) => {
                              setCustomizeButtons((prev) => {
                                const updatedActions = [...prev];
                                updatedActions[originalIndex] = {
                                  ...updatedActions[originalIndex],
                                  type: e.target.value,
                                };
                                return updatedActions;
                              });
                            }}
                          >
                            {callToAction.map((item, i) => (
                              <MenuItem
                                key={i}
                                value={item.value}
                                className="capitalize hover:bg-quick-chat-100 hover:text-quick-chat mx-5 rounded-md p-6"
                              >
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <TextField
                            name="buttonText"
                            placeholder={t('waba.broadcast.AddTemplate.buttonText')}
                            type="text"
                            onChange={(e) => {
                              setCustomizeButtons((prev) => {
                                const updatedActions = [...prev];
                                updatedActions[originalIndex] = {
                                  ...updatedActions[originalIndex],
                                  text: e.target.value,
                                };
                                return updatedActions;
                              });
                            }}
                            value={action.text}
                            className="bg-white"
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputProps={{
                              style: { fontSize: '16px', fontWeight: 500 },
                            }}
                          />
                          {action.type === 'URL' ? (
                            <TextField
                              name="websiteURL"
                              placeholder={t('waba.broadcast.AddTemplate.websiteURL')}
                              type="text"
                              onChange={(e) => {
                                setCustomizeButtons((prev) => {
                                  const updatedActions = [...prev];
                                  updatedActions[originalIndex] = {
                                    ...updatedActions[originalIndex],
                                    url: e.target.value,
                                  };
                                  return updatedActions;
                                });
                              }}
                              value={action.url}
                              className="bg-white"
                              variant="outlined"
                              size="small"
                              fullWidth
                            />
                          ) : (
                            <PhoneInput
                              placeholder={t(
                                'manageBusiness.addBusinessSteps.addBusiness.form.phone.placeHolder'
                              )}
                              inputStyle={{
                                width: '100%',
                                height: '40px',
                                color: 'black',
                                fontWeight: '400',
                              }}
                              className="w-full "
                              name="phone_number"
                              required
                              size="small"
                              value={action.phone_number}
                              country={loginUserData?.isoCode.toLowerCase()}
                              enableSearch="true"
                              onChange={(phone) => {
                                setCustomizeButtons((prev) => {
                                  const updatedActions = [...prev];
                                  updatedActions[originalIndex] = {
                                    ...updatedActions[originalIndex],
                                    phone_number: phone,
                                  };
                                  return updatedActions;
                                });
                              }}
                            />
                          )}
                          <div className="max-w-40 min-w-40">
                            {(selectedButton === 'ALL' ||
                              (selectedButton === 'CALL_TO_ACTION' && index !== 0)) && (
                              <CloseIcon
                                className="cursor-pointer"
                                onClick={() => handleRemoveAction(originalIndex)}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  {customizeButtons?.filter(
                    (item) => item?.type === 'PHONE_NUMBER' || item?.type === 'URL'
                  )?.length < 2 &&
                    selectedButton !== 'ALL' && (
                      <Button
                        className="p-0 font-semibold text-16"
                        variant="text"
                        color="quickChat"
                        onClick={() => handleAddAction()}
                      >
                        <AddIcon className="font-bold" />
                        {t('quickCampaign.template.addNewAction')}
                      </Button>
                    )}
                </div>
              )}
              {selectedButton === 'COPY_CODE' && (
                <div>
                  <Typography className="text-14 font-medium py-6">
                    {t('quickCampaign.template.button.code')}
                  </Typography>
                  <TextField
                    name="websiteURL"
                    placeholder={t('quickCampaign.template.codePlaceholder')}
                    type="text"
                    onChange={(e) => {
                      setCode(e.target.value);
                    }}
                    value={code}
                    className="bg-white"
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      style: { fontSize: '16px', fontWeight: 500 },
                    }}
                  />
                </div>
              )}
              {selectedButton === 'ALL' && (
                <div className="flex gap-16 mt-16">
                  {customizeButtons.filter((item) => item?.type === 'QUICK_REPLY')?.length < 10 && (
                    <Button
                      className="border border-solid border-grey rounded-md text-[#515151] font-medium hover:bg-transparent"
                      onClick={() => handleAddNewReply()}
                    >
                      <AddIcon className="!text-[#515151] font-bold" />
                      {t('quickCampaign.template.button.quickReplies')} (
                      {10 -
                        (customizeButtons.filter((item) => item?.type === 'QUICK_REPLY')?.length > 0
                          ? customizeButtons.filter((item) => item?.type === 'QUICK_REPLY').length
                          : 0)}
                      )
                    </Button>
                  )}
                  {customizeButtons?.filter((item) => item?.type === 'URL')?.length < 2 && (
                    <Button
                      className="border border-solid border-grey rounded-md text-[#515151] font-medium hover:bg-transparent"
                      onClick={() => handleAddAction('URL')}
                    >
                      <AddIcon className="!text-[#515151] font-bold" />
                      {t('waba.broadcast.AddTemplate.url')} (
                      {2 -
                        (customizeButtons.filter((item) => item?.type === 'URL')?.length > 0
                          ? customizeButtons.filter((item) => item?.type === 'URL').length
                          : 0)}
                      )
                    </Button>
                  )}
                  {customizeButtons?.filter((item) => item?.type === 'PHONE_NUMBER')?.length <
                    1 && (
                    <Button
                      className="border border-solid border-grey rounded-md text-[#515151] font-medium hover:bg-transparent"
                      onClick={() => {
                        handleAddAction('PHONE_NUMBER');
                      }}
                    >
                      <AddIcon className="!text-[#515151] font-bold" />
                      {t('waba.broadcast.AddTemplate.callPhone')} (1)
                    </Button>
                  )}
                </div>
              )}
            </div>
            <div className="flex flex-wrap gap-12 justify-end mt-16">
              <Button
                className="rounded-md w-160"
                variant="outlined"
                color="quickChat"
                disabled={
                  headerType === 'text'
                    ? !headerText
                    : (headerType === 'image' && !imageUrl) ||
                      !selectCategory ||
                      !selectLanguage ||
                      !editorState.getCurrentContent().hasText()
                }
                onClick={() => setDiscardTemplateDialogue(true)}
              >
                {t('common.cancel')}
              </Button>
              <Button
                className="rounded-md w-196"
                variant="contained"
                color="quickChat"
                disabled={
                  headerType === 'text'
                    ? !headerText
                    : (headerType === 'image' && !imageUrl) ||
                      !selectCategory ||
                      !selectLanguage ||
                      !editorState.getCurrentContent().hasText() ||
                      refreshIcon
                }
                onClick={() => setTemplateSubmissionDialogue(true)}
              >
                {t('waba.broadcast.myBroadcast.submitForApproval')}
                {refreshIcon && (
                  <CircularProgress size={24} className="text-quick-chat absolute mx-auto" />
                )}
              </Button>
            </div>
          </div>
        </div>
        <div className="">
          <Typography className="font-semibold text-start text-24">
            {t('waba.broadcast.myBroadcast.preview')}
          </Typography>
          <div className="bg-white sticky top-0 text-center mt-24 rounded-md w-512">
            <div className="relative inline-block mx-auto">
              <img className="w-380 mt-20" src="assets/images/quick-chat/phone.png" alt="" />
              <div className="absolute top-96 left-0 right-0 rounded-md max-w-224 xs:max-w-256 mx-auto max-h-512 overflow-scroll">
                {(headerText ||
                  phoneBody.getCurrentContent().hasText() ||
                  footerText ||
                  imageUrl) && (
                  <div className="bg-white px-12 rounded-md pt-10">
                    {headerText && (
                      <Typography className="font-bold text-start">{headerText}</Typography>
                    )}
                    {imageUrl && <img src={imageUrl} className="mb-10 rounded-md" alt="..." />}
                    {phoneBody && (
                      <div
                        className="text-start"
                        style={{ whiteSpace: 'pre-line' }}
                        dangerouslySetInnerHTML={{
                          __html: convertTemplateToHtml(convertContentToHTML(phoneBody)),
                        }}
                      />
                    )}
                    {footerText && <Typography className="text-start">{footerText}</Typography>}
                  </div>
                )}
                <div>
                  {customizeButtons?.length > 0 &&
                    customizeButtons
                      ?.filter(
                        (buttonValue) =>
                          buttonValue.text || buttonValue.phone_number || buttonValue.url
                      )
                      .map((buttonValue, b) => (
                        <div
                          className="bg-white shadow p-4 rounded-md mt-4 h-32 text-center"
                          key={b}
                        >
                          <a
                            className="font-semibold text-[#0096DE] text-16"
                            href={
                              buttonValue.phone_number
                                ? `tel:${buttonValue.phone_number}`
                                : buttonValue.url || '#'
                            }
                            rel="noreferrer"
                            target="_blank"
                            role="button"
                          >
                            <img
                              src={
                                buttonValue?.phone_number
                                  ? '/assets/images/business/telephone.png'
                                  : '/assets/images/business/view.png'
                              }
                              className="w-14 h-14 align-middle mr-6 inline-block relative -top-1"
                              alt="..."
                            />
                            {buttonValue.text}
                          </a>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DiscardTemplate
        openDiscardTemplateDialogue={openDiscardTemplateDialogue}
        setDiscardTemplateDialogue={setDiscardTemplateDialogue}
        saveAsDraft={saveAsDraft}
        refreshIcon={refreshIcon}
        handleDiscardTemplate={handleDiscardTemplate}
      />
      <ConfirmTemplateSubmission
        templateSubmissionDialogue={templateSubmissionDialogue}
        setTemplateSubmissionDialogue={setTemplateSubmissionDialogue}
        setTemplateSubmittedDialogue={setTemplateSubmittedDialogue}
        handleCreateTemplate={handleCreateTemplate}
        refreshIcon={refreshIcon}
      />
      <TemplateSubmitted
        templateSubmittedDialogue={templateSubmittedDialogue}
        setTemplateSubmittedDialogue={setTemplateSubmittedDialogue}
      />
    </div>
  );
};

export default CreateOrEditTemplate;
