import CloseIcon from '@mui/icons-material/Close';
import ConformationDialogBox from 'src/app/main/CommonComponent/ConformationDialogBox';
import history from '@history';
import {
  Button,
  Checkbox,
  CircularProgress,
  Drawer,
  FormControl,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React, { useCallback, useEffect, useRef, useState, use } from 'react';
import { useTranslation } from 'react-i18next';
import userService from 'src/app/services';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import wabaQuery from 'src/app/main/query/waba';
import {
  formatDateWithTimeZone,
  handleWabaApiRequest,
  wabaImageSendToServer,
} from 'src/app/main/common/common';
import TagsAddEditPopUp from './TagsAddEditPopUp';

const WabaSetting = () => {
  const loginUserData = userService.getUserData();
  const { t } = useTranslation();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const countryId = Number(loginUserData?.countryId);
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [optInKeywords, setOptInKeywords] = useState(['Start']);
  const [optInMessage, setOptInMessage] = useState('');
  const [optOutMessage, setOptOutMessage] = useState('');
  const [response, setResponse] = useState('');
  const [optOutKeywords, setOptOutKeywords] = useState(['Stop']);
  const [drawerOpen, serDrawerOpen] = useState(false);
  const [timeZone, setTimeZone] = useState('asia');
  const [messageType, setMessageType] = useState('regularMessage');
  const [messageTypeText, setMessageTypeText] = useState('text');
  const [countCaption, setCountCaption] = useState(4100);
  const [caption, setCaption] = useState('');
  const maxLength = countCaption;
  const [video, setVideo] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const videoRef = useRef(null);
  const [image, setImage] = useState(null);
  const [document, setDocument] = useState(null);
  const [inOutKeywords, setInOutKeywords] = useState();
  const [optInStatus, setOptInStatus] = useState();
  const [optOutStatus, setOptOutStatus] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [message, setMessage] = useState({});
  const [offHourMessageStatus, setOffHourMessageStatus] = useState();
  const [welcomeMessageStatus, setWelcomeMessageStatus] = useState();
  const fileInputRef = useRef(null);
  const [workingHours, setWorkingHours] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [isInTyping, setIsInTyping] = useState(false);
  const [refreshIn, setRefreshIn] = useState(false);
  const [refreshOut, setRefreshOut] = useState(false);

  // tags
  const [tagsData, setTagsData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [hoveredRow, setHoveredRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [addOrEdit, setAddOrEdit] = useState('');
  const [total, setTotal] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [journey, setJourney] = useState(false);
  const [firstMessage, setFirstMessage] = useState(false);
  const [delType, setDelType] = useState('');

  // AccountDetails
  const [accountDetail, setAccountDetail] = useState();
  const [accountEdit, setAccountEdit] = useState(false);
  const [sector, setSector] = useState([]);
  const [selectedSector, setSelectedSector] = useState('');
  const [businessDescription, setBusinessDescription] = useState('');
  const [businessSector, setBusinessSector] = useState('');
  const [businessAddress, setBusinessAddress] = useState('');
  const [businessEmail, setBusinessEmail] = useState('');
  const [businessWebSite, setBusinessWebSite] = useState('');
  const fileInputRefProfile = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [profileImage, setProfileImage] = useState();
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (userCurrentBusinessDetails?.id) {
        try {
          const payload = {
            query: wabaQuery.getUserDetail,
            variables: {
              quickReviewBusinessId: userCurrentBusinessDetails?.id,
            },
          };
          const res = await handleWabaApiRequest(payload);
          if (res?.getUserDetail?.status === 200) {
            if (!res.getUserDetail.data) {
              history.push('quick-chat/waba-home/');
            }
          }
        } catch (error) {
          console.error('An error occurred:', error);
        }
      }
    };
    fetchUserDetails();
  }, [userCurrentBusinessDetails?.id]);

  // Tab 0 Api Calls
  const getOptInOptOutKeywords = useCallback(async () => {
    setPageLoading(true);
    try {
      const payload = {
        query: wabaQuery.getOptInOptOutKeywords,
      };
      const res = await handleWabaApiRequest(payload);
      if (res?.getOptInOptOutKeywords?.status === 200) {
        setInOutKeywords(res?.getOptInOptOutKeywords?.data);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
    setPageLoading(false);
  }, []);

  useEffect(() => {
    getOptInOptOutKeywords();
  }, [getOptInOptOutKeywords]);

  const getOptInOptOutMessages = useCallback(async () => {
    try {
      const payload = {
        query: wabaQuery.getOptInOptOutMessages,
      };
      const res = await handleWabaApiRequest(payload);
      if (res?.getOptInOptOutMessages?.status === 200) {
        setOptInStatus(res?.getOptInOptOutMessages?.data?.optInMessagesStatus);
        setOptOutStatus(res?.getOptInOptOutMessages?.data?.optOutMessagesStatus);
        setOptInMessage(res?.getOptInOptOutMessages?.data?.optInMessages);
        setOptOutMessage(res?.getOptInOptOutMessages?.data?.optOutMessages);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, []);

  useEffect(() => {
    getOptInOptOutMessages();
  }, [getOptInOptOutMessages]);

  const updateOptInOptOutMessages = async (InStatus, OutStatus, cap, resp, SwitchFrom) => {
    if (SwitchFrom === 'in') setRefreshIn(true);
    if (SwitchFrom === 'out') setRefreshOut(true);
    setRefresh(true);
    try {
      const payload = {
        query: wabaQuery.updateOptInOptOutMessages,
        variables: {
          optInMessagesStatus: InStatus,
          optOutMessagesStatus: OutStatus,
          optInMessages: resp === 'optIn' ? cap : optInMessage,
          optOutMessages: resp === 'optOut' ? cap : optOutMessage,
        },
      };
      const res = await handleWabaApiRequest(payload);
      if (res?.updateOptInOptOutMessages?.status === 200) {
        getOptInOptOutMessages();
        serDrawerOpen(false);
      } else {
        enqueueSnackbar(res?.updateOptInOptOutMessages?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
    setRefreshIn(false);
    setRefreshOut(false);
    setRefresh(false);
  };

  const addOrRemoveOptInOptOutKeywords = async (outKeyWords, task, from, id = null) => {
    try {
      const keywordsArray = outKeyWords
        ?.map((item) => (typeof item === 'string' ? item : item?.keyword))
        ?.filter(Boolean);
      const payload = {
        query: wabaQuery.addOrRemoveOptInOptOutKeywords,
        variables: {
          ...(from === 'OptIn'
            ? { optInKeyWords: keywordsArray }
            : { optOutKeywords: keywordsArray }),
          optKeywordId: id,
          task,
        },
      };
      const res = await handleWabaApiRequest(payload);
      if (res?.addOrRemoveOptInOptOutKeywords?.status === 200) {
        getOptInOptOutKeywords();
      } else {
        enqueueSnackbar(res?.addOrRemoveOptInOptOutKeywords?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
    setIsTyping(false);
    setIsInTyping(false);
  };

  // Tab 1 Api Calls
  const getWelcomeAndOffHourMessageSettings = useCallback(async () => {
    setPageLoading(true);
    try {
      const payload = {
        query: wabaQuery.getWelcomeAndOffHourMessageSettings,
      };
      const res = await handleWabaApiRequest(payload);
      if (res?.getWelcomeAndOffHourMessageSettings?.status === 200) {
        setMessage(res?.getWelcomeAndOffHourMessageSettings?.data);
        setOffHourMessageStatus(
          res?.getWelcomeAndOffHourMessageSettings?.data?.offHourMessageStatus
        );
        setWelcomeMessageStatus(
          res?.getWelcomeAndOffHourMessageSettings?.data?.welcomeMessageStatus
        );
        setWorkingHours(
          res?.getWelcomeAndOffHourMessageSettings?.data?.workingHours?.map((item) => ({
            ...item,
            loader: false,
          }))
        );
        setTimeZone(res?.getWelcomeAndOffHourMessageSettings?.data?.timeZone);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
    setPageLoading(false);
  }, []);

  useEffect(() => {
    if (selectedTab === 1) {
      getWelcomeAndOffHourMessageSettings();
    }
  }, [getWelcomeAndOffHourMessageSettings, selectedTab]);

  const updateWelcomeAndOffHourMessageSettings = useCallback(
    async (offHourMessageStatus1, timeZone1, welcomeMessageStatus1, workingHours1) => {
      setRefresh(true);
      try {
        const payload = {
          query: wabaQuery.updateWelcomeAndOffHourMessageSettings,
          variables: {
            offHourMessageData: {
              text:
                response === 'offHour' && messageType === 'text'
                  ? caption
                  : message?.offHourMessageData?.text,
              messageType:
                response === 'offHour' ? messageType : message?.offHourMessageData?.messageType,
              messageTypeFormat:
                response === 'offHour'
                  ? messageTypeText
                  : message?.offHourMessageData?.messageTypeFormat,
              caption:
                response === 'offHour' && messageType !== 'text'
                  ? caption
                  : message?.offHourMessageData?.caption,
              mediaUrl: 'mediaUrl',
            },
            welcomeMessageData: {
              // text:
              //   response === 'welcome' && messageType === 'text'
              //     ? caption
              //     : message?.welcomeMessageData?.text,
              text: caption,
              messageType:
                response === 'welcome' ? messageType : message?.welcomeMessageData?.messageType,
              messageTypeFormat:
                response === 'welcome'
                  ? messageTypeText
                  : message?.welcomeMessageData?.messageTypeFormat,
              // caption:
              //   response === 'welcome' && messageType !== 'text'
              //     ? caption
              //     : message?.welcomeMessageData?.caption,
              caption: message?.welcomeMessageData?.caption,
              mediaUrl: 'mediaUrl',
            },
            offHourMessageStatus: offHourMessageStatus1,
            timeZone: timeZone1,
            welcomeMessageStatus: welcomeMessageStatus1,
            workingHours: workingHours1,
          },
        };
        const res = await handleWabaApiRequest(payload);
        if (res?.updateWelcomeAndOffHourMessageSettings?.status === 200) {
          getWelcomeAndOffHourMessageSettings();
          serDrawerOpen(false);
          enqueueSnackbar(res?.updateWelcomeAndOffHourMessageSettings?.message, {
            variant: 'success',
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar(res?.updateWelcomeAndOffHourMessageSettings?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
      setRefresh(false);
    },
    [
      caption,
      enqueueSnackbar,
      getWelcomeAndOffHourMessageSettings,
      message?.offHourMessageData?.caption,
      message?.offHourMessageData?.messageType,
      message?.offHourMessageData?.messageTypeFormat,
      message?.offHourMessageData?.text,
      message?.welcomeMessageData?.caption,
      message?.welcomeMessageData?.messageType,
      message?.welcomeMessageData?.messageTypeFormat,
      messageType,
      messageTypeText,
      response,
    ]
  );

  // Tab 2 Api Calls(Tags)

  const getWaChatTags = useCallback(async () => {
    setPageLoading(true);
    try {
      const payload = {
        query: wabaQuery.getWaChatTags,
        variables: {
          pageNumber,
          pageSize,
          searchText,
        },
      };
      const res = await handleWabaApiRequest(payload);
      if (res?.getWaChatTags?.status === 200) {
        setTagsData(res?.getWaChatTags?.data);
        setTotal(res?.getWaChatTags?.totalCount);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
    setPageLoading(false);
  }, [pageNumber, pageSize, searchText]);

  useEffect(() => {
    if (selectedTab === 2) {
      getWaChatTags();
    }
  }, [getWaChatTags, selectedTab]);

  const deleteWaChatTags = async () => {
    try {
      const payload = {
        query: wabaQuery.deleteWaChatTags,
        variables: {
          tagIds: delType === 'multi' ? selectedRows : [Number(selectedRow?.id)],
        },
      };
      const res = await handleWabaApiRequest(payload);
      if (res?.deleteWaChatTags?.status === 200) {
        setIsDeleteDialogOpen(false);
        setSelectedRow([]);
        setSelectedRows([]);
        getWaChatTags();
      } else {
        enqueueSnackbar(res?.deleteWaChatTags?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const getRandomColor = () => {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  };

  const createAndUpdateWaChatTag = async (
    colorCode1,
    customerJourney1,
    firstMessage1,
    tagId1,
    tagName1
  ) => {
    try {
      const payload = {
        query: wabaQuery.createAndUpdateWaChatTag,
        variables: {
          colorCode: addOrEdit === 'edit' ? colorCode1 : getRandomColor(),
          customerJourney: customerJourney1,
          firstMessage: firstMessage1,
          tagId: tagId1,
          tagName: tagName1,
        },
      };
      const res = await handleWabaApiRequest(payload);
      if (res?.createAndUpdateWaChatTag?.status === 200) {
        getWaChatTags();
        setIsEditDialogOpen(false);
        setAddOrEdit('');
        setInputValue('');
        setFirstMessage(false);
        setJourney(false);
        setSelectedRow([]);
      } else {
        enqueueSnackbar(res?.createAndUpdateWaChatTag?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  // Tab 3 Api Calls(Account Details)

  const getWaUserProfileDetail = useCallback(async () => {
    setPageLoading(true);
    try {
      const payload = {
        query: wabaQuery.getWaUserProfileDetail,
        variables: {
          quickReviewBusinessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const res = await handleWabaApiRequest(payload);
      if (res?.getWaUserProfileDetail?.status === 200) {
        setAccountDetail(res?.getWaUserProfileDetail?.data);
        setBusinessDescription(res?.getWaUserProfileDetail?.data?.description);
        setBusinessSector(res?.getWaUserProfileDetail?.data?.businessSector);
        setBusinessAddress(res?.getWaUserProfileDetail?.data?.address);
        setBusinessEmail(res?.getWaUserProfileDetail?.data?.email);
        setBusinessWebSite(res?.getWaUserProfileDetail?.data?.websites);
        setSelectedImage(res?.getWaUserProfileDetail?.data?.profileUrl);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
    setPageLoading(false);
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (selectedTab === 3 && userCurrentBusinessDetails?.id) {
      getWaUserProfileDetail();
    }
  }, [getWaUserProfileDetail, selectedTab, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    const getWaBusinessSectors = async () => {
      setPageLoading(true);
      try {
        const payload = {
          query: wabaQuery.getWaBusinessSectors,
        };
        const res = await handleWabaApiRequest(payload);
        if (res?.getWaBusinessSectors?.status === 200) {
          setSector(res?.getWaBusinessSectors?.data);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
      setPageLoading(false);
    };
    getWaBusinessSectors();
  }, []);

  const updateWaUserProfileDetail = async () => {
    setRefresh(true);
    try {
      const payload = {
        query: wabaQuery.updateWaUserProfileDetail,
        variables: {
          quickReviewBusinessId: Number(userCurrentBusinessDetails?.id),
          description: businessDescription,
          address: businessAddress,
          website: businessWebSite,
          businessSector,
          email: businessEmail,
          image: profileImage,
          removeProfile: false,
        },
      };

      const res = await wabaImageSendToServer(payload);
      if (res?.updateWaUserProfileDetail?.status === 200) {
        getWaUserProfileDetail();
        setAccountEdit(false);
        setBusinessDescription('');
        setBusinessSector('');
        setBusinessAddress('');
        setBusinessEmail('');
        setBusinessWebSite('');
        setProfileImage();
      } else {
        enqueueSnackbar(res?.updateWaUserProfileDetail?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
    setRefresh(false);
  };

  useEffect(() => {
    if (inOutKeywords?.optIn) {
      const uniqueKeywords = new Set();
      const filteredOptInKeywords = inOutKeywords?.optIn
        ?.filter((item) => {
          const formattedKeyword = item?.keyword;
          if (!uniqueKeywords.has(formattedKeyword)) {
            uniqueKeywords.add(formattedKeyword);
            return true;
          }
          return false;
        })
        ?.map((item) => ({
          ...item,
          keyword: item.keyword,
        }));
      setOptInKeywords(filteredOptInKeywords);
    }
  }, [inOutKeywords]);

  useEffect(() => {
    if (inOutKeywords?.optOut) {
      const uniqueKeywords = new Set();
      const filteredOptOutKeywords = inOutKeywords?.optOut
        .filter((item) => {
          const formattedKeyword = item.keyword;
          if (!uniqueKeywords.has(formattedKeyword)) {
            uniqueKeywords.add(formattedKeyword);
            return true;
          }
          return false;
        })
        .map((item) => ({
          ...item,
          keyword: item.keyword,
        }));
      setOptOutKeywords(filteredOptOutKeywords);
    }
  }, [inOutKeywords]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleAddMore = () => {
    if (optOutKeywords?.length < 3) {
      setOptOutKeywords([...optOutKeywords, '']);
    }
  };

  const handleInputChange = (index, value) => {
    setIsTyping(true);
    const updatedKeywords = [...optOutKeywords];
    updatedKeywords[index] = value;
    setOptOutKeywords(updatedKeywords);
  };

  const handleRemoveField = (index, id) => {
    if (id) {
      addOrRemoveOptInOptOutKeywords(optOutKeywords, 'REMOVE', 'OptOut', id);
    }
    const updatedKeywords = optOutKeywords?.filter((_, i) => i !== index);
    setOptOutKeywords(updatedKeywords);
  };

  const handleInAddMore = () => {
    if (optInKeywords?.length < 3) {
      setOptInKeywords([...optInKeywords, '']);
    }
  };

  const handleInInputChange = (index, value) => {
    setIsInTyping(true);
    const updatedKeywords = [...optInKeywords];
    updatedKeywords[index] = value;
    setOptInKeywords(updatedKeywords);
  };

  const handleInRemoveField = (index, id) => {
    if (id) {
      addOrRemoveOptInOptOutKeywords(optInKeywords, 'REMOVE', 'OptIn', id);
    }
    const updatedKeywords = optInKeywords?.filter((_, i) => i !== index);
    setOptInKeywords(updatedKeywords);
  };

  const handleToggle = (index) => {
    setWorkingHours((prev) =>
      prev?.map((item, i) =>
        i === index
          ? {
              ...item,
              toggle: !item?.toggle,
              setTime: item?.toggle
                ? ''
                : {
                    hours: new Date().getHours(),
                    minutes: new Date().getMinutes(),
                  },
              offTime: item?.toggle
                ? ''
                : {
                    hours: new Date().getHours(),
                    minutes: new Date().getMinutes(),
                  },
            }
          : item
      )
    );
  };

  const handleTimeChange = (index, field, value) => {
    if (!value) return;
    setWorkingHours((prev) =>
      prev?.map((item, i) =>
        i === index
          ? {
              ...item,
              [field]: {
                hours: value?.getHours(),
                minutes: value?.getMinutes(),
              },
            }
          : item
      )
    );
  };

  const handleChange = (event) => {
    const inputText = event.target.value;
    if (inputText?.length <= maxLength) {
      setCaption(inputText);
    }
  };

  const handleDrop = (event, type) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleFileUpload({ target: { files: [file] } }, type);
  };

  const handleFileUpload = (event, type) => {
    const file = event.target.files[0];
    if (!file) return;

    const fileType = file.type;
    const fileName = file.name.toLowerCase();
    if (type === 'video' && fileType === 'video/mp4') {
      setVideo(file);
    } else if (type === 'image' && (fileType === 'image/png' || fileType === 'image/jpeg')) {
      setImage(file);
    } else if (
      type === 'document' &&
      (fileName.endsWith('.pdf') || fileName.endsWith('.docx') || fileName.endsWith('.xlsx'))
    ) {
      setDocument(file);
    } else {
      console.error('Invalid file type');
    }
  };

  const extractThumbnail = (videoSrc) => {
    const videoOne = document.createElement('video');
    videoOne.src = videoSrc;
    videoOne.crossOrigin = 'anonymous';
    videoOne.currentTime = 3;

    videoOne.onloadeddata = () => {
      const canvas = document.createElement('canvas');
      canvas.width = videoOne.videoWidth;
      canvas.height = videoOne.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(videoOne, 0, 0, canvas.width, canvas.height);
      setThumbnail(canvas.toDataURL('image/png'));
    };
  };

  const handleEditClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSelectRow = (id) => {
    setSelectedRows((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };

  const handleSelectAll = (event) => {
    setSelectedRows(event.target.checked ? tagsData.map((row) => row.id) : []);
  };

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  const copyToClipboard = (number) => {
    const textToCopy = `https://whatsapp.quickreviews.click/unique-id?uniqueId=${number}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        enqueueSnackbar('Copied to clipboard!', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  const handleSectorChange = (event) => {
    setBusinessSector(event.target.value);
  };

  const selectedDescription =
    sector.find((sectors) => sectors.name === businessSector)?.description || 'Select a sector';

  const handleButtonClick = () => {
    fileInputRefProfile.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfileImage(file);
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  if (pageLoading) {
    return <FuseLoading />;
  }
  return (
    <div className="p-20 lg:p-24 h-full l-padding">
      <Typography className="sm:text-28 text-24 font-semibold ">
        {t(`waba.setting.title`)}
      </Typography>
      <div className="border-solid border-grey sm:flex justify-between items-center border-b-2 quick-chat mb-20">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="quickChat"
          aria-label="secondary tabs example"
          sx={{
            '& .MuiTabs-flexContainer': {
              display: 'flex',
              justifyContent: 'space-evenly', // Even spacing between tabs
              overflowX: 'auto',
            },
            '& .MuiTab-root': {
              minWidth: 'unset',
              width: 'auto',
            },
          }}
        >
          <Tab
            className={`sm:text-18 mr-20 p-0 ${
              selectedTab === 0 ? 'text-quick-chat font-bold' : 'text-black font-medium'
            }`}
            value={0}
            label={t('waba.setting.tab1')}
          />
          <Tab
            className={`sm:text-18 mr-20 p-0 ${
              selectedTab === 1 ? 'text-quick-chat font-bold' : 'text-black font-medium'
            }`}
            value={1}
            label={t('waba.setting.tab2')}
          />
          <Tab
            sx={{
              minWidth: 'unset',
            }}
            className={`sm:text-18 mr-20 p-0 text-left w-auto ${
              selectedTab === 2 ? 'text-quick-chat font-bold' : 'text-black font-medium'
            }`}
            value={2}
            label={t('waba.setting.tab3')}
          />
          <Tab
            className={`sm:text-18 mr-20 p-0 ${
              selectedTab === 3 ? 'text-quick-chat font-bold' : 'text-black font-medium'
            }`}
            value={3}
            label={t('waba.setting.tab4')}
          />
        </Tabs>
      </div>
      {selectedTab === 0 && (
        <>
          <div className="sm:flex bg-white p-20 mb-20 rounded-md">
            <div className="sm:border-r-2 border-gray-300 sm:border-b-0 border-b-2 sm:w-1/2 sm:pr-20 sm:pb-0 pb-20">
              <Typography className="text-20 font-semibold mb-16">
                {t('waba.setting.optOutKeywords')}
              </Typography>
              <p className="text-16 font-medium mb-16">{t('waba.setting.optOutKeywordsContent')}</p>
              <p className="text-12 text-grey-700 mb-12">
                {t('waba.setting.optOutKeywordsContent1')}
              </p>
              <div className="max-w-full mb-24 ">
                {optOutKeywords?.map((keyword, index) => (
                  <div key={index} className="flex justify-center items-center mb-9">
                    <TextField
                      placeholder={t('botResponses.enterYourQuestion')}
                      variant="outlined"
                      fullWidth
                      type="text"
                      value={keyword?.keyword}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      className="text-14 quick-chat"
                      color="quickChat"
                      disabled={!!keyword?.id}
                      sx={{
                        '& .MuiOutlinedInput-input': {
                          padding: '12px',
                        },
                      }}
                    />
                    {index > 0 && (
                      <Button
                        onClick={() => {
                          handleRemoveField(index, keyword?.id);
                        }}
                        className="ml-2 text-black text-24"
                      >
                        <CloseIcon />
                      </Button>
                    )}
                  </div>
                ))}
                {optOutKeywords?.length < 3 && (
                  <Button
                    onClick={handleAddMore}
                    color="quickChat"
                    className="flex justify-around items-center hover:bg-white"
                  >
                    + {t('waba.setting.addMore')}
                  </Button>
                )}
              </div>
              <Button
                color="quickChat"
                variant="contained"
                className="rounded-md"
                size="medium"
                onClick={() => addOrRemoveOptInOptOutKeywords(optOutKeywords, 'ADD', 'OptOut')}
                disabled={
                  optOutKeywords?.length === inOutKeywords?.optOut?.length ||
                  (optOutKeywords?.length === 2 &&
                    optOutKeywords[1]?.length === 0 &&
                    optOutKeywords[2]?.length === 0) ||
                  (optOutKeywords?.length === 2 && optOutKeywords[1]?.length === 0)
                }
              >
                Save Settings
              </Button>
            </div>
            <div className="sm:pl-20 sm:w-1/2 sm:pt-0 pt-20">
              <div className="flex justify-between items-center mb-36">
                <div>
                  <Typography className="text-20 font-semibold mb-14">
                    {t('waba.setting.optOutResponse')}
                  </Typography>
                  <p className="text-16 font-medium ">{t('waba.setting.optOutResponseContent')}</p>
                </div>
                {refreshOut ? (
                  <div className="flex justify-end items-center">
                    <CircularProgress size={24} className="text-darkgreen mx-auto" />
                  </div>
                ) : (
                  <Switch
                    checked={optOutStatus}
                    onClick={() =>
                      updateOptInOptOutMessages(
                        optInStatus,
                        !optOutStatus,
                        optInMessage,
                        optOutMessage,
                        'out'
                      )
                    }
                  />
                )}
              </div>
              <div className="">
                <p className="p-10 bg-gray-A500 text-16 font-medium mb-36 relative sm:ml-10 rounded-md">
                  <WhatsAppIcon className="absolute text-white bg-[#2DDC4F] -left-10 -top-12 rounded-full p-2" />
                  {optOutMessage}
                </p>
              </div>

              <Button
                onClick={() => {
                  setCaption(optOutMessage);
                  serDrawerOpen(true);
                  setResponse('optOut');
                }}
                color="quickChat"
                className="rounded-md"
                size="medium"
                variant="contained"
              >
                {t('botResponses.editResponse')}
              </Button>
            </div>
          </div>
          <div className="sm:flex bg-white p-20 rounded-md">
            <div className="sm:border-r-2 border-gray-300 sm:border-b-0 border-b-2 sm:w-1/2 sm:pr-20 sm:pb-0 pb-20">
              <Typography className="text-20 font-semibold mb-16">
                {t('waba.setting.optInKeywords')}
              </Typography>
              <p className="text-16 font-medium mb-16">{t('waba.setting.optInKeywordsContent')}</p>
              <p className="text-12 text-grey-700 mb-12">
                {t('waba.setting.optOutKeywordsContent1')}
              </p>
              <div className="max-w-full mb-24 ">
                {optInKeywords?.map((keyword, index) => (
                  <div key={index} className="flex justify-center items-center mb-9">
                    <TextField
                      placeholder="Enter..."
                      variant="outlined"
                      fullWidth
                      type="text"
                      value={keyword?.keyword}
                      onChange={(e) => handleInInputChange(index, e.target.value)}
                      className="text-14 quick-chat"
                      color="quickChat"
                      disabled={!!keyword?.id}
                      sx={{
                        '& .MuiOutlinedInput-input': {
                          padding: '12px',
                        },
                      }}
                    />
                    {index > 0 && (
                      <Button
                        onClick={() => handleInRemoveField(index, keyword?.id)}
                        className="ml-2 text-black text-24"
                      >
                        <CloseIcon />
                      </Button>
                    )}
                  </div>
                ))}
                {optInKeywords?.length < 3 && (
                  <Button
                    onClick={handleInAddMore}
                    color="quickChat"
                    className="flex justify-around items-center hover:bg-white"
                  >
                    + {t('waba.setting.addMore')}
                  </Button>
                )}
              </div>
              <Button
                color="quickChat"
                variant="contained"
                className="rounded-md"
                size="medium"
                onClick={() => addOrRemoveOptInOptOutKeywords(optInKeywords, 'ADD', 'OptIn')}
                disabled={
                  optInKeywords?.length === inOutKeywords?.optIn?.length ||
                  (optInKeywords?.length === 2 &&
                    optInKeywords[1]?.length === 0 &&
                    optInKeywords[2]?.length === 0) ||
                  (optInKeywords?.length === 2 && optInKeywords[1]?.length === 0)
                }
              >
                Save Settings
              </Button>
            </div>
            <div className="sm:pl-20 sm:w-1/2 sm:pt-0 pt-20">
              <div className="flex justify-between items-center mb-36">
                <div>
                  <Typography className="text-20 font-semibold mb-14">
                    {t('waba.setting.optInResponse')}
                  </Typography>
                  <p className="text-16 font-medium ">{t('waba.setting.optOutResponseContent')}</p>
                </div>

                {refreshIn ? (
                  <div className="flex justify-end items-center">
                    <CircularProgress size={24} className="text-darkgreen mx-auto" />
                  </div>
                ) : (
                  <Switch
                    checked={optInStatus}
                    onClick={() =>
                      updateOptInOptOutMessages(
                        !optInStatus,
                        optOutStatus,
                        optInMessage,
                        optOutMessage,
                        'in'
                      )
                    }
                  />
                )}
              </div>
              <div>
                <p className="p-10 bg-gray-A500 text-16 font-medium mb-36 relative sm:ml-10 rounded-md">
                  <WhatsAppIcon className="absolute text-white bg-[#2DDC4F] -left-10 -top-12 rounded-full p-2" />
                  {optInMessage}
                </p>
              </div>
              <Button
                onClick={() => {
                  setCaption(optInMessage);
                  serDrawerOpen(true);
                  setResponse('optIn');
                }}
                color="quickChat"
                className="rounded-md"
                size="medium"
                variant="contained"
              >
                {t('botResponses.editResponse')}
              </Button>
            </div>
          </div>
        </>
      )}
      {selectedTab === 1 && (
        <div>
          <div className="sm:flex mb-20">
            <div className="bg-white sm:p-20 p-16 sm:mr-24 mr-0 sm:mb-0 mb-16 sm:w-1/2 rounded-md">
              <div className="justify-between flex mb-36">
                <div className="max-w-400">
                  <Typography className="text-20 font-semibold mb-16">
                    {t('waba.setting.welcomeMessage')}
                  </Typography>
                  <Typography className="text-16 font-medium">
                    {t('waba.setting.welcomeMessageContent')}
                  </Typography>
                </div>
                {refresh ? (
                  <div className="flex justify-end items-center mr-10">
                    <CircularProgress size={24} className="text-darkgreen mx-auto" />
                  </div>
                ) : (
                  <div className="flex justify-end items-center">
                    <Switch
                      checked={message?.welcomeMessageStatus}
                      onClick={() => {
                        setWelcomeMessageStatus(!message?.welcomeMessageStatus);
                        updateWelcomeAndOffHourMessageSettings(
                          offHourMessageStatus,
                          timeZone,
                          !message?.welcomeMessageStatus,
                          workingHours
                        );
                      }}
                    />
                  </div>
                )}
              </div>
              <div>
                <p className="p-10 bg-gray-A500 text-16 font-medium mb-36 relative sm:ml-10 rounded-md">
                  <WhatsAppIcon className="absolute text-white bg-[#2DDC4F] -left-10 -top-12 rounded-full p-2" />
                  {message?.welcomeMessageData?.text}
                </p>
              </div>
              <Button
                onClick={() => {
                  serDrawerOpen(true);
                  setMessageType(message?.welcomeMessageData?.messageType);
                  setMessageTypeText(message?.welcomeMessageData?.messageTypeFormat);
                  // setCaption(
                  //   message?.welcomeMessageData?.messageTypeFormat === 'text'
                  //     ? message?.welcomeMessageData?.text
                  //     : message?.welcomeMessageData?.caption
                  // );
                  setCaption(message?.welcomeMessageData?.text);
                  setResponse('welcome');
                }}
                color="quickChat"
                className="rounded-md"
                size="medium"
                variant="contained"
              >
                {t('botResponses.editResponse')}
              </Button>
            </div>
            {/* <div className="bg-white sm:p-20 p-16 sm:w-1/2">
              <div className="justify-between flex mb-20">
                <div className="max-w-400">
                  <Typography className="text-20 font-semibold mb-16">
                    {t("waba.setting.offHoursMessage")}
                  </Typography>
                  <Typography className="text-16 font-medium">
                    {t("waba.setting.offHoursMessageContent")}
                  </Typography>
                </div>
                <Switch
                  checked={message?.offHourMessageStatus}
                  onClick={() => {
                    setOffHourMessageStatus(!message?.offHourMessageStatus);
                    updateWelcomeAndOffHourMessageSettings(
                      !message?.offHourMessageStatus,
                      timeZone,
                      welcomeMessageStatus,
                      workingHours
                    );
                  }}
                />
              </div>
              <div>
                <p className="p-10 bg-gray-A500 text-16 font-medium mb-36 relative sm:ml-10 rounded-md">
                  <WhatsAppIcon className="absolute text-white bg-[#2DDC4F] -left-10 -top-12 rounded-full p-2" />
                  {message?.offHourMessageData?.text}
                </p>
              </div>
              <Button
                onClick={() => {
                  serDrawerOpen(true);
                  setMessageType(message?.offHourMessageData?.messageType);
                  setMessageTypeText(
                    message?.offHourMessageData?.messageTypeFormat
                  );
                  setCaption(
                    message?.offHourMessageData?.messageTypeFormat === "text"
                      ? message?.offHourMessageData?.text
                      : message?.offHourMessageData?.caption
                  );
                  setResponse("offHour");
                }}
                color="quickChat"
                className="rounded-md"
                size="medium"
                variant="contained"
              >
                {t("botResponses.editResponse")}
              </Button>
            </div> */}
          </div>
          {/* <div className="p-24 bg-white ">
            <Typography className="text-20 font-semibold mb-24">
              {t("primaryDetails.workingHours")}
            </Typography>
            <div className="sm:flex items-center gap-28 mb-24">
              <Typography className="text-20 font-semibold md:mb-0 mb-10 ">
                {t("waba.setting.timezone")}
              </Typography>
              <FormControl className="mb-4 quick-chat ">
                <Select
                  value={timeZone}
                  onChange={(e) => {
                    setTimeZone(e.target.value);
                    updateWelcomeAndOffHourMessageSettings(
                      offHourMessageStatus,
                      e.target.value,
                      welcomeMessageStatus,
                      workingHours
                    );
                  }}
                >
                  <MenuItem value="asia">Asia/Calcutta (+05:30)</MenuItem>
                  <MenuItem value="usa">America/New York (-05:00)</MenuItem>
                  <MenuItem value="europe">Europe/London (+00:00)</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              {workingHours?.map((day, index) => (
                <div key={index} className="sm:flex items-center mb-20">
                  <Typography className="text-20 font-medium w-128">
                    {" "}
                    {day?.day}
                  </Typography>
                  <div className="gap-20 flex items-center">
                    <Typography className="text-16 font-medium ">
                      {day?.toggle ? "Open" : "Close"}
                    </Typography>
                    <Switch
                      checked={day.toggle}
                      onChange={() => handleToggle(index)}
                    />
                  </div>
                  {day?.toggle ? (
                    <>
                      <div className="">
                        <TimePicker
                          className="max-w-320 h-44"
                          id="time"
                          value={
                            day?.setTime
                              ? new Date(
                                  1970,
                                  0,
                                  1,
                                  day.setTime.hours || 0,
                                  day.setTime.minutes || 0
                                )
                              : null
                          }
                          onChange={(e) => {
                            handleTimeChange(index, "setTime", e);
                          }}
                          size="small"
                          required
                          ampm={false}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                        />
                      </div>
                      <Typography className="text-16 font-medium md:mx-20 mx-20 md:my-0 my-10">
                        To
                      </Typography>
                      <div className="">
                        <TimePicker
                          className="max-w-320 h-44"
                          id="time"
                          value={
                            day?.offTime
                              ? new Date(
                                  1970,
                                  0,
                                  1,
                                  day.offTime.hours || 0,
                                  day.offTime.minutes || 0
                                )
                              : null
                          }
                          onChange={(e) =>
                            handleTimeChange(index, "offTime", e)
                          }
                          size="small"
                          ampm={false}
                          required
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="p-12 flex bg-gray-A500 rounded-md border-2 border-grey-300 w-216">
                      <BedtimeSharpIcon className="mr-12" />
                      <Typography className="text-16 font-medium">
                        Closed
                      </Typography>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div> */}
        </div>
      )}
      {selectedTab === 2 && (
        <div className="max-w-6xl">
          <div className="flex justify-between mb-16">
            <Typography className="text-20 font-semibold flex justify-center items-center ">
              {' '}
              {t('waba.setting.tab3')}
            </Typography>
            <div className="gap-20 flex">
              <TextField
                placeholder="Search..."
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  setPageNumber(1);
                }}
                className="max-h-40 md:w-640 max-w-640 quick-chat bg-white"
              />
              <Button
                color="quickChat"
                variant="contained"
                className="rounded-md py-12 px-16"
                size="medium"
                onClick={() => {
                  setIsEditDialogOpen(true);
                  setAddOrEdit('add');
                }}
              >
                +{t('waba.setting.addTag')}
              </Button>
            </div>
          </div>
          <TableContainer component={Paper} className="rounded-sm">
            <Table>
              <TableHead className="">
                <TableRow className="border-2 border-b-grey h-44">
                  <TableCell className="h-24 p-5 w-16">
                    <Checkbox
                      checked={selectedRows?.length === tagsData?.length}
                      onChange={handleSelectAll}
                      indeterminate={
                        selectedRows?.length > 0 && selectedRows?.length < tagsData?.length
                      }
                    />
                  </TableCell>
                  {selectedRows?.length > 0 ? (
                    <>
                      <TableCell className=" h-24 flex justify-between items-center w-full ">
                        <Typography className="text-18 font-semibold">
                          Selected({selectedRows?.length})
                        </Typography>
                      </TableCell>
                      <TableCell className="h-24 p-5 w-256 text-18 font-semibold" />
                      <TableCell className="h-24 p-5 w-256 text-18 font-semibold" />
                      <TableCell className="h-24 p-5 text-18 font-semibold flex justify-end">
                        <Button
                          variant="outlined"
                          color="quickChat"
                          className="rounded-md justify-center flex items-center "
                          onClick={() => {
                            setIsDeleteDialogOpen(true);
                            setDelType('multi');
                          }}
                        >
                          <DeleteIcon className="h-20 w-20 mr-8" />
                          Delete
                        </Button>
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell className="h-24 p-5 w-400 text-18 font-semibold">
                        {t('waba.setting.tab3')}
                      </TableCell>
                      <TableCell className="h-24 p-5 w-256 text-18 font-semibold">
                        {t('waba.setting.customerJourney')}
                      </TableCell>
                      <TableCell className="h-24 p-5 w-256 text-18 font-semibold">
                        {t('waba.setting.createdOn')}
                      </TableCell>
                      <TableCell className="h-24 p-5 text-18 font-semibold"> </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {tagsData?.map((data, index) => (
                  <TableRow
                    key={index}
                    className="hover:bg-quick-chat-100 border-b-2 border-gray-300"
                    onMouseEnter={() => setHoveredRow(data?.id)}
                    onMouseLeave={() => setHoveredRow(null)}
                  >
                    <TableCell className="h-24 max-h-24 p-5 w-16">
                      <Checkbox
                        checked={selectedRows.includes(data.id)}
                        onChange={() => handleSelectRow(data.id)}
                      />{' '}
                    </TableCell>
                    <TableCell className="h-24 max-h-24 text-18 font-medium p-5 w-400 ">
                      <div className="flex items-center gap-12">
                        <span
                          className="h-10 w-10 rounded-full "
                          style={{ backgroundColor: data?.colorCode }}
                        >
                          {' '}
                        </span>
                        {data?.tagName}
                      </div>
                    </TableCell>
                    <TableCell className="h-24 max-h-24 text-18 font-medium p-5 w-256">
                      {data?.customerJourney ? 'Yes' : 'No'}
                    </TableCell>
                    <TableCell className="h-24 max-h-24 text-18 font-medium p-5 w-256">
                      {formatDateWithTimeZone(data?.cts)}
                    </TableCell>
                    <TableCell className="h-24 max-h-24 p-5">
                      {hoveredRow === data.id && (
                        <div className="flex gap-16 justify-end px-12">
                          <button
                            type="button"
                            className="cursor-pointer bg-quick-chat-200 hover:bg-white rounded-full p-3 flex justify-center items-center"
                            onClick={() => {
                              setIsEditDialogOpen(true);
                              setAddOrEdit('edit');
                              setInputValue(data?.tagName);
                              setFirstMessage(data?.firstMessage);
                              setJourney(data?.customerJourney);
                              setSelectedRow(data);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                setIsEditDialogOpen(true);
                                setAddOrEdit('edit');
                                setInputValue(data?.tagName);
                                setFirstMessage(data?.firstMessage);
                                setJourney(data?.customerJourney);
                                setSelectedRow(data);
                              }
                            }}
                            aria-label="Edit"
                          >
                            <EditIcon className="h-20 w-20 text-grey-600" />
                          </button>
                          <button
                            type="button"
                            className="cursor-pointer bg-quick-chat-200 hover:bg-white rounded-full p-3  flex justify-center items-center"
                            onClick={() => {
                              setIsDeleteDialogOpen(true);
                              setSelectedRow(data);
                              setDelType('single');
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                setIsDeleteDialogOpen(true);
                                setSelectedRow(data);
                                setDelType('single');
                              }
                            }}
                            aria-label="Delete"
                          >
                            <DeleteIcon className="h-20 w-20 text-grey-600" />
                          </button>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {total > 10 && (
            <div className="bg-white px-28 sm:px-52 py-20 sm:py-32 bottom-0 right-0 w-full flex justify-end">
              <Pagination
                count={Math.ceil(total / 10)}
                page={pageNumber}
                onChange={handlePageChange}
                showFirstButton
                showLastButton
              />
            </div>
          )}
          {isDeleteDialogOpen && (
            <ConformationDialogBox
              open={isDeleteDialogOpen}
              onClose={() => {
                setIsDeleteDialogOpen(false);
                setSelectedRow([]);
              }}
              additionalInfo={t('waba.setting.deleteNote')}
              onDelete={() => deleteWaChatTags()}
              confirmationText={t('quickCampaignsContacts.confirmMessage')}
              deleteImage="/assets/images/icon/trashBin.png"
              backgroundColor="quick-chat"
              textColor="quick-chat"
              buttonText={t('common.delete')}
            />
          )}
          {isEditDialogOpen && (
            <TagsAddEditPopUp
              open={isEditDialogOpen}
              onClose={() => {
                setIsEditDialogOpen(false);
                setAddOrEdit('');
                setInputValue('');
                setFirstMessage(false);
                setJourney(false);
                setSelectedRow([]);
              }}
              from={addOrEdit}
              backgroundColor="quick-chat"
              inputValue={inputValue}
              setInputValue={setInputValue}
              journey={journey}
              setJourney={setJourney}
              firstMessage={firstMessage}
              setFirstMessage={setFirstMessage}
              onClick={() =>
                createAndUpdateWaChatTag(
                  selectedRow?.colorCode,
                  journey,
                  firstMessage,
                  selectedRow?.id,
                  inputValue
                )
              }
            />
          )}
        </div>
      )}
      {selectedTab === 3 && (
        <div>
          <div className="bg-white p-16 max-w-512">
            <div className="flex justify-between items-start">
              <div className="flex mb-12 gap-14">
                {accountDetail?.profileUrl ? (
                  <img src={accountDetail?.profileUrl} alt="" className="h-60 w-60" />
                ) : (
                  <div className="h-60 w-60 flex justify-center items-center bg-gray">
                    <Typography>
                      {accountDetail?.verifiedName
                        ? accountDetail.verifiedName.charAt(0).toUpperCase() +
                          accountDetail.verifiedName.slice(1)
                        : ''}
                    </Typography>
                  </div>
                )}
                <div>
                  <Typography className="text-20 font-semibold">
                    {accountDetail?.verifiedName || 'N/A'}
                  </Typography>
                  <Typography className="text-16 font-medium text-gray-700">
                    {accountDetail?.businessSector || 'N/A'}
                  </Typography>
                </div>
              </div>
              <button
                type="button"
                onClick={() => setAccountEdit(true)}
                className="p-0 bg-transparent border-none"
                aria-label="Edit Profile"
              >
                <img
                  src="assets/images/icon/editIcon.png"
                  alt="Edit Profile"
                  className="h-32 w-32"
                />
              </button>
            </div>
            <div className="flex mb-12">
              <Typography className="text-16 font-medium text-gray-700">
                {t('quickCampaignsContacts.contactNumber')} :
              </Typography>
              <Typography className="text-16 font-medium ">
                {accountDetail?.displayPhoneNumber || 'No Contact Number'}
              </Typography>
            </div>
            {accountDetail?.displayPhoneNumber && (
              <div className="flex gap-10 mb-24 items-center">
                <Typography className="text-16 font-medium text-gray-700">
                  Contact Link :{' '}
                </Typography>
                <Typography className="text-16 font-medium ">
                  wa.quickchat.com/{accountDetail?.displayPhoneNumber}
                </Typography>

                <ContentCopyIcon
                  onClick={() => copyToClipboard(accountDetail?.displayPhoneNumber)}
                  className="text-grey flex justify-center items-baseline h-20 w-20"
                />
              </div>
            )}
            <div className="mb-20">
              <Typography className="text-16 font-medium text-gray-700 mb-8">
                Description
              </Typography>
              <Typography className="text-16 font-medium ">
                {accountDetail?.description || 'No Description'}
              </Typography>
            </div>
            <div className="mb-20">
              <Typography className="text-16 font-medium text-gray-700 mb-8">Address </Typography>
              <Typography className="text-16 font-medium ">
                {accountDetail?.address || 'No Address'}
              </Typography>
            </div>
            <div className="mb-20">
              <Typography className="text-16 font-medium text-gray-700 mb-8">Email </Typography>
              <Typography className="text-16 font-medium ">
                {accountDetail?.email || 'No Email'}
              </Typography>
            </div>
            <div className="mb-20">
              <Typography className="text-16 font-medium text-gray-700 mb-8">Websites </Typography>
              <Typography className="text-16 font-medium ">
                {accountDetail?.websites || 'No Websites'}
              </Typography>
            </div>
          </div>
        </div>
      )}

      {drawerOpen && (
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={() => {
            setMessageType('');
            setMessageTypeText('');
            setCaption('');
            serDrawerOpen(false);
            setResponse('');
          }}
          className="max-w-786 "
          sx={{
            '& .MuiDrawer-paper': {
              maxWidth: '1080px',
            },
          }}
        >
          <div className="flex justify-between items-center ">
            <Typography className="text-20 font-semibold px-20 py-16">
              {t('waba.setting.configureMessage')}
            </Typography>
            <Button
              onClick={() => {
                setMessageType('');
                setMessageTypeText('');
                setCaption('');
                serDrawerOpen(false);
                setResponse('');
              }}
              className="bg-white hover:bg-white"
            >
              <CloseIcon />
            </Button>
          </div>
          <div className="flex bg-gray-A500 h-full">
            <div className="w-full p-20">
              {/* {selectedTab === 1 && (
                <div className="mb-16">
                  <Typography className="text-16 font-medium mb-12">
                    {t('waba.setting.messageType')}
                  </Typography>
                  <FormControl className="quick-chat bg-white ">
                    <Select
                      value={messageType}
                      onChange={(e) => setMessageType(e.target.value)}
                      className="h-44 sm:w-256 max-w-full "
                      sx={{ padding: '0px' }}
                    >
                      <MenuItem value="regularMessage">Regular Message</MenuItem>
                      <MenuItem value="approvedTemplate">Approved Template</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )}
              {selectedTab === 1 && (
                <div className="mb-16">
                  <Typography className="text-16 font-medium mb-12">
                    {t('waba.setting.messageType')} {t('waba.setting.text')}
                  </Typography>
                  <FormControl className="quick-chat bg-white">
                    <Select
                      value={messageTypeText}
                      onChange={(e) => setMessageTypeText(e.target.value)}
                      className="h-44 sm:w-256 max-w-full"
                      sx={{ padding: '0px' }}
                    >
                      <MenuItem value="text">Text</MenuItem>
                      <MenuItem value="video">Video</MenuItem>
                      <MenuItem value="image">Image</MenuItem>
                      <MenuItem value="doc">Doc</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )} */}

              <div className="mb-16">
                <Typography className="text-16 font-medium mb-12">
                  {/* {messageTypeText === 'text' || selectedTab === 0
                    ? t('waba.setting.messageContent')
                    : t('waba.setting.caption')} */}
                  {t('waba.setting.messageContent')}
                </Typography>
                <p className="text-12 font-medium text-gray mb-12">
                  {t('waba.setting.captionNote')}
                  {maxLength - caption.length}
                  {t('waba.setting.captionNote1')}
                </p>
                <TextField
                  placeholder={
                    messageTypeText === 'text'
                      ? 'Message Description ...'
                      : 'Write Your Caption ...'
                  }
                  multiline
                  maxRows={7}
                  className="p-0 w-256 quick-chat bg-white"
                  value={caption}
                  onChange={handleChange}
                  inputProps={{ maxLength }}
                />
              </div>
              {/* {messageTypeText !== 'text' && selectedTab !== 0 && (
                <div>
                  <Typography className="text-16 font-medium mb-12">
                    {t('waba.setting.media')}
                  </Typography>
                  <Typography className="text-12 font-medium text-gray mb-12">
                    {messageTypeText === 'video'
                      ? t('waba.setting.mediaVideo')
                      : messageTypeText === 'image'
                      ? t('waba.setting.mediaImage')
                      : t('waba.setting.mediaDoc')}
                  </Typography>
                </div>
              )}
              {messageTypeText === 'video' && selectedTab === 1 && (
                <div
                  className="border-2 border-dashed p-16 text-center cursor-pointer mb-16 bg-white"
                  onDrop={(e) => handleDrop(e, 'video')}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <label htmlFor="video-upload" className="cursor-pointer">
                    <input
                      id="video-upload"
                      type="file"
                      accept="video/*"
                      onChange={(e) => handleFileUpload(e, 'video')}
                      className="hidden"
                    />
                    <div className="flex justify-center items-center mb-12">
                      <div className="bg-gray-A500 p-7 rounded-full">
                        <img
                          src="assets/images/icon/upload-cloud.svg"
                          alt=""
                          className="bg-white text-4xl p-5 rounded-full"
                        />
                      </div>
                    </div>
                    <div className="flex justify-center items-center">
                      <div className="flex">
                        <p className="text-quick-chat font-medium">Click to upload </p>{' '}
                        <p className="font-medium"> or drag and drop</p>
                      </div>
                    </div>
                    <p className="font-medium">Size {`<`} 16MB, Accepted formats - .mp4</p>
                    {video && <p className="">{video.name}</p>}
                  </label>
                </div>
              )}
              {messageTypeText === 'image' && selectedTab === 1 && (
                <div
                  className="border-2 border-dashed p-6 text-center cursor-pointer mb-16 bg-white "
                  onDrop={(e) => handleDrop(e, 'image')}
                  onDragOver={(e) => e.preventDefault()}
                >
                  {image ? (
                    <div>
                      <div className="relative w-144 h-96">
                        <img
                          src={URL.createObjectURL(image)}
                          alt="img"
                          className="rounded-md w-full h-full object-cover "
                        />
                        <div className="absolute bottom-5 right-5 flex gap-4">
                          <>
                            <div
                              className="bg-white p-5 rounded-full h-24 w-24 justify-center items-center flex cursor-pointer"
                              role="button"
                              tabIndex={0}
                              onClick={handleEditClick}
                              onKeyDown={() => handleEditClick()}
                            >
                              <EditIcon className="h-16 w-16" />
                            </div>
                            <input
                              type="file"
                              accept=".png,.jpeg"
                              className="hidden"
                              onChange={(e) => handleFileUpload(e, 'image')}
                              ref={fileInputRef}
                            />
                          </>
                          <div
                            className="bg-white p-5 rounded-full h-24 w-24 justify-center items-center flex cursor-pointer"
                            onClick={() => setImage(null)}
                            role="button"
                            tabIndex={0}
                            onKeyDown={() => setImage(null)}
                          >
                            <img src="assets/icons/delete.svg" alt="Delete" className="h-20 w-20" />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <label htmlFor="image-upload" className="cursor-pointer">
                      <input
                        id="image-upload"
                        type="file"
                        accept=".png,.jpeg"
                        onChange={(e) => handleFileUpload(e, 'image')}
                        className="hidden"
                      />
                      <div className="flex justify-center items-center mb-12">
                        <div className="bg-gray-A500 p-7 rounded-full">
                          <img
                            src="assets/images/icon/upload-cloud.svg"
                            alt=""
                            className="bg-white text-4xl p-5 rounded-full"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center items-center">
                        <div className="flex">
                          <p className="text-quick-chat font-medium">Click to upload </p>{' '}
                          <p className="font-medium"> or drag and drop</p>
                        </div>
                      </div>
                      <p className="font-medium">
                        Size {`<`} 5MB, Accepted formats - .png or .jpeg
                      </p>
                      {image && <p className="text-green-500">{image.name}</p>}
                    </label>
                  )}
                </div>
              )}
              {messageTypeText === 'doc' && selectedTab === 1 && (
                <div
                  className="border-2 border-dashed p-6 text-center cursor-pointer mb-16"
                  onDrop={(e) => handleDrop(e, 'document')}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <label htmlFor="doc-upload" className="cursor-pointer">
                    <input
                      id="doc-upload"
                      type="file"
                      accept=".pdf,.docx,.xlsx"
                      onChange={(e) => handleFileUpload(e, 'document')}
                      className="hidden"
                    />
                    <div className="flex justify-center items-center mb-12">
                      <div className="bg-gray-A500 p-7 rounded-full">
                        <img
                          src="assets/images/icon/upload.png"
                          alt=""
                          className="bg-white text-4xl p-5 rounded-full filter grayscale"
                        />
                      </div>
                    </div>
                    <div className="flex justify-center items-center">
                      <div className="flex">
                        <p className="text-quick-chat font-medium">Click to upload </p>{' '}
                        <p className="font-medium"> or drag and drop</p>
                      </div>
                    </div>
                    <p className="text-sm text-gray-500">
                      Size {`<`} 100MB, Accepted formats - .pdf, .DOCX & .XLSX
                    </p>
                    {document && <p className="text-green-500">{document.name}</p>}
                  </label>
                </div>
              )} */}
              <div className="flex justify-end items-center">
                <div className="flex gap-16">
                  <Button
                    variant="outlined"
                    color="quickChat"
                    className="rounded-md bg-white"
                    size="small"
                    disabled={refresh}
                    onClick={() => {
                      setMessageType('');
                      setMessageTypeText('');
                      setCaption('');
                      setResponse('');
                      serDrawerOpen(false);
                    }}
                  >
                    {t('common.cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    color="quickChat"
                    className="rounded-md"
                    size="small"
                    disabled={refresh}
                    onClick={() => {
                      if (selectedTab === 0) {
                        updateOptInOptOutMessages(optInStatus, optOutStatus, caption, response);
                      }
                      if (selectedTab === 1) {
                        updateWelcomeAndOffHourMessageSettings(
                          offHourMessageStatus,
                          timeZone,
                          welcomeMessageStatus,
                          workingHours
                        );
                      }
                    }}
                  >
                    {t('common.save')}
                    {refresh && (
                      <CircularProgress size={24} className="text-quick-chat absolute mx-auto" />
                    )}
                  </Button>
                </div>
              </div>
            </div>
            {/* <div className="w-1/2 p-20">
              <Typography className="text-20 font-semibold mb-16">
                {t('quickChat.createBot.preview')}
              </Typography>
              <img src="assets/images/icon/Preview.svg" alt="preview" className="" />
            </div> */}
          </div>
        </Drawer>
      )}

      {accountEdit && (
        <Drawer
          anchor="right"
          open={accountEdit}
          onClose={() => {
            setAccountEdit(false);
            setBusinessDescription('');
            setBusinessSector('');
            setBusinessAddress('');
            setBusinessEmail('');
            setBusinessWebSite('');
            setProfileImage();
            getWaUserProfileDetail();
          }}
          className="max-w-586 "
          sx={{
            '& .MuiDrawer-paper': {
              maxWidth: '565px',
            },
          }}
        >
          <div className="flex justify-between items-center px-20 pt-12">
            <Typography className="text-20 font-semibold ">{t('common.editProfile')}</Typography>
            <Button
              onClick={() => {
                setAccountEdit(false);
              }}
              className="bg-white hover:bg-white"
            >
              <CloseIcon />
            </Button>
          </div>
          <div className="p-20">
            <div className="border-2 border-black border-solid p-20 rounded-md mb-20">
              <Typography className="text-16 font-semibold mb-16">
                {t('waba.setting.profilePicture')}
              </Typography>
              <div className="py-16 px-20 mb-16 flex gap-10 justify-center items-center">
                {selectedImage ? (
                  <img src={selectedImage} alt="" className="rounded-full h-144 w-144" />
                ) : (
                  <div className="h-144 w-144 rounded-full bg-gray-400"> </div>
                )}
                <div>
                  <Typography className="text-12 font-normal">
                    {t('waba.setting.profileUploadContent1')}
                  </Typography>
                  <Typography className="text-12 font-normal">
                    {t('waba.setting.profileUploadContent2')}
                  </Typography>
                  <Typography className="text-12 font-normal">
                    {t('waba.setting.profileUploadContent3')}
                  </Typography>
                </div>
              </div>
              <div className="gap-12 flex">
                <Button
                  variant="contained"
                  className="rounded-md"
                  color="quickChat"
                  size="medium"
                  onClick={() => handleButtonClick()}
                >
                  {t('common.change')}
                </Button>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRefProfile}
                  className="hidden"
                  onChange={handleFileChange}
                />
                {/* <Button variant="outlined" className="rounded-md" color="quickChat" size="medium">
                  Remove
                </Button> */}
              </div>
            </div>

            <div className="mb-20">
              <Typography className="text-16 font-medium mb-12">
                {t(`waba.setting.descriptionOfBusiness`)}
              </Typography>
              <TextField
                placeholder="Enter A Description..."
                value={businessDescription}
                onChange={(e) => setBusinessDescription(e.target.value)}
                multiline
                rows={7}
                className="w-full quick-chat"
              />
            </div>
            <div className="mb-20 quick-chat">
              <Typography className="text-16 font-medium mb-12">
                {t(`waba.setting.businessSector`)}
              </Typography>
              <FormControl fullWidth>
                <Select value={businessSector} onChange={handleSectorChange}>
                  {sector?.map((sectors) => (
                    <MenuItem key={sectors.id} value={sectors.name}>
                      {sectors.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="mb-20">
              <Typography className="text-16 font-medium mb-12">
                {t(`waba.setting.officeAddress`)}
              </Typography>
              <TextField
                placeholder="Enter office address..."
                value={businessAddress}
                onChange={(e) => setBusinessAddress(e.target.value)}
                multiline
                maxRows={3}
                className="w-full quick-chat"
              />
            </div>
            <div className="mb-20">
              <Typography className="text-16 font-medium mb-12">{t('common.email')}</Typography>
              <TextField
                placeholder="enteryouremail@domain.com"
                value={businessEmail}
                onChange={(e) => setBusinessEmail(e.target.value)}
                multiline
                maxRows={3}
                className="w-full quick-chat"
              />
            </div>
            <div className="mb-20">
              <Typography className="text-16 font-medium mb-12">
                {t('listManagement.website')}
              </Typography>
              <TextField
                placeholder="www.mywebsite.com"
                value={businessWebSite}
                onChange={(e) => setBusinessWebSite(e.target.value)}
                multiline
                maxRows={3}
                className="w-full quick-chat"
              />
            </div>
            <div className="flex justify-end items-center">
              <div className="flex gap-12">
                <Button
                  variant="outlined"
                  color="quickChat"
                  className="rounded-md"
                  size="medium"
                  disabled={refresh}
                  onClick={() => {
                    setAccountEdit(false);
                    setBusinessDescription('');
                    setBusinessSector('');
                    setBusinessAddress('');
                    setBusinessEmail('');
                    setBusinessWebSite('');
                    setProfileImage();
                    getWaUserProfileDetail();
                  }}
                >
                  {t('common.cancel')}
                </Button>
                <Button
                  variant="contained"
                  color="quickChat"
                  className="rounded-md"
                  size="medium"
                  disabled={
                    refresh ||
                    !businessDescription ||
                    !businessSector ||
                    !businessAddress ||
                    !businessEmail ||
                    !businessWebSite
                  }
                  onClick={() => updateWaUserProfileDetail()}
                >
                  {t('common.save')}
                  {refresh && (
                    <CircularProgress size={24} className="text-quick-chat absolute mx-auto" />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Drawer>
      )}
    </div>
  );
};

export default WabaSetting;
