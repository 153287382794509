import {
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Radio,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import history from '@history';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import userService from 'src/app/services';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FuseLoading from '@fuse/core/FuseLoading';
import wabaQuery from 'src/app/main/query/waba';
import { getDecryptData, handleWabaApiRequest } from 'src/app/main/common/common';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';

const WabaBillingAndUsage = () => {
  const loginUserData = userService.getUserData();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const { t } = useTranslation();
  const currentDate = new Date();
  const countryId = Number(loginUserData?.countryId);
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOptionCCU, setSelectedOptionCCU] = useState('');
  const [startDateStart, setStartDateStart] = useState(currentDate.getTime().toString());
  const [endDateStart, setEndDateStart] = useState(currentDate.getTime().toString());
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectBotTypeData, setSelectBotTypeData] = useState('Billing Period Wise');
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElCCU, setAnchorElCCU] = useState(null);
  const [billingData, setBillingData] = useState();
  const [customEndDate, setCustomEndDate] = useState('');
  const [customStartDate, setCustomStartDate] = useState('');
  const [userDetails, setUserDetails] = useState();
  const open = Boolean(anchorEl);
  const openDurationSelectCCU = Boolean(anchorElCCU);
  const durationOptions = [
    { label: 'In 24hr', value: 'In 24hr' },
    { label: 'This week', value: 'This Week' },
    { label: 'This month', value: 'This Month' },
    { label: 'Custom', value: 'Custom' },
  ];

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (userCurrentBusinessDetails?.id) {
        setPageLoading(true);
        try {
          const payload = {
            query: wabaQuery.getUserDetail,
            variables: {
              quickReviewBusinessId: userCurrentBusinessDetails?.id,
            },
          };
          const res = await handleWabaApiRequest(payload);
          setPageLoading(false);
          if (res?.getUserDetail?.status === 200) {
            if (!res.getUserDetail.data) {
              history.push('quick-chat/waba-home/');
            }
          }
        } catch (error) {
          setPageLoading(false);
          console.error('An error occurred:', error);
        }
      }
    };
    fetchUserDetails();
  }, [userCurrentBusinessDetails?.id]);

  const fetchWhatsAppUsage = useCallback(async (EndDate, StartDate, from) => {
    try {
      const payload = {
        query: wabaQuery.whatsAppUsage,
        variables: {
          startTime: StartDate,
          endTime: EndDate,
        },
      };
      const res = await handleWabaApiRequest(payload);
      if (res?.whatsAppUsage?.status === 200) {
        setCustomEndDate('');
        setCustomStartDate('');
        setBillingData(res?.whatsAppUsage?.data);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, []);

  useEffect(() => {
    if (selectedOptionCCU !== 'Custom' && endDate && startDate) {
      fetchWhatsAppUsage(endDate, startDate);
    }
    if (!selectedOptionCCU) {
      fetchWhatsAppUsage(endDateStart, startDateStart);
    }
  }, [endDate, endDateStart, fetchWhatsAppUsage, selectedOptionCCU, startDate, startDateStart]);

  const handleSelectBotType = (duration) => {
    setSelectBotTypeData(duration);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event);
  };

  const handleDurationSelectCCU = (event) => {
    setSelectedOptionCCU(event);

    if (event === 'This Month') {
      const startDateMonth = new Date(currentDate);
      startDateMonth.setMonth(startDateMonth.getMonth() - 1);
      const startDateTimestamp = startDateMonth.getTime().toString();
      const endDateTimestamp = currentDate.getTime().toString();
      setStartDate(startDateTimestamp);
      setEndDate(endDateTimestamp);
      setAnchorElCCU(null);
    }
    if (event === 'This Week') {
      const startDateWeek = new Date(currentDate);
      const endDateWeek = new Date(currentDate);
      const dayOfWeek = currentDate.getDay();
      startDateWeek.setDate(currentDate.getDate() - dayOfWeek - 6);
      startDateWeek.setHours(0, 0, 0, 0);
      const startDateTimestamp = startDateWeek.getTime().toString();
      endDateWeek.setDate(currentDate.getDate() - dayOfWeek);
      endDateWeek.setHours(23, 59, 59, 999);
      const endDateTimestamp = endDateWeek.getTime().toString();
      setStartDate(startDateTimestamp);
      setEndDate(endDateTimestamp);
      setAnchorElCCU(null);
    }
    if (event === 'In 24hr') {
      const startDateHrs = new Date(currentDate);
      startDateHrs.setDate(currentDate.getDate() - 1);
      startDateHrs.setHours(0, 0, 0, 0);
      const startDateTimestamp = startDateHrs.getTime().toString();
      const endDateHrs = new Date(currentDate);
      endDateHrs.setHours(23, 59, 59, 999);
      const endDateTimestamp = endDateHrs.getTime().toString();
      setStartDate(startDateTimestamp);
      setEndDate(endDateTimestamp);
      setAnchorElCCU(null);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenCCU = (event) => {
    setAnchorElCCU(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseOptionCCU = () => {
    setAnchorElCCU(null);
  };

  const handleApply = () => {
    handleClose();
  };

  const handleApplyCCU = () => {
    if (selectedOptionCCU === 'Custom') {
      fetchWhatsAppUsage(customEndDate, customStartDate, 'filter');
    } else {
      fetchWhatsAppUsage(endDate, startDate, 'filter');
    }

    handleCloseOptionCCU();
  };

  if (pageLoading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-24 h-full l-padding">
      <Typography className="sm:text-28 text-24 font-semibold mb-28">
        {t(`waba.billingAndUsage.title`)}
      </Typography>
      {/* <div className="bg-white py-16 px-20 max-w-786 rounded-md mb-20">
        <Typography className="sm:text-24 text-20 font-semibold mb-20">
          {t(`waba.billingAndUsage.WCC`)}
        </Typography>
        <div className="mb-40">
          <Typography className="sm:text-20 text-16 font-semibold text-gray">
            {t(`waba.billingAndUsage.availableBalance`)}
          </Typography>
          <div className="sm:flex justify-between items-center">
            <Typography className="sm:text-40 text-32 font-semibold">
              {countryId === 2 ? '₹ ' : '$ '} 2100.02
            </Typography>
            <Typography className="sm:text-20 text-16 font-medium">
              100/1000 {t(`waba.billingAndUsage.used`)}
            </Typography>
          </div>
        </div>
        <Button variant="contained" size="small" color="quickChat" className="rounded-md px-16">
          {t(`waba.billingAndUsage.buyMore`)}
        </Button>
      </div>
      <div className="bg-white py-16 px-20 max-w-786 rounded-md mb-20">
        <Typography className="sm:text-24 text-20 font-semibold mb-20">
          {t(`waba.billingAndUsage.billingAddress`)}
        </Typography>
        <Typography className="text-14 font-medium mb-20">
          Office No. 6-7 Level 2, Hermes Palazzo, opp. St Anne's School, Camp, Pune, Maharashtra
          411001.
        </Typography>
        <Button variant="outlined" size="small" color="quickChat" className="rounded-md px-16">
          {t(`common.edit`)}
        </Button>
      </div>
      <div className="bg-white py-16 px-20 max-w-full rounded-md mb-20">
        <div className="mb-16">
          <Typography className="sm:text-24 text-20 font-semibold mb-5">
            {t(`waba.billingAndUsage.MAU`)}
          </Typography>
          <Typography className="sm:text-16 text-14 font-medium">
            {t(`waba.billingAndUsage.MAUContent`)}
          </Typography>
        </div>
        <div className="flex mb-16">
          <Button
            className={`${
              selectBotTypeData === 'Billing Period Wise'
                ? 'bg-[#323952] text-white hover:bg-[#323952]'
                : 'bg-gray-A500 text-black'
            } rounded-md  sm:w-160 py-4 text-16`}
            onClick={() => handleSelectBotType('Billing Period Wise')}
            size="small"
          >
            {t(`waba.billingAndUsage.billingPeriodWise`)}
          </Button>
          <Button
            className={`${
              selectBotTypeData === 'all'
                ? 'bg-[#323952] text-white hover:bg-[#323952]'
                : 'bg-gray-A500 text-black'
            } rounded-md sm:w-160 py-4 text-16`}
            onClick={() => handleSelectBotType('all')}
            size="small"
          >
            {t('menuItems.all')}
          </Button>
        </div>
        <div className="mb-20">
          <div
            role="button"
            tabIndex={0}
            className="border border-gray max-w-192 min-h-28 px-20 py-12 rounded-md cursor-pointer text-16 flex justify-around"
            onClick={handleClick}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                handleClick(event);
              }
            }}
          >
            <p className="text-16 font-medium">{selectedOption || 'Select Duration'}</p>

            <KeyboardArrowDownIcon
              className={`transition-transform duration-300 ${open ? 'rotate-180' : 'rotate-0'}`}
            />
          </div>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <div className="flex flex-col px-20 py-8 w-400">
              {durationOptions?.map((option) => (
                <MenuItem
                  key={option.value}
                  onClick={() => handleOptionChange(option.value)}
                  className="flex justify-between items-center w-full px-4 py-2"
                >
                  <span className="text-16 font-medium">{option.label}</span>
                  <Radio color="quickChat" checked={selectedOption === option.value} />
                </MenuItem>
              ))}
              {selectedOption === 'Custom' && (
                <div className="flex gap-2 px-4 py-2 justify-around ">
                  <DatePicker
                    label="From"
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="max-w-160 border-none bg-gray-A500 flex justify-center items-center p-8"
                    format="MM dd, yyyy"
                    disablePast
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        border: 'none',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '& .MuiInputLabel-root': {
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'flex-start',
                        width: '100%',
                      },
                    }}
                  />
                  <DatePicker
                    label="To"
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="max-w-160  border-none bg-gray-A500 flex justify-center items-center p-8"
                    format="MM dd, yyyy"
                    disablePast
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '& .MuiInputLabel-root': {
                        textAlign: 'center',
                        width: '100%',
                        display: 'flex',
                      },
                    }}
                  />
                </div>
              )}
              <div className="flex justify-end px-4 pt-4">
                <Button
                  color="quickChat"
                  variant="contained"
                  size="small"
                  onClick={handleApply}
                  className="rounded-md px-16 text-16"
                >
                  {t('quickChat.apply')}
                </Button>
              </div>
            </div>
          </Popover>
        </div>
        <div className="sm:flex sm:gap-28">
          <div className="bg-gray-A500 p-20 rounded-sm sm:mb-0 mb-20">
            <Typography className="sm:text-20 text-16 text-grey mb-16 font-semibold ">
              {t(`waba.billingAndUsage.totalMAUUsage`)}
            </Typography>
            <Typography className="text-24 font-semibold">
              {countryId === 2 ? '₹ ' : '$ '} 2100.02
            </Typography>
          </div>
          <div className="bg-gray-A500 p-20 rounded-sm sm:mb-0 mb-20">
            <Typography className="sm:text-20 text-16 text-grey mb-16 font-semibold ">
              {t(`waba.billingAndUsage.businessCreatedMAU`)}
            </Typography>
            <Typography className="text-24 font-semibold">
              {countryId === 2 ? '₹ ' : '$ '} 2100.02
            </Typography>
          </div>
          <div className="bg-gray-A500 p-20 rounded-sm sm:mb-0 mb-20">
            <Typography className="sm:text-20 text-16 text-grey mb-16 font-semibold">
              {t(`waba.billingAndUsage.userCreatedMAU`)}
            </Typography>
            <Typography className="text-24 font-semibold">
              {countryId === 2 ? '₹ ' : '$ '} 2100.02
            </Typography>
          </div>
        </div>
      </div> */}
      <div className="bg-white py-16 px-20 max-w-full rounded-md mb-20">
        <div className="mb-24">
          <Typography className="sm:text-24 text-20 font-semibold mb-16">
            {t(`waba.billingAndUsage.ccu`)}
          </Typography>
        </div>
        <div className="mb-24">
          <div
            role="button"
            tabIndex={0}
            className="border-2 border-gray bg-gray-50 max-w-192 min-h-28 px-20 py-12 rounded-lg cursor-pointer text-16 flex justify-between"
            onClick={handleOpenCCU}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                handleOpenCCU(event);
              }
            }}
          >
            <p className="text-16 font-medium text">{selectedOptionCCU || 'Select Duration'}</p>

            <KeyboardArrowDownIcon
              className={`transition-transform duration-300 ${
                openDurationSelectCCU ? 'rotate-180' : 'rotate-0'
              }`}
            />
          </div>
          <Popover
            open={Boolean(anchorElCCU)}
            anchorEl={anchorElCCU}
            onClose={handleCloseOptionCCU}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <div className="flex flex-col px-20 py-8 w-480">
              {durationOptions?.map((option) => (
                <MenuItem
                  key={option.value}
                  onClick={() => handleDurationSelectCCU(option.value)}
                  className="flex justify-between items-center w-full px-4 py-2"
                >
                  <span className="text-16 font-medium">{option.label}</span>
                  <Radio color="quickChat" checked={selectedOptionCCU === option.value} />
                </MenuItem>
              ))}
              {selectedOptionCCU === 'Custom' && (
                <div className="flex gap-2 px-4 py-2 justify-around mb-24">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={startDate}
                      onChange={(date) => {
                        setCustomStartDate(new Date(date).getTime().toString());
                        setStartDate(date);
                      }}
                      className="max-w-192 max-h-40 border-none bg-gray-A500 flex justify-center items-center p-8 quick-chat"
                      format="MM dd, yyyy"
                      maxDate={new Date()}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          fontSize: '14px',
                          border: 'none',
                          display: 'flex',
                          alignItems: 'center',
                          textAlign: 'center',
                          paddingRight: '0px',
                        },
                        '& .MuiOutlinedInput-input': {
                          padding: '0px',
                          height: '32px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        '& .MuiInputLabel-root': {
                          textAlign: 'center',
                          width: '100%',
                          display: 'flex',
                        },
                        '& .MuiIconButton-root': {
                          padding: '0px',
                          minWidth: 'auto',
                          margin: '0px',
                        },
                      }}
                    />
                  </LocalizationProvider>
                  <div className="flex justify-center items-center">
                    <Typography className="text-14 font-medium"> TO </Typography>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={endDate}
                      onChange={(date) => {
                        setCustomEndDate(new Date(date).getTime().toString());
                        setEndDate(date);
                      }}
                      className="max-w-192 max-h-40 border-none bg-gray-A500 flex justify-center items-center p-8"
                      format="MM dd, yyyy"
                      maxDate={new Date()}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          fontSize: '14px',
                          border: 'none',
                          display: 'flex',
                          alignItems: 'center',
                          textAlign: 'center',
                          paddingRight: '0px',
                        },
                        '& .MuiOutlinedInput-input': {
                          padding: '0px',
                          height: '32px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        '& .MuiInputLabel-root': {
                          textAlign: 'center',
                          width: '100%',
                          display: 'flex',
                        },
                        '& .MuiIconButton-root': {
                          padding: '0px',
                          minWidth: 'auto',
                          margin: '0px',
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>
              )}
              {selectedOptionCCU === 'Custom' && (
                <div className="flex justify-end px-4 pt-4">
                  <Button
                    color="quickChat"
                    variant="contained"
                    size="small"
                    onClick={handleApplyCCU}
                    className="rounded-md px-16 text-16"
                    disabled={
                      selectedOptionCCU === 'Custom' && (!customEndDate || !customStartDate)
                    }
                  >
                    {t('quickChat.apply')}
                  </Button>
                </div>
              )}
            </div>
          </Popover>
        </div>
        {/* <div className="mb-24">
          <div className="mb-20">
            <Typography className="sm:text-24 text-20 font-semibold mb-5">
              {t(`waba.billingAndUsage.bic`)}
            </Typography>
            <p className="sm:text-16 text-14 font-medium">
              {t(`waba.billingAndUsage.bicContent`)}{" "}
            </p>
          </div>
          <div className="sm:flex sm:gap-28">
            <div className="bg-gray-A500 p-20 rounded-sm sm:mb-0 mb-20 sm:min-w-224">
              <p className="sm:text-20 text-16 text-grey mb-16 font-semibold">
                {t(`waba.billingAndUsage.bicCount`)}
              </p>
              <p className="text-24 font-semibold">100</p>
            </div>
            <div className="bg-gray-A500 p-20 rounded-sm sm:mb-0 mb-20 sm:min-w-224">
              <p className="sm:text-20 text-16 text-grey mb-16 font-semibold ">
                {t(`waba.billingAndUsage.bicCreditUsage`)}
              </p>
              <p className="text-24 font-semibold">
                {countryId === 2 ? "₹ " : "$ "}100
              </p>
            </div>
          </div>
        </div>
        <div className="mb-24">
          <div className="mb-20">
            <Typography className="sm:text-24 text-20 font-semibold mb-5">
              {t(`waba.billingAndUsage.uic`)}
            </Typography>
            <p className="sm:text-16 text-14 font-medium">
              {t(`waba.billingAndUsage.uicContent`)}{" "}
            </p>
          </div>
          <div className="sm:flex sm:gap-28">
            <div className="bg-gray-A500 p-20 rounded-sm sm:mb-0 mb-20 sm:min-w-224">
              <p className="sm:text-20 text-16 text-grey mb-16 font-semibold">
                {t(`waba.billingAndUsage.uicCount`)}
              </p>
              <p className="text-24 font-semibold">100</p>
            </div>
            <div className="bg-gray-A500 p-20 rounded-sm sm:mb-0 mb-20 sm:min-w-224">
              <p className="sm:text-20 text-16 text-grey mb-16 font-semibold ">
                {t(`waba.billingAndUsage.uicCreditUsage`)}
              </p>
              <p className="text-24 font-semibold">
                {countryId === 2 ? "₹ " : "$ "}100
              </p>
            </div>
          </div>
        </div> */}
        <div className="mb-24">
          <div className="mb-20">
            <Typography className="sm:text-24 text-20 font-semibold mb-5">
              {t(`waba.billingAndUsage.sc`)}
            </Typography>
            <p className="sm:text-16 text-14 font-medium">{t(`waba.billingAndUsage.scContent`)} </p>
          </div>
          <div className="sm:flex sm:gap-28">
            <div className="bg-gray-A500 p-20 rounded-sm sm:mb-0 mb-20 sm:min-w-224">
              <p className="sm:text-20 text-16 text-grey mb-16 font-semibold">
                {t(`waba.billingAndUsage.scCount`)}
              </p>
              <p className="text-24 font-semibold">{billingData?.SERVICE?.conversation} </p>
            </div>
            <div className="bg-gray-A500 p-20 rounded-sm sm:mb-0 mb-20 sm:min-w-224">
              <p className="sm:text-20 text-16 text-grey mb-16 font-semibold ">
                {t(`waba.billingAndUsage.scCreditUsage`)}
              </p>
              <p className="text-24 font-semibold">
                {countryId === 2 ? '₹ ' : '$ '}
                {billingData?.SERVICE?.cost}
              </p>
            </div>
          </div>
        </div>
        <div className="mb-24">
          <div className="mb-20">
            <Typography className="sm:text-24 text-20 font-semibold mb-5">
              {t(`waba.billingAndUsage.ac`)}
            </Typography>
            <p className="sm:text-16 text-14 font-medium">{t(`waba.billingAndUsage.acContent`)} </p>
          </div>
          <div className="sm:flex sm:gap-28">
            <div className="bg-gray-A500 p-20 rounded-sm sm:mb-0 mb-20 sm:min-w-224">
              <p className="sm:text-20 text-16 text-grey mb-16 font-semibold">
                {t(`waba.billingAndUsage.acCount`)}
              </p>
              <p className="text-24 font-semibold">{billingData?.AUTHENTICATION?.conversation}</p>
            </div>
            <div className="bg-gray-A500 p-20 rounded-sm sm:mb-0 mb-20 sm:min-w-224">
              <p className="sm:text-20 text-16 text-grey mb-16 font-semibold ">
                {t(`waba.billingAndUsage.acCreditUsage`)}
              </p>
              <p className="text-24 font-semibold">
                {countryId === 2 ? '₹ ' : '$ '}
                {billingData?.AUTHENTICATION?.cost}
              </p>
            </div>
          </div>
        </div>
        <div className="mb-24">
          <div className="mb-20">
            <Typography className="sm:text-24 text-20 font-semibold mb-5">
              {t(`waba.billingAndUsage.mc`)}
            </Typography>
            <p className="sm:text-16 text-14 font-medium">{t(`waba.billingAndUsage.mcContent`)}</p>
          </div>
          <div className="sm:flex sm:gap-28">
            <div className="bg-gray-A500 p-20 rounded-sm sm:mb-0 mb-20 sm:min-w-224">
              <p className="sm:text-20 text-16 text-grey mb-16 font-semibold">
                {t(`waba.billingAndUsage.mcCount`)}
              </p>
              <p className="text-24 font-semibold">{billingData?.MARKETING?.conversation}</p>
            </div>
            <div className="bg-gray-A500 p-20 rounded-sm sm:mb-0 mb-20 sm:min-w-224">
              <p className="sm:text-20 text-16 text-grey mb-16 font-semibold ">
                {t(`waba.billingAndUsage.mcCreditUsage`)}
              </p>
              <p className="text-24 font-semibold">
                {countryId === 2 ? '₹ ' : '$ '}
                {billingData?.MARKETING?.cost}
              </p>
            </div>
          </div>
        </div>
        <div className="mb-24">
          <div className="mb-20">
            <Typography className="sm:text-24 text-20 font-semibold mb-5">
              {t(`waba.billingAndUsage.uc`)}
            </Typography>
            <p className="sm:text-16 text-14 font-medium">{t(`waba.billingAndUsage.ucContent`)} </p>
          </div>
          <div className="sm:flex sm:gap-28">
            <div className="bg-gray-A500 p-20 rounded-sm sm:mb-0 mb-20 sm:min-w-224">
              <p className="sm:text-20 text-16 text-grey mb-16 font-semibold">
                {t(`waba.billingAndUsage.ucCount`)}
              </p>
              <p className="text-24 font-semibold">{billingData?.UTILITY?.conversation}</p>
            </div>
            <div className="bg-gray-A500 p-20 rounded-sm sm:mb-0 mb-20 sm:min-w-224">
              <p className="sm:text-20 text-16 text-grey mb-16 font-semibold ">
                {t(`waba.billingAndUsage.ucCreditUsage`)}
              </p>
              <p className="text-24 font-semibold">
                {countryId === 2 ? '₹ ' : '$ '}
                {billingData?.UTILITY?.cost}
              </p>
            </div>
          </div>
        </div>
        {/* <div className="mb-24">
          <div className="mb-20">
            <Typography className="sm:text-24 text-20 font-semibold mb-5">
              {t(`waba.billingAndUsage.waPay`)}
            </Typography>
            <p className="sm:text-16 text-14 font-medium">
              {t(`waba.billingAndUsage.waPayContent`)}{" "}
            </p>
          </div>
          <div className="sm:flex sm:gap-28">
            <div className="bg-gray-A500 p-20 rounded-sm sm:mb-0 mb-20 sm:min-w-224">
              <p className="sm:text-20 text-16 text-grey mb-16 font-semibold">
                {t(`waba.billingAndUsage.waPayCount`)}
              </p>
              <p className="text-24 font-semibold">100</p>
            </div>
            <div className="bg-gray-A500 p-20 rounded-sm sm:mb-0 mb-20 sm:min-w-224">
              <p className="sm:text-20 text-16 text-grey mb-16 font-semibold ">
                {t(`waba.billingAndUsage.waPayCreditUsage`)}
              </p>
              <p className="text-24 font-semibold">
                {countryId === 2 ? "₹ " : "$ "}100
              </p>
            </div>
          </div>
        </div> */}
        <div className="mb-24">
          <div className="mb-20">
            <Typography className="sm:text-24 text-20 font-semibold mb-5">
              {t(`waba.billingAndUsage.totalCreditUsage`)}
            </Typography>
            <p className="sm:text-16 text-14 font-medium">
              {t(`waba.billingAndUsage.totalCreditUsageContent`)}
            </p>
          </div>
          <div className="sm:flex sm:gap-28">
            <div className="bg-gray-A500 p-20 rounded-sm sm:mb-0 mb-20 sm:min-w-224">
              <p className="sm:text-20 text-16 text-grey mb-16 font-semibold">
                {t(`waba.billingAndUsage.totalCreditUsage`)}
              </p>
              <p className="text-24 font-semibold">
                {countryId === 2 ? '₹ ' : '$ '}
                {(billingData?.UTILITY?.cost ?? 0) +
                  (billingData?.MARKETING?.cost ?? 0) +
                  (billingData?.AUTHENTICATION?.cost ?? 0) +
                  (billingData?.SERVICE?.cost ?? 0)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WabaBillingAndUsage;
