import {
  Button,
  Icon,
  InputAdornment,
  Pagination,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';
import history from '@history';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import {
  capitalizeFirstLetter,
  getDecryptData,
  handleWabaApiRequest,
} from 'src/app/main/common/common';
import wabaQuery from 'src/app/main/query/waba';
import SelectTemplate from './selectTemplate/SelectTemplate';
import { DeleteTemplate } from '../common/Popup';
import BusinessNotVerify from '../BusinessNotVerify';

const Broadcast = () => {
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(location?.state?.tabValue || 1);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [broadcastData, setBroadcastData] = useState([]);
  const [openSelectTemplateDialogue, setOpenSelectTemplateDialogue] = useState(false);
  const [page, setPage] = useState(1);
  const [openDeleteTemplateDialogue, setDeleteTemplateDialogue] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [deleteBroadcastId, setDeleteBroadcastId] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [businessNotVerify, setBusinessNotVerify] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const getWABroadCastList = useCallback(async () => {
    try {
      if (!userCurrentBusinessDetails?.id) return;
      const payload = {
        query: wabaQuery.getWABroadCastList,
        variables: {
          pageNumber: page,
          pageSize: 10,
          searchName: searchValue,
          userBusinessId: Number(userCurrentBusinessDetails?.id),
          ...(activeStep === 2 && { isScheduled: true }),
        },
      };
      const res = await handleWabaApiRequest(payload);
      if (res?.getWABroadCastList?.status === 200) {
        const data = res.getWABroadCastList.data?.length > 0 ? res.getWABroadCastList.data : [];
        const parsedData = data.map((item) => ({
          ...item,
          dynamicData: item.dynamicData ? JSON.parse(item.dynamicData) : {},
          templateData: item.templateData ? JSON.parse(item.templateData) : {},
        }));
        const processedTemplates = parsedData.map((template) => {
          const bodyText = template?.templateData?.components?.find(
            (item) => item?.type === 'BODY'
          )?.text;
          const updatedBodyText = bodyText?.replace(/\{\{(\d+)\}\}/g, (match, key) => {
            const replacement = template?.dynamicData?.body?.find(
              (item) => `{{${item.key}}}` === match
            )?.description;
            return `[${replacement}]` || match;
          });
          return {
            ...template,
            updatedBodyText,
          };
        });
        setBroadcastData(processedTemplates || []);
        setTotalCount(res?.getWABroadCastList?.totalCount);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [page, activeStep, searchValue, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (userCurrentBusinessDetails?.id) {
        try {
          const payload = {
            query: wabaQuery.getUserDetail,
            variables: {
              quickReviewBusinessId: userCurrentBusinessDetails?.id,
            },
          };
          const res = await handleWabaApiRequest(payload);
          if (res?.getUserDetail?.status === 200) {
            if (res?.getUserDetail?.data && Object.keys(res?.getUserDetail?.data)?.length > 0) {
              const decryptedData = JSON.parse(getDecryptData(res.getUserDetail.data));
              setUserDetails(decryptedData);
            } else {
              history.push({
                pathname: 'quick-chat/waba-home/',
              });
            }
          }
        } catch (error) {
          console.error('An error occurred:', error);
        }
      }
    };
    fetchUserDetails();
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    getWABroadCastList();
  }, [getWABroadCastList]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const deleteBroadCast = async () => {
    try {
      setRefreshIcon(true);
      const payload = {
        query: wabaQuery.deleteBroadcast,
        variables: {
          waBroadcastId: Number(deleteBroadcastId),
        },
      };
      const res = await handleWabaApiRequest(payload);
      setRefreshIcon(false);
      if (res?.deleteBroadcast?.status === 200) {
        setDeleteTemplateDialogue(false);
        getWABroadCastList();
        setPage(1);
        enqueueSnackbar(res?.deleteBroadcast?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        setDeleteTemplateDialogue(false);
        enqueueSnackbar(res?.deleteBroadcast?.message || 'Failed to delete broadcast.', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      setDeleteTemplateDialogue(false);
      console.error('Error deleting broadcast:', error);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <div className="lg:p-24 p-16">
      <div>
        <div className="flex w-full items-center justify-between lg:mb-20 mb-16">
          <Typography
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex md:text-28 text-20 font-bold mb-16 md:mb-0"
          >
            {t('navigation.wabaBroadcast')}
          </Typography>
          <Button
            onClick={() => {
              !userDetails?.businessVerificationStatus
                ? setBusinessNotVerify(true)
                : setOpenSelectTemplateDialogue(true);
            }}
            className="rounded-md lg:px-40 text-14 font-medium inline-block align-middle"
            variant="contained"
            color="quickChat"
          >
            {t('waba.broadcast.myBroadcast.newBroadcast')}
          </Button>
        </div>
        <div className="border-b-3 border-solid border-grey sm:flex justify-between items-center quick-chat">
          <Tabs
            value={activeStep}
            onChange={(e, newValue) => setActiveStep(newValue)}
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab
              className={`sm:text-18 font-semibold p-0 sm:mr-28 mr-14 ${
                activeStep === 1 ? 'text-quick-chat' : 'text-black'
              }`}
              value={1}
              label={t('waba.broadcast.myBroadcast.myBroadcast')}
            />
            <Tab
              className={`sm:text-18 font-semibold p-0 sm:mr-28 mr-14 ${
                activeStep === 2 ? 'text-quick-chat' : 'text-black'
              }`}
              value={2}
              label={t('waba.broadcast.myBroadcast.scheduledBroadcast')}
            />
          </Tabs>
        </div>
        <div className="flex justify-between items-center quick-chat py-16">
          <Typography className="font-semibold text-20">
            {activeStep === 1
              ? t('waba.broadcast.myBroadcast.myBroadcast')
              : t('waba.broadcast.myBroadcast.scheduleBroadcast')}
          </Typography>
          {(broadcastData?.length > 0 || searchValue) && (
            <TextField
              placeholder="Search broadcast name"
              variant="outlined"
              fullWidth
              className="bg-white w-full sm:w-512 m-0"
              margin="normal"
              size="small"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                setPage(1);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: searchValue && (
                  <InputAdornment position="end">
                    <Icon
                      color="quickChat"
                      className="cursor-pointer text-20"
                      onClick={() => {
                        setSearchValue('');
                        setPage(1);
                      }}
                    >
                      close
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </div>
        {broadcastData?.length > 0 ? (
          <div>
            <TableContainer component={Paper} className="rounded-md border-none shadow-0">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="text-18 font-semibold border-b-2 border-solid border-gray-500 h-52">
                      {t('waba.broadcast.myBroadcast.tableColumns.srNo')}
                    </TableCell>
                    <TableCell className="text-18 min-w-128 max-w-128 font-semibold border-b-2 border-solid border-gray-500 h-52">
                      {t('waba.broadcast.myBroadcast.tableColumns.broadcast')}
                    </TableCell>
                    <TableCell className="text-18 max-w-128 font-semibold border-b-2 border-solid border-gray-500 h-52">
                      {t('waba.broadcast.myBroadcast.tableColumns.createdOn')}
                    </TableCell>
                    <TableCell className="text-18 max-w-96 font-semibold border-b-2 border-solid border-gray-500 h-52">
                      {t('waba.broadcast.myBroadcast.tableColumns.recipient')}
                    </TableCell>
                    <TableCell className="text-18 max-w-96 font-semibold border-b-2 border-solid border-gray-500 h-52">
                      {t('waba.broadcast.myBroadcast.tableColumns.status')}
                    </TableCell>
                    <TableCell className="text-18 font-semibold border-b-2 border-solid border-gray-500 h-52 rounded-tr-md w-160" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {broadcastData?.length > 0 &&
                    broadcastData?.map((data, index) => (
                      <TableRow
                        key={index}
                        className="hover:bg-quick-chat-100 hover:bg-opacity-30"
                        onMouseEnter={() => setHoveredRow(index)}
                        onMouseLeave={() => setHoveredRow(null)}
                      >
                        <TableCell className="h-52 max-h-52  border-b-[1px] border-solid border-gray-300 text-16 color-black font-medium  py-5 pb-16 md:py-10 capitalize">
                          {(page - 1) * 10 + index + 1}
                        </TableCell>
                        <TableCell className="h-52 max-h-52  border-b-[1px] border-solid border-gray-300 text-16 color-black font-medium  py-5 pb-16 md:py-10 capitalize">
                          <span
                            title={data?.name}
                            className="whitespace-nowrap overflow-hidden text-ellipsis block max-w-128"
                          >
                            {' '}
                            {data?.name}
                          </span>
                        </TableCell>
                        <TableCell className="h-52 max-h-52  border-b-[1px] border-solid border-gray-300 text-16 color-black font-medium  py-5 pb-16 md:py-10 capitalize">
                          {moment(data.cts).format('MMM DD, YYYY')}
                        </TableCell>
                        <TableCell className="h-52 max-h-52  border-b-[1px] border-solid border-gray-300 text-16 color-black font-medium  py-5 pb-16 md:py-10 capitalize">
                          {data?.recipientsCount} Contact
                        </TableCell>
                        <TableCell className="h-52 max-h-52  border-b-[1px] border-solid border-gray-300 text-16 color-black font-medium  py-5 pb-16 md:py-10 capitalize">
                          <Typography
                            className={`border rounded-full w-max px-12 py-6 font-semibold border border-solid  bg-opacity-30 ${
                              data?.status === 'pending'
                                ? 'border-[#EBB32F] text-[#EBB32F] bg-[#EBB32F]'
                                : data?.status === 'completed' &&
                                  'border-[#0BA861] text-[#0BA861] bg-[#0BA861]'
                            }`}
                          >
                            {capitalizeFirstLetter(data?.status)}
                          </Typography>
                        </TableCell>
                        <TableCell className="relative h-52 max-h-52 border-b-[1px] border-solid border-gray-300 text-16 color-black font-medium py-5 pb-16 md:py-10">
                          {hoveredRow === index ? (
                            <div className="flex gap-12 absolute top-10 right-0 left-0">
                              <Button
                                type="button"
                                className="p-0 min-w-36 w-36 min-h-36 h-36 bg-quick-chat-100 hover:bg-quick-chat-100"
                                onClick={() =>
                                  history.push('quick-chat/broadcastDetails', {
                                    template: data,
                                  })
                                }
                                aria-label="Edit"
                              >
                                <img
                                  className="h-20 w-20"
                                  src="assets/images/quick-chat/view.svg"
                                  alt=""
                                />
                              </Button>
                              {activeStep === 2 && (
                                <Button
                                  className="p-0 min-w-36 w-36 min-h-36 h-36 bg-quick-chat-100 hover:bg-quick-chat-100"
                                  onClick={() =>
                                    history.push('quick-chat/createBroadcast', {
                                      template: data,
                                      isSchedule: true,
                                    })
                                  }
                                  aria-label="Edit"
                                >
                                  <img src="assets/images/quick-hub/edit.png" alt="" />
                                </Button>
                              )}
                              {activeStep === 2 && (
                                <Button
                                  className="p-0 min-w-36 w-36 min-h-36 h-36 bg-quick-chat-100 hover:bg-quick-chat-100"
                                  onClick={() => {
                                    setDeleteTemplateDialogue(true);
                                    setDeleteBroadcastId(data?.id);
                                  }}
                                  aria-label="Delete"
                                >
                                  <img src="assets/images/quick-hub/delete.png" alt="" />
                                </Button>
                              )}
                            </div>
                          ) : (
                            <div className="flex gap-16 pr-24">
                              <div className="p-3">
                                <div className="bg-transparent rounded-full h-24 w-24" />
                              </div>
                              <div className="p-3">
                                <div className="bg-transparent rounded-full h-24 w-24" />
                              </div>
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="px-52 py-32 bottom-0 right-0 w-full flex justify-end">
              <Pagination
                count={Math.ceil(totalCount / 10)}
                page={page}
                onChange={handlePageChange}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        ) : (
          <div className="pt-160 text-center">
            <img
              className="m-auto"
              src={
                searchValue
                  ? 'assets/images/quick-chat/search.svg'
                  : 'assets/images/quick-chat/broadcast.svg'
              }
              alt=""
            />
            <Typography className="font-bold text-24 text-center pt-20 pb-6">
              {searchValue
                ? t('quickCampaign.template.notExactMatchFount')
                : t('waba.broadcast.myBroadcast.getStartFirstBroadCast')}
            </Typography>
            <Typography className="font-semibold sm:max-w-640 m-auto text-16 text-center text-[#4D4D4D]">
              {searchValue
                ? t('quickCampaign.template.broadcastNotExactMatchFountMessage')
                : t('waba.broadcast.myBroadcast.firstBroadcastMessage')}
            </Typography>
            <Button
              onClick={() => {
                !userDetails?.businessVerificationStatus
                  ? setBusinessNotVerify(true)
                  : setOpenSelectTemplateDialogue(true);
              }}
              className="rounded-md lg:px-40 text-14 mt-20 font-medium inline-block align-middle"
              variant="contained"
              color="quickChat"
            >
              {t('waba.broadcast.myBroadcast.newBroadcast')}
            </Button>
          </div>
        )}
      </div>
      <SelectTemplate
        openSelectTemplateDialogue={openSelectTemplateDialogue}
        setOpenSelectTemplateDialogue={setOpenSelectTemplateDialogue}
      />
      <DeleteTemplate
        setDeleteTemplateDialogue={setDeleteTemplateDialogue}
        openDeleteTemplateDialogue={openDeleteTemplateDialogue}
        handleDelete={deleteBroadCast}
        refreshIcon={refreshIcon}
        deleteMessage={t('waba.broadcast.myBroadcast.deleteBroadcast')}
      />
      <BusinessNotVerify
        businessNotVerify={businessNotVerify}
        setBusinessNotVerify={setBusinessNotVerify}
      />
    </div>
  );
};

export default Broadcast;
