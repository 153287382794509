import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  Icon,
  Typography,
  Select,
  MenuItem,
  InputAdornment,
  Checkbox,
} from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneInput from 'react-phone-input-2';
import * as yup from 'yup';
import { t } from 'i18next';
import InputLabel from '@mui/material/InputLabel';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import _ from '@lodash';
import { useSnackbar } from 'notistack';
import {
  getDataToServer,
  handleApiRequest,
  handlePhoneNumberValidationCheck,
  handleWabaApiRequest,
  hexToRgb,
} from 'src/app/main/common/common';
import wabaQuery from 'src/app/main/query/waba';
import UserService from 'src/app/services/userService';
import { selectDashboardData } from 'src/app/store/userSlice';

const defaultValues = {
  firstName: '',
  phone_number: '',
  mail: '',
};

const AddContact = (props) => {
  const {
    openValue,
    cancelCall,
    popUpMessage,
    popUpButtonName,
    type,
    initialValues,
    selectedRow,
    borderColor,
    textColor,
    callFrom,
    getData,
    fetchContactData,
    csvContactData,
    isEmailRequired,
  } = props;

  const loginUserData = UserService.getUserData();
  const [phone, setPhone] = useState();
  const [phoneValue, setPhoneValue] = useState();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(openValue);
  const [errorMessage, setErrorMessage] = useState(true);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [searchLabelName, setSearchLabelName] = useState();
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [labels, setLabels] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [isExpandedTag, setIsExpandedTag] = useState(false);
  const [tagLoading, setTagLoading] = useState(false);
  const [tagValue, setTagValue] = useState('');

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required(t('signUpPage.formField.firstName.validationMessages.required'))
      .max(15, t('signUpPage.formField.firstName.validationMessages.max'))
      .matches(/^[a-zA-Z]+$/, t('signUpPage.formField.firstName.validationMessages.matches')),
    lastName: yup
      .string()
      .required(t('signUpPage.formField.lastName.validationMessages.required'))
      .max(15, t('signUpPage.formField.lastName.validationMessages.max'))
      .matches(/^[a-zA-Z]+$/, t('signUpPage.formField.lastName.validationMessages.matches')),
    phone_number: yup.string().required(t('signInPage.enterPhoneNumber')),
    mail: yup
      .string()
      .trim()
      .test('is-valid-email', t('common.enterAValidEmail'), (value) => {
        if (!value) return true; // Allow empty email if not required
        return /^\S+@\S+\.\S+$/.test(value); // Validate email format if entered
      })
      .when('isEmailRequired', {
        is: true,
        then: (schemaa) => schemaa.required(t('common.emailIsRequired')),
      }),
  });

  const { control, formState, handleSubmit, reset, setValue, getValues, watch, clearErrors } =
    useForm({
      mode: 'onChange',
      defaultValues: type === 'edit' ? { ...props.initialValues } : defaultValues,
      resolver: yupResolver(schema),
    });
  const form = watch();

  const { isValid, dirtyFields, errors } = formState;

  const handleGetTagAll = useCallback(async () => {
    try {
      const payload = {
        query: wabaQuery.getWaChatTags,
        variables: {
          pageSize: 1000,
          pageNumber: 1,
          searchText: searchLabelName,
        },
      };
      const res = await handleWabaApiRequest(payload);

      if (res?.getWaChatTags?.status === 200) {
        setTagList(res?.getWaChatTags?.data);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [searchLabelName]);

  useEffect(() => {
    handleGetTagAll();
  }, [handleGetTagAll]);

  useEffect(() => {
    if (type === 'edit' && initialValues?.phone_number) {
      const dialCode = initialValues.phone_number.split(' ')[0] || '';
      const phoneNumber = initialValues.phone_number.split(' ')[1] || '';
      setPhoneValue(initialValues.phone_number);
      setPhone({ dialCode, phoneNumber });
      setValue('phone_number', phoneNumber, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [type, initialValues, setValue]);

  useEffect(() => {
    if (type === 'edit' && tagList.length > 0 && selectedRow?.waTags?.length > 0) {
      const preSelectedTags = tagList
        .filter((tag) => selectedRow.waTags.some((selected) => selected.tagId === tag.id))
        .map((tag) => tag.id);

      setSelectedLabels(preSelectedTags);
    }
  }, [type, tagList, selectedRow]);

  const handlePhoneChange = (event, data, value, formattedValue) => {
    setPhoneValue(event);
    setPhone({
      ...data,
      phoneNumber: parseInt(event.slice(data?.dialCode?.length), 10),
    });
    setValue('phone_number', event.slice(data?.dialCode?.length), {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const handleClose = () => {
    setDialogOpen(false);
    reset(defaultValues);
    clearErrors();
    cancelCall();
    setPhoneValue('');
  };

  const onSubmit = async (data) => {
    setRefreshIcon(true);
    const selectedTagDetails = tagList
      .filter((tag) => selectedLabels.includes(tag.id))
      .map(({ id, tagName, colorCode }) => ({ tagId: id, tagName, colorCode }));

    if (type === 'add') {
      const obj = {
        query: wabaQuery.saveCampaignContact,
        variables: {
          callFrom,
          countryCode: `+${phone?.dialCode}`,
          email: data?.mail || null,
          firstName: data?.firstName,
          lastName: data?.lastName,
          phoneNumber: Number(phone?.phoneNumber),
          userBusinessId: Number(userCurrentBusinessDetails?.id),
          tags: selectedTagDetails?.length > 0 ? selectedTagDetails : null,
        },
      };
      const res = await getDataToServer(obj);
      setRefreshIcon(false);
      if (res?.saveCampaignContact?.status === 200) {
        getData();
        if (fetchContactData) {
          fetchContactData();
        }

        enqueueSnackbar(res?.saveCampaignContact?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        reset(defaultValues);
        setPhoneValue('');
        handleClose();
        closeSnackbar(3000);
      } else {
        enqueueSnackbar(res?.saveCampaignContact?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        closeSnackbar(3000);
      }
    } else {
      const obj = {
        query: wabaQuery.updateCampaignContact,
        variables: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data?.mail,
          countryCode: phone?.dialCode,
          phoneNumber: `${phone?.phoneNumber}`,
          oldPhoneNumber: `${selectedRow?.phoneNumber}`,
          oldEmail: selectedRow?.mail,
          dataFrom: selectedRow?.dataFrom,
          id: selectedRow?.id,
          tags: selectedTagDetails,
        },
      };
      const res = await handleApiRequest(obj);
      setRefreshIcon(false);
      if (res?.updateCampaignContact?.status === 200) {
        getData();
        if (fetchContactData) {
          fetchContactData();
        }
        enqueueSnackbar(res?.updateCampaignContact?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        handleClose();
      } else {
        enqueueSnackbar(res?.updateCampaignContact?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  };
  const handleRemoveFilter = (data) => {
    setSelectedLabels((prevFilter) => {
      const updatedFilters = prevFilter?.filter((filter) => filter !== data?.id);
      return updatedFilters.length > 0 ? updatedFilters : [];
    });
  };

  const handleDeleteTag = async (tagId) => {
    setSelectedLabels((prevSelectedLabels) => prevSelectedLabels.filter((id) => id !== tagId));
  };

  const addTagFunction = async () => {
    setTagLoading(true);
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    try {
      const payload = {
        query: wabaQuery.createAndUpdateWaChatTag,
        variables: {
          tagName: tagValue,
          customerJourney: false,
          firstMessage: false,
          colorCode: randomColor,
        },
      };
      const res = await handleWabaApiRequest(payload);
      setTagLoading(false);
      if (res?.createAndUpdateWaChatTag?.status === 200) {
        enqueueSnackbar(res?.createAndUpdateWaChatTag?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setTagValue('');
        handleGetTagAll();
        setIsExpandedTag(false);
      } else {
        enqueueSnackbar(res?.createAndUpdateWaChatTag?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };
  return (
    <Dialog
      classes={{
        paper: `m-24 p-28 rounded-md max-w-640 ${
          callFrom === 'whatsapp' ? 'border-t-quick-chat' : 'border-t-quick-campaign'
        }`,
      }}
      open={openValue}
      onKeyDown={(event) => {
        if (event.keyCode === 27) {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle className="p-0 mb-20">
        <Icon
          onClick={() => handleClose()}
          className="text-28 absolute top-28 right-24 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
        >
          close
        </Icon>
        <Typography className="text-24 font-semibold text-black w-full">{popUpMessage}</Typography>
      </DialogTitle>

      <form
        name="addClient"
        className={`${callFrom === 'whatsapp' ? 'quick-chat' : 'quick-campaign'}`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="block mb-16">
          <InputLabel id="firstName" className="text-16 font-medium mb-10 text-grey-900">
            {t('common.firstName')}
          </InputLabel>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <TextField
                placeholder={t('common.firstName')}
                {...field}
                type="text"
                error={!!errors.firstName}
                helperText={errors?.firstName?.message}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          />
        </div>
        <div className="block mb-16">
          <InputLabel id="firstName" className="text-16 font-medium mb-10 text-grey-900">
            {t('common.lastName')}
          </InputLabel>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <TextField
                placeholder={t('common.lastName')}
                {...field}
                type="text"
                error={!!errors.lastName}
                helperText={errors?.lastName?.message}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          />
        </div>
        <div className="block mb-16">
          <InputLabel id="phone_number" className="text-16 font-medium mb-10 text-grey-900">
            {t('common.contact')}
          </InputLabel>
          <PhoneInput
            placeholder={t('common.phoneNumber')}
            inputStyle={{
              width: '100%',
              height: '37px',
            }}
            className="w-full"
            name="phone_number"
            required
            error={!!errors.phone_number}
            helperText={errors?.phone_number?.message}
            value={phoneValue}
            size="small"
            country={loginUserData?.isoCode?.toLowerCase()}
            enableSearch="true"
            onChange={(value, data) => {
              handlePhoneChange(value, data);
              const validPhoneNumber = handlePhoneNumberValidationCheck(value, data);
              setErrorMessage(validPhoneNumber);
            }}
          />
          {errorMessage === false && phoneValue?.length > 0 && (
            <p className="text-red mt-5 text-16">{t('productSettings.InvalidPhoneNumber')}</p>
          )}
        </div>
        <div className="block mb-16">
          <InputLabel id="mail" className="text-16 font-medium mb-10 text-grey-900">
            {t('common.email')}{' '}
            <span className="text-14 text-grey-500">{!isEmailRequired && `(optional)`}</span>
          </InputLabel>
          <Controller
            name="mail"
            control={control}
            render={({ field }) => (
              <TextField
                placeholder={t('common.email')}
                {...field}
                type="text"
                error={!!errors.mail}
                helperText={errors?.mail?.message}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          />
        </div>
        <div className="block mb-16">
          <div className="flex items-center justify-between">
            <InputLabel id="tag" className="text-16 font-medium mb-10 text-grey-900">
              {t('waba.broadcast.contactList.table.tag')}{' '}
              <span className="text-14 text-grey-500">{!isEmailRequired && `(optional)`}</span>
            </InputLabel>
            <span
              className="text-quick-chat text-14 font-semibold cursor-pointer"
              onClick={() => setIsExpandedTag(true)}
              aria-hidden
            >
              {t('common.addTag')}
            </span>
          </div>
          <div>
            {!isExpandedTag && tagList && tagList.length > 0 ? (
              <Select
                className="w-full"
                id="labels"
                multiple
                value={selectedLabels}
                size="small"
                displayEmpty
                onChange={(e) => setSelectedLabels(e.target.value)}
                sx={{ '& .MuiSelect-select': { display: 'flex', alignItems: 'center' } }}
                renderValue={() => t('common.selectTag')}
                onOpen={() => {
                  setTimeout(() => {
                    const menuElement = document.querySelector('.MuiMenu-paper');
                    if (menuElement) {
                      menuElement.scrollTop = 0;
                    }
                  }, 10);
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 250,
                      overflowY: 'auto',
                    },
                  },
                }}
              >
                <MenuItem disableRipple>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder={t('common.searchTag')}
                    value={searchLabelName}
                    onChange={(e) => setSearchLabelName(e.target.value)}
                    onKeyDown={(e) => e.stopPropagation()}
                    sx={{
                      backgroundColor: 'white',
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: 'white',
                      },
                      '& .MuiOutlinedInput-input': {
                        backgroundColor: 'white',
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: searchLabelName && (
                        <InputAdornment position="end">
                          <Icon
                            color="quickChat"
                            className="cursor-pointer text-20"
                            onClick={() => setSearchLabelName('')}
                          >
                            close
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </MenuItem>
                {tagList.length > 0 ? (
                  tagList.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      <Checkbox checked={selectedLabels.includes(option.id)} />
                      <span
                        className="h-14 w-14 mr-6 rounded-full"
                        style={{ backgroundColor: option.colorCode }}
                      />
                      {option.tagName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>{t('manageLabels.noFound')}</MenuItem>
                )}
              </Select>
            ) : (
              <div className="block">
                <input
                  type="text"
                  value={tagValue}
                  onChange={(e) => setTagValue(e.target.value)}
                  placeholder={t('waba.inbox.enterTag')}
                  className="w-full px-12 py-2 border rounded-md h-40 border-gray-400 "
                />

                {isExpandedTag && (
                  <div className="text-right mt-10">
                    <Button
                      onClick={() => {
                        setIsExpandedTag(false);
                        setTagValue('');
                      }}
                      variant="outlined"
                      color="quickChat"
                      className="text-12 inline-block rounded-md h-28 min-h-28 pt-2"
                    >
                      {t('common.cancel')}
                    </Button>
                    {tagValue && (
                      <Button
                        onClick={() => addTagFunction()}
                        variant="contained"
                        color="quickChat"
                        className="text-12 inline-block rounded-md h-28 min-h-28 pt-2 px-10 w-92 min-w-92 ml-16"
                      >
                        <span>
                          <Icon className="text-14 align-middle">add</Icon>{' '}
                          <span>{t('waba.inbox.addTag')}</span>
                        </span>
                        {tagLoading && (
                          <CircularProgress
                            size={12}
                            className="text-quick-chat absolute mx-auto right-3"
                          />
                        )}
                      </Button>
                    )}
                  </div>
                )}
              </div>
            )}
            <div className="flex flex-wrap items-center mt-16">
              {selectedLabels && selectedLabels?.length > 0 && (
                <div className="flex flex-wrap gap-8 ">
                  {tagList
                    .filter((tag) => selectedLabels.includes(tag.id))
                    ?.map((tagValues, index) => (
                      <span
                        className="font-medium text-12 rounded-md px-6 py-4"
                        style={{
                          backgroundColor: `rgba(${hexToRgb(tagValues?.colorCode)}, 0.1)`,
                          color: tagValues?.colorCode,
                        }}
                        key={index}
                      >
                        {tagValues?.tagName}{' '}
                        <Icon
                          className="text-14 align-middle"
                          onClick={() => handleDeleteTag(tagValues?.id)}
                        >
                          close
                        </Icon>
                      </span>
                    ))}
                </div>
              )}
              {selectedLabels?.length > 0 && (
                <Button
                  className="min-w-auto w-auto hover:bg-transparent text-red font-medium text-14 p-6 h-16 min-h-16 mt-2"
                  onClick={() => setSelectedLabels([])}
                >
                  {t('users.UserList.Filter.clearBtn')}
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center ">
          {selectedLabels?.length > 0 && (
            <div className="flex flex-wrap items-center gap-8 mt-16">
              {labels
                ?.filter((label) => selectedLabels?.includes(label?.id))
                ?.map((label, i) => {
                  return (
                    <div
                      className="flex gap-16 items-center border border-solid border-gray rounded-full px-16 py-8"
                      key={i}
                    >
                      <div className="flex gap-6 items-center">
                        <div
                          className="w-14 h-14 rounded-full"
                          style={{ backgroundColor: label?.color }}
                        />
                        <Typography className="font-semibold text-14">
                          {label?.label} ({label?.businessAssociatedCount})
                        </Typography>
                      </div>
                      <Button
                        className="min-w-16 w-16 hover:bg-transparent p-0 h-16 min-h-16"
                        onClick={() => handleRemoveFilter(label)}
                      >
                        <CloseIcon />
                      </Button>
                    </div>
                  );
                })}
            </div>
          )}
        </div>

        <DialogActions className="p-0 mt-20 justify-end">
          <Button
            className="md:text-16 font-medium disabled:text-black rounded-md w-full md:max-w-288 mx-10"
            variant="contained"
            color={callFrom === 'whatsapp' ? 'quickChat' : 'quickCampaign'}
            type="submit"
            disabled={
              _.isEmpty(dirtyFields) ||
              !isValid ||
              refreshIcon ||
              errorMessage === false ||
              (getValues('mail') && !/^\S+@\S+\.\S+$/.test(getValues('mail'))) ||
              phone?.phoneNumber?.length === 0
            }
          >
            {popUpButtonName}
            {refreshIcon && (
              <CircularProgress
                size={24}
                className={`absolute mx-auto ${
                  callFrom === 'whatsapp' ? 'text-quick-chat' : 'text-quick-campaign'
                }`}
              />
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddContact;
