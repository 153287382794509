import { t } from 'i18next';
import moment from 'moment-timezone';
import {
  Button,
  Icon,
  MenuItem,
  Select,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableSortLabel,
  Input,
  Pagination,
  Drawer,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  TextField,
  InputAdornment,
} from '@mui/material';
import { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { useSnackbar } from 'notistack';
import { ReactComponent as AddContactIcon } from 'src/app/main/image/add-contact.svg';
import './Waba.css';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import wabaQuery from 'src/app/main/query/waba';
import businessData from 'src/app/main/query/business';
import {
  formatDateWithTimeZone,
  handleApiRequest,
  storeCsvFileDataToServer,
  handleWabaApiRequest,
  hexToRgb,
} from 'src/app/main/common/common';
import { selectDashboardData } from 'src/app/store/userSlice';
import AddContact from './AddContact';

const WabaContact = () => {
  const location = useLocation();
  const timezone = moment.tz.guess();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const currentDate = new Date();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [ContactList, setContactList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [openDialogType, setOpenDialogType] = useState('add');
  const [selectedOpted, setSelectedOpted] = useState('');
  const [selectedOptIn, setSelectedOptIn] = useState('');
  const [createAt, setCreateAt] = useState({});
  const [selectedActiveStatus, setSelectedActiveStatus] = useState('');
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [searchLabelName, setSearchLabelName] = useState();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [selectedRow, setSelectedRow] = useState(null);
  const [openCreateDialogue, setOpenCreateDialogue] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [customEndDate, setCustomEndDate] = useState('');
  const [customStartDate, setCustomStartDate] = useState('');
  const [startDate, setStartDate] = useState(currentDate.getTime().toString());
  const [endDate, setEndDate] = useState(currentDate.getTime().toString());
  const [isFilter, setIsFilter] = useState(false);
  const fileRef = useRef();

  const handleGetTagAll = useCallback(async () => {
    try {
      const payload = {
        query: wabaQuery.getWaChatTags,
        variables: {
          pageSize: 1000,
          pageNumber: 1,
          searchText: searchLabelName,
        },
      };
      const res = await handleWabaApiRequest(payload);

      if (res?.getWaChatTags?.status === 200) {
        setTagList(res?.getWaChatTags?.data);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [searchLabelName]);

  useEffect(() => {
    handleGetTagAll();
  }, [handleGetTagAll]);

  useEffect(() => {
    if (location.state?.openDialog) {
      setOpenCreateDialogue(true);
      setOpenDialogType('add');
    }
  }, [location.state]);

  const handleContactList = useCallback(
    async (optedIn, duration, tags) => {
      if (userCurrentBusinessDetails?.id) {
        try {
          const payload = {
            query: businessData.getUserCampaignContact,
            variables: {
              callFrom: 'whatsapp',
              userBusinessId: userCurrentBusinessDetails?.id,
              defaultSave: true,
              searchText: searchValue,
              pageNumber: page,
              ...(optedIn && { optIn: optedIn !== 'no' }),
              ...(duration && Object?.keys(duration)?.length > 0 && { createdAt: duration }),
              ...(tags && { tagIds: tags }),
            },
          };
          const result = await handleApiRequest(payload);
          if (result?.getUserCampaignContact?.status === 200) {
            setContactList(result?.getUserCampaignContact?.data);
            setTotalCount(result?.getUserCampaignContact?.totalCount);
          }
        } catch (error) {
          console.error('An error occurred:', error);
        }
      }
    },
    [searchValue, userCurrentBusinessDetails?.id, page]
  );

  useEffect(() => {
    handleContactList();
  }, [handleContactList]);

  const cancelDialogue = async () => {
    setOpenCreateDialogue(false);
    setSelectedRow({});
    setOpenDialogType('add');
    handleContactList();
  };

  const handleSearchEvent = async (event) => {
    setSearchValue(event.target.value);
    setPage(1);
  };

  const handleClearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
  };

  const handleDownloadCsvFile = async () => {
    const payload = {
      query: businessData.getCampaignPlatformDemoCsv,
    };
    const result = await handleApiRequest(payload);
    if (result?.getCampaignPlatformDemoCsv?.status === 200) {
      const demoCsv = result?.getCampaignPlatformDemoCsv?.data?.demoCsv;
      const downloadLink = document.createElement('a');
      downloadLink.href = demoCsv;
      downloadLink.target = '_blank';
      downloadLink.download = 'demo.csv';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedOptIn('');
    setCreateAt({});
    setSelectedLabels([]);
    setSelectedOpted('');
  };

  function EnhancedTableHead() {
    return (
      <TableHead className="bg-white rounded-md hidden md:table-header-group">
        <TableRow>
          <TableCell
            className="text-18 font-semibold border-b-2 border-solid border-gray-500 h-48"
            align="left"
          >
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.contactList.table.srNo')}
            </TableSortLabel>
          </TableCell>
          <TableCell
            className="text-18 font-semibold border-b-2 border-solid border-gray-500 h-48"
            align="left"
          >
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.contactList.table.name')}
            </TableSortLabel>
          </TableCell>
          <TableCell
            className="text-18 font-semibold border-b-2 border-solid border-gray-500 h-48"
            align="left"
          >
            <TableSortLabel hideSortIcon>{t('common.phone')}</TableSortLabel>
          </TableCell>
          <TableCell
            className="text-18 font-semibold border-b-2 border-solid border-gray-500"
            align="left"
          >
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.contactList.table.tag')}
            </TableSortLabel>
          </TableCell>
          <TableCell
            className="text-18 font-semibold border-b-2 border-solid border-gray-500 h-48"
            align="left"
          >
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.contactList.table.createdDate')}
            </TableSortLabel>
          </TableCell>
          <TableCell
            className="text-18 font-semibold border-b-2 border-solid border-gray-500 h-48"
            align="left"
          >
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.contactList.table.optedIn')}
            </TableSortLabel>
          </TableCell>
          <TableCell
            className="text-18 font-semibold border-b-2 border-solid border-gray-500 h-48"
            align="left"
          >
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.contactList.table.optedOut')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-18 font-semibold border-b-2 border-solid border-gray-500 h-48 rounded-tr-md w-96" />
        </TableRow>
      </TableHead>
    );
  }

  const handleCancelSubscription = async () => {
    const payloadData = {
      query: wabaQuery.deleteUserCsvContactOrCampaignContact,
      variables: {
        phoneNumber: `${selectedRow?.phoneNumber}`,

        id: selectedRow?.id,
      },
    };
    if (selectedRow?.dataFrom) {
      payloadData.variables.dataFrom = selectedRow?.dataFrom;
    }
    if (selectedRow?.email) {
      payloadData.variables.email = selectedRow?.email;
    }
    const result = await handleApiRequest(payloadData);
    if (result?.deleteUserCsvContactOrCampaignContact?.status === 200) {
      enqueueSnackbar(result?.deleteUserCsvContactOrCampaignContact?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setOpenDialog(false);
      setSelectedRow(null);
      handleContactList();
    }
  };

  const handleWabaFileInputChange = async (e) => {
    const selectedFile = e?.target?.files[0];
    if (selectedFile?.type === 'text/csv' || selectedFile?.name?.endsWith('.csv')) {
      const payload = {
        query: businessData.saveUserCSV,
        variables: {
          file: selectedFile,
          variables: {
            callFrom: 'whatsapp',
          },
          defaultSave: true,
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
        },
      };
      const result = await storeCsvFileDataToServer(payload);
      if (result?.saveUserCSV?.status === 200) {
        e.target.value = '';
        handleContactList();
        enqueueSnackbar(result?.saveUserCSV?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar(result?.saveUserCSV?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } else {
      enqueueSnackbar(t('sendMessage.onlyCscFileSelect'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const createdAt = (e) => {
    setSelectedOpted(e?.target?.value);
    if (e?.target?.value === 'in24Hour') {
      setCreateAt({
        timeKey: 'in24Hour',
      });
    }
    if (e?.target?.value === 'thisWeek') {
      setCreateAt({
        timeKey: 'thisWeek',
      });
    }
    if (e?.target?.value === 'thisMonth') {
      setCreateAt({
        timeKey: 'thisMonth',
      });
    }

    if (e?.target?.value === 'custom') {
      setCreateAt({
        timeKey: 'custom',
        customDate: {
          customStartDate,
          customEndDate,
        },
      });
    }
  };

  return (
    <>
      <div className="lg:p-24 p-16">
        <div className="flex w-full items-center justify-between lg:mb-20 mb-16 ">
          <div className="flex items-center ">
            <Typography
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="sm:flex md:text-28 text-20 font-bold"
            >
              {t('navigation.wabaContacts')}
            </Typography>
          </div>
          <div className="flex items-center justify-start md:justify-end w-full md:w-2/5 gap-10 flex-col xs:flex-row">
            <label htmlFor="button-file" className="xs:w-auto w-full">
              <input
                className="hidden"
                id="button-file"
                type="file"
                ref={fileRef}
                onChange={handleWabaFileInputChange}
                aria-labelledby="upload-label"
              />
              <Button
                className="border rounded-md bg-white min-w-160 text-16"
                variant="outlined"
                color="quickChat"
                component="span"
                id="upload-label"
              >
                {t('waba.broadcast.newBroadcast.importCsv')}
              </Button>
            </label>
            <Button
              onClick={() => setOpenCreateDialogue(true)}
              className="rounded-md text-16 font-medium inline-block align-middle min-w-160"
              variant="contained"
              color="quickChat"
            >
              <Icon className="mr-5 align-middle text-20">add</Icon>
              {t('waba.broadcast.contactList.addContact')}
            </Button>
          </div>
        </div>

        <div>
          {(Number(totalCount) !== 0 || searchValue !== '' || isFilter) && (
            <div className="md:flex items-center justify-between">
              <div className="flex items-start sm:items-center justify-between sm:flex-row flex-col w-full mb-16">
                <h3 className="text-20 font-semibold">{t('waba.broadcast.contactList.title')}</h3>
                <div>
                  <div className="inline-block align-middle w-full sm:w-360  md:my-0 my-12 shadow-none bg-white border-1 border-solid border-grey-300 rounded-md relative">
                    <Input
                      placeholder={t('waba.broadcast.contactList.search')}
                      className="text-black text-16 h-44 px-12"
                      disableUnderline
                      name="searchText"
                      fullWidth
                      onChange={handleSearchEvent}
                      value={searchValue}
                      inputProps={{
                        'aria-label': 'Search',
                      }}
                    />
                    <span className="absolute right-8 top-10">
                      {searchValue ? (
                        <Icon
                          color="quickChat"
                          className="cursor-pointer text-20"
                          onClick={() => handleClearSearch()}
                        >
                          close
                        </Icon>
                      ) : (
                        <Icon color="quickChat" className="text-24 text-grey-600">
                          search
                        </Icon>
                      )}
                    </span>
                  </div>
                  <div
                    className="cursor-pointer text-14 px-12 ml-12 pt-8 font-medium inline-block align-middle bg-white rounded-md shadow-none border-1 border-solid border-grey-300 h-44"
                    onClick={() => setIsPopupOpen(true)}
                    aria-hidden="true"
                  >
                    <Icon className="text-24 align-middle text-black mr-8">filter_list</Icon>
                    {t('quickSocial.filter')}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-wrap gap-12">
            {selectedOpted && (
              <div className="border-2 border-black rounded-full flex items-center p-8 gap-9">
                <Typography className="font-semibold text-14">{selectedOpted}</Typography>
                <button
                  type="button"
                  className="font-semibold"
                  onClick={() => {
                    setSelectedOpted('');
                    setCreateAt({});
                    handleContactList(selectedOptIn, {}, selectedLabels);
                  }}
                >
                  X
                </button>
              </div>
            )}
            {selectedOptIn && (
              <div className="border-2 border-black rounded-full flex items-center p-8 gap-9">
                <Typography className="font-semibold text-14">
                  Opted in : {selectedOptIn}
                </Typography>
                <button
                  type="button"
                  className="font-semibold"
                  onClick={() => {
                    setSelectedOptIn('');
                    handleContactList('', createAt, selectedLabels);
                  }}
                >
                  X
                </button>
              </div>
            )}
            {(selectedOptIn || selectedOpted) && (
              <button
                type="button"
                style={{ color: '#d50000' }}
                className="font-medium"
                onClick={() => {
                  setSelectedOpted('');
                  setCreateAt({});
                  setSelectedOptIn('');
                  handleContactList();
                }}
              >
                {t('users.UserList.Filter.clearBtn')}
              </button>
            )}
          </div>
          <div>
            <div className="w-full">
              <div
                className={`${
                  ContactList?.length === 0 && 'flex justify-center items-center'
                } h-full`}
              >
                {ContactList && ContactList?.length > 0 ? (
                  <div className="w-full h-full">
                    <TableContainer className="bg-white rounded-md">
                      <Table
                        className="w-full border-separate border-spacing-0"
                        aria-labelledby="tableTitle"
                        size="medium"
                      >
                        <EnhancedTableHead />
                        <TableBody className="block md:table-row-group">
                          {ContactList?.map((row, index) => {
                            return (
                              <TableRow
                                className="block md:table-row cursor-pointer hover:bg-quick-chat-100 hover:bg-opacity-30"
                                role="checkbox"
                                tabIndex={-1}
                                key={index}
                                onMouseEnter={() => setHoveredRow(index)}
                                onMouseLeave={() => setHoveredRow(null)}
                              >
                                <TableCell
                                  className="h-48 max-h-48 border-b-[1px] border-solid border-gray-300 text-16 color-black font-medium block md:table-cell md:w-60 py-5 pt-16 md:py-10"
                                  align="left"
                                >
                                  {(page - 1) * rowsPerPage + index + 1}
                                </TableCell>
                                <TableCell
                                  className="h-48 max-h-48  border-b-[1px] border-solid border-gray-300 text-16 color-black font-medium block md:table-cell md:max-w-256 py-5 md:py-10"
                                  align="left"
                                >
                                  {row?.name}
                                </TableCell>
                                <TableCell
                                  className="h-48 max-h-48  border-b-[1px] border-solid border-gray-300 text-16 color-black font-medium block md:table-cell md:max-w-136 py-5 md:py-10"
                                  align="left"
                                >
                                  <span className="inline-block align-middle md:hidden mr-10 font-bold text-quick-chat">
                                    {t('users.deactivatedAccount.labels.deactivatedDate')}:
                                  </span>
                                  {row?.phoneNumber
                                    ? `${row?.countryCode} ${row?.phoneNumber}`
                                    : '-'}
                                </TableCell>
                                <TableCell
                                  className="h-48 max-h-48  border-b-[1px] border-solid border-gray-300 text-16 color-black font-semibold block md:table-cell md:max-w-256 py-5 md:py-10"
                                  align="left"
                                >
                                  <div className="flex gap-4 flex-wrap">
                                    {row?.waTags?.length > 0 ? (
                                      <>
                                        {row?.waTags?.slice(0, 2)?.map((label, i) => (
                                          <span
                                            key={i}
                                            style={{
                                              backgroundColor: `rgba(${hexToRgb(
                                                label?.colorCode
                                              )}, 0.1)`,
                                              color: label?.colorCode,
                                            }}
                                            className="rounded-md px-16 py-3 font-medium text-12"
                                          >
                                            {label?.tagName}
                                          </span>
                                        ))}

                                        {row?.waTags?.length > 2 && (
                                          <span className="font-medium text-12 px-8 py-2">
                                            +{Math.max(0, (row?.waTags?.length || 0) - 2)} more
                                          </span>
                                        )}
                                      </>
                                    ) : (
                                      '_ _ _'
                                    )}
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="h-48 max-h-48  border-b-[1px] border-solid border-gray-300 text-16 color-black font-medium block md:table-cell py-5 pb-16 md:py-10 capitalize"
                                  align="left"
                                >
                                  <span>
                                    {row.cts
                                      ? formatDateWithTimeZone(row.cts / 1000, 'MMM DD, YYYY')
                                      : '-'}
                                  </span>
                                </TableCell>
                                <TableCell
                                  className="h-48 max-h-48  border-b-[1px] border-solid border-gray-300 text-16 color-black font-medium block md:table-cell py-5 pb-16 md:py-10 capitalize"
                                  align="left"
                                >
                                  <span>{row?.waOptedIn ? 'yes' : 'No'}</span>
                                </TableCell>
                                <TableCell
                                  className="h-48 max-h-48  border-b-[1px] border-solid border-gray-300 text-16 color-black font-medium block md:table-cell py-5 pb-16 md:py-10 capitalize"
                                  align="left"
                                >
                                  <span>{!row?.waOptedIn ? 'yes' : 'No'}</span>
                                </TableCell>
                                <TableCell
                                  className="relative h-48 max-h-48 border-b-[1px] border-solid border-gray-300 text-16 color-black font-medium block py-5 pb-16 md:py-10"
                                  align="left"
                                >
                                  {hoveredRow === index ? (
                                    <div className="flex gap-12 absolute top-5 right-0 left-0">
                                      <Button
                                        className="p-0 min-w-36 w-36 min-h-36 h-36 bg-quick-chat-100 hover:bg-quick-chat-100"
                                        onClick={() => {
                                          setOpenCreateDialogue(true);
                                          setSelectedRow(row);
                                          setOpenDialogType('edit');
                                        }}
                                      >
                                        <img src="assets/images/quick-hub/edit.png" alt="" />
                                      </Button>
                                      <Button
                                        className="p-0 min-w-36 w-36 min-h-36 h-36 bg-quick-chat-100 hover:bg-quick-chat-100"
                                        onClick={() => {
                                          setSelectedRow(row);
                                          setOpenDialog(true);
                                        }}
                                      >
                                        <img src="assets/images/quick-hub/delete.png" alt="" />
                                      </Button>
                                    </div>
                                  ) : (
                                    <div className="flex gap-16 ">
                                      <div className="p-3">
                                        <div className="bg-transparent rounded-full h-16 w-16" />
                                      </div>
                                      <div className=" p-3">
                                        <div className="bg-transparent rounded-full h-16 w-16" />
                                      </div>
                                    </div>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <div className="w-full flex justify-end mt-16">
                      <Pagination
                        count={Math.ceil(totalCount / 10)}
                        page={page}
                        onChange={handlePageChange}
                        showFirstButton
                        showLastButton
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    {searchValue ? (
                      <div className="text-center h-full flex items-center">
                        <div className="mx-auto">
                          <AddContactIcon className="chat-svg w-144 mx-auto" />
                          <Typography className="text-24 font-semibold mt-36">
                            {t('waba.broadcast.contactList.noContactWithSearch')}
                          </Typography>
                          <Typography className="text-16 font-medium max-w-640 mt-16 mb-20">
                            {t('waba.broadcast.contactList.noContactDescWithSearch')}
                          </Typography>
                        </div>
                      </div>
                    ) : (
                      <div className="flex justify-center items-center py-112 text-center">
                        <div className="mx-auto">
                          <AddContactIcon className="chat-svg w-144 mx-auto" />
                          <Typography className="text-24 font-semibold mt-36">
                            {t('waba.broadcast.contactList.noContact')}
                          </Typography>
                          <Typography className="text-16 font-medium max-w-640 mt-16 mb-20">
                            {t('waba.broadcast.contactList.noContactDesc')}
                          </Typography>
                          <Button
                            onClick={() => setOpenCreateDialogue(true)}
                            className="rounded-md md:px-40 text-16 font-medium w-full max-w-288"
                            variant="contained"
                            color="quickChat"
                          >
                            <Icon className="mr-5 align-middle font-normal">add</Icon>{' '}
                            {t('waba.broadcast.contactList.addContact')}
                          </Button>
                          <div>
                            <Button
                              variant="outlined"
                              color="quickChat"
                              className="rounded-md mt-16 md:px-16 w-full max-w-288 bg-white"
                              onClick={handleDownloadCsvFile}
                            >
                              {t('quickCampaignsContacts.downloadDemoCSV')}
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {openCreateDialogue && (
          <AddContact
            openValue={openCreateDialogue}
            cancelCall={cancelDialogue}
            type={openDialogType}
            popUpMessage={
              openDialogType === 'edit'
                ? t('waba.broadcast.contactList.editContact')
                : t('waba.broadcast.contactList.addContact')
            }
            popUpButtonName={
              openDialogType === 'edit'
                ? t('waba.broadcast.contactList.editContact')
                : t('waba.broadcast.contactList.addContact')
            }
            selectedRow={selectedRow}
            initialValues={{
              firstName: selectedRow?.firstName || selectedRow?.name?.split(' ')?.[0],
              lastName: selectedRow?.lastName || selectedRow?.name?.split(' ')?.[1],
              phone_number:
                selectedRow && `${selectedRow?.countryCode} ${selectedRow?.phoneNumber}`,
              mail: selectedRow?.email ? selectedRow?.email : '',
            }}
            callFrom="whatsapp"
            getData={handleContactList}
          />
        )}
        {openDialog && (
          <>
            <Dialog
              classes={{
                paper: 'm-24 px-28 py-40 rounded max-w-512',
              }}
              open={openDialog}
              onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                  setOpenDialog(false);
                }
              }}
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle className="p-0 text-center">
                <img
                  src="/assets/images/icon/trashBin.png"
                  className="img-fluid mx-auto w-96 h-96"
                  alt="..."
                />
                <Typography className="text-20 font-semibold text-black text-center w-full pb-10 mt-28 mb-0">
                  {t('waba.broadcast.contactList.confirmDeletion')}
                </Typography>
                <Icon
                  onClick={() => {
                    setOpenDialog(false);
                  }}
                  className="text-24 absolute top-12 right-12 text-black font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
                >
                  close
                </Icon>
              </DialogTitle>
              <DialogContent className="p-0 mb-28">
                <DialogContentText
                  id="alert-dialog-description"
                  className="text-16 font-medium text-center text-black max-w-sm mx-auto"
                >
                  {t('waba.broadcast.contactList.confirmDeletionDesc')}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="p-0 justify-center">
                <Button
                  variant="outlined"
                  color="quickChat"
                  className="md:text-16 font-medium disabled:text-black rounded-md px-10 xs:px-24 pb-6 min-w-160 min-h-40"
                  aria-label={t('common.cancel')}
                  onClick={() => {
                    setOpenDialog(false);
                  }}
                  type="button"
                  size="small"
                >
                  {t('common.cancel')}
                </Button>
                <Button
                  variant="contained"
                  color="quickChat"
                  className="md:text-16 font-medium disabled:text-black rounded-md px-10 xs:px-24 pb-6 min-w-160 min-h-40"
                  aria-label={t('common.confirm')}
                  onClick={() => handleCancelSubscription()}
                  // disabled={refreshCancelSubscriptionIcon}
                  type="button"
                  size="small"
                >
                  {t('common.confirm')}
                  {/* {refreshCancelSubscriptionIcon && (
                    <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                  )} */}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
        <Drawer
          anchor="right"
          open={isPopupOpen}
          onClose={() => {
            handleClosePopup();
            handleContactList();
          }}
          sx={{ '& .MuiDrawer-paper': { width: '100%', maxWidth: '510px' } }}
        >
          <div>
            <div className="flex justify-between px-20 py-16 bg-white border-b border-solid border-gray-600">
              <h2 className="text-20 font-bold mb-4 text-center justify-center items-center flex">
                {t('quickSocial.filter')}
              </h2>
              <CloseIcon
                onClick={() => {
                  handleClosePopup();
                  handleContactList();
                }}
                className="cursor-pointer"
              />
            </div>
            <div className="px-24 quick-chat">
              <div className="mb-16">
                <div className="text-18 font-semibold py-10 block">
                  {t('waba.broadcast.contactList.table.tags')}
                </div>
                <Select
                  className="w-full"
                  id="labels"
                  multiple
                  value={selectedLabels}
                  sx={{ '& .MuiSelect-select': { display: 'flex', alignItems: 'center' } }}
                  size="small"
                  displayEmpty
                  onChange={(e) => setSelectedLabels(e.target.value)}
                  renderValue={() => t('common.selectTag')}
                  onOpen={() => {
                    setTimeout(() => {
                      const menuElement = document.querySelector('.MuiMenu-paper');
                      if (menuElement) {
                        menuElement.scrollTop = 0;
                      }
                    }, 10);
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: 250,
                        overflowY: 'auto',
                      },
                    },
                  }}
                >
                  <MenuItem disableRipple>
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      placeholder={t('common.searchTag')}
                      value={searchLabelName}
                      onChange={(e) => setSearchLabelName(e.target.value)}
                      onKeyDown={(e) => e.stopPropagation()}
                      sx={{
                        backgroundColor: 'white',
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: 'white',
                        },
                        '& .MuiOutlinedInput-input': {
                          backgroundColor: 'white',
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: searchLabelName && (
                          <InputAdornment position="end">
                            <Icon
                              color="quickChat"
                              className="cursor-pointer text-20"
                              onClick={() => setSearchLabelName('')}
                            >
                              close
                            </Icon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MenuItem>
                  {tagList?.length > 0 ? (
                    tagList?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <Checkbox checked={selectedLabels.includes(option.id)} />
                        <span
                          className="h-14 w-14 mr-6 rounded-full"
                          style={{ backgroundColor: option.colorCode }}
                        />
                        {option.tagName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>{t('manageLabels.noFound')}</MenuItem>
                  )}
                </Select>
                {selectedLabels?.length > 0 && (
                  <div className="flex flex-wrap gap-8 mt-12">
                    {tagList &&
                      tagList
                        ?.filter((item) => selectedLabels?.includes(item?.id))
                        ?.map((tag, index) => (
                          <div
                            key={index}
                            className="rounded-md p-7 h-20 min-w-max flex justify-center items-center gap-4"
                            style={{
                              backgroundColor: `${tag?.colorCode}20`,
                            }}
                          >
                            <Typography style={{ color: tag?.colorCode }} className="font-semibold">
                              {tag?.tagName}
                            </Typography>
                            <button
                              type="button"
                              style={{ color: tag?.colorCode }}
                              className="font-bold"
                              color="error"
                              onClick={() => {
                                setSelectedLabels((prev) => prev?.filter((id) => id !== tag?.id));
                              }}
                            >
                              X
                            </button>
                          </div>
                        ))}
                    <button
                      type="button"
                      color="error"
                      onClick={() => {
                        setSelectedLabels([]);
                      }}
                      style={{ color: '#d50000' }}
                      className="font-medium"
                    >
                      {t('users.UserList.Filter.clearBtn')}
                    </button>
                  </div>
                )}
              </div>
              <div className="mb-16">
                <div className="text-18 font-semibold px-16 py-10 bg-gray-A500 block">
                  {t('waba.broadcast.contactList.createdAt')}
                </div>
                <div className="border-1 border-solid border-gray-A500 p-16 border-t-[0px]">
                  <FormControl className="w-full">
                    <RadioGroup
                      aria-labelledby="delivery-option"
                      className="block"
                      name="opted-option"
                      value={selectedOpted}
                      onChange={createdAt}
                    >
                      <FormControlLabel
                        value="in24Hour"
                        control={<Radio />}
                        className="flex flex-row-reverse justify-between m-0 mb-10"
                        sx={{
                          '& .MuiRadio-root': { padding: '0' },
                          '& .MuiFormControlLabel-label': { fontSize: '16px', fontWeight: '500' },
                        }}
                        label={t('common.24Hrs')}
                      />
                      <FormControlLabel
                        value="thisWeek"
                        control={<Radio />}
                        className="flex flex-row-reverse justify-between m-0 mb-10"
                        sx={{
                          '& .MuiRadio-root': { padding: '0' },
                          '& .MuiFormControlLabel-label': { fontSize: '16px', fontWeight: '500' },
                        }}
                        label={t('common.week')}
                      />
                      <FormControlLabel
                        value="thisMonth"
                        control={<Radio />}
                        className="flex flex-row-reverse justify-between m-0 mb-10"
                        sx={{
                          '& .MuiRadio-root': { padding: '0' },
                          '& .MuiFormControlLabel-label': { fontSize: '16px', fontWeight: '500' },
                        }}
                        label={t('common.month')}
                      />
                      <FormControlLabel
                        value="custom"
                        control={<Radio />}
                        className="flex flex-row-reverse justify-between m-0"
                        sx={{
                          '& .MuiRadio-root': { padding: '0' },
                          '& .MuiFormControlLabel-label': { fontSize: '16px', fontWeight: '500' },
                        }}
                        label={t('common.custom')}
                      />
                    </RadioGroup>
                    {selectedOpted === 'custom' && (
                      <div className="flex gap-2 px-4 py-2 justify-around my-24">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            value={startDate}
                            onChange={(date) => {
                              setCustomStartDate(moment(date).tz(timezone).format('YYYY-MM-DD'));
                              setStartDate(date);
                            }}
                            className="max-w-192 max-h-40 border-none bg-gray-A500 flex justify-center items-center p-8 "
                            format="MM dd, yyyy"
                            maxDate={new Date()}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                fontSize: '14px',
                                border: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                textAlign: 'center',
                                paddingRight: '0px',
                              },
                              '& .MuiOutlinedInput-input': {
                                padding: '0px',
                                height: '32px',
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                              },
                              '& .MuiInputLabel-root': {
                                textAlign: 'center',
                                width: '100%',
                                display: 'flex',
                              },
                              '& .MuiIconButton-root': {
                                padding: '0px',
                                minWidth: 'auto',
                                margin: '0px',
                              },
                            }}
                          />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            value={endDate}
                            onChange={(date) => {
                              setCustomEndDate(moment(date).tz(timezone).format('YYYY-MM-DD'));
                              setEndDate(date);
                            }}
                            className="max-w-192 max-h-40 border-none bg-gray-A500 flex justify-center items-center p-8"
                            format="MM dd, yyyy"
                            maxDate={new Date()}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                fontSize: '14px',
                                border: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                textAlign: 'center',
                                paddingRight: '0px',
                              },
                              '& .MuiOutlinedInput-input': {
                                padding: '0px',
                                height: '32px',
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                              },
                              '& .MuiInputLabel-root': {
                                textAlign: 'center',
                                width: '100%',
                                display: 'flex',
                              },
                              '& .MuiIconButton-root': {
                                padding: '0px',
                                minWidth: 'auto',
                                margin: '0px',
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    )}
                  </FormControl>
                </div>
              </div>
              <div className="mb-16">
                <div className="text-18 font-semibold px-16 py-10 bg-gray-A500 block">
                  {t('waba.broadcast.contactList.table.optedIn')}
                </div>
                <div className="border-1 border-solid border-gray-A500 p-16 border-t-[0px]">
                  <FormControl className="w-full">
                    <RadioGroup
                      aria-labelledby="delivery-option"
                      className="block"
                      name="opted-option"
                      value={selectedOptIn}
                      onChange={(e) => setSelectedOptIn(e?.target?.value)}
                    >
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        className="flex flex-row-reverse justify-between m-0 mb-10"
                        sx={{
                          '& .MuiRadio-root': { padding: '0' },
                          '& .MuiFormControlLabel-label': { fontSize: '16px', fontWeight: '500' },
                        }}
                        label={t('common.yes')}
                      />
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        className="flex flex-row-reverse justify-between m-0"
                        sx={{
                          '& .MuiRadio-root': { padding: '0' },
                          '& .MuiFormControlLabel-label': { fontSize: '16px', fontWeight: '500' },
                        }}
                        label={t('common.no')}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              {/* <div>
                <div className="text-18 font-semibold px-16 py-10 bg-gray-A500 block">
                  {t('waba.broadcast.contactList.activeStatus')}
                </div>
                <div className="border-1 border-solid border-gray-A500 p-16 border-t-[0px]">
                  <FormControl className="w-full">
                    <RadioGroup
                      aria-labelledby="delivery-option"
                      className="block"
                      name="opted-option"
                      value={selectedActiveStatus}
                      onChange={(e) => setSelectedActiveStatus(e?.target?.value)}
                    >
                      <FormControlLabel
                        value="active"
                        control={<Radio />}
                        className="flex flex-row-reverse justify-between m-0 mb-10"
                        sx={{
                          '& .MuiRadio-root': { padding: '0' },
                          '& .MuiFormControlLabel-label': { fontSize: '16px', fontWeight: '500' },
                        }}
                        label={t('menuItems.active')}
                      />
                      <FormControlLabel
                        value="inActive"
                        control={<Radio />}
                        className="flex flex-row-reverse justify-between m-0"
                        sx={{
                          '& .MuiRadio-root': { padding: '0' },
                          '& .MuiFormControlLabel-label': { fontSize: '16px', fontWeight: '500' },
                        }}
                        label={t('menuItems.inActive')}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div> */}

              <div className="flex mt-28 gap-12 justify-end">
                <Button
                  className="rounded-md py-0 max-w-128 w-full text-16 font-medium min-h-32"
                  size="small"
                  variant="outlined"
                  color="quickChat"
                  type="button"
                  onClick={() => {
                    handleClosePopup();
                    handleContactList();
                  }}
                >
                  {t(`quickCampaign.campaign.clearFilter`)}
                  <Icon className="text-16">{t(`quickAds.close`)}</Icon>
                </Button>
                <Button
                  className="rounded-md py-0 max-w-128 w-full text-16 font-medium min-h-32"
                  size="small"
                  variant="contained"
                  color="quickChat"
                  type="button"
                  onClick={() => {
                    handleContactList(selectedOptIn, createAt, selectedLabels);
                    setIsPopupOpen(false);
                    setIsFilter(true);
                  }}
                >
                  {t('quickChat.apply')}
                </Button>
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
};

export default WabaContact;
