import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { t } from 'i18next';
import {
  Button,
  Checkbox,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import wabaQuery from 'src/app/main/query/waba';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';
import { ScheduleDialog, SuccessDialogue } from '../../common/Popup';
import SelectTemplate from '../selectTemplate/SelectTemplate';
import {
  capitalizeFirstLetter,
  convertTemplateToHtml,
  handleWabaApiRequest,
} from '../../../../../common/common';

const PostOrSchedule = (props) => {
  const {
    tabValue,
    template,
    selectedContact,
    setStepsCount,
    viewTemplate,
    setViewTemplate,
    selectedVariables,
    setSelectedVariables,
    setCampaignName,
    campaignName,
    isBroadcastSchedule,
    isAllPageContactSelect,
    setImageUrl,
    setTemplate,
    imageUrl,
  } = props;
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [scheduledDate, setScheduledDate] = useState(null);
  const [scheduledTime, setScheduledTime] = useState(null);
  const [successDialogue, setSuccessDialogue] = useState(false);
  const [campaignScheduledSuccessFully, setCampaignScheduledSuccessFully] = useState(false);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [openSelectTemplateDialogue, setOpenSelectTemplateDialogue] = useState(false);

  useEffect(() => {
    if (!template?.scheduledDate) {
      return;
    }
    const date = new Date(template?.scheduledDate);
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone,
    }).formatToParts(date);

    const parsedDate = {
      year: formattedDate?.find((p) => p.type === 'year')?.value,
      month: formattedDate?.find((p) => p.type === 'month')?.value,
      day: formattedDate?.find((p) => p.type === 'day')?.value,
      hour: formattedDate?.find((p) => p.type === 'hour')?.value,
      minute: formattedDate?.find((p) => p.type === 'minute')?.value,
    };
    const localDate = new Date(
      `${parsedDate.year}-${parsedDate.month}-${parsedDate.day}T${parsedDate.hour}:${parsedDate.minute}`
    );
    setScheduledDate(localDate);
    setScheduledTime(localDate);
  }, [template?.scheduledDate]);

  const handleCreateBroadCast = async () => {
    const hours = new Date(scheduledTime)?.getHours();
    const minutes = new Date(scheduledTime)?.getMinutes();
    const seconds = new Date(scheduledTime)?.getSeconds();
    const updatedScheduledDate = new Date(scheduledDate);
    updatedScheduledDate.setHours(hours, minutes, seconds, 0);
    const isoString = updatedScheduledDate.toISOString();
    const result = selectedContact?.map(({ name, phoneNumber, countryCode }) => ({
      customerName: name,
      phoneNumber: `${countryCode?.replace('+', '')}${phoneNumber}`,
    }));
    setCampaignScheduledSuccessFully(isSchedule);
    try {
      const payload = {
        query: isBroadcastSchedule ? wabaQuery.updateBroadcast : wabaQuery.createBroadcast,
        variables: {
          contact: result,
          isScheduled: isSchedule,
          scheduledDate: isoString,
          name: campaignName,
          allContactSelected: isAllPageContactSelect,
          templateId: Number(template?.id),
          dynamicVariables: JSON.stringify({
            body: selectedVariables?.map((item) => ({
              ...item,
              description: item.description.replace(/\[|\]/g, ''),
            })),
            header: template?.dynamicData?.header?.map((item) => ({ ...item, value: imageUrl })),
            footer: template?.templateData?.components?.find((item) => item?.type === 'FOOTER')
              ?.text,
            buttons: template?.templateData?.components?.find((item) => item?.type === 'BUTTONS')
              ?.buttons,
          }),
          ...(!isBroadcastSchedule
            ? {
                userBusinessId: Number(userCurrentBusinessDetails?.id),
              }
            : {
                broadcastId: Number(template?.id),
              }),
        },
      };
      const res = await handleWabaApiRequest(payload);
      const response = isBroadcastSchedule ? res?.updateBroadcast : res?.createBroadcast;
      setRefreshIcon(false);
      if (response?.status === 200) {
        setCampaignName('');
        setSuccessDialogue(true);
      } else {
        enqueueSnackbar(response?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  return (
    <div>
      <div className="flex gap-8 items-center mt-16">
        <ArrowBackIcon onClick={() => setStepsCount(3)} />
        <Typography className="font-semibold text-20">
          {t('waba.broadcast.myBroadcast.postSchedule')}
        </Typography>
      </div>
      <div className="flex flex-wrap gap-16 flex-wrap justify-between mt-16">
        <div className="w-full lg:w-[1020px]">
          <div className="p-14 sm:p-24 bg-white rounded-md w-full quick-chat">
            <Typography className="text-18 font-semibold">
              {t('waba.broadcast.myBroadcast.campaignDetails')}
            </Typography>
            <Typography className="text-16 font-medium pb-10 pt-16">
              {t('waba.broadcast.myBroadcast.campaignName')}
            </Typography>
            <TextField
              className="w-full"
              value={campaignName}
              size="medium"
              placeholder="Enter Campaign Name"
              onChange={(e) => setCampaignName(e.target.value)}
              InputProps={{
                style: {
                  fontSize: '16px',
                  fontWeight: 500,
                },
              }}
            />
            <div className="flex items-center justify-between">
              <Typography className="text-16 font-medium py-10">
                {t('waba.broadcast.myBroadcast.selectedTemplate')}
              </Typography>
              <Button
                className="text-quick-chat font-semibold hover:bg-white text-16"
                onClick={() => setOpenSelectTemplateDialogue(true)}
              >
                {t('waba.broadcast.myBroadcast.change')}
              </Button>
            </div>
            <TextField
              className="w-full"
              value={template?.templateName || template?.templateData?.name}
              size="medium"
              InputProps={{
                style: {
                  fontSize: '16px',
                  fontWeight: 500,
                },
              }}
            />
            <div className="flex flex-col sm:flex-row gap-8 sm:gap-20 justify-start mt-20">
              <Button
                variant="outlined"
                color="quickChat"
                autoFocus
                className="rounded-md w-full sm:w-160 md:px-76 font-semibold text-16"
                size="medium"
                disabled={!campaignName}
                onClick={() => setIsSchedule(true)}
              >
                {t('quickCampaign.campaign.schedule')}
              </Button>
              <Button
                variant="contained"
                color="quickChat"
                autoFocus
                size="medium"
                onClick={() => handleCreateBroadCast()}
                disabled={!campaignName}
                className={`rounded-md w-full sm:w-160 ${
                  refreshIcon
                    ? 'disabled:bg-gray disabled'
                    : 'disabled:opacity-40 disabled:bg-quick-chat disabled:text-white'
                }`}
              >
                {t('quickCampaign.campaign.createNow')}
                {refreshIcon && (
                  <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
                )}
              </Button>
            </div>
          </div>
          <div className="bg-white mt-16 p-12 rounded-md w-full">
            <div className="flex items-center justify-between">
              <Typography className="text-18 font-semibold py-10">
                {t('waba.broadcast.myBroadcast.selectedContacts')}
              </Typography>
              <Button
                className="text-quick-chat font-semibold text-16"
                onClick={() => setStepsCount(2)}
              >
                {t('common.edit')}
              </Button>
            </div>
            <TableContainer component={Paper} className="rounded-md border-none shadow-0">
              <Table className="border border-solid border-t-0 min-w-480 md:min-w-586">
                <TableHead>
                  <TableRow className="bg-gray-A500">
                    <TableCell className="p-8 quick-chat sm:p-12 px-14 sm:px-24 border-b-2 border-grey-300 w-12">
                      <Checkbox
                        className="opacity-50 cursor-not-allowed text-gray-400"
                        checked
                        disabled
                      />
                    </TableCell>
                    <TableCell className="font-medium p-8 sm:p-12 px-14 sm:px-24 text-14 sm:text-18 border-b-2 border-grey-300">
                      {t('common.name')}
                    </TableCell>
                    <TableCell className="font-medium p-8 sm:p-12 px-14 sm:px-24 text-14 sm:text-18 border-b-2 border-grey-300">
                      {t('quickCampaignsContacts.contactNumber')}
                    </TableCell>
                    {tabValue === 2 && (
                      <TableCell className="font-medium p-8 sm:p-12 px-14 sm:px-24 text-14 sm:text-18 border-b-2 border-grey-300 w-[15%]" />
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedContact?.map((item, index) => {
                    return (
                      <TableRow
                        key={index}
                        className="quick-chat hover:bg-quick-chat-100 hover:bg-opacity-30 h-64 border-b-2 border-grey-300"
                      >
                        <TableCell className="font-medium p-8 sm:p-12 px-14 sm:px-24">
                          <Checkbox
                            className="opacity-50 cursor-not-allowed text-gray-400"
                            checked
                            disabled
                          />
                        </TableCell>

                        <TableCell className="font-semibold p-8 sm:p-12 px-14 sm:px-24 text-14 sm:text-18">
                          <div className="flex items-center gap-12">
                            <div
                              style={{
                                backgroundColor: `#${item.userProfile}33`,
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: `#${item.userProfile}`,
                                fontSize: '16px',
                                fontWeight: 'bold',
                              }}
                              className="sm:min-w-42 sm:min-h-42 min-w-28 min-h-28"
                            >
                              {item?.name?.charAt(0).toUpperCase()}
                            </div>
                            <Typography className="font-semibold text-16">
                              {capitalizeFirstLetter(item?.name)}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell className="font-semibold p-8 sm:p-12 px-14 sm:px-24 text-14 sm:text-18">
                          {item?.countryCode} {item?.phoneNumber}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <div className="w-full lg:w-[450px] bg-white px-40 pt-16">
          <Typography className="pb-20 font-bold text-16">
            {t('waba.broadcast.myBroadcast.preview')}
          </Typography>
          <div className="relative mb-16">
            <img
              className="w-380 m-auto h-[720px]"
              src="assets/images/quick-chat/phone.png"
              alt=""
            />
            <div className="absolute top-96 left-0 right-0 rounded-md max-w-224 xs:max-w-288 mx-auto  h-[572px] overflow-scroll">
              <div className="bg-white px-12 rounded-md pt-10">
                {template?.templateData?.components?.length > 0 &&
                  template?.templateData?.components?.map((templateValue, i) => (
                    <div key={i}>
                      {templateValue?.type === 'HEADER' && (
                        <>
                          {templateValue?.format === 'TEXT' && (
                            <Typography
                              className="font-bold"
                              dangerouslySetInnerHTML={{ __html: templateValue?.text }}
                            />
                          )}
                        </>
                      )}
                      {templateValue?.format === 'IMAGE' && (
                        <>
                          {template?.dynamicData?.header?.map((dynamicValue) => (
                            <>
                              {dynamicValue?.type === 'image' && (
                                <img
                                  src={imageUrl || dynamicValue?.value}
                                  className="mb-10 rounded-md w-full h-144 object-cover shadow-md"
                                  alt="..."
                                />
                              )}
                            </>
                          ))}
                        </>
                      )}
                      {templateValue?.type === 'BODY' && (
                        <>
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: convertTemplateToHtml(viewTemplate?.replace(/\n/g, '<br>')),
                            }}
                          />
                        </>
                      )}
                      {templateValue?.type === 'FOOTER' && (
                        <>
                          <Typography dangerouslySetInnerHTML={{ __html: templateValue?.text }} />
                        </>
                      )}
                    </div>
                  ))}
              </div>
              {template?.templateData?.components?.length > 0 &&
                template?.templateData?.components?.map((templateValue, i) => (
                  <div key={i}>
                    {templateValue?.type === 'BUTTONS' && (
                      <>
                        {templateValue?.buttons?.map((buttonValue, b) => (
                          <div className="bg-white shadow p-4 rounded-md mt-4 h-32 text-center">
                            <a
                              className="font-semibold text-[#0096DE] text-16"
                              href={`${
                                buttonValue?.phone_number ? `tel:${buttonValue?.phone_number}` : ''
                              }`}
                              rel="noreferrer"
                              target="_blank"
                              role="button"
                              key={b}
                            >
                              <img
                                src={
                                  buttonValue?.phone_number
                                    ? '/assets/images/business/telephone.png'
                                    : '/assets/images/business/view.png'
                                }
                                className="w-14 h-14 align-middle mr-6 inline-block relative -top-1"
                                alt="..."
                              />
                              {buttonValue?.text}
                            </a>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <ScheduleDialog
        setIsSchedule={setIsSchedule}
        isSchedule={isSchedule}
        setScheduledDate={setScheduledDate}
        scheduledDate={scheduledDate}
        scheduledTime={scheduledTime}
        setScheduledTime={setScheduledTime}
        handleCreateBroadCast={handleCreateBroadCast}
        refreshIcon={refreshIcon}
      />
      <SuccessDialogue
        successDialogue={successDialogue}
        setSuccessDialogue={setSuccessDialogue}
        isSchedule={campaignScheduledSuccessFully}
      />
      <SelectTemplate
        openSelectTemplateDialogue={openSelectTemplateDialogue}
        setOpenSelectTemplateDialogue={setOpenSelectTemplateDialogue}
        selectedVariables={selectedVariables}
        setSelectedVariables={setSelectedVariables}
        setTemplate={setTemplate}
        setImageUrl={setImageUrl}
        setViewTemplate={setViewTemplate}
        type="postOrSchedule"
      />
    </div>
  );
};

export default PostOrSchedule;
