import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import InputBase from '@mui/material/InputBase';
import Avatar from '@mui/material/Avatar';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { publicIpv4 } from 'public-ip';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import { getParamsId } from 'src/app/services/userService';
import { connectChatSocket } from 'src/socket';
import quickHub from 'src/app/main/query/quickHub';
import { formatDateWithTimeZone, handleApiRequest } from 'src/app/main/common/common';

function ChatBotAssistant() {
  const chatSocket = connectChatSocket();
  const chatContainerRef = useRef(null);
  const chatScroll = useRef(null);
  const [timeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [messageText, setMessageText] = useState('');
  const [messages, setMessages] = useState([]);
  const [isConnected, setIsConnected] = useState(chatSocket.connected); // chatSocket.connected
  const currentTime = moment().tz(timeZone).toISOString();
  const [ipAddress, setIpAddress] = useState(null);
  const [cachedIp, setCachedIp] = useState(null);
  const [subscribeChannelByClientUserStatus, setSubscribeChannelByClientUserStatus] =
    useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [prevPage, setPrevPage] = useState(0);
  const [wasLastList, setWasLastList] = useState(false);
  const id = Number(getParamsId());

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const ip = await publicIpv4();
        setIpAddress(ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchIp();
  }, []);

  useEffect(() => {
    const fetchDefaultMessages = () => {
      chatSocket.emit(
        'eventFromAssistanceClient',
        {
          eventType: 'subscribeBusinessOwnerForAssistance',
          data: { userBusinessId: id },
        },
        (response) => {
          if (response && response?.success) {
            setSubscribeChannelByClientUserStatus(true);
          } else {
            console.error('Subscription failed:', response);
          }
        }
      );
    };
    fetchDefaultMessages();
  }, [chatSocket, id]);

  const initialSetData = useCallback(
    (data) => {
      if (data?.length === 0) {
        setTimeout(() => {
          chatSocket.emit(
            'eventFromAssistanceClient',
            {
              eventType: 'beginAssistanceCommunication',
              data: {
                userBusinessId: id,
                message: 'I’m a Quick Hub Assistant, what do you want to know?',
              },
            },
            (response) => {
              if (response && response?.success) {
                setSubscribeChannelByClientUserStatus(true);
              } else {
                console.error('Subscription failed:', response);
              }
            }
          );
          setMessages([
            {
              message: `I’m a Quick Hub Assistant, what do you want to know?`,
              messageBy: 'BOT',
              time: moment().tz(timeZone).toISOString(),
            },
          ]);
        }, 2000);
      } else {
        setMessages(data);
      }
    },
    [chatSocket, id, timeZone]
  );

  useEffect(() => {
    const fetchIp = async () => {
      const ip = await ipAddress;
      setCachedIp(ip);
    };

    if (!cachedIp) {
      fetchIp();
    }
  }, [cachedIp, ipAddress]);

  const fetchAgentSupportStatus = useCallback(async () => {
    const payloadData = {
      query: quickHub?.getOwnerAssistanceConversation,
      variables: {
        userBusinessId: id,
        pageNumber: currPage,
        pageSize: 20,
        sortBy: 'cts',
        sortOrder: 'desc',
      },
    };
    const resultData = await handleApiRequest(payloadData);

    if (resultData?.getOwnerAssistanceConversation?.status === 200) {
      setHasMore(true);
      if (resultData?.getOwnerAssistanceConversation?.data?.length === 0) {
        setWasLastList(true);
        setHasMore(false);
        if (currPage === 1) {
          initialSetData([]);
        }
        return;
      }
      setPrevPage(currPage);
      setMessages([
        ...(resultData?.getOwnerAssistanceConversation?.data.reverse() || []),
        ...messages,
      ]);
      if (currPage === 1) {
        initialSetData(resultData?.getOwnerAssistanceConversation?.data);
      }

      const previousScrollHeight = chatContainerRef?.current?.scrollHeight;
      setTimeout(() => {
        const newScrollHeight = chatContainerRef?.current?.scrollHeight;
        chatContainerRef.current.scrollTop += newScrollHeight - previousScrollHeight;
      }, 0);
    } else if (resultData?.getOwnerAssistanceConversation?.data?.length === 0) {
      setWasLastList(true);
      setHasMore(false);
      if (currPage === 1) {
        initialSetData([]);
      }
    }
  }, [id, currPage, messages, initialSetData]);

  useEffect(() => {
    if (!wasLastList && prevPage !== currPage) {
      fetchAgentSupportStatus();
    }
  }, [currPage, wasLastList, prevPage, fetchAgentSupportStatus, hasMore]);

  useEffect(() => {
    const handleSubscribeChatData = async () => {
      try {
        chatSocket.emit(
          'eventFromAssistanceClient',
          {
            eventType: 'subscribeBusinessOwnerForAssistance',
            data: { userBusinessId: id },
          },
          (response) => {
            if (response && response?.success) {
              setSubscribeChannelByClientUserStatus(true);
            } else {
              console.error('Subscription failed:', response);
            }
          }
        );
      } catch (error) {
        console.error('Error fetching IP address or connecting to chat:', error);
        setIsConnected(false);
      }
    };

    if (!subscribeChannelByClientUserStatus) {
      handleSubscribeChatData();
      console.log('Executing subscribeChannelByClientUser with valid conditions');
    }
  }, [chatSocket, id, ipAddress, isConnected, subscribeChannelByClientUserStatus]);

  useEffect(() => {
    const handleMessage = async (message) => {
      if (message?.data?.answer || message?.data?.message) {
        const status = 'delivered';
        const newMessage = {
          message: message?.data?.answer || message?.data?.message,
          messageBy: 'BOT',
          cts: currentTime,
          status,
        };

        setMessages((prevMessages) => [...prevMessages, newMessage]);
        const payload = JSON.stringify({
          quickChatRoomMessagesId: message?.data?.quickChatRoomMessagesId,
          status,
        });
        chatSocket.emit('messageStatusFromClient', payload);

        const previousScrollHeight = chatContainerRef.current?.scrollHeight;
        setTimeout(() => {
          const newScrollHeight = chatContainerRef.current?.scrollHeight;
          chatContainerRef.current.scrollTop += newScrollHeight - previousScrollHeight;
        }, 0);
      }
    };

    const handleConnect = async () => {
      try {
        setSubscribeChannelByClientUserStatus(false);
        setIsConnected(true);
      } catch (error) {
        console.error('Error connecting to chat:', error);
        setIsConnected(false);
      }
    };

    const handleDisconnect = () => {
      setIsConnected(false);
    };

    // Attach event listeners
    chatSocket.on('connect', handleConnect);
    chatSocket.on('disconnect', handleDisconnect);
    chatSocket.on('eventFromAssistanceClient', handleMessage);

    // Cleanup function to remove the event listeners when component unmounts or re-renders
    return () => {
      chatSocket.off('connect', handleConnect);
      chatSocket.off('disconnect', handleDisconnect);
      chatSocket.off('eventFromAssistanceClient', handleMessage);
    };
  }, [chatSocket, currentTime, messages, subscribeChannelByClientUserStatus]);

  const sendMessage = async (e) => {
    const message = messageText || e;

    if (message?.trim()) {
      chatSocket.emit(
        'eventFromAssistanceClient',
        {
          eventType: 'businessOwnersReply',
          data: { userBusinessId: id, message },
        },
        (response) => {
          if (response && response?.success) {
            setSubscribeChannelByClientUserStatus(true);
          } else {
            console.error('Subscription failed:', response);
          }
        }
      );
      const newMessages = [
        ...messages,
        { message, messageType: 'delivered', messageBy: 'OWNER', cts: currentTime },
      ];
      setMessages(newMessages);
      setMessageText('');

      setTimeout(() => {
        if (chatContainerRef?.current) {
          chatContainerRef?.current?.scrollTo({
            top: chatContainerRef?.current?.scrollHeight,
            behavior: 'smooth',
          });
        }
      }, 0);
    }
  };

  const onMessageSubmit = async (e) => {
    e.preventDefault();
    sendMessage();
  };

  const groupMessagesByDate = (messagesData) => {
    return messagesData.reduce((groups, message) => {
      const date = formatDateWithTimeZone(message?.time || message?.cts, 'YYYY-MM-DD');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});
  };

  const onScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = chatContainerRef.current;
      if (scrollTop === 0) {
        setHasMore(false);
        setCurrPage((prevPageNumber) => prevPageNumber + 1);
      }
    }
  };
  return (
    <>
      <AppBar className="shadow sticky md:mb-48 bg-blue-gray-800">
        <Toolbar className="px-4">
          <div className="flex flex-1 items-center px-12">
            <div className="bg-white w-48 h-48 flex justify-center items-center relative rounded-full overflow-hidden">
              <img
                className="w-full h-full object-cover p-8"
                name="logo"
                src="/assets/images/logo/icon.png"
                alt="bell notification icon"
              />
            </div>
            <span className="w-10 h-10 bg-green inline-block rounded-full -ml-8 z-10 mt-20" />
            <Typography className="mx-16 text-16" color="inherit">
              {t('quickChat.quickHubAssistant')}
            </Typography>
          </div>

          <div className="flex px-4">
            <IconButton
              onClick={() => {
                window.open('about:blank', '_self');
                window.close();
              }}
              color="inherit"
              size="large"
            >
              <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <div className="client-chat-height px-12 md:pb-76">
        <div
          className="flex-grow overflow-auto p-20 pb-10"
          onScroll={onScroll}
          ref={chatContainerRef}
        >
          {Object.entries(groupMessagesByDate(messages)).map(([date, messagesValue], index) => {
            return (
              <div key={index}>
                <div className="text-center">
                  <div className="text-center text-grey-600 text-12 font-medium mb-12 bg-white inline-block mx-auto rounded-md shadow px-10 py-4">
                    {moment(date !== 'null' ? date : new Date().toISOString())
                      .tz(timeZone)
                      .calendar(null, {
                        sameDay: '[Today]',
                        lastDay: '[Yesterday]',
                        lastWeek: 'dddd',
                        sameElse: 'MMM DD, YYYY',
                      })}
                  </div>
                </div>
                {messagesValue?.map((msg, i) => (
                  <div
                    key={i}
                    className={`block max-w-[1050px] relative mx-auto ${
                      msg?.messageBy === 'BOT' ? 'text-left' : `text-right`
                    }`}
                  >
                    {msg?.messageBy === 'BOT' && (
                      <div className="float-left md:mr-28 mr-12 bg-darkgreen-100 w-48 h-48 flex justify-center items-center relative rounded-full overflow-hidden">
                        <img
                          className="w-full h-full object-cover p-8"
                          name="logo"
                          src="/assets/images/logo/icon.png"
                          alt="bell notification icon"
                        />
                      </div>
                    )}
                    <div>
                      <div className="sm:max-w-480 max-w-256 inline-block">
                        <div
                          key={index}
                          className={`transition ease-in-out delay-150 md:p-16 p-10 my-5 mx-auto rounded-2xl message md:text-16 font-semibold ${
                            msg?.messageBy === 'BOT'
                              ? 'bg-darkgreen-100 text-left rounded-tl-none'
                              : `bg-blue-gray-800 text-right text-white rounded-tr-none receiver-color`
                          } `}
                          dangerouslySetInnerHTML={{
                            __html: msg?.message,
                          }}
                        />
                        <Typography
                          className="time w-full text-12 font-medium text-grey-700 mt-8 whitespace-nowrap"
                          color="text.secondary"
                        >
                          {formatDateWithTimeZone(msg?.cts, 'hh:mm A')}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
        <div ref={chatScroll} className="h-20" />
      </div>

      <form
        onSubmit={onMessageSubmit}
        className="pb-16 px-8 bg-transparent bottom-0 fixed left-0 right-0 mx-auto max-w-[1050px] pt-24"
      >
        <Paper className="rounded-16 flex items-center relative shadow-none border-1 border-solid border-grey-500">
          <InputBase
            autoFocus={false}
            id="message-input"
            size="small"
            className="flex flex-1 grow shrink-0 px-16"
            placeholder="Ask Me anything"
            onChange={(e) => {
              setMessageText(e.target.value);
            }}
            onFocus={() => {
              const payload = JSON.stringify({
                quickChatRoomMessagesId: messages[messages.length - 1]?.id,
                quickChatRoomId: messages[messages.length - 1]?.quickChatRoomId,
                status: 'seen',
              });
              chatSocket.emit('messageStatusFromClient', payload);
            }}
            value={messageText}
          />
          <IconButton className="absolute right-10 top-3" type="submit" size="small">
            <FuseSvgIcon className="rotate-90" color="action">
              heroicons-outline:paper-airplane
            </FuseSvgIcon>
          </IconButton>
        </Paper>
      </form>
    </>
  );
}

export default ChatBotAssistant;
