import { Button, Typography } from '@mui/material';
import { selectDashboardData } from 'app/store/userSlice';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import history from '@history';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './Dashboards.css';
import AddIcon from '@mui/icons-material/Add';
import manageBusinessData from 'src/app/main/query/manageBusiness';
import {
  getParamsId,
  capitalizeFirstLetter,
  reviewFormate,
  handleApiRequest,
} from '../../../common/common';

const BusinessPlateForm = () => {
  const [platformData, setPlatformData] = useState([]);
  const [amazonTotal, setAmazonTotal] = useState();
  const [flipkartTotal, setFlipkartTotal] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      setPlatformData(userCurrentBusinessDetails?.reviewplatform);
    }
  }, [userCurrentBusinessDetails?.id, userCurrentBusinessDetails?.reviewplatform]);

  const physicalPlateFormName = ['google', 'zomato', 'yelp', 'trustpilot'];

  const zomatoData = userCurrentBusinessDetails?.reviewplatform?.find(
    (item) => item?.reviewPlatformId === 3
  );

  const zomatoReviewData = [
    {
      type: 'Delivery',
      title: 'Zomato Delivery',
      totalReview: zomatoData?.totalReview,
      totalRatingStars: zomatoData?.totalRatingStars,
      totalReviewText: 'Reviews',
      ratingStars: zomatoData?.totalRatingStars,
    },
    {
      type: 'Dining',
      title: 'Zomato Dine Out',
      totalReview: zomatoData?.totalDinningReview,
      totalRatingStars: zomatoData?.totalDinningRatingStars,
      totalReviewText: 'Reviews',
      ratingStars: zomatoData?.totalDinningRatingStars,
    },
  ];
  const newSettings = {
    dots: true,
    infinite: zomatoReviewData?.filter((review) => review?.totalReview !== 0)?.length > 1,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchAmazonData = async () => {
      if (userCurrentBusinessDetails?.id) {
        try {
          const payload = {
            query: manageBusinessData.getAmazonProduct,
            variables: {
              userBusinessId: Number(userCurrentBusinessDetails?.id),
            },
          };
          const result = await handleApiRequest(payload);
          if (result?.getAmazonProduct?.status === 200) {
            setAmazonTotal(result?.getAmazonProduct?.totalCount);
          }
        } catch (error) {
          console.error('Error fetching Amazon products:', error);
        }
      }
    };
    fetchAmazonData();
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    const fetchFlipkartData = async () => {
      if (userCurrentBusinessDetails?.id) {
        try {
          const payload = {
            query: manageBusinessData.getFlipkartProduct,
            variables: {
              userBusinessId: Number(userCurrentBusinessDetails?.id),
            },
          };
          const result = await handleApiRequest(payload);
          if (result?.getFlipkartProduct?.status === 200) {
            setFlipkartTotal(result?.getFlipkartProduct?.totalCount);
          }
        } catch (error) {
          console.error('Error fetching Amazon products:', error);
        }
      }
    };
    fetchFlipkartData();
  }, [userCurrentBusinessDetails?.id]);

  const redirect = (type, platformsId, zomatoType) => {
    const id = getParamsId();
    const selectedData = platformData.filter((data) => data?.reviewPlatformId === platformsId);
    const ratingTypeForRedirect = zomatoType === 'Delivery' ? 'delivery' : 'dining';
    const placeIdForRedirect = selectedData[0]?.placeId;
    if (type === 'reviews') {
      if (id) {
        history.push(`quick-reviews/reviews/${id}`, {
          placeIdForRedirect,
          ratingTypeForRedirect,
          platformsId,
        });
      } else {
        history.push(`quick-reviews/reviews/`, {
          placeIdForRedirect,
          ratingTypeForRedirect,
          platformsId,
        });
      }
    } else if (type === 'inviteReviews') {
      if (id) {
        history.push(`/invite-reviews/${id}`);
      } else {
        history.push('/invite-reviews/');
      }
    } else if (type === 'addProducts') {
      history.push(`quick-reviews/product/${id}`, { platformsId, openDialogue: true });
    } else {
      history.push(`quick-reviews/product/`, { platformsId, openDialogue: true });
    }
  };

  const handleAddPlatForm = () => {
    const allPlatformsPresent = physicalPlateFormName.every((platformName) =>
      userCurrentBusinessDetails?.reviewplatform.some(
        (item) => item?.name.toLowerCase() === platformName
      )
    );
    history.push('/editBusinessDetails', { businessId: userCurrentBusinessDetails?.id });
  };

  return (
    <div className="bg-white rounded-md relative shadow p-10 sm:p-16 w-full">
      <div className="flex justify-between items-center">
        <Typography className="font-bold text-16 md:text-20">
          {t('businessDashboard.businessPlateForm')}
        </Typography>
        {!userCurrentBusinessDetails?.isOnlineBusiness &&
          !physicalPlateFormName.every((platformName) =>
            userCurrentBusinessDetails?.reviewplatform.some(
              (item) => item?.name?.toLowerCase() === platformName
            )
          ) && (
            <Button
              className="text-quick-review font-semibold text-14 md:text-16 hover:bg-white p-0"
              onClick={() => handleAddPlatForm()}
            >
              <AddIcon /> {t('businessDashboard.AddPlateForm')}
            </Button>
          )}
      </div>
      {userCurrentBusinessDetails?.reviewplatform?.length > 0 &&
        userCurrentBusinessDetails?.reviewplatform?.map((item, index) => {
          const isOnlineBusiness = userCurrentBusinessDetails?.isOnlineBusiness;
          return Number(item?.reviewPlatformId) === 3 ? (
            <Slider {...newSettings} className="zomatoSlider">
              {zomatoReviewData
                ?.filter((review) => review?.totalReview !== 0)
                ?.map((review, i) => (
                  <div key={i} className="w-full mt-16 p-12 rounded-lg min-h-120 bg-orange-A300">
                    <div className="flex justify-between items-center">
                      <div className="flex gap-16 items-center">
                        <div className="rounded">
                          <img className="w-60 m-auto" src={zomatoData?.logo} alt="" />
                        </div>
                        <div>
                          <Typography className="text-16 font-semibold">{review?.title}</Typography>
                          <Typography className="text-14">
                            Based on {review?.totalReview} {review?.totalReviewText}
                          </Typography>
                          <Typography className="pr-8">
                            {reviewFormate(review?.ratingStars)}{' '}
                            <span className="font-semibold">{review?.ratingStars} / 5</span>
                          </Typography>
                        </div>
                      </div>
                      <div>
                        <Button
                          className="bg-white hover:bg-white rounded max-w-40 min-w-40 w-40"
                          onClick={() => redirect('reviews', item?.reviewPlatformId, review?.type)}
                        >
                          <ChevronRightIcon className="text-quick-review" />
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          ) : (
            <div
              className={`flex items-center justify-between mt-16 p-12 rounded-lg min-h-112 ${
                (parseInt(item?.reviewPlatformId, 10) === 2 ||
                  parseInt(item?.reviewPlatformId, 10) === 5) &&
                'bg-blue-A300'
              } ${
                (parseInt(item?.reviewPlatformId, 10) === 3 ||
                  parseInt(item?.reviewPlatformId, 10) === 7) &&
                'bg-orange-A300'
              } ${
                (parseInt(item?.reviewPlatformId, 10) === 4 ||
                  parseInt(item?.reviewPlatformId, 10) === 6) &&
                'bg-purple-A300'
              }`}
              key={index}
            >
              <div className="flex gap-16 items-center">
                <div className={`${item?.reviewPlatformId === 8 ? ' ' : 'bg-white p-12'} rounded`}>
                  <img
                    className={`${item?.reviewPlatformId === 8 ? 'w-60' : 'w-40'} m-auto`}
                    src={item?.logo}
                    alt=""
                  />
                </div>
                <div>
                  <Typography className="text-16 font-semibold">
                    {item?.name && capitalizeFirstLetter(item?.name)}
                  </Typography>
                  <div>
                    <Typography className="text-14">
                      {t('reviews.reviewCount')}
                      {item?.totalReview}
                      {t('reviews.title')}
                    </Typography>
                    {item && (
                      <span className="block">
                        <Typography className="p8-8 flex justify-center items-center text-11">
                          {reviewFormate(item?.totalRatingStars)}
                          <span className="font-semibold ml-10">
                            {(Number(item?.reviewPlatformId), item?.totalRatingStars)} / 5
                          </span>
                        </Typography>
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div>
                {!isOnlineBusiness ||
                (Number(item?.reviewPlatformId) === 6 && flipkartTotal > 0) ||
                (Number(item?.reviewPlatformId) === 5 && amazonTotal > 0) ||
                (Number(item?.reviewPlatformId) === 7 && (amazonTotal > 0 || flipkartTotal > 0)) ? (
                  <Button
                    className="bg-white hover:bg-white rounded shadow-md max-w-40 min-w-40 w-40"
                    onClick={() => redirect('reviews', item?.reviewPlatformId)}
                  >
                    <ChevronRightIcon className="text-quick-review" />
                  </Button>
                ) : (
                  <>
                    {parseInt(item?.reviewPlatformId, 10) !== 7 && (
                      <Button
                        variant="contained"
                        className="rounded-md px-20 mx-auto"
                        color="secondary"
                        size="small"
                        type="button"
                        onClick={() =>
                          redirect(
                            parseInt(item?.reviewPlatformId, 10) === 7
                              ? 'inviteReviews'
                              : 'addProducts',
                            item?.reviewPlatformId
                          )
                        }
                      >
                        {t('businessDashboard.addProducts')}
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default BusinessPlateForm;
