import themesConfig from 'app/configs/themesConfig';
import ManageLabels from './manageLabels/ManageLabels';
import Reports from './manageGroups/reports/Reports';
import Businesses from './manageGroups/businesses/Businesses';
import Posts from './manageGroups/posts/Posts';
import CreateNewPost from './manageGroups/posts/CreateNewPost';
import PrimaryDetails from './manageGroups/primaryDetails.js/PrimaryDetails';
import AddPhotoAndVideo from './manageGroups/primaryDetails.js/addImageAndPhoto/AddPhotoAndVideo';
import Menu from './manageGroups/primaryDetails.js/menu/Menu';
import OpeningAndClosingHours from './manageGroups/primaryDetails.js/openingAndClosingHours/OpeningAndClosingHours';
import Services from './manageGroups/primaryDetails.js/services/Services';
import Attributes from './manageGroups/primaryDetails.js/attributes/Attributes';
import OverallReports from './OverallRports';
import Documents from './manageGroups/documents/Documents';

const QuickHubConfig = {
  settings: {
    theme: {
      main: themesConfig.legacy,
    },
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'quick-hub/businesses',
      element: <Businesses />,
    },
    {
      path: 'quick-hub/primaryDetails',
      element: <PrimaryDetails />,
    },
    {
      path: 'quick-hub/addImagesAndVideos',
      element: <AddPhotoAndVideo />,
    },
    {
      path: 'quick-hub/menu',
      element: <Menu />,
    },
    {
      path: 'quick-hub/openingAndClosingHour',
      element: <OpeningAndClosingHours />,
    },
    {
      path: 'quick-hub/services',
      element: <Services />,
    },
    {
      path: 'quick-hub/attributes',
      element: <Attributes />,
    },
    {
      path: 'quick-hub/posts',
      element: <Posts />,
    },
    {
      path: 'quick-hub/createNewPost',
      element: <CreateNewPost />,
    },
    {
      path: 'quick-hub/manageLabels',
      element: <ManageLabels />,
    },
    {
      path: 'quick-hub/reports',
      element: <Reports />,
    },
    {
      path: 'overall-reports/:id?',
      element: <OverallReports />,
    },
    {
      path: 'quick-hub/documents',
      element: <Documents />,
    },
    {
      path: 'quick-hub/documents/:id?',
      element: <Documents />,
    },
  ],
};

export default QuickHubConfig;
