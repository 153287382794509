import { Button, CircularProgress, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { t } from 'i18next';
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';
import userService from 'src/app/services';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import wabaQuery from '../../../../../query/waba';
import { handleWabaApiRequest } from '../../../../../common/common';

const TestCampaign = (props) => {
  const { setStepsCount, template, imageUrl, campaignName, selectedVariables } = props;
  const loginUserData = userService.getUserData();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [userName, setUserName] = useState('');
  const [phoneData, setPhoneData] = useState({
    countryCode: '',
    phoneNumber: '',
  });
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const handlePhoneChange = (event, data) => {
    setPhoneData({
      countryCode: data?.dialCode,
      phoneNumber: event,
    });
  };

  const handleCreateBroadCast = async () => {
    setRefreshIcon(true);
    const result = [
      {
        customerName: userName,
        phoneNumber: phoneData?.phoneNumber,
      },
    ];
    try {
      const payload = {
        query: wabaQuery.testBroadcast,
        variables: {
          contact: result,
          isScheduled: false,
          name: campaignName,
          templateId: Number(template?.id),
          userBusinessId: Number(userCurrentBusinessDetails?.id),
          dynamicVariables: JSON.stringify({
            body: selectedVariables?.map((item) => ({
              ...item,
              description: item.description.replace(/\[|\]/g, ''),
            })),
            header: template?.dynamicData?.header?.map((item) => ({ ...item, value: imageUrl })),
            footer: template?.templateData?.components?.find((item) => item?.type === 'FOOTER')
              ?.text,
            buttons: template?.templateData?.components?.find((item) => item?.type === 'BUTTONS')
              ?.buttons,
          }),
        },
      };
      const res = await handleWabaApiRequest(payload);
      setRefreshIcon(false);
      if (res.testBroadcast?.status === 200) {
        enqueueSnackbar(res?.testBroadcast?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar(res?.testBroadcast?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  return (
    <div className="mt-16">
      <div className="flex gap-8 items-center">
        <ArrowBackIcon onClick={() => setStepsCount(2)} />
        <Typography className="font-semibold text-20">
          {t('waba.broadcast.myBroadcast.testCampaign')}
        </Typography>
      </div>
      <div className="p-14 sm:p-24 bg-white w-[700px] rounded-md mt-16 quick-chat">
        <Typography className="text-18 font-medium">
          {t('waba.broadcast.myBroadcast.templateDetails.userName.name')}
        </Typography>
        <Typography className="text-grey-A300 font-semibold py-10">
          {t('waba.broadcast.myBroadcast.userNameMessage')}
        </Typography>
        <TextField
          className="w-full"
          placeholder={t('waba.broadcast.myBroadcast.templateDetails.userName.placeholder')}
          size="medium"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          InputProps={{
            style: {
              fontSize: '16px',
              fontWeight: 500,
            },
          }}
        />
        <Typography className="text-18 font-medium mt-16 pb-10">
          {t('waba.broadcast.myBroadcast.templateDetails.contactNumber.name')}
        </Typography>
        <PhoneInput
          placeholder={t('manageBusiness.addBusinessSteps.addBusiness.form.phone.placeHolder')}
          inputStyle={{
            width: '100%',
            height: '50px',
          }}
          className="w-full"
          name="phone_number"
          required
          // value={phoneValue}
          size="small"
          country={loginUserData?.isoCode?.toLowerCase()}
          enableSearch="true"
          onChange={(value, data) => handlePhoneChange(value, data)}
        />
        <div className="flex gap-8 flex-wrap justify-end mt-12">
          <Button
            className="rounded-md w-160 font-semibold"
            variant="outlined"
            color="quickChat"
            onClick={() => setStepsCount(4)}
          >
            Skip
          </Button>
          <Button
            className={`rounded-md w-196 font-semibold ${
              refreshIcon
                ? 'disabled:bg-gray disabled: text-black'
                : 'disabled:opacity-40 disabled:bg-quick-chat disabled:text-white'
            }`}
            variant="contained"
            color="quickChat"
            disabled={
              !userName ||
              !phoneData?.phoneNumber?.slice(phoneData?.countryCode?.length) ||
              refreshIcon
            }
            onClick={() => handleCreateBroadCast()}
          >
            Send Test Campaign
            {refreshIcon && (
              <CircularProgress size={24} className="absolute mx-auto text-quick-chat" />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TestCampaign;
