import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import history from '@history';
import FuseLoading from '@fuse/core/FuseLoading';
import ConformationDialogBox from 'src/app/main/CommonComponent/ConformationDialogBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation, useParams } from 'react-router-dom';
import manageBusinessData from '../../../query/manageBusiness';
import common from '../../../query/common';
import { handleApiRequest } from '../../../common/common';
import { selectDashboardData } from '../../../../store/userSlice';

const CustomAttributes = () => {
  const params = useParams();
  const [businessAttribute, setBusinessAttribute] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [businessCustomAttribute, setBusinessCustomAttribute] = useState([]);
  const [ratingFormateValue, selectRatingFormateValue] = useState(0);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [edit, setEdit] = useState(false);
  const [attributeValidation, setAttributeValidation] = useState(0);
  const [editData, setEditData] = useState();
  const [ratingFormateData, setRatingFormateData] = useState([]);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [openDialogueForDelete, setOpenDialogueForDelete] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [bAttribute, setBAttribute] = useState('');
  const [googleAttribute, setGoogleAttribute] = useState([]);
  const [allAttribute, setAllAttribute] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [isDeleting, setIsDeleting] = useState(false);
  const [userCustomBusinessAttributeId, setUserBusinessAttributeId] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [selectedAttributeId, setSelectedAttributeId] = useState(null);
  const location = useLocation();
  const { plateFormID, productDetailsData } = location.state || {};
  const productId = parseInt(params?.productId, 10);

  const fetchBusinessAttributeData = useCallback(
    async (event = true) => {
      if (userCurrentBusinessDetails?.id) {
        try {
          setPageLoading(event);

          if (productId) {
            const variables = {
              businessTypeId: parseInt(productDetailsData[0]?.businessTypeId, 10),
            };

            let query = null;
            let res = null;

            if (parseInt(plateFormID, 10) === 5) {
              query = manageBusinessData.getAmazonProductAttributesOnId;
              variables.amazonProductId = productId;
            } else if (parseInt(plateFormID, 10) === 6) {
              query = manageBusinessData.getFlipkartProductAttributesOnId;
              variables.flipkartProductId = productId;
            }

            if (query) {
              const result = await handleApiRequest({ query, variables });
              res =
                parseInt(plateFormID, 10) === 5
                  ? result?.getAmazonProductAttributesOnId
                  : result?.getFlipkartProductAttributesOnId;

              setPageLoading(false);

              if (res?.status === 200) {
                setBusinessAttribute(res?.data);
                setAllAttribute((prevAttributes) => [...prevAttributes, ...res.data]);
              }
              return res;
            }
          } else {
            const query = manageBusinessData.getBusinessAttributesOnId;
            const variables = {
              businessId: parseInt(userCurrentBusinessDetails?.id, 10),
              businessTypeId: parseInt(userCurrentBusinessDetails?.businessTypeId, 10),
            };

            const result = await handleApiRequest({ query, variables });
            setPageLoading(false);

            if (result?.getBusinessAttributesOnId?.status === 200) {
              setBusinessAttribute(result?.getBusinessAttributesOnId?.data);
              if (Array.isArray(result?.getBusinessAttributesOnId?.data)) {
                setAllAttribute((prevAttributes) => [
                  ...prevAttributes,
                  ...result.getBusinessAttributesOnId.data,
                ]);
              }
            } else {
              enqueueSnackbar(result?.getBusinessAttributesOnId?.message, {
                variant: 'error',
                autoHideDuration: 3000,
              });
            }
            return result;
          }
        } catch (error) {
          console.error('error', error);
        }
      }
      return null;
    },
    [
      userCurrentBusinessDetails?.id,
      userCurrentBusinessDetails?.businessTypeId,
      productId,
      plateFormID,
      productDetailsData,
      enqueueSnackbar,
    ]
  );

  useEffect(() => {
    fetchBusinessAttributeData();
  }, [fetchBusinessAttributeData]);

  useEffect(() => {
    setPageLoading(true);
    const fetchData = async () => {
      const payload = {
        query: manageBusinessData.getBusinessAttributeValue,
      };
      const result = await handleApiRequest(payload);
      setPageLoading(false);
      if (result?.getBusinessAttributeValue?.status === 200) {
        setRatingFormateData(result?.getBusinessAttributeValue?.data);
      } else {
        enqueueSnackbar(result?.getBusinessAttributeValue?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    };
    fetchData();
  }, [enqueueSnackbar]);

  const fetchBusinessCustomAttribute = useCallback(
    async (event = true) => {
      if (userCurrentBusinessDetails?.id) {
        try {
          setPageLoading(event);

          if (productId) {
            let variables = {};
            let query = null;
            let res = null;
            if (parseInt(plateFormID, 10) === 5) {
              query = manageBusinessData.getAmazonCustomAttributes;
              variables.amazonProductId = productId;
            } else if (parseInt(plateFormID, 10) === 6) {
              query = manageBusinessData.getFlipkartCustomAttributes;
              variables = {
                flipkartProductId: productId,
                userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
              };
            }
            if (query) {
              const result = await handleApiRequest({ query, variables });
              res =
                parseInt(plateFormID, 10) === 5
                  ? result?.getAmazonCustomAttributes
                  : result?.getFlipkartCustomAttributes;

              if (res?.status === 200) {
                setBusinessCustomAttribute(res?.data);
                const ownerAttributesData = Array.isArray(res?.data) ? res?.data : [];
                setAllAttribute((prevAttributes) => [...prevAttributes, ...ownerAttributesData]);
              } else {
                enqueueSnackbar(res?.message, {
                  variant: 'error',
                  autoHideDuration: 3000,
                });
              }
              return res;
            }
          } else {
            const query = manageBusinessData.getBusinessOwnerCustomAttributes;
            const variables = {
              businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            };
            const resultCustom = await handleApiRequest({ query, variables });
            setPageLoading(false);
            if (resultCustom?.getBusinessOwnerCustomAttributes?.status === 200) {
              setBusinessCustomAttribute(resultCustom?.getBusinessOwnerCustomAttributes?.data);
              setGoogleAttribute(
                resultCustom?.getBusinessOwnerCustomAttributes?.googleAttributesData
              );
              const googleAttributesData = Array.isArray(
                resultCustom?.getBusinessOwnerCustomAttributes?.googleAttributesData
              )
                ? resultCustom.getBusinessOwnerCustomAttributes.googleAttributesData
                : [];
              const ownerAttributesData = Array.isArray(
                resultCustom?.getBusinessOwnerCustomAttributes?.data
              )
                ? resultCustom.getBusinessOwnerCustomAttributes?.data
                : [];
              setAllAttribute((prevAttributes) => [
                ...prevAttributes,
                ...googleAttributesData,
                ...ownerAttributesData,
              ]);
            } else {
              enqueueSnackbar(resultCustom?.getBusinessOwnerCustomAttributes?.message, {
                variant: 'error',
                autoHideDuration: 3000,
              });
            }
            return resultCustom;
          }
        } catch (error) {
          console.error('error', error);
        }
      }
      return null;
    },
    [userCurrentBusinessDetails?.id, productId, plateFormID, enqueueSnackbar]
  );
  useEffect(() => {
    const compareArraysResult = compareArrays(
      [...businessAttribute, ...googleAttribute, ...businessCustomAttribute],
      allAttribute
    );
    const isDisabledValue =
      !userCurrentBusinessDetails?.id ||
      compareArraysResult ||
      [...businessAttribute, ...googleAttribute, ...businessCustomAttribute].filter(
        (obj) => obj.isActive === true
      ).length > 5;

    setIsDisabled(isDisabledValue);
  }, [
    userCurrentBusinessDetails,
    businessAttribute,
    googleAttribute,
    businessCustomAttribute,
    allAttribute,
  ]);

  useEffect(() => {
    fetchBusinessCustomAttribute();
  }, [fetchBusinessCustomAttribute]);

  const handleRemove = () => {
    selectRatingFormateValue(0);
    setAttributeValidation(0);
    setOpenDialogue(false);
    setEdit(false);
    setBAttribute('');
    setIsEmpty(false);
    setEditData();
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setAttributeValidation(value?.length);
    if (value.startsWith(' ')) {
      return;
    }
    if (value?.length < 21) {
      setBAttribute(value);
      setIsEmpty(value?.trim() === '');
    }
  };

  const handlePaste = (e) => {
    const pastedText = e?.clipboardData?.getData('text') || '';
    const currentValue = bAttribute || '';
    const remainingSpace = 20 - currentValue.length;
    if (remainingSpace > 0) {
      const truncatedText = pastedText.slice(0, remainingSpace);
      const updatedValue = currentValue + truncatedText;
      setAttributeValidation(pastedText?.length);
      setBAttribute(updatedValue);
      setIsEmpty(updatedValue.trim() === '');
    }
    e.preventDefault();
  };

  const editBusinessAttribute = (value) => {
    setOpenDialogue(true);
    setEdit(true);
    setBAttribute(value?.bAttribute);
    selectRatingFormateValue(value?.businessAttributeValueId);
    setUserBusinessAttributeId(value?.id);
    setEditData(value);
  };

  const handleOpenDeleteDialog = async (id) => {
    const resData = await fetchBusinessCustomAttribute(false);
    const resBusinessData = await fetchBusinessAttributeData(false);
    if (
      resData?.data?.length === 1 && productId
        ? !resBusinessData?.data.some((attr) => attr.isActive === true)
        : !resBusinessData?.getBusinessAttributesOnId?.data.some(
            (attr) =>
              attr.isActive === true || googleAttribute?.some((attrs) => attrs?.isActive === true)
          )
    ) {
      enqueueSnackbar(
        t(
          'manageBusiness.addBusinessSteps.customAttribute.dialog.form.attributeName.notDeleteCustomAttribute'
        ),
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      );
    } else {
      setSelectedAttributeId(id);
      setOpenDialogueForDelete(true);
    }
  };

  const handleCloseDeleteDialog = () => {
    setOpenDialogueForDelete(false);
    setSelectedAttributeId(null);
  };

  const enableDisableCustomAttribute = (value) => {
    const data = businessCustomAttribute.map((item) => {
      if (item.id === value.id) {
        return {
          ...item,
          isActive: !value.isActive,
        };
      }
      return item;
    });
    setBusinessCustomAttribute(data);
  };

  const enableDisableGoogleAttribute = (value) => {
    const data = googleAttribute.map((item) => {
      if (item.id === value.id) {
        return {
          ...item,
          isActive: !value.isActive,
        };
      }
      return item;
    });
    setGoogleAttribute(data);
  };

  const onSubmit = async () => {
    setRefreshIcon(true);
    if (productId) {
      let payload;
      let queryKey;

      if (parseInt(plateFormID, 10) === 5) {
        payload = {
          query: edit
            ? manageBusinessData.editCustomAmazonAttribute
            : manageBusinessData.addCustomAmazonAttribute,
          variables: {
            bAttribute,
            businessAttributeValueId: parseInt(ratingFormateValue, 10),
            isActive: false,
            ...(edit && {
              amazonCustomBusinessAttributeId: parseInt(userCustomBusinessAttributeId, 10),
            }),
            amazonProductId: productId,
          },
        };
        queryKey = edit ? 'editCustomAmazonAttribute' : 'addCustomAmazonAttribute';
      } else if (parseInt(plateFormID, 10) === 6) {
        payload = {
          query: edit
            ? manageBusinessData.editCustomFlipKartAttribute
            : manageBusinessData.addCustomFlipKartAttribute,
          variables: {
            bAttribute,
            businessAttributeValueId: parseInt(ratingFormateValue, 10),
            isActive: false,
            ...(edit && {
              flipKartCustomBusinessAttributeId: parseInt(userCustomBusinessAttributeId, 10),
            }),
            flipKartProductId: productId,
          },
        };
        queryKey = edit ? 'editCustomFlipKartAttribute' : 'addCustomFlipKartAttribute';
      }

      if (payload) {
        const result = await handleApiRequest(payload);
        const res = result?.[queryKey];
        setRefreshIcon(false);

        if (res?.status === 200) {
          handleRemove();
          setAllAttribute([]);
          setUserBusinessAttributeId();
          fetchBusinessCustomAttribute();
        } else {
          enqueueSnackbar(res?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    } else {
      const payload = {
        query: edit
          ? manageBusinessData.editCustomBusinessAttribute
          : manageBusinessData.addCustomBusinessAttributeV1,
        variables: {
          bAttribute,
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          businessAttributeValueId: parseInt(ratingFormateValue, 10),
          isActive: false,
          ...(edit && {
            userCustomBusinessAttributeId: parseInt(userCustomBusinessAttributeId, 10),
          }),
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      const res = edit ? result?.editCustomBusinessAttribute : result?.addCustomBusinessAttributeV1;
      if (res?.status === 200) {
        handleRemove();
        setAllAttribute([]);
        setGoogleAttribute([]);
        setUserBusinessAttributeId();
        fetchBusinessCustomAttribute();
      } else {
        enqueueSnackbar(res?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  };

  const enableDisableDefaultAttribute = (value) => {
    const data = businessAttribute.map((item) => {
      if (item.id === value.id) {
        return {
          ...item,
          isActive: !value.isActive,
        };
      }
      return item;
    });
    setBusinessAttribute(data);
  };

  if (pageLoading) {
    return <FuseLoading />;
  }
  const save = async () => {
    setPageLoading(true);
    const customAttributeData = businessCustomAttribute?.map((ele) => {
      return {
        userCustomBusinessAttributeId: parseInt(ele.id, 10),
        isActive: ele.isActive,
      };
    });
    const googleAttributeData = googleAttribute?.map((ele) => {
      return {
        userCustomBusinessAttributeId: parseInt(ele.id, 10),
        isActive: ele.isActive,
      };
    });
    const defaultAttributeData = businessAttribute?.map((ele) => {
      return {
        ...(productId
          ? { defaultAttributeId: parseInt(ele.id, 10) }
          : { businessTypeId: parseInt(ele.id, 10) }),
        isActive: ele.isActive,
      };
    });
    try {
      if (productId) {
        let payload;
        let queryKey;

        if (parseInt(plateFormID, 10) === 5) {
          payload = {
            query: manageBusinessData.updateAmazonAttributeWithDefaultBusinessAttribute,
            variables: {
              customBusinessAttribute: customAttributeData,
              defaultAttribute: defaultAttributeData,
              amazonProductId: productId,
            },
          };
          queryKey = 'updateAmazonAttributeWithDefaultBusinessAttribute';
        } else if (parseInt(plateFormID, 10) === 6) {
          payload = {
            query: manageBusinessData.updateFlipkartAttributeWithDefaultBusinessAttribute,
            variables: {
              customBusinessAttribute: customAttributeData,
              defaultAttribute: defaultAttributeData,
              flipkartProductId: productId,
            },
          };
          queryKey = 'updateFlipkartAttributeWithDefaultBusinessAttribute';
        }

        if (payload) {
          const result = await handleApiRequest(payload);
          const res = result?.[queryKey];
          setPageLoading(false);

          if (res?.status === 200) {
            setAllAttribute([]);
            fetchBusinessCustomAttribute();
          } else {
            enqueueSnackbar(res?.message, {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
        }
      } else {
        const payload = {
          query: manageBusinessData.updateCustomBusinessAttributeWithDefaultBusinessAttribute,
          variables: {
            customBusinessAttribute: customAttributeData.concat(googleAttributeData),
            defaultAttribute: defaultAttributeData,
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        setPageLoading(false);
        if (result?.updateCustomBusinessAttributeWithDefaultBusinessAttribute?.status === 200) {
          setGoogleAttribute([]);
          setAllAttribute([]);
          fetchBusinessCustomAttribute();
        } else {
          enqueueSnackbar(
            result?.updateCustomBusinessAttributeWithDefaultBusinessAttribute?.message,
            {
              variant: 'error',
              autoHideDuration: 3000,
            }
          );
        }
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  function compareArrays(changesAttributeArray, defaultAttributeArray) {
    if (changesAttributeArray.length !== defaultAttributeArray.length) {
      setIsDisabled(false);
      return false;
    }
    for (let i = 0; i < changesAttributeArray.length; i += 1) {
      if (changesAttributeArray[i] !== defaultAttributeArray[i]) {
        setIsDisabled(false);
        return false;
      }
    }

    setIsDisabled(true);
    return true;
  }

  const isAllAttributesDisabled = () => {
    return (
      [...businessAttribute, ...googleAttribute, ...businessCustomAttribute].filter(
        (obj) => obj.isActive === true
      ).length === 0
    );
  };
  const handleDelete = async () => {
    if (selectedAttributeId && !isDeleting) {
      setIsDeleting(true);
      try {
        if (productId) {
          let payload;
          let queryKey;

          if (parseInt(plateFormID, 10) === 5) {
            payload = {
              query: manageBusinessData.deleteAmazonCustomAttribute,
              variables: {
                amazonCustomBusinessAttributeId: selectedAttributeId,
              },
            };
            queryKey = 'deleteAmazonCustomAttribute';
          } else if (parseInt(plateFormID, 10) === 6) {
            payload = {
              query: manageBusinessData.deleteFlipkartCustomAttribute,
              variables: {
                flipKartCustomBusinessAttributeId: selectedAttributeId,
              },
            };
            queryKey = 'deleteFlipkartCustomAttribute';
          }

          if (payload) {
            const result = await handleApiRequest(payload);
            const res = result?.[queryKey];

            if (res?.status === 200) {
              setBusinessCustomAttribute((prevAttributes) =>
                prevAttributes.filter((attr) => attr.id !== selectedAttributeId)
              );
              handleCloseDeleteDialog();
              fetchBusinessAttributeData();
            } else {
              enqueueSnackbar(res?.message, {
                variant: 'error',
                autoHideDuration: 3000,
              });
            }
          }
        } else {
          const payload = {
            query: common.deleteBusinessCustomAttribute,
            variables: {
              userCustomBusinessAttributeId: selectedAttributeId,
            },
          };
          const result = await handleApiRequest(payload);

          if (result?.deleteBusinessCustomAttribute?.status === 200) {
            enqueueSnackbar(result?.deleteBusinessCustomAttribute?.message, {
              variant: 'success',
              autoHideDuration: 3000,
            });
            setBusinessCustomAttribute((prevAttributes) =>
              prevAttributes.filter((attr) => attr.id !== selectedAttributeId)
            );
            handleCloseDeleteDialog();
            fetchBusinessAttributeData();
          } else {
            enqueueSnackbar(result?.deleteBusinessCustomAttribute?.message, {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
        }
      } catch (error) {
        console.error('Error deleting custom attribute:', error);
      } finally {
        setIsDeleting(false);
      }
    }
  };

  return (
    <div>
      {params?.productId && (
        <div className="">
          <Toolbar className="pt-32 font-semibold min-h-fit">
            <Icon className="text-20 cursor-pointer" onClick={() => history.go(-1)}>
              west
            </Icon>
            <Typography
              variant="subtitle1"
              color="inherit"
              className="flex-1 px-12 font-bold text-18"
            >
              {t('products.productSettings')}
            </Typography>
          </Toolbar>
        </div>
      )}
      <div className="lg:p-24 p-16">
        <div className="sm:flex w-full items-center justify-between lg:mb-20 mb-16">
          <div className="flex items-center">
            <Typography
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="sm:flex md:text-28 text-20 font-bold mb-16 sm:mb-0"
            >
              {t('customAttribute.title')}
            </Typography>
          </div>
          <div className="flex gap-20 flex-col xs:flex-row text-center mb-16 sm:mb-0">
            <Button
              className="bg-quick-review rounded sm:px-36 px-0 w-full sm:w-auto mb-1 sm:mb-0 text-white border disabled:bg-gray disabled:text-black disabled:border-gray border-solid border-quick-review hover:bg-quick-review "
              type="button"
              disabled={!userCurrentBusinessDetails?.id}
              onClick={() => setOpenDialogue(true)}
            >
              {t('customAttribute.button.addAttribute')}
            </Button>
            <Button
              className="bg-quick-review rounded px-0 sm:px-60 w-full sm:w-auto disabled:bg-gray disabled:text-black text-white hover:bg-quick-review"
              disabled={isDisabled || isAllAttributesDisabled()}
              onClick={() => save()}
            >
              {t('common.save')}
            </Button>
          </div>
        </div>
        {[...businessAttribute, ...businessCustomAttribute]?.filter((obj) => obj.isActive === true)
          .length > 5 && (
          <Typography className="bg-red-100 py-12 rounded-4 text-red-800 text-14 text-center font-medium lg:mb-20 mb-16">
            {t('manageBusiness.addBusinessSteps.customAttribute.customAttributeLimitExist')}
          </Typography>
        )}
        <div className="">
          {businessAttribute?.length > 0 && (
            <div className="bg-white px-20 py-16 rounded-md">
              <h4 className="text-20 font-semibold border-b-1 border-solid border-grey pb-14 mb-20">
                {t('manageBusiness.addBusinessSteps.customAttribute.defaultAttribute')}
              </h4>
              {businessAttribute?.map((item, index) => {
                return (
                  <div
                    className="my-14 items-center bg-grey-100 rounded-sm mx-auto md:py-2 px-10 py-6 h-44"
                    key={index}
                  >
                    <div className="flex items-center justify-between">
                      <span className="block md:text-16 text-14 font-medium">
                        {item?.bAttribute}
                      </span>
                      <Switch
                        checked={item?.isActive}
                        onChange={() => enableDisableDefaultAttribute(item)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {googleAttribute?.length > 0 && (
            <div className="bg-white px-20 py-16 mt-24 rounded-md">
              <h4 className="text-20 font-semibold border-b-1 border-solid border-grey pb-14 mb-20">
                {t('manageBusiness.addBusinessSteps.customAttribute.googleAttribute')}
              </h4>
              <div className="max-h-288 overflow-scroll">
                {googleAttribute?.map((item, index) => {
                  return (
                    <div
                      className="my-14 items-center bg-grey-100 rounded-sm mx-auto md:py-2 px-10 py-6 h-44"
                      key={index}
                    >
                      <div className="flex items-center justify-between">
                        <span className="block md:text-16 text-14 font-medium">
                          {item?.bAttribute}
                        </span>
                        <Switch
                          checked={item?.isActive}
                          onChange={() => enableDisableGoogleAttribute(item)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="bg-white  px-20 py-16 mt-24 rounded-md">
            <h4 className="text-20 font-semibold border-b-1 border-solid border-grey pb-14 mb-20">
              {t('customAttribute.title')}
            </h4>
            {businessCustomAttribute?.length > 0 ? (
              <div className="max-h-288 overflow-scroll">
                {businessCustomAttribute?.map((item, index) => {
                  return (
                    <div
                      className="my-14 bg-gray-100 rounded-md mx-auto flex items-center"
                      key={index}
                    >
                      <div className="flex flex-row mr-16 gap-7">
                        <Button
                          variant="contained"
                          color="inherit"
                          className="md:text-16 bg-white m-5 font-medium hover:bg-grey-300 rounded-sm px-0 min-h-36 w-36 min-w-36"
                          aria-label="edit"
                          onClick={() => editBusinessAttribute(item)}
                          type="button"
                        >
                          <Icon className="text-gray-600">edit</Icon>
                        </Button>
                        <Button
                          variant="contained"
                          color="inherit"
                          className="md:text-16 font-medium hover:bg-grey-300 rounded-sm px-0 bg-white m-5 min-h-36 w-36 min-w-36"
                          aria-label="delete"
                          onClick={() => handleOpenDeleteDialog(item.id)}
                          type="button"
                        >
                          <DeleteIcon className="text-gray-600" />
                        </Button>
                      </div>
                      <div className="flex items-center justify-between md:py-2 px-10 py-6 h-44 w-full">
                        <span className="block md:text-16 text-14 font-semibold capitalize">
                          {item?.bAttribute}
                        </span>
                        <Switch
                          checked={item?.isActive}
                          onChange={() => enableDisableCustomAttribute(item)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="text-center py-60">
                <h5 className="text-20 font-semibold mb-20">
                  {t('manageBusiness.addBusinessSteps.customAttribute.addAttributeTitle')}
                </h5>
                <Typography className="text-14 font-medium mb-20 max-w-384 mx-auto">
                  {t('manageBusiness.addBusinessSteps.customAttribute.AddAttributeDesc')}
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  className="md:text-16 font-medium disabled:bg-gray disabled:text-black rounded-full px-28"
                  aria-label="add"
                  disabled={!userCurrentBusinessDetails?.id}
                  onClick={() => setOpenDialogue(true)}
                  type="button"
                >
                  <Icon>add</Icon>
                  {t('manageBusiness.addBusinessSteps.customAttribute.addAttributeButton')}
                </Button>
              </div>
            )}
          </div>
        </div>
        <Dialog
          classes={{
            paper:
              'm-24 p-28 business-type-dropdown border-t-8 border-solid border-t-quick-review border-quick-review rounded-md',
          }}
          open={openDialogue}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle className="p-0 mb-28">
            <Icon
              onClick={handleRemove}
              className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
            >
              cancel
            </Icon>
            <Typography className="text-20 font-semibold text-black border-b-1 border-dashed border-grey-500 w-full pb-10">
              {!edit
                ? t('manageBusiness.addBusinessSteps.customAttribute.dialog.add')
                : t('common.edit')}{' '}
              {t('manageBusiness.addBusinessSteps.customAttribute.dialog.title')}
            </Typography>
          </DialogTitle>

          <form noValidate className="flex flex-col md:overflow-hidden">
            <DialogContent className="p-0 mb-24">
              <div className="block mb-24">
                <InputLabel
                  htmlFor="bAttribute"
                  className="text-16 font-medium mb-12 text-grey-900 flex items-center justify-between"
                >
                  {t(
                    'manageBusiness.addBusinessSteps.customAttribute.dialog.form.attributeName.name'
                  )}
                  <span>{bAttribute?.length || 0}/20</span>
                </InputLabel>
                <TextField
                  placeholder={t(
                    'manageBusiness.addBusinessSteps.customAttribute.dialog.form.attributeName.placeHolder'
                  )}
                  onChange={handleChange}
                  onPaste={handlePaste}
                  value={bAttribute}
                  size="small"
                  id="bAttribute"
                  variant="outlined"
                  required
                  fullWidth
                />
                {isEmpty && (
                  <Typography className="text-red pt-8 text-14">
                    {t(
                      'manageBusiness.addBusinessSteps.customAttribute.dialog.form.attributeName.placeHolder'
                    )}
                  </Typography>
                )}
                {attributeValidation > 20 &&
                  (bAttribute?.length <= 20 || bAttribute?.length === undefined) &&
                  !isEmpty && (
                    <Typography className="text-red pt-8 text-14">
                      {t('customAttribute.validationMsg')}
                    </Typography>
                  )}
              </div>
              <div className="block">
                <InputLabel
                  htmlFor="bAttribute"
                  className="text-16 font-medium mb-12 text-grey-900"
                >
                  {t(
                    'manageBusiness.addBusinessSteps.customAttribute.dialog.form.selectRatingFormat.name'
                  )}
                </InputLabel>
                <Select
                  className="w-full"
                  id="ratingFormate"
                  sx={{ '& .MuiSelect-select': { display: 'flex', alignItems: 'center' } }}
                  size="small"
                  value={ratingFormateValue}
                  displayEmpty
                  onChange={(e) => {
                    selectRatingFormateValue(e?.target?.value);
                  }}
                >
                  <MenuItem value={0} disabled>
                    {t('business.businessAttribute.formField.select')}
                  </MenuItem>
                  {ratingFormateData?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.type}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </DialogContent>
            <DialogActions className="p-0 justify-center">
              <Button
                className="md:text-16 font-medium disabled:text-black rounded-md w-full sm:w-256"
                variant="contained"
                color="secondary"
                type="submit"
                size="small"
                disabled={
                  bAttribute?.length > 20 ||
                  ratingFormateValue === 0 ||
                  refreshIcon ||
                  !bAttribute ||
                  (editData?.bAttribute === bAttribute &&
                    parseInt(editData?.businessAttributeValueId, 10) ===
                      parseInt(ratingFormateValue, 10))
                }
                onClick={() => onSubmit()}
              >
                {t('common.save')}
                {refreshIcon && (
                  <CircularProgress size={24} className="text-quick-review absolute mx-auto" />
                )}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <ConformationDialogBox
          open={openDialogueForDelete}
          onClose={handleCloseDeleteDialog}
          onDelete={() => {
            handleDelete();
          }}
          confirmationText={t('manageBusiness.button.confirmDeleteAttribute')}
          deleteImage="/assets/images/icon/delete.png"
          additionalInfo={`${t('manageBusiness.deleteText.deleteText1')} ${
            businessCustomAttribute.find((attr) => attr.id === selectedAttributeId)?.bAttribute
          }${t('manageBusiness.deleteText.deleteText2')}`}
          backgroundColor="red"
          textColor="red"
          buttonText={t('common.delete')}
        />
      </div>
    </div>
  );
};

export default CustomAttributes;
