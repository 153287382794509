import {
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import { selectDashboardData } from 'app/store/userSlice';
import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import history from '@history';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { useSnackbar } from 'notistack';
import ClearIcon from '@mui/icons-material/Clear';
import businessData from 'src/app/main/query/business';
import ConformationDialogBox from '../../../CommonComponent/ConformationDialogBox';
import { handleApiRequest } from '../../../common/common';
import CreateTemplate from './CreateTemplate';
import userService from '../../../../services';
import quickSocialQuery from '../../../query/quickSocial';
import quickChatQuery from '../../../query/quickChat';
import userData from '../../../query/user';

const Template = (props) => {
  const { showInPopUp } = props;
  const location = useLocation();
  const [tabValue, setTabValue] = useState(location?.state?.tabValue || 2);
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [templates, setTemplates] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [comeFrom, setComeFrom] = useState(false);
  const [selectTemplate, setSelectTemplate] = useState();
  const [openDialogToShowTemplate, setOpenDialogToShowTemplate] = useState(false);
  const [subcategoryData, setSubcategoryData] = useState([]);
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [openDeleteDialogBox, setOpenDeleteDialogBox] = useState(false);
  const [deleteTemplateId, setDeleteTemplateId] = useState('');
  const loginUserData = userService.getUserData();
  const [suggestion, setSuggestion] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchTerm, setSearchTerm] = useState({});
  const [suggestList, setSuggestList] = useState(false);
  const [showIcon, setShowIcon] = useState(null);
  const [subShowIcon, setSubShowIcon] = useState(null);
  const [subCategoryIcon, setSubCategoryIcon] = useState(null);
  const [cardsData, setCardsData] = useState([]);
  const [userBusinessDetails, setUserBusinessDetails] = useState();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const categoryFind = category?.find((item) => item.type === 'Your Template');
    setSelectedCategory(categoryFind);
  }, [tabValue, category]);

  useEffect(() => {
    const fetchAppSetUpData = async () => {
      try {
        const payloadData = {
          query: businessData.getAppSetupCard,
          variables: {
            businessId: Number(userCurrentBusinessDetails?.id),
            type: 'quick_campaigns',
          },
        };
        const resultData = await handleApiRequest(payloadData);

        if (resultData?.getAppSetupCard?.status === 200) {
          setCardsData(resultData?.getAppSetupCard?.data || []);
        } else {
          console.error('Unexpected status:', resultData?.getAppSetupCard?.status);
        }
      } catch (error) {
        console.error('Error fetching cards data:', error);
      }
    };
    if (userCurrentBusinessDetails?.id) {
      fetchAppSetUpData();
    }
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    const fetchDashBoard = async () => {
      try {
        const obj = {
          query: userData.getDashboard,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const res = await handleApiRequest(obj);
        setUserBusinessDetails(res?.getDashboard?.data?.[0]);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    if (userCurrentBusinessDetails?.id) {
      fetchDashBoard();
    }
  }, [userCurrentBusinessDetails?.id]);
  useEffect(() => {
    const searchQuickSocialTemplatesCategories = async () => {
      try {
        const payload = {
          query: businessData.searchQuickCampaignTemplatesCategories,
          variables: {
            searchText,
            countryId: Number(loginUserData?.countryId),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.searchQuickCampaignTemplatesCategories?.status === 200) {
          setSuggestion(result?.searchQuickCampaignTemplatesCategories?.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (searchText) {
      searchQuickSocialTemplatesCategories();
    }
  }, [searchText, loginUserData?.countryId]);

  useEffect(() => {
    const fetchSubcategory = async () => {
      try {
        const payload = {
          query: businessData.getQuickCampaignTemplatesSubCategoryV1,
          variables: {
            campaignBusinessTypeId: Number(selectedCategory?.mainCategoryId),
            countryId: Number(loginUserData?.countryId),
            userBusinessId: Number(userBusinessDetails?.id),
          },
        };
        const result = await handleApiRequest(payload);

        if (result?.getQuickCampaignTemplatesSubCategoryV1?.status === 200) {
          const data = result.getQuickCampaignTemplatesSubCategoryV1?.data;
          const sortData = data?.sort((a, b) => a.id - b.id);
          setSubcategoryData(data);
          setSelectedSubcategoryId(sortData?.[0]?.id);
        } else {
          enqueueSnackbar(result?.deleteDraftAndScheduleCamping?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        console.error('Error fetching subcategories:', error);
      }
    };

    if (loginUserData?.countryId && selectedCategory?.isSubCategory && userBusinessDetails?.id) {
      fetchSubcategory();
    }
  }, [loginUserData?.countryId, selectedCategory, enqueueSnackbar, userBusinessDetails?.id]);

  const getQuickCampaignTemplatesCategoryV1 = useCallback(async () => {
    try {
      const payload = {
        query: businessData.getQuickCampaignTemplatesCategoryV1,
        variables: {
          userBusinessId: Number(userBusinessDetails?.id),
          relevantTemplateIds: userBusinessDetails?.relevantCampaignTemplateId,
          searchText: searchTerm?.type || null,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getQuickCampaignTemplatesCategoryV1?.status === 200) {
        setCategory(result.getQuickCampaignTemplatesCategoryV1?.data);
        setSelectedCategory(result.getQuickCampaignTemplatesCategoryV1?.data?.[0]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [searchTerm, userBusinessDetails?.id, userBusinessDetails?.relevantCampaignTemplateId]);

  useEffect(() => {
    if (userBusinessDetails?.id) {
      getQuickCampaignTemplatesCategoryV1();
    }
  }, [getQuickCampaignTemplatesCategoryV1, userBusinessDetails]);

  const fetchTemplate = useCallback(async () => {
    if (userBusinessDetails?.id && tabValue) {
      try {
        const payload = {
          query: businessData.getCampaignStandardMessages,
          variables: {
            campaignPlatformId: tabValue,
            messagesBusinessTypeId: Number(selectedCategory?.mainCategoryId),
            ...((Number(selectedCategory?.mainCategoryId) === 1 || tabValue === 3) && {
              userBusinessId: Number(userBusinessDetails?.id),
            }),
            ...(selectedSubcategoryId && {
              messagesBusinessSubTypeId: Number(selectedSubcategoryId),
            }),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getCampaignStandardMessages?.status === 200) {
          const data = result?.getCampaignStandardMessages?.data;
          if (
            category?.find((value) => value?.mainCategoryId === selectedCategory?.mainCategoryId)
              ?.type === 'Invite Review' &&
            tabValue === 1
          ) {
            const updateData = data?.map((template) => ({
              ...template,
              htmlTemplate: template?.htmlTemplate?.replace(
                '[Review Link]',
                userBusinessDetails?.businessQRId
              ),
            }));
            setTemplates(updateData);
          } else {
            setTemplates(data);
          }
        }
      } catch (error) {
        console.error('Error white fetching template', error);
      }
    }
  }, [selectedCategory, tabValue, userBusinessDetails, selectedSubcategoryId, category]);

  useEffect(() => {
    fetchTemplate();
  }, [fetchTemplate]);

  const handleGetTemplateCategoryWise = async (id) => {
    setSelectedCategory(id);
    setSelectedSubcategoryId('');
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    handleGetTemplateCategoryWise(selectedCategory, newValue);
    setSelectedSubcategoryId('');
  };

  const selectDeleteTemplate = (id) => {
    setDeleteTemplateId(id);
    setOpenDeleteDialogBox(true);
  };

  const deleteTemplate = async () => {
    try {
      setRefreshIcon(true);
      const payload = {
        query: businessData.deleteCampaignServicesStandardMessages,
        variables: {
          userBusinessId: Number(userBusinessDetails?.id),
          campaignStandardMessageId: Number(deleteTemplateId),
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.deleteCampaignServicesStandardMessages?.status === 200) {
        fetchTemplate();
        setOpenDeleteDialogBox(false);
        enqueueSnackbar(result?.deleteCampaignServicesStandardMessages?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar(result?.deleteCampaignServicesStandardMessages?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error in deleteTemplate:', error);
    }
  };

  const editTemplate = async (data, name) => {
    setSelectTemplate(data);
    setOpenDialog(true);
    setComeFrom(name);
  };

  const handleCloseDeleteDialogBix = () => {
    setDeleteTemplateId('');
    setOpenDeleteDialogBox(false);
  };

  const handleSelectedCategory = (data) => {
    setSearchTerm(data);
    setSearchText(data?.type);
    setSuggestList(false);
  };

  const handlePinnedCategory = async (data) => {
    try {
      const payload = {
        query: quickSocialQuery.pinTemplateCategories,
        variables: {
          userBusinessId: Number(userBusinessDetails?.id),
          quickCampaignTemplatesCategoryId:
            data?.subCategoryId || data?.id ? null : Number(data?.mainCategoryId),
          quickCampaignTemplatesSubCategoryId: data?.subCategoryId || data?.id,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.pinTemplateCategories?.status === 200) {
        getQuickCampaignTemplatesCategoryV1();
        // getQuickSocialTemplatesSubCategoryV1();
      } else {
        enqueueSnackbar(result?.pinTemplateCategories?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleUnPinnedCategory = async (data) => {
    try {
      const payload = {
        query: quickSocialQuery.unPinTemplateCategories,
        variables: {
          userBusinessId: Number(userBusinessDetails?.id),
          quickCampaignTemplatesCategoryId: data?.subCategoryId
            ? null
            : Number(data?.mainCategoryId),
          quickCampaignTemplatesSubCategoryId: Number(data?.subCategoryId),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.unPinTemplateCategories?.status === 200) {
        getQuickCampaignTemplatesCategoryV1();
      } else {
        enqueueSnackbar(result?.unPinTemplateCategories?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePendingTask = async () => {
    const isAdded = cardsData?.find((item) => item?.cardName === 'exploreTemplates')?.isAdded;
    if (!isAdded) {
      try {
        const payload = {
          query: quickChatQuery.updateAppSetUpCard,
          variables: {
            userBusinessId: Number(userCurrentBusinessDetails?.id),
            cardType: 'exploreTemplates',
            productType: 'quick_campaigns',
          },
        };
        const res = await handleApiRequest(payload);
      } catch (error) {
        console.error('Error in updateAppSetUpCard:', error);
      }
    }
  };

  return (
    <div className="lg:p-24 p-16 h-max">
      <div className="sm:flex justify-between lg:mb-20 mb-16">
        <Typography
          className={`font-bold md:text-28 text-20 ${
            Number(tabValue) !== 3 && !showInPopUp ? 'mb-16 sm:mb-0' : ''
          }`}
        >
          {t('dashboard.exploreFeatures.campaigns.template')}
        </Typography>
        <div className=" max-w-640 relative">
          <TextField
            placeholder="Search Template Category"
            variant="outlined"
            fullWidth
            className="bg-white my-0 quick-campaign w-full sm:w-384 mr-12"
            margin="normal"
            size="small"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              setSuggestList(true);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchText && (
                <InputAdornment position="end">
                  <IconButton
                    className="hover:bg-transparent"
                    onClick={() => {
                      setSearchText('');
                      setSearchTerm({});
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
              sx: {
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              },
            }}
          />
          {suggestList && searchText && (
            <div className="bg-white h-144 w-384 overflow-y-auto z-20 rounded-8 absolute p-7 mt-3">
              {suggestion && suggestion?.length > 0 ? (
                suggestion?.map((interest, index) => (
                  <div
                    key={index}
                    className="h-60 p-4 flex font-medium items-center  border-b-1 border-b-gray-A100 last:border-b-0"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      handleSelectedCategory(interest);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleSelectedCategory(interest);
                      }
                    }}
                  >
                    <p>{interest?.name} </p>
                    <p className="text-black capitalize">{interest?.type}</p>
                  </div>
                ))
              ) : (
                <div className=" left-0 right-14 bg-white h-92 w-384  z-20 rounded-8 absolute p-7 text-black flex justify-center items-center">
                  {t('quickAds.noDataAvailable')}
                </div>
              )}
            </div>
          )}
          {Number(tabValue) !== 3 && !showInPopUp && (
            <Button
              variant="contained"
              color="quickCampaign"
              autoFocus
              onClick={() => {
                setComeFrom('create');
                setOpenDialog(true);
              }}
              size="medium"
              className="rounded-md gap-10 md:px-20 md:py-12"
            >
              <p className="text-16 font-medium"> {t('quickCampaign.campaign.createTemplate')}</p>
            </Button>
          )}
        </div>
      </div>
      <div className="border-solid border-grey sm:flex justify-between items-center border-b-2 mb-32 overflow-x-auto">
        <Tabs
          value={tabValue}
          className="sm:overflow-hidden overflow-scroll quick-campaign"
          sx={{
            '& .MuiTabs-scroller': {
              overflow: { xs: 'scroll !important', sm: 'hidden !important' },
            },
          }}
          onChange={handleTabChange}
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab
            className={`sm:text-18 font-bold mr-20 p-0 ${
              tabValue === 2 ? 'text-quick-campaign' : 'text-black'
            }`}
            value={2}
            label={t('dashboard.exploreFeatures.campaigns.sms')}
          />
          <Tab
            className={`sm:text-18 font-bold mr-20 p-0 ${
              tabValue === 1 ? 'text-quick-campaign' : 'text-black'
            }`}
            value={1}
            label={t('common.email')}
          />
          <Tab
            className={`sm:text-18 font-bold p-0 ${
              tabValue === 3 ? 'text-quick-campaign' : 'text-black'
            }`}
            value={3}
            label={t('dashboard.exploreFeatures.campaigns.whatsapp')}
          />
        </Tabs>
      </div>
      <div
        className={`lg:flex md:flex gap-24 ${
          showInPopUp
            ? 'min-w-[1350px] max-w-[1350px] h-[850px] min-w-[400px] max-w-[400px] sm:min-w-[800px] sm:-max-w-[800px] md:max-w-[1150px] md:min-w-[1150px]'
            : 'w-full max-h-[calc(100vh-250px)] h-screen'
        }`}
      >
        <div className="mb-10 sm:mb-10 md:max-w-288 max-w-full w-full overflow-x-auto lg:overscroll-hidden">
          <div className="sm:flex flex md:block">
            {category?.length > 0 &&
              category?.map((item, index) => (
                <div
                  key={index}
                  role="button"
                  tabIndex={0}
                  onMouseEnter={() => {
                    setShowIcon(item?.mainCategoryId);
                    item?.subCategoryId
                      ? setSubShowIcon(item?.subCategoryId)
                      : setSubShowIcon(null);
                  }}
                  onMouseLeave={() => {
                    setShowIcon(null);
                    setSubShowIcon(null);
                  }}
                  className={`flex px-12 py-8 h-64 md:min-w-256 cursor-pointer gap-8  items-center flex-shrink-0 ${
                    selectedCategory?.type === item?.type
                      ? 'bg-quick-campaign text-white '
                      : ' bg-white '
                  }`}
                  onClick={() => {
                    handleGetTemplateCategoryWise(item);
                    handlePendingTask();
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      setSelectedCategory(item);
                    }
                  }}
                >
                  <img className="w-40" src={item?.icon} alt={item?.name} />
                  <h3 className="text-16 font-medium whitespace-normal break-words flex-grow">
                    {item?.type}
                  </h3>
                  {!item?.isPinCategory &&
                    showIcon === item?.mainCategoryId &&
                    subShowIcon === item?.subCategoryId && (
                      <div className="bg-gray-A500 p-3 rounded-full flex justify-center items-center">
                        <PushPinOutlinedIcon
                          className="text-quick-campaign h-20 w-20"
                          style={{ transform: 'rotate(45deg)' }}
                          onClick={() => handlePinnedCategory(item)}
                        />
                      </div>
                    )}
                  {item?.isPinCategory && (
                    <div className="bg-gray-A500 p-3 rounded-full flex justify-center items-center">
                      <PushPinIcon
                        className="text-quick-campaign h-20 w-20"
                        style={{ transform: 'rotate(45deg)' }}
                        onClick={() => handleUnPinnedCategory(item)}
                      />
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
        <div className="w-full overflow-scroll">
          {selectedCategory?.isSubCategory === true && (
            <div className="w-auto overflow-x-auto mb-16">
              <div className="gap-10 flex">
                {subcategoryData?.length > 0 &&
                  subcategoryData
                    ?.sort((a, b) => a.id - b.id)
                    ?.map((subcategory) => (
                      <div
                        key={subcategory?.id}
                        role="button"
                        tabIndex={0}
                        className={`flex gap-8 border-1 px-36 py-20 text-14 rounded-full justify-center items-center relative cursor-pointer mb-12 h-32 border-gray-300 ${
                          selectedSubcategoryId === subcategory?.id
                            ? 'bg-quick-campaign text-white'
                            : 'bg-transparent'
                        }`}
                        onClick={() => setSelectedSubcategoryId(subcategory?.id)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            setSelectedSubcategoryId(subcategory?.id);
                          }
                        }}
                        onMouseEnter={() => {
                          setSubCategoryIcon(subcategory?.id);
                        }}
                        onMouseLeave={() => {
                          setSubCategoryIcon(null);
                        }}
                      >
                        <div className="flex justify-start items-center">
                          <span className="h-24 w-24 bg-white p-4 rounded-full">
                            <img src={subcategory?.icon} alt={subcategory?.name} />
                          </span>
                          <h4 className="text-ellipsis whitespace-nowrap ml-8">
                            {subcategory?.type}
                          </h4>
                        </div>
                        {subCategoryIcon === subcategory?.id && (
                          <div className="bg-gray-A500 p-3 rounded-full flex justify-center items-center absolute right-5 top-8">
                            <PushPinOutlinedIcon
                              className="text-quick-campaign h-20 w-20"
                              style={{ transform: 'rotate(45deg)' }}
                              onClick={() => handlePinnedCategory(subcategory)}
                            />
                          </div>
                        )}
                      </div>
                    ))}
              </div>
            </div>
          )}
          {templates?.length > 0 ? (
            <div className="grid grid-cols-6 gap-16 h-max w-full">
              {templates?.map((item, i) => {
                return (
                  <div
                    className="bg-white p-16 md:col-span-2 sm:col-span-3 col-span-6 h-max rounded-md w-full"
                    key={i}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      setSelectTemplate(item);
                      setOpenDialogToShowTemplate(true);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        setSelectTemplate(item);
                      }
                    }}
                  >
                    <div className="flex items-center justify-between">
                      <Typography className="font-bold text-16">{item?.title}</Typography>
                      {category?.find(
                        (value) => value?.mainCategoryId === selectedCategory?.mainCategoryId
                      )?.type === 'Your Template' && (
                        <div className="flex gap-12">
                          <Button
                            className="w-32 max-w-32 min-w-32 h-32 min-h-32 p-8 bg-gray-A500"
                            onClick={(e) => {
                              e.stopPropagation();
                              editTemplate(item, 'edit');
                            }}
                          >
                            <img className="w-20" src="/assets/icons/edit.svg" alt="" />
                          </Button>
                          <Button
                            className="w-32 max-w-32 min-w-32 h-32 min-h-32 p-8 bg-gray-A500"
                            onClick={(e) => {
                              e.stopPropagation();
                              selectDeleteTemplate(item?.id);
                            }}
                            disabled={refreshIcon}
                          >
                            <img className="w-20" src="/assets/icons/delete.svg" alt="" />
                            {refreshIcon && (
                              <CircularProgress
                                size={24}
                                className="text-quick-campaign absolute mx-auto"
                              />
                            )}
                          </Button>
                        </div>
                      )}
                    </div>
                    {tabValue === 2 && (
                      <Typography className="bg-gray-A500 p-8 mt-16 rounded-md h-112 overflow-scroll">
                        {item?.messages}
                      </Typography>
                    )}
                    {tabValue === 1 && (
                      <div className="bg-gray-A500 p-8 mt-16 rounded-md h-256 overflow-scroll">
                        <Typography className="">
                          {t('quickCampaign.template.subject')} : <span>{item?.htmlSubject}</span>
                        </Typography>
                        <Typography
                          className="mt-8"
                          dangerouslySetInnerHTML={{ __html: item?.htmlTemplate }}
                        />
                      </div>
                    )}
                    {tabValue === 3 && (
                      <div className="mt-16 rounded-md h-312 overflow-scroll">
                        <Typography
                          className="mt-8 w-full"
                          dangerouslySetInnerHTML={{ __html: item?.htmlTemplate }}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex justify-center text-center w-full">
              {tabValue === 1 && (
                <div className="pt-40 pb-40 sm:pb-60 sm:pt-60 w-full">
                  <img className="m-auto w-192" src="assets/images/business/email.png" alt="" />
                  <Typography className="py-12 font-semibold text-20">
                    {t('quickCampaign.template.noEmailFound')}
                  </Typography>
                  <Typography className="font-semibold text-16 text-gray max-w-400 m-auto">
                    {t('quickCampaign.template.noEmailFoundMessage')}
                  </Typography>
                </div>
              )}
              {tabValue === 2 && (
                <div className="pt-160 w-full">
                  <img className="m-auto w-192" src="assets/images/business/sms.png" alt="" />
                  <Typography className="py-12 font-semibold text-20">
                    {t('quickCampaign.template.noSmsFound')}
                  </Typography>
                  <Typography className="font-semibold text-16 text-gray max-w-400 m-auto">
                    {t('quickCampaign.template.noSmsFoundMessage')}
                  </Typography>
                </div>
              )}
              {tabValue === 3 && (
                <div className="pt-160 w-full">
                  <img className="m-auto w-192" src="assets/images/business/whatsapp.png" alt="" />
                  <Typography className="py-12 font-semibold text-20">
                    {t('quickCampaign.template.noWhatsAppFound')}
                  </Typography>
                  <Typography className="font-semibold text-16 text-gray max-w-400 m-auto">
                    {t('quickCampaign.template.noWhatsAppFoundMessage')}
                  </Typography>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <CreateTemplate
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        category={category}
        tabValue={tabValue}
        fetchTemplate={fetchTemplate}
        template={selectTemplate}
        setSelectTemplate={setSelectTemplate}
        isEdit={!!selectTemplate}
        comeFrom={comeFrom}
      />
      <ConformationDialogBox
        open={openDeleteDialogBox}
        onClose={handleCloseDeleteDialogBix}
        additionalInfo={t('quickCampaign.template.deleteWarnMessage')}
        onDelete={deleteTemplate}
        confirmationText={t('quickCampaignsContacts.confirmMessage')}
        deleteImage="/assets/images/icon/campaignDeleteIcon.png"
        backgroundColor="quick-campaign"
        textColor="quick-campaign"
        buttonText={t('common.delete')}
      />
      <Dialog
        classes={{
          paper: 'm-24 sm:p-16 p-10 pt-0 rounded-md max-w-400',
        }}
        open={openDialogToShowTemplate}
        fullWidth
        maxWidth="sm"
      >
        <div className="text-right flex justify-between w-full sm:pt-0 pt-10 pb-10">
          <Typography className="font-bold text-16">{selectTemplate?.title}</Typography>
          <Button
            className="p-0 min-w-28 min-h-0 hover:bg-white max-h-20 h-20"
            onClick={() => {
              setOpenDialogToShowTemplate(false);
              setSelectTemplate('');
            }}
          >
            <CloseIcon />
          </Button>
        </div>
        <div className="bg-white p-2 h-max rounded-md w-full">
          {tabValue === 2 && (
            <Typography className="bg-gray-200 p-8 mt-16 rounded-md">
              {selectTemplate?.messages}
            </Typography>
          )}
          {tabValue === 1 && (
            <div className="bg-gray-A500 p-8 rounded-md h-max">
              <Typography className="">
                {t('quickCampaign.template.subject')} : <span>{selectTemplate?.htmlSubject}</span>
              </Typography>
              <Typography
                className="mt-8"
                dangerouslySetInnerHTML={{ __html: selectTemplate?.htmlTemplate }}
              />
            </div>
          )}
          {tabValue === 3 && (
            <div className="mt-16 rounded-md h-max overflow-scroll">
              <Typography
                className="mt-8 w-full"
                dangerouslySetInnerHTML={{ __html: selectTemplate?.htmlTemplate }}
              />
            </div>
          )}
          <Button
            variant="contained"
            color="quickCampaign"
            autoFocus
            onClick={() =>
              history.push('quick-campaign/createCampaign', {
                template: selectTemplate,
                platForm: tabValue,
                selectedCategoryId: selectedCategory?.mainCategoryId,
                categoryName: selectedCategory?.type,
              })
            }
            size="medium"
            className="rounded-md w-full mt-20 gap-10 md:px-20 md:py-12"
          >
            <p className="text-16 font-medium"> {t('quickCampaign.campaign.selectTemplate')}</p>
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default Template;
