import WabaBillingAndUsage from './WabaBillingAndUsage';
import WabaBroadcast from './WabaBroadcast';
import WabaContact from './WabaContact';
import WabaInbox from './WabaInbox';
import WabaSetting from './WabaSetting';
import WabaDashBoard from './WabaDashBoard';
import Broadcast from './broadcast/Broadcast';
import CreateBroadcastDetails from './broadcast/CreateBroadcast/CreateBroadcastDetails';
import Template from './template/Template';
import CreateOrEditTemplate from './template/CreateOrEditTemplate';
import ViewBroadcastDetails from './broadcast/ViewBroadcastDetails';

const WabaConfigs = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'quick-chat/waba-home/:id?',
      element: <WabaDashBoard />,
    },
    {
      path: 'quick-chat/waba-inbox/:id?',
      element: <WabaInbox />,
    },
    {
      path: 'quick-chat/template/',
      element: <Template />,
    },
    {
      path: 'quick-chat/waba-broadcast/',
      element: <Broadcast />,
    },
    {
      path: 'quick-chat/broadcastDetails/',
      element: <ViewBroadcastDetails />,
    },
    {
      path: 'quick-chat/createOrEditTemplate/',
      element: <CreateOrEditTemplate />,
    },
    {
      path: 'quick-chat/createBroadcast/',
      element: <CreateBroadcastDetails />,
    },
    {
      path: 'quick-chat/waba-contacts/:id?',
      element: <WabaContact />,
    },
    {
      path: 'quick-chat/waba-billing-and-usage/:id?',
      element: <WabaBillingAndUsage />,
    },
    {
      path: 'quick-chat/waba-setting/:id?',
      element: <WabaSetting />,
    },
  ],
};

export default WabaConfigs;
