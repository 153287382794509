import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';
import { t } from 'i18next';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { IconButton, Menu, MenuItem, Button } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import FuseLoading from '@fuse/core/FuseLoading';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import { useParams } from 'react-router-dom';
import history from '@history';
import ConformationDialogBox from 'src/app/main/CommonComponent/ConformationDialogBox';
import quickChatQuery from 'src/app/main/query/quickChat';
import { getDecryptData, handleApiRequest, handleWabaApiRequest } from 'src/app/main/common/common';
import wabaQuery from 'src/app/main/query/waba';
import SharePost from '../quick-post/SharePost';

const BotStatus = (props) => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElForWP, setAnchorElForWP] = useState(null);
  const [getChatRoom, setGetChatRoom] = useState([]);
  const [copyLink, setCopyLink] = useState(false);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const [openDialogueForConformation, setOpenDialogueForConformation] = useState(false);
  const [openDialogueForConformationForWP, setOpenDialogueForConformationForWP] = useState(false);
  const [isWhatsAppStatus, setIsWhatsAppStatus] = useState();
  const [openDialogueForRedirectToWABA, setOpenDialogueForRedirectToWABA] = useState(false);
  const [shareCompOpen, setShareCompOpen] = useState(false);
  const { quickChatActiveData, fetchAgentSupportStatus } = props;
  const botLink = `${process.env.REACT_APP_PORTAL_URL}/quickchat/chat-assistant/${quickChatActiveData?.domain}`;

  const redirect = (type) => {
    switch (type) {
      case 'waba':
        history.push(`/quick-chat/waba/${params?.id ? params.id : ''}`);
        break;
      default:
        console.warn(`Unknown redirect type: ${type}`);
        break;
    }
  };

  useEffect(() => {
    const whatAppUserChatRoom = async () => {
      if (!userCurrentBusinessDetails?.id) return;
      try {
        const payload = {
          query: wabaQuery.getChatRoomWithLastMessage,
          variables: {
            isBroadcast: false,
            isUnRead: false,
            userBusinessId: userCurrentBusinessDetails?.id,
          },
        };
        const res = await handleWabaApiRequest(payload);
        if (res?.getChatRoomWithLastMessage?.status === 200) {
          setGetChatRoom(res?.getChatRoomWithLastMessage?.data);
        } else {
          setGetChatRoom([]);
        }

        whatAppUserChatRoom();
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };
  }, [userCurrentBusinessDetails?.id]);

  const handleClickEvent = (event) => {
    setAnchorEl(event.currentTarget);
    setIsWhatsAppStatus(false);
  };
  const handleClickEventForWP = (event) => {
    setAnchorElForWP(event.currentTarget);
    setIsWhatsAppStatus(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElForWP(null);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(botLink).then(() => {
      setCopyLink(true);
      setTimeout(() => {
        setCopyLink(false);
      }, 1000);
    });
  };

  const handleCloseComp = () => {
    setShareCompOpen(false);
  };
  const handleCloseConformationDialog = () => {
    setOpenDialogueForConformation(false);
  };
  const handleCloseConformationDialogForWP = () => {
    setOpenDialogueForConformationForWP(false);
  };
  const handleCloseConformationDialogForWABA = () => setOpenDialogueForRedirectToWABA(false);
  const handleActiveInActiveChatBot = async () => {
    try {
      const variables = {
        userBusinessId: Number(userCurrentBusinessDetails?.id),
        ...(isWhatsAppStatus
          ? { isWhatsAppSupport: !quickChatActiveData?.isWhatsAppSupport }
          : { isActive: !quickChatActiveData?.isActive }),
      };

      const payload = {
        query: quickChatQuery.startAndStopQuickChatServices,
        variables,
      };

      const res = await handleApiRequest(payload);
      if (res?.startAndStopQuickChatServices?.status === 200) {
        setOpenDialogueForConformation(false);
        fetchAgentSupportStatus();
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div>
      <div className="bg-white max-w-640 rounded-md p-10 md:p-24">
        <div className="w-full flex flex-col justify-between gap-20 rounded-md bg-white">
          <p className="text-20 font-600">{t('quickChat.chatbotDetail.botStatus')}</p>
          {!userCurrentBusinessDetails?.isOnlineBusiness && (
            <div className="bg-indigo-50 bg-opacity-50 flex justify-between flex-row items-center p-10">
              <div className="flex gap-14 flex-row items-center">
                <div className="bg-white w-48 h-48 flex justify-center items-center relative rounded-full overflow-hidden">
                  <img
                    className="w-full h-full object-cover"
                    name="logo"
                    src={quickChatActiveData?.avatar}
                    alt="bell notification icon"
                  />
                </div>
                <p className="text-14">{quickChatActiveData?.name}</p>
              </div>
              <div className="flex items-center gap-0">
                {quickChatActiveData?.isActive ? (
                  <>
                    <span className="bg-quick-review-100 text-14 sm:text-16 flex items-center text-quick-review-900 py-6 px-6 sm:px-16 w-full rounded-8">
                      <span className="inline-block w-8 h-8 sm:w-10 sm:h-10 mr-6 rounded-full bg-green" />
                      {t('quickChat.chatbotDetail.active')}
                    </span>
                  </>
                ) : (
                  <span className="bg-gray-300 text-16 flex items-center text-black py-6 px-24 rounded-8">
                    {t('quickChat.chatbotDetail.inactive')}
                  </span>
                )}
                <div>
                  <IconButton className="hover:bg-transparent !p-0" onClick={handleClickEvent}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    classes={{
                      paper: 'min-w-112',
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleCopyLink}>
                      <ContentCopyRoundedIcon className="text-15 mr-8" />
                      {t('partners.action.copyLink')}
                    </MenuItem>
                    {copyLink && (
                      <span className="absolute bg-black text-white text-12 px-8 rounded top-32 left-52 text-center duration-300 animate-bounce">
                        {t('business.selectedBusinessDetail.labels.copied')}
                      </span>
                    )}
                    <MenuItem onClick={() => setShareCompOpen(true)}>
                      <ShareIcon className="text-15 mr-8" />
                      {t('qrScanner.button.share')}
                    </MenuItem>
                    {quickChatActiveData?.isActive ? (
                      <MenuItem
                        onClick={() => {
                          setOpenDialogueForConformation(true);
                          setAnchorEl(null);
                        }}
                      >
                        <PauseCircleOutlineIcon className="text-15 mr-8" />
                        {t('quickChat.chatbotDetail.pauseService')}
                      </MenuItem>
                    ) : (
                      <MenuItem
                        onClick={() => {
                          setOpenDialogueForConformation(true);
                          setAnchorEl(null);
                        }}
                      >
                        <PlayCircleIcon className="text-15 mr-8" />
                        {t('quickChat.chatbotDetail.activateService')}
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              </div>
            </div>
          )}
          <div className="bg-indigo-50 bg-opacity-50 flex flex-row justify-between items-center p-10">
            <div className="flex gap-14 flex-row items-center">
              <div className="bg-white w-48 h-48 flex justify-center items-center relative rounded-full">
                <img
                  className="block rounded w-full absolute p-8"
                  name="logo"
                  src="/assets/images/business/whatsapp.svg"
                  alt="WhatsApp icon"
                />
              </div>
              <p className="text-14 ">{t('quickChat.chatbotDetail.whatsappBot')}</p>
            </div>
            <div className="flex items-center gap-0">
              {!props?.whatsAppPhoneNumberData ? (
                <div>
                  <Button
                    className="text-12 sm:text-16 font-medium rounded-md !px-14 md:!px-32"
                    variant="contained"
                    color="quickChat"
                    type="button"
                    size="small"
                    disabled={getChatRoom?.length !== 0}
                    onClick={() => setOpenDialogueForRedirectToWABA(true)}
                  >
                    {t('users.UserList.Filter.applyBtn')}
                  </Button>
                </div>
              ) : (
                <>
                  {quickChatActiveData?.isWhatsAppSupport ? (
                    <>
                      <span className="bg-quick-review-100 text-14 sm:text-16 flex items-center text-quick-review-900 py-6 px-6 sm:px-16 w-full rounded-8">
                        <span className="inline-block w-8 h-8 sm:w-10 sm:h-10 mr-6 rounded-full bg-green" />
                        {t('quickChat.chatbotDetail.active')}
                      </span>
                    </>
                  ) : (
                    <span className="bg-gray-300 text-16 flex items-center text-black py-6 px-24 rounded-8">
                      {t('quickChat.chatbotDetail.inactive')}
                    </span>
                  )}
                  <div>
                    <IconButton
                      className="hover:bg-transparent !p-0"
                      onClick={handleClickEventForWP}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorElForWP}
                      open={Boolean(anchorElForWP)}
                      classes={{
                        paper: 'min-w-112',
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      onClose={handleClose}
                    >
                      {quickChatActiveData?.isWhatsAppSupport ? (
                        <MenuItem
                          onClick={() => {
                            setOpenDialogueForConformationForWP(true);
                            setAnchorElForWP(null);
                          }}
                        >
                          <PauseCircleOutlineIcon className="text-15 mr-8" />
                          {t('quickChat.chatbotDetail.pauseService')}
                        </MenuItem>
                      ) : (
                        <MenuItem
                          onClick={() => {
                            setOpenDialogueForConformationForWP(true);
                            setAnchorElForWP(null);
                          }}
                        >
                          <PlayCircleIcon className="text-15 mr-8" />
                          {t('quickChat.chatbotDetail.activateService')}
                        </MenuItem>
                      )}
                    </Menu>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {shareCompOpen && (
        <SharePost
          image={botLink}
          open={shareCompOpen}
          onClose={handleCloseComp}
          dialogTitle={t('quickChat.chatbotDetail.shareThisBot')}
        />
      )}
      {!isWhatsAppStatus && (
        <ConformationDialogBox
          open={openDialogueForConformation}
          onClose={handleCloseConformationDialog}
          onDelete={() => handleActiveInActiveChatBot()}
          confirmationText={
            quickChatActiveData?.isActive
              ? t('quickChat.noBot.use.pauseWebchatBot')
              : t('quickChat.noBot.use.activateWebchatBot')
          }
          additionalInfo={
            quickChatActiveData?.isActive
              ? t('quickChat.noBot.use.confrimDesc1')
              : t('quickChat.noBot.use.confrimDesc2')
          }
          deleteImage="/assets/images/icon/conformationForChatbot.svg"
          backgroundColor="quick-chat"
          textColor="quick-chat"
          buttonText={
            quickChatActiveData?.isActive
              ? t('quickChat.noBot.use.pauseBot')
              : t('quickChat.noBot.use.confirmActivation')
          }
        />
      )}
      {isWhatsAppStatus && (
        <ConformationDialogBox
          open={openDialogueForConformationForWP}
          onClose={handleCloseConformationDialogForWP}
          onDelete={() => handleActiveInActiveChatBot()}
          confirmationText={
            isWhatsAppStatus
              ? quickChatActiveData?.isWhatsAppSupport
                ? t('quickChat.noBot.use.pauseWhatsappBot')
                : t('quickChat.noBot.use.ActivateWhatsappBot')
              : quickChatActiveData?.isActive
              ? t('quickChat.noBot.use.pauseWebchatBot')
              : t('quickChat.noBot.use.activateWebchatBot')
          }
          additionalInfo={
            isWhatsAppStatus
              ? quickChatActiveData?.isWhatsAppSupport
                ? t('quickChat.noBot.use.confrimDesc3')
                : t('quickChat.noBot.use.confrimDesc4')
              : quickChatActiveData?.isActive
              ? t('quickChat.noBot.use.confrimDesc1')
              : t('quickChat.noBot.use.confrimDesc2')
          }
          deleteImage="/assets/images/icon/conformationForChatbot.svg"
          backgroundColor="quick-chat"
          textColor="quick-chat"
          buttonText={
            quickChatActiveData?.isWhatsAppSupport
              ? t('quickChat.noBot.use.pauseBot')
              : t('quickChat.noBot.use.activateWebchatBotBtn')
          }
        />
      )}
      <ConformationDialogBox
        open={openDialogueForRedirectToWABA}
        onClose={handleCloseConformationDialogForWABA}
        onDelete={() => redirect('waba')}
        confirmationText={t('quickChat.noBot.use.ActivateWhatsappBot')}
        additionalInfo={t('quickChat.noBot.use.ActivateWhatsappBotDesc')}
        deleteImage="/assets/images/icon/conformationForChatbot.svg"
        backgroundColor="quick-chat"
        textColor="quick-chat"
        buttonText={t('quickChat.noBot.use.confirmActivation')}
      />
    </div>
  );
};

export default BotStatus;
