import { t } from 'i18next';
import {
  Button,
  Checkbox,
  Icon,
  MenuItem,
  Select,
  Typography,
  Badge,
  TextField,
  InputAdornment,
  Tabs,
  Drawer,
  Tab,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import EmojiPicker from 'emoji-picker-react';
import { useEffect, useState, useRef, useCallback } from 'react';
import history from '@history';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactPlayer from 'react-player/lazy';
import moment from 'moment-timezone';
import CircularProgress from '@mui/material/CircularProgress';
import { Editor, EditorState, RichUtils, Modifier, convertToRaw, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { useSelector } from 'react-redux';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Tooltip from '@mui/material/Tooltip';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import { useLocation } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as EditIcon } from 'src/app/main/image/edit.svg';
import { ReactComponent as DeleteIcon } from 'src/app/main/image/delete.svg';
import { ReactComponent as DownloadIcon } from 'src/app/main/image/download.svg';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { connectSocket } from 'src/socket';
import './Waba.css';
import wabaQuery from 'src/app/main/query/waba';
import { selectDashboardData } from 'src/app/store/userSlice';
import {
  handleWabaApiRequest,
  convertTemplateToHtml,
  getDecryptData,
  getParamsId,
  hexToRgb,
} from 'src/app/main/common/common';
import MediaList from './MediaList';
import TemplateList from './TemplateList';
import SelectTemplate from './broadcast/selectTemplate/SelectTemplate';

const WabaInbox = (props) => {
  const location = useLocation();
  const timezone = moment.tz.guess();
  const currentDate = new Date();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const chatScroll = useRef(null);
  const chatContainerRef = useRef(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [pageLoading, setPageLoading] = useState(false);
  const [chatType, setChatType] = useState(0);
  const [whatsAppPhoneNumberId, setWhatsAppPhoneNumberId] = useState('');
  const [isExpandedSearch, setIsExpandedSearch] = useState(false);
  const [openTemplateListDialog, setOpenTemplateListDialog] = useState(false);
  const [openMediaDialog, setOpenMediaDialog] = useState(false);
  const [isExpandedNote, setIsExpandedNote] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [getChatRoom, setGetChatRoom] = useState([]);
  const [liveCount, setLiveCount] = useState();
  const [defaultCount, setDefaultCount] = useState();
  const [getChatNote, setGetChatNote] = useState([]);
  const [getChatJourney, setGetChatJourney] = useState([]);
  const [selectContactMessageList, setSelectContactMessageList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [noteValue, setNoteValue] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [createdAt, setCreatedAt] = useState('');
  const [lastSeen, setLastSeen] = useState('');
  const [lastSeenAt, setLastSeenAt] = useState({});
  const [createdAtF, setCreatedAtF] = useState({});
  const [customEndDate, setCustomEndDate] = useState(
    moment(currentDate).tz(timezone).format('YYYY-MM-DD')
  );
  const [customStartDate, setCustomStartDate] = useState(
    moment(currentDate).tz(timezone).format('YYYY-MM-DD')
  );
  const [customCreatedAtEndDate, setCustomCreatedAtEndDate] = useState(
    moment(currentDate).tz(timezone).format('YYYY-MM-DD')
  );
  const [customCreatedAtStartDate, setCustomCreatedAtStartDate] = useState(
    moment(currentDate).tz(timezone).format('YYYY-MM-DD')
  );
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [startDateCreateAt, setStartDateCreateAt] = useState();
  const [endDateCreateAt, setEndDateCreateAt] = useState();
  const [customDates, setCustomDates] = useState({
    from: null,
    to: null,
  });
  const inputRef = useRef(null);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedOpted, setSelectedOpted] = useState('');
  const durationOptions = [
    { label: 'In 24hr', value: 'in24Hour' },
    { label: 'This week', value: 'thisWeek' },
    { label: 'This month', value: 'thisMonth' },
    { label: 'Custom', value: 'custom' },
  ];
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [editedNote, setEditedNote] = useState(null);
  const [editedContent, setEditedContent] = useState('');
  const [currPage, setCurrPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [prevPage, setPrevPage] = useState(0);
  const [wasLastList, setWasLastList] = useState(false);
  const emojiPickerRef = useRef(null);
  const [timeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const socket = connectSocket();
  const [filterIds, setFilterIds] = useState([]);
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [statusList, setStatusList] = useState('');
  const [filteredData, setFilteredData] = useState(statusList.value);
  const [chatRoomId, setChatRoomId] = useState('');
  const [status, setStatus] = useState('');
  const [preFilteredData, setPreFilteredData] = useState([]);
  const [activeFilter, setActiveFilter] = useState('open');
  const [openJourney, setOpenJourney] = useState(true);
  const [tagValue, setTagValue] = useState('');
  const [isExpandedTag, setIsExpandedTag] = useState(false);
  const [searchLabelName, setSearchLabelName] = useState();
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [selectedLabelsFilter, setSelectedLabelsFilter] = useState([]);
  const [searchLabelNameFilter, setSearchLabelNameFilter] = useState();
  const [tagList, setTagList] = useState([]);
  const [assignedTagList, setAssignedTagList] = useState([]);
  const [tagLoading, setTagLoading] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const fromDashboard = location.state?.contact;
  const [lastSeenFil, setLastSeenFil] = useState({});
  const [openSelectTemplateDialogue, setOpenSelectTemplateDialogue] = useState(false);
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [customLastSeenDates, setCustomLastSeenDates] = useState({
    from: null,
    to: null,
  });

  const whatAppUserDetails = useCallback(async () => {
    try {
      if (selectedContact) {
        const previousScrollHeight = chatContainerRef.current?.scrollHeight;
        const payload = {
          query: wabaQuery.getAllMessage,
          variables: {
            waChatRoomId: selectedContact?.waChatRoomId,
            sortBy: 'cts',
            sortOrder: 'desc',
            pageNumber: currPage,
            pageSize: 20,
          },
        };
        if (currPage === 1) {
          setIsLoading(true);
        }
        const res = await handleWabaApiRequest(payload);
        if (res?.getAllMessage?.status === 200) {
          setHasMore(true);
          if (!res?.getAllMessage?.data?.length) {
            setWasLastList(true);
            setHasMore(false);
            return;
          }
          setPrevPage(currPage);
          setSelectContactMessageList([
            ...(res?.getAllMessage?.data.reverse() || []),
            ...selectContactMessageList,
          ]);
          setTimeout(() => {
            const chatContainer = chatContainerRef.current;
            if (currPage === 1) {
              chatContainer.scrollTop = chatContainer.scrollHeight;
            } else {
              const newScrollHeight = chatContainer.scrollHeight;
              chatContainer.scrollTop += newScrollHeight - previousScrollHeight;
            }
            setIsLoading(false);
          }, 150);
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error('An error occurred:', error);
    }
  }, [selectedContact, currPage, selectContactMessageList]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      setPageLoading(true);
      if (userCurrentBusinessDetails?.id) {
        try {
          const payload = {
            query: wabaQuery.getUserDetail,
            variables: {
              quickReviewBusinessId: userCurrentBusinessDetails?.id,
            },
          };
          const res = await handleWabaApiRequest(payload);
          setPageLoading(false);
          if (res?.getUserDetail?.status === 200) {
            if (res?.getUserDetail?.data && Object.keys(res?.getUserDetail?.data)?.length > 0) {
              const decryptedData = JSON.parse(getDecryptData(res?.getUserDetail?.data));
              setUserDetails(decryptedData);
              setWhatsAppPhoneNumberId(decryptedData?.whatsAppPhoneNumberId);
            } else {
              const paramsId = getParamsId();
              history.push({
                pathname: `quick-chat/waba-home/${paramsId}`,
              });
            }
          }
        } catch (error) {
          setPageLoading(false);
          console.error('An error occurred:', error);
        }
      }
    };
    fetchUserDetails();
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (selectedContact) {
      if (!wasLastList && prevPage !== currPage) {
        whatAppUserDetails();
      }
    }
  }, [
    selectedContact,
    currPage,
    wasLastList,
    prevPage,
    selectContactMessageList,
    whatAppUserDetails,
    hasMore,
  ]);

  const onScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = chatContainerRef.current;
      if (scrollTop <= 4 && !wasLastList && hasMore) {
        setHasMore(false);
        setCurrPage((prevPageNumber) => prevPageNumber + 1);
      }
    }
  };

  const whatAppUserChatRoom = useCallback(
    async (data, tagIds, optIn, lastSeenPara, CreatedAtPara) => {
      if (!userCurrentBusinessDetails?.id) return;
      try {
        const payload = {
          query: wabaQuery.getChatRoomWithLastMessage,
          variables: {
            isBroadcast: chatType === 2,
            isUnRead: chatType === 1,
            search: searchValue,
            isWaiting: activeFilter === 'awaiting',
            status: data,
            userBusinessId: userCurrentBusinessDetails?.id,
            ...(tagIds?.length > 0 && { tagIds }),
            ...(lastSeenPara &&
              Object?.keys(lastSeenPara)?.length > 0 && { lastSeen: lastSeenPara }),
            ...(CreatedAtPara &&
              Object?.keys(CreatedAtPara)?.length > 0 && { createdAt: CreatedAtPara }),
            ...(optIn && { optIn: optIn !== 'no' }),
          },
        };
        const res = await handleWabaApiRequest(payload);
        if (res?.getChatRoomWithLastMessage?.status === 200) {
          setGetChatRoom(res?.getChatRoomWithLastMessage?.data);
          setDefaultCount(res?.getChatRoomWithLastMessage?.liveCount);
          setLiveCount(res?.getChatRoomWithLastMessage?.awaitingCount);
        } else {
          setGetChatRoom([]);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    },
    [activeFilter, chatType, searchValue, userCurrentBusinessDetails?.id]
  );

  useEffect(() => {
    whatAppUserChatRoom();
  }, [whatAppUserChatRoom]);

  const handleGetTagAll = useCallback(async () => {
    try {
      const payload = {
        query: wabaQuery.getWaChatTags,
        pageSize: 1000,
        pageNumber: 1,
      };
      const res = await handleWabaApiRequest(payload);

      if (res?.getWaChatTags?.status === 200) {
        setTagList(res?.getWaChatTags?.data);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, []);

  useEffect(() => {
    handleGetTagAll();
  }, [handleGetTagAll]);

  useEffect(() => {
    if (isExpandedSearch) {
      inputRef.current.focus();
    }
  }, [isExpandedSearch]);

  useEffect(() => {
    if (!socket) return undefined;
    const handleConnect = () => {
      setIsConnected(true);
    };

    const handleReceivedMessage = (message) => {
      if (message?.payload?.eventType === 'MESSAGES_STATUS') {
        const messageId = message?.payload?.data?.messageId;
        setSelectContactMessageList((prevList) =>
          prevList.map((msg) =>
            msg.messageId === messageId ? { ...msg, status: message?.payload?.data?.status } : msg
          )
        );
      }

      if (message?.payload?.eventType === 'MESSAGE_FROM_CLIENT') {
        if (message?.payload?.data?.waChatRoomId === selectedContact?.waChatRoomId) {
          setSelectContactMessageList((prevMessages) => {
            if (message?.payload?.data) {
              const emojiData = message?.payload?.data?.reactOnMsg;
              if (message?.payload?.data?.type === 'reaction') {
                return prevMessages.map((msg) =>
                  msg.reactOnMsg?.message_id === emojiData?.message_id ||
                  msg?.messageId === message?.payload?.data?.reactOnMsg?.message_id
                    ? { ...msg, reactOnMsg: emojiData }
                    : msg
                );
              }
              return [
                ...prevMessages,
                {
                  ...message.payload.data,
                  cts: Number(message.payload.data.timestamp) * 1000,
                },
              ];
            }
            return prevMessages;
          });
        }
        setGetChatRoom((prevContacts) => {
          const contactExists = prevContacts?.some(
            (contact) => contact?.waChatRoomId === message?.payload?.data?.waChatRoomId
          );

          let updatedContacts;

          if (!contactExists) {
            updatedContacts = [
              {
                ...message?.payload?.data,
                cts: Number(message?.payload?.data?.timestamp) * 1000,
                unRead: 1,
              },
              ...prevContacts,
            ];
          } else {
            updatedContacts = prevContacts.map((contact) =>
              contact.waChatRoomId === message?.payload?.data?.waChatRoomId
                ? {
                    ...contact,
                    ...message?.payload?.data,
                    cts: Number(message?.payload?.data?.timestamp) * 1000,
                    unRead: 1,
                  }
                : contact
            );
          }
          return updatedContacts.sort((a, b) => b.cts - a.cts);
        });
        if (message?.payload?.data?.type !== 'reaction') {
          setTimeout(() => {
            const chatContainer = chatContainerRef.current;
            if (chatContainer) {
              chatContainer.scrollTop = chatContainer.scrollHeight;
            }
          }, 0);
        }
      }
    };
    const handleDisconnect = () => {
      setIsConnected(false);
    };

    socket.on('receivedMessage', handleReceivedMessage);
    socket.on('connect', handleConnect);
    socket.on('disconnect', handleDisconnect);

    return () => {
      socket.off('connect', handleConnect);
      socket.off('receivedMessage', handleReceivedMessage);
      socket.off('disconnect', handleDisconnect);
    };
  }, [selectContactMessageList, selectedContact, socket]);

  const handleClickOutside = (event) => {
    if (emojiPickerRef?.current && !emojiPickerRef?.current?.contains(event.target)) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    if (showEmojiPicker) {
      document?.addEventListener('mousedown', handleClickOutside);
    } else {
      document?.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document?.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showEmojiPicker]);

  const handleGetNote = useCallback(async () => {
    try {
      const payload = {
        query: wabaQuery.getChatRoomNote,
        variables: {
          chatRoomId: selectedContact?.waChatRoomId,
        },
      };
      const res = await handleWabaApiRequest(payload);
      if (res?.getChatRoomNote?.status === 200) {
        setGetChatNote(res?.getChatRoomNote?.data);
        setIsExpandedNote(false);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [selectedContact?.waChatRoomId]);

  const handleGetJourney = useCallback(async () => {
    try {
      const payloadJourney = {
        query: wabaQuery.getCustomerJourney,
        variables: {
          waChatRoomId: selectedContact?.waChatRoomId,
          pageSize: 1000,
          pageNumber: 1,
        },
      };
      const resJourney = await handleWabaApiRequest(payloadJourney);
      if (resJourney?.getCustomerJourney?.status === 200) {
        setGetChatJourney(resJourney?.getCustomerJourney?.data);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [selectedContact?.waChatRoomId]);

  const handleGetTag = useCallback(async () => {
    try {
      const payload = {
        query: wabaQuery.getWaChatTags,
      };
      const res = await handleWabaApiRequest(payload);

      if (res?.getWaChatTags?.status === 200) {
        setTagList(res?.getWaChatTags?.data);
      }
      const payloadTag = {
        query: wabaQuery.getAttachedWaChatTags,
        variables: {
          waChatRoomId: selectedContact?.waChatRoomId,
        },
      };
      const resTag = await handleWabaApiRequest(payloadTag);
      if (resTag?.getAttachedWaChatTags?.status === 200) {
        setAssignedTagList(resTag?.getAttachedWaChatTags?.data);
        setSelectedLabels(
          resTag?.getAttachedWaChatTags?.data?.length > 0
            ? resTag?.getAttachedWaChatTags?.data
                ?.filter((label) => label?.id)
                .map((label) => label?.id)
            : []
        );
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [selectedContact?.waChatRoomId]);

  const handleDownload = (url, fileName) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = fileName || 'download';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    try {
      if (selectedContact) {
        handleGetNote();
        handleGetJourney();
        handleGetTag();
        handleGetTagAll();
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [selectedContact, handleGetNote, handleGetJourney, handleGetTag, handleGetTagAll]);

  const handleContactClick = (contact) => {
    setSelectContactMessageList([]);
    setSelectedContact(contact);
    setGetChatRoom((prevList) =>
      prevList.map((roomData) =>
        roomData.waChatRoomId === contact?.waChatRoomId ? { ...roomData, unRead: 0 } : roomData
      )
    );
    setCurrPage(1);
    setPrevPage(0);
    setHasMore(false);
  };

  useEffect(() => {
    if (fromDashboard) {
      handleContactClick(fromDashboard);
      setTimeout(() => {
        if (chatContainerRef.current) {
          chatContainerRef.current.scrollTo({
            top: chatContainerRef.current.scrollHeight,
            behavior: 'smooth',
          });
        }
      }, 0);
    }
  }, [fromDashboard]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage('text');
    }
  };
  const ChatLoader = () => {
    return (
      <div className="absolute inset-0 flex items-center justify-center bg-white">
        <div className="w-8 h-8 border-4 border-gray-300 border-t-blue-500 rounded-full animate-spin" />
      </div>
    );
  };
  const LocationMessage = ({ latitude, longitude, name, address }) => {
    const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    const mapEmbedUrl = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${latitude},${longitude}`;

    return (
      <div className=" bg-gray-100 rounded-lg relative">
        <iframe
          src={mapEmbedUrl}
          title={`Location: ${name}`}
          className="w-288 h-150 rounded-lg"
          allowFullScreen
        />
        <div className="mt-2 text-sm">
          <p className="font-semibold">{name}</p>
          <p className="text-gray-600">{address}</p>
          <a
            href={googleMapsUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            Open in Maps
          </a>
        </div>
      </div>
    );
  };

  const getBodyText = (editorStates) => {
    const contentState = editorStates.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    let formattedText = '';
    const bodyVariables = new Set();

    rawContent.blocks.forEach((block) => {
      const blockText = block.text;
      const styles = new Map();

      const placeholders = blockText.match(/\[.*?\]/g);
      if (placeholders) {
        placeholders.forEach((placeholder) => {
          bodyVariables.add(placeholder.replace(/[[]]/g, ''));
        });
      }

      block.inlineStyleRanges.forEach((styleRange) => {
        const { style, offset, length } = styleRange;
        const key = `${offset}-${offset + length}`;
        const existing = styles.get(key) || [];
        styles.set(key, [...existing, style]);
      });

      let lastIndex = 0;
      Array.from(styles.entries()).forEach(([range, stylesW]) => {
        const [start, end] = range.split('-').map(Number);
        const textToFormat = blockText.slice(start, end);
        let formattedSegment = textToFormat;

        stylesW.forEach((style) => {
          if (style === 'BOLD') {
            formattedSegment = `*${formattedSegment}*`;
          } else if (style === 'ITALIC') {
            formattedSegment = `_${formattedSegment}_`;
          } else if (style === 'STRIKETHROUGH') {
            formattedSegment = `~${formattedSegment}~`;
          } else if (style === 'CODE') {
            formattedSegment = `\`\`\`${formattedSegment}\`\`\``;
          } else if (style === 'UNDERLINE') {
            formattedSegment = `__${formattedSegment}__`;
          }
        });

        formattedText += blockText.slice(lastIndex, start) + formattedSegment;
        lastIndex = end;
      });

      formattedText += `${blockText.slice(lastIndex)}\n`;
    });

    return { formattedText, bodyVariables: Array.from(bodyVariables) };
  };

  const handleSendMessage = async (value, template) => {
    const { formattedText } = getBodyText(editorState);
    if (
      selectedMedia?.length === 0 &&
      value === 'text' &&
      (!formattedText || formattedText?.trim() === '')
    ) {
      return;
    }
    const tempMessageId = `${Date.now()}${selectedContact?.id}`;
    let newMessage = {};
    let updatedTemplateData;
    if (value === 'template') {
      updatedTemplateData = { ...template };

      if (typeof updatedTemplateData?.dynamicData === 'string') {
        try {
          updatedTemplateData.dynamicData = JSON.parse(updatedTemplateData?.dynamicData);
        } catch (error) {
          console.error('Error parsing dynamicData:', error);
          return;
        }
      }

      updatedTemplateData.dynamicData = JSON?.stringify(updatedTemplateData?.dynamicData);
      const tempData = {
        name: template?.templateData?.name,
        category: template?.templateData?.category,
        language: template?.templateData?.language,
        components: template?.templateData?.components,
      };
      newMessage = {
        type: 'template',
        templateId: template?.id,
        cts: moment.utc(),
        tempMessageId,
        status: 'sent',
        templateData: JSON.stringify(tempData),
        dynamicData: updatedTemplateData?.dynamicData,
      };
    } else {
      newMessage = {
        type: 'text',
        message: formattedText,
        cts: moment?.utc(),
        tempMessageId,
        status: 'sent',
      };
    }

    const newMediaMessage = [];
    if (selectedMedia?.length > 0) {
      selectedMedia.forEach((media, i) => {
        const mimeType = media?.fileData?.mimeType;
        const fileUrl = media?.awsUrl;

        const newMessages = {
          ...newMessage,
          type: mimeType.startsWith('image/')
            ? 'image'
            : mimeType.startsWith('video/')
            ? 'video'
            : 'document',
          whatsAppMedia: fileUrl,
          ...(i === 0 &&
            mimeType.startsWith('image/') && {
              imageMsgId: JSON.stringify({ caption: formattedText }),
            }),
          ...(i === 0 &&
            mimeType.startsWith('video/') && { video: JSON.stringify({ caption: formattedText }) }),
        };

        newMediaMessage.push(newMessages);
      });
      setSelectContactMessageList((prevList) => [...prevList, ...newMediaMessage]);
    } else {
      setSelectContactMessageList((prevList) => [...prevList, newMessage]);
    }
    chatScroll?.current?.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => {
      const chatContainer = chatContainerRef.current;
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }, 0);

    try {
      const payload = {
        query: wabaQuery.sendMessages,
        variables: {
          name: selectedContact?.name,
          phoneNumber: selectedContact?.phoneNumber,
          tempMessageId,
          type: value === 'template' ? 'template' : 'text',
          waBusinessPhoneNumberId: whatsAppPhoneNumberId,
        },
      };
      if (value === 'template' && template) {
        payload.variables.templateId = template?.id;
        payload.variables.templateComponents = updatedTemplateData?.dynamicData;
        payload.variables.isNewDynamicTemplate = true;
      }
      if (value === 'text') {
        payload.variables.message = formattedText;
      }
      setSelectedMedia([]);
      setEditorState(EditorState.createEmpty());
      if (selectedMedia?.length > 0) {
        const mediaRequests = selectedMedia.map((media, i) => {
          const mimeType = media?.fileData?.mimeType;
          const fileUrl = media?.awsUrl;

          if (mimeType) {
            const payloadBase = {
              ...payload,
              variables: {
                ...payload.variables,
                type: mimeType.startsWith('image/')
                  ? 'image'
                  : mimeType.startsWith('video/')
                  ? 'video'
                  : 'document',
                link: fileUrl,
                mediaName: media?.fileData?.fileName,
                caption: i === 0 ? formattedText : '',
              },
            };

            delete payloadBase.variables.message;
            delete payloadBase.variables.name;
            delete payloadBase.variables.tempMessageId;

            return handleWabaApiRequest(payloadBase);
          }
          return null;
        });
        const responses = await Promise.all(mediaRequests.filter(Boolean));
        responses.forEach((res) => {
          if (res?.sendMessages?.status === 200) {
            const updatedTempMessageId = res?.sendMessages?.data?.tempMessageId;
            const messageId = res?.sendMessages?.data?.messageId;

            setSelectContactMessageList((prevList) =>
              prevList.map((msg) =>
                msg.tempMessageId === updatedTempMessageId ? { ...msg, messageId } : msg
              )
            );
          }
        });
      } else {
        const res = await handleWabaApiRequest(payload);
        if (res.sendMessages?.status === 200) {
          const updatedTempMessageId = res?.sendMessages?.data?.tempMessageId;
          const messageId = res?.sendMessages?.data?.messageId;

          setSelectContactMessageList((prevList) =>
            prevList.map((msg) =>
              msg.tempMessageId === updatedTempMessageId ? { ...msg, messageId } : msg
            )
          );
        }
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const createNote = async () => {
    setLoading(true);
    try {
      const payload = {
        query: wabaQuery.createChatRoomNote,
        variables: {
          chatRoomId: selectedContact?.waChatRoomId,
          note: noteValue,
        },
      };
      const res = await handleWabaApiRequest(payload);
      setLoading(false);
      if (res?.createChatRoomNote?.status === 200) {
        setNoteValue('');
        handleGetNote();
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const deleteNote = async (id) => {
    setLoading(true);
    try {
      const payload = {
        query: wabaQuery.deleteChatRoomNote,
        variables: {
          noteId: id,
        },
      };
      const res = await handleWabaApiRequest(payload);
      setLoading(false);
      if (res?.deleteChatRoomNote?.status === 200) {
        enqueueSnackbar(res?.deleteChatRoomNote?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        handleGetNote();
      } else {
        enqueueSnackbar(res?.deleteChatRoomNote?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const addTagFunction = async () => {
    setTagLoading(true);
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    try {
      const payload = {
        query: wabaQuery.createAndUpdateWaChatTag,
        variables: {
          waChatRoomId: selectedContact?.waChatRoomId,
          tagName: tagValue,
          customerJourney: false,
          firstMessage: false,
          colorCode: randomColor,
        },
      };
      const res = await handleWabaApiRequest(payload);
      setTagLoading(false);
      if (res?.createAndUpdateWaChatTag?.status === 200) {
        enqueueSnackbar(res?.createAndUpdateWaChatTag?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setTagValue('');
        handleGetTag();
        handleGetTagAll();
        setIsExpandedTag(false);
      } else {
        enqueueSnackbar(res?.createAndUpdateWaChatTag?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleEditClick = (note) => {
    setEditedNote(note?.id);
    setEditedContent(note?.note);
  };

  const handleTemplateSelect = async (template) => {
    if (template) {
      setSelectedTemplate(template);

      await handleSendMessage('template', template);
      setOpenTemplateListDialog(false);
    }
  };

  const handleMediaSelected = async (media) => {
    if (media) {
      setSelectedMedia(media);
      setOpenMediaDialog(false);
    }
  };

  const handleSaveClick = async (note) => {
    try {
      if (note?.note !== editedContent) {
        const payload = {
          query: wabaQuery.editChatRoomNote,
          variables: {
            noteId: note?.id,
            note: editedContent,
          },
        };
        const res = await handleWabaApiRequest(payload);
        setLoading(false);
        if (res?.editChatRoomNote?.status === 200) {
          enqueueSnackbar(res?.editChatRoomNote?.message, {
            variant: 'success',
            autoHideDuration: 3000,
          });
          handleGetNote();
          setEditedNote(null);
          setEditedContent('');
        }
      } else {
        setEditedNote(null);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const formattedDate = (timestamp) => {
    const date = moment.unix(timestamp / 1000);
    const today = moment();
    const yesterday = moment().subtract(1, 'days');

    if (date?.isSame(today, 'day')) {
      return date?.format('h:mm A');
    }
    if (date?.isSame(yesterday, 'day')) {
      return 'Yesterday';
    }
    return date.format('MMM DD, YYYY');
  };

  const groupMessagesByDate = (messages) => {
    return messages?.reduce((groups, message) => {
      const date = moment.unix(message.cts / 1000).format('MMM DD, YYYY');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date]?.push(message);
      return groups;
    }, {});
  };

  useEffect(() => {
    try {
      const getStatusList = async () => {
        const payload = {
          query: wabaQuery.getChatRoomResolveStatusList,
        };
        const result = await handleWabaApiRequest(payload);
        if (result?.getChatRoomResolveStatusList?.status === 200) {
          setStatusList(result?.getChatRoomResolveStatusList?.data);
        }
      };
      getStatusList();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, []);

  useEffect(() => {
    if (!selectedContact?.waChatRoomId) {
      return;
    }
    const fetchUserDetails = async () => {
      try {
        const payload = {
          query: wabaQuery.getWaUserDetailsOnChatRoomId,
          variables: {
            waChatRoomId: selectedContact?.waChatRoomId,
          },
        };

        const result = await handleWabaApiRequest(payload);

        if (result?.getWaUserDetailsOnChatRoomId?.status === 200) {
          setChatRoomId(result?.getWaUserDetailsOnChatRoomId);
        } else {
          console.error('Failed to fetch user details:', result);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      } finally {
        setPageLoading(false);
      }
    };

    fetchUserDetails();
  }, [selectedContact?.waChatRoomId]);

  const updateStatus = useCallback(
    async (update) => {
      try {
        const payload = {
          query: wabaQuery.updateChatRoomResolveStatus,
          variables: {
            waChatRoomId: selectedContact?.waChatRoomId,
            status: update,
          },
        };
        const result = await handleWabaApiRequest(payload);
        if (result?.updateChatRoomResolveStatus?.status === 200) {
          whatAppUserChatRoom();
          enqueueSnackbar(result?.updateChatRoomResolveStatus?.message, {
            variant: 'success',
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(result?.updateQuickChatResolveStatus?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    },
    [enqueueSnackbar, selectedContact?.waChatRoomId, whatAppUserChatRoom]
  );

  useEffect(() => {
    if (Array.isArray(statusList)) {
      const selectedStatus = statusList?.find(
        (state) => state?.value?.toLowerCase() === selectedContact?.chatResolveStatus?.toLowerCase()
      );
      if (selectedStatus) {
        setStatus(selectedStatus?.value);
      }
    }
  }, [selectedContact?.chatResolveStatus, statusList]);

  const handleSelectFilter = (selectedItem) => {
    const updatedFilterIds = filterIds?.some((filterId) => filterId?.key === selectedItem?.key)
      ? filterIds.filter((filterId) => filterId?.key !== selectedItem?.key)
      : [...filterIds, selectedItem];
    setFilterIds(updatedFilterIds);
    const selectedKeys = updatedFilterIds?.map((item) => item?.key);
    setFilteredData(selectedKeys);
  };

  const applyFilters = () => {
    setPreFilteredData(filterIds);
    setShowFilterOption(false);
    whatAppUserChatRoom(filteredData);
  };

  const handleCancel = () => {
    setFilterIds(preFilteredData);
    setShowFilterOption(false);
  };

  const openFilterOption = () => {
    setFilterIds(preFilteredData);
  };

  const changeReviewPlatForm = (value) => {
    setStatus(value);
    const selectedStatus = statusList?.find((state) => state?.value === value);

    if (selectedStatus) {
      updateStatus(selectedStatus.key);
    }
  };

  const searchHandle = (event) => {
    setSearchValue(event?.target?.value);
  };
  if (pageLoading) {
    return <FuseLoading />;
  }

  const replacePlaceholders = (text, replacement) => {
    if (!text || !replacement) {
      return text;
    }
    const parseData = JSON.parse(replacement);
    const replaceValue = text.replace(/\{\{(\d+)\}\}/g, (match, index) => {
      const variable = parseData?.body[Number(index) - 1];
      return variable && variable.value ? variable.value : match;
    });
    return replaceValue;
  };

  const handleKeyCommand = (command, editorStates) => {
    const newState = RichUtils.handleKeyCommand(editorStates, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const onBoldClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
  };

  const onItalicClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
  };

  const onStrikethroughClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'STRIKETHROUGH'));
  };

  const onUnderlineClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
  };

  const convertContentToHTML = () => {
    const contentState = editorState.getCurrentContent();
    return stateToHTML(contentState);
  };

  const addEmoji = (emojiData, event) => {
    if (!emojiData || !emojiData.emoji) return;

    const { emoji } = emojiData;
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    const newContentState = Modifier.insertText(
      contentState,
      selectionState,
      emoji,
      editorState.getCurrentInlineStyle()
    );

    let newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');

    newEditorState = EditorState.forceSelection(
      newEditorState,
      newContentState.getSelectionAfter()
    );

    setEditorState(newEditorState);
    setShowEmojiPicker(false);
  };

  const handleEditorChange = (state) => {
    const currentContent = state.getCurrentContent();
    const plainText = currentContent.getPlainText('');
    const length = plainText?.length;

    if (length <= 750) {
      setEditorState(state);
    } else {
      const trimmedContent = Modifier.removeRange(currentContent, state.getSelection(), 'backward');
      setEditorState(EditorState.push(state, trimmedContent, 'remove-range'));
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleDateChange = (key, value, from) => {
    if (from === 'lastSeen') {
      setCustomLastSeenDates((prev) => ({ ...prev, [key]: value }));
      setCustomDates((prev) => ({ ...prev, [key]: value }));
    }
  };

  // const handleSaveClick = async (note) => {
  //   try {
  //     if (note?.note !== editedContent) {
  //       const payload = {
  //         query: wabaQuery.editChatRoomNote,
  //         variables: {
  //           noteId: note?.id,
  //           note: editedContent,
  //         },
  //       };
  //       const res = await handleWabaApiRequest(payload);
  //       setLoading(false);
  //       if (res?.editChatRoomNote?.status === 200) {
  //         enqueueSnackbar(res?.editChatRoomNote?.message, {
  //           variant: 'success',
  //           autoHideDuration: 3000,
  //         });
  //         handleGetNote();
  //         setEditedNote(null);
  //         setEditedContent('');
  //       }
  //     } else {
  //       setEditedNote(null);
  //     }
  //   } catch (error) {
  //     console.error('An error occurred:', error);
  //   }
  // };

  const handleTagChange = async (event) => {
    try {
      const selectedTagId = event; // The tag being selected/unselected
      let updatedLabels = [...selectedLabels];
      let task = 'ADD';

      if (updatedLabels.includes(selectedTagId)) {
        // Remove the tag if it's already selected
        updatedLabels = updatedLabels.filter((id) => id !== selectedTagId);
        task = 'REMOVE';
      } else {
        // Add the tag if it's not selected
        updatedLabels.push(selectedTagId);
      }

      // API Payload
      const payload = {
        query: wabaQuery.attachOrRemoveWaChatTag,
        variables: {
          waChatRoomId: selectedContact?.waChatRoomId,
          task,
          tagIds: selectedTagId,
        },
      };

      const result = await handleWabaApiRequest(payload);

      if (result?.attachOrRemoveWaChatTag?.status === 200) {
        handleGetTag();
        setSelectedLabels(updatedLabels);
      }
      // setOpen(false);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleDeleteTag = async (tagId) => {
    try {
      const payload = {
        query: wabaQuery.attachOrRemoveWaChatTag,
        variables: {
          waChatRoomId: selectedContact?.waChatRoomId,
          task: 'REMOVE',
          tagIds: [tagId],
        },
      };

      const result = await handleWabaApiRequest(payload);

      if (result?.attachOrRemoveWaChatTag?.status === 200) {
        handleGetTag();
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const removeMedia = (mediaData) => {
    setSelectedMedia(selectedMedia.filter((item) => item?.id !== mediaData?.id));
  };
  const formatMessage = (text) => {
    if (!text) return '';

    return text
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // **bold**
      .replace(/\*(.*?)\*/g, '<strong>$1</strong>') // *bold* (single asterisk)
      .replace(/__(.*?)__/g, '<u>$1</u>') // __underline__
      .replace(/_(.*?)_/g, '<em>$1</em>') // _italic_
      .replace(/~(.*?)~/g, '<del>$1</del>') // ~strikethrough~
      .replace(/`(.*?)`/g, '<code>$1</code>'); // `code`
  };

  const handleLastSeenFilter = (e) => {
    setLastSeen(e.target.value);
    if (e?.target?.value === 'in24Hour') {
      setLastSeenAt({
        timeKey: 'in24Hour',
      });
    }
    if (e?.target?.value === 'thisWeek') {
      setLastSeenAt({
        timeKey: 'thisWeek',
      });
    }
    if (e?.target?.value === 'thisMonth') {
      setLastSeenAt({
        timeKey: 'thisMonth',
      });
    }

    if (e?.target?.value === 'custom') {
      setLastSeenAt({
        timeKey: 'custom',
        customDate: {
          customStartDate,
          customEndDate,
        },
      });
    }
  };

  const handleCreatedAt = (e) => {
    setCreatedAt(e?.target?.value);
    if (e?.target?.value === 'in24Hour') {
      setCreatedAtF({
        timeKey: 'in24Hour',
      });
    }
    if (e?.target?.value === 'thisWeek') {
      setCreatedAtF({
        timeKey: 'thisWeek',
      });
    }
    if (e?.target?.value === 'thisMonth') {
      setCreatedAtF({
        timeKey: 'thisMonth',
      });
    }

    if (e?.target?.value === 'custom') {
      setCreatedAtF({
        timeKey: 'custom',
        customDate: {
          customStartDate: customCreatedAtStartDate,
          customEndDate: customCreatedAtEndDate,
        },
      });
    }
  };

  return (
    <>
      <div className="lg:p-24 p-16">
        <div className="flex w-full items-center justify-between lg:mb-20 mb-16">
          <div className="flex items-center justify-between">
            <Typography
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
            >
              {t('navigation.wabaInbox')}
            </Typography>
          </div>
          <span>
            {' '}
            {userDetails?.displayPhoneNumber
              ? `Connected Number: ${userDetails?.displayPhoneNumber}`
              : ''}
          </span>
        </div>
        <div className="bg-white rounded-md border-1 border-solid border-grey-300">
          {userDetails?.status !== 'PENDING' ? (
            <div className="sm:flex sm:h-screen chat-height">
              {/* Left Sidebar */}
              <div className="w-full sm:max-h-screen max-h-320 md:w-1/4 sm:overflow-auto overflow-scroll mb-48 sm:mb-0">
                <div>
                  <div className="px-16 py-16">
                    <div className="flex mb-16">
                      {/* Open Chat Button */}
                      <Button
                        onClick={() => setActiveFilter('open')}
                        className={`flex items-center px-4 py-2 rounded-l-md rounded-r-none w-1/2 hover:bg-[#323952] hover:text-white ${
                          activeFilter === 'open'
                            ? 'bg-[#323952] text-white'
                            : 'bg-gray-A500 text-black'
                        }`}
                      >
                        {t('waba.inbox.openChat')}
                        <span className="inline-block rounded-full text-14 font-medium px-5 py-4 leading-none bg-quick-chat text-white ml-8">
                          {defaultCount}
                        </span>
                      </Button>

                      {/* Awaiting Button */}
                      <Button
                        onClick={() => setActiveFilter('awaiting')}
                        className={`flex items-center px-4 py-2 rounded-r-md rounded-l-none w-1/2 hover:bg-[#323952] hover:text-white ${
                          activeFilter === 'awaiting'
                            ? 'bg-[#323952] text-white'
                            : 'bg-gray-A500 text-black'
                        }`}
                      >
                        {t('waba.inbox.awaiting')}
                        <span className="inline-block rounded-full text-14 font-medium px-5 py-4 leading-none bg-quick-chat text-white ml-8">
                          {liveCount}
                        </span>
                      </Button>
                    </div>
                    <div>
                      {/* <Select
                        className="inline-block border-none text-black capitalize min-h-0 h-auto p-0 font-semibold text-16"
                        id="msgType"
                        value={chatType}
                        size="small"
                        displayEmpty
                        IconComponent={KeyboardArrowDownIcon}
                        sx={{
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                          },
                          '& .MuiSelect-select': {
                            padding: '0px 0',
                            display: 'flex',
                            alignItems: 'center',
                            border: 'none',
                          },
                        }}
                        onChange={(e) => setChatType(e?.target?.value)}
                      >
                        <MenuItem value={0} className="capitalize">
                          {t('waba.inbox.allChat')}
                        </MenuItem>
                        <MenuItem value={1} className="capitalize">
                          {t('waba.inbox.unreadChat')}
                        </MenuItem>
                        <MenuItem value={2} className="capitalize">
                          {t('waba.inbox.broadcast')}
                        </MenuItem>
                      </Select> */}
                      <div className="flex items-center justify-between gap-5 ">
                        <div className="relative w-full">
                          <input
                            ref={inputRef}
                            type="text"
                            className="px-10 py-2 border rounded-md w-full h-36 border-none bg-gray-A500"
                            placeholder={t('waba.inbox.searchPlaceholder')}
                            onChange={(e) => {
                              setSearchValue(e.target.value);
                              handleCancel();
                            }}
                            value={searchValue}
                          />

                          {searchValue ? (
                            <Icon
                              aria-hidden
                              className="absolute right-10 top-10 text-16 cursor-pointer"
                              onClick={() => {
                                setSearchValue('');
                                handleCancel();
                              }}
                            >
                              close
                            </Icon>
                          ) : (
                            <Icon
                              aria-hidden
                              className="absolute right-10 top-10 text-16 cursor-pointer"
                              onClick={() => {
                                setIsExpandedSearch(true);
                                setShowFilterOption(false);
                              }}
                            >
                              search
                            </Icon>
                          )}
                        </div>
                        <div className="flex gap-3">
                          {/* <Icon
                            className="cursor-pointer justify-center items-center flex bg-gray-A500 rounded-md hover:bg-grey px-16 py-10 h-36 "
                            onClick={() => {
                              const id = getParamsId();
                              if (id) {
                                history.push(`quick-chat/waba-contacts/${id}`, {
                                  openDialog: true,
                                });
                              } else {
                                history.push('quick-chat/waba-contacts', {
                                  openDialog: true,
                                });
                              }
                            }}
                            aria-hidden
                          >
                            add
                          </Icon> */}

                          <div className="relative flex items-center justify-center ">
                            <Button
                              className={` bg-gray-A500 ${
                                filterIds?.length > 0 ? 'text-quick-review' : 'border-gray-500'
                              } rounded-md w-28 min-w-28 p-16 h-24 min-h-24 hover:bg-grey`}
                              onClick={() => setIsPopupOpen(true)}
                              aria-hidden="true"
                            >
                              <FilterListIcon
                                className={`${
                                  filterIds?.length > 0 ? 'text-quick-review' : 'text-gray-800'
                                } opacity-100`}
                              />
                            </Button>
                            {filterIds?.length > 0 && (
                              <Typography className="absolute -right-6 -top-8 text-12 font-semibold bg-quick-review text-white rounded-full flex items-center justify-center h-16 w-16">
                                {filterIds?.length}
                              </Typography>
                            )}
                            <div>
                              {/* {showFilterOption && (
                                <div className="absolute z-10 top-40 right-0 bg-white max-w-192 rounded-md shadow-3">
                                  {statusList?.length > 0 &&
                                    statusList?.map((item, i) => {
                                      return (
                                        <div key={i} className="flex items-center mt-16 px-7">
                                          <Checkbox
                                            className="p-0 text-grey-300"
                                            checked={filterIds.some(
                                              (filterId) => filterId?.key === item?.key
                                            )}
                                            onChange={() => handleSelectFilter(item)}
                                          />
                                          <Typography className="text-14 text-grey-800 ml-6 font-medium">
                                            {item?.value}
                                          </Typography>
                                        </div>
                                      );
                                    })}
                                  <div className="flex border-t-2 py-10 px-7 mt-10 gap-12 border-grey-200">
                                    <Button
                                      className="rounded-md py-0 px-16"
                                      size="small"
                                      variant="outlined"
                                      color="quickChat"
                                      type="button"
                                      onClick={() => handleCancel()}
                                    >
                                      {t('common.cancel')}
                                    </Button>
                                    <Button
                                      className="rounded-md py-0 px-24"
                                      size="small"
                                      variant="contained"
                                      color="quickChat"
                                      type="button"
                                      onClick={applyFilters}
                                    >
                                      {t('quickChat.apply')}
                                    </Button>
                                  </div>
                                </div>
                              )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-10 max-h-[499px] sm:max-h-screen pt-0">
                  {getChatRoom && getChatRoom?.length > 0 ? (
                    <>
                      {getChatRoom?.map((contact, index) => (
                        <div
                          key={index}
                          className={`mb-10 cursor-pointer hover:bg-quick-chat-50 border-1 border-solid  rounded p-10 ${
                            selectedContact?.id === contact?.id
                              ? 'bg-quick-chat-50 border-quick-chat-100 border-l-3 border-l-solid border-l-quick-chat'
                              : 'bg-white border-grey-300 pl-12'
                          }`}
                          aria-hidden
                          onClick={() => handleContactClick(contact)}
                        >
                          <div className="flex items-center">
                            <img
                              src="/assets/images/business/profile.png"
                              className="img-fluid w-full sm:max-w-48 max-w-36 mr-10"
                              alt="..."
                            />

                            <div className="overflow-hidden w-full">
                              <div className="flex items-center justify-between">
                                <h3 className="text-14 sm:text-16 font-semibold w-full overflow-hidden whitespace-nowrap text-ellipsis">
                                  {contact?.name || contact?.phoneNumber || contact?.from}
                                </h3>
                                <span className="text-12 font-semibold w-112 justify-end items-center text-right">
                                  {formattedDate(contact?.cts) ||
                                    formattedDate(Number(contact?.timestamp) * 1000)}
                                </span>
                                {/* <div
                                  className={`text-12 sm:text-14 font-semibold w-full max-w-160 flex  justify-end items-center text-right gap-4 capitalize `}
                                >
                                  <span
                                    className={`inline-block w-7 h-7 rounded-full ${
                                      contact?.chatResolveStatus === 'Resolved by human' ||
                                      contact?.chatResolveStatus === 'Resolved by bot'
                                        ? 'bg-darkgreen'
                                        : contact?.chatResolveStatus === 'Unresolved by bot' ||
                                          contact?.chatResolveStatus === 'Unresolved by human'
                                        ? 'bg-red'
                                        : ''
                                    }`}
                                  />
                                  <span
                                    className={`inline-block  ${
                                      contact?.chatResolveStatus === 'Unresolved by bot' ||
                                      contact?.chatResolveStatus === 'Unresolved by human'
                                        ? 'text-red'
                                        : contact?.chatResolveStatus === 'Resolved by human' ||
                                          contact?.chatResolveStatus === 'Resolved by bot'
                                        ? 'text-darkgreen'
                                        : ''
                                    }`}
                                  >
                                    {contact?.chatResolveStatus}
                                  </span>
                                </div> */}
                              </div>
                              <div className="flex items-center justify-between">
                                <span className="font-medium text-14 whitespace-nowrap overflow-hidden text-ellipsis mt-6 mb-6 text-grey-600">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: formatMessage(contact?.message),
                                    }}
                                  />
                                </span>
                                <span
                                  className={`text-right bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300 ${
                                    contact?.unRead === 0 || !contact?.unRead ? 'hidden' : ''
                                  }`}
                                >
                                  New
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {searchValue ? (
                        <Typography className="text-center font-medium">
                          {t('waba.inbox.noResult')}
                        </Typography>
                      ) : (
                        <Typography className="text-center font-medium">
                          {t('waba.inbox.noContact')}
                        </Typography>
                      )}
                    </>
                  )}
                </div>
              </div>

              {/* Middle Section */}
              <div className="flex flex-col w-full md:w-3/4 md:flex-row">
                <div
                  className={`flex-grow relative md:h-auto h-586 sm:mt-0 sm:bg-inherit bg-white md:${
                    selectedContact ? 'w-[70%]' : 'w-4/5'
                  } border-x-1 border-solid border-grey-300`}
                >
                  {selectedContact ? (
                    <>
                      <div className="border-b-1 border-solid h-80 sm:h-64 grid align-middle w-full px-16">
                        <div className="flex items-center">
                          <img
                            src="/assets/images/business/profile.png"
                            className="img-fluid w-full sm:max-w-48 max-w-36 mr-10"
                            alt="..."
                          />
                          <div className="flex sm:items-center items-start gap-5 flex-col sm:flex-row justify-start sm:justify-between w-full">
                            <h3 className="text-16 font-bold mb-4 overflow-hidden whitespace-nowrap text-ellipsis max-w-480 w-full">
                              {selectedContact?.name || selectedContact?.phoneNumber}
                            </h3>
                            {/* <div className="flex gap-20 w-full justify-end">
                              {' '}
                              <Select
                                className="rounded-md h-40 bg-gray-A500 text-16 w-full sm:w-256 leading-5  capitalize"
                                variant="filled"
                                sx={{
                                  '& .MuiFilledInput-input': {
                                    pt: '8px',
                                    zIndex: '10',
                                  },
                                }}
                                value={status}
                                fullWidth
                                onChange={(e) => changeReviewPlatForm(e?.target?.value)}
                                IconComponent={() => (
                                  <KeyboardArrowDownIcon className="text-28 align-middle absolute right-6 text-black ml-2" />
                                )}
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      borderRadius: '2px',
                                      marginTop: '1px',
                                      boxShadow: 'none',

                                      '& .MuiList-root': {
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                      },
                                    },
                                  },
                                }}
                              >
                                {statusList &&
                                  statusList
                                    ?.slice()
                                    .sort((a, b) => b.defaultReview - a.defaultReview)
                                    .map((review, i) => (
                                      <MenuItem
                                        className="capitalize hover:bg-white bg-grey-100"
                                        value={review?.value}
                                        key={i}
                                      >
                                        <div className="flex gap-8">
                                          <div
                                            className={`w-7 h-7 rounded-full justify-center items-center flex mt-7 ${
                                              review?.value === 'Unresolved by bot' ||
                                              review?.value === 'Unresolved by human'
                                                ? 'bg-red-500'
                                                : review?.value === 'Resolved by bot' ||
                                                  review?.value === 'Resolved by human'
                                                ? 'bg-darkgreen'
                                                : ''
                                            }`}
                                          >
                                            {' '}
                                          </div>
                                          <Typography>
                                            {review?.value && capitalizeFirstLetter(review?.value)}
                                          </Typography>
                                        </div>
                                      </MenuItem>
                                    ))}
                              </Select>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="sm:h-[600px] inbox-height flex flex-col bg-gray-A300 relative bg-[url('/public/assets/images/background/whatsapp-background.png')]">
                        {isLoading && <ChatLoader />}
                        {selectContactMessageList?.length > 0 && (
                          <>
                            <div
                              className="flex-grow overflow-auto p-20 pb-0"
                              onScroll={onScroll}
                              ref={chatContainerRef}
                            >
                              {Object.entries(groupMessagesByDate(selectContactMessageList))?.map(
                                ([date, messages], index) => (
                                  <div key={index}>
                                    <div className="text-center">
                                      <div className="text-center text-grey-600 text-12 mb-12 bg-white inline-block mx-auto rounded-md shadow px-10 py-4 font-medium">
                                        {moment(date).calendar(null, {
                                          sameDay: '[Today]',
                                          lastDay: '[Yesterday]',
                                          lastWeek: 'dddd',
                                          sameElse: 'MMM DD, YYYY',
                                        })}
                                      </div>
                                    </div>
                                    {messages?.map((message, idx) => (
                                      <div className="mb-12 block" key={idx}>
                                        <div
                                          key={message?.id}
                                          className={`rounded-xl mb-4 inline-block px-8 py-8 text-14 font-medium ${
                                            message?.type
                                              ? !message?.from
                                                ? 'self-end text-right float-right break-all bg-quick-chat-50 text-black rounded-br-none'
                                                : 'self-start text-left break-all bg-white rounded-bl-none'
                                              : '!block self-auto bg-unset text-center'
                                          }`}
                                        >
                                          <div>
                                            {message?.type === 'text' ? (
                                              <p
                                                dangerouslySetInnerHTML={{
                                                  __html: formatMessage(message?.message),
                                                }}
                                              />
                                            ) : (
                                              (typeof message?.templateData === 'string'
                                                ? JSON.parse(message?.templateData)?.components
                                                : []
                                              )?.map((templateValue, i) => (
                                                <div className="block max-w-320 text-left" key={i}>
                                                  <div>
                                                    {templateValue?.type === 'HEADER' && (
                                                      <>
                                                        {templateValue?.format === 'TEXT' && (
                                                          <Typography className="mb-10 font-bold">
                                                            {templateValue?.text}
                                                          </Typography>
                                                        )}
                                                        {templateValue?.format === 'IMAGE' && (
                                                          <>
                                                            {JSON.parse(
                                                              message?.dynamicData
                                                            )?.header?.map((dynamicValue) => (
                                                              <>
                                                                {dynamicValue?.type === 'image' && (
                                                                  <img
                                                                    src={dynamicValue?.value}
                                                                    className="mb-10 rounded-md w-full"
                                                                    alt="..."
                                                                  />
                                                                )}
                                                              </>
                                                            ))}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                    {templateValue?.type === 'BODY' && (
                                                      <>
                                                        <div
                                                          dangerouslySetInnerHTML={{
                                                            __html: convertTemplateToHtml(
                                                              replacePlaceholders(
                                                                templateValue?.text,
                                                                message?.dynamicData
                                                              )
                                                            ),
                                                          }}
                                                        />
                                                      </>
                                                    )}
                                                    {templateValue?.type === 'FOOTER' && (
                                                      <>
                                                        <Typography className="mt-6 text-grey-700">
                                                          {templateValue?.text}
                                                        </Typography>
                                                      </>
                                                    )}
                                                    {templateValue?.type === 'BUTTONS' &&
                                                      templateValue?.buttons?.map(
                                                        (buttonValue, b) => (
                                                          <div
                                                            className="text-center border-t-1 border-solid border-grey-300 py-4 mt-12"
                                                            key={b}
                                                          >
                                                            <a
                                                              className="font-bold text-[#0096DE] text-16"
                                                              href={`${
                                                                buttonValue?.phone_number
                                                                  ? `tel:${buttonValue?.phone_number}`
                                                                  : buttonValue?.url
                                                              }`}
                                                              rel="noreferrer"
                                                              target="_blank"
                                                              role="button"
                                                            >
                                                              <img
                                                                src={
                                                                  buttonValue?.phone_number
                                                                    ? '/assets/images/business/telephone.png'
                                                                    : '/assets/images/business/view.png'
                                                                }
                                                                className="w-14 h-14 align-middle mr-6 inline-block relative -top-1"
                                                                alt="..."
                                                              />
                                                              {buttonValue?.text}
                                                            </a>
                                                          </div>
                                                        )
                                                      )}
                                                  </div>
                                                </div>
                                              ))
                                            )}
                                          </div>
                                          <div>
                                            {/* {message?.type === 'image' &&
                                              message?.whatsAppMedia && (
                                                <img
                                                  src={message?.whatsAppMedia}
                                                  className="mb-10 rounded-md max-w-256"
                                                  alt="..."
                                                />
                                              )} */}
                                            {message?.type === 'image' &&
                                              message?.whatsAppMedia &&
                                              (() => {
                                                const imageData = JSON.parse(
                                                  message?.imageMsgId || '{}'
                                                );
                                                return (
                                                  <>
                                                    <img
                                                      src={message?.whatsAppMedia}
                                                      className="mb-10 rounded-md max-w-256"
                                                      alt="..."
                                                    />
                                                    {imageData?.caption && (
                                                      <span className="block mt-4 text-left">
                                                        {imageData?.caption}
                                                      </span>
                                                    )}
                                                  </>
                                                );
                                              })()}
                                            {message?.reactOnMsg?.emoji}
                                          </div>
                                          <div>
                                            {message?.type === 'video' &&
                                              message?.whatsAppMedia &&
                                              (() => {
                                                const videoData = JSON.parse(
                                                  message?.video || '{}'
                                                );
                                                return (
                                                  <>
                                                    <div className="max-w-256 rounded-lg bg-gray-200 overflow-hidden">
                                                      <ReactPlayer
                                                        url={message?.whatsAppMedia}
                                                        controls
                                                        className="rounded-lg"
                                                        width="100%"
                                                        height="auto"
                                                      />
                                                    </div>
                                                    {videoData?.caption && (
                                                      <span className="block mt-4 text-left">
                                                        {videoData?.caption}
                                                      </span>
                                                    )}
                                                  </>
                                                );
                                              })()}
                                          </div>
                                          <div>
                                            {message?.type === 'document' &&
                                              message?.whatsAppMedia &&
                                              (() => {
                                                const docData =
                                                  typeof message?.document === 'string'
                                                    ? JSON.parse(message.document)
                                                    : message?.document || {};
                                                return (
                                                  <>
                                                    <div className="flex items-center justify-between min-w-256 max-w-256 bg-quick-chat-100 px-5 py-4 rounded-md">
                                                      <div>
                                                        <img
                                                          src="/assets/images/icon/file.png"
                                                          alt="Preview"
                                                          className="w-32 h-32 rounded-md img-filter-dark inline-block mr-5 align-middle"
                                                        />
                                                        <span className="inline-block overflow-hidden whitespace-nowrap text-ellipsis max-w-144 align-middle">
                                                          {docData?.mediaName || docData?.filename}
                                                        </span>
                                                      </div>

                                                      <span className="bg-quick-chat rounded-md w-24 h-24 text-center inline-block">
                                                        <DownloadIcon
                                                          aria-hidden
                                                          onClick={(e) => {
                                                            e.stopPropagation();

                                                            handleDownload(
                                                              message?.whatsAppMedia,
                                                              message?.mediaName
                                                            );
                                                          }}
                                                          className="w-14 h-14 fill-white cursor-pointer mx-auto relative top-4"
                                                        />
                                                      </span>
                                                    </div>

                                                    {docData?.caption && (
                                                      <span className="block mt-4 text-left">
                                                        {docData?.caption}
                                                      </span>
                                                    )}
                                                  </>
                                                );
                                              })()}
                                          </div>
                                          <div>
                                            {/* {message?.type === 'location' && (
                                              <LocationMessage
                                                latitude="37.7749"
                                                longitude="-122.4194"
                                                name="pune"
                                                address="pune"
                                              />
                                            )} */}
                                            {message?.type === 'location' &&
                                              message?.location &&
                                              (() => {
                                                try {
                                                  const locationData = JSON.parse(
                                                    message.location || '{}'
                                                  );
                                                  return (
                                                    <LocationMessage
                                                      latitude={locationData?.latitude}
                                                      longitude={locationData?.longitude}
                                                      name={locationData.name}
                                                      address={locationData.url}
                                                    />
                                                  );
                                                } catch (error) {
                                                  console.error(
                                                    'Error parsing location data:',
                                                    error
                                                  );
                                                  return null;
                                                }
                                              })()}
                                          </div>
                                          <div>
                                            {message?.type === null && message?.message && (
                                              <div className="text-center">
                                                <div className="text-center text-grey-600 text-12 mb-12 bg-white inline-block mx-auto rounded-md shadow px-10 py-4 font-medium">
                                                  {message?.message}
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div
                                          className={`text-12 text-gray-700 font-medium clear-both  ${
                                            message?.type
                                              ? !message?.from
                                                ? 'text-right'
                                                : 'text-left'
                                              : ''
                                          }`}
                                        >
                                          {message?.type
                                            ? moment
                                                .utc(Number(message?.cts))
                                                .tz(timeZone)
                                                .format('hh:mm A')
                                            : ''}
                                          {!message?.from && message?.status === 'sent' && (
                                            <Icon className="text-14">done</Icon>
                                          )}
                                          {!message?.from && message?.status === 'delivered' && (
                                            <DoneAllIcon className="text-14 ml-3" />
                                          )}
                                          {!message?.from && message?.status === 'read' && (
                                            <DoneAllIcon className="text-blue text-14 ml-3" />
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )
                              )}
                              <div ref={chatScroll} className="h-32 inline" />
                            </div>
                            <div className="mt-4 bg-white items-center px-5 xs:px-16 pt-16 pb-12 mx-16 mb-12 border border-solid border-gray-A500 rounded">
                              {/* <input
                                type="text"
                                className="block border-none rounded-none h-60"
                                value={messageInput}
                                onChange={(e) => setMessageInput(e.target.value)}
                                placeholder={t('waba.inbox.typeMessage')}
                                onKeyDown={handleKeyPress}
                              /> */}
                              <div className="min-h-36">
                                <Editor
                                  className="min-h-28 h-28"
                                  editorState={editorState}
                                  handleKeyCommand={handleKeyCommand}
                                  onChange={handleEditorChange}
                                  onKeyDown={handleKeyPress}
                                  placeholder={t('waba.inbox.typeMessage')}
                                />
                              </div>
                              {selectedMedia && selectedMedia?.length > 0 && (
                                <div className="flex items-center flex-row gap-x-6 mb-10 overflow-scroll">
                                  {selectedMedia?.map((media, index) => (
                                    <div
                                      key={index}
                                      className="flex items-center justify-between rounded-md border-2 border-solid min-w-224 max-w-224 p-4"
                                    >
                                      <div className="flex items-center">
                                        {media?.fileData?.mimeType.startsWith('image/') ? (
                                          <img
                                            src={media?.awsUrl}
                                            alt="Preview"
                                            className="w-32 h-32 rounded-md mr-6"
                                          />
                                        ) : media?.fileData?.mimeType.startsWith('video/') ? (
                                          <img
                                            src="/assets/images/icon/video.png"
                                            alt="Preview"
                                            className="w-32 h-32 rounded-md mr-6"
                                          />
                                        ) : (
                                          <img
                                            src="/assets/images/icon/file.png"
                                            alt="Preview"
                                            className="w-32 h-32 rounded-md mr-6 img-filter-dark"
                                          />
                                        )}
                                        <span className="text-12 font-medium inline-block text-ellipsis whitespace-nowrap max-w-112 overflow-hidden">
                                          {media?.fileData?.fileName}
                                        </span>
                                      </div>
                                      <span
                                        onClick={() => removeMedia(media)}
                                        aria-hidden
                                        className="px-3 py-1 text-sm cursor-pointer"
                                      >
                                        <Icon>close</Icon>
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              )}
                              <div className="flex flex-row justify-between ">
                                <div className="flex bg-gray-A500 items-center gap-40 px-8">
                                  <Button
                                    onClick={onBoldClick}
                                    className="text-20 font-bold text-grey-600 min-w-0 min-h-0 p-0 h-auto leading-none"
                                  >
                                    B
                                  </Button>
                                  <Button
                                    onClick={onItalicClick}
                                    className="text-20 font-bold italic text-grey-600 min-w-0 min-h-0 p-0 h-auto leading-none"
                                  >
                                    I
                                  </Button>
                                  {/* <Button
                                                        onClick={onUnderlineClick}
                                                        className="text-20 font-bold italic text-grey-600 min-w-0 min-h-0 p-0 h-auto leading-none"
                                                      >
                                                        U
                                                      </Button> */}
                                  <Button
                                    onClick={onStrikethroughClick}
                                    className="text-20 font-bold line-through text-grey-600 min-w-0 min-h-0 p-0 h-auto leading-none"
                                    title="Strike-through"
                                  >
                                    T
                                  </Button>
                                  <span
                                    aria-hidden
                                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                                    className="cursor-pointer leading-none"
                                  >
                                    <Icon className="text-20 text-grey-600">emoji_emotions</Icon>
                                  </span>
                                  {showEmojiPicker && (
                                    <>
                                      <div ref={emojiPickerRef} className="absolute bottom-60 z-10">
                                        <EmojiPicker onEmojiClick={addEmoji} skinTonesDisabled />
                                      </div>
                                    </>
                                  )}
                                  <span
                                    aria-hidden
                                    onClick={() => setOpenMediaDialog(true)}
                                    title="Template"
                                    className="cursor-pointer leading-none"
                                  >
                                    <Icon className="text-20 text-grey-600">image</Icon>
                                  </span>
                                  <span
                                    aria-hidden
                                    onClick={() => setOpenSelectTemplateDialogue(true)}
                                    title="Template"
                                    className="cursor-pointer leading-none"
                                  >
                                    <Icon className="text-20 text-grey-600">text_snippet</Icon>
                                  </span>
                                </div>

                                <Button
                                  className="rounded-md md:px-24 min-w-112 text-16 font-semibold"
                                  onClick={() => handleSendMessage('text')}
                                  size="small"
                                  variant="contained"
                                  color="quickChat"
                                >
                                  Send
                                </Button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="h-full flex items-center justify-center bg-gray-A300">
                      <div className="mx-auto text-center">
                        <img
                          src="/assets/images/business/conversation.png"
                          className="img-fluid mx-auto mb-48"
                          alt="..."
                        />
                        <Typography className="block text-24 font-semibold mb-12">
                          {t('waba.inbox.starAConversation')}
                        </Typography>
                        <Typography className="block font-medium text-16 text-grey-600 mb-28">
                          {t('waba.inbox.startDesc')}
                        </Typography>
                        <Button
                          className="rounded-md sm:px-68"
                          size="medium"
                          variant="contained"
                          color="quickChat"
                          onClick={() => {
                            const id = getParamsId();
                            if (id) {
                              history.push(`quick-chat/waba-contacts/${id}`, {
                                openDialog: true,
                              });
                            } else {
                              history.push('quick-chat/waba-contacts', {
                                openDialog: true,
                              });
                            }
                          }}
                        >
                          {t('waba.inbox.addNewContact')}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>

                {/* Right Sidebar */}
                {selectedContact && (
                  <div className="w-full md:w-[30%] sm:max-h-screen max-h-320 sm:overflow-auto overflow-scroll mb-48 sm:mb-0">
                    <div className="max-h-[499px] sm:max-h-screen">
                      <div className="border-b-1 border-solid border-grey-300 p-16">
                        <div className="flex items-center">
                          <img
                            src="/assets/images/business/profile.png"
                            className="img-fluid w-full max-w-48 mr-20"
                            alt="..."
                          />

                          <div>
                            <h3 className="text-20 font-semibold">
                              {selectedContact?.name || selectedContact?.phoneNumber}
                            </h3>
                            {/* <span className="font-medium text-14 text-grey-800 capitalize">
                          {selectedContact?.status}
                        </span> */}
                          </div>
                        </div>
                      </div>
                      <div className="p-16">
                        <div className="bg-gray-A500 p-12">
                          <span className="text-16 font-semibold block mb-10">
                            {t('waba.inbox.info')}
                          </span>
                          <div className="flex justify-between gap-12">
                            <div className="grid gap-10">
                              <span className="font-medium text-14 text-grey-600 block">
                                {t('waba.inbox.phone')}
                              </span>
                              <span className="font-medium text-14 text-grey-600 block">
                                {t('waba.inbox.status')}
                              </span>
                              <span className="font-medium text-14 text-grey-600 block">
                                {t('waba.inbox.assignTo')}
                              </span>
                              <span className="font-medium text-14 text-grey-600 block">
                                {t('waba.inbox.lastActive')}
                              </span>
                              <span className="font-medium text-14 text-grey-600 block">
                                {t('waba.inbox.templateMessage')}
                              </span>
                              <span className="font-medium text-14 text-grey-600 block">
                                {t('waba.inbox.sessionMessage')}
                              </span>
                              <span className="font-medium text-14 text-grey-600 block">
                                {t('waba.inbox.source')}
                              </span>
                              <span className="font-medium text-14 text-grey-600 block">
                                {t('waba.inbox.optedIn')}
                              </span>
                            </div>
                            <div className="grid gap-10">
                              <Typography className="text-14 font-medium">
                                {selectedContact?.phoneNumber}
                              </Typography>
                              <Typography className="text-14 font-medium">
                                {selectedContact?.optedIn ? 'Inactive' : 'Active'}
                              </Typography>
                              <Typography className="text-14 font-medium">
                                {selectedContact?.assignToOwner ? 'Business Owner' : 'Bot'}
                              </Typography>
                              <Typography className="text-14 font-medium">
                                {selectedContact?.lastOnlineTime
                                  ? moment
                                      .unix(selectedContact.lastOnlineTime / 1000)
                                      .tz(timeZone)
                                      .format('MMM DD, YYYY')
                                  : 'N/A'}
                              </Typography>
                              <Typography className="text-14 font-medium">
                                {selectedContact?.templateCount}
                              </Typography>
                              <Typography className="text-14 font-medium">
                                {selectedContact?.textCount}
                              </Typography>
                              <Typography className="text-14 font-medium">
                                {selectedContact?.sourceType ? selectedContact?.sourceType : '-'}
                              </Typography>
                              <Typography className="text-14 font-medium">
                                {selectedContact?.optedIn ? 'Yes' : 'No'}
                              </Typography>
                            </div>
                          </div>
                        </div>
                        <div className="bg-gray-A500 p-12 mt-12">
                          <div className="flex items-center justify-between">
                            <span className="text-16 font-semibold block">
                              {t('waba.inbox.contactTag')}{' '}
                            </span>
                            {!isExpandedTag && (
                              <div
                                className="cursor-pointer"
                                onClick={() => setIsExpandedTag(true)}
                                aria-hidden
                              >
                                <span className="bg-quick-chat w-14 h-14 inline-block align-middle text-white text-center mr-8">
                                  <Icon className="text-14">add</Icon>
                                </span>
                                <span className="text-12 font-medium inline-block align-middle text-quick-chat">
                                  {t('waba.inbox.addTag')}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="mt-10 quick-chat">
                            {!isExpandedTag && tagList && tagList.length > 0 ? (
                              <div>
                                <Select
                                  className="w-full"
                                  id="labels"
                                  multiple
                                  value={selectedLabels}
                                  size="small"
                                  displayEmpty
                                  onChange={(e) => handleTagChange(e?.target?.value)}
                                  sx={{
                                    '& .MuiSelect-select': {
                                      display: 'flex',
                                      alignItems: 'center',
                                    },
                                  }}
                                  renderValue={() => t('common.selectTag')}
                                  onOpen={() => {
                                    setTimeout(() => {
                                      const menuElement = document.querySelector('.MuiMenu-paper');
                                      if (menuElement) {
                                        menuElement.scrollTop = 0;
                                      }
                                    }, 10);
                                  }}
                                  MenuProps={{
                                    PaperProps: {
                                      sx: {
                                        maxHeight: 250,
                                        overflowY: 'auto',
                                      },
                                    },
                                  }}
                                >
                                  <MenuItem disableRipple>
                                    <TextField
                                      size="small"
                                      variant="outlined"
                                      fullWidth
                                      placeholder={t('common.searchTag')}
                                      value={searchLabelName}
                                      onChange={(e) => setSearchLabelName(e.target.value)}
                                      onKeyDown={(e) => e.stopPropagation()}
                                      sx={{
                                        backgroundColor: 'white',
                                        '& .MuiOutlinedInput-root': {
                                          backgroundColor: 'white',
                                        },
                                        '& .MuiOutlinedInput-input': {
                                          backgroundColor: 'white',
                                        },
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <SearchIcon />
                                          </InputAdornment>
                                        ),
                                        endAdornment: searchLabelName && (
                                          <InputAdornment position="end">
                                            <Icon
                                              color="quickChat"
                                              className="cursor-pointer text-20"
                                              onClick={() => setSearchLabelName('')}
                                            >
                                              close
                                            </Icon>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </MenuItem>
                                  {tagList.length > 0 ? (
                                    tagList.map((option) => (
                                      <MenuItem
                                        key={option.id}
                                        value={option.id}
                                        disabled={selectedLabels.includes(option.id)}
                                      >
                                        <Checkbox checked={selectedLabels.includes(option.id)} />
                                        <span
                                          className="h-14 w-14 mr-6 rounded-full"
                                          style={{ backgroundColor: option.colorCode }}
                                        />
                                        {option.tagName}
                                      </MenuItem>
                                    ))
                                  ) : (
                                    <MenuItem disabled>{t('manageLabels.noFound')}</MenuItem>
                                  )}
                                </Select>
                              </div>
                            ) : (
                              <div className="block">
                                <input
                                  type="text"
                                  value={tagValue}
                                  onChange={(e) => setTagValue(e.target.value)}
                                  placeholder={t('waba.inbox.enterTag')}
                                  className="w-full px-10 py-2 border rounded-md h-36 "
                                />

                                {isExpandedTag && (
                                  <div className="text-right mt-10">
                                    <Button
                                      onClick={() => {
                                        setIsExpandedTag(false);
                                        setTagValue('');
                                      }}
                                      variant="outlined"
                                      color="quickChat"
                                      className="text-12 inline-block rounded-md h-28 min-h-28 pt-2"
                                    >
                                      {t('common.cancel')}
                                    </Button>
                                    {tagValue && (
                                      <Button
                                        onClick={() => addTagFunction()}
                                        variant="contained"
                                        color="quickChat"
                                        className="text-12 inline-block rounded-md h-28 min-h-28 pt-2 px-10 w-92 min-w-92 ml-16"
                                      >
                                        <span>
                                          <Icon className="text-14 align-middle">add</Icon>{' '}
                                          <span>{t('waba.inbox.addTag')}</span>
                                        </span>
                                        {tagLoading && (
                                          <CircularProgress
                                            size={12}
                                            className="text-quick-chat absolute mx-auto right-3"
                                          />
                                        )}
                                      </Button>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                            {assignedTagList && assignedTagList?.length > 0 && (
                              <div className="flex flex-wrap gap-8 mt-16">
                                {assignedTagList?.map((tagValues, index) => (
                                  <span
                                    className="font-medium text-12 rounded-md px-6 py-4"
                                    style={{
                                      backgroundColor: `rgba(${hexToRgb(
                                        tagValues?.colorCode
                                      )}, 0.1)`,
                                      color: tagValues?.colorCode,
                                    }}
                                    key={index}
                                  >
                                    {tagValues?.tagName}{' '}
                                    <Icon
                                      className="text-14 align-middle cursor-pointer"
                                      onClick={() => handleDeleteTag(tagValues?.id)}
                                    >
                                      close
                                    </Icon>
                                  </span>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="bg-gray-A500 p-12 mt-12">
                          <div className="flex items-center justify-between mb-16">
                            <span className="text-16 font-semibold block">
                              {t('waba.inbox.notes')}{' '}
                              <Tooltip
                                title={t('waba.inbox.noteInfo')}
                                arrow
                                classes={{
                                  tooltip: 'bg-quick-chat',
                                  arrow: 'text-quick-chat',
                                }}
                              >
                                <Icon className="text-quick-chat text-16 align-middle mt-2 ml-2">
                                  info
                                </Icon>
                              </Tooltip>
                            </span>
                            {!isExpandedNote && (
                              <div
                                className="cursor-pointer"
                                onClick={() => setIsExpandedNote(true)}
                                aria-hidden
                              >
                                <span className="bg-quick-chat w-14 h-14 inline-block align-middle text-white text-center mr-8">
                                  <Icon className="text-14">add</Icon>
                                </span>
                                <span className="text-12 font-medium inline-block align-middle text-quick-chat">
                                  {t('waba.inbox.addNote')}
                                </span>
                              </div>
                            )}
                          </div>

                          <textarea
                            className="px-10 py-8 border rounded-md w-full h-36"
                            onChange={(event) => setNoteValue(event.target.value)}
                            value={noteValue}
                            placeholder={t('waba.inbox.enterDescription')}
                            rows={5}
                            style={{ display: isExpandedNote ? 'block' : 'none', height: 'auto' }}
                          />
                          {isExpandedNote && (
                            <div className="text-right mt-10">
                              <Button
                                onClick={() => {
                                  setIsExpandedNote(false);
                                  setNoteValue('');
                                }}
                                variant="outlined"
                                color="quickChat"
                                className="text-12 inline-block rounded-md h-28 min-h-28 pt-2"
                              >
                                {t('common.cancel')}
                              </Button>
                              {noteValue && (
                                <Button
                                  onClick={() => createNote()}
                                  variant="contained"
                                  color="quickChat"
                                  className="text-12 inline-block rounded-md h-28 min-h-28 pt-2 ml-16"
                                >
                                  {t('common.save')}
                                  {loading && (
                                    <CircularProgress
                                      size={12}
                                      className="text-quick-chat absolute mx-auto right-3"
                                    />
                                  )}
                                </Button>
                              )}
                            </div>
                          )}
                          {getChatNote &&
                            getChatNote?.length > 0 &&
                            getChatNote?.map((note, index) => (
                              <div className="bg-white border p-12 rounded-md mt-12" key={index}>
                                {editedNote === note?.id ? (
                                  <textarea
                                    className="px-10 py-8 border rounded-md w-full"
                                    onChange={(e) => setEditedContent(e.target.value)}
                                    value={editedContent}
                                    placeholder={t('waba.inbox.enterDescription')}
                                    rows={5}
                                    style={{ height: 'auto' }}
                                  />
                                ) : (
                                  <span className="block">{note?.note}</span>
                                )}
                                <span className="mt-12">
                                  <span>
                                    {editedNote === note?.id ? (
                                      <div className="text-right">
                                        <Button
                                          onClick={() => setEditedNote(null)}
                                          variant="outlined"
                                          color="quickChat"
                                          className="text-12 inline-block rounded-md h-28 min-h-28 pt-2"
                                        >
                                          {t('common.cancel')}
                                        </Button>
                                        {note?.note !== editedContent && (
                                          <Button
                                            onClick={() => handleSaveClick(note)}
                                            variant="contained"
                                            color="quickChat"
                                            className="text-12 inline-block rounded-md h-28 min-h-28 pt-2 ml-16"
                                          >
                                            {t('common.save')}
                                            {loading && (
                                              <CircularProgress
                                                size={12}
                                                className="text-quick-chat absolute mx-auto right-3"
                                              />
                                            )}
                                          </Button>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="mt-8">
                                        <span className="text-12 font-medium text-gray-500 inline-block">
                                          {moment(note?.uts).format('MMM DD, YYYY h:MM A')}
                                        </span>
                                        <div className="inline-block float-right">
                                          <EditIcon
                                            onClick={() => handleEditClick(note)}
                                            aria-hidden
                                            className="w-16 fill-gray-800 inline-block cursor-pointer hover:fill-quick-chat"
                                          />
                                          <DeleteIcon
                                            onClick={() => deleteNote(note?.id)}
                                            aria-hidden
                                            className="w-16 hover:stroke-quick-chat group-hover:stroke-quick-chat stroke-gray-800  inline-block ml-10 cursor-pointer"
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </span>
                                </span>
                              </div>
                            ))}
                        </div>

                        <div className="bg-gray-A500 p-12 mt-12">
                          <div
                            className="flex items-center justify-between"
                            onClick={() => setOpenJourney(!openJourney)}
                            aria-hidden
                          >
                            <span className="text-16 font-semibold block">
                              {t('waba.inbox.customerJourney')}{' '}
                            </span>
                            <span className="inline-block relative">
                              {openJourney ? (
                                <Icon className="text-18 ">expand_more</Icon>
                              ) : (
                                <Icon className="text-18">expand_less</Icon>
                              )}
                            </span>
                          </div>
                          <Collapse className="mt-16" in={openJourney} timeout="auto" unmountOnExit>
                            {getChatJourney &&
                              getChatJourney?.length > 0 &&
                              getChatJourney?.map((journey, index) => (
                                <div className="flex relative" key={index}>
                                  <div className="flex items-center flex-col relative top-3">
                                    <span
                                      className={` w-12 h-12 rounded-md ${
                                        index === 0
                                          ? 'bg-quick-chat border border-solid border-quick-chat'
                                          : 'bg-transparent border border-solid border-grey-600'
                                      }`}
                                    />
                                    <span
                                      className={`w-2 grow bg-quick-chat ${
                                        index === getChatJourney.length - 1 ? 'hidden' : ''
                                      }`}
                                    />
                                  </div>
                                  <div className="ml-16">
                                    <div className="text-12 font-medium">
                                      {journey?.journeyType === 'Opt-Out' ||
                                      journey?.journeyType === 'Opt-In'
                                        ? 'User '
                                        : ''}
                                      {journey?.wa_user_tags?.tagName && (
                                        <span>
                                          <span
                                            className="inline-block rounded-md text-white px-5"
                                            style={{
                                              backgroundColor: `rgba(${hexToRgb(
                                                journey?.wa_user_tags?.colorCode
                                              )}, 0.1)`,
                                              color: journey?.wa_user_tags?.colorCode,
                                            }}
                                          >
                                            {journey?.wa_user_tags?.tagName}
                                          </span>{' '}
                                        </span>
                                      )}{' '}
                                      {journey?.journeyType === 'Tag Removed'
                                        ? 'Tag Removed'
                                        : journey?.journeyType === 'Tag Added'
                                        ? 'Tag Added'
                                        : ''}
                                      {journey?.journeyType !== 'Tag Removed' &&
                                        journey?.journeyType !== 'Tag Added' &&
                                        journey?.convoName}
                                      {journey?.journeyType === 'Conversation Started'
                                        ? ' Conversation Started'
                                        : ''}
                                    </div>
                                    <div
                                      className={`text-12 font-medium text-gray-600 ${
                                        index === getChatJourney.length - 1 ? 'mb-0' : 'mb-16'
                                      }`}
                                    >
                                      {moment(journey?.cts).format('MMM DD, YYYY h:MM A')}
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="h-full flex items-center justify-center bg-white rounded-md h-screen chat-height">
              <div className="mx-auto text-center">
                <img
                  src="/assets/images/business/conversation.png"
                  className="img-fluid mx-auto mb-48"
                  alt="..."
                />
                <Typography className="block text-24 font-semibold mb-12">
                  {t('waba.inbox.approveHead')}
                </Typography>
                <Typography className="block font-medium text-16 text-grey-600 mb-28">
                  {t('waba.inbox.approveDesc')}
                </Typography>
              </div>
            </div>
          )}
        </div>
        {openTemplateListDialog && (
          <TemplateList
            openValue={openTemplateListDialog}
            cancelCall={() => setOpenTemplateListDialog(false)}
            popUpMessage={t('waba.inbox.chooseTemplate')}
            onTemplateSelect={handleTemplateSelect}
          />
        )}
        {openMediaDialog && (
          <MediaList
            openValue={openMediaDialog}
            cancelCall={() => setOpenMediaDialog(false)}
            popUpMessage={t('waba.inbox.mediaLibrary')}
            onMediaSelected={handleMediaSelected}
            selectedMedia={selectedMedia}
          />
        )}
        <SelectTemplate
          openSelectTemplateDialogue={openSelectTemplateDialogue}
          setOpenSelectTemplateDialogue={setOpenSelectTemplateDialogue}
          selectedVariables={selectedVariables}
          setSelectedVariables={setSelectedVariables}
          handleTemplateSelect={handleTemplateSelect}
          type="waBaChat"
        />
        <Drawer
          anchor="right"
          open={isPopupOpen}
          onClose={handleClosePopup}
          sx={{ '& .MuiDrawer-paper': { width: '100%', maxWidth: '510px' } }}
        >
          <div>
            <div className="flex justify-between px-20 py-16 bg-white border-b border-solid border-gray-600">
              <h2 className="text-20 font-bold mb-4 text-center justify-center items-center flex">
                {t('quickSocial.filter')}
              </h2>
              <CloseIcon onClick={handleClosePopup} className="cursor-pointer" />
            </div>
            <div className="px-24 quick-chat">
              <div className="mb-16">
                <div className="text-18 font-semibold py-10 block">
                  {t('waba.broadcast.contactList.table.tags')}
                </div>
                <Select
                  className="w-full"
                  id="labels"
                  multiple
                  value={selectedLabelsFilter}
                  sx={{ '& .MuiSelect-select': { display: 'flex', alignItems: 'center' } }}
                  size="small"
                  displayEmpty
                  onChange={(e) => setSelectedLabelsFilter(e.target.value || [])}
                  renderValue={() => t('common.selectTag')}
                  onOpen={() => {
                    setTimeout(() => {
                      const menuElement = document.querySelector('.MuiMenu-paper');
                      if (menuElement) {
                        menuElement.scrollTop = 0;
                      }
                    }, 10);
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: 250,
                        overflowY: 'auto',
                      },
                    },
                  }}
                >
                  <MenuItem disableRipple>
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      placeholder={t('common.searchTag')}
                      value={searchLabelNameFilter}
                      onChange={(e) => setSearchLabelNameFilter(e.target.value)}
                      onKeyDown={(e) => e.stopPropagation()}
                      sx={{
                        backgroundColor: 'white',
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: 'white',
                        },
                        '& .MuiOutlinedInput-input': {
                          backgroundColor: 'white',
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: searchLabelNameFilter && (
                          <InputAdornment position="end">
                            <Icon
                              color="quickChat"
                              className="cursor-pointer text-20"
                              onClick={() => setSearchLabelNameFilter('')}
                            >
                              close
                            </Icon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MenuItem>
                  {tagList.length > 0 ? (
                    tagList.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <Checkbox checked={selectedLabelsFilter.includes(option.id)} />
                        <span
                          className="h-14 w-14 mr-6 rounded-full"
                          style={{ backgroundColor: option.colorCode }}
                        />
                        {option.tagName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>{t('manageLabels.noFound')}</MenuItem>
                  )}
                </Select>
                {selectedLabelsFilter?.length > 0 && (
                  <div className="flex flex-wrap gap-8 mt-12">
                    {tagList &&
                      tagList
                        .filter((item) => selectedLabelsFilter?.includes(item?.id))
                        .map((tag, index) => (
                          <div
                            key={index}
                            className="rounded-md p-7 h-20 min-w-max flex justify-center items-center gap-4"
                            style={{
                              backgroundColor: `${tag?.colorCode}20`,
                            }}
                          >
                            <Typography style={{ color: tag?.colorCode }} className="font-semibold">
                              {tag?.tagName}
                            </Typography>
                            <button
                              type="button"
                              style={{ color: tag?.colorCode }}
                              className="font-bold"
                              color="error"
                              onClick={() => {
                                setSelectedLabelsFilter((prev) =>
                                  prev.filter((id) => id !== tag?.id)
                                );
                              }}
                            >
                              X
                            </button>
                          </div>
                        ))}
                    <button
                      type="button"
                      color="error"
                      onClick={() => {
                        setSelectedLabelsFilter([]);
                      }}
                      style={{ color: '#d50000' }}
                      className="font-medium"
                    >
                      {t('users.UserList.Filter.clearBtn')}
                    </button>
                  </div>
                )}
              </div>
              <div className="mb-16">
                <div className="text-18 font-semibold px-16 py-10 bg-gray-A500 block">
                  {t('waba.inbox.lastSeen')}
                </div>
                <div className="border-1 border-solid border-gray-A500 p-16 border-t-[0px]">
                  <FormControl className="w-full">
                    <RadioGroup
                      aria-labelledby="created-option"
                      className="block"
                      name="opted-option"
                      value={lastSeen}
                      onChange={handleLastSeenFilter}
                    >
                      {durationOptions &&
                        durationOptions?.length > 0 &&
                        durationOptions?.map((lastSeenValue, index) => (
                          <FormControlLabel
                            key={index}
                            value={lastSeenValue?.value}
                            control={<Radio />}
                            className="flex flex-row-reverse justify-between m-0 mb-10"
                            sx={{
                              '& .MuiRadio-root': { padding: '0' },
                              '& .MuiFormControlLabel-label': {
                                fontSize: '16px',
                                fontWeight: '500',
                              },
                            }}
                            label={lastSeenValue?.label}
                          />
                        ))}
                    </RadioGroup>
                  </FormControl>
                  {lastSeen === 'custom' && (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Box display="flex " justifyContent="space-between" gap={2} mt={4}>
                        <DatePicker
                          label={
                            <span className="font-semibold text-black text-18">
                              {t('reports.from')}
                            </span>
                          }
                          value={startDate}
                          onChange={(date) => {
                            setCustomStartDate(moment(date).tz(timezone).format('YYYY-MM-DD'));
                            setStartDate(date);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          disableFuture
                        />
                        <DatePicker
                          label={
                            <span className="font-semibold text-black text-18">
                              {t('reports.to')}
                            </span>
                          }
                          value={endDate}
                          onChange={(date) => {
                            setCustomEndDate(moment(date).tz(timezone).format('YYYY-MM-DD'));
                            setEndDate(date);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          disableFuture
                        />
                      </Box>
                    </LocalizationProvider>
                  )}
                </div>
              </div>
              <div className="mb-16">
                <div className="text-18 font-semibold px-16 py-10 bg-gray-A500 block">
                  {t('waba.broadcast.contactList.createdAt')}
                </div>
                <div className="border-1 border-solid border-gray-A500 p-16 border-t-[0px]">
                  <FormControl className="w-full">
                    <RadioGroup
                      aria-labelledby="created-option"
                      className="block"
                      name="opted-option"
                      value={createdAt}
                      onChange={handleCreatedAt}
                    >
                      {durationOptions &&
                        durationOptions?.length > 0 &&
                        durationOptions?.map((created, index) => (
                          <FormControlLabel
                            key={index}
                            value={created?.value}
                            control={<Radio />}
                            className="flex flex-row-reverse justify-between m-0 mb-10"
                            sx={{
                              '& .MuiRadio-root': { padding: '0' },
                              '& .MuiFormControlLabel-label': {
                                fontSize: '16px',
                                fontWeight: '500',
                              },
                            }}
                            label={created?.label}
                          />
                        ))}
                    </RadioGroup>
                  </FormControl>
                  {createdAt === 'custom' && (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Box display="flex " justifyContent="space-between" gap={2} mt={4}>
                        <DatePicker
                          label={
                            <span className="font-semibold text-black text-18">
                              {t('reports.from')}
                            </span>
                          }
                          value={startDateCreateAt}
                          onChange={(date) => {
                            setCustomCreatedAtStartDate(
                              moment(date).tz(timezone).format('YYYY-MM-DD')
                            );
                            setStartDateCreateAt(date);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          disableFuture
                        />
                        <DatePicker
                          label={
                            <span className="font-semibold text-black text-18">
                              {t('reports.to')}
                            </span>
                          }
                          value={endDateCreateAt}
                          onChange={(date) => {
                            setCustomCreatedAtEndDate(
                              moment(date).tz(timezone).format('YYYY-MM-DD')
                            );
                            setEndDateCreateAt(date);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          disableFuture
                        />
                      </Box>
                    </LocalizationProvider>
                  )}
                </div>
              </div>
              <div className="mb-16">
                <div className="text-18 font-semibold px-16 py-10 bg-gray-A500 block">
                  {t('waba.broadcast.contactList.table.optedIn')}
                </div>
                <div className="border-1 border-solid border-gray-A500 p-16 border-t-[0px]">
                  <FormControl className="w-full">
                    <RadioGroup
                      aria-labelledby="delivery-option"
                      className="block"
                      name="opted-option"
                      value={selectedOpted}
                      onChange={(e) => setSelectedOpted(e?.target?.value)}
                    >
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        className="flex flex-row-reverse justify-between m-0 mb-10"
                        sx={{
                          '& .MuiRadio-root': { padding: '0' },
                          '& .MuiFormControlLabel-label': { fontSize: '16px', fontWeight: '500' },
                        }}
                        label={t('common.yes')}
                      />
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        className="flex flex-row-reverse justify-between m-0"
                        sx={{
                          '& .MuiRadio-root': { padding: '0' },
                          '& .MuiFormControlLabel-label': { fontSize: '16px', fontWeight: '500' },
                        }}
                        label={t('common.no')}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <div className="flex mt-28 gap-12 justify-end">
                <Button
                  className="rounded-md py-0 max-w-128 w-full text-16 font-medium min-h-32"
                  size="small"
                  variant="outlined"
                  color="quickChat"
                  type="button"
                  onClick={() => {
                    setSelectedLabelsFilter([]);
                    setSelectedOpted();
                    setCreatedAt();
                    setLastSeen();
                    setIsPopupOpen(false);
                    whatAppUserChatRoom();
                  }}
                >
                  {t(`quickCampaign.campaign.clearFilter`)}
                  <Icon className="text-16">{t(`quickAds.close`)}</Icon>
                </Button>
                <Button
                  className="rounded-md py-0 max-w-128 w-full text-16 font-medium min-h-32"
                  size="small"
                  variant="contained"
                  color="quickChat"
                  type="button"
                  onClick={() => {
                    whatAppUserChatRoom(
                      filteredData,
                      selectedLabelsFilter,
                      selectedOpted,
                      lastSeenAt,
                      createdAtF
                    );
                    setIsPopupOpen(false);
                  }}
                >
                  {t('quickChat.apply')}
                </Button>
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
};

export default WabaInbox;
