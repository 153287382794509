import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { t } from 'i18next';

const NotVerifyBusinessPopUp = (props) => {
  const { isDialogOpen, setIsDialogOpen, from } = props;
  return (
    <Dialog
      open={isDialogOpen}
      classes={{
        paper: `m-24 p-12 pt-28 pb-28 sm:p-36 flex gap-10 flex-col rounded-md`,
      }}
      maxWidth="sm"
    >
      <DialogContent className="p-0 flex justify-center items-center">
        <div className="text-center flex flex-col gap-10">
          <img
            className="m-auto w-128"
            src="assets/images/business/google-my-business-logo.svg"
            alt=""
          />
          <Typography className="text-black font-bold text-20">
            {t('reviews.businessNotVerify')}
          </Typography>
          <Typography className="max-w-400 m-auto text-16 font-medium">
            {from === 'Post'
              ? t('reviews.businessNotVerifyMessagePost')
              : t('reviews.businessNotVerifyMessage')}
          </Typography>
        </div>
      </DialogContent>

      <DialogActions className="p-0 flex justify-center gap-14">
        <Button
          className="md:text-16 font-bold rounded-md w-full"
          variant="outlined"
          color="secondary"
          onClick={() => setIsDialogOpen(false)}
        >
          {t('reviews.notNow')}
        </Button>
        <Button
          className="md:text-16 font-bold rounded-md bg-quick-review w-full"
          variant="contained"
          color="secondary"
          onClick={() => {
            window.open('https://business.google.com/locations', '_blank');
            setIsDialogOpen(false);
          }}
        >
          {t('reviews.verify')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotVerifyBusinessPopUp;
