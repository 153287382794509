import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Icon,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Drawer,
  CircularProgress,
  Pagination,
} from '@mui/material';
import EmojiPicker from 'emoji-picker-react';
import userService from 'src/app/services';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { selectDashboardData } from 'src/app/store/userSlice';
import CloseIcon from '@mui/icons-material/Close';
import history from '@history';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FuseLoading from '@fuse/core/FuseLoading';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ShareIcon from '@mui/icons-material/Share';
import { useSnackbar } from 'notistack';
import SocialPlatformTab from './socialPlatformTab';
import {
  fetchSocialConnections,
  formatDateWithTimeZone,
  handleApiRequest,
  handleFBLogin,
  handleSocialResponse,
  imageDataToServer,
} from '../../common/common';
import AnalyticsDialogBox from './AnalyticsDialogBox';
import quickSocialQuery from '../../query/quickSocial';
import quickPostQuery from '../../query/quickPost';
import PostEditDialogBox from './PostEditDialogBox';
import ConformationDialogBox from '../../CommonComponent/ConformationDialogBox';
import TemplatePopUp from './TemplatePopUp';
import UserProfile from '../../common/userProfile';
import SelectPlatformBox from './SelectPlatformBox';
import SharePost from '../quick-post/SharePost';
import UpdatePost from './UpdatePost';
import Linkedin from './LinkedinConnect';

const AllSharePost = () => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const dummyPosts = {
    id: 1,
    postImage: 'https://via.placeholder.com/224',
    profileImage: 'https://via.placeholder.com/48',
    username: 'Hotel_Flavor Haven',
    postDate: '10 Jun 2024 9:57 AM',
    postContent:
      "Take advantage of our Summer Sale! We're offering fantastic 30% discount on all items, from now until end of the month. Whether you're looking for dishes or Read More...",
    likes: 2,
    commentsCount: 25,
    shares: 9,
    comments: [
      {
        id: Math.random(),
        profileImage: 'https://via.placeholder.com/36',
        username: userCurrentBusinessDetails?.name,
        commentDate: new Date().setHours(new Date().getMinutes() - 4),
        commentText: "Great offer! I'll definitely visit soon. Do you have vegetarian options?",
        likes: 2,
        likeOrUnlike: true,
        replies: [
          {
            id: Math.random(),
            profileImage: 'https://via.placeholder.com/36',
            username: userCurrentBusinessDetails?.name,
            replyDate: '30 mins ago',
            replyText: 'Yes, we have an extensive vegetarian menu!',
            likes: 1,
            likeOrUnlike: true,
          },
        ],
      },
      {
        id: Math.random(),
        profileImage: 'https://via.placeholder.com/36',
        username: userCurrentBusinessDetails?.name,
        commentDate: new Date().setHours(new Date().getMinutes() - 5),
        commentText: 'Amazing discounts! Is the offer available online too?',
        likes: 0,
        likeOrUnlike: false,
        replies: [],
      },
    ],
  };
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerAnchorEl, setDrawerAnchorEl] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [postDetails, setPostDetails] = useState([]);
  const [selectedPost, setSelectedPost] = useState({});
  const [selectedIndex, setSelectedIndex] = useState();
  const [showOptions, setShowOptions] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedPostDrawer, setSelectedPostDrawer] = useState(null);
  const [expandedPosts, setExpandedPosts] = useState({});
  const [expandedPost, setExpandedPost] = useState({});
  const [socialData, setSocialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showEditPost, setShowEditPost] = useState(false);
  const [showDeletePost, setShowDeletePost] = useState(false);
  const [openTemplatePopUp, setOpenTemplatePopUp] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const loginUserData = userService.getUserData();
  const [facebookUserId, setFacebookUserId] = useState();
  const [connectionData, setConnectionData] = useState([]);
  const [accessToken, setAccessToken] = useState();
  const [commentText, setCommentText] = useState('');
  const [replyCommentText, setReplyCommentText] = useState('');
  const [commentsData, setCommentsData] = useState(dummyPosts);
  const [selectedPage, setSelectedPage] = useState([]);
  const [socialPlatformId, setSocialPlatformId] = useState(null);
  const [isReply, setIsReply] = useState(false);
  const [selectUserToReply, setSelectUserToReply] = useState('');
  const [openDialog, setOpenDialog] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [comments, setComments] = useState([]);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [btLoading, setBtLoading] = useState(false);
  const [delComment, setDelComment] = useState(false);
  const [delCommentId, setDelCommentId] = useState();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUpload, setImageUpload] = useState();
  const [replyImage, setReplyImage] = useState(null);
  const [isLinkedin, setIsLinkedin] = useState(false);
  const [openLinkedinPopUp, setOpenLinkedinPopUp] = useState(false);
  const [createPostAnchor, setCreatePostAnchor] = useState(null);
  const [createPostOpen, setCreatePostOpen] = useState(false);
  const [linkedinRefreshIcon, setLinkedinRefreshIcon] = useState(false);
  const [replyImageUpload, setReplyImageUpload] = useState();
  const [del, setDel] = useState(false);
  const appKey = process.env.REACT_APP_WABA_APP_ID;
  const fbConfigKey = process.env.REACT_APP_FB_CONFIG_ID;
  const inConfigKey = process.env.REACT_APP_IN_CONFIG_ID;
  const isExpandedS = expandedPost[selectedPostDrawer?.id] || false;
  const texts =
    activeStep === 10
      ? selectedPostDrawer?.caption
      : activeStep === 9 || activeStep === 12
      ? selectedPostDrawer?.description
      : activeStep === 11
      ? selectedPostDrawer?.tweet
      : '';

  const empty = () => {
    setCommentText('');
    setSelectedImage(null);
    setImageUpload();
  };

  const resetCommentTexts = () => {
    setCommentText('');
    setReplyCommentText('');
  };

  const resetImages = () => {
    setSelectedImage(null);
    setImageUpload(null);
    setReplyImageUpload(null);
    setReplyImage(null);
  };

  useEffect(() => {
    if (selectedPostDrawer) {
      const matchingPost = postDetails?.find((post) => post?.id === selectedPostDrawer?.id);
      if (matchingPost) {
        setSelectedPostDrawer(matchingPost);
      }
    }
  }, [postDetails, selectedPostDrawer]);

  useEffect(() => {
    setOpenDrawer(false);
    resetCommentTexts();
    resetImages();
  }, [anchorEl]);

  const handleClickOutside = (event) => {
    if (emojiPickerRef?.current && !emojiPickerRef?.current?.contains(event.target)) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    if (showEmojiPicker) {
      document?.addEventListener('mousedown', handleClickOutside);
    } else {
      document?.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document?.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showEmojiPicker]);

  const fileUpload = (event, setImage, setUpload, setText) => {
    const file = event?.target?.files[0];
    if (!file) return;
    if (!['image/jpeg', 'image/png', 'image/gif'].includes(file?.type)) {
      enqueueSnackbar(t('quickSocial.fileValidation1'), {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setText((prevText) => `${prevText}`);
      setUpload(file);
    };
    try {
      reader.readAsDataURL(file);
    } catch (error) {
      enqueueSnackbar(t('quickSocial.fileValidation'), {
        variant: 'error',
        autoHideDuration: 2000,
      });
      console.error('File reading error:', error);
    }
  };

  const handleSubSubUpload = (event) => {
    fileUpload(event, setReplyImage, setReplyImageUpload, setReplyCommentText);
  };

  const handleSubUpload = (event) => {
    fileUpload(event, setReplyImage, setReplyImageUpload, setReplyCommentText);
  };

  const handlePhotoUpload = (event) => {
    fileUpload(event, setReplyImage, setReplyImageUpload, setReplyCommentText);
  };

  const handleFileUpload = (event) => {
    fileUpload(event, setSelectedImage, setImageUpload, setCommentText);
  };

  const handleRemoveImage = () => {
    resetImages();
    setCommentText((prevText) => prevText.replace(/\[Image: .*?\]\n?/g, ''));
    setReplyCommentText((prevText) => prevText.replace(/\[Image: .*?\]\n?/g, ''));
  };

  const fetchAgentSupportStatus = useCallback(async () => {
    if (userCurrentBusinessDetails?.id && activeStep) {
      const queryMap = {
        9: quickSocialQuery.getAllFacebookChannelData,
        10: quickSocialQuery.getInstagramPosts,
        1: quickPostQuery.getViewedPosts,
        12: quickSocialQuery.getLinkedinPosts,
        11: quickSocialQuery.getAllTwitterPosts,
      };
      const query = queryMap[activeStep];
      setIsLoading(true);
      if (query) {
        const payload = {
          query,
          variables: {
            ...(activeStep === 12 || activeStep === 11
              ? {
                  businessId: Number(userCurrentBusinessDetails?.id),
                }
              : {
                  userBusinessId: Number(userCurrentBusinessDetails?.id),
                }),
            pageNumber: page,
            pageSize: 10,
          },
        };
        try {
          const res = await handleApiRequest(payload);
          const responseData =
            activeStep === 9
              ? res?.getAllFacebookChannelData
              : activeStep === 10
              ? res?.getInstagramPosts
              : activeStep === 1
              ? res?.getViewedPosts
              : activeStep === 12
              ? res?.getLinkedinPosts
              : activeStep === 11
              ? res?.getAllTwitterPosts
              : null;
          if (responseData?.status === 200) {
            setPostDetails(responseData?.data ?? []);
            setTotalCount(responseData?.totalCount);
          } else {
            setPostDetails([]);
            console.error('Error fetching posts:', responseData?.message);
          }
        } catch (error) {
          console.error('API call failed:', error);
        }
      }
      setIsLoading(false);
    }
  }, [activeStep, userCurrentBusinessDetails?.id, page]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id && activeStep) fetchAgentSupportStatus();
  }, [activeStep, fetchAgentSupportStatus, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    setPage(1);
  }, [activeStep]);

  const getComment = useCallback(async () => {
    if (openDrawer) {
      try {
        const payload = {
          query:
            activeStep === 11
              ? quickSocialQuery.getReplyOnTweeterPost
              : quickSocialQuery.getPostCommentsAndSubCommentsForFacebook,
          variables: {
            userBusinessId: Number(userCurrentBusinessDetails?.id),
            postId: Number(selectedPostDrawer?.id),
          },
        };
        const result = await handleApiRequest(payload);
        const res =
          activeStep === 11
            ? result?.getReplyOnTweeterPost
            : result?.getPostCommentsAndSubCommentsForFacebook;
        if (res?.status === 200) {
          setComments(res?.data || []);
        }
      } catch (error) {
        console.error('An error occurred while fetching comments:', error);
      }
    }
  }, [userCurrentBusinessDetails?.id, selectedPostDrawer?.id, activeStep, openDrawer]);

  useEffect(() => {
    if (selectedPostDrawer?.id && userCurrentBusinessDetails?.id) {
      getComment();
    }
  }, [getComment, selectedPostDrawer?.id, userCurrentBusinessDetails?.id, activeStep]);

  const handleCreateBtn = (e) => {};

  const handleMenuClick = (event, menuId, data, index) => {
    setAnchorEl((prev) => ({ ...prev, [menuId]: event.currentTarget }));
    setSelectedMenu(menuId);
    setSelectedPost(data);
    setSelectedIndex(index);
  };

  const handleDrawerMenuClick = (event, menuId, data, index) => {
    setDrawerAnchorEl(event.currentTarget);
    setSelectedMenu(menuId);
  };

  const handleCloseMenu = (postId) => {
    setAnchorEl((prev) => ({ ...prev, [postId]: null }));
    setSelectedMenu(null);
  };

  const onDeletePost = async () => {
    try {
      setDel(true);
      const payload = {
        query:
          activeStep === 11 ? quickSocialQuery?.deleteTwitterPost : quickSocialQuery.deletePost,
        variables: {
          postId: Number(selectedPost?.id || selectedPostDrawer?.id),
          userBusinessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const result = await handleApiRequest(payload);
      const res = activeStep === 11 ? result?.deleteTwitterPost : result?.deletePost;
      if (res?.status === 200) {
        setShowDeletePost(false);
        setOpenDrawer(false);
        handleCloseMenu();
        fetchAgentSupportStatus();
        setSelectedPost({});
        enqueueSnackbar(t('quickSocial.postDeletedSuccessfully'), {
          variant: 'success',
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(res?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } catch (error) {
      console.error('Error deleting post:', error);
    } finally {
      setDel(false);
    }
  };
  const handleCloseEditPost = (data) => {
    setShowEditPost(false);
    setSelectedPost({});
  };

  const toggleExpandPost = (postId) => {
    setExpandedPosts((prevExpanded) => ({
      ...prevExpanded,
      [postId]: !prevExpanded[postId],
    }));
  };

  const toggleExpandDrawer = (postId) => {
    setExpandedPost((prevExpanded) => ({
      ...prevExpanded,
      [postId]: !prevExpanded[postId],
    }));
  };

  const handleSocialConnection = async (social) => {
    setLoading(true);
    try {
      let authResponse;
      if (social === 9 || social === 10) {
        authResponse = await handleFBLogin(appKey, fbConfigKey, inConfigKey, social);
        if (authResponse) {
          const fbAccessToken = authResponse?.accessToken;
          setFacebookUserId(authResponse?.userID);

          if (fbAccessToken && userCurrentBusinessDetails?.id) {
            await fetchSocialConnections(
              social,
              userCurrentBusinessDetails,
              setConnectionData,
              setAccessToken,
              setOpenDialog,
              setSelectedPage,
              setLoading,
              fbAccessToken,
              enqueueSnackbar
            );
          } else {
            console.log('Missing Facebook access token or business details.');
          }
        } else {
          console.log('Facebook login failed or was cancelled.');
        }
      } else if (social === 11) {
        history.push('/quick-social/social-connect/');
      } else if (social === 12) {
        setIsLinkedin(true);
        setOpenLinkedinPopUp(true);
      }
    } catch (error) {
      console.error('Error during social connection:', error);
    } finally {
      setLoading(false);
    }

    setSocialPlatformId(social);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleWritePost = () => {
    history.push('/quick-social/post', { passFrom: 'Dashboard' });
  };

  const handleTemplatePost = () => {
    setOpenTemplatePopUp(true);
  };

  const handleClosePopup = () => {
    setOpenDrawer(false);
    resetCommentTexts();
    resetImages();
  };

  const handleCloseTemplatePopUp = () => {
    setOpenTemplatePopUp(false);
  };

  const handleShare = (data) => {
    setOpenShare(true);
    setSelectedPost(data);
    handleCloseMenu();
  };

  const handleCloseShareDialog = () => {
    setOpenShare(false);
    setSelectedPost({});
  };

  const handleAnalytics = (post) => {
    setSelectedPost(post);
    setShowOptions(true);
    handleCloseMenu();
  };

  const formatTimeFromDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${day} ${month} ${year} ${hours}:${formattedMinutes} ${ampm}`;
  };

  const hasUnconnectedPlatforms = socialData?.some(
    (item) => !item?.isConnected && item?.reviewPlatformId === activeStep
  );

  const handleSubmitReplyComment = async (comment, level) => {
    setRefreshIcon(true);
    try {
      const payload = {
        query:
          activeStep === 11
            ? quickSocialQuery.replyOnTwitterPost
            : quickSocialQuery.commentOrReplyOnFacebookPost,
        variables: {
          userBusinessId: Number(userCurrentBusinessDetails?.id),
          media:
            level === 'levelOne' || level === 'levelTwo' || level === 'levelThree'
              ? replyImageUpload || null
              : imageUpload || null,
          ...(activeStep === 11
            ? {
                reply: commentText || '',
              }
            : {
                message:
                  level === 'levelOne' || level === 'levelTwo' || level === 'levelThree'
                    ? replyCommentText || null
                    : commentText || null,
                ...(comment && { commentId: comment?.id }),
              }),
          ...(!comment && { postId: Number(selectedPostDrawer?.id) }),
        },
      };
      const result = await imageDataToServer(payload);
      const res =
        activeStep === 11 ? result?.replyOnTwitterPost : result?.commentOrReplyOnFacebookPost;
      if (res?.status === 200) {
        getComment();
        setIsReply(false);
        resetCommentTexts();
        resetImages();
      }
      if (res?.status === 500) {
        enqueueSnackbar(res?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } catch (error) {
      console.error('An error occurred while submitting the reply:', error);
    } finally {
      setRefreshIcon(false);
    }
  };

  const handleDelComment = (id) => {
    setDelComment(true);
    setDelCommentId(id);
  };

  const deleteComment = async (id) => {
    setBtLoading(true);
    try {
      const payload = {
        query:
          activeStep === 11
            ? quickSocialQuery.deleteReplyByReplyId
            : quickSocialQuery.deleteFacebookComment,
        variables: {
          userBusinessId: Number(userCurrentBusinessDetails?.id),
          ...(activeStep === 11
            ? {
                replyId: Number(delCommentId),
              }
            : {
                commentId: Number(delCommentId),
              }),
        },
      };
      const result = await handleApiRequest(payload);
      const res = activeStep === 11 ? result?.deleteReplyByReplyId : res?.deleteFacebookComment;
      if (res?.status === 200) {
        getComment();
        resetCommentTexts();
        setDelComment(false);
        setIsReply(false);
      }
      if (res?.status === 400) {
        enqueueSnackbar(res?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } catch (error) {
      console.error('An error occurred while submitting the reply:', error);
    } finally {
      setBtLoading(false);
    }
  };

  const likeUnlikePost = async (post) => {
    try {
      const payload = {
        query: quickSocialQuery.likeOrUnlikeTwitterPost,
        variables: {
          userBusinessId: Number(userCurrentBusinessDetails?.id),
          postId: Number(post?.id),
          isLike: !post?.isLike,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.likeOrUnlikeTwitterPost?.status === 200) {
        fetchAgentSupportStatus();
        enqueueSnackbar(result?.likeOrUnlikeTwitterPost?.message, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(result?.likeOrUnlikeTwitterPost?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } catch (error) {
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      console.error('Error in likeUnlikePost:', error);
    }
  };

  const updateReplyLikes = (replies, commentId, isLike) => {
    return replies?.map((reply) => {
      if (reply?.id === commentId) {
        return {
          ...reply,
          isLike: !isLike,
          activity: {
            ...reply?.activity,
            likes: isLike ? reply.activity.likes - 1 : reply.activity.likes + 1,
          },
        };
      }
      if (reply?.replies && reply?.replies?.length > 0) {
        return {
          ...reply,
          replies: updateReplyLikes(reply?.replies, commentId, isLike),
        };
      }
      return reply;
    });
  };

  const handleLike = async (commentId, isLike, type, isAuthor, isRep = false) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    setSelectedPostDrawer((prev) => {
      if (prev && prev?.id === commentId) {
        const updatedDrawer = {
          ...prev,
          isLike: !isLike,
          activity: {
            ...prev?.activity,
            likes:
              activeStep === 11
                ? isLike
                  ? prev?.insights?.likeCount && prev.insights.likeCount - 1
                  : prev?.insights?.likeCount && prev.insights.likeCount - 1
                : isLike
                ? prev?.activity?.likes && prev.activity.likes - 1
                : prev?.activity?.likes && prev.activity.likes + 1,
          },
        };
        setPostDetails((prevDetails) =>
          prevDetails?.map((post) =>
            post?.id === commentId
              ? {
                  ...post,
                  isLike: updatedDrawer?.isLike,
                  activity: { ...updatedDrawer?.activity },
                }
              : post
          )
        );
        return updatedDrawer;
      }
      return prev;
    });

    setComments((prevComments) =>
      prevComments?.map((comment) => {
        if (isRep) {
          return {
            ...comment,
            replies: updateReplyLikes(comment?.replies, commentId, isLike),
          };
        }
        if (comment?.id === commentId && !isRep) {
          return {
            ...comment,
            isLike: !isLike,
            activity: {
              ...comment?.activity,
              likes:
                activeStep === 11
                  ? isLike
                    ? comment?.insights?.likeCount && comment.insights.likeCount - 1
                    : comment?.insights?.likeCount && comment.insights.likeCount - 1
                  : isLike
                  ? comment?.activity?.likes && comment.activity.likes - 1
                  : comment.activity?.likes && comment.activity.likes + 1,
            },
          };
        }
        return comment;
      })
    );
    const timeout = setTimeout(async () => {
      try {
        const payload = {
          query:
            activeStep === 11
              ? type === 'post'
                ? quickSocialQuery.likeOrUnlikeTwitterPost
                : quickSocialQuery.likeOrUnlikeTwitterReply
              : quickSocialQuery.likeAndUnlikeCommentAndPost,
          variables: {
            userBusinessId: Number(userCurrentBusinessDetails?.id),
            ...(activeStep === 11
              ? {
                  ...(type === 'post'
                    ? {
                        postId: type === 'post' ? Number(commentId) : null,
                      }
                    : {
                        replyId: Number(commentId),
                      }),
                }
              : {
                  commentId: type === 'comment' ? commentId : null,
                  postId: type === 'post' ? Number(commentId) : null,
                }),
            isLike: !isLike,
          },
        };
        const result = await handleApiRequest(payload);
        const res =
          activeStep === 11
            ? type === 'post'
              ? result?.likeOrUnlikeTwitterPost
              : result?.likeOrUnlikeTwitterReply
            : result?.likeAndUnlikeCommentAndPost;
        if (res?.status === 200) {
          getComment();
          if (!isAuthor) {
            fetchAgentSupportStatus();
          }
        } else {
          enqueueSnackbar(res?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        console.error('An error occurred while fetching comments:', error);
      }
    }, 3000);
    setDebounceTimeout(timeout);
  };

  const handleDrawer = (post) => {
    if (activeStep === 9) {
      setSelectedPostDrawer(post);
      setOpenDrawer(true);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleEdit = (data) => {
    setShowEditPost(true);
    setSelectedPost(data);
    handleCloseMenu();
  };

  const handleDelete = (data) => {
    setShowDeletePost(true);
    setSelectedPost(data);
    handleCloseMenu();
  };

  const handleCreatePostMenuClick = (event) => {
    setCreatePostAnchor(event.currentTarget);
    setCreatePostOpen(true);
  };

  const handleCreatePostMenuClose = () => {
    setCreatePostAnchor(null);
    setCreatePostOpen(false);
  };

  if (loading) {
    return <FuseLoading />;
  }
  return (
    <div className="lg:p-24 p-16">
      <div className="flex w-full items-center justify-between lg:mb-20 mb-16">
        <Typography
          component={motion?.span}
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          className="sm:flex md:text-28 text-20 font-bold mb-0"
        >
          {t('quickSocial.posts')}
        </Typography>
        <Button
          className="text-16 px-14 rounded-md"
          variant="contained"
          color="quickSocial"
          onClick={handleCreatePostMenuClick}
        >
          <span className="text-20 sm:text-28 mr-10">+</span>
          {t('createPost.title')}
        </Button>
        <Menu
          anchorEl={createPostAnchor}
          open={createPostOpen}
          onClose={handleCreatePostMenuClose}
          classes={{ paper: 'min-w-112' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiPaper-root': {
              boxShadow: '1px 1px 3px gray',
            },
          }}
        >
          <MenuItem onClick={() => handleTemplatePost()}>
            <BrokenImageOutlinedIcon className="text-quick-social text-20 mr-8" />
            <p className="text-16 font-500">{t('quickSocial.selectTemplate')}</p>
          </MenuItem>
          <MenuItem onClick={() => handleWritePost()}>
            <CreateOutlinedIcon className="border-1 border-quick-social p-1 rounded-6 text-quick-social text-20 mr-8" />
            <p className="text-16 font-500">{t('quickSocial.writeYourOwn')}</p>
          </MenuItem>
        </Menu>
      </div>
      <SocialPlatformTab
        onSelectTab={setActiveStep}
        socialData={socialData}
        setSocialData={setSocialData}
      />
      {!isLoading ? (
        <div>
          {postDetails?.length > 0 ? (
            <>
              {postDetails?.map((post, i) => {
                const isExpanded = expandedPosts[post?.id] || false;
                const text =
                  activeStep === 10
                    ? post?.caption
                    : activeStep === 9
                    ? post?.description
                    : activeStep === 12
                    ? post?.description
                    : activeStep === 11
                    ? post?.tweet
                    : '';
                return (
                  <div
                    className={
                      activeStep !== 1
                        ? 'bg-white rounded-md mb-16 p-16 sm:max-w-4xl sm:flex gap-16 '
                        : 'bg-white max-w-288 py-10 justify-center items-center flex flex-col mb-10'
                    }
                    key={i}
                    aria-hidden
                    // onClick={() => handleDrawer(post)}
                  >
                    {activeStep === 1 && (
                      <div className="flex gap-20 mb-20 ">
                        {loginUserData?.profileURL ? (
                          <img
                            src={loginUserData?.profileURL}
                            className="w-44 h-44 rounded-full inline-block"
                            alt="User Profile"
                          />
                        ) : (
                          <UserProfile name={loginUserData?.fullName} width="45px" height="45px" />
                        )}
                        <div>
                          <span className="block font-medium text-20 mb-4">
                            {post?.userName || post?.pageName}
                          </span>
                          <span className="text-16 font-medium">
                            {formatTimeFromDate(post?.cts)}
                          </span>
                        </div>
                      </div>
                    )}
                    {post?.media?.image ||
                    post?.mediaUrl ||
                    post?.share_post_template?.templateImage ||
                    post?.media?.[0]?.entityLocation ||
                    post?.media?.url ? (
                      <img
                        src={
                          activeStep === 9
                            ? post?.media?.image
                            : activeStep === 10
                            ? post?.mediaUrl
                            : activeStep === 1
                            ? post?.share_post_template?.templateImage
                            : activeStep === 12
                            ? post?.media?.[0]?.entityLocation
                            : activeStep === 11 && post?.media?.url
                        }
                        className="w-full h-auto sm:w-224 sm:h-224 rounded-md inline-block sm:mb-0 mb-10"
                        alt="Post"
                      />
                    ) : null}
                    <div className="w-full flex flex-col justify-between">
                      <div className="flex flex-col gap-20">
                        <div className="flex justify-between">
                          {activeStep !== 1 && (
                            <div className="flex gap-20">
                              {post?.profilePictureUrl || post?.userProfile || post?.profileUrl ? (
                                <img
                                  src={
                                    activeStep === 9
                                      ? post?.profilePictureUrl
                                      : activeStep === 10
                                      ? post?.userProfile
                                      : activeStep === 12
                                      ? post?.profileUrl
                                      : activeStep === 11 && post?.profilePictureUrl
                                  }
                                  className="w-60 h-60 rounded-full inline-block"
                                  alt="User Profile"
                                />
                              ) : (
                                <UserProfile
                                  name={post?.userName || post?.pageName}
                                  width="60px"
                                  height="60px"
                                  fontSize="25px"
                                  fontWeight="bold"
                                />
                              )}
                              <div>
                                <span className="block font-medium break-all text-16 sm:text-20 mb-4">
                                  {post?.userName || post?.pageName || post?.vanityName}
                                </span>
                                <span className="text-16 font-medium">
                                  {formatTimeFromDate(post?.createdTime || post?.createTime)}
                                </span>
                              </div>
                            </div>
                          )}
                          {activeStep !== 1 && (
                            <>
                              <IconButton
                                className="hover:bg-transparent !p-0 sm:!p-10"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleMenuClick(event, post?.id);
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEl?.[post?.id] || null}
                                open={Boolean(anchorEl?.[post?.id])}
                                onClose={(event) => {
                                  event.stopPropagation();
                                  handleCloseMenu(post?.id);
                                }}
                                classes={{ paper: 'min-w-112 shadow-none' }}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}
                                sx={{
                                  '& .MuiPaper-root': {
                                    boxShadow: '1px 1px 3px gray',
                                  },
                                }}
                              >
                                {/* {activeStep === 9 && (
                                  <MenuItem
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleEdit(post);
                                    }}
                                  >
                                    <Icon className="text-20 mr-8 ">{t('quickSocial.edit1')}</Icon>
                                    <span className="text-16 font-500">{t('common.edit')}</span>
                                  </MenuItem>
                                )} */}
                                {activeStep === 9 ||
                                  (activeStep === 11 && (
                                    <MenuItem
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        handleDelete(post);
                                      }}
                                    >
                                      <Icon className="text-20 mr-8 ">
                                        {t('quickSocial.delete1')}
                                      </Icon>
                                      <span className="text-16 font-500">{t('common.delete')}</span>
                                    </MenuItem>
                                  ))}
                                <MenuItem
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleShare(post);
                                  }}
                                >
                                  <ShareIcon className="text-20 mr-8 " />
                                  <p className="text-16 font-500">{t('createPost.shareBtn')}</p>
                                </MenuItem>
                                <MenuItem
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleAnalytics(post);
                                  }}
                                >
                                  <AnalyticsOutlinedIcon className="text-20 mr-8 " />
                                  <p className="text-16 font-500">
                                    {t('quickSocial.postAnalytics')}
                                  </p>
                                </MenuItem>
                              </Menu>
                            </>
                          )}
                        </div>
                        <div className="font-500 mb-4">
                          <p
                            className={`text-14 sm:text-16 ${
                              isExpanded ? 'whitespace-normal text-justify' : 'line-clamp-3'
                            } text-justify`}
                          >
                            {text}
                          </p>
                          {text &&
                            (post?.media?.image ||
                            post?.mediaUrl ||
                            post?.share_post_template?.templateImage
                              ? text?.length > 202
                              : text?.length > 311) && (
                              <Button
                                className="text-blue-500 mt-2 hover:bg-transparent"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  toggleExpandPost(post?.id);
                                }}
                              >
                                {isExpanded ? 'Show Less' : 'Read More'}
                              </Button>
                            )}
                        </div>
                      </div>
                      {activeStep !== 1 && (
                        <div
                          className="flex gap-28 mt-auto "
                          aria-hidden
                          onClick={() => {
                            if (activeStep === 9 || activeStep === 10 || activeStep === 12) {
                              setOpenDrawer(true);
                              resetCommentTexts();
                              setSelectedPostDrawer(post);
                            }
                          }}
                        >
                          <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center text-12 font-medium">
                            <span className="mx-auto inline-block">
                              {post?.isLike ? (
                                <FavoriteIcon
                                  className="text-18 mr-6 cursor-pointer text-red"
                                  onClick={() => {
                                    handleLike(post?.id, post?.isLike, 'post');
                                    setSelectedPostDrawer(post);
                                  }}
                                />
                              ) : (
                                <FavoriteBorderIcon
                                  className="text-18 mr-6 cursor-pointer"
                                  onClick={() => {
                                    handleLike(post?.id, post?.isLike, 'post');
                                    setSelectedPostDrawer(post);
                                  }}
                                />
                              )}
                              {activeStep === 9
                                ? Number(post?.id) === 442801
                                  ? commentsData.likes
                                  : post?.activity?.likes
                                : Number(post?.id) === 1409049
                                ? commentsData.likes
                                : post?.activity?.like_count}
                            </span>
                          </span>
                          <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center text-12 font-medium">
                            <span className="mx-auto inline-block">
                              <ChatBubbleOutlineIcon
                                className="text-18 mr-6 cursor-pointer"
                                onClick={() => {
                                  if (activeStep === 9 || activeStep === 11) {
                                    setOpenDrawer(true);
                                    resetCommentTexts();
                                    setSelectedPostDrawer(post);
                                  }
                                }}
                              />
                              {activeStep === 9
                                ? Number(post?.id) === 442801
                                  ? commentsData.comments.length
                                  : post?.activity?.comments
                                : Number(post?.id) === 1409049
                                ? commentsData.comments.length
                                : post?.activity?.comments_count}
                            </span>
                          </span>
                          <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center text-12 font-medium">
                            <span className="mx-auto inline-block">
                              <SendIcon className="text-18 mr-6" />
                              {activeStep === 9
                                ? Number(post?.id) === 442801
                                  ? commentsData.shares
                                  : post?.activity?.shares
                                : Number(post?.id) === 1409049
                                ? commentsData.shares
                                : post?.activity?.impression}
                            </span>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
              {totalCount > 10 && (
                <div className="bg-white px-52 py-32 bottom-0 right-0 w-full flex justify-end">
                  <Pagination
                    count={Math.ceil(totalCount / 10)}
                    page={page}
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton
                  />
                </div>
              )}
            </>
          ) : (
            <div>
              {hasUnconnectedPlatforms ? (
                <div className="flex w-full justify-center items-center ">
                  <div className="flex flex-col items-center justify-center text-center mt-112 w-400">
                    <img
                      src="assets/images/icon/socialNotConnectIcon.png"
                      alt="connect"
                      className="mb-16"
                    />
                    <h1 className="text-24 font-bold mb-16">
                      {t('quickSocial.connect')}{' '}
                      {(() => {
                        const name = socialData?.find(
                          (item) => !item?.isConnected && item?.reviewPlatformId === activeStep
                        )?.name;
                        return name ? name.charAt(0).toUpperCase() + name.slice(1) : '';
                      })()}{' '}
                      {t('quickSocial.account')}
                    </h1>
                    <p className="text-gray-600 font-medium mb-16">
                      Your{' '}
                      {socialData
                        ?.find(
                          (item) => !item?.isConnected && item?.reviewPlatformId === activeStep
                        )
                        ?.name?.replace(/^./, (char) => char.toUpperCase()) || ''}{' '}
                      {t('quickSocial.notConnectContent')}
                    </p>
                    <Button
                      variant="contained"
                      color="quickSocial"
                      className="rounded-md text-medium text-16 px-80"
                      onClick={() => handleSocialConnection(activeStep)}
                    >
                      {t('quickSocial.connectNow')}
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="flex w-full justify-center items-center">
                  <div className="flex flex-col items-center justify-center text-center mt-112">
                    <img
                      src="assets/images/icon/socialNoPost.png"
                      alt="connect"
                      className="mb-16"
                    />
                    <h1 className="text-24 font-bold mb-16"> {t('quickSocial.noPost')} </h1>
                    <p className="text-gray-600 font-medium mb-16">
                      {t('quickSocial.noPostContent')}
                    </p>
                    <Button
                      variant="contained"
                      color="quickSocial"
                      className="rounded-md text-medium text-20 px-80"
                      onClick={() => handleTemplatePost()}
                    >
                      {t('quickSocialScheduled.createPost')}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="w-full h-512 justify-center items-center flex">
          <FuseLoading />
        </div>
      )}

      {openTemplatePopUp && (
        <TemplatePopUp open={openTemplatePopUp} onClose={handleCloseTemplatePopUp} />
      )}

      {openShare && (
        <SharePost
          open={openShare}
          onClose={handleCloseShareDialog}
          url={
            selectedPost?.media?.permalink_url ||
            selectedPost?.mediaUrl ||
            selectedPost?.media?.[0]?.entityLocation ||
            selectedPost?.media?.url
          }
          image={
            selectedPost?.media?.permalink_url ||
            selectedPost?.postUrl ||
            selectedPost?.media?.[0]?.entityLocation ||
            selectedPost?.media?.url
          }
        />
      )}

      {showOptions && selectedPost && (
        <AnalyticsDialogBox
          open={showOptions}
          onClose={() => {
            setSelectedPost({});
            setShowOptions(false);
          }}
          post={selectedPost}
          activeStep={activeStep}
        />
      )}

      {showEditPost && selectedPost && (
        <PostEditDialogBox
          open={showEditPost}
          fetchAgentSupportStatus={fetchAgentSupportStatus}
          onClose={(data) => {
            handleCloseEditPost(data);
          }}
          post={selectedPost}
          activeStep={activeStep}
          setOpenDrawer={setOpenDrawer}
          setShowEditPost={setShowEditPost}
          handleCloseMenu={handleCloseMenu}
        />
      )}
      {showDeletePost && (
        <ConformationDialogBox
          open={showDeletePost}
          onDelete={() => onDeletePost()}
          onClose={() => {
            setSelectedPost({});
            setShowDeletePost(false);
          }}
          confirmationText="Are You Sure?"
          deleteImage="/assets/images/icon/socialDeleteIcon.png"
          backgroundColor="quick-social"
          textColor="quick-social"
          refresh={del}
          buttonText={t('common.delete')}
          additionalInfo={t('quickSocial.deletePostContent')}
        />
      )}
      <Drawer anchor="right" open={openDrawer} onClose={handleClosePopup} className="w-586">
        <div className="flex justify-between p-16 bg-white">
          <div className="flex gap-10 items-center">
            <ArrowBackIcon onClick={handleClosePopup} className="cursor-pointer" />

            <h2 className="text-20 font-600 mb-4 items-center flex">
              {t('quickSocial.postDetails')}
            </h2>
          </div>
          <div>
            {activeStep !== 1 && (
              <>
                <IconButton
                  className="hover:bg-transparent !p-0 sm:!p-10"
                  onClick={(event) => handleDrawerMenuClick(event, 'postMenus', selectedPostDrawer)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={selectedMenu === 'postMenus' ? drawerAnchorEl : null}
                  open={Boolean(drawerAnchorEl) && selectedMenu === 'postMenus'}
                  onClose={handleCloseMenu}
                  classes={{ paper: 'min-w-112 shadow-none' }}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      boxShadow: '1px 1px 3px gray',
                    },
                    mt: 5,
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleEdit(selectedPostDrawer);
                    }}
                  >
                    <Icon className="text-20 mr-8 ">{t('quickSocial.edit1')}</Icon>
                    <span className="text-16 font-500">{t('common.edit')}</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDelete(selectedPostDrawer);
                    }}
                  >
                    <Icon className="text-20 mr-8 ">{t('quickSocial.delete1')}</Icon>
                    <span className="text-16 font-500">{t('common.delete')}</span>
                  </MenuItem>
                  <MenuItem onClick={() => handleShare(selectedPostDrawer)}>
                    <ShareIcon className="text-20 mr-8 " />
                    <p className="text-16 font-500">{t('createPost.shareBtn')}</p>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleAnalytics(selectedPostDrawer);
                    }}
                  >
                    <AnalyticsOutlinedIcon className="text-20 mr-8 " />
                    <p className="text-16 font-500">{t('quickSocial.postAnalytics')}</p>
                  </MenuItem>
                </Menu>
              </>
            )}
          </div>
        </div>
        <div className=" bg-gray-A500 w-full sm:w-640 h-full rounded-md p-16">
          <div className="bg-white rounded-md p-16 border-gray-200 border border-solid">
            <div className="flex items-top flex-col gap-10 sm:gap-0 sm:flex-row  w-full sm:w-auto">
              {(selectedPostDrawer?.media?.image ||
                selectedPostDrawer?.media?.[0]?.entityLocation ||
                selectedPostDrawer?.media?.url) && (
                <img
                  src={
                    activeStep === 9
                      ? selectedPostDrawer?.media?.image
                      : activeStep === 10
                      ? selectedPostDrawer?.mediaUrl
                      : activeStep === 1
                      ? selectedPostDrawer?.share_post_template?.templateImage
                      : activeStep === 12
                      ? selectedPostDrawer?.media?.[0]?.entityLocation
                      : activeStep === 11
                      ? selectedPostDrawer?.media?.url
                      : ''
                  }
                  alt="..."
                  className="mr-0 sm:mr-20 w-auto sm:w-224 "
                />
              )}
              <div className="flex flex-col h-full">
                <div className="flex items-center mb-24 h-full">
                  <img
                    src={
                      activeStep === 9
                        ? selectedPostDrawer?.profilePictureUrl
                        : activeStep === 10
                        ? selectedPostDrawer?.userProfile
                        : activeStep === 11
                        ? selectedPostDrawer?.profilePictureUrl
                        : activeStep === 12
                        ? selectedPostDrawer?.profileUrl
                        : ''
                    }
                    alt="..."
                    width="48"
                    height="48"
                    className="mr-12 rounded-full"
                  />
                  <div>
                    <span className="text-20 font-medium block">
                      {selectedPostDrawer?.userName ||
                        selectedPostDrawer?.pageName ||
                        selectedPostDrawer?.localizedName}
                    </span>
                    <span className="text-16 block">
                      {formatTimeFromDate(
                        activeStep === 9
                          ? selectedPostDrawer?.createdTime
                          : activeStep === 10
                          ? selectedPostDrawer?.createTime
                          : null
                      )}
                    </span>
                  </div>
                </div>

                <div className="text-16 font-500 mb-4 max-w-288 min-h-96">
                  <p
                    className={`${
                      isExpandedS ? 'whitespace-normal text-justify' : 'line-clamp-3'
                    } text-justify`}
                  >
                    {texts}
                  </p>
                  {texts && texts?.length > 92 && (
                    <Button
                      className="text-blue-500 mt-2 hover:bg-transparent"
                      onClick={() => toggleExpandDrawer(selectedPostDrawer?.id)}
                    >
                      {isExpandedS ? 'Show Less' : 'Read More'}
                    </Button>
                  )}
                </div>

                <div className="mt-auto flex gap-28 justify-between items-end">
                  <Button
                    className="bg-gray-A500 text-12 font-medium py-5"
                    size="small"
                    onClick={() =>
                      handleLike(selectedPostDrawer?.id, selectedPostDrawer?.isLike, 'post')
                    }
                  >
                    {!selectedPostDrawer?.isLike || selectedPostDrawer?.activity?.likes === 0 ? (
                      <FavoriteBorderIcon className="text-18 mr-6" />
                    ) : (
                      <FavoriteIcon className="text-18 mr-6 text-red" />
                    )}

                    {selectedPostDrawer?.activity?.likes}
                  </Button>
                  <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center text-12 font-medium">
                    <span className="mx-auto inline-block">
                      <SendIcon className="text-18 mr-6" /> {selectedPostDrawer?.activity?.shares}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="p-8 border border-gray-300 rounded-lg mt-36">
              <textarea
                className="w-full border-none rounded-lg p-3 text-sm resize-none placeholder:text-18 placeholder:font-500 placeholder:text-gray-500"
                rows="3"
                placeholder="Add Comment"
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
              />
              <div className="flex items-center justify-between mt-2">
                <div className="flex space-x-2">
                  <IconButton
                    className="text-gray-500 hover:text-gray-700"
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  >
                    <EmojiEmotionsOutlinedIcon />
                  </IconButton>
                  {showEmojiPicker && (
                    <div ref={emojiPickerRef} className="absolute bottom-60 right-16">
                      <EmojiPicker
                        onEmojiClick={(emojiObject) => {
                          setCommentText((prevMsg) => prevMsg + emojiObject.emoji);
                          setShowEmojiPicker(false);
                        }}
                      />
                    </div>
                  )}
                  <IconButton
                    className="text-gray-500 hover:text-gray-700"
                    onClick={() => document.getElementById('file-input').click()}
                  >
                    <InsertPhotoOutlinedIcon />
                  </IconButton>
                  <input
                    id="file-input"
                    type="file"
                    accept=".jpg,.jpeg,.png,.gif"
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                  />
                </div>
                <Button
                  variant="contained"
                  color="quickSocial"
                  className="text-16 font-500 rounded-md py-5 px-10"
                  onClick={() => handleSubmitReplyComment()}
                  disabled={refreshIcon || (commentText === '' && selectedImage === null)}
                >
                  {t('agencyBusiness.clients.form.submit')}

                  {refreshIcon && (
                    <CircularProgress size={24} className="text-quick-social absolute mx-auto" />
                  )}
                </Button>
              </div>
            </div>
            {selectedImage && (
              <div className="mt-10 ml-10 relative">
                <div className="relative inline-block">
                  <img alt="..." className="w-120 h-68 capitalize text-40" src={selectedImage} />{' '}
                  <Button
                    className="absolute -right-10 -top-7 w-20 h-20 min-w-20 min-h-20 hover:bg-white bg-gray-A500"
                    onClick={handleRemoveImage}
                  >
                    <CloseIcon className="text-black text-lg" />
                  </Button>
                </div>
              </div>
            )}
            <hr className="my-16" />
            <div className="bg-gray-A500 p-16 rounded-md">
              {comments?.length > 0 && (
                <div className="mb-10">
                  <span className="font-semibold text-18 inline-block">
                    {t('quickSocial.comments')}
                  </span>
                  <span className="text-14 font-medium border border-solid border-grey-700 rounded-full px-12 py-2 ml-20 inline-block">
                    {comments?.length}
                  </span>
                </div>
              )}
              {comments && comments?.length > 0 ? (
                comments
                  ?.sort((a, b) => b.id - a.id)
                  ?.map((comment, i) => (
                    <div
                      key={i}
                      className="bg-white rounded-md p-16 border-gray-200 border border-solid mt-16"
                    >
                      <div className="flex items-top">
                        <div className="w-36">
                          <img
                            src={
                              activeStep === 11
                                ? comment?.author?.profileUrl
                                : comment?.commenter?.picture
                            }
                            alt="..."
                            width="36"
                            height="36"
                            className="mr-8 rounded-full h-36"
                          />
                        </div>
                        <div className="w-full">
                          <div className="">
                            <span className="text-16 font-semibold inline-block items-center">
                              {activeStep === 11
                                ? comment?.author?.username
                                : comment?.commenter?.name}
                            </span>
                            <span className="text-14 ml-10 inline-block items-center text-grey-500">
                              {formatDateWithTimeZone(
                                Number(comment?.createdTime) / 1000,
                                ' MMM DD YYYY',
                                'fromNow'
                              )}
                            </span>
                          </div>
                          <Typography className="text-14 py-16 font-medium max-w-480 text-grey-600 block">
                            {activeStep === 11 ? comment?.reply : comment?.message}
                            {comment?.media?.image && (
                              <img
                                src={comment?.media?.image}
                                alt="..."
                                className="h-96 w-192 rounded-lg"
                              />
                            )}
                          </Typography>
                          <div className="flex gap-28 mt-auto">
                            <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center text-12 font-medium">
                              <span className="mx-auto inline-block">
                                <Button
                                  className="hover:bg-transparent text-12 font-medium"
                                  onClick={() =>
                                    handleLike(
                                      comment?.id,
                                      comment?.isLike,
                                      'comment',
                                      comment?.isAuthor,
                                      false
                                    )
                                  }
                                >
                                  {!comment?.isLike ||
                                  (activeStep === 11
                                    ? comment?.insights?.likeCount === 0
                                    : comment?.activity?.likes === 0) ? (
                                    <FavoriteBorderIcon className="text-18 mr-6" />
                                  ) : (
                                    <FavoriteIcon className="text-18 mr-6 text-red" />
                                  )}
                                  {activeStep === 11
                                    ? comment?.insights?.likeCount
                                    : comment?.activity?.likes}
                                </Button>
                              </span>
                            </span>
                            {activeStep !== 11 && (
                              <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center">
                                <span className="mx-auto inline-block">
                                  <Button
                                    className="hover:bg-transparent text-12 font-medium"
                                    onClick={() => {
                                      empty();
                                      setIsReply((prev) =>
                                        selectUserToReply === comment?.id ? !prev : true
                                      );
                                      setSelectUserToReply(comment?.id);
                                    }}
                                  >
                                    <ChatBubbleOutlineIcon className="text-18 mr-6" />
                                    {t('quickSocial.reply')}
                                  </Button>
                                </span>
                              </span>
                            )}
                            <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center">
                              <span className="mx-auto inline-block">
                                <Button
                                  className="hover:bg-transparent text-12 font-medium"
                                  onClick={() => handleDelComment(comment?.id)}
                                  disabled={btLoading && selectUserToReply === comment?.id}
                                >
                                  <DeleteOutlineIcon className="text-18 mr-6" />{' '}
                                  {t('common.delete')}
                                  {btLoading && selectUserToReply === comment?.id && (
                                    <CircularProgress
                                      size={24}
                                      className="text-quick-social absolute mx-auto"
                                    />
                                  )}
                                </Button>
                              </span>
                            </span>
                          </div>
                          {isReply && selectUserToReply === comment?.id && (
                            <div className="items-center gap-8 mt-8 bg-gray-A500 p-10">
                              <textarea
                                className="w-full bg-gray-A500"
                                size="small"
                                rows="1"
                                placeholder={t('quickSocial.commentYourReplyHere')}
                                value={replyCommentText}
                                onChange={(e) => {
                                  // level 1
                                  if (selectUserToReply === comment?.id) {
                                    setReplyCommentText(e.target.value);
                                    setCommentText('');
                                    setSelectedImage(null);
                                    setImageUpload();
                                  }
                                }}
                              />

                              <div className="flex items-center justify-between mt-2">
                                <div className="flex space-x-2">
                                  <IconButton
                                    className="text-gray-500 hover:text-gray-700"
                                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                                  >
                                    <EmojiEmotionsOutlinedIcon />
                                  </IconButton>
                                  {showEmojiPicker && (
                                    <div
                                      ref={emojiPickerRef}
                                      className="absolute bottom-60 right-16"
                                    >
                                      <EmojiPicker
                                        onEmojiClick={(emojiObject) => {
                                          setReplyCommentText(
                                            (prevMsg) => prevMsg + emojiObject.emoji
                                          );
                                          setShowEmojiPicker(false);
                                        }}
                                      />
                                    </div>
                                  )}
                                  <IconButton
                                    className="text-gray-500 hover:text-gray-700"
                                    onClick={() =>
                                      document.getElementById(`file-input-${comment?.id}`).click()
                                    }
                                  >
                                    <InsertPhotoOutlinedIcon />
                                  </IconButton>
                                  <input
                                    id={`file-input-${comment?.id}`}
                                    type="file"
                                    accept=".jpg,.jpeg,.png,.gif"
                                    style={{ display: 'none' }}
                                    onChange={handlePhotoUpload}
                                  />
                                </div>
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="quickSocial"
                                  className="text-16 rounded-md py-5 px-10"
                                  onClick={() => handleSubmitReplyComment(comment, 'levelOne')}
                                  disabled={
                                    (selectUserToReply === comment?.id && refreshIcon) ||
                                    (replyCommentText === '' && replyImage === null)
                                  }
                                >
                                  {t('agencyBusiness.clients.form.submit')}
                                  {refreshIcon && selectUserToReply === comment?.id && (
                                    <CircularProgress
                                      size={24}
                                      className="text-quick-social absolute mx-auto"
                                    />
                                  )}
                                </Button>
                              </div>
                            </div>
                          )}
                          {replyImage && selectUserToReply === comment?.id && (
                            <div className="mt-10 ml-10 relative">
                              <div className="relative inline-block">
                                <img
                                  alt="Uploaded"
                                  className="w-120 h-68 capitalize text-40"
                                  src={replyImage}
                                />
                                <Button
                                  className="bg-white absolute -right-10 -top-7 w-20 h-20 min-w-20 min-h-20 hover:bg-white"
                                  onClick={handleRemoveImage}
                                >
                                  <CloseIcon className="text-black text-lg" />
                                </Button>
                              </div>
                            </div>
                          )}

                          {comment?.replies &&
                            comment?.replies?.length > 0 &&
                            comment?.replies?.map((reply, index) => (
                              <div key={index} className="mt-16">
                                <div className="ml-24">
                                  <div className="flex items-center">
                                    <img
                                      src={reply?.commenter?.picture}
                                      alt="..."
                                      width="36"
                                      height="36"
                                      className="mr-8 rounded-full"
                                    />
                                    <div className="block">
                                      <span className="text-16 font-semibold inline-block items-center">
                                        {reply?.commenter?.name}
                                      </span>
                                      <span className="text-14 ml-10 inline-block items-center text-grey-500">
                                        {formatDateWithTimeZone(
                                          reply?.createdTime,
                                          'MMM DD YYYY',
                                          'fromNow'
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <Typography className="text-14 py-16 font-medium max-w-480 text-grey-600 block">
                                    {reply?.message}
                                    {reply?.media?.image && (
                                      <img
                                        src={reply?.media?.image}
                                        alt="..."
                                        className="h-96 w-192 rounded-lg"
                                      />
                                    )}
                                  </Typography>
                                </div>
                                <div className="flex gap-28 mt-auto">
                                  <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center text-12 font-medium">
                                    <span className="mx-auto inline-block">
                                      <Button
                                        className="hover:bg-transparent text-12 font-medium"
                                        onClick={() =>
                                          handleLike(
                                            reply?.id,
                                            reply?.isLike,
                                            'comment',
                                            comment?.isAuthor,
                                            true
                                          )
                                        }
                                      >
                                        {!reply?.isLike || reply?.likes === 0 ? (
                                          <FavoriteBorderIcon className="text-18 mr-6" />
                                        ) : (
                                          <FavoriteIcon className="text-18 mr-6 text-red" />
                                        )}
                                        {reply?.activity?.likes}
                                      </Button>
                                    </span>
                                  </span>
                                  <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center text-12 font-medium">
                                    <span className="mx-auto inline-block">
                                      <Button
                                        className="hover:bg-transparent text-12 font-medium"
                                        onClick={() => {
                                          empty();
                                          setIsReply((prev) =>
                                            selectUserToReply === reply?.id ? !prev : true
                                          );
                                          setSelectUserToReply(reply?.id);
                                        }}
                                      >
                                        <ChatBubbleOutlineIcon className="text-18 mr-6" />{' '}
                                        {t('quickSocial.reply')}
                                      </Button>
                                    </span>
                                  </span>
                                  <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center">
                                    <span className="mx-auto inline-block">
                                      <Button
                                        className="hover:bg-transparent text-12 font-medium"
                                        onClick={() => handleDelComment(reply?.id)}
                                        disabled={btLoading && selectUserToReply === reply?.id}
                                      >
                                        <DeleteOutlineIcon className="text-18 mr-6" /> Delete
                                        {btLoading && (
                                          <CircularProgress
                                            size={24}
                                            className="text-quick-social absolute mx-auto"
                                          />
                                        )}
                                      </Button>
                                    </span>
                                  </span>
                                </div>
                                {isReply && selectUserToReply === reply?.id && (
                                  <div className=" items-center gap-8 mt-8  bg-gray-A500 p-10">
                                    <textarea
                                      className="w-full bg-gray-A500"
                                      size="small"
                                      rows="1"
                                      placeholder={t('quickSocial.commentYourReplyHere')}
                                      value={replyCommentText}
                                      onChange={(e) => {
                                        // level 2
                                        if (selectUserToReply === reply?.id) {
                                          setReplyCommentText(e.target.value);
                                          setCommentText('');
                                          setSelectedImage(null);
                                          setImageUpload();
                                        }
                                      }}
                                    />
                                    <div className="flex items-center justify-between mt-2">
                                      <div className="flex space-x-2">
                                        <IconButton
                                          className="text-gray-500 hover:text-gray-700"
                                          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                                        >
                                          <EmojiEmotionsOutlinedIcon />
                                        </IconButton>
                                        {showEmojiPicker && (
                                          <div
                                            ref={emojiPickerRef}
                                            className="absolute bottom-60 right-16"
                                          >
                                            <EmojiPicker
                                              onEmojiClick={(emojiObject) => {
                                                setReplyCommentText(
                                                  (prevMsg) => prevMsg + emojiObject.emoji
                                                );
                                                setShowEmojiPicker(false);
                                              }}
                                            />
                                          </div>
                                        )}
                                        <IconButton
                                          className="text-gray-500 hover:text-gray-700"
                                          onClick={() =>
                                            document
                                              .getElementById(`file-input-${reply?.id}`)
                                              .click()
                                          }
                                        >
                                          <InsertPhotoOutlinedIcon />
                                        </IconButton>
                                        <input
                                          id={`file-input-${reply?.id}`}
                                          type="file"
                                          accept=".jpg,.jpeg,.png,.gif"
                                          style={{ display: 'none' }}
                                          onChange={handleSubUpload}
                                        />
                                      </div>
                                      <Button
                                        variant="contained"
                                        size="small"
                                        color="quickSocial"
                                        className="text-16 rounded-md py-5 px-10 "
                                        onClick={() => handleSubmitReplyComment(reply, 'levelTwo')}
                                        disabled={
                                          (selectUserToReply === reply?.id && refreshIcon) ||
                                          (replyCommentText === '' && replyImage === null)
                                        }
                                      >
                                        {t('agencyBusiness.clients.form.submit')}
                                        {refreshIcon && selectUserToReply === reply?.id && (
                                          <CircularProgress
                                            size={24}
                                            className="text-quick-social absolute mx-auto"
                                          />
                                        )}
                                      </Button>
                                    </div>
                                  </div>
                                )}
                                {replyImage && selectUserToReply === reply?.id && (
                                  <div className="mt-10 ml-10 relative">
                                    <div className="relative inline-block">
                                      <img
                                        alt="Uploaded"
                                        className="w-120 h-68 capitalize text-40"
                                        src={replyImage}
                                      />
                                      <Button
                                        className="bg-white absolute -right-10 -top-7 w-20 h-20 min-w-20 min-h-20 hover:bg-white"
                                        onClick={handleRemoveImage}
                                      >
                                        <CloseIcon className="text-black text-lg" />
                                      </Button>
                                    </div>
                                  </div>
                                )}
                                <>
                                  {reply?.replies &&
                                    reply?.replies?.length > 0 &&
                                    reply?.replies?.map((replied) => (
                                      <div key={i} className="mt-16 ml-48">
                                        <div className="ml-24">
                                          <div className="flex items-center">
                                            <img
                                              src={replied?.commenter?.picture}
                                              alt="..."
                                              width="36"
                                              height="36"
                                              className="mr-8 rounded-full"
                                            />
                                            <div className="block">
                                              <span className="text-16 font-semibold inline-block items-center">
                                                {replied?.commenter?.name}
                                              </span>
                                              <span className="text-14 ml-10 inline-block items-center text-grey-500">
                                                {formatDateWithTimeZone(
                                                  replied?.createdTime,
                                                  'MMM DD YYYY',
                                                  'fromNow'
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                          <Typography className="text-14 py-16 font-medium max-w-480 text-grey-600 block">
                                            {replied?.message}
                                            {replied?.media?.image && (
                                              <img
                                                src={replied?.media?.image}
                                                alt="..."
                                                className="h-96 w-192 rounded-lg"
                                              />
                                            )}
                                          </Typography>
                                        </div>
                                        <div className="flex gap-28 mt-auto">
                                          <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center text-12 font-medium">
                                            <span className="mx-auto inline-block">
                                              <Button
                                                className="hover:bg-transparent text-12 font-medium"
                                                onClick={() => {
                                                  handleLike(
                                                    replied?.id,
                                                    replied?.isLike,
                                                    'comment',
                                                    comment?.isAuthor,
                                                    true
                                                  );
                                                }}
                                              >
                                                {!replied?.isLike || replied?.likes === 0 ? (
                                                  <FavoriteBorderIcon className="text-18 mr-6" />
                                                ) : (
                                                  <FavoriteIcon className="text-18 mr-6 text-red" />
                                                )}
                                                {replied?.activity?.likes}
                                              </Button>
                                            </span>
                                          </span>
                                          <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center text-12 font-medium">
                                            <span className="mx-auto inline-block">
                                              <Button
                                                className="hover:bg-transparent text-12 font-medium"
                                                onClick={() => {
                                                  empty();
                                                  setIsReply((prev) =>
                                                    selectUserToReply === replied?.id ? !prev : true
                                                  );
                                                  setSelectUserToReply(replied?.id);
                                                }}
                                              >
                                                <ChatBubbleOutlineIcon className="text-18 mr-6" />
                                                {t('quickSocial.reply')}
                                              </Button>
                                            </span>
                                          </span>
                                          <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center">
                                            <span className="mx-auto inline-block">
                                              <Button
                                                className="hover:bg-transparent text-12 font-medium"
                                                onClick={() => handleDelComment(replied?.id)}
                                                disabled={
                                                  btLoading && selectUserToReply === replied?.id
                                                }
                                              >
                                                <DeleteOutlineIcon className="text-18 mr-6" />{' '}
                                                {t('common.delete')}
                                                {btLoading && (
                                                  <CircularProgress
                                                    size={24}
                                                    className="text-quick-social absolute mx-auto"
                                                  />
                                                )}
                                              </Button>
                                            </span>
                                          </span>
                                        </div>
                                        {isReply && selectUserToReply === replied?.id && (
                                          <div className=" items-center gap-8 mt-8  bg-gray-A500 p-10">
                                            <textarea
                                              className="w-full bg-gray-A500"
                                              size="small"
                                              rows="1"
                                              placeholder={t('quickSocial.commentYourReplyHere')}
                                              value={replyCommentText}
                                              onChange={(e) => {
                                                // level 3
                                                if (selectUserToReply === replied?.id) {
                                                  setReplyCommentText(e.target.value);
                                                  setCommentText('');
                                                  setSelectedImage(null);
                                                  setImageUpload();
                                                }
                                              }}
                                            />
                                            <div className="flex items-center justify-between mt-2">
                                              <div className="flex space-x-2">
                                                <IconButton className="text-gray-500 hover:text-gray-700">
                                                  <EmojiEmotionsOutlinedIcon />
                                                </IconButton>
                                                {showEmojiPicker && (
                                                  <div
                                                    ref={emojiPickerRef}
                                                    className="absolute bottom-60 right-16"
                                                  >
                                                    <EmojiPicker
                                                      onEmojiClick={(emojiObject) => {
                                                        setReplyCommentText(
                                                          (prevMsg) => prevMsg + emojiObject.emoji
                                                        );
                                                        setShowEmojiPicker(false);
                                                      }}
                                                    />
                                                  </div>
                                                )}
                                                <IconButton
                                                  className="text-gray-500 hover:text-gray-700"
                                                  onClick={() =>
                                                    document
                                                      .getElementById(`file-input-${reply?.id}`)
                                                      .click()
                                                  }
                                                >
                                                  <InsertPhotoOutlinedIcon />
                                                </IconButton>
                                                <input
                                                  id={`file-input-${reply?.id}`}
                                                  type="file"
                                                  accept=".jpg,.jpeg,.png,.gif"
                                                  style={{ display: 'none' }}
                                                  onChange={handleSubSubUpload}
                                                />
                                              </div>
                                              <Button
                                                variant="contained"
                                                size="small"
                                                color="quickSocial"
                                                className="text-16 rounded-md py-5 px-10 "
                                                onClick={() =>
                                                  handleSubmitReplyComment(replied, 'levelThree')
                                                }
                                                disabled={
                                                  (selectUserToReply === replied?.id &&
                                                    refreshIcon) ||
                                                  (replyCommentText === '' && replyImage === null)
                                                }
                                              >
                                                {t('agencyBusiness.clients.form.submit')}
                                                {refreshIcon &&
                                                  selectUserToReply === replied?.id && (
                                                    <CircularProgress
                                                      size={24}
                                                      className="text-quick-social absolute mx-auto"
                                                    />
                                                  )}
                                              </Button>
                                            </div>
                                          </div>
                                        )}
                                        {replyImage && selectUserToReply === replied?.id && (
                                          <div className="mt-10 ml-10 relative">
                                            <div className="relative inline-block">
                                              <img
                                                alt="Uploaded"
                                                className="w-120 h-68 capitalize text-40"
                                                src={replyImage}
                                              />
                                              <Button
                                                className="bg-white absolute -right-10 -top-7 w-20 h-20 min-w-20 min-h-20 hover:bg-white"
                                                onClick={handleRemoveImage}
                                              >
                                                <CloseIcon className="text-black text-lg" />
                                              </Button>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                </>
                              </div>
                            ))}
                        </div>
                      </div>
                      {delComment && (
                        <ConformationDialogBox
                          open={delComment}
                          onDelete={() => deleteComment()}
                          onClose={() => setDelComment(false)}
                          confirmationText={t('quickSocial.areYouSure')}
                          deleteImage="/assets/images/icon/socialDeleteIcon.png"
                          backgroundColor="quick-social"
                          textColor="quick-social"
                          buttonText={t('common.delete')}
                          refresh={btLoading}
                          additionalInfo={t('quickSocial.deleteCommentContent')}
                        />
                      )}
                    </div>
                  ))
              ) : (
                <div className="flex justify-center items-center">
                  <h1 className="text-16 font-bold text-grey-600">
                    {t('quickSocial.beFirstToComment')}
                  </h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </Drawer>
      {connectionData?.length > 0 && openDialog && (
        <SelectPlatformBox
          onClose={handleClose}
          openDialog={openDialog}
          socialPlatformId={socialPlatformId}
          connectionData={connectionData}
          setSelectedPage={setSelectedPage}
          selectedPage={selectedPage}
          setOpenDialog={setOpenDialog}
          handleSocialResponse={() =>
            handleSocialResponse(
              socialPlatformId,
              accessToken,
              facebookUserId,
              selectedPage,
              userCurrentBusinessDetails,
              setLoading,
              enqueueSnackbar,
              setConnectionData,
              setOpenDialog,
              fetchAgentSupportStatus
            )
          }
          handleClose={handleClose}
        />
      )}
      {isLinkedin && (
        <Linkedin
          setIsLinkedin={setIsLinkedin}
          setLinkedinRefreshIcon={setLinkedinRefreshIcon}
          fetchAgentSupportStatus={fetchAgentSupportStatus}
          setOpenLinkedinPopUp={setOpenLinkedinPopUp}
          openLinkedinPopUp={openLinkedinPopUp}
        />
      )}
    </div>
  );
};

export default AllSharePost;
