import { Tab, Tabs, Typography } from '@mui/material';
import { selectDashboardData } from 'app/store/userSlice';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getParamsId, handleApiRequest } from 'src/app/main/common/common';
import quickHub from 'src/app/main/query/quickHub';
import { DownloadOutlined, SendOutlined } from '@mui/icons-material';
import OverallReports from '../../OverallRports';
import BusinessVideo from './BusinessVideo';
import SharePost from '../../../quick-post/SharePost';

const Documents = () => {
  const getTabData = (isPreSales) => [
    ...(!isPreSales
      ? [
          {
            id: 1,
            label: t('documents.welcomeLetter'),
            buttonName: t('documents.welcomeLetter'),
            url: 'quick-hub/openingAndClosingHour',
            value: 'WORKING_HOURS',
          },
        ]
      : []),
    {
      id: 2,
      label: t('documents.monthlyReport'),
      buttonName: t('documents.monthlyReport'),
      url: 'overall-reports',
      value: 'SERVICES',
    },
    // ...(!isPreSales
    //   ? [
    //       {
    //         id: 3,
    //         label: t('documents.businessVideo'),
    //         buttonName: t('documents.businessVideo'),
    //         url: 'quick-hub/attributes',
    //         value: 'ATTRIBUTES',
    //         name: 'Welcome Video',
    //       },
    //     ]
    //   : []),
  ];
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(null);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [welcomeLetter, setWelcomeLetter] = useState(null);
  const [isPreSalesUser, setIsPreSalesUser] = useState(null);
  const [tabData, setTabData] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [reportData, setReportData] = useState(location?.state?.overAllFullReport || []);
  const [pdfUrl, setPdfUrl] = useState();
  const [openShare, setOpenShare] = useState(false);

  const fetchUserPreparationData = useCallback(async () => {
    try {
      const payload = {
        query: quickHub.getUserPreparationData,
        variables: {
          businessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getUserPreparationData?.status === 200) {
        setWelcomeLetter(result?.getUserPreparationData?.data?.welcomeLetter);
      }
    } catch (err) {
      console.error('Error fetching user preparation data:', err);
    }
  }, [userCurrentBusinessDetails?.id]);

  const getActiveTabLabel = () => {
    const currentTab = tabData.find((tab) => tab.id === activeTab);
    return currentTab?.label || '';
  };

  const IconWrapper = ({ children }) => (
    <div className="w-28 h-28 flex items-center justify-center rounded-full bg-white hover:bg-gray-400 transition-colors duration-200">
      {children}
    </div>
  );

  const handleCloseDeleteDialog = () => {
    setOpenShare(false);
  };

  const fetchDashboardData = useCallback(
    async (id) => {
      const fetchPayload = (query, variables) => ({
        query,
        variables: { ...variables },
      });

      try {
        const result = await handleApiRequest(
          fetchPayload(quickHub.businessPerformanceReports, {
            userBusinessId: id || userCurrentBusinessDetails?.id,
          })
        );

        if (result?.businessPerformanceReports?.data) {
          setReportData(result.businessPerformanceReports.data);
        }
      } catch (error) {
        console.error('Error fetching report data', error);
      }
    },
    [userCurrentBusinessDetails?.id]
  );

  const handleDownloadPDF = () => {
    if (welcomeLetter) {
      const link = document.createElement('a');
      link.href = welcomeLetter; // PDF URL
      link.target = '_blank';
      link.download = 'Welcome_Letter.pdf'; // Name of the downloaded file
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('No PDF URL found');
    }
  };

  const DocumentHeader = ({ title }) => (
    <div className="flex items-center justify-between w-[55%] mb-4">
      <Typography className="text-20 sm:text-28 font-bold">{title}</Typography>
      {activeTab === 1 && (
        <div className="flex gap-10">
          <IconWrapper>
            <DownloadOutlined
              className="w-16 h-16 cursor-pointer text-gray-600 hover:text-gray-900"
              onClick={handleDownloadPDF}
            />
          </IconWrapper>
          <IconWrapper>
            <SendOutlined
              className="w-16 h-16 cursor-pointer text-gray-600 hover:text-gray-900 transform -rotate-45"
              onClick={(e) => {
                e.stopPropagation();
                setPdfUrl(welcomeLetter);
                setOpenShare(true);
              }}
            />
          </IconWrapper>
        </div>
      )}
    </div>
  );

  useEffect(() => {
    const fetchBusinessData = async () => {
      try {
        const payload = {
          query: quickHub.getAllBusinessesOnUserId,
        };
        const result = await handleApiRequest(payload);
        const businessData = result.getAllBusinessOnUserId.data;
        const isPreSales =
          Array.isArray(businessData) &&
          businessData.some(
            (business) => business.id === userCurrentBusinessDetails?.id && business.isPreSales
          );
        setIsPreSalesUser(isPreSales);
        fetchUserPreparationData();
        const newTabData = getTabData(isPreSales);
        const filteredTabData = newTabData?.filter((tab) => !(tab?.id === 1 && !welcomeLetter));
        setTabData(filteredTabData);
        const redirectedTabId = location?.state?.id;
        if (redirectedTabId) {
          const tabExists = filteredTabData?.some((tab) => tab?.id === redirectedTabId);
          setActiveTab(tabExists ? redirectedTabId : filteredTabData[0]?.id);
        } else {
          setActiveTab(filteredTabData[0]?.id);
        }
      } catch (err) {
        console.error('Error fetching user preparation data:', err);
      }
    };

    fetchBusinessData();
  }, [
    userCurrentBusinessDetails?.id,
    location?.state?.id,
    welcomeLetter,
    fetchUserPreparationData,
  ]);

  useEffect(() => {
    if (activeTab === 2) {
      if (!location?.state?.overAllFullReport || reportData?.length === 0) {
        fetchDashboardData(userCurrentBusinessDetails?.id);
      }
    }
  }, [
    activeTab,
    fetchDashboardData,
    location?.state?.overAllFullReport,
    reportData?.length,
    userCurrentBusinessDetails?.id,
  ]);

  useEffect(() => {
    const fetchBusinessData = async () => {
      try {
        const payload = {
          query: quickHub.getAllBusinessesOnUserId,
        };
        const result = await handleApiRequest(payload);
        setIsPreSalesUser(result.getAllBusinessOnUserId.data);
        const businessData = result.getAllBusinessOnUserId.data;
        const isPreSales =
          Array.isArray(businessData) &&
          businessData.some(
            (business) => business.id === userCurrentBusinessDetails?.id && business.isPreSales
          );

        setIsPreSalesUser(isPreSales);
        setTabData(getTabData(isPreSales));
        if (activeTab === 2 && !isPreSales) {
          setActiveTab(1);
        }
      } catch (err) {
        console.error('Error fetching user preparation data:', err);
      }
    };

    if (activeTab === 1) {
      fetchBusinessData();
    }
  }, [activeTab, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    const fetchDashboard = async () => {
      try {
        const payload = {
          query: quickHub.getDashboard,
        };
        const result = await handleApiRequest(payload);
      } catch (err) {
        console.error('Error fetching user preparation data:', err);
      }
    };

    if (activeTab === 1) {
      fetchDashboard();
    }
  }, [activeTab]);

  return (
    <div className="bg-gray-A500 min-h-screen">
      <Typography className="text-20 sm:text-28 font-bold pt-16 sm:pt-16 px-16 sm:px-24">
        {t('listManagement.documents')}
      </Typography>
      <div className="p-16 sm:p-24">
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          {tabData?.map((tab, index) => {
            return (
              <Tab
                className={`sm:text-20 font-medium p-0 sm:mr-36 mr-16 ${
                  Number(activeTab) === Number(tab?.id) ? '!text-quick-hub' : 'text-black'
                }`}
                value={tab?.id}
                label={tab?.label}
                key={index}
              />
            );
          })}
        </Tabs>
        <hr className="border-1 border-solid !border-gray" />
        <div>
          {/* <Typography className="text-20 sm:text-28 font-bold pt-16 sm:pt-16 px-16 sm:px-24">
            {activeTab === 1 && getActiveTabLabel()}
            {activeTab === 3 && 'Welcome Video'}
          </Typography> */}
          {activeTab === 1 && welcomeLetter && (
            <>
              <DocumentHeader title="Welcome Letter" />
              <div className="mt-4">
                <iframe
                  src={`${welcomeLetter}#toolbar=0`}
                  allowfullscreen
                  width="55%"
                  height="800px"
                  style={{ border: 'none' }}
                  title="Welcome Letter PDF"
                />
              </div>
            </>
          )}
          {activeTab === 2 && <OverallReports reportData={reportData} />}
          {/* {activeTab === 3 && (
            <>
              <DocumentHeader title="Welcome Video" />
              <BusinessVideo />
            </>
          )} */}
          {openShare && pdfUrl && (
            <SharePost
              open={openShare}
              onClose={handleCloseDeleteDialog}
              image={pdfUrl}
              businessId={userCurrentBusinessDetails?.id}
              plateFormID={location?.state}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Documents;
