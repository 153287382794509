import React, { useState, useMemo, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './Landing.css';
import {
  Typography,
  Box,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import AppleLogin from 'react-apple-login';
import AppleIcon from '@mui/icons-material/Apple';
import jwtDecode from 'jwt-decode';
import { Star, Visibility, VisibilityOff } from '@mui/icons-material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import history from '@history';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import { publicIpv4 } from 'public-ip';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import userService from 'src/app/services';
import {
  checkEmailExistOrNot,
  checkPhoneNumberExistOrNot,
  getDataToServerForAuth,
  getDecryptData,
  getEncryptedData,
  sendOTPOnPhoneNumberVerification,
  verifyPhoneOtp,
} from '../common/common';
import queryData from '../query/common';
import Query from '../query/auth';
import { getCountryData, setUserData } from '../../store/userSlice';
import { getLocation } from '../sign-up/store/signUpSlice';

function Landing() {
  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required(t('signUpPage.formField.firstName.validationMessages.required'))
      .max(15, t('signUpPage.formField.firstName.validationMessages.max'))
      .matches(/^[a-zA-Z]+$/, t('signUpPage.formField.firstName.validationMessages.matches')),
    lastName: yup
      .string()
      .required(t('signUpPage.formField.lastName.validationMessages.required'))
      .max(15, t('signUpPage.formField.lastName.validationMessages.max'))
      .matches(/^[a-zA-Z]+$/, t('signUpPage.formField.lastName.validationMessages.matches')),
    email: yup.string().email(t('common.enterAValidEmail')).trim(),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
      ),
  });

  const [visibleImages, setVisibleImages] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [isOtpSendAndVerify, setIsOtpSendAndVerify] = useState(false);
  const [otp, setOtp] = useState('');
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(59);
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [countryList, setCountryList] = useState([]);
  const countriesData = useSelector(getCountryData);
  const countryDataString = localStorage.getItem('countries');
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [ipBasedData, setIpBasedData] = useState(
    localStorage.getItem('ipData') ? JSON.parse(getDecryptData(localStorage.getItem('ipData'))) : ''
  );
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    isGoogleSignUp: false,
    accessToken: '',
    phoneNumber: {
      countryCode: ipBasedData?.countryCode || '+91',
      phoneNumber: '',
      isoCode: ipBasedData?.isoCode || 'IN',
    },
  });

  const productData = useMemo(
    () => [
      {
        label: 'Quick Review',
        image: 'assets/images/quick-hub/quick-review.svg',
        id: 0,
        content_heading:
          'Your <span class="text-landing-page text-bold">reputation matters.</span>',
        content_body:
          'With Quick Reviews, you can manage and respond to reviews from platforms like Google and Yelp in one place. Analyze trends, uncover actionable insights, and ensure your customers feel heard. Multi-location businesses? No problem—track reviews across all your branches effortlessly.',
        content_img: 'assets/images/landing/product-desc/quick-review.png',
      },
      {
        label: 'Quick Social',
        image: 'assets/images/quick-hub/quick-social.svg',
        id: 2,
        content_heading:
          'Be everywhere <span class="text-landing-page text-bold">your audience is</span>',
        content_body:
          'Quick Social enables you to create stunning posts using templates, schedule them across platforms, and analyze engagement—all from a single dashboard. Build your brand effortlessly with the power of consistency and creativity.',
        content_img: 'assets/images/landing/product-desc/quick-social.png',
      },
      {
        label: 'Quick Chat',
        image: 'assets/images/quick-hub/quick-chat.svg',
        id: 1,
        content_heading:
          'Never miss a <span class="text-landing-page text-bold">conversation.</span>',
        content_body:
          'Quick Chat lets you handle customer inquiries, support requests, and bot interactions from a unified inbox. Keep response times low and satisfaction high by managing all your customer touchpoints seamlessly.',
        content_img: 'assets/images/landing/product-desc/quick-chat.png',
      },
      {
        label: 'Quick Campaign',
        image: 'assets/images/quick-hub/quick-campaigns.svg',
        id: 3,
        content_heading:
          'Supercharge your <span class="text-landing-page text-bold">outreach.</span>',
        content_body:
          'Use Quick Campaign to craft targeted marketing campaigns via email, SMS, and WhatsApp. Whether you’re welcoming new customers or promoting a seasonal offer, you’ll have the tools to build meaningful connections and drive engagement.',
        content_img: 'assets/images/landing/product-desc/quick-Campaign.png',
      },
      {
        label: 'Quick Ads',
        image: 'assets/images/quick-hub/quick-ads.svg',
        id: 4,
        content_heading:
          'Make your <span class="text-landing-page text-bold">advertising easy and impactful.</span>',
        content_body:
          'Quick Ads simplifies launching Facebook and Instagram campaigns by helping you target the right people, set your budget, and define your goals. Focus on connecting with the right audience. we’ll handle the rest.',
        content_img: 'assets/images/landing/product-desc/quick-ads.png',
      },
    ],
    []
  );

  const features = [
    {
      label: 'Save Time',
      image: 'assets/images/landing/save-time.png',
      id: 0,
      content_body: 'Automate processes and focus on what matters most—your growth.',
    },
    {
      label: 'All-in-One',
      image: 'assets/images/landing/all-in-one.png',
      id: 1,
      content_body: 'No need to juggle multiple tools; everything you need is here.',
    },
    {
      label: 'Data-Driven Insights',
      image: 'assets/images/landing/data-driven.png',
      id: 0,
      content_body: 'Make smarter decisions with comprehensive analytics.',
    },
    {
      label: 'Easy Integration',
      image: 'assets/images/landing/easy-integration.png',
      id: 0,
      content_body: 'Seamlessly connect with platforms you already use.',
    },
  ];

  const feedBack = [
    {
      customer_name: 'Rahul',
      review:
        'I just tried it out, and I am seeing fascinating results in the increase of reviews. It is very simple to provide reviews to our customers, and I am very satisfied with the service and value that Quick Reviews delivers for our customers and business growth. Thank you, Quick Reviews, for helping us grow our business.',
      business_name: 'DSC Vadodara',
      image: 'assets/images/landing/testimonial/rahul.png',
    },
    {
      customer_name: 'Sayali',
      review:
        'After downloading Quick Reviews, we noticed sudden changes in our Google review count. Our customers appreciate the process of obtaining reviews by simply scanning the QR code and selecting attributes. It is simple and quick, leading to growth in our business. Thank you, Quick Reviews!',
      business_name: 'Hakuna Matata',
      image: 'assets/images/landing/testimonial/sayali.png',
    },
    {
      customer_name: 'Ram',
      review:
        'Quick Reviews has made our review acquisition process incredibly easy for both our customers and our business. It has facilitated the growth of our reviews, which in turn is boosting our Google ratings. We can easily See all the reviews and analytics in the quick reviews app which helps to make better decisions.',
      business_name: 'Paneerwala',
      image: 'assets/images/landing/testimonial/ram.png',
    },
    {
      customer_name: 'Narayan',
      review:
        'Quick Reviews helps us increase our reviews on Google Maps. I can effortlessly share positive reviews across all our social media accounts. by receiving the very best reviews, we are growing our business effectively.',
      business_name: 'Shafe Kababi',
      image: 'assets/images/landing/testimonial/narayan.png',
    },
    {
      customer_name: 'Shubham',
      review:
        'Quick Hub made it so easy to get started! Within minutes, I had my business profile set up, connected my social media, and launched my first campaign. The tools are simple, powerful, and have already helped me grow my customer base. I wish I had started sooner!',
      business_name: 'Chimo Shoes',
      image: 'assets/images/landing/testimonial/chimo.png',
    },
  ];

  const steps = [
    {
      label: 'Sign Up',
      image: 'assets/images/landing/sign-up.png',
      id: 0,
      content_body: 'Create your Quick Hub account in minutes.',
    },
    {
      label: 'Customize',
      image: 'assets/images/landing/customize.png',
      id: 1,
      content_body: 'Set up your business preferences and connect platforms.',
    },
    {
      label: 'Launch',
      image: 'assets/images/landing/launch.png',
      id: 2,
      content_body: 'Start managing and growing your business seamlessly.',
    },
  ];

  useEffect(() => {
    if (!ipBasedData) {
      const ipAddress = publicIpv4();
      const ipAddressData = async () => {
        console.log('await ipAddress', await ipAddress);
        const payload = {
          query: queryData.getLocation,

          variables: {
            data: getEncryptedData(`{"ipAddress": "${await ipAddress}"}`),
          },
        };
        const locationResponse = await dispatch(getLocation(payload));
        if (locationResponse?.payload?.data?.getLocation?.data) {
          const locationData = locationResponse?.payload?.data?.getLocation?.data;
          if (locationData) {
            setIpBasedData(JSON.parse(getDecryptData(locationData)));
            localStorage.setItem('ipData', locationData);
          }
        }
      };
      ipAddressData();
    }
  }, [dispatch, ipBasedData]);

  useEffect(() => {
    setCountryList(countriesData || JSON.parse(countryDataString));
  }, [countriesData, countryDataString]);

  const customLabels = useMemo(() => {
    if (!Array.isArray(countryList)) {
      return {};
    }

    return countryList.reduce((acc, country) => {
      acc[country.isoCode] = {
        primary: country.name,
        secondary: `+${country.countryCode}`,
        isoCode: country.isoCode,
        countryId: country?.id,
      };
      return acc;
    }, {});
  }, [countryList]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds]);

  useEffect(() => {
    productData.forEach((_, index) => {
      setTimeout(() => {
        setVisibleImages((prev) => [...prev, index]);
      }, index * 1000);
    });
  }, [productData]);

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    try {
      await schema.validateAt(name, { [name]: value });
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    } catch (err) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: err.message,
      }));
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleOtpChange = (value) => {
    const numericValue = value.replace(/\D/g, '');
    setOtp(numericValue);
  };

  const responseGoogle = async (response) => {
    const decodeData = jwtDecode(response?.credential);
    if (response) {
      try {
        const googleResponse = JSON.stringify({
          accessToken: response.credential,
        });
        const payload = {
          query: Query.asPreviouslyRegesterInGoogle,
          variables: { data: getEncryptedData(googleResponse) },
        };
        const registrationResult = await getDataToServerForAuth(payload);
        if (registrationResult?.asPreviouslyRegesterInGoogle?.available === true) {
          setUserDetails((prev) => ({
            ...prev,
            firstName: decodeData?.given_name,
            lastName: decodeData?.family_name,
            email: decodeData?.email,
            isGoogleSignUp: true,
            accessToken: response?.credential,
          }));
        } else {
          enqueueSnackbar(t('signInPage.messages.notRegistered'), {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        enqueueSnackbar(t('signInPage.messages.errorOccurred'), {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    }
  };

  const handleSuccess = (authResult) => {
    const { refreshToken, accessToken, user } = authResult;
    localStorage.removeItem('userPackageIds');
    if (refreshToken) {
      history.push('/welcome');
      dispatch(setUserData(user));
      userService.setSession(accessToken);
    }
  };

  const checkExistData = async () => {
    const checkEmailExist = await checkEmailExistOrNot(userDetails?.email, enqueueSnackbar);
    if (!checkEmailExist) return false;
    const checkPhoneNumberExist = await checkPhoneNumberExistOrNot(
      userDetails?.phoneNumber?.phoneNumber?.slice(
        userDetails?.phoneNumber?.countryCode?.slice(1)?.length
      ),
      enqueueSnackbar
    );
    return checkPhoneNumberExist;
  };

  const handleSendOtpOnPhone = async () => {
    try {
      setRefreshIcon(true);
      const isDataValid = await checkExistData();
      if (!isDataValid) {
        setRefreshIcon(false);
        return;
      }
      const phoneNumber = userDetails?.phoneNumber?.phoneNumber?.slice(
        userDetails?.phoneNumber?.countryCode?.slice(1)?.length
      );
      const countryCode = userDetails?.phoneNumber?.countryCode;
      const isOtpSent = await sendOTPOnPhoneNumberVerification(
        phoneNumber,
        countryCode,
        enqueueSnackbar
      );
      if (isOtpSent) {
        setIsOtpSendAndVerify(true);
        setSeconds(59);
      }
    } catch (error) {
      console.error('Error in handleSendOtpOnPhone:', error);
    } finally {
      setRefreshIcon(false);
    }
  };

  const resendOtp = async () => {
    setOtp('');
    const sendOtpOnPhone = await sendOTPOnPhoneNumberVerification(
      userDetails?.phoneNumber?.phoneNumber?.slice(
        userDetails?.phoneNumber?.countryCode?.slice(1)?.length
      ),
      userDetails?.phoneNumber?.countryCode,
      enqueueSnackbar
    );
    if (sendOtpOnPhone) {
      setIsOtpSendAndVerify(true);
      setSeconds(59);
    }
  };

  const onSubmit = async () => {
    try {
      const verifyOtp = await verifyPhoneOtp(
        userDetails?.phoneNumber?.phoneNumber?.slice(
          userDetails?.phoneNumber?.countryCode?.slice(1)?.length
        ),
        userDetails?.phoneNumber?.countryCode,
        otp,
        enqueueSnackbar
      );
      if (!verifyOtp) return;
      setRefreshIcon(true);
      const matchingLabel = Object.entries(customLabels).find(
        ([key, value]) => value.isoCode === userDetails?.phoneNumber?.isoCode
      )?.[1];

      const obj = {
        firstName: userDetails?.firstName,
        lastName: userDetails?.lastName,
        phoneNumber: {
          countryCode: userDetails?.phoneNumber?.countryCode,
          phoneNumber: Number(
            userDetails?.phoneNumber?.phoneNumber?.slice(
              userDetails?.phoneNumber?.countryCode?.slice(1)?.length
            )
          ),
          isoCode: userDetails?.phoneNumber?.isoCode,
        },
        countryId: matchingLabel?.countryId || ipBasedData?.countryId,
        isSocialUser: true,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        isQuickHubProduct: true,
        userPackageIds:
          ipBasedData?.countryId === 2
            ? [Number(process.env.REACT_APP_PACKAGE_BUNDLE_IND_SUBSCRIPTION_ID)]
            : [Number(process.env.REACT_APP_PACKAGE_BUNDLE_USA_SUBSCRIPTION_ID)],
        e: userDetails?.email || '',
        accessToken: userDetails?.isGoogleSignUp ? userDetails?.accessToken : '',
        ...(!userDetails?.isGoogleSignUp && { p: userDetails?.password }),
      };
      const payload = {
        query: userDetails?.isGoogleSignUp ? queryData.googleAuthV3 : queryData.signupV3,
        variables: { data: getEncryptedData(JSON.stringify(obj)) },
      };
      const result = await getDataToServerForAuth(payload);
      setRefreshIcon(false);
      if (!result) {
        throw new Error('No response from server');
      }
      const { googleAuthV3, signupV3, appleAuthV2 } = result || {};
      if (userDetails?.isGoogleSignUp && googleAuthV3?.status === 200) {
        handleSuccess(googleAuthV3);
      } else if (userDetails?.isAppleSignUp && appleAuthV2?.status === 200) {
        handleSuccess(appleAuthV2);
      } else if (signupV3?.status === 200) {
        handleSuccess(signupV3);
      } else {
        enqueueSnackbar(t(result?.message), {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error in onSubmit:', error);
    }
  };

  return (
    <div className="custom-font main-landing">
      <header className="bg-[#111218] md:py-24 py-16 relative">
        <div className="custom-container">
          <img
            src="https://quick-review.s3.us-east-1.amazonaws.com/logo/logo_white_horizontal.png"
            className="md:w-256 w-200"
            alt="Quick hub"
          />
        </div>
        <img
          src="assets/images/landing/line-green.png"
          className="absolute -bottom-0 w-full"
          alt=""
        />
      </header>
      <div className="bg-[#0b0b0b] lg:py-112 md:py-80 py-36 relative top-bg">
        <div className="custom-container">
          <div className="md:grid md:grid-cols-2 items-center">
            <div>
              <h2 className="text-white md:text-56 text-28 font-bold custom-font">Automate Your</h2>
              <h2 className="text-landing-page md:text-[80px] text-36 font-bold">Marketing</h2>
              <div className="border-b-1 border-solid pb-14 flex items-center !border-landing-page md:mb-32 mb-16 relative before:w-5 before:h-5 before:bg-landing-page before:inline-block before:rounded-full before:absolute before:right-0 before:-bottom-3">
                <h3 className="md:text-36 text-24 text-white lg:mr-48 mr-12">with Quick Hub</h3>
                <div className="flex items-center">
                  {productData.map((product, index) => (
                    <img
                      key={product.id}
                      src={product.image}
                      alt={product.label}
                      className={`mx-2 transition-opacity md:w-48 md:h-48 w-28 h-28 duration-1000 ${
                        visibleImages.includes(index) ? 'opacity-100' : 'opacity-0'
                      }`}
                    />
                  ))}
                </div>
              </div>
              <Typography className="md:text-24 text-16 font-medium text-white max-w-400">
                Manage reviews, grow your social presence, engage with customers, run campaigns and
                launch ads—all in one powerful platform.
              </Typography>
            </div>
            {!isOtpSendAndVerify ? (
              <div className="rounded-[40px] form-bg-landing p-16 md:p-20 max-w-512 mx-auto mt-10 md:mt-0 shadow-lg border border-gray-700">
                <div className="text-white text-left max-w-288 text-xl md:text-2xl">
                  Get started with your 15-Days free trial.
                </div>
                <div className="grid md:grid-cols-2 grid-cols-1 gap-x-16">
                  <div className="mt-16 space-y-4">
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Enter First Name"
                      error={!!errors.firstName}
                      helperText={errors.firstName}
                      name="firstName"
                      size="large"
                      value={userDetails?.firstName}
                      onChange={handleChange}
                      InputProps={{
                        sx: {
                          borderRadius: '20px',
                          backgroundColor: '#0D0D0D',
                          color: 'white',
                          fontWeight: 'normal',
                          minHeight: '66px',
                          fontSize: '20px',
                          '& fieldset': {
                            borderColor: '#0D0D0D !important',
                            minHeight: '66px',
                            padding: '10px 16px',
                          },
                          '&:hover fieldset': { borderColor: '#0D0D0D !important' },
                          '&.Mui-focused fieldset': { borderColor: '#0D0D0D !important' },
                          '& input::placeholder': {
                            fontWeight: 'normal',
                            fontSize: '20px',
                          },
                        },
                      }}
                    />
                  </div>
                  <div className="space-y-4 mt-16">
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={userDetails?.lastName}
                      placeholder="Enter Last Name"
                      error={!!errors.lastName}
                      helperText={errors.lastName}
                      name="lastName"
                      onChange={handleChange}
                      InputProps={{
                        sx: {
                          borderRadius: '20px',
                          backgroundColor: '#0D0D0D',
                          color: 'white',
                          fontWeight: 'normal',
                          minHeight: '66px',
                          fontSize: '20px',
                          '& fieldset': {
                            borderColor: '#0D0D0D !important',
                            minHeight: '66px',
                            padding: '10px 16px',
                          },
                          '&:hover fieldset': { borderColor: '#0D0D0D !important' },
                          '&.Mui-focused fieldset': { borderColor: '#0D0D0D !important' },
                          '& input::placeholder': {
                            fontWeight: 'normal',
                            fontSize: '20px',
                          },
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="space-y-4 mt-16">
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Enter Email ID"
                    error={!!errors.email}
                    helperText={errors.email}
                    name="email"
                    disabled={userDetails?.isGoogleSignUp}
                    value={userDetails?.email}
                    onChange={handleChange}
                    InputProps={{
                      sx: {
                        borderRadius: '20px',
                        backgroundColor: '#0D0D0D',
                        color: 'white',
                        fontWeight: 'normal',
                        minHeight: '66px',
                        fontSize: '20px',
                        '& fieldset': {
                          borderColor: '#0D0D0D !important',
                          minHeight: '66px',
                          padding: '10px 16px',
                        },
                        '&:hover fieldset': { borderColor: '#0D0D0D !important' },
                        '&.Mui-focused fieldset': { borderColor: '#0D0D0D !important' },
                        '& input::placeholder': {
                          fontWeight: 'normal',
                          fontSize: '20px',
                        },
                      },
                    }}
                  />
                </div>
                {!userDetails?.isGoogleSignUp && (
                  <div className="space-y-4 mt-16">
                    <TextField
                      fullWidth
                      variant="outlined"
                      type={showPassword ? 'text' : 'password'}
                      error={!!errors.password}
                      helperText={errors.password}
                      name="password"
                      value={userDetails?.password}
                      onChange={handleChange}
                      placeholder="Password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleTogglePassword} edge="end">
                              {showPassword ? (
                                <Visibility className="text-white" />
                              ) : (
                                <VisibilityOff className="text-white" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        sx: {
                          borderRadius: '20px',
                          backgroundColor: '#0D0D0D',
                          color: 'white',
                          fontWeight: 'normal',
                          minHeight: '66px',
                          fontSize: '20px',
                          '& fieldset': {
                            borderColor: '#0D0D0D !important',
                            minHeight: '66px',
                            padding: '10px 16px',
                          },
                          '&:hover fieldset': { borderColor: '#0D0D0D !important' },
                          '&.Mui-focused fieldset': { borderColor: '#0D0D0D !important' },
                          '& input::placeholder': {
                            fontWeight: 'normal',
                            fontSize: '20px',
                          },
                        },
                      }}
                    />
                  </div>
                )}
                <div className="mt-16">
                  <PhoneInput
                    placeholder={t('common.phoneNumber')}
                    country={ipBasedData?.isoCode?.toLowerCase()}
                    value={userDetails.phoneNumber.phoneNumber}
                    enableSearch="true"
                    onChange={(value, country) => {
                      setUserDetails((prev) => ({
                        ...prev,
                        phoneNumber: {
                          countryCode: `+${country.dialCode}`,
                          phoneNumber: value.replace(`+${country.dialCode}`, ''),
                          isoCode: country.countryCode?.toUpperCase(),
                        },
                      }));
                    }}
                    inputStyle={{
                      width: '100%',
                      height: '66px',
                      backgroundColor: '#0D0D0D',
                      borderRadius: '14px',
                      border: '1px solid #0D0D0D',
                      color: 'white',
                      fontSize: '16px',
                      paddingLeft: '50px',
                      fontWeight: 'normal',
                    }}
                    buttonStyle={{
                      backgroundColor: '#0D0D0D',
                      borderRadius: '20px 0 0 20px',
                      border: '1px solid #0D0D0D',
                    }}
                    dropdownStyle={{
                      backgroundColor: '#101010',
                      color: 'white',
                    }}
                    containerStyle={{
                      width: '100%',
                    }}
                    className="custom-phone-input w-full"
                  />
                </div>

                <div className="text-white text-16 mt-16 font-400">
                  By continuing you agree to our{' '}
                  <a
                    href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
                    target="_blank"
                    rel="noreferrer noopener"
                    role="button"
                    className="text-landing-page hover:underline cursor-pointer"
                  >
                    {t('signUpPage.labels.privacyPolicy')}
                  </a>
                  ,{' '}
                  <a
                    href={process.env.REACT_APP_TERMS_OF_SERVICE_LINK}
                    target="_blank"
                    rel="noreferrer noopener"
                    role="button"
                    className="text-landing-page hover:underline cursor-pointer"
                  >
                    {t('signUpPage.labels.termsService')}
                  </a>
                  ,{' '}
                  <a
                    href={process.env.REACT_APP_EULA_LINK}
                    target="_blank"
                    rel="noreferrer noopener"
                    role="button"
                    className="text-landing-page hover:underline cursor-pointer"
                  >
                    {t('signUpPage.labels.eula')}
                  </a>{' '}
                  and{' '}
                  <a
                    href={process.env.REACT_APP_DISCLAIMER_LINK}
                    target="_blank"
                    rel="noreferrer noopener"
                    role="button"
                    className="text-landing-page hover:underline cursor-pointer"
                  >
                    {t('signUpPage.labels.disclaimers')}
                  </a>
                </div>
                <Button
                  className="disabled:bg-[#B8B8B8] disabled:text-[#4D4D4D] my-14 py-3 text-20"
                  fullWidth
                  variant="contained"
                  sx={{
                    backgroundColor: '#C5F46B',
                    color: 'black',
                    fontWeight: 'bold',
                    minHeight: '66px',
                    borderRadius: '12px',
                    '&:hover': { backgroundColor: 'rgb(173, 238, 95)' },
                  }}
                  onClick={() => handleSendOtpOnPhone()}
                  disabled={
                    !userDetails?.firstName ||
                    !userDetails?.lastName ||
                    !userDetails?.email ||
                    (!userDetails?.isGoogleSignUp && !userDetails?.password) ||
                    !userDetails?.phoneNumber?.phoneNumber ||
                    (userDetails?.phoneNumber?.phoneNumber &&
                      userDetails?.phoneNumber?.countryCode &&
                      userDetails?.phoneNumber?.phoneNumber
                        .toString()
                        .slice(userDetails?.phoneNumber?.countryCode?.toString().slice(1).length)
                        .length < 10) ||
                    Object.values(errors).some((error) => error !== '') ||
                    refreshIcon
                  }
                >
                  Start free trial
                  {refreshIcon && (
                    <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                  )}
                </Button>
                <div className="text-center text-white text-14">Or Sign-In using</div>
                <div className="mt-16 flex justify-center google-sign">
                  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                    <div className="w-full">
                      <GoogleLogin
                        logo_alignment="center"
                        width="200"
                        height="40"
                        theme="filled_black"
                        text="signup_with"
                        className="custom-button-log"
                        onSuccess={responseGoogle}
                        onError={responseGoogle}
                      />
                    </div>
                  </GoogleOAuthProvider>
                </div>
              </div>
            ) : (
              <div className="rounded-3xl max-w-md mx-auto w-400 p-16 md:p-20 h-max form-bg-landing">
                <div className="flex items-center text-white text-lg font-semibold">
                  OTP Verification
                </div>
                <p className="text-16 text-white font-semibold pt-6">
                  Enter the 6-digit code sent to
                </p>
                <div className="flex items-center mt-2 space-x-2">
                  <span className="text-white text-16 font-semibold">
                    {userDetails?.phoneNumber?.countryCode}{' '}
                    {userDetails?.phoneNumber?.phoneNumber?.slice(
                      userDetails?.phoneNumber?.countryCode?.slice(1)?.length
                    )}
                  </span>
                  <button
                    type="button"
                    className="pl-4"
                    onClick={() => setIsOtpSendAndVerify(false)}
                  >
                    <img className="w-16 h-16" src="assets/images/landing/edit.svg" alt="Edit" />
                  </button>
                </div>
                <div className="flex justify-center mt-16 w-full">
                  <MuiOtpInput
                    length={6}
                    value={otp}
                    onChange={handleOtpChange}
                    TextFieldsProps={{
                      sx: {
                        width: '45px',
                        height: '45px',
                        backgroundColor: '#0D0D0D',
                        borderRadius: '8px',
                        color: 'white',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        '& input': {
                          color: 'white',
                          textAlign: 'center',
                          fontSize: '20px',
                          fontWeight: 'bold',
                        },
                        '& fieldset': { borderColor: '#0D0D0D !important' },
                        '&:hover fieldset': { borderColor: '#0D0D0D !important' },
                        '&.Mui-focused fieldset': { borderColor: '#0D0D0D !important' },
                      },
                      inputProps: {
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        type: 'tel',
                      },
                    }}
                  />
                </div>
                <div className="text-white text-16 font-semibold mt-20 flex items-center gap-2 whitespace-nowrap">
                  <span>Didn't receive OTP ? </span>
                  <Button
                    className="items-center text-[#CBF688] font-semibold h-12 min-h-16 max-h-12 disabled:text-white text-white text-16 py-0"
                    disabled={minutes > 0 || seconds > 0}
                    onClick={resendOtp}
                  >
                    {minutes === 0 && seconds === 0 ? (
                      <span className="underline">Resend Code</span>
                    ) : (
                      `Resend in ${String(seconds).padStart(2, '0')} sec`
                    )}
                  </Button>
                </div>

                <Button
                  fullWidth
                  variant="contained"
                  className="mt-14 mb-6 py-3 bg-[#c1fa64] hover:bg-[#c1fa64] disabled:bg-[#B8B8B8] disabled:text-[#4D4D4D] text-black font-bold rounded-xl"
                  onClick={() => onSubmit()}
                  disabled={!otp || otp?.length < 6 || refreshIcon}
                >
                  Verify
                  {refreshIcon && (
                    <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                  )}
                </Button>
              </div>
            )}
          </div>
        </div>
        <img
          src="assets/images/landing/line-green.png"
          className="absolute -bottom-0 w-full"
          alt=""
        />
      </div>
      <div className="bg-[#272727] py-32 md:mb-72 mb-36">
        <div className="custom-container">
          <Typography className="md:text-36 text-landing-page mb-16 text-24 text-center block">
            Platform Integrations
          </Typography>
          <div className="md:flex grid grid-cols-3 gap-4 items-center justify-between">
            <img src="assets/images/landing/partner_logo/google.png" className="" alt="google" />
            <img src="assets/images/landing/partner_logo/meta.png" className="" alt="meta" />
            <img
              src="assets/images/landing/partner_logo/truspilot.png"
              className=""
              alt="truspilot"
            />
            <img src="assets/images/landing/partner_logo/amazon.png" className="" alt="amazon" />
            <img src="assets/images/landing/partner_logo/yelp.png" className="" alt="yelp" />
          </div>
        </div>
      </div>

      <div className="custom-container text-center mb-48">
        <Typography className="md:text-36 text-24 font-semibold leading-none">
          What Makes Quick Hub
        </Typography>
        <Typography className="md:text-36 text-24 font-semibold md:mb-56 mb-28">
          Your <span className="text-landing-page-A500 font-bold">Ultimate Growth Partner?</span>
        </Typography>

        <div className="bg-[#e0e0e0] rounded-3xl border-1 border-solid border-[#9B9B9B] mb-40 ">
          <div className="flex rounded-3xl w-full bg-gray-300 relative">
            {productData.map((tab, index) => (
              <motion.div
                key={tab.id}
                aria-hidden
                initial={{ opacity: 0.5 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0.3 }}
                transition={{ duration: 0.3, ease: 'easeIn' }}
                onClick={() => setActiveTab(index)}
                className={`cursor-pointer transition-all md:py-24 py-12 text-center mx-auto relative tab-common
              ${activeTab === index ? 'text-white tab-active' : 'tab-normal'}`}
                style={{ width: `${100 / productData.length}%` }}
              >
                <span className="mr-4 inline-block align-middle z-10 relative">
                  <img src={tab.image} className="md:w-36 md:h-36 w-20 h-20" alt={tab.label} />
                </span>
                <span
                  className="md:text-16 text-14 font-medium inline-block align-middle z-10 relative"
                  key={index}
                >
                  {tab.label}
                </span>
              </motion.div>
            ))}
          </div>

          <AnimatePresence mode="wait">
            <motion.div
              key={activeTab}
              initial={{ opacity: 0.5 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0.3 }}
              transition={{ duration: 0.3, ease: 'easeIn' }}
              className={`bg-[#272727] text-white rounded-b-3xl mx-auto text-left min-h-400 md:p-32 p-16 tab-content max-h-480 relative ${
                activeTab === 0 && 'rounded-tl-none'
              } ${activeTab === productData.length - 1 && 'rounded-tr-none'}`}
            >
              {productData.map((tab, index) => (
                <div key={index} aria-hidden>
                  {activeTab === index && (
                    <>
                      <Typography className="font-semibold md:text-48 text-24 mb-20">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: tab?.content_heading,
                          }}
                        />
                      </Typography>
                      <Typography className="mt-3 text-white md:text-28 text-16 max-w-[900px]">
                        {tab.content_body}
                      </Typography>
                      <div className="text-right absolute right-16 bottom-16">
                        <motion.img
                          src={tab.content_img}
                          className="ml-auto"
                          alt="..."
                          initial={{ opacity: 0, scale: 0.8 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.3, ease: 'easeInOut' }}
                        />
                      </div>
                    </>
                  )}
                </div>
              ))}
            </motion.div>
          </AnimatePresence>
        </div>

        <Typography className="mx-auto max-w-786 md:text-[22px] text-16 font-semibold mb-24">
          <span className="text-landing-page-A500 font-bold">
            Save up to 40% with the Quick Hub Bundle
          </span>{' '}
          and gain access to an integrated system that helps you manage your business more
          efficiently.
        </Typography>

        <Button
          variant="contained"
          size="medium"
          className="text-white md:px-20 md:py-10 rounded-lg shadow-lg md:text-24 text-16 font-medium md:min-h-56"
          style={{ background: 'linear-gradient(90deg, #4F37B4 0%, #7863D1 99.78%)' }}
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          Get the Full Suite at an Exclusive Price
        </Button>
      </div>

      <div className="bg-[#0B0B0B] md:pt-40 pt-20 md:pb-120 md:mb-120 mb-36 pb-36">
        <div className="custom-container md:mb-120 mb-36">
          <Typography className="md:text-36 text-24 font-semibold md:mb-56 mb-28 text-center text-white">
            Why <span className="text-landing-page font-bold">Businesses Love</span>
            <span className="block"> Quick Hub ?</span>
          </Typography>
          <div className="md:grid md:grid-cols-4 grid-cols-2 gap-16">
            {features.map((fea, index) => (
              <div key={index} className="rounded-[42px] p-1 text-center md:mb-0 mb-10 outer-bg">
                <div className="rounded-[42px] md:p-24 p-16 bg-[#2d2d2d] h-full">
                  <div className="mb-16 h-80">
                    <img src={fea.image} className="mx-auto mb-16" alt="" />
                  </div>
                  <Typography className="font-semibold text-20 text-white mb-16">
                    {fea.label}
                  </Typography>
                  <Typography className="text-white text-16 max-w-208 mx-auto">
                    {fea.content_body}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className=" max-w-[1564px] mx-auto text-center">
          <div className="mx-16">
            <Typography className="md:text-36 text-24 font-medium md:mb-56 mb-28 text-center text-white">
              Real Results Real Feedback
            </Typography>
            <div className="grid md:grid-cols-3 gap-24 justify-center">
              {feedBack.map((testimonial, index) => (
                <div key={index} className="rounded-2xl p-24 text-center bg-[#2d2d2d]">
                  <>
                    <div className="flex justify-start mb-10">
                      {[...Array(5)].map((_, i) => (
                        <Star key={i} className="text-yellow-400" fontSize="small" />
                      ))}
                    </div>
                    <p className="text-gray-300 md:text-20 text-16 text-left mb-12">
                      {testimonial.review}
                    </p>
                    <div className="block">
                      <div className="text-left flex items-center justify-between">
                        <div>
                          <img
                            src={testimonial.image}
                            className="w-40 h-40  inline-block align-middle mr-10"
                            alt=".."
                          />
                          <p className="font-bold text-16 text-[#BCB9B4] inline-block align-middle">
                            {testimonial.customer_name}
                          </p>
                        </div>

                        <p className="text-gray-400 text-14">{testimonial.business_name}</p>
                      </div>
                    </div>
                  </>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="custom-container md:mb-80 mb-36">
        <div>
          <Typography className="md:text-36 text-24 font-semibold md:mb-56 mb-28 text-center">
            Get Started in <span className="text-landing-page-A500 font-bold">3 Easy Steps</span>
          </Typography>
        </div>
        <div className="sm:flex text-center justify-between">
          {steps.map((step, index) => (
            <div key={index} className="steps-ui relative mb-16">
              <img
                src={step.image}
                className="w-112 h-112 inline-block align-middle relative z-9999"
                alt=".."
              />
              <span className="block text-20 font-bold my-10">{step.label}</span>
              <Typography className="text-16 font-medium w-200 mx-auto">
                {step.content_body}
              </Typography>
            </div>
          ))}
        </div>
      </div>
      <div className="custom-container">
        <div className="md:flex items-center justify-between">
          <div className="max-w-586">
            <Typography className="md:text-36 text-24 font-medium md:leading-none mb-20">
              Join Thousands of Businesses Growing with Quick Hub
            </Typography>
            <Typography className="text-16 mb-20">
              Quick Hub is transforming the way businesses manage operations—bringing together
              reviews, campaigns, chats, social media, and ads into one seamless platform. Ready to
              simplify your work and boost your growth? Let’s get started today.
            </Typography>
            <Button
              variant="contained"
              size="medium"
              className="text-white px-20 py-10 rounded-lg shadow-lg md:text-24 text-16 font-medium md:min-h-56"
              style={{ background: 'linear-gradient(90deg, #4F37B4 0%, #7863D1 99.78%)' }}
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
              Start Your Free Trial Now
            </Button>
          </div>
          <img
            src="assets/images/landing/app.png"
            className="max-w-480 w-full md:mt-0 mt-20 img-responsive"
            alt="..."
          />
        </div>
      </div>
      <footer className="bg-[#0b0b0b] pt-24 md:pb-48 pb-24">
        <div className="custom-container">
          <div className="md:flex items-center justify-between mb-28">
            <img
              src="https://quick-review.s3.us-east-1.amazonaws.com/logo/logo_white_horizontal.png"
              className="md:w-256 w-200"
              alt="Quick hub"
            />
            <div className="block text-center md:mt-0 mt-16">
              <a
                href={process.env.REACT_APP_TERMS_OF_SERVICE_LINK}
                role="button"
                target="_blank"
                rel="noreferrer noopener"
                aria-hidden
                className="inline-block align-middle leading-none text-white hover:text-landing-page mr-6 pr-6 border-r-1 border-solid border-grey"
              >
                Terms of Service
              </a>
              <a
                href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
                role="button"
                target="_blank"
                rel="noreferrer noopener"
                aria-hidden
                className="inline-block align-middle leading-none text-white hover:text-landing-page"
              >
                Privacy Policy
              </a>
            </div>
          </div>
          <Typography className="block text-white text-16 text-center">
            The names and logos of Quick Hub are trademarks of Cloudpeak Technologies Pvt Ltd. All
            other trademarks, logos, and product names mentioned are the property of their
            respective owners. Comparison data is accurate as of June 15th, 2024, and is subject to
            change.
          </Typography>
        </div>
      </footer>
    </div>
  );
}

export default Landing;
