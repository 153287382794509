import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  Drawer,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import history from '@history';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import { Elements } from '@stripe/react-stripe-js';
import quickHub from '../../../query/quickHub';
import paymentData from '../../../query/payment';
import userService from '../../../../services';
import {
  duration,
  formatDateWithTimeZone,
  handleApiRequest,
  findExpireDay,
  getEncryptedData,
  getDecryptData,
} from '../../../common/common';
import { getAllUserBusinessSubscription, selectDashboardData } from '../../../../store/userSlice';
import StripePayment from './StripePayment';

const SelectPlan = () => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const loginUserData = userService.getUserData();
  const [productData, setProductData] = useState([]);
  const [isCheckOut, setIsCheckOut] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState('subscription');
  const [packageIds, setPackageIds] = useState([]);
  const [packageIntervalType, setPackageIntervalType] = useState('month');
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [comboDetails, setComboDetails] = useState();
  const [loading, setLoading] = useState(false);
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const { enqueueSnackbar } = useSnackbar();
  const countryId = Number(loginUserData?.countryId);
  const [stripe, setStripe] = useState(null);
  const [stripePaymentSuccess, setStripePaymentSuccess] = useState(false);
  const [stripePaymentSuccessPopUp, setStripePaymentSuccessPopUp] = useState(false);
  const [stripePaymentFailedPopUp, setStripePaymentFailedPopUp] = useState(false);
  const [cardDetails, setCardDetails] = useState([]);
  const [paymentMethodId, setPaymentMethodId] = useState('');
  const [addCardDetails, setAddCardDetails] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      setPackageIds(location.state?.packageBundle || []);
      setPackageIntervalType(location.state?.intervalType || 'month');
      setPaymentDetails(location.state?.paymentDetails || 'subscription');
      if (location?.state?.specialOffer) {
        setIsCheckOut(true);
        setPackageIds(location?.state?.packageBundle);
      }
    }
  }, [location.state]);

  const loadStripeScript = () =>
    new Promise((resolve, reject) => {
      if (window.Stripe) {
        resolve(window.Stripe);
        return;
      }
      if (document.querySelector('script[src="https://js.stripe.com/v3/"]')) {
        // Wait until the existing script loads
        const interval = setInterval(() => {
          if (window.Stripe) {
            clearInterval(interval);
            resolve(window.Stripe);
          }
        }, 100);
        return;
      }

      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/';
      script.onload = () => resolve(window.Stripe);
      script.onerror = () => reject(new Error('Stripe SDK failed to load.'));
      document.body.appendChild(script);
    });

  const fetchCardDetails = async () => {
    try {
      const payload = {
        query: quickHub.getStripeCustomerCardDetails,
      };
      const result = await handleApiRequest(payload);
      if (result?.getStripeCustomerCardDetails?.status === 200) {
        try {
          const decryptedData = getDecryptData(result?.getStripeCustomerCardDetails?.data);
          const parsedData = JSON.parse(decryptedData);
          setCardDetails(parsedData);
        } catch (parseError) {
          console.error('Error parsing card details:', parseError);
        }
      }
    } catch (err) {
      console.error('API Fetch Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const setDefaultStripeCard = async (cardData) => {
    try {
      const setDefaultCard = JSON.stringify({
        paymentMethodId: cardData?.paymentMethodId,
        stripeCustomerId: cardDetails?.stripeCustomerId,
      });
      const payload = {
        query: quickHub.setDefaultStripeCard,
        variables: {
          data: getEncryptedData(setDefaultCard),
        },
      };
      const result = await handleApiRequest(payload);

      if (result?.setDefaultStripeCard?.status === 200) {
        await fetchCardDetails();
        enqueueSnackbar('Your default payment method has been updated successfully.', {
          variant: 'success',
          autoHideDuration: 2000,
        });
      }
    } catch (err) {
      console.error('API Fetch Error:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadStripeInstance = async () => {
      try {
        if (window.Stripe) {
          setStripe(window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY));
          return;
        }
        const stripeInstance = await loadStripeScript();
        setStripe(stripeInstance(process.env.REACT_APP_STRIPE_PUBLIC_KEY));
      } catch (error) {
        console.error('Error loading Stripe:', error);
      }
    };

    loadStripeInstance();
  }, []);

  useEffect(() => {
    const defaultPayment = cardDetails?.card?.find((item) => item?.isDefault);

    if (defaultPayment) {
      setPaymentMethodId(defaultPayment.paymentMethodId);
    }
  }, [cardDetails?.card]);

  const handleCheckOutNextStep = useCallback(async () => {
    try {
      fetchCardDetails();
      setRefreshIcon(true);
      const stripeIds = productData
        ?.filter((item) => packageIds?.includes(item.packageTypeId))
        ?.map((item) => item.stripePriceId);

      if (
        countryId !== 2 &&
        !location?.state?.specialOffer &&
        (!stripeIds || stripeIds?.length === 0)
      ) {
        setRefreshIcon(false);
        return;
      }
      const payload = {
        query: quickHub.getCombo,
        variables: {
          countryId,
          interval: packageIntervalType,
          ...(packageIds?.length > 1 && countryId === 2 && !location?.state?.packageId
            ? { packageTypeId: packageIds }
            : countryId === 2 && {
                packageId: location?.state?.packageId
                  ? location?.state?.packageId
                  : productData?.find((item) => item.packageTypeId === packageIds[0])?.id,
              }),
          ...(countryId !== 2 && {
            ...((packageIds?.length === 1 && packageIds[0] === 12) || location?.state?.specialOffer
              ? {
                  packageId: location?.state?.specialOffer
                    ? location?.state?.packageId
                    : productData?.find((item) => item.packageTypeId === packageIds[0])?.id,
                }
              : { stripeIds }),
          }),
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.getCombo?.status === 200) {
        setIsCheckOut(true);
        setComboDetails(result?.getCombo?.data);
      } else {
        enqueueSnackbar(result?.getCombo?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      setRefreshIcon(false);
    }
  }, [enqueueSnackbar, countryId, packageIds, packageIntervalType, productData, location?.state]);

  useEffect(() => {
    if (
      location?.state?.specialOffer &&
      isCheckOut &&
      (packageIds?.length > 0 || location?.state?.packageId)
    ) {
      handleCheckOutNextStep();
    }
  }, [
    isCheckOut,
    packageIds,
    location?.state?.specialOffer,
    handleCheckOutNextStep,
    location?.state?.packageId,
  ]);

  const loadScript = (src) =>
    new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => reject(new Error('Razorpay SDK failed to load.'));
      document.body.appendChild(script);
    });
  const transformUserSubscriptionData = (data) => {
    return data?.reduce((acc, item) => {
      const addToAcc = (details, key) => {
        details?.forEach((detail) => {
          if (detail?.[key]) {
            acc.push({ name: detail?.[key] });
          }
        });
      };
      if (item?.name !== 'quick bundle' && item?.name !== 'quick combo') {
        acc.push({ name: item?.name });
      }
      addToAcc(item?.comboDetails, 'name');
      addToAcc(item?.packageBundleDetails, 'name');
      return acc;
    }, []);
  };
  const handleDefaultChange = (data) => {
    setPaymentMethodId(data?.paymentMethodId);
    setDefaultStripeCard(data);
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const payload = {
          query: quickHub.getQuickHubProducts,
          variables: {
            countryId,
            packageInterval: packageIntervalType,
          },
        };
        const result = await handleApiRequest(payload);
        setLoading(false);
        if (result?.getQuickHubProducts?.status === 200) {
          const data = result.getQuickHubProducts?.data;
          const findBusinessPackageDetails = userSubscriptionData?.filter(
            (item) => item?.userBusinessId === userCurrentBusinessDetails?.id
          );
          const filterPurchaseOrNot = findBusinessPackageDetails?.filter((item) => {
            const isValidPaymentType =
              (item.isOneTimePayment || item.isSubscription) &&
              !(item.isOneTimePayment && item.isSubscription);
            const isNotExpired = new Date(item.expireDate) > new Date();

            return isValidPaymentType && isNotExpired;
          });
          const products = transformUserSubscriptionData(filterPurchaseOrNot);
          const filterExistingPackage = data?.filter(
            (item) => !products?.some((value) => value.name === item.name)
          );
          const productsData = location.state?.isBusinessAdded ? filterExistingPackage : data || [];
          const sortProducts = productsData.sort((a, b) =>
            a.packageTypeId === 12 ? -1 : b.packageTypeId === 12 ? 1 : 0
          );
          setProductData(sortProducts);
        }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching QuickHub products:', error);
      }
    };
    if (packageIntervalType && countryId) {
      fetchData();
    }
  }, [
    countryId,
    packageIntervalType,
    userSubscriptionData,
    userCurrentBusinessDetails?.id,
    location.state,
  ]);

  const handleSelectProduct = (id) => {
    let updatedPackageIds = Array.isArray(packageIds) ? [...packageIds] : [];
    if (id === 12) {
      updatedPackageIds = updatedPackageIds.includes(12) ? [] : [12];
    } else {
      updatedPackageIds = updatedPackageIds.includes(id)
        ? updatedPackageIds.filter((packageId) => packageId !== id)
        : [...updatedPackageIds, id];
      if (updatedPackageIds.includes(12) && updatedPackageIds?.length > 1) {
        updatedPackageIds = updatedPackageIds.filter((packageId) => packageId !== 12);
      }
      const allSelected = productData
        .filter((item) => item.packageTypeId !== 12)
        .every((item) => updatedPackageIds.includes(item.packageTypeId));
      if (allSelected) {
        updatedPackageIds = [12];
      }
    }
    setPackageIds(updatedPackageIds);
  };
  const handleCheckOut = async () => {
    handleCheckOutNextStep();
  };

  const handlePay = useCallback(async () => {
    setRefreshIcon(true);
    try {
      let payload = {};
      if (countryId === 2 && paymentDetails === 'oneTimePayment') {
        payload = {
          query: quickHub.redirectPortalForRazorpayOneTimePayment,
          variables: {
            amount: comboDetails?.offerAmount || comboDetails?.amount,
            billingCycle: duration.find((value) => value.interval === packageIntervalType)
              ?.oneTimeInterval,
            isCombo: false,
            packageId: comboDetails?.packageId,
            ...(location.state?.isBusinessAdded && {
              userBusinessId: Number(userCurrentBusinessDetails?.id),
            }),
          },
        };
      } else if (paymentDetails === 'subscription') {
        payload = {
          query: quickHub.redirectPortalForSubscription,
          variables: {
            countryId,
            ...(countryId === 2
              ? {
                  priceId: comboDetails?.razorpayPlanId,
                }
              : {
                  stripePriceIds:
                    (packageIds?.length === 1 && packageIds[0] === 12) ||
                    location?.state?.specialOffer
                      ? productData
                          ?.filter((item) =>
                            location?.state?.specialOffer
                              ? location?.state?.packageId === item?.id
                              : packageIds?.includes(item.packageTypeId)
                          )
                          .map((item) => item?.getOfferStripePriceId)?.[0]
                      : productData
                          ?.filter((item) => packageIds?.includes(item.packageTypeId))
                          .map((item) => item?.stripePriceId),
                  ...(packageIds?.length === 1 &&
                    packageIds[0] === 12 && {
                      isStripeBundleOffer: true,
                    }),
                  isTrail: userSubscriptionData?.isFreeTrial,
                  trailDay: findExpireDay(userSubscriptionData?.expireDate),
                }),
            ...(location.state?.isBusinessAdded && {
              userBusinessId: Number(userCurrentBusinessDetails?.id),
            }),
          },
        };
      }
      const result = await handleApiRequest(payload);
      // setRefreshIcon(false);
      const response =
        countryId === 2 && paymentDetails === 'oneTimePayment'
          ? result?.redirectPortalForRazorpayOneTimePayment
          : result?.redirectPortalForSubscription;
      if (response?.status === 200) {
        if (countryId === 2 && paymentDetails === 'subscription') {
          const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

          if (!res) {
            console.log('Razorpay SDK failed to load. Are you online?');
            return;
          }
          setRefreshIcon(false);
          const options = {
            key: process.env.REACT_APP_RAZORPAY_PUBLIC_KEY,
            subscription_id: response?.data?.razorpaySubscriptionId,
            name: 'Quick Hub',
            prefill: {
              name: loginUserData?.fullName,
              email: loginUserData?.email,
              contact: loginUserData?.phoneNumber?.phoneNumber,
            },
            theme: {
              color: '#0BA861',
            },
            handler: (responseRaz) => {
              if (responseRaz) {
                setRefreshIcon(true);
                enqueueSnackbar(
                  'Thank you for purchasing the plan! Your subscription will be active in 10 minutes.',
                  {
                    variant: 'success',
                    autoHideDuration: 2000,
                  }
                );
                history.push('/mySubscription');
              }
            },
            timeout: 600,
            modal: {
              confirm_close: true,
              ondismiss: async (reason) => {
                setRefreshIcon(false);
                enqueueSnackbar(
                  'Your payment was not completed. Please try again to continue with your purchase',
                  {
                    variant: 'error',
                    autoHideDuration: 2000,
                  }
                );
              },
            },
          };
          const razorpayInstance = new window.Razorpay(options);
          razorpayInstance.open();
        } else if (countryId === 2 && paymentDetails === 'oneTimePayment') {
          const redirectUrl = response?.data?.url;
          if (redirectUrl) {
            window.location.href = redirectUrl;
          } else {
            throw new Error('Redirect URL not found');
          }
        } else {
          const createStripeSubscription = JSON.stringify({
            priceId: productData
              ?.filter((item) => packageIds?.includes(item.packageTypeId))
              ?.flatMap((item) =>
                item.getOfferStripePriceId.length > 0
                  ? item.getOfferStripePriceId
                  : item.stripePriceId
              ),
            promotionCode: '',
            packageInterval: packageIntervalType,
            countryId: 1,
            ...(packageIds?.length === 1 &&
              packageIds[0] === 12 && {
                isStripeBundleOffer: true,
              }),
            paymentMethodId,
            ...(location.state?.isBusinessAdded && {
              userBusinessId: Number(userCurrentBusinessDetails?.id),
            }),
          });
          const registrationPayload = {
            query: paymentData.createSubscriptionV1,
            variables: { data: getEncryptedData(createStripeSubscription) },
          };
          const resultSubscription = await handleApiRequest(
            registrationPayload,
            response?.data?.token
          );
          setStripePaymentSuccess(false);
          if (resultSubscription?.createSubscriptionV1?.status === 200) {
            // show card added success popup
            setIsCheckOut(false);
            setStripePaymentSuccessPopUp(true);
            setRefreshIcon(false);
          } else {
            // show faild popupsetIsCheckOut(false);
            setStripePaymentFailedPopUp(true);
            setRefreshIcon(false);
          }
        }
      } else {
        setStripePaymentSuccess(false);
        setRefreshIcon(false);
        enqueueSnackbar(response?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
        throw new Error('Payment request failed');
      }
    } catch (error) {
      setStripePaymentSuccess(false);
      setRefreshIcon(false);
      console.error('Error during payment processing:', error);
    }
    setRefreshIcon(false);
  }, [
    comboDetails?.amount,
    comboDetails?.offerAmount,
    comboDetails?.packageId,
    comboDetails?.razorpayPlanId,
    countryId,
    enqueueSnackbar,
    location.state?.isBusinessAdded,
    location.state?.packageId,
    location.state?.specialOffer,
    loginUserData?.email,
    loginUserData?.fullName,
    loginUserData?.phoneNumber?.phoneNumber,
    packageIds,
    packageIntervalType,
    paymentDetails,
    paymentMethodId,
    productData,
    userCurrentBusinessDetails?.id,
    userSubscriptionData?.expireDate,
    userSubscriptionData?.isFreeTrial,
  ]);

  useEffect(() => {
    if (stripePaymentSuccess) {
      handlePay();
    }
  }, [stripePaymentSuccess, handlePay]);

  const clickOnCheckFeature = (data) => {
    const { packageTypeId } = data || {};

    const routesMap = {
      7: 'quick-reviews',
      9: 'quick-chat',
      8: 'quick-social',
      10: 'quick-campaign',
      11: 'quick-ads',
      12: 'quick-bundle',
    };

    const route = routesMap[packageTypeId];
    history.push(`${route}/description/`, { isRedirectNotNeed: true });
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="">
      <div className="md:flex w-full justify-between">
        <div className="p-28 w-full">
          {productData?.filter((item) => !packageIds?.includes(item.packageTypeId))?.length > 0 && (
            <Typography className="font-bold text-24">
              {t('selectProduct.SelectYourProduct')}
            </Typography>
          )}
          <div className="mt-20">
            {productData?.length > 0 &&
              productData
                ?.filter((item) => !packageIds?.includes(item.packageTypeId))
                .map((item, index) => {
                  return (
                    <div
                      className={`border flex gap-16 relative items-center !border-gray-A800 border-solid !max-w-[600px] p-8 rounded-md mb-12 pr-16 ${
                        item?.packageTypeId === 12 && '!bg-darkgreen-100 !border-quick-hub'
                      }`}
                      key={index}
                    >
                      {item?.packageTypeId === 12 && (
                        <Typography className="absolute -top-12 bg-quick-hub rounded-md text-white px-6 right-0">
                          {t('selectProduct.recommend')}
                        </Typography>
                      )}
                      <div>
                        <img className="w-48 mx-20" src={item?.image} alt="" />
                      </div>
                      <div className="w-full">
                        <div className="flex justify-between h-32">
                          <Typography className="font-bold text-16">{item?.name}</Typography>
                          <Typography className="mb-12 align-middle capitalize flex flex-col text-end">
                            {item?.offerAmount && (
                              <h3 className="text-16 font-bold mb-0">
                                <span>{countryId === 2 ? '₹' : '$'}</span>
                                {item?.offerAmount}/{item?.packageInterval?.replace(/_/g, ' ')}
                              </h3>
                            )}
                          </Typography>
                        </div>
                        <div className="flex justify-between w-full">
                          <Typography className="max-w-320 font-semibold text-12">
                            {item?.description}
                          </Typography>
                          <h3
                            className={`block ${
                              item?.offerAmount
                                ? 'line-through text-grey-500 font-medium text-14'
                                : 'text-16 font-bold'
                            }`}
                          >
                            <span>{countryId === 2 ? '₹' : '$'}</span>
                            {item?.amount}/{item?.packageInterval?.replace(/_/g, ' ')}
                          </h3>
                        </div>
                        <div className="flex justify-between w-full">
                          <Button
                            className="text-darkgreen p-0 h-16 max-h-16 min-h-32 hover:bg-transparent font-bold"
                            onClick={() => clickOnCheckFeature(item)}
                          >
                            {t('plan.checkFeatures')} <KeyboardArrowRightIcon />
                          </Button>
                          <Checkbox
                            onChange={() => handleSelectProduct(item?.packageTypeId)}
                            checked={packageIds?.some(
                              (packageId) => packageId === item?.packageTypeId
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
        {!isCheckOut ? (
          <div className="p-28 border !border-gray-A900 rounded-md w-full lg:!max-w-[466px] border-t-0 min-h-[calc(100vh-65px)]">
            <Typography className="font-bold text-24">{t('plan.selectProducts')}</Typography>
            {location.state?.isBusinessAdded && userCurrentBusinessDetails && (
              <div className="flex bg-white p-12 gap-12 items-center my-20">
                <img
                  className="w-40 h-40 rounded-md"
                  src={
                    userCurrentBusinessDetails?.profileURL ||
                    'assets/images/business/businessprofile.png'
                  }
                  alt=""
                />
                <div>
                  <Typography className="font-semibold">
                    {userCurrentBusinessDetails?.name}
                  </Typography>
                  <Typography>{userCurrentBusinessDetails?.address}</Typography>
                </div>
              </div>
            )}
            {countryId === 2 && (
              <div className="my-16 w-full border border-darkgreen flex justify-between rounded-full items-center">
                <Button
                  className={`text-center w-full font-semibold flex justify-center items-center ${
                    paymentDetails === 'subscription'
                      ? 'bg-darkgreen hover:bg-darkgreen text-white'
                      : 'bg-white hover:bg-white text-darkgreen'
                  } ${countryId === 2 ? 'rounded-r-0' : ''}`}
                  onClick={() => {
                    setPaymentDetails('subscription');
                    setPackageIntervalType('month');
                  }}
                >
                  {t('users.UserList.Filter.subscription')}
                </Button>
                <Button
                  className={`text-center w-full rounded-l-0 font-semibold flex justify-center items-center ${
                    paymentDetails === 'oneTimePayment'
                      ? 'bg-darkgreen hover:bg-darkgreen text-white'
                      : 'bg-white hover:bg-white text-darkgreen'
                  }`}
                  onClick={() => setPaymentDetails('oneTimePayment')}
                >
                  {t('plan.oneTimePayment')}
                </Button>
              </div>
            )}
            {paymentDetails === 'subscription' && (
              <div className="text-center">
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={packageIntervalType}
                    onChange={(e) => setPackageIntervalType(e.target.value)}
                  >
                    <FormControlLabel value="month" control={<Radio />} label={t('plan.monthly')} />
                    <FormControlLabel value="year" control={<Radio />} label={t('plan.yearly')} />
                  </RadioGroup>
                </FormControl>
              </div>
            )}
            {paymentDetails === 'oneTimePayment' && (
              <div className="flex gap-12 my-24">
                {duration.map((item, i) => {
                  return (
                    <Button
                      className={`w-full text-center py-8 px-12 font-medium text-12 rounded-md ${
                        packageIntervalType === item.interval
                          ? 'bg-darkgreen-100 text-darkgreen'
                          : 'bg-white'
                      }`}
                      onClick={() => setPackageIntervalType(item.interval)}
                      key={i}
                    >
                      {item.value}
                    </Button>
                  );
                })}
              </div>
            )}
            {productData?.length > 0 &&
              productData
                ?.filter((item) => packageIds?.includes(item.packageTypeId))
                .map((item, i) => {
                  return (
                    <div
                      className="mt-16 flex gap-24 items-center bg-darkgreen-100 p-8 rounded-md"
                      key={i}
                    >
                      <div className="px-4">
                        <img className="w-60 h-48" src={item?.image} alt="" />
                      </div>
                      <div className="w-full">
                        <div className="flex justify-between">
                          <Typography className="font-bold text-16">{item?.name}</Typography>
                          <Typography>
                            {item?.offerAmount && (
                              <Typography className="text-16 font-bold mb-0">
                                <span>{loginUserData?.countryId === 2 ? '₹' : '$'}</span>
                                {item?.offerAmount}/{item?.packageInterval?.replace(/_/g, ' ')}
                              </Typography>
                            )}
                          </Typography>
                        </div>
                        <div className="flex justify-between">
                          <Typography className="text-12 font-semibold py-4">
                            {duration
                              .find((value) => value.interval === item?.packageInterval)
                              ?.value?.toLowerCase()}{' '}
                            {t('agencyBusiness.clients.plan')}
                          </Typography>
                          <Typography
                            className={`block ${
                              item?.offerAmount
                                ? 'line-through text-grey-500 font-medium text-14'
                                : 'text-16 font-bold'
                            }`}
                          >
                            <span>{loginUserData?.countryId === 2 ? '₹' : '$'}</span>
                            {item?.amount}/{item?.packageInterval?.replace(/_/g, ' ')}
                          </Typography>
                        </div>
                        <div className="flex justify-between items-center h-20">
                          <div>
                            <Typography className="font-semibold text-14">
                              {countryId === 2 ? '₹' : '$'} {item?.offerAmount || item?.amount}{' '}
                              {t('plan.payFor')}{' '}
                              {duration
                                .find((value) => value.interval === item?.packageInterval)
                                ?.value?.toLowerCase()}
                            </Typography>
                          </div>
                          <Checkbox
                            onChange={() => handleSelectProduct(item?.packageTypeId)}
                            checked={packageIds.some(
                              (packageId) => packageId === item.packageTypeId
                            )}
                          />
                        </div>
                        <Button
                          className="text-darkgreen p-0 h-16 max-h-16 min-h-32 hover:bg-transparent font-bold"
                          onClick={() => clickOnCheckFeature(item)}
                        >
                          {t('plan.checkFeatures')} <KeyboardArrowRightIcon />
                        </Button>
                      </div>
                    </div>
                  );
                })}
            {packageIds?.length !== 0 && productData?.length > 0 && (
              <div className="text-center flex justify-center items-center m-auto mt-24 w-3/4">
                <Button
                  className="bg-darkgreen text-white font-bold hover:bg-darkgreen disabled:bg-gray rounded-md w-full"
                  onClick={() => handleCheckOut()}
                  disabled={refreshIcon || packageIds?.length === 0}
                >
                  {t('plan.checkOut')}
                  {refreshIcon && (
                    <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
                  )}
                </Button>
              </div>
            )}
          </div>
        ) : (
          <Drawer className="" anchor="right" open={isCheckOut}>
            <div className="flex items-center justify-between w-full p-20">
              <Typography className="font-bold text-24">{t('plan.checkOut')}</Typography>
              <Button
                className="w-32 min-w-32 hover:bg-transparent"
                onClick={() => {
                  setAddCardDetails(false);
                  setIsCheckOut(false);
                }}
              >
                <CloseIcon />
              </Button>
            </div>
            <div className="bg-gray-A500 max-w-full h-full rounded-md flex ">
              <div
                className={`${
                  countryId !== 2 && 'w-1/2 border-r-2 border-gray-500'
                } w-full md:min-w-400 md:max-w-400 p-24`}
              >
                {location.state?.isBusinessAdded && userCurrentBusinessDetails && (
                  <div className="flex bg-white p-12 gap-12 items-center mb-20">
                    <img
                      className="w-40 h-40 rounded-md"
                      src={
                        userCurrentBusinessDetails?.profileURL ||
                        'assets/images/business/businessprofile.png'
                      }
                      alt=""
                    />
                    <div>
                      <Typography className="font-semibold">
                        {userCurrentBusinessDetails?.name}
                      </Typography>
                      <Typography>{userCurrentBusinessDetails?.address}</Typography>
                    </div>
                  </div>
                )}
                <div className="bg-white p-12 rounded-md mt-16">
                  <Typography className="font-semibold">{t('plan.selectedProducts')}</Typography>
                  {productData
                    ?.filter((item) => packageIds?.includes(item.packageTypeId))
                    .map((item, index) => {
                      return (
                        <div className="border px-16 py-8 rounded-md mt-12" key={index}>
                          <div className="flex justify-between">
                            <Typography className="font-semibold">{item.name}</Typography>
                            {item?.offerAmount && (
                              <Typography className="text-16 font-bold mb-0">
                                <span>{loginUserData?.countryId === 2 ? '₹' : '$'}</span>
                                {item?.offerAmount}/{item?.packageInterval?.replace(/_/g, ' ')}
                              </Typography>
                            )}
                          </div>
                          <div className="flex justify-between">
                            <Typography className="text-14">
                              {countryId === 2 ? '₹' : '$'} {item?.offerAmount || item?.amount}{' '}
                              {t('plan.payFor')}{' '}
                              {duration
                                .find((value) => value.interval === item?.packageInterval)
                                ?.value?.toLowerCase()}
                            </Typography>
                            <Typography
                              className={`block ${
                                item?.offerAmount
                                  ? 'line-through text-grey-500 font-medium text-14'
                                  : 'text-16 font-bold'
                              }`}
                            >
                              <span>{loginUserData?.countryId === 2 ? '₹' : '$'}</span>
                              {item?.amount}/{item?.packageInterval?.replace(/_/g, ' ')}
                            </Typography>
                          </div>
                        </div>
                      );
                    })}
                  {/* {comboDetails?.isOffer && (
                  <Typography className="bg-yellow-A800 text-center rounded-md mt-12 py-8">
                    {t('plan.grateOfferMessage')} {countryId === 2 ? '₹' : '$'}
                    {Number(comboDetails?.amount) - Number(comboDetails?.offerAmount)}
                  </Typography>
                )} */}
                </div>
                <div className="bg-white p-12 rounded-md mt-16">
                  <Typography className="font-semibold">{t('plan.billingDetails')}</Typography>
                  <hr className="border border-dashed !border-gray my-12" />
                  <div className="flex justify-between">
                    <Typography className="text-gray">{t('plan.billingCycle')}</Typography>
                    <Typography className="font-semibold">
                      {paymentDetails === 'oneTimePayment'
                        ? t('plan.oneTime')
                        : packageIntervalType === 'year'
                        ? t('plan.annually')
                        : t('plan.monthly')}
                    </Typography>
                  </div>
                  <div className="flex justify-between my-8">
                    <Typography className="text-gray">{t('plan.startDate')}</Typography>
                    <Typography className="font-semibold">
                      {formatDateWithTimeZone(comboDetails?.startDate, 'MMM DD, YYYY')}
                    </Typography>
                  </div>
                  {paymentDetails === 'subscription' && (
                    <div className="flex justify-between">
                      <Typography className="text-gray">{t('common.nextRecurringDate')}</Typography>
                      <Typography className="font-semibold">
                        {formatDateWithTimeZone(comboDetails?.expireDate, 'MMM DD, YYYY')}
                      </Typography>
                    </div>
                  )}
                  {comboDetails?.isOffer && (
                    <Typography className="bg-[#e9f3ff] rounded-md mt-12 px-6 py-16 border border-dashed border-[#2b8cff]">
                      {t('common.note')}: {t('plan.after')}{' '}
                      {duration
                        .find((value) => value.interval === packageIntervalType)
                        ?.value?.toLowerCase()}
                      , {t('plan.noteMessage')} {countryId === 2 ? '₹' : '$'}{' '}
                      {comboDetails?.offerAmount || comboDetails?.amount}
                    </Typography>
                  )}
                </div>
                <div className="bg-white p-12 rounded-md mt-16">
                  <Typography className="font-semibold">{t('plan.orderSummary')}</Typography>
                  <hr className="border border-dashed !border-gray my-12" />
                  <div className="flex justify-between">
                    <Typography className="text-gray">{t('plan.originalAmount')}</Typography>
                    <Typography className="font-semibold">
                      {countryId === 2 ? '₹' : '$'} {comboDetails?.amount}
                    </Typography>
                  </div>
                  <div className="flex justify-between my-8">
                    <Typography className="text-gray">{t('plan.discountAmount')}</Typography>
                    <Typography className="font-semibold">
                      - {countryId === 2 ? '₹' : '$'} {comboDetails?.discount}
                    </Typography>
                  </div>

                  <div className="flex justify-between my-8">
                    <Typography className="text-gray">{t('plan.estimatedTax')}</Typography>
                    <Typography className="font-semibold">
                      {countryId === 2 ? '₹' : '$'} {comboDetails?.tax}
                    </Typography>
                  </div>
                  <div className="flex justify-between">
                    <Typography className="text-gray">{t('plan.totalAmount')}</Typography>
                    <Typography className="font-semibold">
                      {countryId === 2 ? '₹' : '$'}{' '}
                      {comboDetails?.offerAmount || comboDetails?.amount}
                    </Typography>
                  </div>
                  {/* {comboDetails?.isOffer && (
                  <Typography className="bg-darkgreen-A100 opacity-60 font-medium text-center rounded-md mt-12 py-8">
                    {t('plan.youSaved')} {countryId === 2 ? '₹' : '$'}{' '}
                    {Number(comboDetails?.amount) - Number(comboDetails?.offerAmount)}{' '}
                    {t('plan.timePeriodMessage')}{' '}
                    {duration
                      .find((value) => value.interval === packageIntervalType)
                      ?.value?.toLowerCase()}
                  </Typography>
                )} */}
                </div>
                <div className="text-center m-auto mt-24">
                  {!refreshIcon && countryId !== 1 && (
                    <Button
                      className="mb-16 font-bold disabled:bg-gray rounded-md mx-auto w-full max-w-288"
                      onClick={() => handlePay()}
                      color="secondary"
                      variant="contained"
                      disabled={refreshIcon}
                    >
                      {t('plan.pay')} {countryId === 2 ? '₹' : '$'}{' '}
                      {comboDetails?.offerAmount || comboDetails?.amount}
                      {refreshIcon && (
                        <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
                      )}
                    </Button>
                  )}
                  {refreshIcon && countryId !== 1 && (
                    <div className="payment-loader block">
                      <div className="pad">
                        <div className="chip" />
                        <div className="line line1" />
                        <div className="line line2" />
                      </div>
                      <div className="loader-text">Please wait...</div>
                    </div>
                  )}
                </div>
              </div>
              {countryId !== 2 && (
                <div className="w-1/2 p-24 md:min-w-400 md:max-w-400">
                  <div className="">
                    {cardDetails?.card && cardDetails?.card?.length > 0 && !addCardDetails ? (
                      <>
                        <Typography className="text-18 font-semibold mb-14">
                          {t('plan.selectCardForPayment')}
                        </Typography>
                        <div className="max-h-512 overflow-y-auto">
                          {cardDetails?.card?.map((card, index) => (
                            <div
                              key={index}
                              className={`p-14 flex items-center bg-white mb-14 rounded-md justify-between ${
                                card.isDefault && 'border-2 border-solid border-darkgreen'
                              }`}
                            >
                              <div className="w-full">
                                <div className="flex w-full justify-between items-center mb-6">
                                  <img
                                    src={
                                      card?.brand === 'visa'
                                        ? 'assets/images/icon/visa.svg'
                                        : card?.brand === 'mastercard'
                                        ? 'assets/images/icon/masterCard.svg'
                                        : card?.brand === 'amex'
                                        ? 'assets/images/icon/americanExp.svg'
                                        : 'assets/images/icon/dummyCard.webp'
                                    }
                                    alt=""
                                    className="h-48 w-48 rounded-full"
                                  />
                                  <input
                                    type="radio"
                                    name="defaultCard"
                                    value={card.isDefault}
                                    checked={card.isDefault}
                                    onChange={() => handleDefaultChange(card)}
                                    className="mr-2 accent-darkgreen h-20 w-20"
                                  />
                                </div>

                                <span className="text-16 font-semibold block">
                                  {card?.brand} ending with {card.last4}
                                </span>
                                <span className="text-14 font-medium block">
                                  Exp. date {card.expMonth}/{card.expYear}
                                </span>
                              </div>
                            </div>
                          ))}
                          <Button
                            className="rounded-md text-16 font-medium"
                            variant="outlined"
                            color="quickReview"
                            onClick={() => setAddCardDetails(true)}
                          >
                            {t('plan.addNewCardDetails')}
                          </Button>
                        </div>
                        {/* <div className="mt-20">
                        {addCardDetails && (
                          <Elements stripe={stripe}>
                            <StripePayment
                              setStripePaymentSuccess={setStripePaymentSuccess}
                              setPaymentMethodId={setPaymentMethodId}
                              totalAmount={
                                comboDetails?.offerAmount ||
                                comboDetails?.amount
                              }
                            />
                          </Elements>
                        )}
                      </div> */}
                        <div className="flex justify-end items-center mt-24 ">
                          <Button
                            className="rounded-md md:px-28 text-16 font-medium"
                            variant="contained"
                            color="quickReview"
                            size="medium"
                            onClick={() => handlePay()}
                          >
                            Pay Now
                          </Button>
                        </div>
                      </>
                    ) : (
                      <Elements stripe={stripe}>
                        <StripePayment
                          setStripePaymentSuccess={setStripePaymentSuccess}
                          setPaymentMethodId={setPaymentMethodId}
                          setAddCardDetails={setAddCardDetails}
                          totalAmount={comboDetails?.offerAmount || comboDetails?.amount}
                        />
                      </Elements>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Drawer>
        )}
      </div>
      {stripePaymentSuccessPopUp && (
        <Dialog
          open={stripePaymentSuccessPopUp}
          onClose={() => setStripePaymentSuccessPopUp(false)}
        >
          <DialogContent className="p-32 ">
            <div className="flex justify-center items-center">
              <img src="assets/images/icon/success.png" alt="" className="h-128 w-128 mb-16" />
            </div>
            <div>
              <Typography className="text-20 font-semibold flex justify-center items-center mb-12">
                {t('plan.paymentSuccess')}
              </Typography>
              <Typography className="text-16 font-medium flex justify-center items-center mb-16 text-center">
                {/* {t('plan.successfulContent')} */}
                {`Your payment of ${countryId === 2 ? '₹' : '$'} ${
                  comboDetails?.offerAmount || comboDetails?.amount
                } has been processed successfully. Your subscription is now active—enjoy uninterrupted access to  ${productData
                  ?.filter((item) => packageIds?.includes(item.packageTypeId))
                  .map((item) => item?.name)
                  .join(', ')}`}
              </Typography>
            </div>
            <div className="flex justify-center items-center">
              <Button
                className="rounded-md flex justify-center items-center"
                variant="contained"
                size="large"
                color="quickReview"
                onClick={() => {
                  setStripePaymentSuccessPopUp(false);
                  history.push('business/dashboard/');
                }}
              >
                {t('plan.popUpButton')}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      {stripePaymentFailedPopUp && (
        <Dialog open={stripePaymentFailedPopUp} onClose={() => setStripePaymentFailedPopUp(false)}>
          <DialogContent className="p-32 ">
            <div className="flex justify-center items-center">
              <img src="assets/images/icon/attention.png" alt="" className="h-128 w-128 mb-16" />
            </div>
            <div>
              <Typography className="text-20 font-semibold flex justify-center items-center mb-12">
                {t('plan.paymentFailed')}
              </Typography>
              <Typography className="text-16 font-medium flex justify-center items-center mb-16 text-center">
                {t('plan.failedContent')}
              </Typography>
            </div>
            <div className="flex justify-center items-center">
              <Button
                className="rounded-md flex justify-center items-center"
                variant="contained"
                size="large"
                color="quickReview"
                onClick={() => {
                  setStripePaymentFailedPopUp(false);
                }}
              >
                {t('plan.retryPayment')}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default SelectPlan;
