import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Pagination,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import history from '@history';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { useSnackbar } from 'notistack';
import { ReactComponent as EditIcon } from '../../../../image/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../image/delete.svg';
import {
  capitalizeFirstLetter,
  convertTemplateToHtml,
  handleWabaApiRequest,
} from '../../../../common/common';
import wabaQuery from '../../../../query/waba';
import { selectDashboardData } from '../../../../../store/userSlice';
import {
  DeleteTemplate,
  DuplicateTemplate,
  ViewTemplate,
  ViewTemplateInDrawer,
} from '../common/Popup';

const Template = () => {
  const tabsData = useMemo(
    () => [
      {
        id: 1,
        label: t('quickCampaign.template.tabs.templateLibrary.name'),
        type: 'TEMPLATE',
      },
      {
        id: 2,
        label: t('quickCampaign.template.tabs.approved.name'),
        type: 'APPROVED',
      },
      {
        id: 3,
        label: t('quickCampaign.template.tabs.draft.name'),
        type: 'DRAFT',
      },
      {
        id: 4,
        label: t('quickCampaign.template.tabs.pending.name'),
        type: 'PENDING',
      },
      {
        id: 5,
        label: t('quickCampaign.template.tabs.actionRequired.name'),
        type: 'REJECTED',
      },
    ],
    []
  );

  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location?.state?.activeTab || 1);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [category, setCategory] = useState([]);
  const [viewDialogue, setViewDialogue] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [openDuplicateDialogue, setDuplicateDialogue] = useState(false);
  const [viewTemplateInDrawer, setViewTemplateInDrawer] = useState(false);
  const [openDeleteTemplateDialogue, setDeleteTemplateDialogue] = useState(false);
  const [selectCategory, setSelectCategory] = useState();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (userCurrentBusinessDetails?.id) {
        try {
          const payload = {
            query: wabaQuery.getUserDetail,
            variables: {
              quickReviewBusinessId: userCurrentBusinessDetails?.id,
            },
          };
          const res = await handleWabaApiRequest(payload);
          if (res?.getUserDetail?.status === 200) {
            if (!res?.getUserDetail?.data || Object.keys(res?.getUserDetail?.data)?.length === 0) {
              history.push({
                pathname: 'quick-chat/waba-home',
              });
            }
          }
        } catch (error) {
          console.error('An error occurred:', error);
        }
      }
    };
    fetchUserDetails();
  }, [userCurrentBusinessDetails?.id]);

  const getWhatsappTemplate = useCallback(async () => {
    try {
      const payload = {
        query: wabaQuery.getWhatsappTemplate,
        variables: {
          businessId: Number(userCurrentBusinessDetails?.id),
          ...(activeTab !== 1
            ? {
                status: tabsData?.find((item) => item?.id === activeTab)?.type,
              }
            : {
                defaultTemplateCategoryId: Number(selectCategory?.id),
              }),
          searchText,
          pageNumber: page,
          pageSize: activeTab === 1 ? 12 : 10,
        },
      };
      const res = await handleWabaApiRequest(payload);
      if (res?.getWhatsappTemplate?.status === 200) {
        const data = res.getWhatsappTemplate.data?.length > 0 ? res?.getWhatsappTemplate?.data : [];
        const parsedData = data.map((item) => ({
          ...item,
          dynamicData: item.dynamicData ? JSON.parse(item.dynamicData) : {},
          templateData: item.templateData ? JSON.parse(item.templateData) : {},
        }));
        const processedTemplates = parsedData.map((template) => {
          const bodyText = template?.templateData?.components?.find(
            (item) => item?.type === 'BODY'
          )?.text;
          const updatedBodyText = bodyText?.replace(/\{\{(\d+)\}\}/g, (match, key) => {
            const replacement = template?.dynamicData?.body?.find(
              (item) => `{{${item.key}}}` === match
            )?.description;
            return `[${replacement}]` || match;
          });
          return {
            ...template,
            updatedBodyText,
          };
        });
        setTemplates(processedTemplates || []);
        setTotalCount(res.getWhatsappTemplate?.totalCount);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [activeTab, tabsData, userCurrentBusinessDetails?.id, selectCategory, searchText, page]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      getWhatsappTemplate();
    }
  }, [getWhatsappTemplate, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const payload = {
          query: wabaQuery.getDefaultTemplateCategories,
          variables: {
            quickReviewBusinessId: Number(userCurrentBusinessDetails?.id),
          },
        };
        const res = await handleWabaApiRequest(payload);
        if (res?.getDefaultTemplateCategories?.status === 200) {
          setCategory(res.getDefaultTemplateCategories.data);
          setSelectCategory(res.getDefaultTemplateCategories.data?.[0]);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };
    if (userCurrentBusinessDetails?.id) {
      fetchCategory();
    }
  }, [userCurrentBusinessDetails?.id]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleDelete = async () => {
    try {
      setRefreshIcon(true);
      const payload = {
        query: wabaQuery.deleteWhatsappTemplate,
        variables: {
          templateId: Number(selectedTemplate?.id),
        },
      };
      const result = await handleWabaApiRequest(payload);
      setRefreshIcon(false);
      if (result?.deleteWhatsappTemplate?.status === 200) {
        enqueueSnackbar(result?.deleteWhatsappTemplate?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        getWhatsappTemplate();
        setSelectedTemplate();
        setDeleteTemplateDialogue(false);
      } else {
        enqueueSnackbar(result?.deleteWhatsappTemplate?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        setSelectedTemplate();
        setDeleteTemplateDialogue(false);
      }
    } catch (error) {
      console.error('Error deleting custom attribute:', error);
    }
  };

  return (
    <div className="lg:p-24 p-16">
      <div className="flex justify-between items-center">
        <Typography className="font-bold text-24">
          {t('quickCampaign.template.template')}
        </Typography>
        <Button
          className="w-216 font-semibold text-16 rounded-md mt-16"
          variant="contained"
          color="quickChat"
          onClick={() => history.push('quick-chat/createOrEditTemplate')}
        >
          {t('quickCampaign.template.createTemplate')}
        </Button>
      </div>
      <div className="border-solid quick-chat mt-16 border-grey sm:flex justify-between items-center border-b-2 mb-32 overflow-x-auto">
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => {
            setActiveTab(newValue);
            setPage(1);
          }}
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          {tabsData?.map((tab, i) => {
            return (
              <Tab
                className={`sm:text-18 font-semibold p-0 sm:mr-40 mr-14 ${
                  activeTab === 0 ? 'text-quick-chat' : 'text-black'
                }`}
                key={i}
                value={tab?.id}
                label={tab?.label}
              />
            );
          })}
        </Tabs>
      </div>
      {activeTab === 1 ? (
        <div className="md:flex gap-16">
          <div className="mb-10 sm:mb-10 md:max-w-288 max-w-full w-full overflow-x-auto lg:overscroll-hidden">
            <div className="sm:flex flex md:block">
              {category?.length > 0 &&
                category?.map((item, index) => (
                  <div
                    key={index}
                    role="button"
                    tabIndex={0}
                    className={`flex px-12 py-8 h-64 md:min-w-256 ${
                      selectCategory?.id === item?.id ? 'bg-quick-chat text-white' : 'bg-white'
                    } cursor-pointer gap-16  items-center flex-shrink-0`}
                    onClick={() => setSelectCategory(item)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        setSelectCategory(item);
                      }
                    }}
                  >
                    <img className="w-40" src={item?.logo} alt={item?.name} />
                    <h3 className="text-16 font-medium whitespace-normal break-words flex-grow">
                      {item?.name}
                    </h3>
                  </div>
                ))}
            </div>
          </div>
          {templates?.length > 0 && (
            <div>
              <div className="grid sm:grid-cols-2 md:grid-cols-3 col-span-1 gap-20">
                {templates?.map((item, index) => {
                  return (
                    <div
                      className="bg-white py-8 flex flex-col gap-6 justify-between h-480"
                      key={index}
                    >
                      <div className="px-16">
                        <Typography className="text-start font-semibold text-16">
                          {item?.templateData?.name}
                        </Typography>
                      </div>
                      <hr className="border" />
                      <div className="px-16">
                        <div
                          className="px-16 py-20 h-360"
                          style={{
                            backgroundImage: `url('/assets/images/quick-chat/DefaultWallpaper.svg')`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                          }}
                        >
                          <div className="overflow-scroll h-288">
                            <div className="bg-white px-12 rounded-md pt-10 ">
                              {item?.templateData?.components?.length > 0 &&
                                item?.templateData?.components?.map((templateValue, i) => (
                                  <div key={i}>
                                    {templateValue?.type === 'HEADER' && (
                                      <>
                                        {templateValue?.format === 'TEXT' && (
                                          <Typography
                                            className="font-bold"
                                            dangerouslySetInnerHTML={{
                                              __html: templateValue?.text,
                                            }}
                                          />
                                        )}
                                      </>
                                    )}
                                    {templateValue?.format === 'IMAGE' && (
                                      <>
                                        {item?.dynamicData?.header?.map((dynamicValue) => (
                                          <>
                                            {dynamicValue?.type === 'image' && (
                                              <img
                                                src={dynamicValue?.value}
                                                className="mb-10 rounded-md w-full h-144 object-cover shadow-md"
                                                alt="..."
                                              />
                                            )}
                                          </>
                                        ))}
                                      </>
                                    )}
                                    {templateValue?.type === 'BODY' && (
                                      <Typography
                                        dangerouslySetInnerHTML={{
                                          __html: convertTemplateToHtml(
                                            item?.updatedBodyText?.replace(/\n/g, '<br>')
                                          ),
                                        }}
                                      />
                                    )}
                                    {templateValue?.type === 'FOOTER' && (
                                      <Typography
                                        dangerouslySetInnerHTML={{ __html: templateValue?.text }}
                                      />
                                    )}
                                  </div>
                                ))}
                            </div>
                            <div>
                              {item?.templateData?.components?.length > 0 &&
                                item?.templateData?.components?.map((templateValue, i) => (
                                  <div key={i}>
                                    {templateValue?.type === 'BUTTONS' && (
                                      <>
                                        {templateValue?.buttons?.map((buttonValue, b) => (
                                          <div className="bg-white shadow p-4 rounded-md mt-4 h-32 text-center">
                                            <a
                                              className="font-semibold text-[#0096DE] text-16"
                                              href={`${
                                                buttonValue?.phone_number
                                                  ? `tel:${buttonValue?.phone_number}`
                                                  : buttonValue?.url
                                              }`}
                                              rel="noreferrer"
                                              target="_blank"
                                              role="button"
                                              key={b}
                                            >
                                              <img
                                                src={
                                                  buttonValue?.phone_number
                                                    ? '/assets/images/business/telephone.png'
                                                    : '/assets/images/business/view.png'
                                                }
                                                className="w-14 h-14 align-middle mr-6 inline-block relative -top-1"
                                                alt="..."
                                              />
                                              {buttonValue?.text}
                                            </a>
                                          </div>
                                        ))}
                                      </>
                                    )}
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="border" />
                      <div className="flex justify-between px-16">
                        <Button
                          className="rounded-md text-16 font-semibold w-96"
                          variant="outlined"
                          color="quickChat"
                          onClick={() => {
                            setSelectedTemplate(item);
                            setViewDialogue(true);
                          }}
                        >
                          Preview
                        </Button>
                        <Button
                          className="rounded-md text-16 font-semibold w-96"
                          variant="contained"
                          color="quickChat"
                          onClick={() =>
                            history.push('quick-chat/createOrEditTemplate', { template: item })
                          }
                        >
                          Edit
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="px-52 py-32 bottom-0 right-0 w-full flex justify-end">
                <Pagination
                  count={Math.ceil(totalCount / 12)}
                  page={page}
                  onChange={handlePageChange}
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          {(templates?.length > 0 || searchText) && (
            <div className="flex justify-between mb-20 quick-chat">
              <Typography className="font-semibold md:text-20 text-20">
                {capitalizeFirstLetter(tabsData?.find((item) => item?.id === activeTab)?.type)}{' '}
                {t('dashboard.exploreFeatures.campaigns.templates')}
              </Typography>
              <TextField
                placeholder="Search template name"
                variant="outlined"
                fullWidth
                className="bg-white w-full sm:w-512 m-0"
                margin="normal"
                size="small"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  setPage(1);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: searchText && (
                    <InputAdornment position="end">
                      <IconButton
                        color="quickChat"
                        className="hover:bg-transparent cursor-pointer text-20"
                        onClick={() => {
                          setSearchText('');
                          setPage(1);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
          {templates?.length > 0 ? (
            <div>
              <TableContainer component={Paper} className="rounded-md border-none shadow-0">
                <Table className="">
                  <TableHead>
                    <TableRow>
                      <TableCell className="text-18 font-semibold border-b-2 border-solid border-gray-500 h-52" />
                      <TableCell className="text-18 min-w-128 max-w-128 font-semibold border-b-2 border-solid border-gray-500 h-52">
                        {t('quickCampaign.template.tableColumns.templateName')}
                      </TableCell>
                      <TableCell className="text-18 max-w-128 font-semibold border-b-2 border-solid border-gray-500 h-52">
                        {t('quickCampaign.template.tableColumns.category')}
                      </TableCell>
                      <TableCell className="text-18 max-w-96 font-semibold border-b-2 border-solid border-gray-500 h-52">
                        {t('quickCampaign.template.tableColumns.status')}
                      </TableCell>
                      <TableCell className="text-18 max-w-60 font-semibold border-b-2 border-solid border-gray-500 h-52">
                        {t('quickCampaign.template.tableColumns.type')}
                      </TableCell>
                      {tabsData?.find((item) => item?.id === activeTab)?.type !== 'REJECTED' &&
                        tabsData?.find((item) => item?.id === activeTab)?.type !== 'DRAFT' && (
                          <TableCell className="text-18 min-w-96 max-w-96 font-semibold border-b-2 border-solid border-gray-500 h-52">
                            {t('quickCampaign.template.tableColumns.health')}
                          </TableCell>
                        )}
                      <TableCell className="text-18 min-w-96 max-w-96 font-semibold border-b-2 border-solid border-gray-500 h-52">
                        {t('quickCampaign.template.tableColumns.createdAt')}
                      </TableCell>
                      <TableCell className="text-18 font-semibold border-b-2 border-solid border-gray-500 h-52 rounded-tr-md w-160" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {templates?.map((item, index) => {
                      return (
                        <TableRow
                          key={index}
                          className="hover:bg-quick-chat-100 hover:bg-opacity-30"
                          onMouseEnter={() => setHoveredRow(index)}
                          onMouseLeave={() => setHoveredRow(null)}
                        >
                          <TableCell className="h-52 max-h-52  border-b-[1px] border-solid border-gray-300 text-16 color-black font-medium  py-5 pb-16 md:py-10 capitalize">
                            <span>{(page - 1) * 10 + index + 1}</span>
                          </TableCell>
                          <TableCell className="h-52 max-h-52  border-b-[1px] border-solid border-gray-300 text-16 color-black font-medium  py-5 pb-16 md:py-10 capitalize">
                            <span
                              title={item?.templateName}
                              className="whitespace-nowrap overflow-hidden text-ellipsis block max-w-128"
                            >
                              {item?.templateName}
                            </span>
                          </TableCell>
                          <TableCell className="h-52 max-h-52  border-b-[1px] border-solid border-gray-300 text-16 color-black font-medium py-5 pb-16 md:py-10 capitalize">
                            <span>{item?.categoryName}</span>
                          </TableCell>
                          <TableCell
                            className={`h-52 max-h-52  border-b-[1px] border-solid border-gray-300 text-16 color-black font-medium py-5 pb-16 md:py-10 capitalize ${
                              item?.status === 'APPROVED'
                                ? 'text-darkgreen-500'
                                : item?.status === 'REJECTED'
                                ? 'text-[#DE3636]'
                                : ''
                            }`}
                          >
                            <span className="font-semibold">
                              {capitalizeFirstLetter(item?.status)}
                            </span>
                          </TableCell>
                          <TableCell className="h-52 max-h-52  border-b-[1px] border-solid border-gray-300 text-16 color-black font-medium py-5 pb-16 md:py-10 capitalize">
                            <span>{capitalizeFirstLetter(item.type)}</span>
                          </TableCell>
                          {item?.status !== 'REJECTED' && item?.status !== 'DRAFT' && (
                            <TableCell className="h-52 max-h-52  border-b-[1px] border-solid border-gray-300 text-16 color-black font-medium md:table-cell py-5 pb-16 md:py-10 capitalize">
                              {item.templateHealth ? (
                                <Typography className="bg-quick-hub text-12 md:text-16 text-center text-white rounded-md font-medium">
                                  {item.templateHealth}
                                </Typography>
                              ) : (
                                ''
                              )}
                            </TableCell>
                          )}
                          <TableCell className="h-48 max-h-48  border-b-[1px] border-solid border-gray-300 text-16 color-black font-medium py-5 pb-16 md:py-10 capitalize">
                            {moment(item.cts).format('MMM DD, YYYY')}
                          </TableCell>
                          <TableCell className="relative h-52 max-h-52 border-b-[1px] border-solid border-gray-300 text-16 color-black font-medium py-5 pb-16 md:py-10">
                            {hoveredRow === index ? (
                              <div className="flex gap-12 absolute top-8 right-0 left-0">
                                {(activeTab === 4 || activeTab === 2) && (
                                  <Button
                                    type="button"
                                    className="p-0 min-w-36 w-36 min-h-36 h-36 bg-quick-chat-100 hover:bg-quick-chat-100"
                                    onClick={() => {
                                      setViewTemplateInDrawer(true);
                                      setSelectedTemplate(item);
                                    }}
                                    aria-label="Edit"
                                  >
                                    <img
                                      className="h-20 w-20"
                                      src="assets/images/quick-chat/view.svg"
                                      alt=""
                                    />
                                  </Button>
                                )}
                                {(activeTab === 5 || activeTab === 2) && (
                                  <Button
                                    className="p-0 min-w-36 w-36 min-h-36 h-36 bg-quick-chat-100 hover:bg-quick-chat-100"
                                    onClick={() => {
                                      setDuplicateDialogue(true);
                                      setSelectedTemplate(item);
                                    }}
                                  >
                                    <img
                                      className="h-20 w-20"
                                      src="assets/images/quick-chat/copy.svg"
                                      alt=""
                                    />
                                  </Button>
                                )}
                                {activeTab === 3 && (
                                  <Button
                                    className="p-0 min-w-36 w-36 min-h-36 h-36 bg-quick-chat-100 hover:bg-quick-chat-100"
                                    onClick={() =>
                                      history.push('quick-chat/createOrEditTemplate', {
                                        template: item,
                                      })
                                    }
                                    aria-label="Edit"
                                  >
                                    <img src="assets/images/quick-hub/edit.png" alt="" />
                                  </Button>
                                )}
                                {(activeTab !== 1 || activeTab !== 2) && (
                                  <Button
                                    className="p-0 min-w-36 w-36 min-h-36 h-36 bg-quick-chat-100 hover:bg-quick-chat-100"
                                    onClick={() => {
                                      setDeleteTemplateDialogue(true);
                                      setSelectedTemplate(item);
                                    }}
                                    disabled={refreshIcon && selectedTemplate?.id === item?.id}
                                    aria-label="Delete"
                                  >
                                    <img src="assets/images/quick-hub/delete.png" alt="" />
                                  </Button>
                                )}
                              </div>
                            ) : (
                              <div className="flex gap-16 ">
                                <div className="p-3">
                                  <div className="bg-transparent rounded-full h-16 w-16" />
                                </div>
                                <div className=" p-3">
                                  <div className="bg-transparent rounded-full h-16 w-16" />
                                </div>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="px-52 py-32 bottom-0 right-0 w-full flex justify-end">
                <Pagination
                  count={Math.ceil(totalCount / 10)}
                  page={page}
                  onChange={handlePageChange}
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          ) : (
            <div>
              <div className="pt-128 text-center">
                <img
                  className="m-auto"
                  src={
                    searchText
                      ? 'assets/images/quick-chat/search.svg'
                      : 'assets/images/quick-chat/template.svg'
                  }
                  alt=""
                />
                <Typography className="text-center font-bold text-24 pt-8">
                  {searchText
                    ? t('quickCampaign.template.notExactMatchFount')
                    : activeTab === 2
                    ? t('quickCampaign.template.tabs.approved.noData')
                    : activeTab === 3
                    ? t('quickCampaign.template.tabs.draft.noData')
                    : activeTab === 4
                    ? t('quickCampaign.template.tabs.pending.noData')
                    : t('quickCampaign.template.tabs.actionRequired.noData')}
                </Typography>
                <Typography className="text-center text-14 max-w-640 m-auto pt-8">
                  {searchText
                    ? t('quickCampaign.template.templateNotExactMatchFountMessage')
                    : activeTab === 2
                    ? t('quickCampaign.template.tabs.approved.noDataMessage')
                    : activeTab === 3
                    ? t('quickCampaign.template.tabs.draft.noDataMessage')
                    : activeTab === 4
                    ? t('quickCampaign.template.tabs.pending.noDataMessage')
                    : t('quickCampaign.template.tabs.actionRequired.noDataMessage')}
                </Typography>
                <Button
                  className="w-216 font-semibold text-16 rounded-md mt-16"
                  variant="contained"
                  color="quickChat"
                  onClick={() => history.push('quick-chat/createOrEditTemplate')}
                >
                  {t('quickCampaign.template.createTemplate')}
                </Button>
              </div>
            </div>
          )}
        </>
      )}
      <ViewTemplate
        viewDialogue={viewDialogue}
        setViewDialogue={setViewDialogue}
        selectedTemplate={selectedTemplate}
      />
      <DuplicateTemplate
        openDuplicateDialogue={openDuplicateDialogue}
        setDuplicateDialogue={setDuplicateDialogue}
        selectedTemplate={selectedTemplate}
        activeTab={tabsData?.find((item) => item?.id === activeTab)?.type}
      />
      <ViewTemplateInDrawer
        viewTemplateInDrawer={viewTemplateInDrawer}
        setViewTemplateInDrawer={setViewTemplateInDrawer}
        selectedTemplate={selectedTemplate}
      />
      <DeleteTemplate
        openDeleteTemplateDialogue={openDeleteTemplateDialogue}
        setDeleteTemplateDialogue={setDeleteTemplateDialogue}
        handleDelete={handleDelete}
        refreshIcon={refreshIcon}
        deleteMessage={t('waba.broadcast.myBroadcast.deleteMessage')}
      />
    </div>
  );
};

export default Template;
