import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { selectDashboardData } from 'src/app/store/userSlice';
import { t } from 'i18next';
import { Button } from '@mui/material';
import businessData from 'src/app/main/query/business';
import FuseLoading from '@fuse/core/FuseLoading';
import quickChatQuery from 'src/app/main/query/quickChat';
import history from '../../../../../@history/@history';
import {
  callPendingTaskFunc,
  fetchSocialConnections,
  handleApiRequest,
  handleFBLogin,
  handleSocialResponse,
} from '../../../common/common';
import SelectPlatformBox from '../../quick-social/SelectPlatformBox';
import Linkedin from '../../quick-social/LinkedinConnect';
import quickSocialQuery from '../../../query/quickSocial';

const PendingTask = () => {
  const [card, setCard] = useState([]);
  const [cardId, setCardId] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [facebookUserId, setFacebookUserId] = useState();
  const [connectionData, setConnectionData] = useState([]);
  const [accessToken, setAccessToken] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPage, setSelectedPage] = useState([]);
  const [socialPlatformId, setSocialPlatformId] = useState();
  const [isLinkedin, setIsLinkedin] = useState(false);
  const [openLinkedinPopUp, setOpenLinkedinPopUp] = useState(false);
  const [linkedinRefreshIcon, setLinkedinRefreshIcon] = useState(false);
  const params = useParams();
  const location = useLocation();
  const { productType, botLink, productData } = location.state || {};
  const { enqueueSnackbar } = useSnackbar();
  const totalTasks = card.length;
  const appKey = process.env.REACT_APP_WABA_APP_ID;
  const fbConfigKey = process.env.REACT_APP_FB_CONFIG_ID;
  const inConfigKey = process.env.REACT_APP_IN_CONFIG_ID;

  const platformClasses = {
    quick_reviews: {
      bgColor: 'quick-review',
      bg: 'bg-quick-review',
      bg100: 'bg-quick-review-100',
      border: 'border-quick-review',
      color: 'quickReview',
      url: 'quick-reviews',
    },
    quick_chat: {
      bgColor: 'quick-chat',
      bg: 'bg-quick-chat',
      bg100: 'bg-quick-chat-100',
      border: 'border-quick-chat',
      color: 'quickChat',
      url: 'quick-chat',
    },
    quick_social: {
      bgColor: 'quick-social',
      bg: 'bg-quick-social',
      bg100: 'bg-quick-social-100',
      border: 'border-quick-social',
      color: 'quickSocial',
      url: 'quick-social',
    },
    quick_campaigns: {
      bgColor: 'quick-campaign',
      bg: 'bg-quick-campaign',
      bg100: 'bg-quick-campaign-100',
      border: 'border-quick-campaign',
      color: 'quickCampaign',
      url: 'quick-campaigns',
    },
    quick_ads: {
      bgColor: 'quick-social',
      bg: 'bg-quick-social',
      bg100: 'bg-quick-social-100',
      border: 'border-quick-social',
      color: 'quickSocial',
      url: 'quick-social',
    },
    default: {
      bg: 'bg-quick-review',
      bg100: 'bg-quick-review-100',
      border: 'border-quick-review',
      color: 'quickReview',
      url: 'quick-reviews',
    },
  };

  const selectedPlatform = platformClasses[productType] || platformClasses.default;

  const completedTasks = card.filter((task) => task?.isAdded)?.length;

  const fetchAgentSupportStatus = useCallback(async () => {
    try {
      setLoading(true);
      if (userCurrentBusinessDetails?.id) {
        const payloadData = {
          query: quickSocialQuery.getQuickSocialChannelWiseData,
          variables: {
            businessId: Number(userCurrentBusinessDetails?.id),
          },
        };
        const resultData = await handleApiRequest(payloadData);
        setLoading(false);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails?.id]);

  const getAppSetupCard = useCallback(async () => {
    try {
      setIsLoading(true);
      const invoicePayload = {
        query: businessData.getAppSetupCard,
        variables: {
          type: productType,
          businessId: Number(userCurrentBusinessDetails?.id),
        },
      };

      const invoiceResult = await handleApiRequest(invoicePayload);
      if (invoiceResult?.getAppSetupCard?.status === 200) {
        setCard(invoiceResult?.getAppSetupCard?.data);
        setCardId(invoiceResult?.getAppSetupCard?.appSetUpCardId);
      } else {
        console.error('Error: Unexpected status code', invoiceResult?.getAppSetupCard?.status);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setIsLoading(false);
    }
  }, [productType, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    getAppSetupCard();
  }, [getAppSetupCard]);

  const handleCallFunction = async (cardName) => {
    try {
      const payload = {
        query: quickChatQuery.updateAppSetUpCard,
        variables: {
          userBusinessId: Number(userCurrentBusinessDetails?.id),
          cardType: cardName,
          productType,
        },
      };
      const res = await handleApiRequest(payload);
      if (res?.updateAppSetUpCard?.status === 200) {
        getAppSetupCard();
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const openVideoUrl = (url) => {
    if (url) {
      window.open(url, '_blank');
    } else {
      console.error('No URL provided');
    }
  };

  if (isLoading || loading) {
    return <FuseLoading />;
  }

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleClick = async (item) => {
    const socialPlatformIds = item?.cardName === 'connectYourInstagram' ? 10 : 9;
    setSocialPlatformId(socialPlatformIds);
    if (socialPlatformIds) {
      setLoading(true);
      try {
        let authResponse;
        if (item?.cardName === 'connectYourInstagram' || 'connectYourFacebook') {
          authResponse = await handleFBLogin(appKey, fbConfigKey, inConfigKey, socialPlatformIds);
          if (authResponse) {
            const fbAccessToken = authResponse?.accessToken;
            setFacebookUserId(authResponse?.userID);

            if (fbAccessToken && userCurrentBusinessDetails?.id) {
              await fetchSocialConnections(
                socialPlatformIds,
                userCurrentBusinessDetails,
                setConnectionData,
                setAccessToken,
                setOpenDialog,
                setSelectedPage,
                setLoading,
                fbAccessToken,
                enqueueSnackbar
              );
            } else {
              console.log('Missing Facebook access token or business details.');
            }
          } else {
            console.log('Facebook login failed or was cancelled.');
          }
        }
      } catch (error) {
        console.error('Error during social connection:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="md:p-20 lg:p-24 p-16">
      <style>
        {`
    .icon-hover-effect {
      filter: brightness(0) invert(1);
      transition: filter ease;
    `}
      </style>
      <h1 className="text-24 md:text-28 font-bold mb-32">{t('quickHubDashboard.pendingTask')}</h1>

      <div className="bg-white lg:p-32 md:p-32 sm:p-24 p-16 rounded-8">
        <h3 className="font-semibold md:text-20 text-18 text-left mb-24">
          {t('pendingTask.letSetUpYourBusiness')}
        </h3>
        <p className="font-medium text-16 mb-14">
          {completedTasks} / {card?.length} {t('pendingTask.completed')}
        </p>
        <div className="flex space-x-2 mb-24 md:gap-20 sm:gap-10 gap-5">
          {Array.from({ length: totalTasks }, (_, index) => (
            <div
              key={index}
              className={`h-16 w-full rounded-4 text-white ${
                index < completedTasks ? selectedPlatform?.bg : selectedPlatform?.bg100
              } border-1 ${
                index >= completedTasks ? selectedPlatform?.border : 'border-transparent'
              }`}
            >
              .
            </div>
          ))}
        </div>
        <div>
          {card &&
            card.map((task, index) => (
              <ul
                key={index}
                className="list-disc list-inside flex flex-col sm:gap-0 gap-10 sm:flex-row justify-between w-full bg-grey-100 p-24 px-14 mb-14 rounded-8"
              >
                <li
                  className={`list-disc list-inside flex items-center ${
                    task.isAdded ? 'line-through text-black' : ''
                  }`}
                >
                  <span>
                    {task.isAdded ? (
                      <img
                        src="/assets/images/icon/reviewCheck.svg"
                        alt="tick"
                        className="mr-14 ml-10"
                      />
                    ) : (
                      <img
                        src="/assets/images/icon/dot.webp"
                        alt="dot"
                        className="h-36 w-36 justify-center items-center mr-8"
                      />
                    )}
                  </span>
                  <p className="font-medium text-16">{task?.title}</p>
                </li>
                <div className="flex flex-col sm:flex-row gap-10">
                  {task?.videoUrl &&
                    !task?.isAdded &&
                    task?.id === 95 &&
                    task?.id === 96 &&
                    task?.id === 97 &&
                    task?.id === 98 && (
                      <Button
                        className="text-12 sm:text-16 w-full sm:w-144 font-medium rounded-md"
                        variant="contained"
                        color={selectedPlatform.color}
                        type="button"
                        size="small"
                        onClick={() => openVideoUrl(task?.videoUrl)}
                      >
                        <img
                          src="/assets/images/icon/playVideoIcon-chat.svg"
                          alt="good"
                          className="max-w-60 sm:max-w-80 mr-10 icon-hover-effect"
                        />
                        {t('quickHubDashboard.watchVideo')}
                      </Button>
                    )}
                  {!task.isAdded ? (
                    <Button
                      key={index}
                      variant="outlined"
                      color={selectedPlatform.color}
                      size="small"
                      className="text-16 w-full sm:w-128 py-0 font-medium rounded-md bg-white"
                      aria-label="skip"
                      type="button"
                      onClick={() => {
                        if (
                          task?.cardName === 'connectYourInstagram' ||
                          task?.cardName === 'connectYourFacebook'
                        ) {
                          handleClick(task);
                        } else if (task?.cardName === 'connectYourLinkedin') {
                          setIsLinkedin(true);
                          setOpenLinkedinPopUp(true);
                        } else if (task?.cardName === 'connectYourTwitter') {
                          history.push('/quick-social/social-connect/');
                        } else {
                          callPendingTaskFunc(
                            task,
                            productType,
                            handleCallFunction,
                            userCurrentBusinessDetails,
                            botLink,
                            selectedPlatform.bgColor
                          );
                        }
                      }}
                    >
                      {task.buttonAction}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      className="text-16 w-full sm:w-128 mt-10 font-medium disabled:text-black rounded-md py-0 border-0 hover:bg-darkgreen cursor-default"
                      color="secondary"
                      aria-label="skip"
                      type="button"
                    >
                      {t('pendingTask.completed')}
                    </Button>
                  )}
                </div>
              </ul>
            ))}
        </div>
      </div>
      {openDialog && (
        <SelectPlatformBox
          onClose={handleClose}
          openDialog={openDialog}
          socialPlatformId={socialPlatformId}
          connectionData={connectionData}
          setSelectedPage={setSelectedPage}
          selectedPage={selectedPage}
          setOpenDialog={setOpenDialog}
          handleSocialResponse={() =>
            handleSocialResponse(
              socialPlatformId,
              accessToken,
              facebookUserId,
              selectedPage,
              userCurrentBusinessDetails,
              setLoading,
              enqueueSnackbar,
              getAppSetupCard,
              setConnectionData,
              setOpenDialog
            )
          }
          handleClose={handleClose}
        />
      )}
      {isLinkedin && (
        <Linkedin
          setIsLinkedin={setIsLinkedin}
          setLinkedinRefreshIcon={setLinkedinRefreshIcon}
          fetchAgentSupportStatus={fetchAgentSupportStatus}
          setOpenLinkedinPopUp={setOpenLinkedinPopUp}
          openLinkedinPopUp={openLinkedinPopUp}
        />
      )}
    </div>
  );
};

export default PendingTask;
