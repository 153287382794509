import FuseLoading from '@fuse/core/FuseLoading';
import { Button, CircularProgress, Switch, Typography, Checkbox } from '@mui/material';
import { selectDashboardData } from 'app/store/userSlice';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { handleApiRequest } from 'src/app/main/common/common';
import manageBusinessData from 'src/app/main/query/manageBusiness';

const AutoReplyOnReviews = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [enableAutoReply, setEnableAutoReply] = useState();
  const [enablePolicy, setEnablePolicy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);

  useEffect(() => {
    const fetchAutoThankYouHistory = async () => {
      setLoading(true);
      try {
        const payload = {
          query: manageBusinessData.getAutoReplyReviewStatus,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getAutoReplyReviewStatus?.status === 200) {
          setEnableAutoReply(result?.getAutoReplyReviewStatus?.data?.enableAutoReply);
          setEnablePolicy(result?.getAutoReplyReviewStatus?.data?.enableAutoReply);
        } else {
          enqueueSnackbar(result?.getAutoReplyReviewStatus?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };
    if (userCurrentBusinessDetails?.id) {
      fetchAutoThankYouHistory();
    }
    setLoading(false);
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar]);

  const updateAutoReplyReviewStatus = async () => {
    setRefreshIcon(true);
    try {
      const payload = {
        query: manageBusinessData.updateAutoReplyReviewStatus,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          enableAutoReply,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.updateAutoReplyReviewStatus?.status === 200) {
        enqueueSnackbar(result?.updateAutoReplyReviewStatus?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar(result?.updateAutoReplyReviewStatus?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
    setRefreshIcon(false);
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="lg:p-24 p-16">
      <Typography className="text-28 font-semibold mb-20">
        {t('autoReplyOnReviews.title')}
      </Typography>
      <div className="bg-white max-w-640 rounded-md p-20">
        <div className="flex justify-between items-center mb-10">
          <Typography className="text-18 font-medium"> {t('autoReplyOnReviews.title')}</Typography>
          <Switch
            checked={enableAutoReply}
            onChange={() => {
              setEnableAutoReply(!enableAutoReply);
            }}
          />
        </div>
        <p className="mb-28 text-16 max-w-480">{t('autoReplyOnReviews.content')}</p>
        {enableAutoReply && (
          <div className="flex items-center mb-20">
            <p className="w-full">
              I agree to the{' '}
              <a
                href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
                target="_blank"
                rel="noreferrer noopener"
                role="button"
                className="text-darkgreen hover:underline cursor-pointer"
              >
                Primary Policy
              </a>
              ,{' '}
              <a
                href={process.env.REACT_APP_TERMS_OF_SERVICE_LINK}
                target="_blank"
                rel="noreferrer noopener"
                role="button"
                className="text-darkgreen hover:underline cursor-pointer"
              >
                Terms, and Conditions.
              </a>
            </p>
            <Checkbox
              className="p-0 text-grey-300"
              checked={enablePolicy}
              onChange={() => setEnablePolicy(!enablePolicy)}
            />
          </div>
        )}
        <div className="flex justify-end">
          <Button
            variant="contained"
            color="quickReview"
            className="rounded-md py-10 px-56"
            size="large"
            disabled={refreshIcon || !enablePolicy}
            onClick={() => updateAutoReplyReviewStatus()}
          >
            {refreshIcon && (
              <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
            )}
            {t('common.save')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AutoReplyOnReviews;
