import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Icon,
  IconButton,
  InputAdornment,
  Pagination,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import AddIcon from '@mui/icons-material/Add';
import Checkbox from '@mui/material/Checkbox';
import history from '@history';
import SearchIcon from '@mui/icons-material/Search';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import businessData from 'src/app/main/query/business';
import {
  capitalizeFirstLetter,
  generateRandomColor,
  handleApiRequest,
} from 'src/app/main/common/common';
import AddContact from 'src/app/main/business/quick-chat/waba/AddContact';
import CSVPopUp from '../contacts/CSVPopUp';

const SelectContacts = (props) => {
  const {
    createCampaignDetails,
    selectedContact,
    setSelectContact,
    businessDetails,
    campaignName,
    setStepsCount,
    stepsCount,
    fetchContactData,
    csvContactData,
    contactsData,
    fetchCsvData,
    state,
    page,
    setSearchTerm,
    searchTerm,
    setPage,
    csvPage,
    setCsvPage,
    totalCount,
    csvTotalCount,
    tabValue,
    setTabValue,
    isAllPageContactSelect,
    setIsAllPageContactSelect,
  } = props;
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [openCreateDialogue, setOpenCreateDialogue] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [csvUpload, setCSVUpload] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [csvCustomer, setCsvCustomer] = useState();
  const [isViewCsv, setIsViewCsv] = useState(false);
  const [filterCsvCustomer, setFilterCsvCustomer] = useState([]);
  const [viewCsvId, setViewCsvId] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState(null);
  const [selectedContactPage, setSelectedContactPage] = useState(1);
  const rowsPerPage = 10;
  const paginatedContacts = contacts.slice(
    (selectedContactPage - 1) * rowsPerPage,
    selectedContactPage * rowsPerPage
  );

  useEffect(() => {
    if (stepsCount === 3) {
      if (isAllPageContactSelect) {
        if (tabValue === 1) {
          const filterEmailData = contactsData?.filter((item) =>
            createCampaignDetails?.platForm === 1 && tabValue === 1 ? item?.email : true
          );
          setContacts(filterEmailData);
        } else if (tabValue === 2) {
          setContacts(csvContactData);
        }
      } else {
        setContacts(selectedContact);
      }
    } else if (stepsCount === 2) {
      if (tabValue === 1) {
        const filterEmailData = contactsData?.filter((item) =>
          createCampaignDetails?.platForm === 1 && tabValue === 1 ? item?.email : true
        );
        setContacts(filterEmailData);
      } else if (tabValue === 2) {
        setContacts(csvContactData || []);
      }
    }
  }, [
    csvContactData,
    contactsData,
    selectedContact,
    stepsCount,
    tabValue,
    createCampaignDetails?.platForm,
    isAllPageContactSelect,
  ]);

  useEffect(() => {
    const isSubsetById = () => {
      const mainSet = new Set(selectedContact?.map((item) => item.id));
      return contacts.every((item) => mainSet?.has(item.id));
    };
    setIsSelectAll(isSubsetById);
  }, [selectedContact, contacts]);

  useEffect(() => {
    if (contacts?.length > 0) {
      window.scrollTo({ top: 0 });
    }
  }, [contacts?.length, stepsCount]);

  useEffect(() => {
    setSelectContact((prevSelected) => {
      let updatedContacts;
      if (tabValue === 1) {
        updatedContacts = prevSelected?.map((prev) => {
          const match = contactsData?.find((contact) => contact?.phoneNumber === prev?.phoneNumber);
          return match || prev;
        });
      } else if (tabValue === 2) {
        updatedContacts = prevSelected?.map((prev) => {
          const match = csvContactData?.find((contact) => contact?.id === prev?.id);
          return match || prev;
        });
      }
      return updatedContacts;
    });
  }, [contactsData, tabValue, csvContactData, setSelectContact]);

  useEffect(() => {
    if (Number(businessDetails?.id)) {
      if (tabValue === 1) {
        fetchContactData();
      } else {
        fetchCsvData();
      }
    }
  }, [businessDetails?.id, fetchContactData, fetchCsvData, tabValue]);

  const cancelDialogue = async () => {
    setOpenCreateDialogue(false);
  };

  const handleTabChange = (event, newValue) => {
    setSelectContact([]);
    setTabValue(newValue);
    setSearchTerm('');
    setPage(1);
    setCsvPage(1);
    setIsAllPageContactSelect(false);
  };

  const handleSelectContact = (data) => {
    setSelectContact((prevContacts) => {
      const contactExists = prevContacts?.some(
        (contact) => Number(contact.phoneNumber) === Number(data.phoneNumber)
      );
      if (contactExists) {
        return prevContacts?.filter(
          (contact) => Number(contact.phoneNumber) !== Number(data.phoneNumber)
        );
      }
      return [...prevContacts, data];
    });
  };
  const handleSelectAllContact = () => {
    setSelectContact((prevSelected) => {
      const areAllAvailable = contacts?.every((contact) =>
        prevSelected?.some(
          (selected) =>
            (tabValue === 1 ? Number(selected?.phoneNumber) : Number(selected?.phoneNumber)) ===
            (tabValue === 1 ? Number(contact?.phoneNumber) : Number(contact?.phoneNumber))
        )
      );
      if (areAllAvailable) {
        return prevSelected?.filter(
          (contact) =>
            !contacts.some(
              (c) =>
                (tabValue === 1 ? Number(c?.phoneNumber) : Number(c?.phoneNumber)) ===
                (tabValue === 1 ? Number(contact?.phoneNumber) : Number(contact?.phoneNumber))
            )
        );
      }
      return [...new Set([...prevSelected, ...contacts])];
    });
  };

  const saveCampaignDraft = async () => {
    try {
      setRefreshIcon(true);
      const payload = {
        query: businessData.saveAndUpdateCampaignDraft,
        variables: {
          businessId: Number(businessDetails?.id),
          campaignName,
          isSchedule: false,
          scheduleDate: null,
          campaignPlatformId: Number(createCampaignDetails?.platForm),
          campaignDraftId: state?.id || null,
          campaignServicesStandardMessageId: Number(createCampaignDetails?.id),
          ...(!isAllPageContactSelect && {
            ...(tabValue === 1
              ? {
                  queue: selectedContact?.map((item) => {
                    if (createCampaignDetails?.platForm === 1) {
                      return {
                        email: item?.email,
                        htmlSubject: createCampaignDetails?.htmlSubject,
                        htmlTemplate: createCampaignDetails?.htmlTemplate,
                        phoneNumber: Number(item.phoneNumber),
                        countryCode: item.countryCode,
                        name: `${item?.name}`,
                        title: createCampaignDetails?.messages,
                      };
                    }
                    if (createCampaignDetails?.platForm === 2) {
                      return {
                        phoneNumber: item.phoneNumber,
                        countryCode: item.countryCode,
                        name: `${item?.name}`,
                        title: createCampaignDetails?.messages,
                      };
                    }
                    if (createCampaignDetails?.platForm === 3) {
                      return {
                        phoneNumber: item.phoneNumber,
                        countryCode: item.countryCode,
                        name: `${item?.name}`,
                      };
                    }
                    return null;
                  }),
                }
              : {
                  csvIds: selectedContact?.map((item) => item?.id),
                }),
          }),
        },
      };
      const res = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (res?.saveAndUpdateCampaignDraft?.status === 200) {
        history.push('quick-campaign/campaign');
      } else {
        enqueueSnackbar(res?.saveAndUpdateCampaignDraft?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error in saveCampaignDraft:', error);
    }
  };

  const handleSearchTerm = (value, data) => {
    setSearchTerm(value);
    const filterData = data?.filter((item) =>
      tabValue === 1 ? item?.name?.includes(value) : item?.fileName?.includes(value)
    );
    setContacts(filterData);
  };

  const handleClearSearch = async () => {
    await setSearchTerm('');
  };

  const handleCloseUploadCSV = () => {
    setCSVUpload(false);
    fetchCsvData();
  };

  const handleSelectCsvFile = async (data) => {
    setViewCsvId(data);
    try {
      const payload = {
        query: businessData.getUserCSVOnCsvId,
        variables: {
          csvId: Number(data?.id),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getUserCSVOnCsvId?.status === 200) {
        const allContacts = result?.getUserCSVOnCsvId?.data?.map((item) => ({
          ...item,
          userProfile: generateRandomColor(),
        }));
        setIsViewCsv(true);
        setCsvCustomer(allContacts);
        setFilterCsvCustomer(allContacts);
      } else {
        setCsvCustomer([]);
      }
    } catch (error) {
      console.error('Error handling CSV file:', error);
    }
  };

  const handleSearchCustomer = (value) => {
    const filterData = csvCustomer.filter(
      (item) => item?.name?.includes(value) || item?.phoneNumber?.toString().includes(value)
    );
    setFilterCsvCustomer(filterData);
  };

  const handleClearSearchCustomer = () => {
    setFilterCsvCustomer(csvCustomer);
  };

  const handlePageChange = (event, value) => {
    if (stepsCount === 3 && !isAllPageContactSelect) {
      setSelectedContactPage(value);
    } else if (tabValue === 1) {
      setPage(value);
    } else {
      setCsvPage(value);
    }
  };

  const handleDownloadCsvFile = async () => {
    const payload = {
      query: businessData.getCampaignPlatformDemoCsv,
    };
    const result = await handleApiRequest(payload);
    if (result?.getCampaignPlatformDemoCsv?.status === 200) {
      const demoCsv = result?.getCampaignPlatformDemoCsv?.data?.demoCsv;
      const downloadLink = document.createElement('a');
      downloadLink.href = demoCsv;
      downloadLink.target = '_blank';
      downloadLink.download = 'demo.csv';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <div className="p-14 sm:p-24">
      {stepsCount === 2 && (
        <div className="border-solid border-grey sm:flex justify-between items-center border-b-2 mb-32 overflow-x-auto">
          <Tabs
            value={tabValue}
            className="sm:overflow-hidden overflow-scroll quick-campaign"
            sx={{
              '& .MuiTabs-scroller': {
                overflow: { xs: 'scroll !important', sm: 'hidden !important' },
              },
            }}
            onChange={handleTabChange}
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab
              className={`sm:text-18 font-bold mr-20 p-0 ${
                tabValue === 1 ? 'text-quick-campaign' : 'text-black'
              }`}
              value={1}
              label={t('quickCampaignsContacts.tab1')}
            />
            <Tab
              className={`sm:text-18 font-bold mr-20 p-0 ${
                tabValue === 2 ? 'text-quick-campaign' : 'text-black'
              }`}
              value={2}
              label={t('quickCampaignsContacts.tab2')}
            />
          </Tabs>
        </div>
      )}
      {((((tabValue === 1 && contactsData?.length > 0 && stepsCount !== 3) ||
        (tabValue === 2 && csvContactData?.length > 0)) &&
        stepsCount === 2) ||
        searchTerm) && (
        <div className="w-full flex flex-col sm:flex-row gap-8 sm:gap-16 mb-14 sm:mb-8">
          <TextField
            placeholder="Search name or number"
            variant="outlined"
            fullWidth
            margin="normal"
            size="small"
            className="w-full bg-gray-A500 mt-0"
            value={searchTerm}
            onChange={(e) =>
              handleSearchTerm(e.target.value, tabValue === 1 ? contactsData : csvContactData)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <Icon
                    color="quickChat"
                    className="cursor-pointer text-20"
                    onClick={handleClearSearch}
                  >
                    close
                  </Icon>
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
              },
            }}
          />
          <Button
            variant="outlined"
            color="quickCampaign"
            autoFocus
            size="medium"
            className="rounded-md px-16 min-w-192 quick-campaign bg-gray-A500 hover:bg-gray-A500 border border-gray-A500 hover:border-gray-A500"
          >
            <Checkbox
              checked={isAllPageContactSelect}
              onChange={() => setIsAllPageContactSelect(!isAllPageContactSelect)}
            />
            <p className="text-16 font-medium">
              {t('quickCampaignsContacts.selectAll')}
              {isAllPageContactSelect ? ` (${tabValue === 1 ? totalCount : csvTotalCount})` : ''}
            </p>
          </Button>
          <Button
            variant="outlined"
            color="quickCampaign"
            autoFocus
            onClick={() => {
              tabValue === 1 ? setOpenCreateDialogue(true) : setCSVUpload(true);
            }}
            size="medium"
            className="rounded-md px-16 min-w-160 bg-gray-A500 hover:bg-gray-A500 border border-gray-A500 hover:border-gray-A500"
          >
            <AddIcon />
            <p className="text-16 font-medium">
              {tabValue === 1
                ? t('quickCampaignsContacts.addContact')
                : t('quickCampaignsContacts.addCSVFile')}
            </p>
          </Button>
        </div>
      )}
      {stepsCount === 3 && (
        <div className="text-end">
          <Button
            className="text-quick-campaign font-bold text-16 hover:bg-white"
            onClick={() => setStepsCount(2)}
          >
            {t('common.edit')}
          </Button>
        </div>
      )}
      {contacts?.length > 0 ? (
        <>
          <TableContainer component={Paper} className="rounded-md border-none shadow-0">
            <Table className="border border-solid border-t-0 min-w-480 md:min-w-586">
              <TableHead>
                <TableRow className="bg-gray-A500">
                  <TableCell className="p-8 quick-campaign sm:p-12 px-14 sm:px-24 border-b-2 border-grey-300 w-12">
                    <Checkbox
                      checked={isAllPageContactSelect || isSelectAll}
                      onChange={() => handleSelectAllContact()}
                      disabled={isAllPageContactSelect || stepsCount === 3}
                      className={`${
                        (isAllPageContactSelect || stepsCount === 3) &&
                        'opacity-50 cursor-not-allowed text-gray-400'
                      }`}
                    />
                  </TableCell>
                  <TableCell className="font-medium p-8 sm:p-12 px-14 sm:px-24 text-14 sm:text-18 border-b-2 border-grey-300">
                    {tabValue === 1 ? t('common.name') : t('quickCampaignsContacts.csvFile')}
                  </TableCell>
                  <TableCell className="font-medium p-8 sm:p-12 px-14 sm:px-24 text-14 sm:text-18 border-b-2 border-grey-300">
                    {tabValue === 1
                      ? createCampaignDetails?.platForm === 1
                        ? t('common.email')
                        : t('quickCampaignsContacts.contactNumber')
                      : t('quickCampaignsContacts.numberOfContacts')}
                  </TableCell>
                  {tabValue === 2 && (
                    <TableCell className="font-medium p-8 sm:p-12 px-14 sm:px-24 text-14 sm:text-18 border-b-2 border-grey-300 w-[15%]" />
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {(stepsCount === 3 && !isAllPageContactSelect ? paginatedContacts : contacts)?.map(
                  (item, index) => {
                    return (
                      <TableRow
                        key={index}
                        className="quick-campaign hover:bg-quick-campaign-100 hover:bg-opacity-30 h-64 border-b-2 border-grey-300"
                      >
                        <TableCell className="font-medium p-8 sm:p-12 px-14 sm:px-24">
                          <Checkbox
                            checked={
                              isAllPageContactSelect ||
                              selectedContact?.some(
                                (value) => Number(value?.phoneNumber) === Number(item?.phoneNumber)
                              )
                            }
                            onChange={() => handleSelectContact(item)}
                            disabled={isAllPageContactSelect || stepsCount === 3}
                            className={`${
                              (isAllPageContactSelect || stepsCount === 3) &&
                              'opacity-50 cursor-not-allowed text-gray-400'
                            }`}
                          />
                        </TableCell>

                        <TableCell className="font-semibold p-8 sm:p-12 px-14 sm:px-24 text-14 sm:text-18">
                          {tabValue === 1 ? (
                            <div className="flex items-center gap-12">
                              <div
                                style={{
                                  backgroundColor: `#${item.userProfile}33`,
                                  borderRadius: '50%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  color: `#${item.userProfile}`,
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                }}
                                className="sm:min-w-42 sm:min-h-42 min-w-28 min-h-28"
                              >
                                {item?.name?.charAt(0).toUpperCase()}
                              </div>
                              <Typography className="font-semibold text-16">
                                {capitalizeFirstLetter(item?.name)}
                              </Typography>
                            </div>
                          ) : (
                            <div className="flex items-center gap-12 break-all">
                              <img className="w-40" src="assets/images/business/csv.png" alt="" />
                              {item?.fileName}
                            </div>
                          )}
                        </TableCell>
                        <TableCell className="font-semibold p-8 sm:p-12 px-14 sm:px-24 text-14 sm:text-18">
                          {tabValue === 1
                            ? createCampaignDetails?.platForm === 1
                              ? item?.email
                              : `${item?.phoneNumber}` === 'null'
                              ? ''
                              : `${item?.countryCode} ${item?.phoneNumber}`
                            : `${item?.csvContactCount} ${t('quickCampaignsContacts.contacts')}`}
                        </TableCell>
                        {tabValue === 2 && (
                          <TableCell className="p-12 px-24 border-b-2 border-grey-300">
                            <Button
                              className="p-0 hover:bg-transparent text-quick-campaign text-16 font-semibold"
                              onClick={() => {
                                handleSelectCsvFile(item);
                              }}
                            >
                              {t('quickCampaignsContacts.viewCsv')}
                            </Button>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="bg-white px-24 sm:px-52 py-20 sm:py-32 bottom-0 right-0 w-full flex justify-end">
            <Pagination
              count={
                stepsCount === 3 && !isAllPageContactSelect
                  ? Math.ceil(contacts.length / rowsPerPage)
                  : Math.ceil(tabValue === 1 ? totalCount / 10 : csvTotalCount / 10)
              }
              page={
                stepsCount === 3 && !isAllPageContactSelect
                  ? selectedContactPage
                  : tabValue === 1
                  ? page
                  : csvPage
              }
              onChange={handlePageChange}
              showFirstButton
              showLastButton
            />
          </div>
        </>
      ) : (
        <>
          {tabValue === 1 ? (
            <div className="flex justify-center items-center py-96">
              <div className="text-center">
                <img className="m-auto w-160" src="assets/images/business/addContact.png" alt="" />
                <Typography className="py-8 font-semibold text-20">
                  {t('quickCampaign.campaign.noContact')}
                </Typography>
                <Typography className="font-normal max-w-400">
                  {t('quickCampaign.campaign.noContactAddMessage')}
                </Typography>
                <Button
                  variant="contained"
                  color="quickCampaign"
                  autoFocus
                  onClick={() => setOpenCreateDialogue(true)}
                  size="medium"
                  className="rounded-md mt-16 px-60"
                >
                  <AddIcon />
                  <p className="text-16 font-medium">{t('quickCampaignsContacts.addContact')}</p>
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center py-112">
              <div className="text-center">
                <img className="m-auto" src="assets/images/business/csv.png" alt="" />
                <Typography className="font-bold text-16 py-12 text-20">
                  {t('quickCampaignsContacts.notAddCsv')}
                </Typography>
                <Typography className="max-w-480 m-auto">
                  {t('quickCampaignsContacts.notYetCsvMessage')}
                </Typography>
                <Button
                  variant="contained"
                  color="quickCampaign"
                  autoFocus
                  onClick={() => setCSVUpload(true)}
                  size="medium"
                  className="rounded-md mt-16 px-60"
                >
                  <AddIcon />
                  <p className="text-16 font-medium">{t('quickCampaignsContacts.addCSVFile')}</p>
                </Button>
                <div>
                  <Button
                    variant="outlined"
                    color="quickCampaign"
                    className="rounded-md mt-16 px-48"
                    onClick={handleDownloadCsvFile}
                  >
                    {t('quickCampaignsContacts.downloadDemoCSV')}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {contacts?.length > 0 && stepsCount === 2 && (
        <div className="mt-24 flex flex-wrap gap-12 justify-between items-center">
          <Button
            className="rounded-md w-144 text-quick-campaign hover:bg-white hover:border-quick-campaign border-quick-campaign font-semibold text-16"
            variant="outlined"
            color="secondary"
            onClick={() => saveCampaignDraft()}
            disabled={refreshIcon}
          >
            {t('common.save')}
            {refreshIcon && (
              <CircularProgress className="text-quick-campaign absolute mx-auto" size={24} />
            )}
          </Button>
          <div className="text-end flex flex-col sm:flex-row gap-10 justify-normal sm:justify-end">
            <Button
              variant="outlined"
              color="quickCampaign"
              autoFocus
              className="rounded-md font-semibold text-16 px-32 md:px-76 md:py-12"
              // onClick={() => handleCreateTemplate()}
              size="medium"
            >
              {t('dashboard.dashboardSales.selected')}{' '}
              {`
            ${selectedContact?.length > 0 ? `(${selectedContact.length})` : ''}`}
            </Button>
            <Button
              variant="contained"
              color="quickCampaign"
              autoFocus
              onClick={() => setStepsCount(3)}
              size="medium"
              disabled={selectedContact?.length === 0 && !isAllPageContactSelect}
              className={`rounded-md font-semibold text-16 px-40 md:px-76 ${
                refreshIcon
                  ? 'disabled:bg-gray disabled'
                  : 'disabled:opacity-40 disabled:bg-quick-campaign disabled:text-white'
              }`}
            >
              {t('quickCampaignsContacts.next')}
            </Button>
          </div>
        </div>
      )}
      <AddContact
        openValue={openCreateDialogue}
        cancelCall={cancelDialogue}
        type="add"
        popUpMessage={t('waba.broadcast.contactList.addContact')}
        popUpButtonName={t('waba.broadcast.contactList.addContact')}
        borderColor="quick-campaign"
        textColor="quick-campaign"
        callFrom="campaign"
        getData={fetchContactData}
        isEmailRequired={createCampaignDetails?.platForm === 1}
      />
      <CSVPopUp
        open={csvUpload}
        onClose={handleCloseUploadCSV}
        fetchData={fetchContactData}
        file={file}
        setFile={setFile}
        color="quick-campaign"
      />
      <Dialog
        open={isViewCsv}
        classes={{
          paper: `m-24 p-28 rounded-sm`,
        }}
        maxWidth="md"
      >
        <div className="flex items-center justify-between">
          <h3 className="text-16 font-bold">{viewCsvId?.fileName}</h3>
          <div>
            <IconButton
              className="hover:bg-transparent"
              onClick={() => {
                setIsViewCsv(false);
                setViewCsvId();
              }}
              color="inherit"
            >
              <CloseIcon className="text-24 text-grey-600" />
            </IconButton>
          </div>
        </div>
        <DialogContent className="p-0 mb-24">
          <TextField
            placeholder="Search name or number"
            variant="outlined"
            fullWidth
            margin="normal"
            size="small"
            className="w-full bg-gray-A500 mt-0"
            onChange={(e) => handleSearchCustomer(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <Icon
                    color="quickChat"
                    className="cursor-pointer text-20"
                    onClick={handleClearSearchCustomer}
                  >
                    close
                  </Icon>
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
              },
            }}
          />
          <TableContainer component={Paper} className="rounded-md border-none shadow-0">
            <Table className="border border-solid border-t-0">
              <TableHead>
                <TableRow className="bg-gray-A500">
                  <TableCell className="font-medium p-12 px-24 text-18 border-b-2 border-grey-300">
                    {t('common.name')}
                  </TableCell>
                  <TableCell className="font-medium p-12 px-24 text-18 border-b-2 border-grey-300">
                    {t('common.email')}
                  </TableCell>
                  <TableCell className="font-medium p-12 px-24 text-18 border-b-2 border-grey-300">
                    {t('quickCampaignsContacts.contactNumber')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterCsvCustomer?.length > 0 &&
                  filterCsvCustomer?.map((item, index) => {
                    return (
                      <TableRow
                        key={index}
                        className="cursor-pointer hover:bg-quick-campaign-100 hover:bg-opacity-30 h-64 border-b-2 border-grey-300"
                      >
                        <TableCell className="font-semibold p-12 px-24 text-16">
                          <div className="flex items-center gap-12">
                            <div
                              style={{
                                backgroundColor: `#${item.userProfile}33`,
                                width: '38px',
                                height: '38px',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: `#${item.userProfile}`,
                                fontSize: '16px',
                                fontWeight: 'bold',
                              }}
                            >
                              {item?.name?.charAt(0).toUpperCase()}
                            </div>
                            <Typography className="font-semibold text-16">
                              {capitalizeFirstLetter(item?.name)}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell className="font-semibold p-12 px-24 text-16">
                          {item?.email}
                        </TableCell>
                        <TableCell className="font-semibold p-12 px-24 text-16">
                          {`${item?.countryCode} ${item?.phoneNumber}`}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SelectContacts;
